














































































































import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import { SchoolSelect } from '@business-components/school'
import { SchoolDepartSelect } from '@business-components/school-depart'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { BusinessTypeSelect } from '@business-components/business-type'
import { ClassTypeSelect } from '@business-components/class-type'
import { EnableStatusRadio } from '@business-components/enable-status'
import { Button, Form, FormItem, Input } from 'view-design'
import { SchoolClassType, Session } from 'xuexin-vuex'
import i18n from '../../i18n'
const components = {
  AppModal,
  Button,
  Form,
  FormItem,
  Input,
  SchoolSelect,
  SchoolDepartSelect,
  SchoolPhaseSelect,
  BusinessTypeSelect,
  ClassTypeSelect,
  EnableStatusRadio,
}

@Component({ name: 'TrialReadingModal', components, i18n })
export default class TrialReadingModal extends Vue {
  @Ref('model') private readonly form!: Form
  @Model('input') private value!: any
  @Prop({ type: Object })
  private readonly schoolClassType!: SchoolClassType.Entity
  @Prop({ type: Number }) private readonly termID!: number
  @SchoolClassType.Getter('itemStatus')
  private readonly itemStatus!: SchoolClassType.Getter.ItemStatus
  @SchoolClassType.Getter('listStatus')
  private readonly listStatus!: SchoolClassType.Getter.ListStatus
  @SchoolClassType.Action('create')
  private readonly create!: SchoolClassType.Action.Create
  @SchoolClassType.Action('update')
  private readonly update!: SchoolClassType.Action.Update

  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  private readonly prefixCls = 'add-edit-view'
  private title = ''
  private visible = false
  private verifying = false
  private loading = true
  // 表单数据对象
  private model = {
    unitClassTypeName: '',
    parentUnitID: -1,
    unitID: -1,
    schoolDepartID: -1,
    phaseID: -1,
    manageTypeID: -1,
    classTypeID: -1,
    enable: 1,
  }
  get organID() {
    return this.getUser ? this.getUser.organID : -1
  }
  // 验证规则
  get rules() {
    return {
      unitClassTypeName: {
        required: true,
        validator: this.validName,
        trigger: 'blur',
      },
      parentUnitID: {
        required: true,
        validator: this.validparentUnitID,
        trigger: 'change',
      },
      unitID: {
        required: true,
        validator: this.validunitID,
        trigger: 'change',
      },
      schoolDepartID: {
        required: true,
        validator: this.validDepart,
        trigger: 'change',
      },
      phaseID: {
        required: true,
        validator: this.validPhase,
        trigger: 'change',
      },
      manageTypeID: {
        required: true,
        validator: this.validManage,
        trigger: 'change',
      },
      classTypeID: {
        required: true,
        validator: this.validClass,
        trigger: 'change',
      },
      enable: { required: true },
    }
  }
  get identityType() {
    if (
      this.getUser?.identityType === 3 &&
      this.getUser.parentUnitID !== void 0
    ) {
      this.model.parentUnitID = this.getUser.parentUnitID
    }
    return this.getUser?.identityType
  }
  //获取当前校本班型的状态
  get getItemStatus() {
    return this.itemStatus(
      this.schoolClassType ? this.schoolClassType.unitClassTypeID : -1
    )
  }
  //获取当前查询条件所对应的list 状态
  get getListStatus() {
    return this.listStatus(this.model)
  }

  // 监听弹窗显示状态
  @Watch('value', { immediate: true })
  private watchVisible(value: boolean) {
    this.visible = value
    if (value) {
      if (this.schoolClassType) {
        this.title = this.$t('button-text.edit') as 'edit'
      } else {
        this.title = this.$t('button-text.create') as 'create'
      }
    }
  }

  // 监听弹窗显示状态
  @Watch('schoolClassType', { immediate: true, deep: true })
  private watchData(value: boolean) {
    if (this.schoolClassType) {
      this.$set(this.$data, 'model', Object.assign(this.model, value))
    }
  }
  //根据listStatus的数据状态，判断创建事件是否完成
  @Watch('getListStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (this.value && !creating && previous) {
      if (this.getListStatus.creatingError !== null) {
        this.$Message.error(this.getListStatus.creatingError)
        return this.stopAction()
      }

      this.$emit('input', false)
      this.$emit('handle-close', false)
      this.$Message.success(this.$t('message.create-success'))
      return this.stopAction()
    }
  }
  //根据itemStatus数据状态，判断更新事件是否完成
  @Watch('getItemStatus.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (this.value && !updating && previous) {
      if (this.getItemStatus.updatingError !== null) {
        this.$Message.error(this.getItemStatus.updatingError)
        return this.stopAction()
      }
      this.$emit('input', false)
      this.$emit('handle-close', false)
      this.$Message.success(this.$t('message.edit-success'))
      return this.stopAction()
    }
  }
  private stopAction(): void {
    this.loading = false
    this.verifying = false
    this.$nextTick(() => (this.loading = true))
  }
  // 确定按钮点击事件
  private handleOK(): void {
    this.form.validate((valid?: boolean) => {
      if (valid) {
        let that = this
        this.verifying = true
        if (that.schoolClassType) {
          that.update(
            Object.assign({}, this.model, {
              unitClassTypeID: that.schoolClassType.unitClassTypeID,
            })
          )
        } else {
          that.create(this.model)
        }
      } else {
        return this.stopAction()
      }
    })
  }
  // 弹窗显示状态改变事件
  private handleVisibleChange(visible: boolean): void {
    this.$emit('input', visible)
  }
  // 取消按钮事件
  private handleHidden(): void {
    this.form.resetFields()
    this.$set(this.$data, 'model', {
      unitClassTypeName: '',
      parentUnitID: -1,
      unitID: -1,
      schoolDepartID: -1,
      phaseID: -1,
      manageTypeID: -1,
      classTypeID: -1,
      enable: 1,
    })
    this.stopAction()
  }
  // 校本班型验证
  private validName(rule: string, value: string, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('message.name-cannot-empty') as string))
    } else {
      callback()
    }
  }
  // 学校验证
  private validparentUnitID(rule: string, value: number, callback: any) {
    if (value === -1) {
      callback(new Error(this.$t('message.select-school') as string))
    } else {
      callback()
    }
  }
  // 校区验证
  private validunitID(rule: string, value: number, callback: any) {
    if (value === -1) {
      callback(new Error(this.$t('message.select-campus') as string))
    } else {
      callback()
    }
  }
  // 学部验证
  private validDepart(rule: string, value: number, callback: any) {
    if (value === -1) {
      callback(new Error(this.$t('message.select-school-depart') as string))
    } else {
      callback()
    }
  }
  // 学段验证
  private validPhase(rule: string, value: number, callback: any) {
    if (value === -1) {
      callback(new Error(this.$t('message.select-phase') as string))
    } else {
      callback()
    }
  }
  // 报读类型验证
  private validManage(rule: string, value: number, callback: any) {
    if (value === -1) {
      callback(new Error(this.$t('message.select-manage-type') as string))
    } else {
      callback()
    }
  }
  // 班级类型验证
  private validClass(rule: string, value: number, callback: any) {
    if (value === -1) {
      callback(new Error(this.$t('message.select-class-type') as string))
    } else {
      callback()
    }
  }
}
