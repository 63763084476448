var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ViewLayout',{attrs:{"name":_vm.prefixCls}},[_c('div',{class:_vm.classes},[_c('div',{class:("card " + _vm.prefixCls + "_head")},[_c('DynamicSearch',{on:{"on-search":_vm.handleSearch},model:{value:(_vm.parameter),callback:function ($$v) {_vm.parameter=$$v},expression:"parameter"}})],1),_vm._v(" "),_c('div',{class:("card " + _vm.prefixCls + "_body")},[_c('DynamicToolbar',{class:(_vm.prefixCls + "_toolbar"),scopedSlots:_vm._u([{key:"export",fn:function(){return [_c('Button',{attrs:{"disabled":_vm.disButton},on:{"click":function($event){return _vm.handleClick('export')}}},[_vm._v(_vm._s(_vm.$t('button.export')))])]},proxy:true}])}),_vm._v(" "),_c('AppTable',{class:(_vm.prefixCls + "_table"),attrs:{"loading":_vm.fetching,"data":_vm.tableData,"columns":_vm.tableColumns,"no-data-text":_vm.noDataText},on:{"on-selection-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"studentName",fn:function(ref){
var row = ref.row;
return [_c('StudentFlowStatusBadge',{attrs:{"value":row.flowStatus,"startDate":row.startShortDate || row.startFeedbackDate,"endDate":row.endShortDate || row.endFeedbackDate}}),_vm._v(" "),_c('span',{staticClass:"text-link",attrs:{"title":row.studentName},on:{"click":function($event){return _vm.handleClick('view-student', row)}}},[_vm._v(_vm._s(row.studentName))])]}},{key:"school",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.school}},[_vm._v(_vm._s(row.school))])]}},{key:"gradeName",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.gradeName}},[_vm._v(_vm._s(row.gradeName))])]}},{key:"nativePlace",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.nativePlace}},[_vm._v(_vm._s(row.nativePlace))])]}},{key:"workplace",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.workplace}},[_vm._v(_vm._s(row.workplace))])]}},{key:"registeredPlace",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.registeredPlace}},[_vm._v(_vm._s(row.registeredPlace))])]}},{key:"homeAddressInfo",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.homeAddressInfo}},[_vm._v(_vm._s(row.homeAddressInfo))])]}},{key:"passportAddress",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.passportAddress}},[_vm._v(_vm._s(row.passportAddress))])]}},{key:"address",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.address}},[_vm._v(_vm._s(row.address))])]}},{key:"parentNation",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.parentNation}},[_vm._v(_vm._s(row.parentNation))])]}},{key:"freshStudent",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.freshStudent ? '是' : '否')+"\n        ")]}},{key:"academicDirection",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.academicDirectionName)+"\n        ")]}},{key:"studentCategory",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.studentCategoryName)+"\n        ")]}},{key:"remark",fn:function(ref){
var row = ref.row;
return [_c('span',{attrs:{"title":row.remark}},[_vm._v(_vm._s(row.remark))])]}}])}),_vm._v(" "),(_vm.total !== 0)?_c('AppPagination',{class:(_vm.prefixCls + "_pagination"),attrs:{"current":_vm.page,"page-size":_vm.pageSize,"total":_vm.total,"disabled":_vm.fetching},on:{"on-change":_vm.handlePageChange,"on-page-size-change":_vm.handlePageSizeChange}}):_vm._e()],1)]),_vm._v(" "),_c('ExportModal',{attrs:{"value":_vm.modal.name === 'export',"data":_vm.modal.data},on:{"on-finish":function($event){return _vm.handleModalFinish('export', $event)},"on-hidden":_vm.handleModalHidden}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }