































import Vue from 'vue'
import { Component, Ref, Mixins, Watch } from 'vue-property-decorator'

import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'

import getPageColumns from '@components/report-toolbar/columns'
import AppTable from '@components/app-table'
import PageTyping from '../../../../types/page'
import { setReportData, spanChildMethod } from '@util/report'
import i18n from './i18n'

import StudentReport, { ActionType } from '@store/modules/student-report'

const components = {
  ViewLayout,
  ReportSearch,
  AppTable,
  ReportToolbar,
}

@Component({ name: 'StudentComppareAnalysisPage', components, i18n })
export default class StudentComppareAnalysisPage extends Mixins(
  PagePropsMixins
) {
  @StudentReport.Action('fetchList') private readonly fetchList!: any
  @StudentReport.Action('export') private readonly export!: any
  @StudentReport.Getter('list')
  private readonly getList!: StudentReport.Getter.List
  @StudentReport.Getter('listStatus')
  private readonly listStatus!: StudentReport.Getter.ListStatus

  private readonly prefixCls = 'student-compare-analysis'
  private inventedDataIndexArr: any = []
  private tParameter: any = { countType: 0 }
  private defaultData: any = []
  private currentValue = 0
  private parameter: any = {
    endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  }
  private searchParameter: any = {
    endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  }
  private sumKey: any = []
  private rowspans: any = {}

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  /** 无数据提示 */
  get noDataText() {
    if (this.dataSources.length === 0) {
      return this.$t('text.noDataText')
    }
    return
  }

  /** 表格列信息 */
  get tableColumns() {
    return getPageColumns('StudentCompareAnalysis')
      .map((item: any) => {
        return {
          title: item.title,
          key: item.code,
          fixed: 'left',
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat([
        {
          title: '在校生人数',
          key: 'schoolNum',
          align: 'center',
        },
        {
          title: '上学年同期在校生人数',
          key: 'lastSchoolNum',
          align: 'center',
        },
        {
          title: '增长人数',
          key: 'growNum',
          align: 'center',
        },
        {
          title: '进班点名人数',
          key: 'callNum',
          align: 'center',
        },
        {
          title: '当前VS进班点名',
          key: 'vs',
          align: 'center',
        },
      ])
  }
  /** 数据源 */
  get dataSources() {
    return this.getList('studentCompareAnalysis')
  }
  get getListStatus() {
    return this.listStatus('studentCompareAnalysis')
  }
  /** 请求状态 */
  get fetching() {
    return this.getListStatus.fetching === true
  }
  /** 请求错误信息 */
  get fetchingError() {
    return this.getListStatus.fetchingError
  }
  /** 导出状态 */
  get updating() {
    return this.getListStatus.updating === true
  }

  get updatingError() {
    return this.getListStatus.updatingError
  }
  /** 监听请求状态 */
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous !== fetching) {
      if (Boolean(this.fetchingError)) {
        return this.$Message.error(this.fetchingError as string)
      }

      let result = setReportData({
        list: this.dataSources,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
        countColumn: [
          'schoolNum',
          'lastSchoolNum',
          'growNum',
          'callNum',
          'vs',
        ],
      })

      this.inventedDataIndexArr = result.indexArr
      this.defaultData = result.dataArr
      this.rowspans = result.rowspans
    }
  }
  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      if (Boolean(this.updatingError)) {
        return this.$Message.error(this.updatingError as string)
      }
    }
  }

  /** 搜索 */
  private handleSearch(value: any) {
    if (Boolean(value) === false) {
      return
    }
    this.searchParameter = Object.assign({}, value)

    const { endDate = new Date() } = value
    this.fetchList({
      actionType: ActionType.Compare,
      type: 'studentCompareAnalysis',
      ...value,
      endDate: new Date(endDate).getTime(),
      countType: this.currentValue,
      columns: this.tableColumns.map((item: any) => item.key || ''),
    })
  }

  /** 导出 */
  private handleExport(value: any) {
    this.export(
      Object.assign({}, value, {
        actionType: ActionType.Compare,
        type: 'studentCompareAnalysis',
        countType: this.tParameter.countType,
        columns: this.sumKey,
      })
    ).then((res: any) => {
      if (res.result) {
        window.location.href = `${res.result}`
      }
    })
  }

  /** 切换统计形式 */
  private handleChangeType(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
      countColumn: ['schoolNum', 'lastSchoolNum', 'growNum', 'callNum', 'vs'],
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }

  /** 改变显示列 */
  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.handleSearch(this.searchParameter)
  }

  // 合并行列方法
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }
  /** 小计行的样式 */
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      if (row.hide) {
        return 'ivu-table-summary-row ivu-table-hide-row'
      }
      return 'ivu-table-summary-row'
    }
  }

  /** 请求数据 */
  private fetch() {
    if (Boolean(this.parameter) === false) {
      return
    }

    const { endDate = new Date() } = this.parameter

    this.fetchList({
      actionType: ActionType.Compare,
      type: 'studentCompareAnalysis',
      ...this.parameter,
      endDate: new Date(endDate).getTime(),
      countType: this.tParameter.countType,
      columns: this.tableColumns.map((item: any) => item.key || ''),
    })
  }

  /** 初始化 */
  created() {
    this.sumKey = getPageColumns('StudentCompareAnalysis').map(
      (item: any) => {
        return item.code
      }
    )
  }
}
