/**
 * 学籍操作记录页面路由
 */
import RouterViewLayout from '@layouts/router-view-layout'
import { RouteConfig } from 'vue-router'
import OperateRecordPage from '@pages/operate-record'
import StudentDetailPage from '@pages/student-detail'

const children: RouteConfig[] = []

// 操作记录列表展示
children.push({
  path: '/operate-record',
  name: 'operate-record-management',
  component: OperateRecordPage,
  meta: { ignore: true, authCode: '0508', parentAuthCode: '05' },
})

/** 学生详情 */
children.push({
  path: ':xuexinID/:unitID/:termID/detail',
  name: 'operate-record-student-detail',
  component: StudentDetailPage,
  meta: { ignore: true, authCode: '0508', parentAuthCode: '05' },
  props: { actionType: 'detail' },
})

export default {
  path: '/record',
  name: 'operate-record',
  redirect: { name: 'operate-record-management' },
  meta: { title: '学籍操作记录', authCode: '0508' },
  component: RouterViewLayout,
  props: { name: 'operate-record' },
  children,
}
