import Vue, { CreateElement, VNode } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Menu, Submenu as MenuSub, MenuItem, Icon } from 'view-design'
import './permissions-menu.scss'

const components = { Menu, MenuSub, MenuItem, Icon }
@Component({ name: 'PermissionsMenu', components, inheritAttrs: false })
export default class PermissionsMenu extends Vue {
  @Prop({ type: Array, default: () => [] }) private readonly menus!: any[]
  private readonly prefixCls: string = 'permissions-menu'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get props() {
    return Object.assign({ width: '208' }, this.$attrs)
  }

  render(createElement: CreateElement) {
    const children: VNode[] = []
    this.menus.forEach((menu, index) => {
      // sub-menu
      if (Array.isArray(menu.children) && menu.children.length > 0) {
        children.push(
          createElement(
            'MenuSub',
            { key: menu.authID, props: { name: menu.authCode } },
            [
              createElement('template', { slot: 'title' }, [
                menu.icon !== void 0
                  ? this.$createElement('Icon', { props: { type: menu.icon } })
                  : void 0,
                createElement(
                  'span',
                  { class: `${this.prefixCls}_text` },
                  menu.authName
                ),
              ]),
              ...menu.children.map((item: any) => {
                return this.createMenuItem(item)
              }),
            ]
          )
        )
      } else {
        // menu
        children.push(this.createMenuItem(menu))
      }
    })

    return createElement('div', { class: this.classes }, [
      createElement(
        'Menu',
        {
          props: this.props,
          on: {
            'on-select': this.handleSelect,
          },
        },
        children
      ),
    ])
  }

  private createMenuItem(menu: any): VNode {
    return this.$createElement(
      'MenuItem',
      {
        key: menu.authID,
        props: {
          name: menu.authCode,
          to: { name: menu.routeName },
        },
      },
      [
        menu.icon !== void 0
          ? this.$createElement('Icon', { props: { type: menu.icon } })
          : void 0,
        this.$createElement('span', { class: `${this.prefixCls}_text` }, [
          menu.authName,
        ]),
      ]
    )
  }

  private handleSelect() {
    // 切换菜单时，清除查询条件缓存
    sessionStorage.removeItem('parameter')
  }
}
