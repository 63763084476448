

























































import Vue from 'vue'
import { Component, Prop, Model, Ref, Watch } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import { Spin, Form, FormItem } from 'view-design'
import AppModal from '@components/app-modal'
import { ResearchFieldSelect } from '@business-components/research-field'
import { StudentMarkSelect } from '@business-components/student-mark'
import { isNonNull } from '@util'
import i18n from './i18n'

interface Data {
  termID: number
  unitID: number
  schoolDepartID: number
  phaseID: number
  classID: number
  xuexinID: string
  studentName: string
  studentMark: string
  researchField: string
  config: any[]
}

const components = {
  AppModal,
  Spin,
  Form,
  FormItem,
  StudentMarkSelect,
  ResearchFieldSelect,
}
@Component({ name: 'AcademicLabelModal', components, i18n })
export default class AcademicLabelModal extends Vue {
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Data
  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'academic-label-modal'
  private hidden = true
  private visible = false
  private loading = true
  private verifying = false
  private model: Partial<Student.Entity> = {}

  get updating() {
    return this.listStatus.updating
  }

  /** 当前值是否与初始化值不同 */
  get dirty() {
    return (
      this.model.researchField !== this.data.researchField ||
      this.model.studentMark !== this.data.studentMark
    )
  }

  get config() {
    return this.data?.config || []
  }

  get rules(): FormRules {
    const list = this.config.filter(item => {
      return (
        (item.columnCode === 'researchField' ||
          item.columnCode === 'studentMark') &&
        item.required === 1
      )
    })

    const rules = list.reduce<FormRules>((rules, item) => {
      rules[item.columnCode] = [
        {
          field: item.columnCode,
          trigger: 'change',
          required: true,
          validator: this.validator,
        },
      ]
      return rules
    }, {})

    return rules
  }

  get showStudentMark() {
    const item = this.config.find(item => {
      return item.columnCode === 'studentMark' && item.forceShow === 1
    })
    return item !== void 0
  }

  get showResearchField() {
    const item = this.config.find(item => {
      return item.columnCode === 'researchField' && item.forceShow === 1
    })
    return this.model.phaseID === 4 && item !== void 0
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (this.visible === true && updating === false && previous === true) {
      this.stopInteraction()

      /** 更新失败 */
      if (this.listStatus.updatingError !== null) {
        return this.$Message.error(this.listStatus.updatingError)
      }

      /** 通知父级元素，操作完成。 */
      return this.$emit('on-finish')
    }
  }

  private stopInteraction() {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)

    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleOk() {
    const { researchField, studentMark } = this.model
    this.$set(this.$data, 'verifying', true)

    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        /** 校验失败 */
        return this.stopInteraction()
      }

      /** 如果数据未变化，则直接关闭 */
      if (this.dirty === false) {
        return this.$emit('on-hidden')
      }

      /** 向后台发送请求 */
      this.update({
        actionType: Constant.ActionType.Labels,
        labels: [this.model],
      })
    })
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      const { config, ...model } = this.data
      /** 初始化 model */
      this.$set(this.$data, 'model', Object.assign({}, model))
      this.$set(this.$data, 'hidden', false)
    }

    this.$emit('on-visible-change', visible)
  }

  private handleHidden() {
    this.$emit('on-hidden')
    this.stopInteraction()
    this.$set(this.$data, 'hidden', true)
  }

  private validator(
    rule: FormRule,
    val: unknown,
    callback: FormRuleCallback
  ) {
    if (val === void 0 || val === null || val === '') {
      callback(`${this.$t(`messages.${rule.field}`)}`)
    }
    return callback()
  }
}
