import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'
import i18n, { StatisticalFormList } from './i18n'

const valueField = 'statisticalFormID'
const labelField = 'statisticalFormName'
const prefixCls = `statistical-form`

function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `StatisticalForm${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class StatisticalFormComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
    @Prop({ type: [String, Number] }) private readonly init!: string | number

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }
    get locale(): Locale {
      return this.$i18n.locale as Locale
    }

    get list() {
      return this.extra.concat(
        StatisticalFormList.map(value => {
          return {
            [labelField]: i18n.messages[this.locale][value],
            [valueField]: value,
            disabled: this.disabled,
          }
        })
      )
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          props.value = this.init
          break
        default:
          break
      }
      return props
    }

    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (type === ComponentType.Text) {
        children = [`${i18n.messages[this.locale][this.$attrs.value]}`]
      }
      return createElement(
        component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return StatisticalFormComponent
}

export const StatisticalFormSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
export const StatisticalFormText = createComponent('span', ComponentType.Text)
