var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.prefixCls},_vm._l((_vm.tabMap),function(item){return _c('div',{key:item.group,staticClass:"info"},[_c('div',{staticClass:"item_title"},[_c('p',[_vm._v(_vm._s(_vm.$t('text.familyMembers'))+_vm._s(_vm.numToCH(item.group)))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.receiveSms === 1),expression:"item.receiveSms === 1"}]},[_vm._v(" "+_vm._s(_vm.$t('text.receiveSms')))])]),_vm._v(" "),_vm._l((item.columns),function(ite,index){return (
        (ite.columnCode === 'politicalStatus' && _vm.isShow) ||
          (ite.columnCode !== 'politicalStatus' &&
            ite.columnCode !== 'parentPassportAddress' &&
            ite.columnCode !== 'parentPassportDate' &&
            ite.columnCode !== 'receiveSms')
      )?_c('div',{key:index,staticClass:"info_item"},[(ite.columnCode !== 'receiveSms')?_c('div',{staticClass:"item_content"},[(!(ite.columnCode === 'idInfo' && ite.value.length > 0))?_c('span',{staticClass:"key_item"},[_vm._v(_vm._s(ite.columnName)+":")]):_vm._e(),_vm._v(" "),(ite.columnCode === 'idInfo' && ite.value.length > 0)?_c('div',_vm._l((ite.value),function(idInfo){return _c('span',{key:idInfo.idNo,style:(("display: inline-block;" + (idInfo.mainFlag === 1 ? '' : 'margin-top:16px')))},[_c('span',{staticClass:"key_item"},[_vm._v(_vm._s(idInfo.mainFlag === 1 ? '主要' : '辅助')+"证件:")]),_vm._v(" "),_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(idInfo.idTypeName)+" - "+_vm._s(idInfo.idNo)+"\n\n              "+_vm._s([2, 3, 6].includes(idInfo.idType)
                  ? (" - 护照有效期:" + (idInfo.idDate) + " - 护照颁发地:" + (idInfo.idAddress))
                  : '')+"\n            ")])])}),0):(
            (_vm.type === 'detail' && !ite.children) ||
              (ite.children && ite.children.length === 0)
          )?_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(ite.valueName || _vm.$t('text.null')))]):(
            _vm.type === 'detail' &&
              ite.children &&
              _vm.nullValue(ite.children) < ite.children.length
          )?_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(ite.valueName)+"\n          "),_vm._l((ite.children),function(itemm,index){return (itemm.valueName !== null)?_c('span',{key:ite.columnCode},[_c('span',{staticClass:"second"},[_vm._v(_vm._s(itemm.valueName || ''))])]):_vm._e()})],2):(
            _vm.type === 'detail' &&
              ite.children &&
              _vm.nullValue(ite.children) === ite.children.length
          )?_c('span',{staticClass:"value_item"},[_vm._v("\n          "+_vm._s(ite.valueName || _vm.$t('text.null'))+"\n        ")]):_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(_vm.$t('text.null')))])]):_vm._e()]):_vm._e()})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }