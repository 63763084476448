import { Constant } from 'xuexin-vuex'

export type TransferReason = Constant.TransferReason
export const TransferReason = Constant.TransferReason

export const TransferReasonList = [
  TransferReason.Transfer,
  TransferReason.QuitSchool,
  TransferReason.Immigrant,
  TransferReason.Economic,
  TransferReason.MajorDisease,
  TransferReason.Death,
  TransferReason.StudyAbroad,
  TransferReason.Other,
  TransferReason.TrialGiveUp,
  TransferReason.HealthReason,
  TransferReason.FamilyReason,
  TransferReason.SchoolStatusReason,
  TransferReason.PersuadeQuit,
  TransferReason.ShortTerm,
  TransferReason.Inadaptation,
  TransferReason.PolicyLiquidation,
  TransferReason.ForeignNationality,
  TransferReason.DirectionChange,
]

export const messages: Messages = {
  'zh-CN': {
    [TransferReason.Transfer]: '转学',
    [TransferReason.QuitSchool]: '勒令退学',
    [TransferReason.Immigrant]: '出国/移民',
    [TransferReason.Economic]: '经济原因',
    [TransferReason.MajorDisease]: '重大疾病',
    [TransferReason.Death]: '死亡',
    [TransferReason.StudyAbroad]: '出国留学',
    [TransferReason.Other]: '其它',
    [TransferReason.TrialGiveUp]: '试读放弃',
    [TransferReason.HealthReason]: '健康原因',
    [TransferReason.FamilyReason]: '家庭原因',
    [TransferReason.SchoolStatusReason]: '学籍原因',
    [TransferReason.PersuadeQuit]: '劝退（违纪、成绩等）',
    [TransferReason.ShortTerm]: '短期入学',
    [TransferReason.Inadaptation]: '不适应/不满',
    [TransferReason.PolicyLiquidation]: '政策性清退',
    [TransferReason.ForeignNationality]: '外籍港澳台',
    [TransferReason.DirectionChange]: '学业方向变更',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [TransferReason.Transfer]: '转学',
    [TransferReason.QuitSchool]: '勒令退学',
    [TransferReason.Immigrant]: '出国/移民',
    [TransferReason.Economic]: '经济原因',
    [TransferReason.MajorDisease]: '重大疾病',
    [TransferReason.Death]: '死亡',
    [TransferReason.StudyAbroad]: '出国留学',
    [TransferReason.Other]: '其它',
    [TransferReason.TrialGiveUp]: '试读放弃',
    [TransferReason.HealthReason]: '健康原因',
    [TransferReason.FamilyReason]: '家庭原因',
    [TransferReason.SchoolStatusReason]: '学籍原因',
    [TransferReason.PersuadeQuit]: '劝退（违纪、成绩等）',
    [TransferReason.ShortTerm]: '短期入学',
    [TransferReason.Inadaptation]: '不适应/不满',
    [TransferReason.PolicyLiquidation]: '政策性清退',
    [TransferReason.ForeignNationality]: '外籍港澳台',
    [TransferReason.DirectionChange]: '学业方向变更',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
