import { Constant } from 'xuexin-vuex'

export type SubjectType = Constant.TrialReadStatus
export const TrialReadStatus = Constant.TrialReadStatus

export const TrialReadStatusList = [
  TrialReadStatus.NotDone,
  TrialReadStatus.InProgress,
  TrialReadStatus.Finished,
]

export const messages: Messages = {
  'zh-CN': {
    [`${TrialReadStatus.NotDone}`]: '未进行',
    [`${TrialReadStatus.InProgress}`]: '进行中',
    [`${TrialReadStatus.Finished}`]: '已结束',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${TrialReadStatus.NotDone}`]: '未进行',
    [`${TrialReadStatus.InProgress}`]: '进行中',
    [`${TrialReadStatus.Finished}`]: '已结束',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
