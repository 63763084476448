import Vue from 'vue'
import { LoadingBar } from 'view-design'
import { Session } from 'xuexin-vuex'
import { Store } from 'vuex'
import VueRouter, { Route, Location } from 'vue-router'
Vue.use(VueRouter)

/** 通用页面 */
import AppLayout from '@layouts/app-layout'
import LoginPage from '@pages/login'
import ErrorPage from '@pages/error'
import AuthPage from '@pages/auth'
import DashboardPage from '@pages/dashboard'
import PasswordResetPage from '@pages/password-reset'

/** 年级管理页面 */
import GradeRoute from './grade-management'

/** 班级管理页面 */
import GlassRoute from './glass-management'

/** 学期注册 */
import SemesterRegistrationRoute from './semester-registration'

/** 分班管理 */
import DivideClassRoute from './divide-class'
/** 新招学生 */
import NewStudentsRoute from './new-student'

/** 学生管理 */
import StudentManagementRoute from './students'

/** 学籍操作记录 */
import OperateRecordRoute from './operate-record'

/** 信息设置 */
import InformationSetup from './information-setup'

/** 统计报表 */
import statisticReports from './statistic-reports'

/** 错误页面 */
import errorRoute from './error'

import { encryptToBase64 } from '@util'

// 不做页面展示的菜单
export const ignoreConfig: Record<string, boolean> = {}

export const routeConfig: Record<string, any> = {
  /** 首页 */
  '0501': { name: 'dashboard' },
  /** 年级管理 */
  '0502': { name: 'grade-management' },
  /** 班级管理 */
  '0503': { name: 'glass-management' },
  /** 学期注册 */
  '0504': { name: 'semester-registration' },
  /** 新招学生 */
  '0510': { name: 'new-student' },
  /** 分班管理 */
  '0505': { name: 'divide-class' },
  /** 学生管理 */
  '0506': { name: 'student-management' },
  '050601': { name: 'in-class' } /** 在班学生 */,
  '050602': { name: 'transfer-students' } /** 转学学生 */,
  '050603': { name: 'graduation' } /** 毕业学生 */,
  '050604': { name: 'trial-reading' } /** 试读学生 */,
  '050605': { name: 'short-term' } /** 短期入学学生 */,
  '050606': { name: 'inner-transfer' } /** 校际互转学生 */,
  '050607': { name: 'inside-transfer' } /** 内转转学生 */,

  /** 学籍操作记录 */
  '0508': { name: 'operate-record' },
  /** 信息设置 */
  '0509': { name: 'info-setup' },
  '050901': { name: 'info-setup-abroad' } /** 出国方向 */,
  '050902': { name: 'info-setup-school-class-type' } /** 校本班型 */,
  '050903': { name: 'info-setup-term-post' } /** 学期注册设置 */,
  '050904': { name: 'info-setup-trial-reading' } /** 试读期限设置 */,
  '050905': { name: 'info-setup-student-info' } /** 学生信息设置 */,
  '050906': { name: 'info-setup-config-management' } /** 配置管理 */,
  '050907': { name: 'info-setup-add-class' } /** 开班设置 */,

  /** 统计报表 */
  '0507': { name: 'reports' },
  '050701': { name: 'school-overview' } /** 学校总览表 */,
  '050702': { name: 'student-overview' } /** 学生总览表 */,
  '050703': { name: 'student-abnormal-change' } /** 学生异动统计表 */,
  '050704': { name: 'student-count-monthly' } /** 学生人数月统计表 */,
  '050705': { name: 'student-compare-analysis' } /** 在校生人数对比分析 */,
  '050706': { name: 'classes-count' } /** 班级数量统计 */,
  '050714': { name: 'classes-count-monthly' } /** 班级数量月统计 */,
  '050707': { name: 'classes-compare-analysis' } /** 班级数量对比分析 */,
  '050708': { name: 'actual-loss-rate' } /** 实际流失率统计 */,
  '050709': { name: 'assess-loss-rate' } /** 考核流失率统计 */,
  '050710': { name: 'loss-compare-analysis' } /** 流失人数统计对比分析 */,
  '050711': { name: 'loss-rate-compare-analysis' } /** 流失率统计对比分析 */,
  '050712': { name: 'student-insert' } /** 插班生人数统计 */,
  '050713': { name: 'student-distribution' } /** 学生分布统计 */,
  '050715': { name: 'class-payment' } /** 班级缴费统计 */,
  '050718': { name: 'enroll-pay-data' } /** 学生人数统计 */,
}

export function createMenus(auths: Session.Auth[]) {
  const tree: Record<string, any> = {}
  let rootKey: string | null = null

  auths.forEach(item => {
    // 不显示忽略的菜单
    if (ignoreConfig[item.authCode] === true) {
      return void 0
    }

    const childKey = `${item.authID}`
    const parentKey = `${item.parentID}`

    // 确认根节点 ID
    if (rootKey === null && parentKey === '-1') {
      rootKey = childKey
    }

    // 若当前树节点未定义，则初始化树节点
    if (tree[childKey] === void 0) {
      tree[childKey] = Object.assign({}, item, { children: [] })
    }

    // 若当前树节点非自己初始化(仅包含 children 和 isNull 属性)，则添加其它属性
    if (tree[childKey].isNull === true) {
      const children = tree[childKey].children
      tree[childKey] = Object.assign({}, item, { children })
    }

    // 添加路由信息
    if (routeConfig[item.authCode] !== void 0) {
      tree[childKey].icon = routeConfig[item.authCode].icon
      tree[childKey].routeName = routeConfig[item.authCode].name
    }

    // 若当前树节点的父节点未定义，则初始化一个父级树节点
    if (tree[parentKey] === void 0) {
      tree[parentKey] = { isNull: true, children: [] }
    }

    // 将当前树节点，添加到父级树节点中
    tree[parentKey].children.push(tree[childKey])
  })

  return rootKey !== null && tree[rootKey] !== void 0
    ? tree[rootKey].children
    : []
}

function cloneLocation(location: Route, obj: Location): Location {
  return {
    name: obj.name,
    hash: location.hash,
    query: { link: encryptToBase64(location.fullPath, 'link-url') },
    params: location.params,
    replace: typeof obj.replace === 'boolean' ? obj.replace : true,
  }
}

export function createRoute(store: Store<any>): VueRouter {
  const sessionState = store.state[Session.namespace] as Session.State
  const whitelist = ['login', 'auth', 'password-reset', 'error']

  // prettier-ignore
  const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
      /** 认证页面 */
      { path: '/auth', name: 'auth', component: AuthPage, meta: {} },
      /** 错误页面 */  
      { path: '/error', name: 'error', component: ErrorPage, meta: {} },
      /** 登录页面 */
      { path: '/login', name: 'login', component: LoginPage },
      /** 密码重置页面 */
      { path: '/password_reset', name: 'password-reset', component: PasswordResetPage },
      {
        path: '/',
        component: AppLayout,
        meta: { ignore: true },
        children: [
          /** 首页 */
          { path: '/', name: 'dashboard', component: DashboardPage, meta: { title: '首页', authCode: '0501' } },
          /** 年级 */
          GradeRoute,
          /** 班级 */
          GlassRoute,
          /** 学期注册 */
          SemesterRegistrationRoute,
          /**新招学生 */
          NewStudentsRoute,
          /** 分班管理 */
          DivideClassRoute,
          /** 学生管理 */
          StudentManagementRoute,
          /** 学籍操作记录 */
          OperateRecordRoute,
          /** 信息设置 */
          InformationSetup,
          /** 统计报表 */
          statisticReports
        ],
      },
    ],
  })

  router.beforeEach((to, from, next) => {
    LoadingBar.start()

    /** 目的地为空或者未定义时，跳转到 `认证` 界面。 */
    if (to.name === null || to.name === void 0) {
      return next(cloneLocation(to, { name: 'auth' })), LoadingBar.finish()
    }

    /** 目的为白名单列表时，直接放通。 */
    if (whitelist.includes(to.name)) {
      return next(), LoadingBar.finish()
    }

    // 未登录时，跳转到 `认证` 界面。
    if (sessionState.data === null || sessionState.data === void 0) {
      return next(cloneLocation(to, { name: 'auth' })), LoadingBar.finish()
    }

    /** 来源为空或者未定义时，跳转到`认证`界面。 */
    if (from.name === null || from.name === void 0) {
      return next(cloneLocation(to, { name: 'auth' })), LoadingBar.finish()
    }

    /** 其它情况放通路由 */
    next()
  })

  router.afterEach(() => {
    LoadingBar.finish()
  })

  return router
}

export default createRoute
