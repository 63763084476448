







































































































































































import Vue from 'vue'
import { Component, Ref, Watch } from 'vue-property-decorator'
import {
  Button,
  Checkbox,
  Tabs,
  TabPane,
  Icon,
  Radio,
  RadioGroup,
} from 'view-design'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import InformationSetup from '@store/modules/information-setup'
import AddEditModal from './components/addEditModal'
import { Session } from 'xuexin-vuex'
import Page from '@store/modules/page'
import PageTyping from '../../../../types/page'
import i18n from './i18n'

const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  Checkbox,
  Tabs,
  TabPane,
  Icon,
  AddEditModal,
  Radio,
  RadioGroup,
}

@Component({ name: 'StudentInfoPage', components, i18n })
export default class StudentInfoPage extends Vue {
  @Ref('toolbar') private readonly toolbar!: DynamicToolbar
  @Page.Action('fetch') private readonly fetchAuth!: PageTyping.Action.Fetch
  @Page.Getter('item') private readonly getItem!: PageTyping.Getter.Item
  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  @InformationSetup.Getter('list')
  private readonly list!: InformationSetup.Getter.List
  @InformationSetup.Getter('listStatus')
  private readonly listStatus!: InformationSetup.Getter.ListStatus
  @InformationSetup.Action('fetch')
  private readonly fetch!: InformationSetup.Action.Fetch
  @InformationSetup.Action('update')
  private readonly update!: InformationSetup.Action.Update
  @InformationSetup.Action('delete')
  private readonly delete!: InformationSetup.Action.Delete
  private readonly prefixCls = 'student-info-page'
  private active = 1
  private dataList: any = []
  private curColumn: InformationSetup.Entity = {}
  private showAddEdit = false
  private modalType = ''

  // 列表加载状态
  get loading() {
    return this.getListStatus.fetching
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get authCode() {
    return this.$route.meta.authCode
  }
  get organID() {
    return this.getUser ? this.getUser.organID : -1
  }

  get identityType() {
    return this.getUser?.identityType
  }

  get getSelectList() {
    return [
      { label: this.$t('must-fill'), value: 1 },
      { label: this.$t('maybe-fill'), value: 0 },
    ]
  }

  // 获取列表数据
  get getList() {
    return this.list('student')
  }
  // 获取列表状态
  get getListStatus() {
    return this.listStatus('student')
  }
  // 监听加载状态
  @Watch('getListStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.getListStatus.fetchingError !== null &&
        this.getListStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.fetchingError)
        return
      }
      this.filterKindName(this.getList)
    }
  }
  // 监听更新状态
  @Watch('getListStatus.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (!updating && previous) {
      if (
        this.getListStatus.updatingError !== null &&
        this.getListStatus.updatingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.updatingError)
        return
      }
      this.$Message.success(this.$t('message-tips'))
    }
  }
  // 监听删除状态
  @Watch('getListStatus.deleting')
  private watchDeleting(deleting: boolean, previous: boolean) {
    if (!deleting && previous) {
      if (
        this.getListStatus.deletingError !== null &&
        this.getListStatus.deletingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.deletingError)
        return
      }
      this.$Message.success(this.$t('message.delete-success'))
      this.refresh()
    }
  }
  // 初始化页面
  private created() {
    this.fetchAuth({ authCode: this.authCode, organID: this.organID })
    this.fetch({ direction: 1, type: 'student' })
  }

  // 判断权限按钮中是否包含某个按钮
  private isHasButton(code: string) {
    const btnList = this.getItem(this.authCode)?.auths
    if (btnList === void 0) {
      return false
    }
    return btnList.some(item => {
      return item.code === code
    })
  }

  // 切换tab 标签
  private handleTabs(val: string): void {
    this.active = parseInt(val)
    this.fetch({ direction: parseInt(val), type: 'student' })
  }

  // 刷新
  private refresh() {
    this.fetch({ direction: this.active, type: 'student' })
  }

  // 添加弹窗显示事件
  private handleAdd(column: InformationSetup.Entity, type: string) {
    this.modalType = type
    if (type === 'add') {
      this.$set(this.$data, 'curColumn', {
        kindCode: column.kindCode,
        columnCode: '',
      })
    } else {
      this.$set(this.$data, 'curColumn', Object.assign({}, column))
    }

    this.showAddEdit = true
  }

  // 删除确认事件
  private handleDelete(column: InformationSetup.Entity) {
    let _this = this
    this.$Modal.confirm({
      title: this.$t('message.delete') as string,
      content: `<p>${this.$t('message.confirm-delete')}</p>`,
      onOk: () => {
        _this.delete({ type: 'student', extendID: column.extendID })
      },
    })
  }

  //保存事件
  private handleSave(): void {
    this.update({
      type: 'student',
      fields: this.dataList,
      direction: this.active,
      editType: 'save',
    })
  }

  //过滤分类名称
  private filterKindName(data: any) {
    let arr: any = data.slice(0),
      subItems = arr.filter((item: any) => {
        return !item.isSubitem
      }),
      yesLastIDs: any = []

    // 获取需设置yesLast属性的字段id
    subItems.forEach((item: any, index: number) => {
      if (
        index < subItems.length - 1 &&
        subItems[index].kindCode !== subItems[index + 1].kindCode
      ) {
        yesLastIDs.push(item.extendID)
      }
    })
    yesLastIDs.push(subItems[subItems.length - 1].extendID)

    // 设置yesLast属性
    arr.forEach((item: any) => {
      item.noFirst = false
      item.yesLast = false
    })
    data.forEach((item: any, index: number) => {
      if (
        index < arr.length - 1 &&
        arr[index].kindCode === arr[index + 1].kindCode
      ) {
        arr[index + 1].noFirst = true
      }
      if (yesLastIDs.includes(item.extendID)) {
        arr[index].yesLast = true
      }
    })
    arr[arr.length - 1].yesLast = true

    this.dataList = arr
  }

  //移动
  private handleMove(
    item: InformationSetup.Entity,
    index: number,
    sort: number,
    direction: string
  ): void {
    if (direction === 'up') {
      if (index > 0 && item.kindCode === this.dataList[index - 1].kindCode) {
        let _groupID = item.groupID,
          curGroup: any = [],
          _prevGroupID = this.dataList[index - 1].groupID,
          prevGroup: any = []

        if (!_groupID) {
          curGroup.push(item)
        } else {
          curGroup = this.dataList.filter((item: any) => {
            return item.groupID === _groupID
          })
        }

        if (!_prevGroupID) {
          prevGroup.push(this.dataList[index - 1])
        } else {
          prevGroup = this.dataList.filter((item: any) => {
            return item.groupID === _prevGroupID
          })
        }

        const arr = this.dataList.splice(index, curGroup.length)

        this.dataList.splice(index - prevGroup.length, 0, ...arr)
      } else {
        return
      }
    } else {
      let _groupID = item.groupID,
        curGroup: any = [],
        _nextGroupID = -1,
        nextGroup: any = []

      if (!_groupID) {
        curGroup.push(item)
        _nextGroupID = this.dataList[index + 1].groupID
      } else {
        curGroup = this.dataList.filter((item: any) => {
          return item.groupID === _groupID
        })
        _nextGroupID = this.dataList[index + curGroup.length].groupID
      }

      if (
        index < this.dataList.length - 1 &&
        item.kindCode === this.dataList[index + curGroup.length].kindCode
      ) {
        if (!_nextGroupID) {
          nextGroup.push(this.dataList[index + curGroup.length])
        } else {
          nextGroup = this.dataList.filter((item: any) => {
            return item.groupID === _nextGroupID
          })
        }

        const arr = this.dataList.splice(
          index + curGroup.length,
          nextGroup.length
        )

        this.dataList.splice(index, 0, ...arr)
      }
    }
    this.dataList.forEach((item: any, index: number) => {
      item.sort = index
    })

    this.filterKindName(this.dataList)
  }
}
