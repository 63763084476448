import { Constant } from 'xuexin-vuex'

export type FlowStatus = Constant.FlowStatus
export const FlowStatus = Constant.FlowStatus

export const FlowStatusList = [
  /** 正常 */
  FlowStatus.Normal,
  /** 试读 */
  FlowStatus.TrialReading,
  /** 校际互转 */
  FlowStatus.InterSchoolTransfer,
  /** 转学 */
  FlowStatus.Transfer,
  /** 短期 */
  FlowStatus.ShortTerm,
  /** 转试 */
  FlowStatus.TransferTrial,
  /** 转短 */
  FlowStatus.TransferShort,
  /** 校短 */
  FlowStatus.SchoolShort,
  /** 校试 */
  FlowStatus.SchoolTrial,
]

export const messages: Messages = {
  'zh-CN': {
    [`${FlowStatus.Normal}`]: '正常',
    [`${FlowStatus.TrialReading}`]: '试读',
    [`${FlowStatus.InterSchoolTransfer}`]: '校际互转',
    [`${FlowStatus.Transfer}`]: '转学',
    [`${FlowStatus.ShortTerm}`]: '短期',
    [`${FlowStatus.TransferTrial}`]: '转试',
    [`${FlowStatus.TransferShort}`]: '转短',
    [`${FlowStatus.SchoolShort}`]: '校短',
    [`${FlowStatus.SchoolTrial}`]: '校试',
    to: '至',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${FlowStatus.Normal}`]: '正常',
    [`${FlowStatus.TrialReading}`]: '试读',
    [`${FlowStatus.InterSchoolTransfer}`]: '校际互转',
    [`${FlowStatus.Transfer}`]: '转学',
    [`${FlowStatus.ShortTerm}`]: '短期',
    [`${FlowStatus.TransferTrial}`]: '转试',
    [`${FlowStatus.TransferShort}`]: '转短',
    [`${FlowStatus.SchoolShort}`]: '校短',
    [`${FlowStatus.SchoolTrial}`]: '校试',
    to: '至',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
