











import Vue from 'vue'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
import ECharts from '@libs/echarts'

const i18n = {
  messages: {
    'zh-CN': {
      text: {
        studentNum: '学生人数',
        turnoverRate: '流失率',
        noData: '暂无数据',
      },
    },
    'en-US': {},
  },
}

@Component({ name: 'HomeECharts', i18n })
export default class HomeECharts extends Vue {
  /** 图表类型 */
  @Prop(String) private type!:
    | 'school'
    | 'schoolDepart'
    | 'unitSchoolDepart'
    | 'manageType'
    | 'lostStatis'
  /**  纵坐标数据 */
  @Prop({ type: Array }) private readonly series!: any[]
  /**  横坐标数据 */
  @Prop({ type: Array }) private readonly xAxisData!: string[]
  /** 颜色 */
  @Prop({
    type: Array,
    default: () => ['#2c74cd', '#FFC924', '#4966FB', '#11D586'],
  })
  private readonly color!: string[]
  /** 展示类型 */
  @Prop({ type: Array }) private readonly legend?: string[]

  @Ref('echart-dom') private readonly dom!: HTMLDivElement

  private echart: echarts.ECharts | null = null

  mounted() {
    if (
      (!this.series && !this.xAxisData) ||
      this.series?.length < 1 ||
      this.xAxisData?.length < 1
    ) {
      return void 0
    }
    this.echart = ECharts.init(this.dom)
    this.refresh()
  }

  private refresh() {
    if (this.echart === null) {
      return void 0
    }

    /** 显示类型 */
    const legend = this.legend
      ? {
          right: 100,
          top: 0,
          itemGap: 10,
          itemWidth: 10,
          itemHeight: 10,
          data: this.legend,
          borderRadius: 2,
        }
      : {}

    /** 显示介绍 */
    const tooltip: ECharts.EChartOption.Tooltip = {
      trigger: 'axis',
      textStyle: {
        lineHeight: 1.5,
        // align: 'left',
      },
      padding: 10,
      backgroundColor: 'rgba(0,0,0,.8)',
      extraCssText: 'text-align:left;',
    }

    /** 纵坐标显示项 */
    const yAxis: any[] = []

    /** 显示数据定义 */
    let series: any[] = []

    switch (this.type) {
      case 'school':
        yAxis.push({
          type: 'value',
          name: this.$t('text.studentNum') as string,
          nameTextStyle: {
            fontSize: 14,
            color: '#8A8E96',
            padding: [0, 0, 0, -60],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
          axisLabel: {
            color: '#8A8E96',
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
        })
        series.push({ type: 'bar', data: this.series, barMaxWidth: 20 })
        tooltip.formatter = (params: any): any => {
          return `${params[0].name}<br/>${params[0].marker}${this.$t(
            'text.studentNum'
          ) as string}：${params[0].value}人`
        }
        break
      case 'unitSchoolDepart':
        yAxis.push({
          type: 'value',
          name: this.$t('text.studentNum') as string,
          nameTextStyle: {
            fontSize: 14,
            color: '#8A8E96',
            padding: [0, 0, 0, -60],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
          axisLabel: {
            color: '#8A8E96',
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
        })
        series = [
          {
            type: 'bar',
            barMaxWidth: 20,
            data: this.series,
          },
        ]
        tooltip.formatter = (params: any): any => {
          return `${params[0].name}<br/>${params[0].marker}${this.$t(
            'text.studentNum'
          ) as string}：${params[0].value}人`
        }
        break
      case 'schoolDepart':
      case 'manageType':
        yAxis.push({
          type: 'value',
          name: this.$t('text.studentNum') as string,
          nameTextStyle: {
            fontSize: 14,
            color: '#8A8E96',
            padding: [0, 0, 0, -60],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
          axisLabel: {
            color: '#8A8E96',
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
        })
        series = this.series.map(item => {
          return {
            type: 'bar',
            barMaxWidth: 20,
            name: `${item[`${this.type}Name`]}`,
            data: item.data,
          }
        })
        tooltip.formatter = (params: any): any => {
          let res = `${params[0].name}<br/>`
          params.forEach((item: any, index: number) => {
            res += `${params[index].marker}${params[index].seriesName}：${params[index].value}人<br/>`
          })
          return res
        }
        break
      case 'lostStatis':
        yAxis.push(
          {
            type: 'value',
            name: this.$t('text.studentNum') as string,
            nameTextStyle: {
              fontSize: 14,
              color: '#8A8E96',
              padding: [0, 0, 0, -60],
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#D9DCE1',
              },
            },
            axisLabel: {
              color: '#8A8E96',
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#D9DCE1',
              },
            },
          },
          {
            type: 'value',
            name: this.$t('text.turnoverRate') as string,
            axisLabel: { formatter: '{value}%', color: '#8A8E96' },
            nameTextStyle: {
              fontSize: 14,
              color: '#8A8E96',
              padding: [0, -60, 0, 0],
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#D9DCE1',
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#D9DCE1',
              },
            },
          }
        )
        series = this.series?.map((item, index) => {
          return {
            type: index === 2 ? 'line' : 'bar',
            name: item.name,
            barMaxWidth: 20,
            lineStyle: {
              width: index === 2 ? 3 : undefined,
            },
            stack: index === 2 ? undefined : this.series[0].name,
            yAxisIndex: index === 2 ? 1 : undefined,
            data: item.data,
          }
        })
        tooltip.formatter = (params: any): any => {
          let res = `${params[0].name}<br/>`
          params.forEach((item: any, index: number) => {
            if (index === params.length - 1) {
              return (res += `${params[index].marker}${params[index].seriesName}：${params[index].value}%<br/>`)
            }
            res += `${params[index].marker}${params[index].seriesName}：${params[index].value}人<br/>`
          })
          return res
        }
        legend.right = undefined
        break
    }
    this.echart.setOption(
      {
        color: this.color,
        legend,
        grid: {
          containLabel: true,
          left: '13%',
        },
        tooltip,
        xAxis: [
          {
            type: 'category',
            data: this.xAxisData || [],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: '#8A8E96',
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#D9DCE1',
              },
            },
          },
        ],
        yAxis,
        series,
      },
      true
    )
  }

  @Watch('series')
  private watchSeriesChange() {
    this.refresh()
  }
}
