import Vue from 'vue'
import { DateUtil } from '@util'

/**
 * 时间转换指令
 */
Vue.directive('dateFormat', function(el, binding) {
  const valueType = typeof binding.value
  if (valueType === 'string' || valueType === 'number') {
    el.innerHTML = DateUtil.format(binding.value)
  } else {
    const { value, format } = binding.value
    el.innerHTML = DateUtil.format(value, format)
  }
})
