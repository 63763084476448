




















































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import AppSelect from '@components/app-select'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import ViewLayout from '@layouts/view-layout'
import i18n from './i18n'
import { User, Constant } from 'xuexin-vuex'
import Records from '@store/modules/records'
import SuccessModal from './success-modal'
import FailureModal from './failure-modal'
import { DatePicker, Input, Button } from 'view-design'

const components = {
  ViewLayout,
  AppSelect,
  AppTable,
  AppPagination,
  SuccessModal,
  FailureModal,
  DatePicker,
  Input,
  Button,
}

@Component({ name: 'SmsRecrdPage', components, i18n })
export default class SmsRecrdPage extends Vue {
  @Records.Action('fetchList')
  private readonly fetchList!: Records.Action.FetchList
  @User.Action('cancelSend')
  private readonly cancelSend!: User.Action.CancelSend
  @User.Getter('itemStatus')
  private readonly smsItemStatus!: User.Getter.ItemStatus
  @Records.Getter('list') private readonly list!: Records.Getter.List
  @Records.Getter('listStatus')
  private readonly listStatus!: Records.Getter.ListStatus
  @Records.Getter('item') private readonly getItem!: Records.Getter.Item
  @Records.Getter('itemStatus')
  private readonly itemStatus!: Records.Getter.ItemStatus

  @Prop({ type: Number }) private readonly actionType!: number

  private readonly prefixCls = 'sms-record-page'
  private modal: { name: string | null; data: any } = { name: null, data: {} }
  private recordID: number = -1
  private status: string = '-1'
  private page = 1
  private pageSize = 10
  private operator: string = ''
  private sendDate: string[] = []
  private startDate = null
  private endDate = null

  get query() {
    return this.$route.query
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get unitID() {
    return this.query.unitID
  }

  get pageType() {
    switch (this.actionType) {
      case Constant.ActionType.InClass /** 在班 */:
        return 0
      case Constant.ActionType.Transfer /** 转学 */:
        return 1
      case Constant.ActionType.InnerTransfer /** 校际互转 */:
        return 2
      case Constant.ActionType.Divide /** 分班 */:
        return 3
      case Constant.ActionType.Graduation /** 毕业 */:
        return 4
      case Constant.ActionType.Glass /** 班级 */:
        return 5
      case Constant.ActionType.Trial /** 试读 */:
        return 6
      case Constant.ActionType.ShortTerm /** 短期 */:
        return 7
      case Constant.ActionType.Register /**学期注册 */:
        return 8
    }
  }

  get tableData() {
    return this.list.map((item, index) => {
      let record = Object.assign({}, item)
      record.num = (this.page - 1) * this.pageSize + index + 1
      return record
    })
  }

  get tableColumns() {
    // prettier-ignore
    return [
      { title:this.$t('table.index'), key: 'num', width: 80, align: 'center', fixed: 'left' },
      { title: this.$t('table.sendDate'), slot: 'sendDate', width: 180 },
      { title: this.$t('table.msg'), slot: 'msg', type: 'string', minWidth: 250 },
      { title: this.$t('table.autograph'), slot: 'autograph', width: 140, ellipsis: true },
      { title: this.$t('table.successCount'), slot: 'successCount', width: 80 },
      { title: this.$t('table.failureCount'), slot: 'failureCount', width: 80 },
      { title: this.$t('table.createDate'), slot: 'createDate', width: 180 },
      { title: this.$t('table.status'), slot: 'status', width: 100 },
      { title: this.$t('table.operatorInfo'), key: 'operatorInfo', align: 'center', width: 220 },
      { title: this.$t('table.operate'), slot: 'operate', align: 'center', fixed: 'right', width: 100 },
    ]
  }

  get selectOption() {
    return [
      { value: '-1', label: this.$t('status.all') },
      { value: '0', label: this.$t('status.unsend') },
      { value: '1', label: this.$t('status.send') },
      { value: '2', label: this.$t('status.cancel') },
    ]
  }

  get noDataText() {
    if (this.list.length !== 0) {
      return ''
    }
    return this.$t('text.noDataText')
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get fetchingError() {
    return this.listStatus.fetchingError || null
  }

  get updating() {
    return this.smsItemStatus({ recordID: this.recordID }).updating
  }

  get updatingError() {
    return (
      this.smsItemStatus({ recordID: this.recordID }).updatingError || null
    )
  }

  get loading() {
    return this.fetching !== false
  }

  get total() {
    return this.listStatus.total || this.list.length
  }

  get parameter() {
    return {
      sender: this.operator /**发送人 */,
      sendStatus: this.status /**发送状态 */,
      startDate: this.startDate ? this.startDate : '' /** 开始时间 */,
      endDate: this.endDate ? this.endDate : '' /** 结束时间 */,
      unitID: this.unitID /** 校区ID */,
      // searches: this.query /** 查询参数，暂时后端不需要 */,
    }
  }

  @Watch('fetching', { immediate: true })
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.fetchingError !== null) {
        return this.$Message.error(this.fetchingError)
      }
    }
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      if (this.updatingError !== null) {
        return this.$Message.error(this.updatingError)
      }
      this.$Message.success(this.$t('text.success'))
      this.fetch()
    }
  }

  /** 设置查询日期 */
  private handleDateChange(value: any) {
    this.$set(this.$data, 'startDate', new Date(value[0]).getTime())
    this.$set(this.$data, 'endDate', new Date(value[1]).getTime())
  }

  /** 非空验证 */
  private valid(filed: any) {
    return filed === void 0 || filed === null || filed === '' || filed === -1
  }

  /** 打开窗口 */
  private handleClick(action: string, row: Records.Entity) {
    switch (action) {
      case 'back':
        this.$router.go(-1)
        break
      case 'search' /** 搜索 */:
        this.$set(this.$data, 'page', 1)
        this.fetch()
        break
      case 'copy' /** 复制短信内容 */:
        {
          const input = document.createElement('input')
          document.body.appendChild(input)
          input.setAttribute('value', row.msg)
          input.select()
          if (document.execCommand('copy')) {
            document.execCommand('copy')
            this.$Message.success(`${this.$t('text.copy')}`)
          }
          document.body.removeChild(input)
        }
        break
      case 'success-count' /** 成功数据查看 */:
        this.$set(this.$data, 'modal', {
          name: 'success-count',
          data: {
            recordID: row.recordID,
          },
        })
        break
      case 'failure-count' /** 失败数据查看 */:
        this.$set(this.$data, 'modal', {
          name: 'failure-count',
          data: Object.assign({}, this.query, {
            isSms: true,
            searches: this.query,
            recordID: row.recordID,
            actionType: this.actionType,
            pageType: this.pageType,
          }),
        })
        break
      case 'cancel' /** 取消发送 */:
        this.$set(this.$data, 'recordID', row.recordID)
        this.$Modal.confirm({
          title: this.$t('text.title') as string,
          content: this.$t('text.content') as string,
          onOk: () => {
            this.cancelSend({ recordID: row.recordID })
          },
        })
        break
      default:
        console.log(`${this.$options.name}.handleClick(${action})`)
        break
    }
  }

  /** 窗口功能完成 */
  private handleModalFinish(modal: string) {
    switch (modal) {
      case 'success-count':
        break
      case 'success-count':
        break
    }
  }

  /** 窗口关闭 */
  private handleModalHidden() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }

  created() {
    this.fetch()
  }

  /** 请求数据 */
  private fetch() {
    this.fetchList(
      Object.assign({}, this.parameter, {
        actionType: 3 /** 固定值，当前请求的是短信发送记录 */,
        type: this.pageType /** 通过type区分当前是在哪个模块 */,
        page: this.page,
        pageSize: this.pageSize,
      }) as any
    )
  }

  /** 发送状态 */
  private statusFilter(status: number) {
    switch (status) {
      case 0:
        return this.$t('status.unsend') as string
        break
      case 1:
        return this.$t('status.send') as string
        break
      case 2:
        return this.$t('status.cancel') as string
        break
    }
  }

  /** 页码发生变化 */
  private handlePageChange(page: number): void {
    /** 修改本地 `page` 属性值，清空已选数据，并重新请求数据 */
    this.$set(this.$data, 'page', page)
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number): void {
    /** 修改本地 `pageSize` 属性值，若当前 `page` 为 1，则手动请求数据 */
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }
}
