














































































































































































































































































































import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import { ActionType, PageType } from 'xuexin-vuex/src/constant'
import { Semester, School, Student } from 'xuexin-vuex'
import {
  Button,
  Input,
  Form,
  FormItem,
  DatePicker,
  Checkbox,
  Select,
  Option,
} from 'view-design'
import AppModal from '@components/app-modal'
import AppRadioGroup from '@components/app-radio-group'
import { SchoolSelect } from '@business-components/school'
import { BusinessTypeSelect } from '@business-components/business-type'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { GradeSelect } from '@business-components/grade'
import { SchoolClassTypeSelect } from '@business-components/school-class-type'
import { TransferStatusText } from '@business-components/transfer-status'
import { SemesterSelect } from '@business-components/semester'
import DictionarySelect from '@business-components/dictionary-select'
import StudentTag from '@store/modules/student-tag'
import StudentTagType from 'types/student-tag'
import { DateUtil } from '@util'
import i18n from './i18n'

type DataType = {
  tranferStudentID: number
  outUnitID: number
  studentID: string
  tranferStatus: any
  isCheck: boolean
  termID: number
  organID: number
  phaseID: number
}

const components = {
  AppModal,
  SchoolSelect,
  BusinessTypeSelect,
  SchoolPhaseSelect,
  GradeSelect,
  SchoolClassTypeSelect,
  TransferStatusText,
  AppRadioGroup,
  Button,
  Input,
  Form,
  FormItem,
  DatePicker,
  Checkbox,
  SemesterSelect,
  Select,
  Option,
  DictionarySelect,
}
@Component({ name: 'InnerTransferModal', components, i18n })
export default class InnerTransferModal extends Vue {
  @Ref('form') private readonly form!: Form
  @Prop({ default: false, required: true }) private readonly value!: boolean
  @Prop({
    default: {
      tranferStudentID: null,
      outUnitID: null,
      studentID: '',
      tranferStatus: 'in',
      isCheck: false,
      termID: null,
      organID: null,
    },
    required: true,
  })
  private readonly data!: DataType

  @School.Action('fetch')
  private readonly fetchDirection!: School.Action.Fetch
  @School.Getter('item') private readonly getSchoolItem!: School.Getter.Item
  @School.Getter('itemStatus')
  private readonly getSchoolItemStatus!: School.Getter.ItemStatus

  @Student.Action('fetch')
  private readonly fetchTransferStatus!: Student.Action.Fetch
  @Student.Action('update')
  private readonly update!: Student.Action.Update
  @Student.Getter('itemStatus')
  private readonly getStudentItemStatus!: Student.Getter.ItemStatus
  @Student.Getter('itemExtra')
  private readonly getStudentItem!: Student.Getter.Item

  @StudentTag.Action('fetch')
  private readonly fetchTag!: StudentTagType.Action.Fetch

  private readonly prefixCls = 'inter-tranfer-modal'
  private loading = true
  private visible = false
  private updateParams: any = {}
  private readonlyAttr: any = {}
  private campusLenght = 0
  private getDirection: any = []

  private model: any = {
    freshStudent: true,
  }

  private tagItem: any = {}

  // 校际互转验证规则
  get rules() {
    return {
      parentUnitID: [
        {
          required: !this.data.isCheck,
          validator: this.validParentUnit,
          trigger: 'change',
        },
      ],
      unitID: [
        {
          required: !this.data.isCheck,
          validator: this.validUnit,
          trigger: 'change',
        },
      ],
      manageTypeID: [
        {
          required: !this.data.isCheck,
          validator: this.validManageTypeID,
          trigger: 'change',
        },
      ],
      glass: [{ required: !this.data.isCheck, validator: this.validGlass }],
      phaseID: [
        {
          required: !this.data.isCheck,
          validator: this.validPhaseID,
          trigger: 'change',
        },
      ],
      gradeID: [
        {
          required: !this.data.isCheck,
          validator: this.validGradeID,
          trigger: 'change',
        },
      ],
      targetTermID: [{ required: !this.data.isCheck }],
      unitClassTypeID: [
        {
          required: !this.data.isCheck,
          validator: this.validClasstypeID,
          trigger: 'change',
        },
      ],
      enrollDate: [
        {
          required: !this.data.isCheck,
          validator: this.validEnrollDate,
          trigger: 'change',
        },
      ],
      freshStudent: [
        {
          type: 'boolean',
          trigger: 'change',
          validator: this.validTag,
        },
      ],
      academicDirection: [
        {
          type: 'string',
          trigger: 'change',
          validator: this.validTag,
        },
      ],
      studentCategory: [
        {
          type: 'string',
          trigger: 'change',
          validator: this.validTag,
        },
      ],
    }
  }

  get schoolItemStatus() {
    return this.getSchoolItemStatus(this.model.unitID!)
  }

  // 日期限制
  get limitCondition() {
    return {
      // date代表当前日期
      disabledDate(date: Date) {
        return date && date.valueOf() < Date.now() - 86400000
      },
    }
  }

  // 获取校际互转事件状态
  get transferItemStatus() {
    const id = this.data.isCheck
      ? this.data.tranferStudentID
      : this.data.studentID
    return this.getStudentItemStatus(`${id}`)
  }

  // 获取校际互转状态详情
  get transferItem() {
    return this.getStudentItem(`${this.data.tranferStudentID}`)
  }
  // 获取学校出国方向
  @Watch('schoolItemStatus.fetching')
  private watchFetchingSchool(fetching: boolean, previous: boolean) {
    if (this.value === false || this.visible === false) {
      return void 0
    }
    if (fetching === false && fetching !== previous) {
      /** 查询失败 */
      if (
        this.schoolItemStatus.fetchingError !== null &&
        this.schoolItemStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.schoolItemStatus.fetchingError)
        return
      }

      this.$set(
        this.$data,
        'getDirection',
        this.getSchoolItem(this.model.unitID!)?.directions || []
      )
      if (this.getDirection.length > 0) {
        this.$set(
          this.$data.model,
          'direction',
          this.getDirection[0].schoolDepartTypeID
        )
      }
    }
  }

  // 监听窗口状态
  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.visible = value
    if (value && this.data.isCheck) {
      this.fetchTransferStatus({
        actionType: ActionType.Transfer,
        pageType: PageType.InnerTransfer,
        tranferStudentID: this.data.tranferStudentID,
        tranferStatus: this.data.tranferStatus,
      })
    }
  }
  // 监听学校id
  @Watch('model.parentUnitID', { immediate: true })
  private watchParentUnitID() {
    const parentUnitID = this.model.parentUnitID
    const unitID = this.model.unitID
    if (
      parentUnitID !== void 0 &&
      parentUnitID !== null &&
      parentUnitID !== -1
    ) {
      // todo 改变学校拿当前学校ID去查询学期报读类型等数据
      if (unitID !== void 0 && unitID !== null && unitID !== -1) {
        this.fetchDirection({ unitID: unitID! })
      }
    }
  }

  // 监听校区id
  @Watch('model.unitID', { immediate: true })
  private watchUnitID(value: number) {
    if (value !== void 0 && value !== null && value !== -1) {
      this.fetchDirection({ unitID: value })
      this.fetchTagItem(value)
    }
  }
  // 监听转至方向
  @Watch('model.direction', { immediate: true })
  private watchDirection(value: number, prev: number) {
    this.$set(this.model, 'phaseID', this.data.phaseID)
    this.resetTagValue(value, this.model.phaseID)
  }
  //学段变化为非高中，重置应届生为true
  @Watch('model.phaseID')
  private watchPhaseID(value: number) {
    if (this.model.direction !== 1) {
      this.resetTagValue(this.model.direction, value)
    }
  }
  // 监听校际互转updating
  @Watch('transferItemStatus.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (this.value === false || this.visible === false) {
      return void 0
    }
    if (updating === false && previous === true) {
      /** 更新失败 */
      if (this.transferItemStatus.updatingError !== null) {
        this.$Message.error(this.transferItemStatus.updatingError)
        return this.stopAction()
      }
      this.$emit('input', false)
      this.$emit('on-finish')
      return this.stopAction()
    }
  }

  // 监听校际互转状态查询fetching
  @Watch('transferItemStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (this.value === false || this.visible === false) {
      return void 0
    }

    if (fetching === false && fetching !== previous) {
      /** 查询失败 */
      if (
        this.transferItemStatus.fetchingError !== null &&
        this.transferItemStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.transferItemStatus.fetchingError)
        return
      }

      // 查询成功
      this.$set(this.$data, 'readonlyAttr', this.transferItem)
      if (this.data.isCheck) {
        this.model.direction = this.transferItem?.direction
      }
    }
  }

  //查询学生标签回显值
  private fetchTagItem(unitID: number) {
    this.fetchTag({
      xuexinID: this.data.studentID,
      unitID: this.data.outUnitID,
      termID: this.data.termID,
      type: 2,
      toUnitID: unitID,
    }).then(({ result }: any) => {
      this.tagItem = {
        freshStudent: result.freshStudent === 0 ? false : true,
        academicDirection: result.academicDirection
          ? `${result.academicDirection}`
          : void 0,
        studentCategory: result.studentCategory
          ? `${result.studentCategory}`
          : void 0,
        hasTermBill: result.hasTermBill,
      }
      if (this.model.phaseID == 4 || this.model.phaseID == 3) {
        const {
          freshStudent,
          academicDirection,
          studentCategory,
        } = this.tagItem
        this.$set(this.model, 'freshStudent', freshStudent)
        this.$set(this.model, 'academicDirection', academicDirection)
        this.$set(this.model, 'studentCategory', studentCategory)
      }
    })
  }

  private setGradeName(
    phaseID: number,
    unitGradeName: string,
    gradeName: string
  ) {
    if (phaseID === 1) {
      return unitGradeName ? unitGradeName : gradeName
    }
    return unitGradeName ? `${unitGradeName}(${gradeName})` : gradeName
  }

  private getLength(list: any) {
    this.campusLenght = list.length
  }
  private stopAction(): void {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleOK(): void {
    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        return this.stopAction()
      }
      // todo 校际互转参数待确认
      const { ...param } = this.model
      const { academicDirection, studentCategory } = this.model
      this.updateParams = Object.assign(
        {
          actionType: ActionType.InnerTransfer,
          pageType: PageType.DivideClass,
          transferUnitID: this.data.outUnitID, // 转出学校ID
          termID: this.data.termID, // 学期ID
          targetTermID: this.data.termID, // 目标学期ID
          xuexinID: this.data.studentID,
          studentID: this.data.studentID,
          tranferDate: new Date().getTime(),
        },
        param,
        {
          targetTermID: this.data.termID,
          enrollDate: new Date(`${this.model.enrollDate}`).getTime(),
          freshStudent: this.model.freshStudent ? 1 : 0,
          studentCategory:
            academicDirection === '5' ? void 0 : studentCategory,
        }
      )

      this.update(this.updateParams)
    })
  }

  private handleHidden(): void {
    this.form.resetFields()
    this.$set(this.$data, 'model', {})

    this.visible = false
    this.$emit('on-hidden')
    this.stopAction()
  }

  // 弹窗展示状态改变事件
  private handleVisibleChange(visible: boolean): void {
    this.$emit('on-visible-change', visible)
  }

  /** 校验函数 */
  private validParentUnit(rule: string, value: any, callback: any) {
    if (
      (value === null || value === -1 || value === void 0) &&
      !this.data.isCheck
    ) {
      const msg =
        ((this.readonlyAttr.tranferStatus === 'in'
          ? this.$t('label.out')
          : this.$t('label.in')) as string) + this.$t('messages.valid-school')
      callback(new Error(msg))
    } else {
      callback()
    }
  }

  private validUnit(rule: string, value: number | string, callback: any) {
    if (
      (value === null || value === -1 || value === void 0) &&
      !this.data.isCheck
    ) {
      const msg =
        ((this.readonlyAttr.tranferStatus === 'in'
          ? this.$t('label.out')
          : this.$t('label.in')) as string) + this.$t('messages.valid-campus')
      callback(new Error(msg))
    } else {
      callback()
    }
  }

  private validManageTypeID(
    rule: string,
    value: number | string,
    callback: any
  ) {
    if (
      (value === null || value === -1 || value === void 0) &&
      !this.data.isCheck
    ) {
      callback(new Error(this.$t('messages.valid-type') as string))
    } else {
      callback()
    }
  }

  private validPhaseID(rule: string, value: number | string, callback: any) {
    if (
      (value === null || value === -1 || value === void 0) &&
      !this.data.isCheck
    ) {
      callback(new Error(this.$t('messages.valid-phase') as string))
    } else {
      callback()
    }
  }

  private validGradeID(rule: string, value: number | string, callback: any) {
    if (
      (value === null || value === -1 || value === void 0) &&
      !this.data.isCheck
    ) {
      callback(new Error(this.$t('messages.valid-grade') as string))
    } else {
      callback()
    }
  }

  private validClasstypeID(
    rule: string,
    value: number | string,
    callback: any
  ) {
    if (
      (value === null || value === -1 || value === void 0) &&
      !this.data.isCheck
    ) {
      callback(new Error(this.$t('messages.valid-class-type') as string))
    } else {
      callback()
    }
  }

  private validEnrollDate(rule: string, value: any, callback: any) {
    if (
      (value === null || value === '' || value === void 0) &&
      !this.data.isCheck
    ) {
      callback(new Error(this.$t('messages.valid-date') as string))
    } else {
      callback()
    }
  }

  private validGlass(rule: string, value: number, callback: any) {
    callback()
  }

  private validTag(rule: FormRule, value: any, callback: any) {
    if (
      (value === null || value === '' || value === void 0) &&
      !this.data.isCheck
    ) {
      if (
        (this.model.direction == 2 &&
          ['freshStudent', 'academicDirection', 'studentCategory'].includes(
            rule.field || '-1'
          )) ||
        (rule.field == 'studentCategory' &&
          this.model.academicDirection == '5')
      ) {
        callback()
      } else {
        callback(new Error(this.$t(`error.${rule.field}`) as string))
      }
    } else {
      callback()
    }
  }
  //充值学生标签默认值
  private resetTagValue(direction: number, phaseID: number) {
    if (direction == 1) {
      const {
        freshStudent,
        academicDirection,
        studentCategory,
      } = this.tagItem
      if (phaseID === 4 || phaseID === 3) {
        this.$set(this.model, 'freshStudent', freshStudent)
        this.$set(this.model, 'academicDirection', academicDirection)
        this.$set(this.model, 'studentCategory', studentCategory)
      } else {
        this.$set(this.model, 'freshStudent', true)
        this.$set(this.model, 'academicDirection', '1')
        this.$set(this.model, 'studentCategory', studentCategory || '3')
      }
    } else {
      //
    }
  }
}
