const searches = [
  {
    /** 学年 */
    code: 'schoolYear',
    fixed: 1,
    labelField: 'schoolYearName',
    show: 1,
    type: 1,
    valueField: 'schoolYear',
  },
  {
    /** 学期 */
    code: 'termID',
    fixed: 1,
    labelField: 'termName',
    show: 1,
    type: 1,
    valueField: 'termID',
  },
  {
    /** 学校 */
    code: 'parentUnitID',
    fixed: 1,
    labelField: 'parentUnitName',
    show: 1,
    type: 1,
    valueField: 'parentUnitID',
  },
  {
    /** 校区 */
    code: 'unitID',
    fixed: 1,
    labelField: 'unitName',
    show: 1,
    type: 1,
    valueField: 'unitID',
  },
  {
    /** 学部 */
    code: 'schoolDepartID',
    fixed: 1,
    labelField: 'schoolDepartName',
    show: 1,
    type: 1,
    valueField: 'schoolDepartID',
  },
  {
    /** 学段 */
    code: 'phaseID',
    fixed: 1,
    labelField: 'phaseName',
    show: 1,
    type: 1,
    valueField: 'phaseID',
  },
  {
    /** 年级 */
    code: 'unitGradeID',
    fixed: 1,
    labelField: 'unitGradeName',
    show: 1,
    type: 1,
    valueField: 'unitGradeID',
  },
  {
    /** 班级 */
    code: 'classID',
    fixed: 1,
    labelField: 'className',
    show: 1,
    type: 1,
    valueField: 'classID',
  },
  {
    /** 报读类型 */
    code: 'manageTypeID',
    fixed: 1,
    labelField: 'manageTypeName',
    show: 1,
    type: 1,
    valueField: 'manageTypeID',
  },
  {
    /** 班级类型 */
    code: 'classTypeID',
    fixed: 1,
    labelField: 'classTypeName',
    show: 1,
    type: 1,
    valueField: 'classTypeID',
  },
  {
    /** 校本班型 */
    code: 'unitClassTypeID',
    fixed: 1,
    labelField: 'unitClassTypeName',
    show: 1,
    type: 1,
    valueField: 'unitClassTypeID',
  },
  {
    /** 学生状态 */
    code: 'studentStatus',
    fixed: 1,
    labelField: 'studentStatus',
    show: 1,
    type: 1,
    valueField: 'studentStatus',
  },
  {
    /** 截止日期 */
    code: 'endDate',
    fixed: 1,
    labelField: 'endDate',
    show: 1,
    type: 4,
    valueField: 'endDate',
  },
  {
    /** 截止月份 */
    code: 'endMonth',
    fixed: 1,
    labelField: 'endMonth',
    show: 1,
    type: 1,
    valueField: 'endMonth',
  },
]
export default function getSearches(code: string) {
  switch (code) {
    case '050701': //学校纵览表
      return searches.map(item => {
        if (
          item.code === 'schoolYear' ||
          item.code === 'classID' ||
          item.code === 'studentStatus' ||
          item.code === 'endMonth'
        ) {
          return Object.assign({}, item, { show: 0 })
        } else {
          return item
        }
      })
    case '050702': //学生一览表
    case '050703': //学生异动统计表
      return searches.map(item => {
        if (
          item.code === 'schoolYear' ||
          item.code === 'studentStatus' ||
          item.code === 'endMonth'
        ) {
          return Object.assign({}, item, { show: 0 })
        } else {
          return item
        }
      })
    case '050704': //学生人数月统计
      return searches.map(item => {
        if (
          item.code === 'schoolYear' ||
          item.code === 'endDate' ||
          item.code === 'endMonth'
        ) {
          return Object.assign({}, item, { show: 0 })
        } else {
          return item
        }
      })
    case '050705': //在校人数对比分析
      return searches.map(item => {
        if (
          item.code === 'termID' ||
          item.code === 'classID' ||
          item.code === 'studentStatus' ||
          item.code === 'endMonth'
        ) {
          return Object.assign({}, item, { show: 0 })
        } else {
          return item
        }
      })
    case '050706': //班级数量统计
      return searches.map(item => {
        if (
          item.code === 'termID' ||
          item.code === 'unitGradeID' ||
          item.code === 'classID' ||
          item.code === 'studentStatus' ||
          item.code === 'endMonth'
        ) {
          return Object.assign({}, item, { show: 0 })
        } else {
          return item
        }
      })
    case '050714': //班级数量月统计
    case '050707': //班级数量对比分析
      return searches.map(item => {
        if (
          item.code === 'termID' ||
          item.code === 'unitGradeID' ||
          item.code === 'classID' ||
          item.code === 'studentStatus' ||
          item.code === 'endDate' ||
          item.code === 'endMonth'
        ) {
          return Object.assign({}, item, { show: 0 })
        } else {
          return item
        }
      })
    case '050708': //实际流失率统计
    case '050709': //考核流失率统计
    case '050713': //学生分布统计
      return searches.map(item => {
        if (
          item.code === 'termID' ||
          item.code === 'classID' ||
          item.code === 'studentStatus' ||
          item.code === 'endDate' ||
          item.code === 'endMonth'
        ) {
          return Object.assign({}, item, { show: 0 })
        } else {
          return item
        }
      })
    case '050710': //流失人数统计对比分析
    case '050711': //流失率统计对比分析
      return searches.map(item => {
        if (
          item.code === 'termID' ||
          item.code === 'unitGradeID' ||
          item.code === 'classID' ||
          item.code === 'studentStatus' ||
          item.code === 'endDate'
        ) {
          return Object.assign({}, item, { show: 0 })
        } else {
          return item
        }
      })
    case '050712': //插班生人数统计
      return searches.map(item => {
        if (
          item.code === 'termID' ||
          item.code === 'classID' ||
          item.code === 'studentStatus' ||
          item.code === 'endMonth'
        ) {
          return Object.assign({}, item, { show: 0 })
        } else {
          return item
        }
      })
    case '050715': //班级缴费
      return searches.map(item => {
        if (
          item.code === 'schoolYear' ||
          item.code === 'phaseID' ||
          item.code === 'manageTypeID' ||
          item.code === 'classTypeID' ||
          item.code === 'studentStatus' ||
          item.code === 'endMonth'
        ) {
          return Object.assign({}, item, { show: 0 })
        } else {
          return item
        }
      })
    default:
      return searches
  }
}
