import Vue from 'vue'
import OperateClass from '../../../types/operate-class'
import { namespace as BindingNamespace } from 'vuex-class'
import { Ajax } from 'xuexin-vuex/src/ajax'
import omit from 'lodash.omit'
import { handlers } from 'xuexin-vuex/src/util'
export const namespace = '@xuexin-vuex/lost-report'

export const types = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
}

const state: OperateClass.State = {
  list: { data: [] },
}

const actions: OperateClass.Actions = {
  //查询报表列表
  fetch(context, payload) {
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload,
      api: `get ${__STUDENT_API__}/common/log/group/types`,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
}

const mutations: OperateClass.Mutations = {
  [types.FETCH_REQUEST](state) {
    handlers.request(state.list, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { result }) {
    const item = state.list
    Vue.set(item, 'data', result)
    handlers.success(item, 'fetching')
  },
  [types.FETCH_FAILURE](state, { message }) {
    handlers.failure(state.list, 'fetching', message)
  },
}

const getters: OperateClass.Getters = {
  list(state) {
    return () => state.list?.data
  },
  listStatus(state) {
    return () => omit(state.list, ['data'])
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
