




























































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Button } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import AddEditModal from './components/addEditModal'
import {
  SchoolClassType,
  SchoolDepart,
  SchoolPhase,
  BusinessType,
  ClassType,
  Semester,
  Session,
} from 'xuexin-vuex'
import { SchoolSelect } from '@business-components/school'
import { SchoolDepartSelect } from '@business-components/school-depart'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { BusinessTypeSelect } from '@business-components/business-type'
import { ClassTypeSelect } from '@business-components/class-type'
import { EnableStatusSelect } from '@business-components/enable-status'
import Page from '@store/modules/page'
import PageTyping from '../../../../types/page'
import i18n from './i18n'

const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  AppTable,
  AppPagination,
  AddEditModal,
  SchoolSelect,
  SchoolDepartSelect,
  SchoolPhaseSelect,
  BusinessTypeSelect,
  ClassTypeSelect,
  EnableStatusSelect,
}
@Component({ name: 'SchoolClassTypePage', components, i18n })
export default class SchoolClassTypePage extends Vue {
  @Page.Action('fetch') private readonly fetchAuth!: PageTyping.Action.Fetch
  @Page.Getter('item') private readonly getItem!: PageTyping.Getter.Item
  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  @SchoolClassType.Action('delete')
  private readonly delete!: SchoolClassType.Action.Delete
  @SchoolClassType.Action('fetchList')
  private readonly fetchList!: SchoolClassType.Action.FetchList
  @SchoolClassType.Getter('list')
  private readonly list!: SchoolClassType.Getter.List
  @SchoolClassType.Getter('listStatus')
  private readonly listStatus!: SchoolClassType.Getter.ListStatus
  @SchoolClassType.Getter('itemStatus')
  private readonly itemStatus!: SchoolClassType.Getter.ItemStatus

  @SchoolDepart.Getter('list')
  private readonly getDepartList!: SchoolDepart.Getter.List
  @SchoolPhase.Getter('list')
  private readonly getPhaseList!: SchoolPhase.Getter.List
  @BusinessType.Getter('list')
  private readonly getBusinessList!: BusinessType.Getter.List
  @ClassType.Getter('list')
  private readonly getClassTypeList!: ClassType.Getter.List
  private readonly prefixCls = 'school-class-type-page'
  private dataList: SchoolClassType.Entity[] = []
  private showAddEdit = false
  private curRow = null
  private curUnitClassTypeID = -1
  private page = 1
  private pageSize = 10
  private schoolLength = 0
  private campusLength = 0
  private departLength = 0
  private phaseLength = 0
  private manageTypeLength = 0
  private classTypeLength = 0
  private multiple = true
  private total = 0

  private parameter: any = {
    parentUnitID: [],
    unitID: [],
    schoolDepartID: [],
    phaseID: [],
    manageTypeID: [],
    classTypeID: [],
    enable: 1,
  }

  get loading() {
    return this.listStatus(this.parameter).fetching
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  get authCode() {
    return this.$route.meta.authCode
  }

  get organID() {
    return this.getUser?.organID
  }
  get identityType() {
    if (
      this.getUser?.identityType === 3 &&
      this.getUser.parentUnitID !== void 0
    ) {
      this.parameter.parentUnitID.push(this.getUser.parentUnitID)
    }
    return this.getUser?.identityType
  }
  get getListStatus() {
    return this.listStatus(this.parameter)
  }
  get getItemStatus() {
    return this.itemStatus(this.curUnitClassTypeID)
  }

  get currentTermID() {
    return this.getUser?.extraMap.currentTermID
  }
  // 表头
  get columns() {
    let _colums = [
      {
        title: '序号',
        width: 80,
        align: 'center',
        slot: 'indexAction',
      },
      { title: this.$t('columns.school'), key: 'parentUnitName' },
      { title: this.$t('columns.campus'), key: 'unitName' },
      { title: this.$t('columns.schoolDepart'), key: 'schoolDepartName' },
      { title: this.$t('columns.phase'), key: 'phaseName' },
      { title: this.$t('columns.manageType'), key: 'manageTypeName' },
      { title: this.$t('columns.classType'), key: 'classTypeName' },
      { title: this.$t('schoolClassType'), key: 'unitClassTypeName' },
      { title: this.$t('columns.state'), slot: 'enableAction' },
    ]

    if (this.identityType === 3) {
      _colums.push({
        title: this.$t('columns.operate'),
        slot: 'handleAction',
      })
    }
    return _colums
  }

  // 监听校本班型查询状态
  @Watch('getListStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.getListStatus.fetchingError !== null &&
        this.getListStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.fetchingError)
        return
      }
      this.dataList = this.list(this.parameter).map(item => {
        return item
      })

      this.total = this.listStatus(this.parameter).total || 0
    }
  }

  // 监听校本班型删除状态
  @Watch('getItemStatus.deleting')
  private watchDeleting(deleting: boolean, previous: boolean) {
    if (deleting === false && deleting !== previous) {
      if (
        this.getItemStatus.deletingError !== null &&
        this.getItemStatus.deletingError !== void 0
      ) {
        this.$Message.error(this.getItemStatus.deletingError)
        return
      }
      this.$Message.success('删除成功！')
      this.fetch()
    }
  }
  // 初始化页面
  private mounted() {
    if (this.getUser?.identityType === 3) {
      this.fetchAuth({
        authCode: this.authCode,
        unitID: this.getUser?.parentUnitID,
      })
    } else {
      this.fetchAuth({ authCode: this.authCode, organID: this.organID })
    }

    this.fetch()
  }

  // 判断权限按钮中是否包含某个按钮
  private isHasButton(code: string) {
    const btnList = this.getItem(this.authCode)?.auths
    if (btnList === void 0) {
      return false
    }
    return btnList.some(item => {
      return item.code === code
    })
  }

  // 组件下拉项个数,只有一个下拉项时，默认选中
  private getLength(node: string, list: any) {
    switch (node) {
      case 'school':
        if (list.length === 1) {
          this.parameter.parentUnitID = [list[0].unitID]
        }
        this.schoolLength = list.length
        break
      case 'campus':
        // 过滤掉未选学校的校区
        if (list.length > 0 && this.parameter.unitID.length > 0) {
          this.parameter.unitID.forEach((item: any) => {
            const len = list.filter((i: any) => {
              return i.unitID == item
            }).length
            if (len < 1) {
              this.parameter.unitID.splice(
                this.parameter.unitID.indexOf(item),
                1
              )
            }
          })
        }

        if (list.length === 1) {
          this.parameter.unitID = [list[0].unitID]
        }
        this.campusLength = list.length
        break
      case 'depart':
        // 过滤掉未选校区的学部
        if (list.length > 0 && this.parameter.schoolDepartID.length > 0) {
          this.parameter.schoolDepartID.forEach((item: any) => {
            const len = list.filter((i: any) => {
              return i.schoolDepartID == item
            }).length
            if (len < 1) {
              this.parameter.schoolDepartID.splice(
                this.parameter.schoolDepartID.indexOf(item),
                1
              )
            }
          })
        }
        if (list.length === 1) {
          this.parameter.schoolDepartID = [list[0].schoolDepartID]
        }
        this.departLength = list.length
        break
      case 'phase':
        // 过滤掉未选学部的学段
        if (list.length > 0 && this.parameter.phaseID.length > 0) {
          this.parameter.phaseID.forEach((item: any) => {
            const len = list.filter((i: any) => {
              return i.phaseID == item
            }).length
            if (len < 1) {
              this.parameter.phaseID.splice(
                this.parameter.phaseID.indexOf(item),
                1
              )
            }
          })
        }
        if (list.length === 1) {
          this.parameter.phaseID = [list[0].phaseID]
        }
        this.phaseLength = list.length
        break
      case 'manageType':
        // 过滤掉未选学段的报读类型
        if (list.length > 0 && this.parameter.manageTypeID.length > 0) {
          this.parameter.manageTypeID.forEach((item: any) => {
            const len = list.filter((i: any) => {
              return i.manageTypeID == item
            }).length
            if (len < 1) {
              this.parameter.manageTypeID.splice(
                this.parameter.manageTypeID.indexOf(item),
                1
              )
            }
          })
        }
        if (list.length === 1) {
          this.parameter.manageTypeID = [list[0].manageTypeID]
        }
        this.manageTypeLength = list.length
        break
      case 'classType':
        // 过滤掉未选报读类型的班级类型
        if (list.length > 0 && this.parameter.classTypeID.length > 0) {
          this.parameter.classTypeID.forEach((item: any) => {
            const len = list.filter((i: any) => {
              return i.classTypeID == item
            }).length
            if (len < 1) {
              this.parameter.classTypeID.splice(
                this.parameter.classTypeID.indexOf(item),
                1
              )
            }
          })
        }
        if (list.length === 1) {
          this.parameter.classTypeID = [list[0].classTypeID]
        }
        this.classTypeLength = list.length
        break
      default:
        break
    }
  }

  //查询按钮点击事件
  private handleSearch() {
    this.page = 1
    this.fetch()
  }

  // 判断是否显示分页
  get showPagination(): boolean {
    if (this.total === void 0) {
      return false
    }
    return this.total > 10
  }
  // 点击编辑按钮
  private showEditModal(item: any) {
    this.$set(this.$data, 'curRow', item)
    this.showAddEdit = true
  }
  // 点击添加按钮
  private showAddModal() {
    this.curRow = null
    this.showAddEdit = true
  }
  //删除事件
  private handleDel(id: number): void {
    this.curUnitClassTypeID = id
    this.$Modal.confirm({
      title: this.$t('button-text.delete') as 'delete',
      content: `<p>${this.$t('message.confirm-delete')}</p>`,
      onOk: () => {
        this.delete({ unitClassTypeID: id })
      },
    })
  }

  //执行查询事件
  private fetch() {
    this.fetchList(
      Object.assign(
        {},
        {
          parentUnitID: this.parameter.parentUnitID.join(','),
          unitID: this.parameter.unitID.join(','),
          schoolDepartID: this.parameter.schoolDepartID.join(','),
          phaseID: this.parameter.phaseID.join(','),
          manageTypeID: this.parameter.manageTypeID.join(','),
          classTypeID: this.parameter.classTypeID.join(','),
          enable: this.parameter.enable,
          pageSize: this.pageSize,
          page: this.page,
        }
      )
    )
  }

  //点击分页
  private handlePageChange(page: number): void {
    this.page = page
    this.$nextTick(this.fetch)
  }
  //切换分页条数
  private handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize
    if (this.page === 1) {
      this.$nextTick(this.fetch)
    } else {
      this.page = 1
    }
  }
}
