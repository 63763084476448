export const messages: Messages = {
  'zh-CN': {
    title: '转学原因',
    loading: '加载中...',
    label: {
      termName: '转学学期',
      studentName: '学生姓名',
      transferDate: '转学日期',
      transfeReason: '转学原因',
      checkLost: '考核流失',
      transfeArea: '转至地区',
      transfeSchool: '转至学校',
      note: '备注',
      enclosure: '附件',
      operator: '操作人',
      operatDate: '操作时间',
      isRefund: '是否退费',
      mealDate: '餐点起止时间',
      stayDate: '住宿起止时间',
      parentCard: '家长收款账号',
      payee: '收款人姓名',
      bankOfDeposit: '开户行',
    },
    checkLost: {
      1: '计入',
      2: '不计入',
    },
    transfeArea: {
      1: '本地',
      2: '外地',
    },
    isRefund: {
      0: '否',
      1: '是',
    },
  },
  'en-US': {},
}

export default { messages }
