import InformationSetup from '../../../types/information-setup'
import { namespace as BindingNamespace } from 'vuex-class'
import { Ajax } from 'xuexin-vuex/src/ajax'
import omit from 'lodash.omit'
import { handlers } from 'xuexin-vuex/src/util'

export const namespace = '@xuexin-vuex/information-setup'

export const types = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',

  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',

  CREATE_REQUEST: 'CREATE_REQUEST',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_FAILURE: 'CREATE_FAILURE',

  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',
}

const state: InformationSetup.State = {
  student: {
    list: { data: [] },
    items: {},
  },
  class: {
    list: { data: [] },
    items: {},
  },
  approval: {
    list: { data: [] },
    items: {},
  },
  losts: {
    list: { data: [] },
    items: {},
  },
}

const actions: InformationSetup.Actions = {
  //查询学生信息配置列表
  fetch(context, payload) {
    const { type, ...parameter } = payload
    let api = ''

    switch (type) {
      case 'student':
        api = `${__STUDENT_API__}/config/students/${payload.direction}`
        break
      case 'class':
        api = `${__STUDENT_API__}/config/clazz`
        break
      case 'approval':
        api = `${__STUDENT_API__}/config/configApprovals`
        break
      case 'losts':
        api = `${__STUDENT_API__}/config/losts`
      default:
        break
    }

    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload,
      api: `get ${api}`,
      parameter: parameter,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
  //更新学生信息配置
  update(context, payload) {
    const { type, editType, ...parameter } = payload
    let api = ''
    switch (type) {
      case 'student':
        if (editType && editType !== void 0 && editType == 'alias') {
          api = `${__STUDENT_API__}/config/students/individual`
        } else {
          api = `${__STUDENT_API__}/config/students`
        }
        break
      case 'class':
        api = `${__STUDENT_API__}/config/clazz`
        break
      case 'approval':
        api = `${__STUDENT_API__}/config/configApprovals`
        break
      case 'losts':
        api = `${__STUDENT_API__}/config/losts`
        break
      case 'studentAlias':
        break
      default:
        break
    }
    return Ajax(context, {
      type: types.UPDATE_REQUEST,
      payload,
      api: `put ${api}`,
      parameter: parameter,
      success: types.UPDATE_SUCCESS,
      failure: types.UPDATE_FAILURE,
    })
  },
  //添加学生信息配置
  create(context, payload) {
    return Ajax(context, {
      type: types.CREATE_REQUEST,
      payload,
      api: `post ${__STUDENT_API__}/config/students`,
      parameter: payload,
      success: types.CREATE_SUCCESS,
      failure: types.CREATE_FAILURE,
    })
  },
  //删除学生信息配置
  delete(context, payload) {
    return Ajax(context, {
      type: types.DELETE_REQUEST,
      payload,
      api: `delete ${__STUDENT_API__}/config/students/${payload.extendID}`,
      parameter: payload,
      success: types.DELETE_SUCCESS,
      failure: types.DELETE_FAILURE,
    })
  },
}

const mutations: InformationSetup.Mutations = {
  //查询学生信息配置列表
  [types.FETCH_REQUEST](state, { payload }) {
    handlers.request(state[payload.type!].list, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { payload, result, total }) {
    switch (payload.type) {
      case 'student':
        handlers.coverToState(state.student, 'extendID', result, total)
        handlers.success(state.student.list, 'fetching')
        break
      case 'class':
        handlers.coverToState(state.class, 'configClassID', result, total)
        handlers.success(state.class.list, 'fetching')
        break
      case 'approval':
        handlers.coverToState(state.approval, 'configApprovalID', result, total)
        handlers.success(state.approval.list, 'fetching')
        break
      case 'losts':
        handlers.coverToState(state.losts, 'lostID', result, total)
        handlers.success(state.losts.list, 'fetching')
      default:
        break
    }
  },
  [types.FETCH_FAILURE](state, { payload, message }) {
    handlers.failure(state[payload.type!].list, 'fetching', message)
  },
  //更新学生信息配置
  [types.UPDATE_REQUEST](state, { payload }) {
    if (
      payload.editType &&
      payload.editType !== void 0 &&
      payload.editType == 'save'
    ) {
      handlers.request(state[payload.type!].list, 'updating')
    } else {
      handlers.itemRequest(state[payload.type!], payload.extendID, 'updating')
    }
  },
  [types.UPDATE_SUCCESS](state, { payload }) {
    if (
      payload.editType &&
      payload.editType !== void 0 &&
      payload.editType == 'save'
    ) {
      handlers.success(state[payload.type!].list, 'updating')
    } else {
      handlers.itemSuccess(state[payload.type!], payload.extendID, 'updating')
    }
  },
  [types.UPDATE_FAILURE](state, { payload, message }) {
    if (
      payload.editType &&
      payload.editType !== void 0 &&
      payload.editType == 'save'
    ) {
      handlers.failure(state[payload.type!].list, 'updating', message)
    } else {
      handlers.itemFailure(
        state[payload.type!],
        payload.extendID,
        'updating',
        message
      )
    }
  },
  //添加学生信息配置
  [types.CREATE_REQUEST](state, { payload }) {
    handlers.request(state[payload.type!].list, 'creating')
  },
  [types.CREATE_SUCCESS](state, { payload }) {
    handlers.success(state[payload.type!].list, 'creating')
  },
  [types.CREATE_FAILURE](state, { payload, message }) {
    handlers.failure(state[payload.type!].list, 'creating', message)
  },
  //删除学生信息配置
  [types.DELETE_REQUEST](state, { payload }) {
    handlers.request(state[payload.type!].list, 'deleting')
  },
  [types.DELETE_SUCCESS](state, { payload }) {
    handlers.success(state[payload.type!].list, 'deleting')
  },
  [types.DELETE_FAILURE](state, { payload, message }) {
    handlers.failure(state[payload.type!].list, 'deleting', message)
  },
}

const getters: InformationSetup.Getters = {
  state(state) {
    return name => state[name] || { list: { data: [] }, items: {} }
  },
  item(state, getters) {
    return (name, id) => {
      return getters.state(name).items[`${id}`]?.data
    }
  },
  itemStatus(state, getters) {
    return (name, id) => {
      return omit(getters.state(name).items[`${id}`], ['data'])
    }
  },
  list(state, getters) {
    return name => {
      return state[name].list.data.map(id => {
        const item = getters.item(name, id)
        return Object.assign({}, item)
      })
    }
  },
  listStatus(state, getters) {
    return name => omit(getters.state(name).list, ['data'])
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
