export default {
  messages: {
    'zh-CN': {
      title: '编辑毕业去向',
      label: '毕业去向',
      loading: '加载中...',
      placeholder: '请选择',
      validator: '请选择去向',
    },
    'en-US': {
      title: '编辑毕业去向',
      label: '毕业去向',
      loading: '加载中...',
      placeholder: '请选择',
      validator: '请选择去向',
    },
  },
}
