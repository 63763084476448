import { Constant } from 'xuexin-vuex'

export type DivideClassType = Constant.DivideClassType
export const DivideClassType = Constant.DivideClassType

export const DivideClassTypeList = [
  DivideClassType.Enrollment,
  DivideClassType.ClassDelete,
  DivideClassType.InnerTransfer,
  DivideClassType.Upgrade,
  DivideClassType.Reentry,
  DivideClassType.OldStatus,
  DivideClassType.HadStudy,
  DivideClassType.GradReentry,
  DivideClassType.Reread,
]

export const messages: Messages = {
  'zh-CN': {
    [`${DivideClassType.Enrollment}`]: '新生',
    [`${DivideClassType.ClassDelete}`]: '班级删除',
    [`${DivideClassType.InnerTransfer}`]: '校际互转',
    [`${DivideClassType.Upgrade}`]: '直升学生',
    [`${DivideClassType.Reentry}`]: '重新入校',
    [`${DivideClassType.OldStatus}`]: '老学籍',
    [`${DivideClassType.HadStudy}`]: '原就读',
    [`${DivideClassType.GradReentry}`]: '毕业重新入校',
    [`${DivideClassType.Reread}`]: '复读',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${DivideClassType.Enrollment}`]: '新生',
    [`${DivideClassType.ClassDelete}`]: '班级删除',
    [`${DivideClassType.InnerTransfer}`]: '校际互转',
    [`${DivideClassType.Upgrade}`]: '直升学生',
    [`${DivideClassType.Reentry}`]: '重新入校',
    [`${DivideClassType.OldStatus}`]: '老学籍',
    [`${DivideClassType.HadStudy}`]: '原就读',
    [`${DivideClassType.GradReentry}`]: '毕业重新入校',
    [`${DivideClassType.Reread}`]: '复读',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
