




































































import Vue from 'vue'
import i18n from './i18n'
import AppModal from '@components/app-modal'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import SendMessageModal from '@business-components/send-message-modal'
import { Constant } from 'xuexin-vuex'
import Records from '@store/modules/records'
import { Model, Component, Prop, Watch } from 'vue-property-decorator'
import { Input, Button } from 'view-design'
const components = {
  AppModal,
  AppTable,
  AppPagination,
  SendMessageModal,
  Input,
  Button,
}
type DataType = {
  unitID: number
  searches: any
  recordID: number
  actionType: Constant.ActionType
  pageType: Constant.PageType
  isSms: boolean
}

type searchModel = {
  name: string
  mobile: number
}

@Component({ name: 'FailureModal', components, i18n })
export default class FailureModal extends Vue {
  @Model('input') private value!: any
  @Records.Action('fetch') private readonly fetchList!: Records.Action.Fetch
  @Records.Getter('item') private readonly item!: Records.Getter.Item
  @Records.Getter('itemStatus')
  private readonly itemStatus!: Records.Getter.ItemStatus
  @Prop({ default: () => ({}) }) private readonly data!: DataType

  private modal: { name: string | null; data: any } = {
    name: null,
    data: {},
  }
  private readonly prefixCls = 'failure-modal'
  private search: Partial<searchModel> = {}
  private selection: string[] = []
  private page = 1
  private pageSize = 10
  private visible = false
  private loading = true

  get recordID() {
    return this.data.recordID
  }

  get searches() {
    return this.data.searches
  }

  get parameter() {
    return Object.assign({}, this.search, {
      sendStatus: 0, // 失败状态
      recordID: this.recordID,
    })
  }

  get tableColumns() {
    return [
      { type: 'selection', width: 60, align: 'center' },
      // prettier-ignore
      { title:this.$t('table.index'), key: 'num', width: 80, align: 'center'},
      // prettier-ignore
      { title: this.$t('table.name'), key: 'name' },
      // prettier-ignore
      { title: this.$t('table.mobile'), key: 'mobile' },
      // prettier-ignore
      { title: this.$t('table.reason'), slot: 'remark', minWidth: 150},
    ]
  }

  get tableData() {
    const failureList: any = this.item(this.recordID)?.child || []
    return failureList.map((item: Records.Entity, index: number) => {
      const list = Object.assign({}, item)
      list.num = (this.page - 1) * this.pageSize + index + 1
      return list
    })
  }

  get total() {
    return this.item(this.recordID)?.failureCount
  }

  get fetching() {
    return this.itemStatus(this.recordID)?.fetching === true
  }

  get fetchingError() {
    return this.itemStatus(this.recordID).fetchingError || null
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 窗口没有打开不处理状态 */
    if (this.value === false || this.visible === false) {
      return void 0
    }

    if (fetching === false && previous === true) {
      if (this.fetchingError !== null) {
        return this.$Message.error(this.fetchingError)
      }
    }
  }

  /** 条件查询 */
  private handleClick(action: string) {
    this.$set(this.$data, 'page', 1)
    switch (action) {
      case 'search':
        this.fetch()
        break
      case 'send-sms':
        this.$set(this.$data, 'modal', {
          name: 'sms',
          data: Object.assign({}, this.data, {
            list: (this.selection.length === 0 ? [] : this.selection).map(
              (item: any) => {
                return {
                  id: item.xuexinID,
                  name: item.name,
                  mobile: item.mobile,
                }
              }
            ),
          }),
        })
        break
    }
  }

  private handleModalFinish(modal: string, target: string) {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }

  private handleModalHidden() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }

  /** 已选数据 */
  private handleSelectionChange(selection: Records.Entity[]) {
    this.$set(this.$data, 'selection', selection)
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      this.fetch()
    }
    this.$emit('on-visible-change')
  }

  private handleHidden() {
    this.$emit('on-hidden')
  }

  private stopAction() {
    this.$set(this.$data, 'loading', false)
    this.$nextTick(() => (this.loading = true))
  }

  private fetch() {
    if (this.fetching === true /** 请求中不重复请求数据 */) {
      return
    }
    this.fetchList(
      Object.assign({}, this.parameter, {
        page: this.page,
        pageSize: this.pageSize,
      })
    )
  }

  /** 页码发生变化 */
  private handlePageChange(page: number): void {
    /** 修改本地 `page` 属性值，清空已选数据，并重新请求数据  */
    this.$set(this.$data, 'page', page)
    this.$set(this.$data, 'selection', [])
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number): void {
    /** 修改本地 `pageSize` 属性值，若当前 `page` 为 1，则手动请求数据 */
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }
}
