import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'
import i18n from './i18n'

const valueField = 'monthID'
const labelField = 'monthName'
const prefixCls = `statistical-form`

function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `month${upperFirst(type)}`
  @Component({ name, inheritAttrs: false, i18n })
  class monthComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
    @Prop({ type: [String, Number] }) private readonly init!: string | number

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }

    get list() {
      let arr = []
      for (let i = 1; i <= 12; i++) {
        arr.push({
          [labelField]: `${i}${this.$t('month')}`,
          [valueField]: i,
          disabled: this.disabled,
        })
      }
      return this.extra.concat(arr)
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          props.value = this.init
          break
        default:
          break
      }
      return props
    }

    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (type === ComponentType.Text) {
        children = [`${this.$attrs.value}${this.$t('month')}`]
      }
      return createElement(
        component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return monthComponent
}

export const MonthSelect = createComponent(AppSelect, ComponentType.Select)
export const MonthText = createComponent('span', ComponentType.Text)
