import Vue, { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

const components = {}
@Component({ name: 'RouterViewLayout', components })
export default class RouterViewLayout extends Vue {
  @Prop({ type: String }) private readonly name!: string

  private readonly prefixCls: string = 'router-view-layout'

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
      [`${this.prefixCls}__${this.name}`]: typeof this.name === 'string',
    }
  }

  render(createElement: CreateElement) {
    return createElement('div', { class: this.classes }, [
      createElement('router-view'),
    ])
  }
}
