export const messages: Messages = {
  'zh-CN': {
    title: '失败数',
    label: {
      name: '学生姓名',
      mobile: '家长电话',
      button: '查询',
      send: '发短信',
    },
    table: {
      index: '序号',
      name: '学生姓名',
      mobile: '家长电话',
      reason: '失败原因',
    },
  },
  'en-US': {},
}
export default { messages }
