import ResearchFieldI18n from '@business-components/research-field/i18n'
import StudentMarkI18n from '@business-components/student-mark/i18n'
import EnrollTypeI18n from '@business-components/enroll-type/i18n'
import StudentSourceI18n from '@business-components/student-source/i18n'
import HouseholdIncomeI18n from '@business-components/household-income/i18n'
import ResidenceTypeI18n from '@business-components/residence-type/i18n'
import IDTypeI18n from '@business-components/id-type/i18n'
import TransferReasonI18n from '@business-components/transfer-reason/i18n'
import EnrollScoreI18n from '@business-components/enroll-score/i18n'
import DivideClassTypeI18n from '@business-components/divide-class-type/i18n'
import StatusTypeI18n from '@business-components/status-type/i18n'
import DirectionI18n from '@business-components/direction/i18n'
import StudentStatusI18n from '@business-components/student-status/i18n'
import EducationI18n from '@business-components/education/i18n'
import PoliticalStatusI18n from '@business-components/political-status/i18n'
import WorkplaceTypeI18n from '@business-components/workplace-type/i18n'
import RelationI18n from '@business-components/relation/i18n'
import TranferReasonI18n from '@business-components/transfer-reason/i18n'
import FlowStatusI18n from '@business-components/student-flow-status/i18n'
import GraduateDirectionI18n from '@business-components/graduate-direction/i18n'
import AssessmentLossI18n from '@business-components/assessment-loss/i18n'
import PayTypeI18n from '@business-components/pay-type/i18n'
import AcademicDirectionI18n from '@business-components/academic-direction/i18n'
import StudentCategoryI18n from '@business-components/student-category/i18n'
import BloodTypeI18n from '@components/blood-type/i18n'

const messages: Messages = {
  'zh-CN': {
    boolean: {
      1: '是',
      0: '否',
      null: '-',
      undefined: '-',
      '': '-',
    },
    gender: {
      0: '男',
      1: '女',
      '-1': '保密',
      null: '-',
      undefined: '-',
      '': '-',
    },
    'boolean.': '-',
    'gender.': '-',
    refresh: '刷新',
    retry: '重试',
    freshStudent: '应届生',
  },
  'en-US': {},
}

const locales: Locale[] = ['zh-CN', 'en-US']

const merges: Record<string, any> = {
  researchField: ResearchFieldI18n,
  studentMark: StudentMarkI18n,
  enrollType: EnrollTypeI18n,
  sourceChannel: StudentSourceI18n,
  householdIncome: HouseholdIncomeI18n,
  residenceType: ResidenceTypeI18n,
  IDType: IDTypeI18n,
  transferReason: TransferReasonI18n,
  enrollScore: EnrollScoreI18n,
  divideType: DivideClassTypeI18n,
  statusType: StatusTypeI18n,
  direction: DirectionI18n,
  studentStatus: StudentStatusI18n,
  education: EducationI18n,
  politicalStatus: PoliticalStatusI18n,
  workplaceType: WorkplaceTypeI18n,
  relation: RelationI18n,
  tranferReason: TranferReasonI18n,
  flowStatus: FlowStatusI18n,
  graduateDirection: GraduateDirectionI18n,
  checkLost: AssessmentLossI18n,
  payType: PayTypeI18n,
  AcademicDirection: AcademicDirectionI18n,
  StudentCategory: StudentCategoryI18n,
  bloodType: BloodTypeI18n,
}

export default {
  messages: locales.reduce((messages, locale) => {
    Object.keys(merges).forEach(field => {
      messages[locale][field] = merges[field].messages[locale]
      messages[locale][`${field}.`] = '-'
    })
    return messages
  }, messages),
}
