


















































































































































































import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Watch, Prop, Model, Ref } from 'vue-property-decorator'
import { Student, Constant, School } from 'xuexin-vuex'
import StudentTag from '@store/modules/student-tag'
import StudentTagType from 'types/student-tag'
import { Spin, Form, FormItem, Modal, Checkbox } from 'view-design'
import AppModal from '@components/app-modal'
import AppRadioGroup from '@components/app-radio-group'
import { BusinessTypeSelect } from '@business-components/business-type'
import { SchoolClassTypeSelect } from '@business-components/school-class-type'
import { DirectionRadioGroup } from '@business-components/direction'
import { SemesterSelect } from '@business-components/semester'
import { SchoolSelect } from '@business-components/school'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import DictionarySelect from '@business-components/dictionary-select'
import { GradeSelect } from '@business-components/grade'

import i18n from './i18n'

interface Data {
  parentUnitID: number
  termID: number
  unitID: number
  xuexinID: string
  direction: string
}

interface Model {
  termID: number
  unitID: number
  manageTypeID: number
  unitClassTypeID: number
  classID: number
  xuexinIDs: string[]
}

const components = {
  AppModal,
  Spin,
  Form,
  FormItem,
  BusinessTypeSelect,
  SchoolClassTypeSelect,
  DirectionRadioGroup,
  SemesterSelect,
  SchoolPhaseSelect,
  DictionarySelect,
  GradeSelect,
  Checkbox,
  SchoolSelect,
  AppRadioGroup,
}

@Component({ name: 'ReEnrollmentModal', components, i18n })
export default class ReEnrollmentModal extends Vue {
  @Student.Action('fetch') private readonly fetch!: Student.Action.Fetch
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('itemStatus')
  private readonly getStatus!: Student.Getter.ItemStatus
  @Student.Getter('item') private readonly getItem!: Student.Getter.Item
  @School.Action('fetch')
  private readonly fetchSchoolStatus!: School.Action.Fetch
  @School.Getter('item')
  private readonly getSchoolStatus!: School.Getter.Item
  @StudentTag.Action('fetch')
  private readonly fetchTag!: StudentTagType.Action.Fetch
  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Data
  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 're-enrollment-modal'
  private hidden = true
  private visible = false
  private loading = true
  private verifying = false
  private model: any = {
    freshStudent: true,
  }

  private tagArr: any = [
    {
      columnCode: 'freshStudent',
      columnName: '应届生',
      columnType: '3',
      forceShow: 1,
      kindCode: 'enroll',
      kindName: ' 入学信息',
      parentCode: null,
      required: 0,
      value: null,
      valueName: 'null',
    },
    {
      columnCode: 'academicDirection',
      columnName: '学业方向/入学分类',
      columnType: '1',
      forceShow: 1,
      kindCode: 'enroll',
      kindName: ' 入学信息',
      parentCode: null,
      required: 0,
      value: null,
      valueName: '-',
    },
    {
      columnCode: 'studentCategory',
      columnName: '学生类别',
      columnType: '1',
      forceShow: 1,
      kindCode: 'enroll',
      kindName: ' 入学信息',
      parentCode: null,
      required: 0,
      value: null,
      valueName: 'null',
    },
  ]
  private tagItem: any = {}
  private columns: any = {}
  private campusLenght = 0
  private aa: string | undefined = void 0

  get updating() {
    return this.getStatus(this.data.xuexinID).updating === true
  }
  get fetching() {
    return this.getStatus(this.data.xuexinID).fetching === true
  }

  get itemStatus() {
    return this.getStatus(this.data.xuexinID)
  }

  get student() {
    return this.getItem(this.data.xuexinID)
  }

  get schoolStatus() {
    return this.getSchoolStatus(this.model.school!)
  }

  get direction() {
    const directions = [
      { schoolDepartTypeID: 1, schoolDepartTypeName: '国内' },
    ]

    this.$set(this.model, 'direction', 1)
    return directions
  }

  @Watch('value', { immediate: true })
  watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('fetching')
  watchfetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      this.hidden = false
    }
  }

  @Watch('updating')
  watchUpdating(updating: boolean, previous: boolean) {
    if (this.visible === true && updating === false && previous === true) {
      this.stopInteraction()
      if (this.itemStatus.updatingError !== null) {
        return this.$Message.error(this.itemStatus.updatingError)
      }

      this.$Message.success('操作成功')
      this.$emit('on-finish')
      this.$emit('on-hidden')
    }
  }

  @Watch('model.term')
  watchTerm(term: number, prev: number) {
    if (term) {
      this.fetchTagItem(term, this.model.school)
    }
  }
  @Watch('model.school')
  watchSchool(unitID: number, prev: number) {
    if (unitID) {
      this.fetchSchoolStatus({
        unitID: unitID,
        organID: this.data.parentUnitID,
      })

      this.fetchTagItem(this.model.term, unitID)
    }
  }
  // 监听转至方向
  @Watch('model.direction', { immediate: true })
  private watchDirection(value: number, prev: number) {
    this.resetTagValue(value, this.model.phase)
  }
  //学段变化为非高中，重置应届生为true
  @Watch('model.phase')
  private watchPhaseID(value: number) {
    this.resetTagValue(this.model.direction, value)
  }
  private getLength(list: any) {
    this.campusLenght = list.length

    if (list.length === 1) {
      this.model.school = list[0].unitID
    }
  }

  private stopInteraction() {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)

    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }

  private handleOK() {
    this.$set(this.$data, 'verifying', true)
    this.loading = true
    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        this.loading = false
        /** 校验失败 */
        return this.stopInteraction()
      }

      const cols = this.columns
        .map((element: any) => {
          return Object.assign({}, element, {
            value: this.model[element.columnCode],
          })
        })
        .filter((i: any) => {
          return (
            i.columnCode !== 'academicDirection' &&
            i.columnCode !== 'freshStudent' &&
            i.columnCode !== 'studentCategory'
          )
        })

      const { academicDirection, studentCategory } = this.model

      /** 重新入校 */
      this.update(
        Object.assign(
          { columns: cols },
          {
            actionType: Constant.ActionType.Rejoin,
            termID: this.data.termID,
            unitID: this.model.school,
            xuexinID: this.data.xuexinID,
            academicDirection: academicDirection,
            freshStudent: this.model.freshStudent ? 1 : 0,
            studentCategory:
              academicDirection === '5' ? void 0 : studentCategory,
          }
        )
      )
    })
  }

  private fetchTagItem(termID: number, unitID: number) {
    this.fetchTag({
      xuexinID: this.data.xuexinID,
      unitID: unitID,
      termID: termID,
      type: 3,
    }).then(({ result }: any) => {
      if (result) {
        this.tagItem = {
          freshStudent: result.freshStudent === 0 ? false : true,
          academicDirection: result.academicDirection
            ? `${result.academicDirection}`
            : void 0,
          studentCategory: result.studentCategory
            ? `${result.studentCategory}`
            : void 0,
          hasTermBill: result.hasTermBill,
        }

        this.aa = result.studentCategory
          ? `${result.studentCategory}`
          : void 0
      } else {
        this.tagItem = {
          freshStudent: true,
          academicDirection: void 0,
          studentCategory: void 0,
          hasTermBill: 0,
        }
        this.aa = void 0
      }

      if (this.model.phase == 4 || this.model.phase == 3) {
        const {
          freshStudent,
          academicDirection,
          studentCategory,
        } = this.tagItem
        this.$set(this.model, 'freshStudent', freshStudent)
        this.$set(this.model, 'academicDirection', academicDirection)
        this.$set(this.model, 'studentCategory', studentCategory)
      }
    })
  }

  private fetchStudent() {
    this.hidden = true
    this.fetch({
      xuexinID: this.data.xuexinID,
      studentID: this.data.xuexinID,
      unitID: this.data.unitID,
      termID: this.data.termID,
      authCode: '050602',
      platform: 5,
    }).then(({ result }: any) => {
      this.columns = result?.columns?.filter((item: any) => {
        return (
          item.kindCode === 'enroll' &&
          item.columnCode !== 'channelLevelTwo' &&
          item.columnCode !== 'channelLevelThree' &&
          item.columnCode !== 'gradeIntention'
        )
      })
    })
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleHidden() {
    this.$emit('on-hidden')
    this.stopInteraction()
    this.$set(this.$data, 'hidden', true)
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      /** 初始化 model */
      this.fetchStudent()

      this.$set(
        this.$data,
        'model',
        Object.assign({}, this.model, {
          termID: this.data.termID,
          unitID: this.data.unitID,
          xuexinID: this.data.xuexinID,
          school: this.data.unitID,
        })
      )
    }

    this.$emit('on-visible-change', visible)
  }
  //重置学生标签默认值
  private resetTagValue(direction: number, phaseID: number) {
    if (direction == 1) {
      const {
        freshStudent,
        academicDirection,
        studentCategory,
      } = this.tagItem

      if (phaseID === 4 || phaseID === 3) {
        this.$set(this.model, 'freshStudent', freshStudent)
        this.$set(this.model, 'academicDirection', academicDirection)
        this.$set(this.model, 'studentCategory', studentCategory)
      } else {
        this.$set(this.model, 'freshStudent', true)
        this.$set(this.model, 'academicDirection', '1')
        this.$set(this.model, 'studentCategory', studentCategory || '3')
      }
    } else {
      //
    }
  }
}
