export default {
  messages: {
    'zh-CN': {
      loading: '加载中...',
      title: '跳留级',
      label: {
        dataID: '类别',
        level: '级',
        manageTypeID: '报读类型',
        unitClassTypeID: '校本班型',
        classID: '转至班级',
        termID: '学期',
      },
      dataID: {
        1: '降级',
        2: '留级',
        3: '跳级',
      },
      error: {
        dataID: '请选择类别',
        level: '请选择级',
        manageTypeID: '请选择报读类型',
        unitClassTypeID: '请选择校本班型',
        classID: '请选择转至班级',
      },
    },
    'en-US': {},
  },
}
