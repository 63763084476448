export const messages: Messages = {
  'zh-CN': {
    title: '校际互转',
    label: {
      'tranfer-status': '互转状态',
      'tranfer-date': '互转日期',
      school: '学校',
      campus: '校区',
      direction: '方向',
      term: '学期',
      'manage-type': '报读类型',
      grade: '年级',
      'class-type': '校本班型',
      'get-date': '到校日期',
      mark: '备注',
      applicant: '申请人',
      'apply-date': '申请时间',
      in: '转至',
      out: '转出',
      freshStudent: '应届生',
      studentCategory: '学生类别',
      academicDirection: '学业方向/入学分类',
    },
    messages: {
      success: '操作成功',
      'valid-school': '学校不能为空',
      'valid-campus': '校区不能为空',
      'valid-type': '报读类型不能为空',
      'valid-phase': '学段不能为空',
      'valid-grade': '年级不能为空',
      'valid-class-type': '校本班型不能为空',
      'valid-date': '到校日期不能为空',
    },
    error: {
      freshStudent: '请选择应届生',
      studentCategory: '请选择学生类别',
      academicDirection: '请选择学业方向/入学分类',
    },
    placeholder: '选择日期',
  },
  'en-US': {
    title: '校际互转',
    label: {
      'tranfer-status': '互转状态',
      'tranfer-date': '互转日期',
      school: '学校',
      campus: '校区',
      direction: '方向',
      term: '学期',
      'manage-type': '报读类型',
      grade: '年级',
      'class-type': '校本班型',
      'get-date': '到校日期',
      mark: '备注',
      applicant: '申请人',
      'apply-date': '申请时间',
      in: '转至',
      out: '转出',
    },
    messages: {
      success: '操作成功',
      'valid-school': '学校不能为空',
      'valid-campus': '校区不能为空',
      'valid-type': '报读类型不能为空',
      'valid-phase': '学段不能为空',
      'valid-grade': '年级不能为空',
      'valid-class-type': '校本班型不能为空',
      'valid-date': '到校日期不能为空',
    },
    placeholder: '选择日期',
  },
}

export default { messages }
