import { LocaleMessages } from 'vue-i18n'

export const MobilePhoneReg = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
export const HanReg = /\p{Unified_Ideograph}/u
export const HanCNReg = /^[\s\S\w\W\d\D]{1,}$/

export enum ErrorCode {
  FieldError = 'FIELD-ERROR-10001',
  HttpError = 'HttpError',
  CodeError = 'CodeError',
}

export enum OS {
  PC = 1,
  MOBILE = 2,
}

export enum DeviceModel {
  PC = 1,
}

export enum MessageSendType {
  Divide = 0, // 分班学生
  InClass = 1, // 在班学生
  Graduate = 2, // 毕业学生
}

export enum ComponentType {
  CheckboxGroup = 'checkbox-group',
  Select = 'select',
  Tag = 'tag',
  Radio = 'radio',
  RadioGroup = 'radio-group',
  Badge = 'badge',
  Text = 'text',
  Cascader = 'cascader',
}

export enum ActionType {
  Create = 'create',
  Edit = 'edit',
  View = 'view',
}

// 字段枚举
export enum ColumnField {
  Num = 'num',
  GradeName = 'gradeName',
  SchoolSystemName = 'schoolSystemName',
}

export function noop(): void {}

function pad(val: string, length: number = 2, data: string | number = ' ') {
  val = String(val)
  while (val.length < length) {
    val = data + val
  }
  return val
}

export function encryptToBase64(str: string, key: string) {
  return window.XXTEA.encryptToBase64(str, key)
}

export function decryptFromBase64(base64: string, key: string) {
  return window.XXTEA.decryptFromBase64(base64, key)
}

// 根据小写数字生成大写
export function convertNumToCH(enNum: number) {
  const cnArray1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const cnArray2 = ['十', '百', '千', '万']
  const num = enNum - 0
  const snum = '' + num
  let str = ''
  for (let i = 0; i < snum.length; i++) {
    if (snum[i] == '0' && i == snum.length - 1) {
      continue
    }
    if (snum[i] == '1' && i == 0 && snum.length == 2) {
      //
    } else {
      str += cnArray1[Number(snum[i])]
    }
    if (snum[i] != '0' && i != snum.length - 1) {
      str += cnArray2[snum.length - i - 2]
    }
  }
  return str
}

// 3位数逗号分割
export function commaSegmentation(num: number) {
  const newNum = (num || 0).toString()
  let number = 0,
    floatNum = '',
    intNum = ''
  if (newNum.indexOf('.') > 0) {
    number = newNum.indexOf('.')
    floatNum = newNum.substr(number)
    intNum = newNum.substring(0, number)
  } else {
    intNum = newNum
  }
  const result = []
  let counter = 0
  const intNums = intNum.split('')
  for (let i = intNums.length - 1; i >= 0; i--) {
    counter++
    result.unshift(intNums[i])
    if (!(counter % 3) && i != 0) {
      result.unshift(',')
    }
  }
  return result.join('') + floatNum || ''
}

// 时间戳转换
export function timestampToTime(
  timestamp: string | number,
  key = 'date',
  symbol = '.'
) {
  const date = new Date(
    typeof timestamp === 'string' ? Number(timestamp) : timestamp
  )
  const Y = date.getFullYear() + `${symbol}`
  const M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + `${symbol}`
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const H =
    (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const Min =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()

  if (key == 'hours') {
    return Y + M + D + '  ' + '  ' + H + Min
  }
  return Y + M + D
}

//时间转时间戳
export function timeToTimeStamp(timestamp: string | number) {
  const date = new Date(
    typeof timestamp === 'string' ? Number(timestamp) : timestamp
  )
  return date.getTime()
}

// 转换错误消息结构
export function convertFields(fields: Array<{ field: string; msg: string }>) {
  return fields.reduce((_set: Record<string, any>, fieldItem) => {
    return (_set[fieldItem.field] = fieldItem.msg), _set
  }, {})
}

/**
 * 时间转换工具
 */

export const DateUtil = {
  format(date: string | number | Date, format: string = 'yyyy-MM-dd') {
    if (date === null || date === void 0) {
      return ''
    }
    if (typeof date === 'string') {
      date = Number(date)
    }

    if (typeof date === 'number') {
      date = new Date(date)
    }

    const o: Record<string, any> = {
      'M+': date.getMonth() + 1, //月份
      'd+': date.getDate(), //日
      'h+': date.getHours(), //小时
      'm+': date.getMinutes(), //分
      's+': date.getSeconds(), //秒
      'q+': Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
    }

    if (/(y+)/.test(format)) {
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }

    for (let k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }

    return format
  },
  parse(date: Date, format: string = 'yyyy-MM-dd') {
    if (date instanceof Date === false) {
      return date
    }

    return DateUtil.format(date.getTime(), format)
  },
}

/**
 * 非空验证
 * @param value 需要校验的值
 */
export function isNonNull(value: any): boolean {
  return value !== void 0 && value !== -1 && value !== null
}

export const GlobalMessages: LocaleMessages = {
  'zh-CN': {
    'student-status': {
      0: '正常',
      1: '休学',
      2: '空挂学籍',
      3: '外出艺体',
    },
    'flow-status': {
      0: '正常',
      1: '试读',
      2: '校际互转',
      3: '转学',
      4: '短期',
      5: '转试',
      6: '转短',
      7: '校短',
      8: '校试',
    },
    boolean: {
      1: '是',
      0: '否',
      undefined: '-',
      null: '-',
    },
    gender: {
      0: '男',
      1: '女',
      '-1': '保密',
      null: '保密',
      undefined: '保密',
    },
    'student-type': {
      change: '转',
      school: '校',
      trial: '试',
      short: '短',
      'change-trial': '转试',
      'change-short': '转短',
      'school-short': '校短',
      'shcool-trial': '校试',
    },
    'confirm-text': {
      cancel: '取消',
      ok: '确定',
    },
  },
  'en-US': {},
}

/**
 * 合并国际化数据
 * @param messages
 */
export function mergeLocaleMessage(messages: LocaleMessages) {
  return {
    messages: Object.keys(GlobalMessages).reduce(
      (localMessages: LocaleMessages, locale: string) => {
        localMessages[locale] = Object.assign(
          {},
          GlobalMessages[locale],
          messages[locale]
        )
        return localMessages
      },
      {}
    ),
  }
}

export function upperFirst(string: string) {
  if (!string) {
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const isArray = (val: unknown): val is [] => {
  return Array.isArray(val) === true
}

export const convertToMulti = (val?: unknown): string | undefined => {
  let array: unknown[] = val as unknown[]

  if (isArray(array) === false) {
    array = [val]
  }

  const list = array.filter(val => Boolean(val) || val === 0)

  if (list.length === 0) {
    return void 0
  }

  return list.join(',')
}

export const validValue = (val: any) => {
  return Boolean(val) || val === 0
}

export const convertSearchParameter = (val: any): any => {
  return Object.keys(val).reduce((parameter: any, key: string) => {
    const value = val[key]

    switch (true) {
      case Array.isArray(value) && value.length === 0 /** 空数组时，置空。 */:
      case `${value}`.trim() === '' /** 空字符串 */:
      case validValue(value) === false /** 无效参数 */:
        // 以上情况置空参数
        break
      case value instanceof Date /** 转换时间类型参数为时间戳 */:
        parameter[key] = value.getTime()
        break
      case Array.isArray(value) && value.length !== 0 /** 数组且有长度 */:
        parameter[key] = []
          .concat(value)
          .map((item: any, index) => {
            if (item instanceof Date) {
              return index === 0
                ? item.getTime() // 开始时间不处理
                : item.getTime() + 1000 * 60 * 60 * 24 - 1 // 结束时间加一天
            }
            return item
          })
          .filter(item => Boolean(item) || item === 0)

        /** 若长度为 0,则清空参数 */
        if (parameter[key].length === 0) {
          delete parameter[key]
        }
        break
      default:
        parameter[key] = validValue(value) ? value : void 0
    }
    return parameter
  }, {})
}

export const isString = (val: unknown): val is string => {
  return typeof val === 'string'
}

export const isNumber = (val: unknown): val is number => {
  return typeof val === 'number'
}

export const isValidDate = (val: unknown): val is Date => {
  return val instanceof Date && !isNaN(val.getTime())
}

export const toNumber = (val: unknown): number | typeof val => {
  const n = parseFloat(val as string)
  return isNaN(n) ? val : n
}

export const toString = (val: unknown): string | typeof val => {
  return val !== void 0 && val !== null ? String(val) : val
}
