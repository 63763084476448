import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Semester } from 'xuexin-vuex'
import { ComponentType, upperFirst, isArray, convertToMulti } from '@util'
import AppSelect from '@components/app-select'

type ViewType = 'text'
const valueField = 'termID'
const labelField = 'termName'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `Semester${upperFirst(type)}`
  const components = {}
  @Component({ name, components, inheritAttrs: false })
  class SemesterComponent extends Vue {
    @Semester.Action('fetch')
    private readonly fetchList!: Semester.Action.FetchList
    @Semester.Getter('listStatus')
    private readonly getStatus!: Semester.Getter.ListStatus
    @Semester.Getter('list')
    private readonly getList!: Semester.Getter.List

    /** 0: 默认学期, 1: 向后追加两个学期, 2: 截止学期 */
    @Prop({ type: Number, default: 1 }) private readonly type?: number
    @Prop({ type: [Number, Array] }) private readonly unitID?:
      | number
      | Array<number>
    @Prop({ type: Boolean, default: true }) private readonly cache!: boolean
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType?: ViewType
    /** 0: 默认全部启用学期, 1: 大于默认的学期, -1: 小于默认的学期 */
    @Prop({ type: Number, default: 0 }) private readonly filterType?: number
    @Prop({ type: Number }) private readonly defaultValue?: number

    private currentID: number | null = null

    get classes() {
      return {
        [`semester-${type}`]: true,
        [`semester-${type}-${this.viewType}`]: this.viewType !== void 0,
      }
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.loading = this.fetching
          props.error = this.status.fetchingError
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    get parameter() {
      return {
        type: this.type,
        unitID: convertToMulti(this.unitID),
      }
    }

    get status() {
      return this.getStatus(this.parameter)
    }

    get fetching() {
      return this.status.fetching !== false
    }

    get list() {
      const list = this.getList(this.parameter).filter(item => {
        // 过滤被禁用的数据
        if (this.defaultValue) {
          if (this.filterType === -1) {
            return item.enable !== 0 && item.termID <= this.defaultValue
          } else if (this.filterType === 1) {
            return item.enable !== 0 && item.termID >= this.defaultValue
          } else {
            return item.enable !== 0
          }
        }

        return item.enable !== 0
      })

      return this.extra.concat(list)
    }

    get value() {
      return Number(this.$attrs.value)
    }

    @Watch('parameter')
    watchParameter() {
      this.fetch()
    }

    @Watch('fetching')
    watchFetching(fetching: boolean, previous: boolean) {
      if (fetching === false && previous === true) {
        const current = this.list.find(item => item.isCurrent === 1)
        if (current !== void 0 && current !== null) {
          this.$set(this.$data, 'currentID', current.termID)
        }
      }
    }

    created() {
      this.fetch()
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        tag = 'span'
        const item = this.list.find(item => item[valueField] === this.value)
        const text =
          item !== void 0
            ? item[labelField]
            : this.$i18n.locale === 'en-US'
            ? '-'
            : '无匹配项'
        children = [text]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }

    mounted() {}

    private fetch() {
      if (
        this.status.fetching === true || // 正在请求中
        (this.cache === true && this.status.loaded === true) // 允许缓存且已有缓存
      ) {
        // 以上情况，不需要请求数据。
        return void 0
      }

      this.fetchList(this.parameter)
    }
  }

  return SemesterComponent
}

export const SemesterSelect = createComponent(AppSelect, ComponentType.Select)
