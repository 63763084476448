import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Glass, Constant } from 'xuexin-vuex'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'

type ViewType = 'text'
const valueField = 'classID'
const labelField = 'className'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `Glass${upperFirst(type)}`
  const components = {}
  @Component({ name, components, inheritAttrs: false })
  class GlassComponent extends Vue {
    @Prop({ type: String }) private readonly viewType?: ViewType
    @Prop({ type: Number }) private readonly termID?: number
    @Prop({ type: Number }) private readonly unitID?: number
    @Prop({ type: [Number, Array] }) private readonly schoolDepartID?:
      | number
      | number[]
    @Prop({ type: [Number, Array] }) private readonly phaseID?: any
    @Prop({ type: [Number, Array] }) private readonly unitGradeID?: any
    @Prop({ type: [Number, Array] }) private readonly manageTypeID?: any
    @Prop({ type: [Number, Array] }) private readonly unitClassTypeID?: any
    @Prop({ type: Number, default: 0 }) private readonly order!: number
    @Prop({ type: Array }) private readonly xuexinIDs?: string[]
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]

    /* 是否根据就读信息查询 */
    @Prop({ type: Boolean }) private readonly isStudy?: boolean
    @Prop({ type: Boolean, default: true }) private readonly cache!: boolean
    @Prop({ type: Boolean, default: false }) private readonly transfer!: boolean

    @Glass.Action('fetch')
    private readonly fetchList!: Glass.Action.Fetch
    @Glass.Getter('listStatus')
    private readonly getStatus!: Glass.Getter.ListStatus
    @Glass.Getter('list') private readonly getList!: Glass.Getter.List

    get classes() {
      return {
        [`glass-${type}`]: this.viewType === void 0,
        [`glass-${this.viewType}`]: this.viewType !== void 0,
      }
    }

    get props() {
      const props: Record<string, any> = {}
      if (this.viewType === 'text') {
        return props
      }

      switch (type) {
        case ComponentType.Select:
          props.data = this.list
          props.loading = this.fetching
          props.error = this.status.fetchingError
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    get parameter(): any {
      return {
        termID: this.termID,
        unitID: this.unitID,
        schoolDepartID: this.schoolDepartID,
        phaseID: this.phaseID,
        unitGradeID: this.unitGradeID,
        manageTypeID: this.manageTypeID,
        unitClassTypeID: this.unitClassTypeID,
        xuexinIDs: this.xuexinIDs,
        transfer: this.transfer,
      }
    }

    /** 是否为有效参数 */
    get valid() {
      return (
        (Boolean(this.unitID) || this.unitID === 0) &&
        (Boolean(this.termID) || this.termID === 0)
      )
    }

    get status() {
      return this.getStatus(this.parameter)
    }

    get fetching() {
      return this.status.fetching !== false
    }

    get fetchError() {
      return this.status.fetchingError || null
    }

    get list() {
      if (this.valid !== true) {
        return this.extra
      }

      const list = this.getList(this.parameter)
        .filter(item => {
          /** 过滤被禁用的数据 */
          return item.enable !== 0
        })
        .map(item => {
          return Object.assign({
            ...item,
            className: Boolean(item.aliasClassName)
              ? `${item.className}(${item.aliasClassName})`
              : `${item.className} `,
          })
        })

      return this.extra.concat(list)
    }

    /** 参数发生变化时，重新请求数据 */
    @Watch('parameter')
    watchParameter() {
      this.fetch()
    }

    @Watch('fetching')
    watchFetching(fetching: boolean, previous: boolean) {
      if (fetching === false && previous === true) {
        if (this.fetchError !== null) {
          return this.$Message.error(this.fetchError)
        }
      }
    }

    private fetch() {
      if (
        this.status.fetching === true /** 正在请求中 */ ||
        // (this.cache === true &&
        //   this.status.loaded === true) /** 允许缓存且已有缓存 */ ||
        this.valid === false
      ) {
        // 以上情况，不需要请求数据
        return void 0
      }
      let actionType = Constant.ActionType.Glass
      if (this.isStudy) {
        actionType = Constant.ActionType.ByStudent
        this.fetchList({
          actionType,
          termID: this.termID,
          unitID: this.unitID,
          xuexinIDs: this.xuexinIDs,
          searches: this.parameter,
          order: this.order,
        })
        return
      }
      this.fetchList({
        actionType,
        searches: this.parameter,
        order: this.order,
      })
    }

    created() {
      this.fetch()
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        tag = 'span'
        const item = this.list.find(
          item => item[valueField] === Number(this.$attrs.value)
        )
        const text = item !== void 0 ? item[labelField] : '-'
        children = [text]
      }
      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return GlassComponent
}

export const GlassSelect = createComponent(AppSelect, ComponentType.Select)
