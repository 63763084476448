/**
 * 学生管理菜单路由
 */

/** 空路由面页 */
import RouterViewLayout from '@layouts/router-view-layout'

/** 在班学生 */
import InClassRoute from './in-class'

/** 转学学生 */
import TransferRoute from './transfer'

/** 毕业学生 */
import GraduationRoute from './graduation'

/** 试读学生 */
import TrialReadingRoute from './trial-reading'

/** 短期入学学生 */
import ShortTermRoute from './short-term'

/** 校际互转学生 */
import InnerTransfer from './inner-transfer'
/** 内转学生 */
import InsideTransfer from './inside-transfer'

export default {
  path: '/students',
  name: 'student-management',
  redirect: { name: 'in-class' },
  meta: { title: '学生管理', authCode: '0506' },
  component: RouterViewLayout,
  props: { name: 'student-management' },
  children: [
    InClassRoute,
    TransferRoute,
    GraduationRoute,
    InnerTransfer,
    TrialReadingRoute,
    ShortTermRoute,
    InsideTransfer,
  ],
}
