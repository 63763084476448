import Vue, { CreateElement, VNodeData, VNodeChildren } from 'vue'
import i18n, { DivideClassType } from '../i18n'
import { Prop, Component } from 'vue-property-decorator'

const messages: Messages = {
  'zh-CN': {
    [`${DivideClassType.Enrollment}`]: '取消录取',
    [`${DivideClassType.InnerTransfer}`]: '查看',
    [`${DivideClassType.Upgrade}`]: '撤销',
  },
  'en-US': {
    [`${DivideClassType.Enrollment}`]: '取消录取',
    [`${DivideClassType.InnerTransfer}`]: '查看',
    [`${DivideClassType.Upgrade}`]: '撤销',
  },
}

@Component({ name: 'DivideClassTypeTag', i18n })
export default class DivideClassTypeTag extends Vue {
  @Prop({ type: Object, required: true }) private readonly data!: {
    value: number
    tag: {
      pay: number
      entry: number
      date: number
    }
  }

  private readonly prefixCls = 'divide-class-type'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get isCanAction() {
    /** 已缴费状态下不能取消撤销 */
    const { value } = this.data
    return (
      value === DivideClassType.Enrollment /**新生 */ ||
      value === DivideClassType.Upgrade /** 直升 */ ||
      value === DivideClassType.Reread ||
      value === DivideClassType.Reentry ||
      value === DivideClassType.GradReentry
    )
  }

  render(createElement: CreateElement) {
    const data: VNodeData = {
      class: this.classes,
    }

    const children: VNodeChildren = []

    /** 分班类型 */
    children.push(
      createElement('span', { class: `${this.prefixCls}_tag` }, [
        `${this.$t(`${this.data.value}`)}`,
      ])
    )

    /** 是否可以操作 */
    if (this.isCanAction === true) {
      // || this.data.tag === null
      const { value, tag } = this.data
      /** pay: 0未缴费 新生未缴费或为空*/
      if (
        [
          DivideClassType.Enrollment,
          DivideClassType.Reentry,
          DivideClassType.GradReentry,
          DivideClassType.Reread,
        ].includes(value)
      ) {
        if (tag.pay !== 0 /**未缴费*/ && tag.pay !== -1) {
          children.push(
            createElement('span', {
              class: `text-link ${this.prefixCls}_button`,
              style: { marginLeft: '5px' },
            })
          )
        } else {
          children.push(
            createElement(
              'span',
              {
                class: `text-link ${this.prefixCls}_button`,
                style: { marginLeft: '5px' },
                on: {
                  click: this.handleClick,
                },
              },
              ['取消录取']
            )
          )
        }
      } else if (value === DivideClassType.Upgrade) {
        /** 直升 */

        /** 没开学，没缴费  */
        if (tag.pay === 0 || tag.pay === -1) {
          children.push(
            createElement(
              'span',
              {
                class: `text-link ${this.prefixCls}_button`,
                style: { marginLeft: '5px' },
                on: {
                  click: this.handleClick,
                },
              },
              ['撤销']
            )
          )
        } else {
          children.push(
            createElement('span', {
              class: `text-link ${this.prefixCls}_button`,
              style: { marginLeft: '5px' },
            })
          )
        }
      } else {
        children.push(
          createElement(
            'span',
            {
              class: `text-link ${this.prefixCls}_button`,
              style: { marginLeft: '5px' },
              on: {
                click: this.handleClick,
              },
            },
            [messages[this.$i18n.locale][value]]
          )
        )
      }
    }

    return createElement('span', data, children)
  }

  private handleClick() {
    this.$emit('click')
  }
}
