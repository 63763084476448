export const messages: Messages = {
  'zh-CN': {
    title: '分班',
    label: '就读班级',
    text: {
      select: '请选择班级',
      valid: '请选择班级',
      success: '分班成功',
    },
  },
  'en-US': {
    title: '分班',
    label: '就读班级',
    text: {
      select: '请选择班级',
      valid: '请选择班级',
    },
  },
}

export default { messages }
