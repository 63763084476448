/** 流失人数统计对比分析 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import LossCompareAnalysis from '@pages/statistic-reports/loss-compare-analysis'

const children: RouteConfig[] = []

children.push({
  path: '/reports/loss-compare-analysis',
  name: 'loss-compare-analysis-report',
  component: LossCompareAnalysis,
  meta: { ignore: true, authCode: '050710', parentAuthCode: '0507' },
})

export default {
  path: '/reports',
  name: 'loss-compare-analysis',
  redirect: { name: 'loss-compare-analysis-report' },
  meta: { title: '流失人数统计对比分析', authCode: '050710' },
  component: RouterViewLayout,
  props: { name: 'loss-compare-analysis' },
  children,
}
