import { Constant } from 'xuexin-vuex'

export type StudentCategory = Constant.EnrollType
export const StudentCategory = Constant.EnrollType

export const StudentCategoryList = [
  StudentCategory.Culture,
  StudentCategory.Art,
  StudentCategory.IICK,
  StudentCategory.International,
]

export const messages: Messages = {
  'zh-CN': {
    [`${StudentCategory.Culture}`]: '文化',
    [`${StudentCategory.Art}`]: '艺体',
    [`${StudentCategory.IICK}`]: '港澳台',
    [`${StudentCategory.International}`]: '外国籍',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${StudentCategory.Culture}`]: '文化',
    [`${StudentCategory.Art}`]: '艺体',
    [`${StudentCategory.IICK}`]: '港澳台',
    [`${StudentCategory.International}`]: '外国籍',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
