


































import { Component, Prop, Mixins, Watch, Ref } from 'vue-property-decorator'
import PagePropsMixins from '@mixins/page-props-mixins'
import { TableColumn } from 'view-design'

import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'

import AppTable from '@components/app-table'
import { ContentTypeSelect } from '@business-components/statistical-content-type'
import i18n from './i18n'
import Export from '@store/modules/export'
import ExportTyping from '../../../../types/export'
import PageTyping from '../../../../types/page'
import StudentDistribution from '@store/modules/student-distribution'
import StudentDistributionType from 'types/student-distribution'

import { setReportData, spanChildMethod } from '@util/report'
import getPageColumns from '@components/report-toolbar/columns'

const components = {
  ViewLayout,
  ReportSearch,
  ReportToolbar,
  AppTable,
  ContentTypeSelect,
}
@Component({ name: 'StudentDistributionReport', components, i18n })
export default class StudentDistributionReport extends Mixins(
  PagePropsMixins
) {
  @StudentDistribution.Action('fetch') private readonly fetchTable!: any
  @StudentDistribution.Getter('list')
  private readonly getList!: StudentDistributionType.Getter.List
  @StudentDistribution.Getter('listStatus')
  private readonly listStatus!: StudentDistributionType.Getter.ListStatus
  @StudentDistribution.Action('export') private readonly export!: any

  private readonly prefixCls = 'school-distribution-page'
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private searchParameter: any = {}
  private defaultData: any = []
  private tParameter = {
    countType: 0,
    countContentType: 1,
  }
  /**  保留原始数据 */
  private defaultData2: any = this.defaultData.concat([])

  /** 合并行 */
  private rowspans: any = {}
  /** 合并前四项 */
  private sumKey: any = []

  /** 合计行下标 {index:下标，type:1-2-3-4(合计类型)} */
  private inventedDataIndexArr: any = []

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  get authCode() {
    return this.$route.meta.authCode
  }
  get noDataText() {
    if (Boolean(this.parameter.termID) === false) {
      return this.$t('message.noParameter')
    }
    return this.$t('message.noDataText')
  }
  // 表头
  get tableColumns(): any {
    return getPageColumns('StudentDistribution')
      .map((item: any) => {
        return {
          title: item.title,
          key: item.code,
          width: 100,
          fixed: 'left',
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat([
        { title: this.typeName, key: 'typeValueStr' },
        { title: '学生人数', key: 'studentCount' },
      ])
  }
  get dataSources() {
    return this.getList()
  }
  get typeName() {
    return this.dataSources ? this.dataSources[0]?.typeName : ''
  }
  get status() {
    return this.listStatus()
  }
  get fetching() {
    return this.status.fetching === true
  }
  // 监听加载状态
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.status.fetchingError !== null &&
        this.status.fetchingError !== void 0
      ) {
        this.$Message.error(this.status.fetchingError)
        return
      }

      let result = setReportData({
        list: this.dataSources,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
        countColumn: ['studentCount'],
        percentageColumn: [],
      })
      this.defaultData = result.dataArr
      this.inventedDataIndexArr = result.indexArr
      this.rowspans = result.rowspans
    }
  }
  // 监听加载状态
  @Watch('deleting')
  private watchDeleting(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.status.deletingError !== null &&
        this.status.deletingError !== void 0
      ) {
        this.$Message.error(this.status.deletingError)
        return
      }
    }
  }
  mounted() {}
  private handelContentType() {
    this.fetch(this.searchParameter)
  }
  private handleStatisticalForm(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
      countColumn: ['studentCount'],
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }
  // 合并行列方法
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }
  // 列表列头发生变化
  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.handleSearch(this.searchParameter)
  }
  // 小计行的样式
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      if (row.hide) {
        return 'ivu-table-summary-row ivu-table-hide-row'
      }
      return 'ivu-table-summary-row'
    }
  }
  private fetch(parameter: any) {
    this.fetchTable(
      Object.assign({}, this.searchParameter, this.tParameter, {
        columns: this.sumKey,
      })
    )
  }
  private handleExport(parameter: any) {
    this.export(
      Object.assign({}, parameter, {
        columns: this.sumKey,
        countType: this.tParameter.countType,
        countContentType: this.tParameter.countContentType,
        type: 'student/distribution',
      })
    ).then((res: any) => {
      if (res.result) {
        window.location.href = `${res.result}`
      }
    })
  }
  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    if (Boolean(parameter.termID) === false) {
      return this.$Message.warning(`${this.$t('message.noTermID')}`)
    }
    this.$set(this.$data, 'searchParameter', parameter)
    this.fetch(parameter)
  }
  created() {
    this.sumKey = getPageColumns('StudentDistribution').map((item: any) => {
      return item.code
    })
  }
}
