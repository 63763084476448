import Vue, { VNodeChildren } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import AppSelect from '@components/app-select'
import i18n, { DivideClassTypeList } from '../i18n'

type ViewType = 'text'
@Component({ name: 'DivideClassTypeSelect', i18n, inheritAttrs: false })
export default class DivideClassTypeSelect extends Vue {
  @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
  @Prop({ type: String }) private readonly viewType?: ViewType

  get classes() {
    return {
      [`divide-class-type-select`]: this.viewType === void 0,
      [`divide-class-type-text`]: this.viewType !== void 0,
    }
  }

  get props() {
    if (this.viewType === 'text') {
      return {}
    }

    return { data: this.list }
  }

  get list() {
    return this.extra.concat(
      DivideClassTypeList.map(value => {
        return { label: this.$t(`${value}`), value }
      })
    )
  }

  render(createElement: Vue.CreateElement) {
    let tag: any = AppSelect
    let children: VNodeChildren = []

    /** 以文本方式显示 */
    if (this.viewType === 'text') {
      tag = 'span'
      children = [`${this.$t(this.$attrs.value || 'null')}`]
    }

    return createElement(
      tag,
      {
        class: this.classes,
        props: this.props,
        attrs: Object.assign({}, this.$attrs),
        on: this.$listeners,
      },
      children
    )
  }
}
