import { Constant } from 'xuexin-vuex'

export type StudentSource = Constant.StudentSource
export const StudentSource = Constant.StudentSource

export const StudentSourceList = [1, 2]

export const messages: Messages = {
  'zh-CN': {
    [1]: '直升',
    [2]: '外招', //0
    null: '外招',
    undefined: '外招',
    '': '外招',
  },
  'en-US': {
    [StudentSource.Upgrade]: '直升',
    [StudentSource.Other]: '外招', //0
    [StudentSource.One]: '班级删除', // 1
    [StudentSource.Two]: '校际互转', // 2
    [StudentSource.Four]: '重新入校', // 4
    [StudentSource.Five]: '老学籍', // 5
    [StudentSource.Six]: '原就读',
    null: '外招',
    undefined: '外招',
    '': '外招',
  },
}

export default { messages }
