import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppRadioGroup from '@components/app-radio-group'
import AppSelect from '@components/app-select'
import i18n, { ConfirmStatusList } from './i18n'

type ViewType = 'text'
function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `Confirm${upperFirst(type)}`
  const components = {}
  @Component({ name, components, inheritAttrs: false, i18n })
  class ConfirmComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType?: ViewType

    get classes() {
      return {
        [`confirm-status-${type}`]: this.viewType === void 0,
        [`confirm-status-${this.viewType}`]: this.viewType !== void 0,
      }
    }

    get list() {
      return this.extra.concat(
        ConfirmStatusList.map(value => {
          return { label: this.$t(`${value}`), value }
        })
      )
    }

    get props() {
      const props: Record<string, any> = {}

      if (this.viewType === 'text') {
        return props
      }

      switch (type) {
        case ComponentType.Select:
        case ComponentType.Radio:
          props.data = this.list
          break
        default:
          break
      }
      return props
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        let i = this.$attrs.value + ''
        tag = 'span'
        children = [`${this.$t(i)}`]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return ConfirmComponent
}

export const ConfirmRadio = createComponent(AppRadioGroup, ComponentType.Radio)
export const ConfirmSelect = createComponent(AppSelect, ComponentType.Select)
