import Vue, { CreateElement } from 'vue'
import { Component, Model, Watch, Prop } from 'vue-property-decorator'
import { Session, Constant } from 'xuexin-vuex'
import SearchItem from './search-item'
import PageTyping from '../../../types/page'
import { Button } from 'view-design'
import getSearches from './searches'
import './report-search.scss'
import i18n from './i18n'
import { isArray } from '@util/index'

type CheckboxProp = {
  label: string
  value: string
  style: Record<string, any>
  disabled: boolean
}

type Flat = Record<string, boolean>

const components = { SearchItem }
@Component({ name: 'ReportSearch', components, i18n })
export default class ReportSearch extends Vue {
  @Session.Getter('user') private readonly user!: Session.Getter.User
  @Model('input', { default: () => ({}) })
  private readonly value!: PageTyping.SearchParameter

  @Prop({
    type: Array,
    default: () => ['termID', 'parentUnitID', 'unitID', 'schoolDepartID'],
  })
  private readonly required!: string[]

  @Prop({ type: Array, default: () => [''] })
  private readonly multiple!: string[]
  @Prop({ type: String }) private type?: string

  private readonly prefixCls = 'report-search'
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private selection: string[] = []
  private checkboxs: CheckboxProp[] = []
  private requiredSet: Record<string, boolean> = {}
  private multipleSet: Record<string, boolean> = {}

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  /** 当前是机构还是学校 */
  get identityType() {
    return this.user?.identityType
  }
  /** 获取菜单authcode */
  get authCode() {
    return this.$route.meta.authCode
  }
  /** 搜索条件 */
  get searches() {
    return getSearches(this.authCode)
  }

  @Watch('value', { immediate: true })
  watchValue(value: PageTyping.Parameter) {
    this.$set(this.$data, 'parameter', value || {})

    /** 当前用户不存在，啥也干不了。 */
    if (this.user === void 0 || this.user === null) {
      return void 0
    }

    /** 参数中不包含学期时，设置默认学期 */
    const { organID, schoolYear, termID, parentUnitID } = this.parameter

    /** 参数中不包含学年时，设置默认学年 */
    if (
      !(Boolean(schoolYear) || schoolYear === 0) /** 未设置学期 */ &&
      Boolean(this.user.extraMap?.currentTermID) /** 且用户包含当前学期 */
    ) {
      const _schoolYear = Number(
        `${this.user.extraMap.currentTermID}`.substr(0, 4)
      )
      this.$set(this.parameter, 'schoolYear', _schoolYear)
      this.handleSearchItemChange('schoolYear', _schoolYear)
    }

    if (
      !(Boolean(termID) || termID === 0) /** 未设置学期 */ &&
      Boolean(this.user.extraMap?.currentTermID) /** 且用户包含当前学期 */ &&
      Boolean(this.user.extraMap?.feeTermID)
    ) {
      const _termID =
        this.type !== 'class-payment'
          ? this.user.extraMap.currentTermID
          : this.user.extraMap.feeTermID
      this.$set(this.parameter, 'termID', _termID)
      this.handleSearchItemChange('termID', _termID)
    }

    /** 当前用户身份为学校时，设置学校默认参数 */
    if (
      this.identityType === Constant.IdentityType.School /** 学校身份 */ &&
      !(Boolean(parentUnitID) || parentUnitID === 0) /** 无学校id */
    ) {
      const _parentUnitID = this.user.parentUnitID
      this.$set(this.parameter, 'parentUnitID', _parentUnitID)
      this.handleSearchItemChange('parentUnitID', _parentUnitID)
    } else if (
      /** 当前用户身份为机构时，设置机构参数 */
      this.identityType ===
        Constant.IdentityType.Organization /** 当前为机构身份 */ &&
      !(Boolean(organID) || organID === 0)
    ) {
      const _organID = this.user.organID
      this.$set(this.parameter, 'organID', _organID)
      this.handleSearchItemChange('organID', _organID)
    }
  }

  @Watch('searches', { immediate: true })
  watchSearches(searches: PageTyping.Search[]) {
    /** 当前已勾选的条件 */
    const selection: string[] = []
    /** 列数 */
    const columnCount = 5
    /** 行数 */
    const rowCount = Math.ceil(searches.length / columnCount) - 1
    /** 满排满列总数量 */
    const threshold = rowCount * columnCount
    /** 显示更多的复选框组数据 */
    const checkboxs = searches.map((item, index) => {
      /** 添加已勾选的列 */
      if (item.show !== 0) {
        selection.push(`${item.code}`)
      }

      /** 是否为行末 */
      const isEol = (index + 1) % columnCount === 0
      /** 是否溢出 */
      const isOverflow = index >= threshold

      /**
       * 标签特殊处理
       */
      let labelKey = item.code
      switch (this.authCode) {
        case '0502': // 年级管理
          switch (item.code) {
            case 'startTerm':
              labelKey = 'startTerm2'
              break
          }
          break
        case '0503': // 班级管理
          switch (item.code) {
            case 'unitGradeID':
              labelKey = 'gradeID'
              break
          }
        case '0505': // 分班管理
          switch (item.code) {
            case 'gradeID':
              labelKey = 'enrollInGrade'
              break
          }
          break
      }

      return {
        label: this.$t(`label.${labelKey}`),
        value: item.code,
        style: {
          width: '100px',
          marginRight: `${isEol ? '0' : '10'}px`,
          marginBottom: `${isOverflow ? '0' : '10'}px`,
        },
        disabled: item.fixed === 1,
      }
    })
    /** 更新本地变量 */
    this.$set(this.$data, 'selection', selection)
    this.$set(this.$data, 'checkboxs', checkboxs)
  }

  @Watch('required', { immediate: true })
  watchRequired(required: string[], previous: string[]) {
    const requiredSet = required.reduce((requiredSet: any, item) => {
      return (requiredSet[item] = true), requiredSet
    }, {})
    this.$set(this.$data, 'requiredSet', requiredSet)
  }

  @Watch('multiple', { immediate: true })
  watchMultiple(multiple: string[], previous: string[]) {
    const multipleSet = multiple.reduce((multipleSet: any, item) => {
      return (multipleSet[item] = true), multipleSet
    }, {})
    this.$set(this.$data, 'multipleSet', multipleSet)
  }

  created() {}

  render(createElement: CreateElement) {
    /** 创建搜索项 */
    const ItemList = this.searches.map(item => {
      /** 学校身份时，且当前条件为学校，强制不显示。 */
      if (this.identityType === 3 && item.code === 'parentUnitID') {
        return null
      }

      /**
       * 标签特殊处理
       */
      let labelKey = item.code
      switch (this.authCode) {
        case '0502': // 年级管理
          switch (item.code) {
            case 'startTerm':
              labelKey = 'startTerm2'
              break
          }
          break
        case '0503': // 班级管理
          switch (item.code) {
            case 'unitGradeID':
              labelKey = 'gradeID'
              break
          }
        case '0505': // 分班管理
          switch (item.code) {
            case 'gradeID':
              labelKey = 'enrollInGrade'
              break
          }
          break
        case '050712': //插班生人数统计
          switch (item.code) {
            case 'endDate':
              item.type = 7
              labelKey = 'startEndDate'
              break
          }
          break
        default:
          switch (item.code) {
            case 'endDate':
              item.type = 4
              break
          }
          break
      }
      return createElement(SearchItem, {
        props: {
          value: this.value[item.code],
          data: item,
          label: this.$t(`label.${labelKey}`),
          parameter: this.parameter,
          multiple:
            this.type === 'class-payment'
              ? false
              : this.multipleSet[item.code] === true,
          required:
            this.type === 'class-payment' && item.code === 'unitID'
              ? true
              : this.requiredSet[item.code] === true,

          authCode: this.authCode,
        },
        on: { 'on-change': this.handleSearchItemChange },
      })
    })
    /** 搜索按钮 */
    const SearchButton = createElement(
      Button,
      {
        props: { type: 'primary' },
        on: { click: this.handleSearch },
      },
      [`${this.$t('text.search')}`]
    )

    /** 导出按钮 */
    const ExportButton = createElement(
      Button,
      {
        props: { type: 'primary' },
        on: { click: this.handleExport },
      },
      [`${this.$t('text.export')}`]
    )

    const ActionContainer = createElement(
      'div',
      {
        class: `search-item ${this.prefixCls}_actions`,
      },
      [SearchButton, ExportButton]
    )

    return createElement(
      'div',
      {
        class: this.classes,
      },
      [ItemList, ActionContainer]
    )
  }

  mounted() {}

  private handleSearchItemChange(
    code: keyof PageTyping.SearchParameter,
    value: unknown
  ) {
    this.$set(this.$data.parameter, code, value)
    this.$emit('input', this.parameter)
    this.$emit('on-change', this.parameter)
  }

  private handleSearch() {
    if (this.type === 'class-payment') {
      return this.$emit(
        'on-search',
        Object.assign({}, this.parameter, {
          endDate: new Date(this.parameter.endDate!).getTime(),
        })
      )
    }
    this.$emit('on-search', this.updateKeys(this.parameter))
  }

  private handleExport() {
    if (this.type === 'class-payment') {
      return this.$emit(
        'on-export',
        Object.assign({}, this.parameter, {
          endDate: new Date(this.parameter.endDate!).getTime(),
        })
      )
    }
    this.$emit('on-export', this.updateKeys(this.parameter))
  }

  private updateKeys(params: any) {
    const {
      parentUnitID,
      unitID,
      schoolDepartID,
      phaseID,
      unitGradeID,
      classID,
      manageTypeID,
      classTypeID,
      unitClassTypeID,
      endDate,
      ...paramets
    } = params

    let endTimes = null
    if (Boolean(endDate)) {
      isArray(endDate)
        ? (endTimes = endDate.map(e => {
            return new Date(e).getTime()
          }))
        : (endTimes = new Date(endDate).getTime())
    }

    return Object.assign({}, paramets, {
      parentUnitIDs: isArray(parentUnitID)
        ? parentUnitID
        : parentUnitID
        ? [parentUnitID]
        : [],
      unitIDs: isArray(unitID) ? unitID : unitID ? [unitID] : [],
      schoolDepartIDs: isArray(schoolDepartID)
        ? schoolDepartID
        : schoolDepartID
        ? [schoolDepartID]
        : [],
      phaseIDs: isArray(phaseID) ? phaseID : phaseID ? [phaseID] : [],
      unitGradeIDs: isArray(unitGradeID)
        ? unitGradeID
        : unitGradeID
        ? [unitGradeID]
        : [],
      classIDs: isArray(classID) ? classID : classID ? [classID] : [],
      manageTypeIDs: isArray(manageTypeID)
        ? manageTypeID
        : manageTypeID
        ? [manageTypeID]
        : [],
      classTypeIDs: isArray(classTypeID)
        ? classTypeID
        : classTypeID
        ? [classTypeID]
        : [],
      unitClassTypeIDs: isArray(unitClassTypeID)
        ? unitClassTypeID
        : unitClassTypeID
        ? [unitClassTypeID]
        : [],
      endDate: endTimes,
    })
  }
}
