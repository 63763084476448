export const messages: Messages = {
  'zh-CN': {
    table: {
      num: '序号',
      depart: '学部',
      beforeTerm: '上学期原有人数',
      graduate: '上学期毕业人数',
      zXue: '本学期转学人数(该天)',
      lastTermGraducateNum: '上学期毕业人数',
      termTranferNum: '本学期转学人数(该天)',
      enroll: {
        title: '本学期新招学生',
        out: '外招',
        up: '直升',
        stay: '毕业留级',
        xj: '小计',
      },
      transfer: {
        title: '本学期校内互转',
        in: '转入',
        out: '转出',
      },
      current: '当前在校人数',
      empty: '缴清总人数',
      owe: '欠费人数',
      rate: '缴费完成率',
    },
    text: {
      noDataText: '暂无数据',
    },
  },
  'en-US': {},
}

export default { messages }
