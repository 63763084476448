





















import Vue from 'vue'
import { Component, Prop, Watch, Model } from 'vue-property-decorator'
import { Select, Option } from 'view-design'
import { Teacher } from 'xuexin-vuex'
const components = { Select, Option }
@Component({ name: 'TeacherSelect', components })
export default class TeacherSelect extends Vue {
  @Model('input') private value!: any
  @Prop({ type: String })
  private readonly placeholder!: string
  @Prop({ type: Number }) private readonly unitID!: number
  @Prop({ type: String }) private readonly teacherID!: string
  @Teacher.Action('fetchList')
  private readonly fetchList!: Teacher.Action.FetchList
  @Teacher.Getter('list') private readonly list!: Teacher.Getter.List

  private readonly prefixCls = 'teacher-select'
  private xuexinID = ''
  private loading = false
  private teacherList: any = []

  @Watch('teacherID', { immediate: true })
  private watchTeacher(newVal: string, oldVal: string) {
    if (newVal !== oldVal && newVal !== '' && newVal !== void 0) {
      this.xuexinID = this.teacherID
      this.fetchList({ unitID: this.unitID, key: '' }).then(({ result }) => {
        if (!result || result.length < 1) {
          this.teacherList = []
          return
        }
        this.loading = false
        this.teacherList = result.map((item: any) => {
          return {
            xuexinID: item.xuexinID,
            label: `${item.realName}(${item.departmentName}+${item.phone})`,
          }
        })
      })
    }
  }

  private created() {}

  private remoteMethod(query: string) {
    if (query !== '') {
      this.loading = true
      this.fetchList({ unitID: this.unitID, key: query }).then(
        ({ result }) => {
          if (!result || result.length < 1) {
            this.teacherList = []
            return
          }
          this.loading = false
          const arr = result.map((item: any) => {
            return {
              xuexinID: item.xuexinID,
              label: `${item.realName}(${item.departmentName}+${item.phone})`,
            }
          })
          this.teacherList = arr.filter(
            (item: any) =>
              item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
          )
        }
      )
    } else {
      this.teacherList = []
    }
  }

  private handleChange(val: any) {
    this.$emit('input', val.value)
  }
  private handleClear() {
    this.$emit('input', '')
  }
}
