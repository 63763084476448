export default {
  messages: {
    'zh-CN': {
      loading: '加载中...',
      title: '内转',
      label: {
        unitID: '校区',
        schoolDepartName: '转至学部',
        phaseID: '转至学段',
        unitGradeID: '转至年级',
        manageTypeID: '报读类型',
        unitClassTypeID: '校本班型',
        classID: '转至班级',
        freshStudent: '应届生',
        studentCategory: '学生类别',
        academicDirection: '学业方向/入学分类',
      },
      error: {
        unitID: '请选择校区',
        phaseID: '请选择转至学段',
        unitGradeID: '请选择转至年级',
        manageTypeID: '请选择报读类型',
        unitClassTypeID: '请选择班型',
        classID: '请选择班级',
        freshStudent: '请选择应届生',
        studentCategory: '请选择学生类别',
        academicDirection: '请选择学业方向/入学分类',
      },
      tips: {
        'no-depart': '无学部数据，请选择其它校区。',
        'unselect-unit-id': '请选择校区',
      },
      depart: {
        1: '小学部',
        2: '初中部',
        3: '高中部',
        4: '国际部',
      },
      phase: {
        2: '小学部',
        3: '初中部',
        4: '高中部',
      },
    },
    'en-US': {},
  },
}
