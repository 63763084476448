/**
 * 统计报表路由
 */

/** 空路由面页 */
import RouterViewLayout from '@layouts/router-view-layout'

/** 学校总览表 */
import SchoolOverviewRouter from './school-overview'

/** 学生一览表 */
import StudentOverviewRouter from './student-overview'

/** 学生异动统计表 */
import StudentAbnormalChangeRouter from './student-abnormal-change'

/** 学生人数月统计表 */
import StudentCountMonthlyRouter from './student-count-monthly'

/** 在校生人数对比分析 */
import StudentCompareAnalysisRouter from './student-compare-analysis'

/** 班级数量统计 */
import ClassesCountRouter from './classes-count'

/** 班级数量月统计 */
import ClassesCountMonthlyRouter from './classes-count-monthly'

/** 班级数量对比分析 */
import ClassesCountCompareAnalysisRouter from './classes-compare-analysis'

/** 实际流失率统计 */
import ActualLossRateRouter from './actual-loss-rate'

/** 考核流失率统计 */
import AssessLossRateRouter from './assess-loss-rate'

/** 流失人数统计对比分析 */
import LossCompareAnalysisRouter from './loss-compare-analysis'

/** 流失率统计对比分析 */
import LossRateCompareAnalysisRouter from './loss-rate-compare-analysis'

/** 插班生人数统计 */
import StudentInsertRouter from './student-insert'

/** 学生分布统计 */
import StudentDistributionRouter from './student-distribution'

/** 班级缴费统计 */
import ClassPaymentRouter from './class-payment'

/** 学生人数统计 */
import EnrollPayDataRouter from './enroll-pay-data'
export default {
  path: '/reports',
  name: 'reports',
  redirect: { name: 'school-overview' },
  meta: { title: '统计报表', authCode: '0507' },
  component: RouterViewLayout,
  props: { name: 'reports' },
  children: [
    SchoolOverviewRouter,
    StudentOverviewRouter,
    StudentAbnormalChangeRouter,
    StudentCountMonthlyRouter,
    StudentCompareAnalysisRouter,
    ClassesCountRouter,
    ClassesCountMonthlyRouter,
    ClassesCountCompareAnalysisRouter,
    ActualLossRateRouter,
    AssessLossRateRouter,
    LossCompareAnalysisRouter,
    LossRateCompareAnalysisRouter,
    StudentInsertRouter,
    StudentDistributionRouter,
    ClassPaymentRouter,
    EnrollPayDataRouter,
  ],
}
