





































































































































































































































































































































































































import Vue from 'vue'
import { Component, Model, Watch, Prop, Ref } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import AppRadioGroup from '@components/app-radio-group'
import AppSelect from '@components/app-select'
import { SchoolSelect } from '@business-components/school'
import { GradeSelect } from '@business-components/grade'
import { BusinessTypeSelect } from '@business-components/business-type'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { SchoolClassTypeSelect } from '@business-components/school-class-type'
import { SemesterSelect } from '@business-components/semester'
import {
  Form,
  FormItem,
  DatePicker,
  Input,
  Spin,
  Checkbox,
  Upload,
  Button,
  Row,
  Col,
  Icon,
} from 'view-design'
import { DateUtil } from '@util'
import i18n from './editI18n'

import { TrialRead, School, Session, Constant } from 'xuexin-vuex'
import DictionarySelect from '@business-components/dictionary-select'
import StudentTag from '@store/modules/student-tag'
import StudentTagType from 'types/student-tag'

const components = {
  AppModal,
  AppRadioGroup,
  AppSelect,
  Form,
  Spin,
  FormItem,
  DatePicker,
  Input,
  SchoolSelect,
  GradeSelect,
  SchoolPhaseSelect,
  SchoolClassTypeSelect,
  BusinessTypeSelect,
  SemesterSelect,
  Checkbox,
  DictionarySelect,
  Upload,
  Button,
  Row,
  Col,
  Icon,
}

type dataType = {
  studentID: string
  shortStudentID: number
  parameter: TrialRead.Entity
  phaseID: number
}

@Component({ name: 'EditFeedbackModal', components, i18n })
export default class EditFeedbackModal extends Vue {
  @TrialRead.Action('fetchItem')
  private readonly fetch!: TrialRead.Action.FetchItem
  @TrialRead.Action('update')
  private readonly update!: TrialRead.Action.Update
  @TrialRead.Getter('itemStatus')
  private readonly getStatus!: TrialRead.Getter.ItemStatus
  @TrialRead.Getter('item') private readonly getItem!: TrialRead.Getter.Item
  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  @School.Action('fetch')
  private readonly fetchSchoolStatus!: School.Action.Fetch
  @School.Getter('item')
  private readonly getSchoolStatus!: School.Getter.Item
  @StudentTag.Action('fetch')
  private readonly fetchTag!: StudentTagType.Action.Fetch

  @Model('input', { type: Boolean }) private value!: boolean
  @Prop({ required: true }) private readonly data!: dataType
  @Ref('form') private readonly form!: Form

  private visible = this.value
  private verifying = false
  private showSchoolSelect = true
  private loading = true
  private model: any = {
    freshStudent: true,
    files: [],
    isMeal: 1,
    isStay: 1,
    isRefund: 1,
    transferReasonRemark: '',
    mealEndDate: [],
    stayEndDate: [],
  }
  private filesBackups: any = []
  private tagItem: any = {}

  private arrivalDateOptions = {
    disabledDate(date: Date) {
      return date && date.valueOf() < Date.now()
    },
  }

  get shortStudentID() {
    return this.data.shortStudentID
  }

  get parentUnitID() {
    if (this.getUser) {
      return this.getUser.parentUnitID
    }
  }
  /** 获取机构id */
  get organID() {
    return this.getUser?.organID
  }
  get areas() {
    return [
      { label: this.$t('button.uncle'), id: 1 },
      { label: this.$t('button.out-uncle'), id: 2 },
    ]
  }

  get assessmentData() {
    return [
      {
        label: this.$t('count') as string,
        value: 1,
      },
      {
        label: this.$t('noCount') as string,
        value: 0,
      },
    ]
  }

  get parameter() {
    return this.data.parameter || {}
  }

  get status() {
    return this.getStatus(this.shortStudentID)
  }

  get schoolStatus() {
    return this.getSchoolStatus(this.model.inUnitID!)
  }

  get direction() {
    const directions = this.schoolStatus?.directions || []
    directions.length >= 1
      ? this.$set(
          this.$data.model,
          'direction',
          directions[0].schoolDepartTypeID
        )
      : ''
    return directions
  }

  get rules(): any {
    return {
      feedbackResult: [
        {
          validator: this.validateNull,
          message: this.$t('message.feedbackResult') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      inParentUnitID: [
        {
          validator: this.validateNull,
          message: this.$t('message.inParentUnitID') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      inUnitID: [
        {
          validator: this.validateNull,
          message: this.$t('message.inUnitID') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      direction: [
        {
          validator: this.validateNull,
          message: this.$t('message.direction') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      manageTypeID: [
        {
          validator: this.validateNull,
          message: this.$t('message.manageTypeID') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      phaseID: [
        {
          validator: this.validateNull,
          message: this.$t('message.phaseID') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      targetGradeID: [
        {
          validator: this.validateNull,
          message: this.$t('message.targetGradeID') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      unitClassTypeID: [
        {
          validator: this.validateNull,
          message: this.$t('message.unitClassTypeID') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      enrollDate: [
        {
          validator: this.validateNull,
          message: this.$t('message.enrollDate') as string,
          trigger: 'blur',
          type: 'string',
        },
      ],
      freshStudent: [
        {
          type: 'boolean',
          trigger: 'change',
          validator: this.validateNull,
        },
      ],
      //prettier-ignore
      mealEndDate: [
        {
          required: true,
          type: 'array',
          trigger: 'blur',
          validator: this.validate,
        },
      ],
      stayEndDate: [
        {
          required: true,
          type: 'array',
          trigger: 'change',
          validator: this.validate,
        },
      ],
      academicDirection: [
        {
          type: 'string',
          trigger: 'change',
          validator: this.validateNull,
        },
      ],
      studentCategory: [
        {
          type: 'string',
          trigger: 'change',
          validator: this.validateNull,
        },
      ],
      targetGrade: [
        {
          validator: this.validTargetGrade,
        },
      ],
      transferDate: [
        {
          required: true,
          type: 'string',
          trigger: 'change',
          validator: this.validateNull,
        },
      ],
      checkLost: [
        {
          required: true,
          type: 'number',
          trigger: 'change',
          message: '请选择是否计入流失率考核',
        },
      ],
      transferReasonRemark: [
        {
          required: true,
          type: 'string',
          trigger: 'blur',
          message: '请填写转学原因',
        },
        {
          min: 5,
          trigger: 'blur',
          message: '转学原因不少于5个字',
        },
      ],
      files: [
        {
          type: 'array',
          trigger: 'blur',
          validator: this.validFile,
        },
      ],
    }
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean): void {
    this.visible = value
  }
  @Watch('model.inUnitID')
  private watchInUnitID(value: number, previous: number): void {
    this.$set(this.model, 'phaseID', void 0)

    if (value && this.model.feedbackResult === 2) {
      this.fetchSchoolStatus({
        unitID: value,
        organID: this.organID,
      })
      this.fetchTagItem(value)
    }
  }
  // 监听转至方向
  @Watch('model.direction', { immediate: true })
  private watchDirection(value: number, prev: number) {
    this.$set(this.model, 'phaseID', this.data.phaseID)
    this.resetTagValue(value, this.model.phaseID)
  }
  //学段变化为非高中，重置应届生为true
  @Watch('model.phaseID')
  private watchPhaseID(value: number) {
    if (this.model.direction !== 1) {
      this.resetTagValue(this.model.direction, value)
    }
  }
  @Watch('status.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.status.fetchingError !== null) {
        return this.$Message.error(this.status.fetchingError)
      }
      const item = this.getItem(this.shortStudentID)
      if (item === void 0) {
        return void 0
      }

      this.$set(this.model, 'feedbackResult', item.feedbackResult || 0)
      this.$set(this.model, 'studentName', item.studentName)
      this.$set(this.model, 'resultConfig', item.resultConfig)
      this.$set(
        this.model,
        'enrollDate',
        item.enrollDate
          ? DateUtil.format(item.enrollDate, 'yyyy-MM-dd hh:mm')
          : ''
      )
    }
  }

  @Watch('status.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    // 更新请求完成
    if (this.value === false) {
      return void 0
    }
    if (updating === false && previous === true) {
      // 分类处理错误消息
      if (this.status.updatingError !== null) {
        this.$Message.error(this.status.updatingError)
        return this.stopLoading()
      }
      /** 更新成功，关闭窗口，通知父级组件。 */
      this.$set(this.$data, 'visible', false)
      this.$emit('on-finish', 'edit-modal')
      return this.stopLoading()
    }
  }

  @Watch('model.checkLost')
  private watchcheckLost(value: number) {
    this.model.files = this.filesBackups
  }

  private stopLoading(): void {
    this.loading = false
    this.verifying = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private validateNull(rule: any, value: number | string, callback: any) {
    if (value === -1 || value === void 0 || value === '' || value === null) {
      if (
        (this.model.direction == 2 &&
          ['freshStudent', 'academicDirection', 'studentCategory'].includes(
            rule.field || '-1'
          )) ||
        (rule.field == 'studentCategory' &&
          this.model.academicDirection == '5')
      ) {
        return callback()
      } else {
        return callback(new Error(this.$t(`message.${rule.field}`) as string))
      }
    }
    return callback()
  }

  private validate(rule: any, value: any, callback: any) {
    if (value === void 0 || value === null || value[0] === '') {
      return callback(`请选择日期`)
    }

    return callback()
  }
  private validFile(rule: any, value: any, callback: any) {
    if (
      (value === void 0 ||
        value === '' ||
        value === null ||
        value.length === 0) &&
      this.model.checkLost !== 1
    ) {
      return callback('请选择附件')
    }

    return callback()
  }

  private validTargetGrade(
    rule: object,
    value: number | string,
    callback: any
  ) {
    return callback()
  }

  private handleShowSchoolSelect(list: School.Getter.List) {
    this.showSchoolSelect = list.length > 1
  }

  private handleOK(): void {
    this.verifying = true
    this.form.validate(valid => {
      if (valid === false) {
        return this.stopLoading()
      }

      const { academicDirection, studentCategory, freshStudent } = this.model

      let paramter = {
        termID: this.parameter.termID,
        shortStudentID: this.shortStudentID,
        feedbackResult: this.model.feedbackResult,
        feedbackNote: this.model.desc,
        checkLost: this.model.checkLost,
        inUnitID: this.model.inUnitID,
        direction: this.model.direction,
        targetGradeID: this.model.targetGradeID,
        targetTermID: this.model.targetTermID,
        unitClassTypeID: this.model.unitClassTypeID,
        enrollDate: this.model.enrollDate
          ? new Date(this.model.enrollDate).getTime()
          : 0,
        manageTypeID: this.model.manageTypeID,
        phaseID: this.model.phaseID,
        freshStudent: freshStudent ? 1 : 0,
        academicDirection,
        studentCategory: academicDirection === '5' ? void 0 : studentCategory,
        transferDate: this.model.transferDate
          ? new Date(this.model.transferDate).getTime()
          : void 0,
        isRefund: this.model.isRefund,
        isMeal: this.model.isMeal,
        mealEndDate:
          this.model.mealEndDate && this.model.mealEndDate[0] != ''
            ? [
                new Date(this.model.mealEndDate[0]).getTime(),
                new Date(this.model.mealEndDate[1]).getTime(),
              ]
            : void 0,
        isStay: this.model.isStay,
        stayEndDate:
          this.model.stayEndDate && this.model.stayEndDate[0] != ''
            ? [
                new Date(this.model.stayEndDate[0]).getTime(),
                new Date(this.model.stayEndDate[1]).getTime(),
              ]
            : void 0,
        payee: this.model.payee,
        parentBankCard: this.model.parentBankCard,
        bankOfDeposit: this.model.bankOfDeposit,
        transfeArea: this.model.transfeArea,
        transfeSchool: this.model.transfeSchool,
        transferReasonRemark: this.model.transferReasonRemark,
      }
      const formData = new FormData()

      if (this.filesBackups.length > 0) {
        for (let i = 0; i < this.filesBackups.length; i++) {
          formData.append('file', this.filesBackups[i])
        }
      }
      // if (this.model.files.length > 0) {
      //   for (let i = 0; i < this.model.files.length; i++) {
      //     formData.append('file', this.model.files[i])
      //   }
      // }
      formData.append('data', JSON.stringify(paramter))

      this.update({
        shortStudentID: this.shortStudentID,
        data: formData,
      })
    })
  }
  //查询学生标签回显值
  private fetchTagItem(unitID: number) {
    this.fetchTag({
      xuexinID: this.data.studentID,
      unitID: this.parameter.unitID || unitID,
      termID: this.parameter.termID,
      type: 2,
      toUnitID: unitID,
    }).then(({ result }: any) => {
      this.tagItem = {
        freshStudent: result.freshStudent === 0 ? false : true,
        academicDirection: result.academicDirection
          ? `${result.academicDirection}`
          : void 0,
        studentCategory: result.studentCategory
          ? `${result.studentCategory}`
          : void 0,
        hasTermBill: result.hasTermBill,
      }
      if (this.model.phaseID == 4 || this.model.phaseID == 3) {
        const {
          freshStudent,
          academicDirection,
          studentCategory,
        } = this.tagItem
        this.$set(this.model, 'freshStudent', freshStudent)
        this.$set(this.model, 'academicDirection', academicDirection)
        this.$set(this.model, 'studentCategory', studentCategory)
      }
    })
  }
  private handleVisibleChange(visible: boolean): void {
    if (visible) {
      this.fetch({
        shortStudentID: this.shortStudentID,
        termID: this.data.parameter.termID,
        unitID: this.data.parameter.unitID,
      })
    }
    this.$emit('on-visible-change')
  }

  private handleHidden(): void {
    this.$set(this.$data, 'model', {})
    this.stopLoading()
    this.$emit('on-hidden')
  }
  /** 文件上传 */
  private handleUpload(file: any) {
    if (file.size > 10 * 1024 * 1024) {
      this.$Message.warning(`${this.$t('text.desc')}`)
      return false
    }
    /** 展示文件列表 */
    this.model.files = [file]
    this.filesBackups = [file]
    return false
  }

  /** 删除文件 */
  private handleRemove(index: number) {
    this.model.files.splice(index, 1)
    this.filesBackups = []
  }

  //重置学生标签默认值
  private resetTagValue(direction: number, phaseID: number) {
    if (direction == 1) {
      const {
        freshStudent,
        academicDirection,
        studentCategory,
      } = this.tagItem
      if (phaseID === 4 || phaseID === 3) {
        this.$set(this.model, 'freshStudent', freshStudent)
        this.$set(this.model, 'academicDirection', academicDirection)
        this.$set(this.model, 'studentCategory', studentCategory)
      } else {
        this.$set(this.model, 'freshStudent', true)
        this.$set(this.model, 'academicDirection', '1')
        this.$set(this.model, 'studentCategory', studentCategory || '3')
      }
    } else {
      //
    }
  }
}
