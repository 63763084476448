





































































































import Vue from 'vue'
import { Component, Model, Watch, Prop, Ref } from 'vue-property-decorator'
import { Grade, Student, Constant } from 'xuexin-vuex'
import { Spin, Form, FormItem } from 'view-design'
import AppModal from '@components/app-modal'
import AppRadioGroup from '@components/app-radio-group'
import { GradeSelect } from '@business-components/grade'
import { BusinessTypeSelect } from '@business-components/business-type'
import { SchoolClassTypeSelect } from '@business-components/school-class-type'
import { SemesterSelect } from '@business-components/semester'
import { GlassSelect } from '@business-components/glass'
import i18n from './i18n'

type Field = 'manageTypeID' | 'unitClassTypeID'

interface Data {
  termID: number
  unitID: number
  schoolDepartID: number
  phaseID: number
  unitGradeID: number
  xuexinIDs: string[]
  gradeRank: number
}

interface Model {
  termID: number
  dataID: 1 | 2 | 3
  level: number
  manageTypeID: number
  unitClassTypeID: number
  classID: number
  xuexinIDs: string[]
}

const components = {
  AppModal,
  Spin,
  Form,
  FormItem,
  AppRadioGroup,
  GradeSelect,
  BusinessTypeSelect,
  SchoolClassTypeSelect,
  GlassSelect,
  SemesterSelect,
}
@Component({ name: 'ChangeGradeModal', components, i18n })
export default class ChangeGradeModal extends Vue {
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly listState!: Student.Getter.ListStatus
  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Data
  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'change-grade-modal'
  private hidden = true
  private visible = false
  private loading = true
  private verifying = false
  private model: Partial<Model> = {}
  private grades: Grade.Entity[] = []
  private show: Record<Field, boolean> = {
    manageTypeID: true,
    unitClassTypeID: true,
  }
  private changeInvalid = false //级相同，不能提交
  // prettier-ignore
  private rules: FormRules = {
      dataID: [
        { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
      ],
      level: [
        { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
      ],
      manageTypeID: [
        { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
      ],
      unitClassTypeID: [
        { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
      ],
      classID: [
        { required: false, type: 'number', trigger: 'change', validator: this.handleValidator },
      ],
    }

  get typeList() {
    return [1, 2, 3].map(value => {
      return { label: this.$t(`dataID.${value}`), value }
    })
  }

  get updating() {
    return this.listState.updating === true
  }

  get gradeName() {
    const grade = this.grades.find(
      item => item.unitGradeID === this.model.level
    )
    if (grade === void 0) {
      return ''
    }

    return grade.gradeName
  }

  @Watch('value', { immediate: true })
  watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('updating')
  watchUpdating(updating: boolean, prvious: boolean) {
    if (this.visible === true && updating === false && prvious === true) {
      this.stopInteraction()

      if (this.listState.updatingError !== null) {
        return this.$Message.error(this.listState.updatingError)
      }

      this.$emit('on-finish')
    }
  }

  private handleValidator(
    rule: FormRule,
    value: any,
    callback: FormRuleCallback
  ) {
    if (Boolean(value) === false) {
      return callback(`${this.$t(`error.${rule.field}`)}`)
    }
    return callback()
  }

  private handleFinish(field: string, list: any[]) {
    switch (field) {
      case 'grade':
        this.$set(this.$data, 'grades', list)

        // 级加载完成，设置下默认跳留级类别
        const grade = this.grades.find(
          item => item.unitGradeID === this.model.level
        )
        const currentYear = Number(`${this.data.termID}`.substr(0, 4)),
          changeYear = Number(`${this.model.termID}`.substr(0, 4))
        const gradeRankResult = grade?.gradeRank - this.data.gradeRank,
          finalResult =
            grade?.gradeRank -
            this.data.gradeRank -
            (changeYear - currentYear)
        if (gradeRankResult === 0) {
          this.changeInvalid = true
          this.model.dataID = void 0
          return
        } else {
          this.changeInvalid = false
        }
        if (finalResult < 0) {
          this.model.dataID = 1
        } else if (finalResult === 0) {
          this.model.dataID = 2
        } else {
          this.model.dataID = 3
        }

      default:
        break
    }
  }

  private stopInteraction() {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)

    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }

  private handleOK() {
    // if (this.changeInvalid) {
    //   this.$Message.warning('跳至年级选择无效，请重新选择！')
    //   return this.stopInteraction()
    // }
    this.$set(this.$data, 'verifying', true)
    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        /** 校验失败 */
        return this.stopInteraction()
      }

      /** 跳留级操作 */
      this.update(
        Object.assign({}, this.model, {
          actionType: Constant.ActionType.Grade,
          unitGradeID: this.model.level,
          unitID: this.data.unitID,
        })
      )
    })
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleHidden() {
    this.$emit('on-hidden')
    this.stopInteraction()
    this.$set(this.$data, 'hidden', true)
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      /** 初始化 model */
      this.$set(
        this.$data,
        'model',
        Object.assign({}, this.model, {
          termID: this.data.termID,
          dataID: 1,
          level: this.data.unitGradeID,
          xuexinIDs: this.data.xuexinIDs,
        })
      )

      this.$set(this.$data, 'hidden', false)
    }

    this.$emit('on-visible-change', visible)
  }

  private handleChangeGrade(id: number) {
    // 1. 判断跳至级与学生当前级是否是一样，如果一样，则学生级没有变，不允许操作
    // 2. 当前级- 目标级 + （目标学年 - 当前学年）  = 0 留级
    // 当value = 0时为留级，当value > 0时为跳级，当value<0时为降级

    const grade = this.grades.find(item => item.unitGradeID === id)
    const currentYear = Number(`${this.data.termID}`.substr(0, 4)),
      changeYear = Number(`${this.model.termID}`.substr(0, 4))
    const gradeRankResult = grade?.gradeRank - this.data.gradeRank,
      finalResult =
        this.data.gradeRank - grade?.gradeRank + (changeYear - currentYear)

    if (gradeRankResult === 0) {
      this.changeInvalid = true
      this.model.dataID = void 0
      return
    } else {
      this.changeInvalid = false
    }
    if (finalResult < 0) {
      this.model.dataID = 1
    } else if (finalResult === 0) {
      this.model.dataID = 2
    } else {
      this.model.dataID = 3
    }
  }
}
