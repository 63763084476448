export default {
  messages: {
    'zh-CN': {
      columns: {
        index: '序号',
        schoolName: '学校名称',
        campus: '校区',
        schoolDepartName: '学部',
        gradeName: '年级',
        startDate: '开学日期',
        endDate: '截止注册日期',
      },
      message: {
        'update-success': '编辑成功！',
        'save-success': '保存成功！',
      },
      term: '学期',
      save: '保存',
      'change-date': '选择日期',
    },
    'en-US': {
      columns: {
        index: '序号',
        schoolName: '学校名称',
        campus: '校区',
        schoolDepartName: '学部',
        gradeName: '年级',
        startDate: '开学日期',
        endDate: '截止注册日期',
      },
      message: {
        'update-success': '编辑成功！',
        'save-success': '保存成功！',
      },
      term: '学期',
      save: '保存',
      'change-date': '选择日期',
    },
  },
}
