import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'

const valueField = 'value'
const labelField = 'label'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `DimensionSelect${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class DimensionSelectComponent extends Vue {
    get classes() {
      return {
        [`class-type-${type}`]: true,
      }
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    get list() {
      return [
        {
          label: '学部维度',
          value: 'schoolDepart',
        },
        {
          label: '年级维度',
          value: 'grade',
        },
        {
          label: '班级维度',
          value: 'class',
        },
      ]
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return DimensionSelectComponent
}

export const DimensionSelectComponent = createComponent(
  AppSelect,
  ComponentType.Select
)
