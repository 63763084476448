




































import Vue from 'vue'
import { Component, Model, Prop, Ref, Watch } from 'vue-property-decorator'
import { SemesterSelect } from '@business-components/semester'
import { Glass } from 'xuexin-vuex'
import AppModal from '@components/app-modal'
import { Form, FormItem, Input } from 'view-design'
const components = { AppModal, Form, FormItem, Input, SemesterSelect }
@Component({ name: 'ChageClassesModal', components })
export default class ChageClassesModal extends Vue {
  @Glass.Getter('itemStatus')
  private readonly getItemStatus!: Glass.Getter.ItemStatus
  @Glass.Action('changeLimcount')
  private readonly changeLimcount!: Glass.Action.ChangeLimcount
  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Glass.Entity
  @Ref('form') private readonly form!: Form
  @Ref('afterLimitCount') private readonly afterLimitCount!: Input

  private readonly prefixCls = 'change-classes-modal'
  private visible = false
  private loading = true
  private verifying = false
  private model: Partial<Glass.Parameter> = {}

  get rules(): FormRules {
    return {
      afterLimitCount: [
        {
          required: true,
          type: 'string',
          message: '请输入变更的班额',
          trigger: 'change',
        },
      ],
    }
  }

  get itemStatus() {
    return this.getItemStatus(this.data.classID)
  }
  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }
  /** 监听更新班级状态 */
  @Watch('itemStatus.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    /** 窗口不显示时，不处理数据。 */
    if (this.value === false || this.visible === false) {
      return void 0
    }

    /** 更新请求完成 */
    if (updating === false && previous === true) {
      /** 更新失败，提示错误消息。 */
      if (this.itemStatus.updatingError !== null) {
        this.$Message.error(this.itemStatus.updatingError)
        return this.stopInteraction()
      }

      /** 更新成功，关闭窗口，通知父级组件。 */
      this.$set(this.$data, 'visible', false)
      this.$Message.success('变更班额成功')
      this.$emit('on-finish')
      return this.handleHidden()
    }
  }
  private handleInput(value: boolean) {
    this.$emit('input', value)
  }
  private handleOk() {
    this.$set(this.$data, 'verifying', true)

    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        this.$nextTick(() => this.afterLimitCount.focus())
        /** 校验失败 */
        return this.stopInteraction()
      }
      /** 向后台发送请求 */
      const { classID, afterLimitCount, termID } = this.model
      this.changeLimcount({
        termID,
        classID,
        afterLimitCount: Number(afterLimitCount),
      })
    })
  }

  private handleVisibleChange(visible: boolean) {
    /** 窗口打开时，更新 model 数据 */
    if (visible === true) {
      this.$set(this.$data, 'model', {
        termID: this.data.termID,
        classID: this.data.classID,
        limitCount: this.data.limitCount,
        className: this.data.className,
        afterLimitCount: '',
      })
    }

    this.$emit('on-visible-change', visible)
  }

  private handleHidden() {
    this.stopInteraction()
    this.$emit('on-hidden')
    this.form.resetFields()
  }

  private stopInteraction() {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)

    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }
}
