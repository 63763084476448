import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Spin } from 'view-design'
import i18n from './i18n'
import './view-layout.scss'

@Component({ name: 'ViewLayout', i18n })
export default class ViewLayout extends Vue {
  @Prop({ type: Boolean, default: false }) private loading!: boolean
  @Prop({ type: String }) private readonly name!: string
  private readonly prefixCls = 'view-layout'

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  created() {}

  render(createElement: CreateElement) {
    const children: VNodeChildren = []

    /** 处理于加载状态时，显示 loading */
    if (this.loading === true) {
      children.push(
        createElement(Spin, { props: { fix: true } }, [`${this.$t('loading')}`])
      )
    }

    /** 渲染实际内容 */
    if (this.$scopedSlots.default !== void 0) {
      children.push(...this.$scopedSlots.default({}))
    }

    return createElement('div', { class: this.classes }, [
      createElement('div', { class: `${this.prefixCls}_content` }, children),
    ])
  }

  mounted() {}

  beforeDestroy() {}
}
