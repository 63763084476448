import { Constant } from 'xuexin-vuex'

export type AssessmentLoss = Constant.AssessmentLoss
export const AssessmentLoss = Constant.AssessmentLoss

export const AssessmentLossList = [
  AssessmentLoss.Included,
  AssessmentLoss.NotInclude,
  AssessmentLoss.Optional,
  AssessmentLoss.AfterApproveNotInclude,
  AssessmentLoss.OptionalAfterApproveNotInclude,
]

export const messages: Messages = {
  'zh-CN': {
    [1]: '计入',
    [0]: '不计入',
    [AssessmentLoss.Optional]: '自选',
    [AssessmentLoss.AfterApproveNotInclude]: '集团审批通过后不计入',
    [AssessmentLoss.OptionalAfterApproveNotInclude]: '集团审批审批通过后不计入',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [1]: '计入',
    [0]: '不计入',
    [AssessmentLoss.Optional]: '自选',
    [AssessmentLoss.AfterApproveNotInclude]: '集团审批通过后不计入',
    [AssessmentLoss.OptionalAfterApproveNotInclude]: '集团审批审批通过后不计入',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
