import { Constant } from 'xuexin-vuex'

export type Relation = Constant.Relation
export const Relation = Constant.Relation

export const RelationList = [
  Relation.Father,
  Relation.Mother,
  Relation.GrandFather,
  Relation.GrandMother,
  Relation.WGrandFather,
  Relation.WGrandMother,
  Relation.Other,
]

export const messages: Messages = {
  'zh-CN': {
    [`${Relation.Other}`]: '其他',
    [`${Relation.Father}`]: '父亲',
    [`${Relation.Mother}`]: '母亲',
    [`${Relation.GrandFather}`]: '祖父',
    [`${Relation.GrandMother}`]: '祖母',
    [`${Relation.WGrandFather}`]: '外祖父',
    [`${Relation.WGrandMother}`]: '外祖母',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${Relation.Other}`]: '其他',
    [`${Relation.Father}`]: '父亲',
    [`${Relation.Mother}`]: '母亲',
    [`${Relation.GrandFather}`]: '祖父',
    [`${Relation.GrandMother}`]: '祖母',
    [`${Relation.WGrandFather}`]: '外祖父',
    [`${Relation.WGrandMother}`]: '外祖母',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
