import Vue, { VNodeData } from 'vue'
import Logo from './logo2.png'

export default Vue.extend({
  name: 'AppLogo',
  functional: true,
  render(h, context) {
    const data: VNodeData = {
      class: ['app-logo'].concat(context.data.class),
      style: Object.assign(
        {
          display: 'inline-block',
          verticalAlign: 'middle',
          width: '100px',
        },
        context.data.style
      ),
      domProps: {
        src: Logo,
      },
    }

    return h('img', data, context.children)
  },
})
