export default {
  messages: {
    'zh-CN': {
      text: {
        birthday: '出生日期',
        xuexinID: '学信号',
        parentPhone: '家长电话',
        examCode: '考号',
        sourceChannel: '学生来源',
        direction: '毕业去向',
        receiveSms: '接收短信',
        null: '-',
        undefined: '-',
        enrollment: '外招',
        classDelete: '班级删除',
        schoolTransfer: '校际互转',
        directStudent: '直升学生',
        oldSchool: '老学籍',
        familyMembers: '家庭成员',
        operationRecord: '学籍操作记录',
        genderName: '性别',
        num: '序号',
        operateType: '操作类型',
        operateContent: '操作内容',
        termName: '操作学期',
        operator: '操作人',
        operatorDate: '操作时间',
        noData: '暂无数据',
        back: '返回',
        gender: {
          0: '男',
          1: '女',
          '-1': '保密',
          null: '保密',
          undefined: '保密',
        },
      },
    },
    'en-US': {},
  },
}
