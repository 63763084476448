import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { TableColumn } from 'view-design'

@Component({ name: 'PagePropsMixins' })
export default class PagePropsMixins extends Vue {
  @Prop({ type: Object, default: () => ({}) }) readonly auths!: Record<
    string,
    boolean
  >
  @Prop({ type: Array, default: () => [] }) readonly columns!: TableColumn[]

  created() {}
  mounted() {}
}
