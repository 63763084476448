export default {
  messages: {
    'zh-CN': {
      exit: '退出登录',
      confirm: {
        title: '退出确定',
        content: '确定要退出登录吗？',
      },
    },
    'en-US': {},
  },
}
