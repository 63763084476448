import { namespace as BindingNamespace } from 'vuex-class'
import { handlers, coverToState, updateState } from 'xuexin-vuex/src/util'
import { Ajax } from 'xuexin-vuex/src/ajax'
import omit from 'lodash.omit'
import { Export } from 'types'
import { ActionType } from 'xuexin-vuex/src/constant'

function getListKey(parameter: any) {
  return `key_&`
}

export const namespace = '@xuexin-vuex/export'

export const types = {
  CREATE_REQUEST: 'CREATE_REQUEST',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_FAILURE: 'CREATE_FAILURE',

  FETCH_LIST_REQUEST: 'FETCH_LIST_REQUEST',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
  FETCH_LIST_FAILURE: 'FETCH_LIST_FAILURE',

  UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
  UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
  UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',

  DOWNLOAD_REQUEST: 'DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'DOWNLOAD_FAILURE',
}

const state: Export.State = {
  list: { data: [] },
  items: {},
}

const actions: Export.Actions = {
  create(context, payload) {
    const { file, operationType } = payload
    let type = types.CREATE_REQUEST
    let api = `post ${__STUDENT_API__}/import/${payload.type}`
    let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    let parameter = file
    let success = types.CREATE_SUCCESS
    let failure = types.CREATE_FAILURE

    if (operationType === 'reDivide') {
      api = `post ${__STUDENT_API__}/import/batchUpdateClasses`
    }
    return Ajax(context, {
      type,
      payload,
      api,
      headers,
      parameter,
      success,
      failure,
    })
  },
  fetchList(context, payload) {
    return Ajax(context, {
      type: types.FETCH_LIST_REQUEST,
      payload: payload,
      api: `get ${__STUDENT_API__}/export/prepare`,
      parameter: payload,
      success: types.FETCH_LIST_SUCCESS,
      failure: types.FETCH_LIST_FAILURE,
    })
  },
  updateItem(context, payload) {
    return Ajax(context, {
      type: types.UPDATE_ITEM_REQUEST,
      payload: payload,
      api: `put ${__STUDENT_API__}/config/pages/${payload.authCode}/1`,
      parameter: payload,
      success: types.UPDATE_ITEM_SUCCESS,
      failure: types.UPDATE_ITEM_FAILURE,
    })
  },
  exportFile(context, payload) {
    let type = types.DOWNLOAD_REQUEST
    let api
    let success = types.DOWNLOAD_SUCCESS
    let failure = types.DOWNLOAD_FAILURE
    const { actionType, ...parameter } = payload
    switch (actionType) {
      case ActionType.Divide: // 分班学生
        api = `post ${__STUDENT_API__}/export/divideStudent`
        break
      case ActionType.InnerTransfer: // 校际互转
        api = `post ${__STUDENT_API__}/students/unittranfer/export`
        break
      case ActionType.ShortTerm: // 短期入学
        api = `post ${__STUDENT_API__}/students/short/export`
        break
      case ActionType.Trial: // 试读学生
        api = `post ${__STUDENT_API__}/students/probations/export`
        break
      case ActionType.Graduation: // 毕业学生
        api = `post ${__STUDENT_API__}/students/graduation/export`
        break
      case ActionType.Transfer: // 转学学生
        api = `post ${__STUDENT_API__}/students/trans/export`
        break
      case ActionType.InClass: // 在班学生
        api = `post ${__STUDENT_API__}/students/export`
        break
      case ActionType.Register: // 学期注册
        api = `post ${__STUDENT_API__}/students/regist/export`
        break
      case ActionType.Glass: // 班级
        api = `post ${__STUDENT_API__}/export/classes`
        break
      case ActionType.Grade: // 年级
        api = `post ${__STUDENT_API__}/export/unitGrades`
        break
      case ActionType.Record: // 操作记录
        api = `post ${__STUDENT_API__}/oper/records/export`
        break
      case ActionType.Enrollment: // 新招学生
        api = `post ${__STUDENT_API__}/export/currenTermStudent`
        break
      default:
        /** 默认导出分班学生 */
        api = `post ${__STUDENT_API__}/export/divideStudent`
        break
    }
    return Ajax(context, { type, payload, api, parameter, success, failure })
  },
}

const mutations: Export.Mutations = {
  /** 批量导入 */
  [types.CREATE_REQUEST](state, { payload }) {
    const listKey = getListKey(payload)
    handlers.itemRequest(state, listKey, 'creating')
  },
  [types.CREATE_SUCCESS](state, { payload, result }) {
    const listKey = getListKey(payload)
    updateState(state.items, listKey, { data: Object.assign({}, result) })
    handlers.itemSuccess(state, listKey, 'creating')
  },
  [types.CREATE_FAILURE](state, { message, payload }) {
    const listKey = getListKey(payload)
    handlers.itemFailure(state, listKey, 'creating', message)
  },

  /** 获取列信息 */
  [types.FETCH_LIST_REQUEST](state) {
    handlers.request(state.list, 'fetching')
  },
  [types.FETCH_LIST_SUCCESS](state, { total, result }) {
    coverToState(state, 'columnCode', result, total)
    handlers.success(state.list, 'fetching')
  },
  [types.FETCH_LIST_FAILURE](state, { message }) {
    handlers.failure(state.list, 'fetching', message)
  },

  /** 更新列信息 */
  [types.UPDATE_ITEM_REQUEST](state) {
    handlers.request(state.list, 'updating')
  },
  [types.UPDATE_ITEM_SUCCESS](state, { payload }) {
    const columns = payload.columns
    columns?.forEach((code: any) => {
      updateState(state.items, `${code.columnCode}`, Object.assign({}, code))
    })
    handlers.success(state.list, 'updating')
  },
  [types.UPDATE_ITEM_FAILURE](state, { message }) {
    handlers.failure(state.list, 'updating', message)
  },

  /** 导出 */
  [types.DOWNLOAD_REQUEST](state, { payload }) {
    const { actionType } = payload
    switch (actionType) {
      case ActionType.Divide: // 分班学生
      case ActionType.InnerTransfer: // 校际互转
      case ActionType.ShortTerm: // 短期入学
      case ActionType.Trial: // 试读学生
      case ActionType.Graduation: // 毕业学生
      case ActionType.Transfer: // 转学学生
      case ActionType.InClass: // 在班学生
      case ActionType.Register: // 学期注册
      case ActionType.Glass: // 班级
      case ActionType.Grade: // 年级
      case ActionType.Record: // 操作记录
        handlers.request(state.list, 'creating')
        break
      default:
        handlers.request(state.list, 'creating')
        break
    }
  },
  [types.DOWNLOAD_SUCCESS](state, { payload, result }) {
    const listKey = getListKey(payload)
    const { actionType } = payload
    switch (actionType) {
      case ActionType.Divide: // 分班学生
      case ActionType.InnerTransfer: // 校际互转
      case ActionType.ShortTerm: // 短期入学
      case ActionType.Trial: // 试读学生
      case ActionType.Graduation: // 毕业学生
      case ActionType.Transfer: // 转学学生
      case ActionType.InClass: // 在班学生
      case ActionType.Register: // 学期注册
      case ActionType.Glass: // 班级
      case ActionType.Grade: // 年级
      case ActionType.Record: // 操作记录
        updateState(state.items, listKey, { data: { url: result } })
        break
      default:
        updateState(state.items, listKey, { data: { url: result } })
        break
    }
    handlers.success(state.list, 'creating')
  },
  [types.DOWNLOAD_FAILURE](state, { payload, message }) {
    const { actionType } = payload
    switch (actionType) {
      case ActionType.Divide: // 分班学生
      case ActionType.InnerTransfer: // 校际互转
      case ActionType.ShortTerm: // 短期入学
      case ActionType.Trial: // 试读学生
      case ActionType.Graduation: // 毕业学生
      case ActionType.Transfer: // 转学学生
      case ActionType.InClass: // 在班学生
      case ActionType.Register: // 学期注册
      case ActionType.Glass: // 班级
      case ActionType.Grade: // 年级
      case ActionType.Record: // 操作记录
        handlers.failure(state.list, 'creating', message)
        break
      default:
        handlers.failure(state.list, 'creating', message)
        break
    }
  },
}

const getters: Export.Getters = {
  list(state, getters) {
    return state.list.data.map(code => getters.item(`${code}`))
  },
  listStatus(state) {
    return omit(state.list, ['data'])
  },
  item(state) {
    return code => {
      const listKey = getListKey(code)
      return state.items[code]?.data || state.items[listKey]?.data
    }
  },
  itemStatus(state) {
    return code => omit(state.items[code], ['data'])
  },
}

export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = BindingNamespace(namespace)
export default { State, Action, Mutation, Getter, namespace, types, Module }
