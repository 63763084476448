import { Constant } from 'xuexin-vuex'

export type StatusType = Constant.StatusType
export const StatusType = Constant.StatusType

export const StatusTypeList = [
  StatusType.School,
  StatusType.Empty,
  StatusType.No,
  StatusType.Nul,
]

export const messages: Messages = {
  'zh-CN': {
    [StatusType.School]: '本校学籍',
    [StatusType.Empty]: '空挂学籍',
    [StatusType.No]: '无本校学籍',
    [StatusType.Nul]: '无学籍',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [StatusType.School]: '本校学籍',
    [StatusType.Empty]: '空挂学籍',
    [StatusType.No]: '无本校学籍',
    [StatusType.Nul]: '无学籍',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
