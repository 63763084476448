import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppRadioGroup from '@components/app-radio-group'
import AppSelect from '@components/app-select'
import i18n, { SubjectTypeList } from './i18n'

const valueField = 'subjectTypeID'
const labelField = 'subjectTypeName'
const prefixCls = `subject-type`

function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `SubjectType${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class SubjectTypeComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }
    get locale(): Locale {
      return this.$i18n.locale as Locale
    }

    get list() {
      return this.extra.concat(
        SubjectTypeList.map(value => {
          return {
            [labelField]: i18n.messages[this.locale][value],
            [valueField]: value,
            disabled: this.disabled,
          }
        })
      )
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
        case ComponentType.RadioGroup:
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (type === ComponentType.Text) {
        children = [`${i18n.messages[this.locale][this.$attrs.value]}`]
      }
      return createElement(
        component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return SubjectTypeComponent
}

export const SubjectTypeRadio = createComponent(
  AppRadioGroup,
  ComponentType.RadioGroup
)
export const SubjectTypeSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
export const SubjectTypeText = createComponent('span', ComponentType.Text)
