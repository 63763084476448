/**
 * 班级管理页面路由
 */
import GlassPage from '@pages/glass-management'
export default {
  path: '/glasses',
  name: 'glass-management',
  meta: { title: '班级管理', authCode: '0503', parentAuthCode: '05' },
  component: GlassPage,
  props: { name: 'glass-management' },
}
