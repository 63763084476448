































import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import { Button, Upload, Form, FormItem, Icon, Modal } from 'view-design'
import { Constant, Semester } from 'xuexin-vuex'
import Export from '@store/modules/export'
import i18n from './i18n'

const components = {
  AppModal,
  Button,
  Form,
  FormItem,
  Upload,
  Icon,
}

type ImportType = Constant.ImportType
type PageType = Constant.PageType

type DataType = {
  termID: number
  unitID: number
  pageType: PageType
  importType: ImportType
}

type Model = {
  type: number
  term: number
  files: any
}

@Component({ name: 'MultipleExportModal', components, i18n })
export default class MultipleExportModal extends Vue {
  @Model('input') private value!: boolean
  @Prop({ default: () => {} }) private readonly data!: DataType
  @Semester.Getter('item')
  private readonly getSemesterItem!: Semester.Getter.Item
  @Export.Action('create')
  private readonly create!: Export.Action.Create
  @Export.Getter('itemStatus')
  private readonly itemStatus!: Export.Getter.ItemStatus
  @Export.Getter('item') private readonly item!: Export.Getter.Item
  @Ref('form') private readonly form!: Form
  @Ref('upload') private readonly upload!: Upload

  private readonly prefixCls = 'multiple-export-modal'
  private model: Partial<Model> = { files: '' }
  private visible = false
  private loading = true

  get rules() {
    return {
      // prettier-ignore
      files: [{trigger: 'change', message: this.$t('valid'), type: 'object'}]
    }
  }

  get termID() {
    return this.data.termID
  }

  get unitID() {
    return this.data.unitID
  }

  get type() {
    return this.data.importType
  }

  get pageType() {
    return this.data.pageType
  }

  get showTerm() {
    const constant = Constant.PageType
    switch (this.pageType) {
      /** 班级管理、分班管理显示学期信息 */
      case constant.DivideClass: // 分班管理
      case constant.Glass: // 班级管理
        return true
        break
      /** 在班学生、毕业学生一期只展示导入文件选项 */
      case constant.InClass: // 在班学生
      case constant.Graduation: // 毕业学生
        return false
        break
    }
  }

  get creating() {
    return this.itemStatus('key_&')?.creating === true
  }

  get creatingError() {
    return this.itemStatus('key_&')?.creatingError || null
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (creating === false && previous === true) {
      if (this.creatingError !== null) {
        this.$Message.error(this.creatingError)
        return this.stopAction()
      }

      const count = this.item('key_&')
      this.$Message.success(
        `${this.$t('text.success')} ${count?.successCount}${this.$t(
          'text.unit'
        )},${this.$t('text.failure')} ${count?.failureCount}${this.$t(
          'text.unit'
        )},${this.$t('text.tip')}`
      )
      this.$set(this.$data, 'model', { files: '' })
      this.$emit('on-finish')
    }
  }

  /** 文件上传 */
  private handleUpload(file: any) {
    /** 展示文件列表 */
    this.model.files = file
    return false
  }

  private handleOK() {
    if (Boolean(this.termID) === false) {
      return
    }

    this.form.validate(valid => {
      const { files } = this.model
      if (valid !== true || files === void 0) {
        return this.stopAction()
      }

      /** 文件上传 */
      const formData = new FormData()

      formData.append('termID', `${this.termID}`)
      formData.append('unitID', `${this.unitID}`)
      formData.append('originalFile', files) // 文件对象

      this.create({ file: formData, type: this.type })
    })
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      if (Boolean(this.termID) === false) {
        return this.$Message.warning(this.$t('text.noTermID'))
      }
      /** 通过termID获取当前学期详情 */
      const term = this.getSemesterItem(this.termID)
      /** 设置学期名称 */
      this.$set(this.$data.model, 'term', term?.termName)
    }
    this.$emit('on-visible-change', visible)
  }

  private handleHidden() {
    this.form.resetFields()
    this.stopAction()
    this.$emit('on-hidden')
    this.$set(this.$data, 'model', { files: '' })
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private stopAction() {
    this.loading = false
    this.$nextTick(() => {
      this.loading = true
    })
  }
}
