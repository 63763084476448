




























import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import { Student } from 'xuexin-vuex'
import { ActionType } from 'xuexin-vuex/src/constant'
import AppModal from '@components/app-modal'
import { Button, Icon, Form, FormItem, Input } from 'view-design'
import i18n from '../../i18n'
const components = {
  AppModal,
  Button,
  Icon,
  Form,
  FormItem,
  Input,
}
@Component({ name: 'AdmissionModal', components, i18n })
export default class AdmissionModal extends Vue {
  @Ref('form') private readonly form!: Form
  @Model('input') private value!: any
  @Prop({ type: Number, required: true })
  private readonly tranferStudentID!: number
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('itemStatus')
  private readonly itemStatus!: Student.Getter.ItemStatus

  // todo 调用退回接口
  private readonly prefixCls = 'return-confirm-view'
  private visible = false
  private loading = true
  private unitID = 1

  private model = {
    reason: '',
  }

  get rules() {
    return {
      reason: {
        validator: this.validReason,
        type: 'blur',
      },
    }
  }
  get getItemStatus() {
    return this.itemStatus(this.tranferStudentID.toString())
  }
  // 监听窗口显示状态
  @Watch('value', { immediate: true })
  private watchVisible(value: boolean) {
    this.visible = value
  }
  // 监听退回事件状态
  @Watch('getItemStatus.updating', { immediate: true })
  private watchUpdating(updating: boolean, previous: boolean) {
    // if (!updating && previous) {
    //   if (this.getItemStatus.updatingError !== null) {
    //     this.$Message.error(this.getItemStatus.updatingError)
    //     return this.stopAction()
    //   }
    //   this.$emit('input', false)
    //   this.$emit('on-change')
    // }
    //接收和退回弹窗里面的提示互相冲突，所以放到then 后面
  }

  private stopAction(): void {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }
  // 确定事件
  private handleOK(): void {
    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        return this.stopAction()
      }
      this.update({
        actionType: ActionType.Return,
        tranferStudentID: this.tranferStudentID,
        backReason: this.model.reason,
      }).then(res => {
        if (res.status) {
          this.$Message.success(this.$t('content.ctrlSuccess'))
          this.$emit('input', false)
          this.$emit('on-change')
          return
        }
        if (res.message && res.message !== void 0) {
          this.$Message.error(res.message)
          this.$emit('input', false)
          return this.stopAction()
        }
      })
    })
  }
  // 关闭弹窗
  private handleHidden(): void {
    this.form.resetFields()
    this.$emit('input', false)
    this.stopAction()
  }
  // 验证规则
  private validReason(rule: string, value: string, callback: any) {
    if (value === '') {
      callback(new Error(`${this.$t('content.reasonNoNull')}`))
    } else {
      callback()
    }
  }
}
