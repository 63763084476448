import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { Student } from 'xuexin-vuex'
import { Spin, Form, FormItem, Button } from 'view-design'
import StudentField from '../student-field'
import i18n from './i18n'
import { toNumber, isArray, DateUtil } from '@util'

type Model = { columns: Student.Column[] }

@Component({ name: 'PaneContent', i18n })
export default class PaneContent extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly pending!: boolean
  @Prop() private readonly direction!: number
  @Prop() private readonly termID!: number
  @Prop() private readonly parentUnitID!: number
  @Prop() private readonly unitID!: number
  @Prop() private readonly schoolDepartID!: number
  @Prop() private readonly manageTypeID!: number
  @Prop() private readonly phaseID!: number
  /** 国籍 */
  @Prop() private readonly nation!: string
  @Prop() private readonly studentMark!: string // 集团优生
  @Prop() private readonly freshStudent!: number // 应届生
  @Prop() private readonly hasTermBill!: number // 是否有账单
  @Prop() private readonly academicDirection!: number
  @Prop() private readonly idLength!: number
  @Prop() private readonly statusType!: number
  @Prop() private readonly transientStudent!: number

  @Prop({ type: Array, default: () => [] }) private data!: Student.Column[]
  @Prop({ type: Boolean, default: false }) private visible!: boolean
  @Prop({ type: String }) private readonly actionType!: string
  @Ref('form') private readonly form!: Form

  private readonly prefixCls: string = 'pane-content'
  private hidden = true
  private model: Model = { columns: [] }
  private hiddenSchool = true
  private otherIdType: any = [,]

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get fieldProps() {
    return {
      basicCode: 'noFamily',
      actionType: this.actionType,
      direction: this.direction,
      termID: this.termID,
      parentUnitID: this.parentUnitID,
      unitID: this.unitID,
      schoolDepartID: this.schoolDepartID,
      manageTypeID: this.manageTypeID,
      phaseID: this.phaseID,
      studentMark: this.studentMark,
      hiddenSchool: this.hiddenSchool,
      freshStudent: this.freshStudent,
      statusType: this.statusType,
      hasTermBill: this.hasTermBill,
      academicDirection: this.academicDirection,
      idLength: this.idLength,
      nation: this.nation,
      otherIdType: this.otherIdType,
    }
  }

  @Watch('visible', { immediate: true })
  watchVisible(visible: boolean, previous: boolean) {
    if (this.hidden === true && visible === true && previous !== true) {
      this.$set(this.$data, 'hidden', false)
    }
  }

  @Watch('data', { immediate: false, deep: true })
  watchData() {
    this.buildColumns()
  }

  created() {
    this.buildColumns()
  }

  render(h: CreateElement) {
    /** 不可见状态下，可以不渲染表单 */
    if (this.hidden === true) {
      return h('div', { class: this.classes }, [
        h(Spin, { props: { fix: true } }, [`${this.$t('loading')}`]),
      ])
    }

    const formItems: VNodeChildren = []

    this.model.columns.forEach((column, index) => {
      /** 不添加隐藏的组件 */
      if (this.isHidden(column) === true) {
        return void 0
      }

      const prop = `columns.${index}`
      formItems.push(
        h(StudentField, {
          key: `${column.columnCode}`,
          props: {
            data: column,
            label: column.columnName,
            prop: `${prop}`,
            ...this.fieldProps,
          },
          on: {
            input: this.handleFieldInput,
            'on-finish': this.handleFieldOnFinish,
            addID: this.handleAdd,
            delID: this.handleDel,
          },
        })
      )
    })

    return h('div', { class: this.classes }, [
      h(
        Form,
        {
          ref: 'form',
          class: `${this.prefixCls}_form`,
          props: { labelWidth: 140, model: this.model },
          nativeOn: { submit: this.handleSubmit },
        },
        formItems
      ),

      h('div', { class: `${this.prefixCls}_footer` }, [
        h(
          Button,
          {
            props: { disabled: this.pending, type: 'primary' },
            on: { click: this.handleSave },
          },
          [`${this.$t('save')}`]
        ),
      ]),
    ])
  }

  mounted() {}

  private isHidden(column: Student.Column) {
    if (
      this.phaseID !== 4 && // 非高中时，以下字段不显示
      (column.columnCode === 'enrollScore' || // 不显示中考成绩
        column.columnCode === 'researchField') // 不显示学业方向
    ) {
      return true
    }

    /** 非中国籍时，隐藏 "国家学籍号、民族、证件类型、证件号码、户口类型、籍贯、出生地、户口所在地" 字段 */
    if (!['CN', 'TW', 'HK', 'MO'].includes(this.nation)) {
      switch (column.columnCode) {
        case 'statusID': /** 国家学籍号 */
        case 'provincialStatusID': /** 省级学籍号 */
        case 'ethnicity': /** 民族 */
        case 'politicalStatus': /** 政治面貌 */
        case 'residenceType': /** 户口类型 */
        case 'nativePlace': /** 籍贯 */
        case 'birthplace': /** 出生地 */
        case 'registeredPlace' /** 户口所在地 */:
          return true
      }
    }

    if (Number(this.freshStudent) === 0 && `${this.statusType}` === '4') {
      if (
        column.columnCode === 'transientStudent' ||
        column.columnCode === 'transientStatusType'
      ) {
        return true
      }
    } else {
      if (this.transientStudent != 1) {
        if (column.columnCode === 'transientStatusType') {
          return true
        }
      }
    }

    /**学业方向/入学分类为”非夏季高考生“时，不显示学生类别 */
    if (
      column.columnCode === 'studentCategory' &&
      Number(this.academicDirection) === 5
    ) {
      return true
    }

    return false
  }

  private buildColumns() {
    /** 过滤数据 */
    const columns = this.data.reduce<Student.Column[]>((columns, column) => {
      //记录当前选择的证件类型
      if (column.columnCode.indexOf('idType1') != -1) {
        this.otherIdType[0] = column.value
      }
      if (column.columnCode.indexOf('idType2') != -1) {
        this.otherIdType[1] = column.value
      }

      return columns.concat(Object.assign({}, column))
    }, [])

    //删除了辅助证件后，otherIdType[1]设置为undefined
    if (columns.findIndex(i => i.columnCode == 'idType2') == -1) {
      this.otherIdType[1] = undefined
    }
    this.$set(this.model, 'columns', columns)
  }

  private handleFieldInput(prop: string, value: unknown) {
    // console.log(`${this.$options.name}.handleFieldInput(${prop},${value})`)
    const path: any = prop.split('.')
    const property = path.pop()
    let model: any = this.model
    path.forEach((key: any) => (model = model[key]))
    if (property !== void 0 && model !== void 0) {
      this.$set(model, property, value)

      if (model.columnCode === 'idType1') {
        this.otherIdType[0] = value
      }

      if (model.columnCode === 'idType2') {
        this.otherIdType[1] = value
      }

      if (model.parentCode) {
        if (model.columnCode === 'idNo') {
          const columnIndex: any = path[1]
          const patValue = this.model.columns[columnIndex].value

          this.$emit(
            'on-field-change',
            model.parentCode,
            `${patValue}-${value}`,
            this.model.columns
          )
        } else {
          this.$emit(
            'on-field-change',
            model.columnCode,
            value,
            this.model.columns
          )
        }
      } else {
        if (
          model.columnCode.indexOf('idType') != -1 &&
          model.children &&
          model.children.length > 0
        ) {
          this.$emit(
            'on-field-change',
            model.columnCode,
            `${value}-${model.children[0].value}`,
            this.model.columns
          )

          if (model.columnCode.indexOf('idType1') != -1) {
            this.otherIdType[0] = value
          }
          if (model.columnCode.indexOf('idType2') != -1) {
            this.otherIdType[1] = value
          }
        } else {
          this.$emit('on-field-change', model.columnCode, value)
        }
      }

      //根据证件类型，判断是否显示护照颁发地和有效期
      let idTypeItem: any = this.model.columns[path[1]]
      if (
        idTypeItem.columnCode === 'idType1' ||
        idTypeItem.columnCode === 'idType2'
      ) {
        if ([2, 3, 6].includes(idTypeItem.value)) {
          idTypeItem.children[1].forceShow = 1
          idTypeItem.children[2].forceShow = 1
        } else {
          idTypeItem.children[1].forceShow = 0
          idTypeItem.children[2].forceShow = 0
        }
        //把证件类型记录到证件号码的parentValue中
        if (idTypeItem.children[0].columnCode.indexOf('idNo') != -1) {
          idTypeItem.children[0].parentValue = idTypeItem.value
        }

        //判断护照类型改变时，进行校验另一种类型是否重复
        if (path.length == 2) {
          if (idTypeItem.columnCode === 'idType1') {
            if (
              this.model.columns[Number(path[1]) + 1] &&
              this.model.columns[Number(path[1]) + 1].value != value
            ) {
              this.form.validateField(`columns.${Number(path[1]) + 1}.value`)
            }
          }
          if (idTypeItem.columnCode === 'idType2') {
            if (this.model.columns[Number(path[1]) - 1].value != value) {
              this.form.validateField(`columns.${Number(path[1]) - 1}.value`)
            }
          }
        }
      }
    }
  }

  private handleFieldOnFinish(prop: string, list: unknown[]) {
    const path = prop.split('.')
    const property = path.pop()
    let model: any = this.model
    path.forEach(key => (model = model[key]))

    if (property !== void 0 && model !== void 0) {
      switch (model.columnCode) {
        case 'school' /** 报读校区 */:
          this.$set(this.$data, 'hiddenSchool', list.length === 1)
          if (list.length > 0) {
            const unit = list[0] as any
            const value = model.value || unit.unitID || ''
            /** 若与当前值不相等，则更新数据 */
            if (model[property] !== value) {
              this.$set(model, property, value)
              this.$emit('on-field-change', model.columnCode, value)
            }
          }
          break
        default:
          break
      }
    }
  }

  private restoreColumns(columns: Student.Column[]): Student.Column[] {
    return columns.reduce<Student.Column[]>((columns, column) => {
      /** 不处理隐藏的组件 */
      if (this.isHidden(column) === true) {
        return columns
      }

      const { children = [], ...item } = column

      switch (item.columnCode) {
        case 'address' /** 联系地址 */:
        case 'homeAddress': /** 现家庭住址 */
        case 'nativePlace': /** 籍贯 */
        case 'birthplace': /** 出生地 */
        case 'registeredPlace' /** 户口所在地 */:
          item.value = isArray(item.value) ? item.value.join('-') : item.value
          break
        /** 处理日期类数据 */
        case 'birthday' /** 出生日期 */:
        case 'passportDate' /** 护照有效期 */:
          item.value = DateUtil.parse(item.value)
          break
        default:
          break
      }
      columns.push(item)

      return columns.concat(this.restoreColumns(children))
    }, [])
  }

  private handleSave() {
    this.form.validate(valid => {
      if (valid !== true) {
        return void 0
      }

      //验证证件类型是否重复
      if (
        this.otherIdType[0] &&
        this.otherIdType[1] &&
        this.otherIdType[1] == this.otherIdType[0]
      ) {
        return this.$Message.error(`证件类型不能重复`)
      }

      const columns = this.restoreColumns(this.model.columns)
      this.$emit('on-submit', columns)
    })
  }

  private handleSubmit(event: Event) {
    event.preventDefault()
  }

  private handleAdd(name: string, value: any) {
    this.$emit('addID', name, value)
  }
  private handleDel(name: string, value: any) {
    this.$emit('delID', name, value)
  }
}
