













































import Vue from 'vue'
import { Component, Ref, Watch } from 'vue-property-decorator'
import { Button } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import AppTable from '@components/app-table'
import AddEditModal from './components/addEditModal'
import { EnableStatusSelect } from '@business-components/enable-status'
import { ApprovalStatusSelect } from '@business-components/approval-status'
import { Abroad, Session } from 'xuexin-vuex'
import i18n from './i18n'

const components = {
  ViewLayout,
  Button,
  AppTable,
  AddEditModal,
  EnableStatusSelect,
  ApprovalStatusSelect,
}

@Component({ name: 'AbroadPage', components, i18n })
export default class AbroadPage extends Vue {
  @Abroad.Action('delete') private readonly delete!: Abroad.Action.Delete
  @Abroad.Action('fetchList')
  private readonly fetchList!: Abroad.Action.FetchList
  @Abroad.Getter('list') private readonly list!: Abroad.Getter.List
  @Abroad.Getter('listStatus')
  private readonly listStatus!: Abroad.Getter.ListStatus
  @Abroad.Getter('itemStatus')
  private readonly itemStatus!: Abroad.Getter.ItemStatus
  private readonly prefixCls = 'abroad-page'

  private showAddEdit = false
  private abroadID = -1
  private abroadName = ''
  private enable = 1
  private showcheck = false
  get loading() {
    return this.listStatus.fetching
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  //表头配置
  get columns() {
    return [
      {
        title: this.$t('columns.index'),
        type: 'index',
        align: 'center',
        width: 80,
      },
      { title: this.$t('columns.abroad'), key: 'abroadName' },
      {
        title: this.$t('columns.state'),
        key: 'enable',
        slot: 'enableAction',
      },
      { title: this.$t('columns.action'), slot: 'handleAction' },
    ]
  }
  get getItemStatus() {
    return this.itemStatus(this.abroadID)
  }
  // 监听出国方向查询状态
  @Watch('listStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.listStatus.fetchingError !== null &&
        this.listStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }

  // 监听出国方向删除状态
  @Watch('getItemStatus.deleting')
  private watchDeleting(deleting: boolean, previous: boolean) {
    if (deleting === false && deleting !== previous) {
      if (
        this.getItemStatus.deletingError !== null &&
        this.getItemStatus.deletingError !== void 0
      ) {
        this.$Message.error(this.getItemStatus.deletingError)
        return
      }
      this.$Message.success(this.$t('message.delete-success') as string)
      this.fetchList({})
    }
  }
  private created() {
    this.fetchList({})
  }
  private refresh() {
    this.fetchList({})
  }
  //编辑按钮点击事件
  private showEditModal(item: any) {
    this.abroadName = item.abroadName
    this.enable = item.enable
    this.abroadID = item.abroadID
    this.showAddEdit = true
  }
  //添加按钮点击事件
  private showAddModal() {
    this.abroadName = ''
    this.enable = 1
    this.abroadID = -1
    this.showAddEdit = true
  }
  //删除事件
  private handleDel(id: number): void {
    this.abroadID = id
    this.$Modal.confirm({
      title: this.$t('button-text.delete') as string,
      content: `<p>${this.$t('message.confirm-delete')}</p>`,
      onOk: () => {
        this.delete({ abroadID: id })
      },
    })
  }
}
