import Vue, { VNode, CreateElement } from 'vue'
import { Component } from 'vue-property-decorator'
import { Session, Constant } from 'xuexin-vuex'
import Page from '@store/modules/page'
import { Button, Tooltip } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import { DateUtil } from '@util'
import i18n from './i18n'
import PageTyping from '../../types/page'

interface TableColumnRenderParams {
  row: any
  column: any
  index: number
}

interface TableColumn {
  type?: 'index' | 'selection' | 'expand' | 'html'
  title?: string
  key?: string
  minWidth?: number
  align?: 'left' | 'right' | 'center'
  className?: string
  fixed?: 'left' | 'right'
  tooltip?: boolean
  slot?: string
  sortable?: boolean | 'custom'
  sortType?: 'asc' | 'desc'
  width?: number
  ellipsis?: boolean
  display?: 'block' | 'inline' | 'inline-block'
  render?: (h: CreateElement, params: TableColumnRenderParams) => VNode
}

export default function(wrappedComponent: Vue.Component) {
  @Component({ name: 'PageConfigProvider', i18n, inheritAttrs: false })
  class PageConfigProvider extends Vue {
    @Page.Action('fetch') private readonly fetch!: PageTyping.Action.Fetch
    @Page.Getter('itemStatus')
    private readonly getItemStatus!: PageTyping.Getter.ItemStatus
    @Page.Getter('item') private readonly getItem!: PageTyping.Getter.Item
    @Session.Getter('user') private readonly user!: Session.Getter.User

    private readonly prefixCls = 'page-config-provider'

    get authCode() {
      return this.$route.meta.authCode
    }

    get status() {
      return this.getItemStatus(this.authCode)
    }

    get fetching() {
      return this.status.fetching === true
    }

    get item() {
      return (
        this.getItem(this.authCode) || { searches: [], columns: [], auths: [] }
      )
    }

    get columns() {
      return this.item.columns
        .reduce<TableColumn[]>((columns, item) => {
          if (item.show === 0) {
            return columns
          }

          /** 生成新的 column 信息 */
          const column: TableColumn = {
            title: item.title,
            key: item.code,
            width: 140,
            ellipsis: true,
            tooltip: false,
            align: 'left',
            display: 'inline',
          }

          /** 分类处理 item */
          switch (item.code) {
            case 'num' /** 序号 */:
              column.align = 'center'
              column.width = 80
              break
            case 'inUnitName': /** 转至校区 */
            case 'parentUnitName': /** 学校名称 */
            case 'unitName': /** 校区名称 */
            case 'term' /** 学期名称 */:
            case 'previousSchool' /** 原就读学校 */:
              column.width = 220
              column.render = (h, { row }) => {
                const title = row[item.code]
                return h('span', { attrs: { title } }, [title])
              }
              break
            case 'gradeName': /** 年级名称 */
            case 'className': /** 班级名称 */
            case 'studentName' /** 学生姓名 */:
              column.align = item.code === 'studentName' ? 'center' : 'left'
              column.width = item.code === 'studentName' ? 120 : 240
              column.slot = item.code
              break
            case 'parentGender': /** 家长性别 */
            case 'gender' /** 性别 */:
              column.align = 'center'
              column.width = item.code === 'gender' ? 80 : 100
              column.render = (h, { row }) => {
                return h('span', {}, [`${this.$t(`gender.${row[item.code]}`)}`])
              }
              break
            case 'tranferStatus' /** 互转状态 */:
              column.slot = 'tranferStatus'
              column.minWidth = 130
              break
            case 'applyDate' /** 申请时间*/:
              column.slot = 'applyDate'
              column.width = 170
              break
            case 'tranferConfirmDate' /** 确认时间 */:
              column.slot = 'tranferConfirmDate'
              column.width = 250
              break
            case 'tranferConfirmStatus' /** 确认状态 */:
              column.align = 'center'
              column.slot = item.code
              column.width = 160
              break
            case 'approveStatus' /** 审批状态 */:
              column.align = 'center'
              column.slot = item.code
              break
            case 'parentMobile' /** 家长电话 */:
            case 'parentIDNO' /** 证件号 */:
              column.align = 'center'
              column.width = 140
              column.render = (h, { row }) => {
                const numNo = row[`${item.code}`]
                const text = numNo
                // ? NumFormate(`${numNo}`) : '-'
                return h!('span', {}, [text])
              }
              break
            case 'studentPhone' /** 学生手机号 */:
              column.align = 'center'
              column.width = 140
              break
            case 'startDate': /** 开始日期 */
            case 'endDate': /** 结束日期 */
            case 'studyDate': /** 就读日期 */
            case 'graduateDate': /** 毕业日期 */
            case 'enterDate': /** 录取日期 */
            case 'tranferUnitDate': /** 互转日期 */
            case 'tranferDate': /** 转学日期 */
            case 'approveDate': /** 审批日期 */
            case 'leaveDate': /** 离校日期 */
            case 'operatorDate': /** 操作时间 */
            case 'departOperateDate': /**内转操作时间 */
            case 'identifyDate': /**内转确认时间 */
            case 'createDate': /** 创建时间 */
            case 'birthday' /** 出生日期 */:
            case 'passportDate' /** 护照有效期 */:
            case 'dvisionDate' /** 分班时间*/:
              column.width = 120
              column.align = 'center'
              column.render = (h, { row }) => {
                const date = row[`${item.code}`]
                const text =
                  typeof date !== 'number'
                    ? '-'
                    : DateUtil.format(date, 'yyyy/MM/dd')
                return h!('span', {}, [text])
              }
              break
            case 'studentCount' /** 学生数 男/女 */:
              column.render = (h, { row }) => {
                return h('span', {}, [
                  `${row.studentCount}  ${row.maleCount}/${row.femaleCount}`,
                ])
              }
              break
            case 'statusID': /** 国家学籍号 */
            case 'provincialStatusID' /** 省级学籍号 */:
              column.width = 120
              break
            case 'primarySchoolScoreRemark' /**小学成绩说明 */:
              column.width = 150
              break
            case 'academicDirectionName':
            case 'manageTypeName': /** 报读类型 */
            case 'classTypeName': /** 班级类型 */
            case 'unitClassTypeName': /** 校本班型 */

            case 'graduationSchoolName' /**毕业学校 */:
            case 'height': /** 身高 */
            case 'weight': /** 体重 */
            case 'shoeSize': /** 鞋码 */
            case 'abroadName': /** 出国方向 */
            case 'schoolSystemName': /** 学制 */
            case 'phaseName': /** 学段 */
            case 'schoolDepartName': /** 学部 */
            case 'registStatus': /** 注册状态 */
            case 'feedbackStatus' /** 试读结果 */:
            case 'studentID': /** 学生ID */
            case 'usedName' /** 曾用名 */:
            case 'gradeIntention' /** 报读年级 */:
            case 'studentTypeSelect' /** 学生状态（新招学生） */:
              column.width = 100
              break
            case 'collegeDirection' /** 高考方向 */:
            case 'studentPoliticalStatus' /** 学生政治面貌 */:
            case 'transientStatusType' /** 借读生转籍状态 */:
              column.width = 140
              column.render = (h, { row }) => {
                const name = item.code + 'Name'
                return h('span', {}, [row[name] || '-'])
              }
              break

            case 'academicDirection' /**学业方向/入学分类 */:
              column.slot = 'academicDirection'
              column.width = 100
              column.render = (h, { row }) => {
                const { academicDirectionName } = row
                if (academicDirectionName && academicDirectionName.length > 4) {
                  return h(
                    'Tooltip',
                    {
                      props: {
                        content: `${academicDirectionName}`,
                        transfer: true,
                      },
                    },
                    [
                      h(
                        'span',
                        {
                          attrs: {
                            style:
                              'display: inline-block;width: 60px;word-break: keep-all;white-space: nowrap;overflow: hidden;text-overflow: ellipsis',
                          },
                        },
                        [`${academicDirectionName}`]
                      ),
                    ]
                  )
                } else {
                  return h('span', {}, [`${academicDirectionName || '-'}`])
                }
              }
              break
            case 'studentCategory' /**学生类别 */:
              column.slot = 'studentCategory'
              column.width = 100

              column.render = (h, { row }) => {
                const { studentCategoryName, studentCategory } = row
                if (studentCategoryName && studentCategoryName.length > 4) {
                  return h(
                    'Tooltip',
                    {
                      props: {
                        content: `${studentCategoryName}`,
                        transfer: true,
                      },
                    },
                    [
                      h(
                        'span',
                        {
                          attrs: {
                            style:
                              'display: inline-block;width: 60px;word-break: keep-all;white-space: nowrap;overflow: hidden;text-overflow: ellipsis',
                          },
                        },
                        [`${studentCategoryName}`]
                      ),
                    ]
                  )
                } else {
                  return h('span', {}, [`${studentCategoryName || '-'}`])
                }
              }

              break
            case 'remark' /** 备注 */:
              column.width = 300
              break
            case 'startTermName': /** 开始学期 */
            case 'endTermName': /** 结束学期 */
            case 'feedbackDate' /** 试读日期 */:
            case 'IDNO': /** 证件号 */
            // case 'parentIDNO': /** 证件号 */
            case 'passportNO': /** 护照号 */
            case 'diseaseHistory': /** 特异病史 */
            case 'graduateTerm': /** 毕业学期 */
            case 'joinTerm': /** 入学学期 */
            case 'tranferTerm' /** 转学学期 */:
              column.width = 240
              break
            /** 处理 boolean 值 */
            case 'onlyChild': /** 独生女子 */
            case 'transientStudent': /** 借读生 */
            case 'staffChild': /** 校职工子女 */

            case 'ownerChild': /** 业主子女 */
            case 'studentMark': /** 集团优生 */
            case 'singleParent' /** 单亲家庭 */:
            case 'freshStudent' /**应届生 */:
              column.align = 'center'
              column.width = item.code === 'staffChild' ? 140 : 100
              column.render = (h, { row }) => {
                return h('span', {}, [
                  `${this.$t(`boolean.${row[item.code]}`)}`,
                ])
              }
              break
            case 'graduateDirection' /** 毕业去向 */:
              column.render = (h, { row }) => {
                return h('span', {}, [row.graduateDirectionName || '-'])
              }
              break
            case 'bloodType':
              column.render = (h, { row }) => {
                return h('span', {}, [
                  `${this.$t(`bloodType.${row[item.code]}`)}`,
                ])
              }
              break
            case 'graduateDirectStatus' /** 是否直升 */:
              column.align = 'center'
              column.width = 100
              column.render = (h, { row }) => {
                return h('span', {}, [
                  `${['否', '直升', '毕业重新入校', '复读'][row[item.code]] ||
                    '-'}`,
                ])
              }
              break
            /** 处理常量转换 */
            case 'researchField' /** 学业方向 */:
            case 'enrollType' /** 入学分类 */:
            case 'sourceChannel' /** 学生来源 */:
            case 'IDType' /** 证件类型 */:
            case 'parentIDType': /** 家长证件类型 */
            case 'householdIncome': /** 家庭年收入 */
            case 'residenceType': /** 户口类型 */
            case 'direction' /** 报名方向 */:
            case 'statusType' /** 学籍状态 */:
            case 'studentStatus' /** 学生状态 */:
            case 'education': /** 文化程度 */
            case 'politicalStatus' /** 政治面貌 */:
            case 'workplaceType': /** 工作性质 */
            case 'relation' /** 家庭关系 */:
            case 'tranferReason' /** 转学原因 */:
            case 'flowStatus' /** 流程状态 */:
            case 'checkLost' /** 考核流失 */:
            case 'payType' /** 缴费状态 */:
            case 'divideType' /** 分类型 */:
              column.width = 120
              switch (item.code) {
                case 'parentIDType' /** 家长证件类型 */:
                case 'householdIncome' /** 家庭年收入 */:
                case 'IDType' /** 证件类型 */:
                  column.width = 140
                  break
                case 'studentStatus' /** 学生状态 */:
                case 'sourceChannel' /** 学生来源 */:
                  column.align = 'center'
                  break
              }
              column.render = (h, { row }) => {
                let key = ''
                switch (item.code) {
                  case 'parentIDType':
                    key = 'IDType'
                    break
                  default:
                    key = item.code
                    break
                }
                if (item.code === 'parentIDType') {
                  return h('span', {}, [
                    `${this.$t(`${key}.${row.parentIDType}`) || '-'}`,
                  ])
                } else {
                  return h('span', {}, [
                    `${this.$t(`${key}.${row[key]}`) || '-'}`,
                  ])
                }
              }
              break
            case 'enrollScore' /** 入学成绩 */:
              column.width = 200
              column.render = (h, { row }) => {
                const { enrollScore, studentscore, studentscoreArea } = row
                let value = `${this.$t(`enrollScore.${enrollScore}`)}`
                value += studentscore ? ` ${studentscore}` : ''
                value += studentscoreArea ? ` ${studentscoreArea}` : ''
                return h('span', {}, [value])
              }
              break
            case 'primarySchoolScore' /** 小学毕业成绩 */:
              column.width = 140
              column.render = (h, { row }) => {
                const { primarySchoolScore, primarySchoolScoreRemark } = row
                let value = primarySchoolScore || '-'
                return h('span', {}, [value])
              }
              break
            case 'highSchoolScore' /** 高考成绩 */:
              column.width = 100
              column.render = (h, { row }) => {
                const { highSchoolScore } = row
                return h('span', {}, [highSchoolScore || '-'])
              }
              break

            case 'nation': /** 国籍 */
            case 'parentNation': /** 家长国籍 */
            case 'ethnicity' /** 民族 */:
              column.width = 100
              column.render = (h, { row }) => {
                const value = row[`${item.code}Name`]
                return h('span', {}, [`${value || '-'}`])
              }
              break
            case 'nativePlace': /** 籍贯 */
            case 'registeredPlace' /** 户口所在地 */:
              column.width = 240
              column.render = (h, { row }) => {
                const value = row[`${item.code}InfoFull`] || '-'
                return h('span', {}, [`${value}`])
              }
              break
            case 'homeAddressInfo' /** 现家庭住址 */:
              column.width = 240
              column.render = (h, { row }) => {
                const value = row[`${item.code}Full`] || ''
                return h('span', {}, [`${value}`])
              }
              break
            case 'schoolDepartStatusName' /** 内转状态 */:
              column.width = 100
              column.slot = 'schoolDepartStatusName'
              break

            default:
              break
          }

          return columns.concat(column)
        }, [])
        .concat({ title: ' ', key: '' })
    }

    get auths() {
      return this.item.auths.reduce<Record<string, boolean>>((auths, item) => {
        if (item.type === 2) {
          auths[item.code] = true
        }
        return auths
      }, {})
    }

    get props() {
      return {
        searches: this.item.searches,
        auths: this.auths,
        columns: this.columns,
      }
    }

    get organID() {
      return this.user?.organID
    }

    get identityType() {
      return this.user?.identityType
    }

    created() {
      this.fetchConfig()
    }

    render(h: CreateElement) {
      /** 正在查询权限时，显示 loading */
      if (this.fetching === true) {
        return h(ViewLayout, { props: { loading: true } })
      }

      /** 查询页面配置失败，显示错误原因 */
      if (
        this.fetching === false &&
        typeof this.status.fetchingError === 'string'
      ) {
        const ErrorMessage = h(
          'span',
          {
            class: `${this.prefixCls}_message`,
            style: {
              display: 'inline-block',
              marginBottom: '24px',
              fontSize: '24px',
            },
          },
          [this.status.fetchingError]
        )

        const RetryAction = h(
          Button,
          { props: { type: 'primary' }, on: { click: this.handleRetry } },
          [`${this.$t('retry')}`]
        )

        return h(ViewLayout, {}, [
          h(
            'div',
            {
              class: `${this.prefixCls}_error`,
              style: {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
              },
            },
            [ErrorMessage, h('br'), RetryAction]
          ),
        ])
      }

      return h(wrappedComponent, { props: this.props })
    }

    activated() {
      if (!this.$route.meta.isBack) {
        this.fetchConfig()
      }
      this.$route.meta.isBack = false //请求完后进行初始化
    }

    deactivated() {}

    private fetchConfig() {
      const parameter: Record<string, any> = {
        authCode: this.authCode,
      }

      switch (this.identityType) {
        case Constant.IdentityType.Organization:
          parameter.organID = this.user?.organID
          break
        case Constant.IdentityType.School:
          parameter.unitID = this.user?.parentUnitID
        default:
          break
      }

      this.fetch(parameter)
    }

    private handleRetry() {
      this.fetchConfig()
    }
  }

  return PageConfigProvider
}
