export enum ReSchoolStatus {
  True = 1,
  False = 0,
}

export const ReSchoolStatusList = [ReSchoolStatus.True, ReSchoolStatus.False]

export const messages: Messages = {
  'zh-CN': {
    [`${ReSchoolStatus.True}`]: '已重新入校',
    [`${ReSchoolStatus.False}`]: '未重新入校',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${ReSchoolStatus.True}`]: '已重新入校',
    [`${ReSchoolStatus.False}`]: '未重新入校',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
