import Vue from 'vue'
import ClassBatchSubmitApproval from '../../../types/class-batch-submit-approval'
import { namespace as BindingNamespace } from 'vuex-class'
import omit from 'lodash.omit'
import { handlers } from 'xuexin-vuex/src/util'
import { Ajax } from 'xuexin-vuex/src/ajax'

export const namespace = '@xuexin-vuex/ClassBatchSubmitApproval'
export const types = {
  SUBMIT_REQUEST: 'SUBMIT_REQUEST',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAILURE: 'SUBMIT_FAILURE',
}

const state: ClassBatchSubmitApproval.State = {
  list: { data: {} },
}

const actions: ClassBatchSubmitApproval.Actions = {
  submit(context, payload) {
    return Ajax(context, {
      type: types.SUBMIT_REQUEST,
      payload: payload,
      api: `post ${__STUDENT_API__}/classes/batchsubmit`,
      parameter: payload,
      success: types.SUBMIT_SUCCESS,
      failure: types.SUBMIT_FAILURE,
    })
  },
}

const mutations: ClassBatchSubmitApproval.Mutations = {
  [types.SUBMIT_REQUEST](state) {
    handlers.request(state.list, 'fetching')
  },
  [types.SUBMIT_SUCCESS](state, { result }) {
    const item = state.list
    Vue.set(item, 'data', result)
    handlers.success(item, 'fetching')
  },
  [types.SUBMIT_FAILURE](state, { message }) {
    handlers.failure(state.list, 'fetching', message)
  },
}

const getters: ClassBatchSubmitApproval.Getters = {
  list() {
    return () => state.list?.data
  },
  listStatus() {
    return () => omit(state.list, ['data'])
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
