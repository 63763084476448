export const messages: Messages = {
  'zh-CN': {
    button: {
      export: '导出',
      delete: '删除',
      sms: '发送短信',
      edit: '编辑',
      'sms-record': '短信发送记录',
      'apply-admission': '申请短期入学',
      'inter-change-school': '校际互转',
      'template-download': '模板下载',
      'batch-import': '批量导入',
      'import-record': '导入记录',
      'divide-class': '分班',
      'edit-grade': '编辑就读年级',
      'trial-reading': '试读',
      'change-school': '转学',
    },
    content: {
      undo: '您确定要撤销直升吗？',
      tips: '请至少选择一名学生进行操作',
      noParameter: '请先选择搜索条件',
      noDataText: '暂无学生数据',
      noUnitID: '请至少选择到校区',
      title: '取消录取',
      msg: '您确定要取消录取该学生吗？',
      cancel: '取消',
      noText: '无法进行操作,请先搜索查询',
    },
    placeholder: {
      success: '操作成功',
    },
    text: {
      view: '查看',
      undo: '撤销',
      cancel: '取消录取',
      common: '正常',
      empty: '空挂学籍',
      score: '学校所在地',
      inscore: '非学校所在地',
      noscore: '无成绩',
    },
    index: '序号',
  },
  'en-US': {},
}

export default { messages }
