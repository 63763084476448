import { Constant } from 'xuexin-vuex'

export type PayType = Constant.PayType
export const PayType = Constant.PayType
export const PayTypeList = [PayType.Unpaid, PayType.Paid, PayType.Refunded]

export const messages: Messages = {
  'zh-CN': {
    [PayType.Unpaid]: '未缴费',
    [PayType.Part]: '部分缴费',
    [PayType.Paid]: '缴费完成',
    [PayType.Refunded]: '已退费',
    undefined: '-',
    null: '-',
    '': '-',
    '-1': ' ',
  },
  'en-US': {
    [PayType.Unpaid]: '未缴费',
    [PayType.Part]: '部分缴费',
    [PayType.Paid]: '缴费完成',
    [PayType.Refunded]: '已退费',
    undefined: '-',
    null: '-',
    '': '-',
    '-1': ' ',
  },
}

export default { messages }
