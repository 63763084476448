import Vue, { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Table, TableColumn } from 'view-design'
import './app-table.scss'

const components = { Table }
@Component({ name: 'AppTable', components, inheritAttrs: false })
export default class AppTable extends Vue {
  @Prop({ type: Array, default: () => [] }) private data!: any[]
  @Prop({ type: Boolean, default: true }) private readonly border!: boolean
  @Prop({ type: Array, default: () => [] })
  private readonly columns!: TableColumn[]

  private readonly prefixCls = 'app-table'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get props() {
    return Object.assign(
      {
        data: this.data,
        border: this.border,
        columns: this.columns,
      },
      this.$attrs
    )
  }

  render(createElement: CreateElement) {
    return createElement('div', { class: this.classes }, [
      createElement('Table', {
        props: this.props,
        on: this.$listeners,
        scopedSlots: this.$scopedSlots,
      }),
    ])
  }
}
