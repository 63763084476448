import { mergeLocaleMessage } from '@util'

export const messages: Messages = {
  'zh-CN': {
    title: {
      create: '添加班级',
      'batch-create': '批量添加班级',
      view: '查看班级',
      edit: '编辑班级',
    },
    text: {
      loading: '加载中...',
      null: '-',
      undefined: '-',
    },
    label: {
      term: '学期',
      unit: '校区',
      depart: '学部',
      phase: '学段',
      grade: '年级',
      schoolSystem: '学制',
      manageType: '报读类型',
      classType: '班级类型',
      unitClassType: '校本班型',
      classNum: '班级数量',
      glassName: '班级名称',
      startDate: '开始日期',
      endDate: '结束日期',
      headTeacher: '班主任',
      realityClassNum: '实际开班人数',
      limitCount: '班额',
      maxClassNum: '最大班额',
      classFileName: '附件',
      subjectType: '文理类型',
      repeatType: '复读班',
      creatorInfo: '添加人',
      createDate: '添加时间',
    },
    message: {
      noChoice: '没有选择',
      unit: '请选择校区',
      schoolDepart: '请选择学部',
      phase: '请选择学段',
      unitGrade: '请选择年级',
      abroad: '请选择出国方向',
      startTerm: '请选择起始学期',
      schoolSystem: '请选择学制',
      manageType: '请选择报读类型',
      unitClassType: '请选择校本班型',
      classNum: '请填写班级数量',
      realityClassNum: '请填写实际开班人数',
      realityClassNumSuccess: '实际开班人数需大于0小于等于班额',
      startDate: '请选择开始日期',
      limitCount: '请填写班额',
      maxClassNum: '请填写最大班额',
      subjectType: '请选择文理类型',
      repeatType: '请选择是否为复读班',
    },
    placeholder: {
      classNum: '请填写班级数量',
      date: '请选择开始日期',
      limitCount: '请填写班额',
      maxClassNum: '请填写最大班额',
      realityClassNum: '请填写实际开班人数',
    },
  },
  'en-US': {},
}

export default mergeLocaleMessage(messages)
