export default {
  messages: {
    'en-US': {
      'interschool-transfer-title': '校际互转学生',
      button: {
        export: '导出',
        sms: '发送短信',
        check: '查看',
        'transfer-in': '转入',
        'transfer-out': '转出',
        return: '退回',
        receive: '接收',
        reason: '原因',
        confirm: '待确认',
        returned: '已退回',
        received: '已接收',
        approval: '待审批',
        approvaling: '审批中',
        apprivaled: '审批通过',
        'approval-failed': '审批未通过',
        resubmit: '重新提交',
        revoke: '撤销',
        'sms-record': '短信发送记录',
      },
      text: {
        null: '-',
        undefined: '-',
        ordinary: '普通',
        liberalArts: '文科',
        science: '理科',
        index: '序号',
        1: '是',
        0: '否',
      },
      content: {
        undo: '确定要撤销吗？',
        tips: '请至少选择一名学生进行操作',
        noParameter: '请先选择搜索条件',
        noDataText: '暂无学生数据',
        confirmText: '您确定要退回该学生吗？',
        returnReason: '退回原因',
        reasonNoNull: '退回原因不能为空',
        ctrlSuccess: '操作成功',
      },
    },
    'zh-CN': {
      'interschool-transfer-title': '校际互转学生',
      button: {
        export: '导出',
        sms: '发送短信',
        check: '查看',
        'transfer-in': '转入',
        'transfer-out': '转出',
        return: '退回',
        receive: '接收',
        reason: '原因',
        confirm: '待确认',
        returned: '已退回',
        received: '已接收',
        approval: '待审批',
        approvaling: '审批中',
        apprivaled: '审批通过',
        'approval-failed': '审批未通过',
        resubmit: '重新提交',
        revoke: '撤销',
        'sms-record': '短信发送记录',
      },
      text: {
        null: '-',
        undefined: '-',
        ordinary: '普通',
        liberalArts: '文科',
        science: '理科',
        index: '序号',
        1: '是',
        0: '否',
      },
      content: {
        undo: '确定要撤销吗？',
        tips: '请至少选择一名学生进行操作',
        noParameter: '请先选择搜索条件',
        noDataText: '暂无学生数据',
        confirmText: '您确定要退回该学生吗？',
        returnReason: '退回原因',
        reasonNoNull: '退回原因不能为空',
        ctrlSuccess: '操作成功',
      },
    },
  },
}
