export const messages: Messages = {
  'zh-CN': {
    title: '申请短期入学',
    label: {
      name: '学生姓名',
      startDate: '开始日期',
      leaveDate: '离校日期',
    },
    loading: '加载中……',
    text: {
      start: '选择开始日期',
      leave: '选择结束日期',
      startDate: '请选择开始日期',
      leaveDate: '请选择离校日期',
      success: '申请成功',
      failure: '申请失败',
    },
  },
  'en-US': {
    title: '申请短期入学',
    label: {
      name: '学生姓名',
      startDate: '开始日期',
      leaveDate: '离校日期',
    },
    loading: '加载中……',
    text: {
      start: '选择开始日期',
      leave: '选择结束日期',
      startDate: '请选择开始日期',
      leaveDate: '请选择离校日期',
      success: '申请成功',
      failure: '申请失败',
    },
  },
}

export default { messages }
