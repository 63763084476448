
















































import Vue from 'vue'
import { Component, Ref, Watch } from 'vue-property-decorator'
import { Session } from 'xuexin-vuex'
import { Form, FormItem, Button, Input } from 'view-design'
import i18n from './i18n'
import { encryptToBase64 } from '../../../../util'

const components = { Form, FormItem, Input, Button }
@Component({ name: 'ResetStep', components, i18n })
export default class ResetStep extends Vue {
  @Session.Action('resetPassword')
  private readonly resetPassword!: Session.Action.ResetPassword
  @Session.Getter('status') private readonly status!: Session.Getter.Status
  @Ref('form') private readonly form!: Form
  @Ref('password') private readonly password!: Input
  @Ref('confirmPassword') private readonly confirmPassword!: Input

  private readonly prefixCls = 'reset-step'
  private model = { password: '', confirmPassword: '' }
  private rules = {
    password: [{ trigger: 'blur', validator: this.verifyPassword }],
    confirmPassword: [
      { trigger: 'blur', validator: this.verifyConfirmPassword },
    ],
  }

  get classes() {
    return {
      'reset-password-step': true,
      [this.prefixCls]: true,
    }
  }

  get resetting() {
    return this.status.resetting === true
  }

  @Watch('resetting')
  private watchResetting(resetting: boolean, previous: boolean) {
    if (resetting === false && previous === true) {
      if (this.status.resettingError !== null) {
        return this.$Message.error(this.status.resettingError)
      }
      return this.$emit('input', 3)
    }
  }

  mounted() {
    this.password.focus()
  }

  private verifyPassword(
    rule: FormRule,
    value: string,
    callback: FormRuleCallback
  ): void {
    if (value === void 0 || value === null || value.trim() === '') {
      return callback(`${this.$t('verification.passwordIsNull')}`)
    }

    if (value.length < 6 || value.length > 20) {
      return callback(`${this.$t('verification.tips')}`)
    }
    callback()
  }

  private verifyConfirmPassword(
    rule: FormRule,
    value: string,
    callback: FormRuleCallback
  ): void {
    if (value === void 0 || value === null || value.trim() === '') {
      return callback(`${this.$t('verification.confirmPasswordIsNull')}`)
    }

    if (value !== this.model.password) {
      return callback(`${this.$t('verification.differentPasswords')}`)
    }
    callback()
  }

  private handleNextStep() {
    this.form.validate((valid?: boolean) => {
      if (valid === false) {
        return void 0
      }

      const { uid = '', xuexinID = '' } = this.status
      this.resetPassword({
        uid,
        xuexinID,
        password: encryptToBase64(this.model.password, xuexinID),
      })
    })
  }

  private handleEnter(name: string) {
    switch (name) {
      case 'password':
        this.form.validateField('password', valid => {
          if (valid !== void 0 && String(valid).trim() !== '') {
            return this.$nextTick(() => this.password.focus())
          }

          this.$nextTick(() => this.confirmPassword.focus())
        })
        break
      case 'confirmPassword':
        this.form.validateField('confirmPassword', valid => {
          if (valid !== void 0 && String(valid).trim() !== '') {
            return this.$nextTick(() => this.confirmPassword.focus())
          }

          this.handleNextStep()
        })
        break

      default:
        break
    }
  }
}
