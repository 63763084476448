import { mergeLocaleMessage } from '@util'

export const messages: Messages = {
  'zh-CN': {
    message: {
      noDataText: '暂无数据',
      noParameter: '请先选择搜索条件',
      noTermID: '最少选择到学期',
      studentCount: '学生人数',
      studentStatis: '学生统计',
      school: '学校',
      schoolDepart: '学部',
      manageType: '报读类型',
    },
  },
  'en-US': {
    message: {
      noDataText: '暂无数据',
      noParameter: '请先选择搜索条件',
      noTermID: '最少选择到学期',
      studentCount: '学生人数',
      studentStatis: '学生统计',
      school: '学校',
      schoolDepart: '学部',
      manageType: '报读类型',
    },
  },
}

export default mergeLocaleMessage(messages)
