/**
 * 转学学生路由配置
 */

import { RouteConfig } from 'vue-router'

/** 子页面容器 */
import RouterViewLayout from '@layouts/router-view-layout'

/** 学生列表 */
import StudentListPage from '@pages/inside-transfer/student-list'

/** 学生详情 */
import StudentDetailPage from '@pages/student-detail'

/** 编辑学生信息/重新入校 */
import StudentPage from '@pages/student'

/** 短信发送记录 */
import SmsRecordPage from '@pages/sms-record'
import { Constant } from 'xuexin-vuex'

const children: RouteConfig[] = []

/** 学生列表 */
children.push({
  path: '/students/inside-transfer',
  name: 'inside-transfer',
  component: StudentListPage,
  meta: { ignore: true, authCode: '050607', parentAuthCode: '0506' },
})

/** 短信发送记录 */
children.push({
  path: 'inside-transfer-sms-record',
  name: 'inside-transfer-sms-record',
  component: SmsRecordPage,
  meta: { title: '短信发送记录', authCode: '050607', parentAuthCode: '0506' },
  props: {
    actionType: Constant.ActionType.Transfer,
  },
})

/** 学生详情 */
children.push({
  path: ':termID/:unitID/:xuexinID',
  name: 'inside-transfer-detail',
  component: StudentDetailPage,
  meta: { title: '学生详情', authCode: '050607', parentAuthCode: '0506' },
  props: { actionType: 'detail' },
})

export default {
  path: '/students/inside-transfer',
  name: 'inside-transfer',
  redirect: { name: 'inside-transfer' },
  meta: { title: '内转学生', authCode: '050607' },
  component: RouterViewLayout,
  props: { name: 'inside-transfer' },
  children,
}
