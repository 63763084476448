


























































































































































































































































import Vue, { CreateElement } from 'vue'
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'
import PagePropsMixins from '@mixins/page-props-mixins'
import { Constant, Student, Session } from 'xuexin-vuex'
import { Button } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import ExportModal from '@business-components/export-modal'
import SendMessageModal from '@business-components/send-message-modal'
import ImportRecordModal from '@business-components/record-modal'
import BatchImportModal from '@business-components/multiple-export-modal'
import UpgradeModal from '../upgrade-modal'
import {
  StudentStatusTag,
  StudentStatusModal,
} from '@business-components/student-status' /** 学生状态 */
import { StudentFlowStatusBadge } from '@business-components/student-flow-status' /** 学生流程状态 */
import GradeModal from '@business-components/grade-modal'
import ClassModal from '@business-components/glass-modal'
import { GraduateDirectionModal } from '@business-components/graduate-direction'
import i18n from './i18n'
import PageTyping from '../../../../types/page'
import { convertSearchParameter } from '@util'
import { getParameter, setParameter } from '@util/parameter'
import NumFormate from '@util/num-format'

type Flat = Record<string, Student.Entity>
const ActionType = Constant.ActionType
const StudentStatus = Constant.StudentStatus

const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  AppTable,
  AppPagination,
  ExportModal,
  SendMessageModal,
  ImportRecordModal,
  BatchImportModal,
  UpgradeModal,
  GradeModal,
  ClassModal,
  StudentStatusTag,
  StudentStatusModal,
  StudentFlowStatusBadge,
  GraduateDirectionModal,
}
@Component({ name: 'GraduationStudentListPage', components, i18n })
export default class GraduationStudentListPage extends Mixins(
  PagePropsMixins
) {
  @Student.Action('fetchList')
  private readonly fetchStudents!: Student.Action.FetchList
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Student.Getter('list') private readonly students!: Student.Getter.List
  @Student.Getter('item') private readonly getStudent!: Student.Getter.Item

  @Student.Mutation('CLEAN_LIST')
  private readonly cleanList!: Student.Mutation.CleanList

  @Session.Getter('user') private readonly user!: Session.Getter.User

  private prefixCls = 'graduation-student-list-page'
  private flat: Flat = {}
  private modal: { name: string | null; data: any } = { name: null, data: {} }
  private selection: Student.Entity[] = []
  private searchParameter: Partial<PageTyping.SearchParameter> = {}
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private page = 1
  private pageSize = 10

  get authCode() {
    return this.$route.meta.authCode
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get valid() {
    // const { schoolDepartID } = this.parameter
    // return (
    //   Boolean(schoolDepartID) ||
    //   schoolDepartID === 0 ||
    //   Boolean(this.searchParameter.schoolDepartID) ||
    //   this.searchParameter.schoolDepartID === 0
    // )
    return true
  }

  get noDataText() {
    // if (
    //   Boolean(this.parameter.schoolDepartID) === false ||
    //   Boolean(this.searchParameter.schoolDepartID) === false
    // ) {
    //   return this.$t('warning.non-search')
    // }

    return this.$t('text.no-data-text')
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get total() {
    return this.listStatus.total || 0
  }

  get tableColumns() {
    if (
      this.valid !== true || // 参数不合法
      this.tableData.length === 0 // 无查询结果
    ) {
      /** 以上条件成立时，直接返回空列头。 */
      return []
    }

    const columns = this.columns.map(item => {
      switch (item.key) {
        case 'studentStatus' /** 学生状态 */:
          return Object.assign({}, item, { slot: 'studentStatus' })
        case 'aliasClassName' /** 学生状态 */:
          return Object.assign({}, item, {
            width: 200,
            slot: 'aliasClassName',
            render: void 0,
          })
        case 'parentMobile' /** 家长电话 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.smsPhone ? NumFormate(`${row.smsPhone}`) : '-'
              return createElement!('span', {}, [text])
            },
          })
        case 'studentPhone' /** 学生电话 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.studentPhone
                ? NumFormate(`${row.studentPhone}`)
                : '-'
              return createElement!('span', {}, [text])
            },
          })
        case 'IDNO' /** 证件号码 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.IDNO ? NumFormate(`${row.IDNO}`) : '-'
              return createElement!('span', {}, [text])
            },
          })
      }
      return item
    })

    /** 添加复选框 */
    columns.unshift({
      type: 'selection',
      width: 60,
      fixed: 'left',
      align: 'center',
    })

    /** 添加操作列 */
    columns.push({
      title: `${this.$t('column.operate')}`,
      slot: 'operate',
      width: 140,
      fixed: 'right',
      align: 'center',
    })
    return columns
  }

  get tableData() {
    if (this.valid !== true) {
      return []
    }
    const list = this.students.map((item, index) => {
      const student = Object.assign({}, item)
      /** 还原当前选中的学生 */
      if (this.flat[item.studentID] !== void 0) {
        student._checked = true
      }
      student.num = (this.page - 1) * this.pageSize + index + 1
      return student
    })
    return list
  }

  get disabled() {
    return this.tableData.length === 0
  }

  get showUpgrade() {
    return (
      this.user !== null &&
      this.user !== void 0 &&
      this.user.extraMap.currentTermID === this.parameter.termID
    )
  }
  @Watch('searchParameter.unitID')
  private watchSchoolDepartID(value: number, oldValue: unknown) {
    // 有条件缓存时，不走默认查询
    const para = getParameter(`${this.$route.meta.authCode}`)
    if (para) return

    // 无条件缓存时，默认查询一次
    if (oldValue === null || oldValue === undefined) {
      this.parameter = { ...this.searchParameter }
      this.fetch()
    }
  }
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 查询学生列表请求完成 */
    if (fetching === false && previous === true) {
      /** 提示错误信息 */
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }

  @Watch('selection')
  private watchSelection(selection: Student.Entity[]) {
    // prettier-ignore
    this.$set(this.$data, 'flat', selection.reduce((flat: Flat, item) => {
      return flat[item.studentID] = item, flat
    }, {}))
  }

  created() {
    /** 清理其它页面的缓存数据 */
    this.cleanList()
    // this.fetch()

    // 若有查询条件缓存，则回显查询条件
    const para = getParameter(`${this.$route.meta.authCode}`)
    if (para) {
      let parameters = {}
      let birthday: any = para.searches.birthday

      if (birthday[0] === '' || !Boolean(birthday)) {
        birthday = ['', '']
      } else {
        birthday = [new Date(birthday[0]), new Date(birthday[1])]
      }

      parameters = Object.assign({}, para.searches, { birthday: birthday })
      this.$set(this.$data, 'parameter', parameters)
      this.$set(this.$data, 'searchParameter', parameters)
      this.page = para.page
      this.pageSize = para.pageSize
      this.fetch()
    }
  }

  mounted() {}

  destroyed() {}

  /** 查询数据 */
  private fetch() {
    if (
      this.valid !== true /** 参数无效 */ ||
      this.fetching === true /** 正在请求数据 */
    ) {
      /** 以上情况不发起请求 */
      return void 0
    }

    this.fetchStudents({
      actionType: ActionType.Graduation,
      authCode: this.authCode,
      searches: this.convertParameter(),
      page: this.page,
      pageSize: this.pageSize,
    })

    // 缓存查询条件
    setParameter(`${this.$route.meta.authCode}`, {
      authCode: this.authCode,
      searches: this.parameter,
      page: this.page,
      pageSize: this.pageSize,
    })
  }

  private convertParameter() {
    return convertSearchParameter(this.parameter)
  }

  /** 页面按钮单击操作 */
  private handleClick(action: string, student: Student.Entity) {
    switch (action) {
      case 'sms-record' /** 短信发送记录 */:
        {
          this.$router.push({
            name: 'graduation-sms-record',
            query: this.convertParameter(),
          })
        }
        break
      case 'export' /** 导出 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'export-modal',
            data: {
              list: this.selection.map(item => ({ id: item.studentID })),
              authCode: this.authCode,
              actionType: ActionType.Graduation,
              type: Constant.PageType.Graduation,
              searches: this.convertParameter(),
            },
          })
        }
        break
      case 'sms' /** 发短信 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'sms-modal',
            data: {
              list: this.selection.map(item => ({
                id: item.studentID,
                name: item.studentName,
                mobile: item.parentMobile,
              })),
              searches: this.convertParameter(),
              authCode: this.authCode,
              actionType: ActionType.Graduation,
              pageType: Constant.PageType.Graduation,
            },
          })
        }
        break
      case 'import-record' /** 导入记录 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'import-record-modal',
            data: {
              termID: this.parameter.termID,
              unitID: this.parameter.unitID,
              type: Constant.PageType.Graduation,
            },
          })
        }
        break
      case 'batch-import' /** 批量导入 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'batch-import-modal',
            data: {
              termID: this.parameter.termID,
              unitID: this.parameter.unitID,
              pageType: Constant.PageType.Graduation,
              importType: Constant.ImportType.GraduateStudent,
            },
          })
        }
        break
      case 'edit-graduation' /** 编辑毕业去向 */:
        {
          if (student !== void 0 || this.selection.length === 1) {
            /** 编辑单个学生的毕业去向 */
            const _student = student || this.selection[0]
            const xuexinID = `${_student.studentID || _student.xuexinID}`
            const item = this.getStudent(xuexinID)
            // prettier-ignore
            if (item === void 0) { return void 0 }
            // prettier-ignore
            const { termID, phaseID, classID, graduateDirection: dataID, } = item
            return this.$set(this.$data, 'modal', {
              name: 'graduate-direction-modal',
              data: { termID, xuexinID, phaseID, classID, dataID },
            })
          }
          // 批量编辑毕业方向
          const list =
            this.selection.length !== 0 ? this.selection : this.tableData
          const [{ phaseID, classID }, ...students] = list

          /** 非高中学段 */
          if (phaseID !== 4) {
            // prettier-ignore
            return this.$Message.warning(`${this.$t('warning.non-high-school')}`)
          }

          /** 查询非同班同学 */
          const item = students.find(item => {
            return item.phaseID !== phaseID || item.classID !== classID
          })

          /** 非同班级学生存在 */
          if (item !== void 0) {
            // prettier-ignore
            return this.$Message.warning(`${this.$t('warning.non-classmates')}`)
          }

          /** 获取列表学生id */
          const ids = list.map(item => item.studentID || item.xuexinID)
          this.$router.push({
            name: 'batch-edit-direction',
            params: { termID: `${this.parameter.termID}` },
            query: { ids: ids.join(',') },
          })
        }
        break
      case 'upgrade': // 直升
        {
          const list =
            this.selection.length === 0 ? this.tableData : this.selection

          if (list.length === 0) {
            return this.$Message.warning(`${this.$t('warning.no-student')}`)
          }

          const [
            {
              classID,
              unitGradeID,
              termID,
              parentUnitID,
              graduateDirectStatus,
              unitID,
            },
            ...students
          ] = list
          const item = students.find(
            item =>
              item.unitGradeID !== unitGradeID ||
              item.graduateDirectStatus === 1
          )

          switch (true) {
            case graduateDirectStatus === 1:
            case item !== void 0 && item.graduateDirectStatus === 1:
              return this.$Message.warning(
                `${this.$t('warning.repeat-upgrade')}`
              )
            case item !== void 0:
              return this.$Message.warning(
                `${this.$t('warning.class-inconsistent')}`
              )
          }

          const xuexinIDs = list.map(item => item.studentID || item.xuexinID)
          this.$set(this.$data, 'modal', {
            name: 'upgrade-modal',
            data: {
              termID,
              unitID: parentUnitID,
              unitGradeID,
              classID,
              xuexinIDs,
              originalUnitID: unitID,
            },
          })
        }
        break
      case 'view-grade' /** 查年年级信息 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'grade-modal',
            data: {
              viewType: 'view',
              unitGradeID: student.unitGradeID,
              parameter: { termID: student.termID },
            },
          })
        }
        break
      case 'view-class' /** 查看班级信息 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'class-modal',
            data: {
              viewType: 'view',
              classID: student.classID,
              parameter: { termID: student.termID },
            },
          })
        }
        break
      case 'view-student' /** 查看学生详情 */:
        {
          this.$router.push({
            name: 'graduation-student-detail',
            params: {
              termID: `${student.termID}`,
              unitID: `${student.unitID}`,
              xuexinID: student.studentID,
              parentUnitID: `${student.parentUnitID || ''}`,
            },
          })
        }
        break
      case 'view-status' /** 查看学生状态 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'student-status-modal',
            data: {
              termID: student.termID,
              xuexinID: student.studentID,
              status: student.studentStatus,
            },
          })
        }
        break
      default:
        console.log(`${this.$options.name}.handleClick(${action})`)
        break
    }
  }

  /** 已选学生发生变化时的处理函数 */
  private handleSelectionChange(selection: Student.Entity[]) {
    this.$set(this.$data, 'selection', selection)
  }

  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    // if (Boolean(parameter.schoolDepartID) === false) {
    //   // prettier-ignore
    //   return this.$Message.warning(`${this.$t('warning.no-school-depart-id')}`)
    // }

    /** 重置页码信息 */
    this.$set(this.$data, 'page', 1)

    /** 同步本地 parameter 对象 */
    this.$set(this.$data, 'parameter', parameter)

    /** 查询数据 */
    this.fetch()
  }

  /** 页码发生变化 */
  private handlePageChange(page: number) {
    /**
     * 修改本地 `page` 属性值，清空已选数据，并重新请求数据。
     */
    this.$set(this.$data, 'page', page)
    this.$set(this.$data, 'selection', [])
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number) {
    /**
     * 修改本地 `pageSize` 属性值，若当前 `page` 为 1，则手动请求数据。
     */
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }

  /** 窗口功能完成事件处理函数 */
  private handleModalFinish(modal: string) {
    /** 清空窗口状态 */
    this.$set(this.$data, 'modal', { name: null, data: {} })

    switch (modal) {
      case 'upgrade-modal' /** 直升成功后，刷新列表 */:
        this.fetch()
        break
      case 'sms-modal' /** 发送短信窗口 */:
        // 以上窗口不提示(窗口内部已处理)
        break
      default:
        this.$Message.success(`${this.$t('success')}`)
        break
    }
  }

  /** 窗口关闭后处理函数 */
  private handleModalHidden(modal: string) {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }
}
