export enum Boolean {
  True = 1,
  False = 0,
}

export const BooleanList = [Boolean.True, Boolean.False]

export const messages: Messages = {
  'zh-CN': {
    [`${Boolean.True}`]: '是',
    [`${Boolean.False}`]: '否',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${Boolean.True}`]: '是',
    [`${Boolean.False}`]: '否',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
