import Vue, { CreateElement, VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Constant } from 'xuexin-vuex'
import { Input, DatePicker } from 'view-design'
import { SemesterSelect } from '@business-components/semester'
import { SchoolSelect } from '@business-components/school'
import { SchoolDepartSelect } from '@business-components/school-depart'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { GradeSelect } from '@business-components/grade'
import { GlassSelect } from '@business-components/glass'
import { BusinessTypeSelect } from '@business-components/business-type'
import { ClassTypeSelect } from '@business-components/class-type'
import { SchoolClassTypeSelect } from '@business-components/school-class-type'
import { StudentSourceSelect } from '@business-components/student-source'
import { EnrollScoreSelect } from '@business-components/enroll-score'
import { StatusTypeSelect } from '@business-components/status-type'
import { StudentMarkSelect } from '@business-components/student-mark'
import { ResearchFieldSelect } from '@business-components/research-field'
import { HouseholdIncomeSelect } from '@business-components/household-income'
import { ResidenceTypeSelect } from '@business-components/residence-type'
import { GenderSelect } from '@business-components/gender'
import { StudentStatusSelect } from '@business-components/student-status'
import { EnrollTypeSelect } from '@business-components/enroll-type'
import { SchoolSystemSelect } from '@business-components/school-system'
import { AbroadSelect } from '@business-components/abroad'
import { SubjectTypeSelect } from '@business-components/subject-type'
import { RegistStatusSelect } from '@business-components/regist-status'
import { DirectionSelect } from '@business-components/direction'
import { DivideClassTypeSelect } from '@business-components/divide-class-type'
import { PayTypeSelect } from '@business-components/pay-type'
import { TransferReasonSelect } from '@business-components/transfer-reason'
import { GraduateDirectionSelect } from '@business-components/graduate-direction'
import { ApprovalStatusSelect } from '@business-components/approval-status'
import { ConfirmSelect } from '@business-components/confirm-status'
import { TransferStatusSelect } from '@business-components/transfer-status'
import { TrialReadResultSelect } from '@business-components/trial-read-result'
import { FeedbackStatusSelect } from '@business-components/trial-read-status'
import { NewOldTypeSelect } from '@business-components/new-old-type'
import { DivideStatusSelect } from '@business-components/divide-status'
import { SchoolDepartStatusSelect } from '@business-components/school-depart-status'
import { ReSchoolStatusSelect } from '@business-components/reSchool-status'
import { EthnicitySelect } from '@components/ethnicity'
import { NationalitySelect } from '@components/nationality'
import { AppBooleanSelect } from '@components/app-boolean'
import { StudentTypeSelect } from '@business-components/student-type'
import { DateTypeSelect } from '@business-components/date-type'
import { AcademicDirectionSelect } from '@business-components/academic-direction'
import { StudentCategorySelect } from '@business-components/student-category'
import { FreshStudentSelect } from '@business-components/fresh-student'
import { CollegeDirectionSelect } from '@business-components/college-direction'
import { TransientStatusTypeSelect } from '@business-components/transient-status-type'
import { StayStatusTypeSelect } from '@business-components/stay-status-type'

import PageTyping from '../../../types/page'
import i18n from './i18n'
import { OperateTypeSelect } from '@business-components/operate-type'
import { OperateClassSelect } from '@business-components/operate-class'

@Component({ name: 'SearchColumn', i18n })
export default class SearchColumn extends Vue {
  @Prop({ type: String, required: true }) private readonly label!: string
  @Prop({ type: Object, required: true })
  private readonly data!: PageTyping.Search
  @Prop({ type: Object, required: true })
  private readonly parameter!: PageTyping.SearchParameter
  @Prop({}) private readonly value!: any
  @Prop({ type: Boolean, default: true }) private readonly multiple!: boolean
  @Prop({ type: Boolean, default: false }) private readonly required!: boolean

  private readonly prefixCls = 'search-item'
  private visible = true

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get style() {
    const visible = this.visible === true && this.data.show === 1
    return {
      display: visible ? 'inline-block' : 'none',
    }
  }

  created() {
    switch (this.data.code) {
      case 'unitID': // 校区
      case 'schoolDepartID': // 学段
        // 以上条件，默认不显示
        this.$set(this.$data, 'visible', true)
        break
      case 'doubleDate':
        this.$set(this.$data, 'visible', false)
        break
      default:
        this.$set(this.$data, 'visible', true)
        break
    }
  }

  render(createElement: CreateElement) {
    const children: VNodeChildren = []
    const props: Record<string, any> = {}
    const attrs: Record<string, any> = {}
    const style: Record<string, any> = {}
    const on: Record<string, any> = {}
    const { type, code } = this.data
    let tag: VueConstructor | null = null

    // if (['termID', 'parentUnitID', 'unitID'].indexOf(`${code}`) === -1) {
    //   return null
    // }

    switch (`${type}`) {
      case Constant.ColumnType.Text /** 文本: 0 */:
        props.value = this.value
        attrs.placeholder = this.label
        on.input = this.handleInput
        tag = Input
        break

      case Constant.ColumnType.Select /** 下拉框: 1 */:
        attrs.multiple = this.multiple
        attrs.clearable = this.required !== true
        attrs.value = this.value
        on['on-change'] = this.handleChange
        on['on-finish'] = this.handleFinish
        style.width = '200px'
        attrs.placeholder = this.label
        switch (code) {
          case 'termID' /** 学期 */:
            attrs.required = this.required
            tag = SemesterSelect
            break
          case 'parentUnitID': /** 学校 */
          case 'inUnitID' /** 转至校区 */:
            props.organID = this.parameter.organID
            props.termID = this.parameter.termID
            attrs.clearable = code === 'inUnitID' ? true : false
            attrs.required = this.required
            tag = SchoolSelect
            break
          case 'unitID' /** 校区 */:
            props.organID = this.parameter.organID
            props.unitID = this.parameter.parentUnitID
            props.capture = true
            attrs.required = this.required
            tag = SchoolSelect
            break
          case 'schoolDepartID' /** 学部 */:
            props.unitID = this.parameter.unitID
            props.termID = this.parameter.termID
            attrs.required = this.required
            style.width = '120px'
            tag = SchoolDepartSelect
            break
          case 'phaseID' /** 学段 */:
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            style.width = '120px'
            tag = SchoolPhaseSelect
            break
          case 'unitGradeID': /** 学校年级 */
          case 'gradeID' /** 年级 */:
            props.termID = this.parameter.termID
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            props.phaseID = this.parameter.phaseID
            props.base = code === 'gradeID' ? true : false
            style.width = code === 'gradeID' ? '100px' : '160px'
            tag = GradeSelect
            break
          case 'classID' /** 班级 */:
            props.termID = this.parameter.termID
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            props.phaseID = this.parameter.phaseID
            props.unitGradeID = this.parameter.unitGradeID
            props.manageTypeID = this.parameter.manageTypeID
            props.unitClassTypeID = this.parameter.unitClassTypeID
            tag = GlassSelect
            break
          case 'manageTypeID' /** 报读类型 */:
            props.unitID = this.parameter.unitID
            props.phaseID = this.parameter.phaseID
            style.width = '120px'
            tag = BusinessTypeSelect
            break
          case 'classTypeID' /** 班级类型 */:
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            props.phaseID = this.parameter.phaseID
            props.manageTypeID = this.parameter.manageTypeID
            props.schoolSystemID = this.parameter.schoolSystemID
            style.width = '120px'
            tag = ClassTypeSelect
            break
          case 'unitClassTypeID' /** 校本班型 */:
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            props.phaseID = this.parameter.phaseID
            props.manageTypeID = this.parameter.manageTypeID
            props.schoolSystemID = this.parameter.schoolSystemID
            props.classTypeID = this.parameter.classTypeID
            style.width = '120px'
            tag = SchoolClassTypeSelect
            break
          case 'schoolSystemID' /** 学制 */:
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            props.phaseID = this.parameter.phaseID
            props.manageTypeID = this.parameter.manageTypeID
            props.classTypeID = this.parameter.classTypeID
            style.width = '80px'
            tag = SchoolSystemSelect
            break
          case 'academicDirection' /**学业方向/ */:
            props.phaseID = this.parameter.phaseID
            props.freshStudent = this.parameter.freshStudent
            style.width = '120px'
            attrs.clearable = true
            tag = AcademicDirectionSelect
            break
          case 'studentCategory' /**学生类别 */:
            props.phaseID = this.parameter.phaseID
            props.freshStudent = this.parameter.freshStudent
            style.width = '120px'
            attrs.clearable = true
            tag = StudentCategorySelect
            break
          case 'tranferStatus' /** 互转状态 */:
            style.width = '120px'
            tag = TransferStatusSelect
            break
          case 'approveStatus' /** 审批状态 */:
            style.width = '120px'
            tag = ApprovalStatusSelect
            break
          case 'tranferReason' /** 转学原因 */:
            style.width = '120px'
            tag = TransferReasonSelect
            break
          case 'graduateDirection' /** 毕业去向 */:
            style.width = '120px'
            tag = GraduateDirectionSelect
            break
          case 'payType' /** 缴费状态 */:
            tag = PayTypeSelect
            style.width = '120px'
            break
          case 'divideType' /** 分班类型 */:
            style.width = '120px'
            tag = DivideClassTypeSelect
            break
          case 'direction' /** 报名方向 */:
            style.width = '120px'
            tag = DirectionSelect
            break
          case 'registStatus' /** 注册状态 */:
            style.width = '120px'
            tag = RegistStatusSelect
            break
          case 'subjectType' /** 文理类型 */:
            style.width = '120px'
            tag = SubjectTypeSelect
            break
          case 'startTerm': /** 开始学期 */
          case 'endTerm' /** 结束学期 */:
            props.unitID = this.parameter.parentUnitID
            /** 结束学期时，type 传 2,查询截止学期 */
            props.type = code === 'endTerm' ? 2 : void 0
            tag = SemesterSelect
            break

          case 'abroadID' /** 出国方向 */:
            style.width = '120px'
            tag = AbroadSelect
            break
          case 'tranferConfirmStatus' /** 确认状态 */:
            style.width = '120px'
            tag = ConfirmSelect
            break
          case 'sourceChannel' /** 学生来源 */:
            style.width = '120px'
            tag = StudentSourceSelect
            break
          case 'enrollScore' /** 入学成绩 */:
            style.width = '120px'
            tag = EnrollScoreSelect
            break
          case 'enrollType' /** 入学分类 */:
            style.width = '120px'
            tag = EnrollTypeSelect
            break
          case 'freshStudent':
            style.width = '120px'
            tag = FreshStudentSelect
            break
          case 'collegeDirection' /**高考方向 */:
            style.width = '120px'
            tag = CollegeDirectionSelect
            break
          case 'transientStatusType' /**借读生转籍状态 */:
            style.width = '140px'
            tag = TransientStatusTypeSelect
            break
          case 'stayStatus' /** 住宿状态 */:
            style.width = '140px'
            tag = StayStatusTypeSelect
            break
          case 'graduateDirectStatus': /** 是否直升 */
          case 'repeatType': /** 复读班 */
          case 'ownerChild': /** 业主子女 */
          case 'staffChild': /** 本校教工子女 */
          case 'transientStudent': /** 借读生 */
          case 'singleParent': /** 单亲家庭 */
          case 'onlyChild' /** 独生子女 */:
            style.width = '120px'
            tag = AppBooleanSelect
            break
          case 'statusType' /** 学籍类型 */:
            style.width = '120px'
            props.useSearch = true
            tag = StatusTypeSelect
            break
          case 'studentMark' /** 学生标记 */:
            style.width = '120px'
            tag = StudentMarkSelect
            break
          case 'researchField' /** 学业方向 */:
            style.width = '120px'
            tag = ResearchFieldSelect
            break
          case 'householdIncome' /** 家庭年收入 */:
            style.width = '120px'
            tag = HouseholdIncomeSelect
            break
          case 'residenceType' /** 户口类别 */:
            style.width = '120px'
            tag = ResidenceTypeSelect
            break
          case 'ethnicity' /** 民族 */:
            style.width = '120px'
            tag = EthnicitySelect
            break
          case 'nation' /** 国籍 */:
            style.width = '120px'
            tag = NationalitySelect
            break
          case 'gender' /** 性别 */:
            style.width = '80px'
            tag = GenderSelect
            break
          case 'studentStatus' /** 学生状态 */:
            style.width = '120px'
            tag = StudentStatusSelect
            break
          case 'feedbackStatus' /** 试读状态 */:
            style.width = '120px'
            tag = FeedbackStatusSelect
            break
          case 'feedbackResult' /** 试读结果 */:
            props.unitID = this.parameter.unitID
            props.termID = this.parameter.termID
            style.width = '120px'
            tag = TrialReadResultSelect
            break

          case 'operateGroup' /**操作类别 */:
            style.width = '120px'
            tag = OperateClassSelect
            break
          case 'operateType' /**操作类型 */:
            style.width = '120px'
            props.operateGroup = this.parameter.operateGroup
            tag = OperateTypeSelect
            break
          case 'studentTypeSelect' /**学生类型 */:
            style.width = '120px'
            tag = StudentTypeSelect
            break
          case 'dateType' /**日期类型 */:
            style.width = '120px'
            attrs.clearable = false
            tag = DateTypeSelect
            break
          case 'newOldType' /** 新老生类型 */:
            style.width = '120px'
            tag = NewOldTypeSelect
            break
          case 'divideStatus' /** 分班状态 */:
            style.width = '120px'
            tag = DivideStatusSelect
            break
          case 'schoolDepartStatus':
            style.width = '120px'
            tag = SchoolDepartStatusSelect
            break
          case 'reSchoolStatus' /** 重新入校状态 */:
            style.width = '120px'
            tag = ReSchoolStatusSelect
            break
          default:
            console.log(
              `未处理下拉框(code: ${code}, type: ${type}), label: ${this.label}`
            )
            break
        }
        break

      case Constant.ColumnType.Date /** 日期: 4 */:
      case Constant.ColumnType.Interval /** 区间日期: 7 */:
        attrs['split-panels'] = true
        attrs.placeholder = this.label
        props.value = this.value
        props.type =
          `${type}` === Constant.ColumnType.Interval ? 'daterange' : 'date'
        style.width =
          `${type}` === Constant.ColumnType.Interval ? '220px' : void 0

        style.display = on.input = this.handleInput

        switch (code) {
          case 'simpleDate':
          case 'doubleDate':
            attrs.clearable = false
            break
          default:
            break
        }

        tag = DatePicker
        break

      default:
        console.log(
          `未处理搜索条件(code: ${code}, type: ${type}, label: ${this.label})`
        )
        break
    }

    if (tag === null) {
      return null
    }

    /** 添加节点组件 */
    children.push(createElement(tag, { style, props, attrs, on }))

    /** label 元素*/
    // const LabelElement = createElement(
    //   'div',
    //   {
    //     class: `${this.prefixCls}_label`,
    //   },
    //   [this.label]
    // )

    /**  content 元素 */
    const ContentElement = createElement(
      'div',
      {
        class: `${this.prefixCls}_content`,
      },
      children
    )

    return createElement(
      'div',
      {
        class: this.classes,
        style: this.style,
      },
      [ContentElement]
      // [LabelElement,ContentElement]
    )
  }

  @Watch('parameter.dateType', { immediate: true })
  watchDateType(dateType: number, previous: number) {
    if (dateType === 2) {
      if (this.data.code === 'doubleDate') {
        this.$set(this.$data, 'visible', true)
      }
      if (this.data.code === 'simpleDate') {
        this.$set(this.$data, 'visible', false)
      }
    } else {
      if (this.data.code === 'simpleDate') {
        this.$set(this.$data, 'visible', true)
      }
      if (this.data.code === 'doubleDate') {
        this.$set(this.$data, 'visible', false)
      }
    }
  }

  mounted() {}

  private handleFinish(list: any[]) {
    switch (this.data.code) {
      case 'unitID': // 校区
      case 'schoolDepartID': // 学部
        /**
         * 以上搜索条件，数据长度不为 1 时，不显示
         * (数据长度为 0 时，需要显示出来，以便告知用户)。
         */
        // console.log(list)
        if (list.length === 1) {
          this.$emit('on-change', this.data.code, list[0][this.data.code])
        }
        this.$set(this.$data, 'visible', list.length !== 1)
        break
      case 'operateType':
        this.parameter.operateTypeList = list
        break
      default:
        break
    }
  }

  private handleInput(value: any) {
    this.$emit('on-change', this.data.code, value)
  }

  private handleChange(value: any) {
    this.$emit('on-change', this.data.code, value)
  }
}
