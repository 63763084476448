












































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Session, Constant } from 'xuexin-vuex'
import { Spin, Button } from 'view-design'
import PermissionsMenu from '@components/permissions-menu'
import PageHeader from '@functional-components/page-header'
import PageBody from '@functional-components/page-body'
import i18n from './i18n'
import { encryptToBase64 } from '../../util'
import Dictionary from '@store/modules/dictionary'
import DictionaryType from 'types/dictionary'

const components = { PageHeader, PageBody, PermissionsMenu, Spin, Button }
@Component({ name: 'AppLayout', components, i18n })
export default class AppLayout extends Vue {
  @Session.Action('fetch') private readonly fetchUser!: Session.Action.Fetch
  @Session.Getter('auths') private readonly auths!: Session.Getter.Auths
  @Session.Getter('user') private readonly user!: Session.Getter.User
  @Session.Getter('status') private readonly status!: Session.Getter.Status
  @Dictionary.Action('fetchList')
  private readonly fetchStuTag!: DictionaryType.Action.FetchList

  private readonly prefixCls = 'app-layout'
  private initial = true

  get activeName(): string {
    return this.$route.meta.authCode || ''
  }

  get openNames(): string[] {
    return [this.$route.meta.parentAuthCode || '']
  }

  get parameter() {
    const parameter: any = {
      platform: Constant.Platform.Education,
    }

    if (this.user === void 0 || this.user === null) {
      return parameter
    }

    if (this.identityType === Constant.IdentityType.School) {
      parameter.unitID = this.user.parentUnitID
    } else {
      parameter.orginID = this.user.organID
    }

    return parameter
  }

  /** 认证状态 */
  get unauthorized() {
    return this.status.unauthorized === true
  }

  /** 是否禁止访问 */
  get forbidden() {
    return this.status.forbidden === true
  }

  /** 当前身份信息 */
  get identityType() {
    return this.user?.identityType
  }

  get updating() {
    return this.status.updating === true
  }

  get fetching() {
    return this.status.fetching === true || this.status.updating !== false
  }

  get message() {
    return this.status.updatingError || this.status.fetchingError || null
  }

  @Watch('unauthorized')
  watchUnauthorized(unauthorized: boolean, previous: boolean) {
    if (unauthorized === true && previous === false) {
      // console.log(`${this.$options.name}.watchUnauthorized()`)
      // console.log(`unauthorized: ${unauthorized}, previous: ${previous}`)
      /** 主动退出登录时，不提示错误消息 */
      if (this.status.isInitiative !== true) {
        this.$Message.error(`${this.$t('unauthorized')}`)
      }
      // this.$router.push({
      //   name: 'login',
      //   query: {
      //     link: encryptToBase64(this.$route.path, 'link-url'),
      //   },
      // })
    }
  }

  @Watch('forbidden')
  watchForbidden(forbidden: boolean, previous: boolean) {
    /** 请求被拒绝，禁止访问 */
    if (forbidden === true && previous === false) {
      return this.$Message.error(`${this.$t('forbidden')}`)
    }
  }

  @Watch('updating')
  watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      // console.log(`${this.$options.name}.watchUpdating(更新用户身份完成)`)
      if (this.unauthorized === true) {
        // 登录失效，不处理任何操作。
        return void 0
      }
      if (this.status.updatingError !== null) {
        return this.$Message.error(this.status.updatingError)
      }

      this.fetchUser(this.parameter)
      /** 添加以下判断之后，会与后台默认数据不同步，所以不判断多请求一次。 */
      // if (this.initial === true) {
      //   this.$set(this.$data, 'initial', false)
      // } else {
      //   this.fetchUser(this.parameter)
      // }
    }
  }

  // @Watch('fetching')
  // watchFetching(fetching: boolean, previous: boolean) {
  //   if (fetching === false && previous === true && this.message === null) {
  //     console.log('切换身份后，统一跳转到首页')
  //   }
  // }

  mounted() {
    // 页面刷新时，清除查询条件缓存
    window.onunload = function() {
      sessionStorage.removeItem('parameter')
    }

    //查询学生字典数据
    this.fetchStuTag({
      catalogID: '107,114,115,116,117',
      phaseID: 4,
      freshStudent: 1,
    })

    //如果用户没有首页权限，则路由名字为'dashboard'时，将跳转到第一个菜单
    if (
      this.auths[0].authCode !== '0501' &&
      this.$route.name == 'dashboard'
    ) {
      return this.$router
        .push({ name: this.auths[0].routeName })
        .catch(err => {})
    }
  }

  private handleRetry() {
    this.fetchUser(this.parameter)
  }
}
