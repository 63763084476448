import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Tooltip } from 'view-design'
import i18n from '../i18n'

@Component({ name: 'ApprovalStatusTag', i18n })
export default class ApprovalStatusTag extends Vue {
  @Prop() private readonly value!: unknown
  @Prop() private readonly organ!: unknown

  private readonly prefixCls = 'approval-status'

  get classes() {
    return {
      [`${this.prefixCls}-tag`]: true,
    }
  }

  get hasTag() {
    if (
      this.organ === null ||
      this.organ === void 0 ||
      (this.organ as number) < 0
    ) {
      return false
    }
    return true
  }

  get hasWorkflow() {
    if (
      this.value === null ||
      this.value === void 0 ||
      (this.value as number) < 0
    ) {
      return false
    }
    return true
  }

  render(h: CreateElement) {
    const children: VNodeChildren = []

    /** 添加 tag */
    if (this.hasTag === true) {
      children.push(
        h(
          'span',
          {
            class: 'group-approval-status-tag',
          },
          [`${this.$t('group')}${this.$t(`${this.organ}`)}`]
        )
      )
    }

    if (this.value === -1) {
      children.push(
        h(
          Tooltip,
          {
            class: {
              [`${this.prefixCls}-tag_text`]: true,
              [`text-link`]: this.hasWorkflow,
            },
            props: {
              content: this.$t(`-1`),
              placement: 'top',
              transfer: true,
            },
          },
          [`-`]
        )
      )

      return h('span', { class: this.classes }, children)
    }

    /** 添加文本 */
    children.push(
      h(
        'span',
        {
          class: {
            [`${this.prefixCls}-tag_text`]: true,
            [`text-link`]: this.hasWorkflow,
          },
          on: {
            click: this.handleClick,
          },
        },
        [`${this.$t(`${this.value}`)}`]
      )
    )
    return h('span', { class: this.classes }, children)
  }

  private handleClick() {
    if (this.hasWorkflow) {
      this.$emit('click', this.value, this.organ)
    }
  }
}
