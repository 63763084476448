export default {
  messages: {
    'zh-CN': {
      internal: '国内',
      international: '国际',
      save: '保存',
      'must-fill': '必填',
      'maybe-fill': '非必填',
      'message-tips': '保存成功！',
      message: {
        delete: '删除',
        'confirm-delete': '确定要删除吗？',
        'delete-success': '删除成功！',
        'create-success': '添加成功！',
        'edit-success': '编辑成功！',
        'abroad-cannot-empty': '别名不能为空',
      },
    },
    'en-US': {
      internal: '国内',
      international: '国际',
      save: '保存',
      'must-fill': '必填',
      'maybe-fill': '非必填',
      'message-tips': '保存成功！',
      message: {
        delete: '删除',
        'confirm-delete': '确定要删除吗？',
        'delete-success': '删除成功！',
        'create-success': '添加成功！',
        'edit-success': '编辑成功！',
        'abroad-cannot-empty': '别名不能为空',
      },
    },
  },
}
