
























































































































import Vue from 'vue'
import { Component, Model, Prop, Watch } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import { Spin, Form, FormItem, Input } from 'view-design'
import { TransferReasonText } from '@business-components/transfer-reason'
import AppModal from '@components/app-modal'
import { DateUtil } from '@util'
import i18n from './i18n'

interface Data {
  termID: number
  shortStudentID: string
}

interface Model {
  xuexinID: string
  studentName: string
  transferDate: number
  transfeReason: number
  checkLost: number
  transfeArea: number
  transfeSchool: string
  note: string
  operator: string
  schoolDepartName: string
  mobile: string
  operatDate: number
  operatorName: string
  enclosure: Array<{
    fileName: string
    suffix: string
    url: string
  }>
}

const components = {
  AppModal,
  Spin,
  Form,
  FormItem,
  Input,
  TransferReasonText,
}
@Component({ name: 'TransferReasonModal', components, i18n })
export default class TransferReasonModal extends Vue {
  @Student.Action('fetch') private readonly fetch!: Student.Action.Fetch
  @Student.Getter('itemStatus')
  private readonly getItemStatus!: Student.Getter.ItemStatus
  @Student.Getter('itemExtra')
  private readonly getItemExtra!: Student.Getter.ItemExtra
  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Data

  private readonly prefixCls = 'transfer-reason-modal'
  private hidden = true
  private visible = false
  private model: Partial<Model> = {}

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get status() {
    return this.getItemStatus(this.data.shortStudentID)
  }

  get fetching() {
    return this.status.fetching === true
  }

  get fetchingError() {
    return this.status.fetchingError
  }

  get hasError() {
    return this.fetching === false && typeof this.fetchingError === 'string'
  }

  get showCheckLost() {
    return Boolean(this.model.checkLost)
  }

  get showTransfeArea() {
    return Boolean(this.model.transfeArea)
  }

  get showEnclosure() {
    return (
      Array.isArray(this.model.enclosure) && this.model.enclosure.length > 0
    )
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('fetching')
  watchFetching(fetching: boolean, previous: boolean) {
    if (this.visible === true && fetching === false && previous === true) {
      this.$set(this.$data, 'hidden', false)
      if (this.fetchingError !== null) {
        return void 0
      }
      const extra = this.getItemExtra(this.data.shortStudentID) || {}

      this.$set(
        this.$data,
        'model',
        Object.assign({}, extra, {
          transferDate:
            DateUtil.format(extra.transferDate, 'yyyy/MM/dd') || '-',
          operator: `${extra.operatorName || ''} ${extra.schoolDepartName ||
            ''} ${extra.mobile || ''}`,
          operatDate:
            DateUtil.format(extra.operatDate, 'yyyy/MM/dd hh:mm') || '-',
          mealDate: `${DateUtil.format(
            extra.mealStartDate,
            'yyyy/MM/dd'
          )} - ${DateUtil.format(extra.mealEndDate, 'yyyy/MM/dd')}`,
          stayDate: `${DateUtil.format(
            extra.stayStartDate,
            'yyyy/MM/dd'
          )} - ${DateUtil.format(extra.stayEndDate, 'yyyy/MM/dd')}`,
        })
      )
    }
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      this.fetch({
        actionType: Constant.ActionType.Transfer,
        shortStudentID: this.data.shortStudentID,
        termID: this.data.termID,
      })
    }

    this.$emit('on-visible-change', visible)
  }

  private handleHidden() {
    this.$emit('on-hidden')
    this.$set(this.$data, 'hidden', true)
  }
}
