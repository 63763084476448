export const messages: Messages = {
  'zh-CN': {
    save: '保存',
    loading: '加载中...',
    'receive-sms': '接收短信',
    'family-member-title': '家庭成员',
    'add-family-member': '添加家庭成员',
    'delete-family': '删除',
    messages: {
      validate: '请检查必填项',
    },
    number: {
      1: '一',
      2: '二',
      3: '三',
      4: '四',
      5: '五',
      6: '六',
      7: '七',
      8: '八',
      9: '九·',
      10: '十',
    },
  },
  'en-US': {},
}

export default { messages }
