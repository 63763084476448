import { mergeLocaleMessage } from '@util'

export const messages: Messages = {
  'zh-CN': {
    text: {
      studentStatis: '学生统计',
      studentStatisSchool: '学生人数统计 - 学校',
      studentStatisDepart: '学生人数统计 - 学部',
      studentStatisManageType: '学生人数统计 - 报读类型',
      school: '学校',
      schoolDepart: '学部',
      manageType: '报读类型',
      lostStatis: '实际流失率',
      studentView: '学生一览',
      transaction: '学生变动',
      entranceType: '入学分类分布',
      statusType: '学籍分布',
      student: '在校人数',
      noClass: '未分班',
      normal: '正常',
      outSchool: '休学',
      outArt: '外出艺体',
      empty: '空挂学籍',
      source: '原就读',
      outside: '外招',
      leave: '流失',
      up: '直升',
      graduation: '当前学期毕业',
      transferClass: '转班',
      homeToAbroad: '国内转国际',
      abroadToHome: '国际转国内',
      skipGrade: '跳级',
      stayGrade: '留级',
      downGrade: '降级',
      unitTranfer: '校际互转',
      total: '合计',
      primarySchool: '小学部',
      juniorHighSchool: '初中部',
      highSchool: '高中部',
      internationalDepartment: '国际部',
      domestic: '国内',
      quasiInternational: '准国际',
      pureInternational: '纯国际',
      weiming: '为明',
      studentCount: '学生人数',
      transfersCount: '转出人数',
      turnoverRate: '实际流失率',
      null: '-',
      noData: '暂无数据',
    },
  },
  'en-US': {},
}
export default mergeLocaleMessage(messages)
