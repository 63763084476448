var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModal',{attrs:{"title":_vm.$t('title'),"width":960,"footer-hide":"","loading":_vm.getListStatus.fetching},on:{"input":_vm.handleInput,"on-visible-change":_vm.handleVisibleChange,"on-hidden":_vm.handleHidden},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.visible)?_c('div',[_c('AppTable',{class:(_vm.prefixCls + "_table"),attrs:{"data":_vm.list,"columns":_vm.columns},scopedSlots:_vm._u([{key:"operateDate",fn:function(ref){
var row = ref.row;
return [(row.operateDate)?_c('span',{directives:[{name:"date-format",rawName:"v-date-format",value:({
            value: row.operateDate,
            format: 'yyyy-MM-dd hh:mm',
          }),expression:"{\n            value: row.operateDate,\n            format: 'yyyy-MM-dd hh:mm',\n          }"}]}):_c('span',[_vm._v("-")])]}},{key:"remarkAction",fn:function(ref){
          var row = ref.row;
return [(row.reMark.length > 0)?_c('Tooltip',{attrs:{"transfer":"","max-width":"300"}},[_c('div',{staticClass:"ellipsis-div",domProps:{"innerHTML":_vm._s(row.reMark)}},[_vm._v("\n            "+_vm._s(row.reMark)+"\n          ")]),_vm._v(" "),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('p',{domProps:{"innerHTML":_vm._s(row.reMark)}},[_vm._v(_vm._s(row.reMark))])])]):_vm._e()]}}],null,false,1735826641)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }