import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import { Spin, Form, Button, Radio, Message } from 'view-design'
import StudentField from '../student-field'
import { isString, toNumber, isArray, DateUtil } from '@util'
import i18n from './i18n'
import { Family } from 'xuexin-vuex/types/student'

type Model = {
  families: Student.Family[]
  kindCode?: string
  kindName?: string
}

@Component({ name: 'FamilyPane', i18n })
export default class FamilyPane extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly pending!: boolean
  @Prop() private readonly direction!: number
  @Prop() private readonly termID!: number
  @Prop() private readonly parentUnitID!: number
  @Prop() private readonly unitID!: number
  @Prop() private readonly schoolDepartID!: number
  @Prop() private readonly manageTypeID!: number
  @Prop() private readonly phaseID!: number
  @Prop({ type: Array, default: () => [] }) private data!: Student.Column[]
  @Prop({ type: Boolean, default: false }) private visible!: boolean
  @Prop({ type: String }) private readonly actionType!: string
  @Ref('form') private readonly form!: Form

  private readonly prefixCls: string = 'family-pane'
  private model: Model = { families: [] }
  private families: Record<string, Student.Column>[] = []
  private hidden = true
  private nations: any = ['CN', 'CN']
  private idLengths: any = [1, 1]
  private otherIdTypes: any = []
  private passportAddressRequired = 0
  private passportDateRequired = 0
  private idInfoRequired = 0

  get classes() {
    return {
      'pane-content': true,
      [this.prefixCls]: true,
    }
  }

  get fieldProps() {
    return {
      basicCode: 'family',
      nations: this.nations,
      actionType: this.actionType,
      direction: this.direction,
      termID: this.termID,
      parentUnitID: this.parentUnitID,
      unitID: this.unitID,
      schoolDepartID: this.schoolDepartID,
      manageTypeID: this.manageTypeID,
      phaseID: this.phaseID,
      idLength: this.idLengths,
      otherIdTypes: this.otherIdTypes,
      idInfoRequired: this.idInfoRequired,
    }
  }

  @Watch('visible', { immediate: true })
  watchVisible(visible: boolean, previous: boolean) {
    if (this.hidden === true && visible === true && previous !== true) {
      this.$set(this.$data, 'hidden', false)
    }
  }

  created() {
    this.buildColumns()
  }

  render(h: CreateElement) {
    /** 不可见状态下，可以不渲染表单 */
    if (this.hidden === true) {
      return h('div', { class: this.classes }, [
        h(Spin, { props: { fix: true } }, [`${this.$t('loading')}`]),
      ])
    }

    const children: VNodeChildren = []

    /** 添加 from 表单 */
    children.push(
      h(
        Form,
        {
          ref: 'form',
          class: `${this.prefixCls}_form`,
          props: { labelWidth: 120, model: this.model },
          nativeOn: { submit: this.handleSubmit },
        },
        this.model.families
          .map((family, familyIndex) => {
            const familyModel = this.families[familyIndex] || {}
            const familyNation = familyModel.nation || {}
            const children: VNodeChildren = []
            let receiveSms = '0'

            /** 添加成员属性内容 */
            children.push(
              h(
                'div',
                {
                  class: `${this.prefixCls}_family-member-content`,
                },
                family.columns.map((column, columnIndex) => {
                  const { columnCode, columnName } = column

                  /** 是否接收短信，不再此处处理。 */
                  if (
                    columnCode === 'receiveSms' && // 当前为短信组件
                    receiveSms === '0' && // 当前未设置接收短信项
                    column.value === '1' // 当前组件为接收状态
                  ) {
                    receiveSms = column.value
                    return void 0
                  }

                  /** 是否隐藏组件 */
                  if (this.isHidden(column, familyNation.value) === true) {
                    return void 0
                  }

                  const prop = `families.${familyIndex}.columns.${columnIndex}`
                  return h(StudentField, {
                    key: prop,
                    props: {
                      data: column,
                      label: columnName,
                      prop,
                      ...this.fieldProps,
                    },
                    on: {
                      input: this.handleFieldInput,
                      addID: this.handleAdd,
                      delID: this.handleDel,
                    },
                  })
                })
              )
            )

            /** 追加成员标题 */
            children.unshift(
              h(
                'div',
                {
                  class: `${this.prefixCls}_family-member-header`,
                  style: { position: 'relative' },
                },
                [
                  h(
                    'div',
                    {
                      class: 'family-member_title',
                    },
                    [
                      `${this.$t('family-member-title')}${this.$t(
                        `number.${familyIndex + 1}`
                      )}`,
                    ]
                  ),
                  h(
                    'div',
                    {
                      class: 'family-member_actions',
                    },
                    [
                      h(
                        Radio,
                        {
                          props: {
                            value: receiveSms,
                            'true-value': '1',
                            'false-value': '0',
                          },
                          on: {
                            'on-change': (val: string) =>
                              this.handleReceiveSMSChange(familyIndex),
                          },
                        },
                        [`${this.$t('receive-sms')}`]
                      ),
                      h(
                        'span',
                        {
                          class: 'text-link',
                          style: {
                            display: 'inlin-block',
                            verticalAlign: 'middle',
                            marginLeft: '10px',
                          },
                          on: {
                            click: () => this.handleDeleteFamily(familyIndex),
                          },
                        },
                        [`-${this.$t('delete-family')}`]
                      ),
                    ]
                  ),
                ]
              )
            )

            return h(
              'div',
              {
                class: `${this.prefixCls}_family-member`,
                key: familyIndex,
              },
              children
            )
          })
          .concat(
            h(
              'span',
              {
                class: 'text-link',
                style: {
                  display: 'inline-block',
                  marginLeft: '24px',
                },
                on: { click: this.handleAddFamily },
              },
              ['+添加家庭成员']
            )
          )
      )
    )

    children.push(
      h('div', { class: `pane-content_footer` }, [
        h(
          Button,
          {
            props: { disabled: this.pending, type: 'primary' },
            on: { click: this.handleSave },
          },
          [`${this.$t('save')}`]
        ),
      ])
    )

    return h('div', { class: this.classes }, children)
  }

  mounted() {}

  private isHidden(column: Student.Column, nation: string) {
    /** 非中国籍时，过滤指定字段信息(证件类型、证件号码、户口所在地、政治面貌) */
    if (nation !== 'CN') {
      switch (column.columnCode) {
        case 'pRegisteredPlace': /** 户口所在地 */
        case 'politicalStatus' /** 政治面貌 */:
          return true
        default:
          break
      }
    }

    if (
      column.columnCode === 'parentPassportAddress' ||
      column.columnCode === 'parentPassportDate'
    ) {
      return true
    }

    return false
  }

  private buildColumns() {
    /** 初始化 表单 model */
    const families: Record<string, Student.Column>[] = []
    const columns = this.data.map(column => {
      return Object.assign({}, column, {
        families:
          column.families?.map((family, groupIndex) => {
            const compose: Record<string, Partial<Student.Column>> = {}
            const familyColumn: Record<string, Student.Column> = {}
            families.push(familyColumn)

            this.nations = []

            //准备记录所有证件类型
            this.otherIdTypes[groupIndex] = []

            return Object.assign({}, family, {
              columns: family.columns.reduce<Student.Column[]>(
                (columns, item) => {
                  const assignColumn = Object.assign({}, item, {
                    columnType:
                      item.columnType !== void 0 || item.columnType !== null
                        ? `${item.columnType}`
                        : item.columnType,
                  })

                  //获取家庭组国籍
                  if (item.columnCode === 'nation') {
                    this.$set(this.nations, groupIndex, item.value)
                  }

                  //获取护照颁发地和有效期是否必填
                  if (item.columnCode === 'parentPassportAddress') {
                    this.passportAddressRequired = item.required
                  }
                  if (item.columnCode === 'parentPassportDate') {
                    this.passportDateRequired = item.required
                  }

                  if (item.columnCode === 'idInfo') {
                    this.idInfoRequired = item.required
                  }

                  //记录当前选择的证件类型
                  if (item.columnCode.indexOf('idInfo') != -1) {
                    if (item.value.length === 0) {
                      this.otherIdTypes[groupIndex][0] = void 0
                    } else {
                      item.value.forEach((idIn: any, i: number) => {
                        this.otherIdTypes[groupIndex][i] = idIn.idType
                      })
                    }
                  }

                  const { columnType, columnCode, parentCode } = assignColumn
                  switch (columnCode) {
                    case 'address': /** 联系地址 */
                    case 'pRegisteredPlace': /** 家长的户口所在地 */
                    case 'homeAddress': /** 现家庭住址 */
                    case 'nativePlace': /** 籍贯 */
                    case 'birthplace' /** 出生地 */:
                      assignColumn.value =
                        assignColumn.value !== void 0 &&
                        assignColumn.value !== null
                          ? `${assignColumn.value}`.split('-')
                          : []
                      break
                    case 'guardian' /** 监护人 */:
                      assignColumn.value = toNumber(assignColumn.value)
                      break
                    /** 国籍信息单独记录到中间模块 */
                    case 'nation' /** 国籍 */:
                      familyColumn['nation'] = assignColumn
                      break
                    default:
                      break
                  }

                  /** 组合节点的父节点 */
                  if (columnType === Constant.ColumnType.Object) {
                    if (compose[columnCode] === void 0) {
                      compose[columnCode] = { children: [] }
                    }
                    assignColumn.children = compose[columnCode].children
                    compose[columnCode] = assignColumn
                  }
                  // 归属于 parentCode 节点
                  else if (isString(parentCode) && parentCode !== '') {
                    if (compose[parentCode] === void 0) {
                      compose[parentCode] = { children: [] }
                    }
                    compose[parentCode].children?.push(assignColumn)
                    return columns
                  }

                  return columns.concat(assignColumn)
                },
                []
              ),
            })
          }) || [],
      })
    })

    //添加证件信息
    columns[0].families.forEach((f: any, index: number) => {
      //获取证件信息所在家庭信息组中的索引位置
      const IDInfoIndex = f.columns.findIndex(
        (i: any) => i.columnCode === 'idInfo'
      )
      //获取证件信息回显值
      const currentIDInfo = f.columns.find(
        (i: any) => i.columnCode === 'idInfo'
      )

      //设置每组家庭信息中证件信息的条数
      this.$set(this.idLengths, index, currentIDInfo.value.length || 1)

      //重新按远数据结构存储需要回显的证件信息
      let setIdInfos = []

      if (currentIDInfo.value.length > 0) {
        setIdInfos = currentIDInfo.value.map((v: any, valIndex: number) => {
          return this.getIdInfo(
            index,
            valIndex + 1,
            v.idType,
            v.idNo,
            v.idAddress,
            v.idDate
          )
        })
      } else {
        setIdInfos = [this.getIdInfo(index, 1, void 0, '', '', void 0)]
      }
      //把后台返回的证件信息删除，并在其位置上添加重组后的证件信息
      f.columns.splice(IDInfoIndex, 1, ...setIdInfos)
    })

    this.$set(this.$data, 'model', columns[0])
    this.$set(this.$data, 'families', families)
  }

  private updateFamilies(families: Student.Family[]) {
    const list: Record<string, Student.Column>[] = []
    /** 更新各家长的国籍数据 */
    this.model.families.forEach((item, index) => {
      item.columns.forEach(column => {
        if (column.columnCode === 'nation') {
          list.push({ nation: column })
        }
      })
    })
    this.$set(this.$data, 'families', list)
  }

  private restoreColumns(
    columns: Student.Column[],
    nation: string
  ): Student.Column[] {
    return columns.reduce<Student.Column[]>((columns, column) => {
      const { children = [], ...item } = column

      if (this.isHidden(column, nation) === true) {
        return columns
      }

      switch (item.columnCode) {
        case 'address': /** 联系地址 */
        case 'pRegisteredPlace': /** 家长户口所在地 */
        case 'homeAddress': /** 现家庭住址 */
        case 'nativePlace': /** 籍贯 */
        case 'birthplace' /** 出生地 */:
          item.value = isArray(item.value) ? item.value.join('-') : item.value
          break
        /** 处理日期类数据 */
        case 'birthday' /** 出生日期 */:
        case 'passportDate' /** 护照有效期 */:
          item.value = DateUtil.parse(item.value)
          break
        default:
          break
      }
      columns.push(item)

      return columns.concat(this.restoreColumns(children, nation))
    }, [])
  }

  private handleSave() {
    this.form.validate(valid => {
      if (valid !== true) {
        return this.$Message.error(`${this.$t('messages.validate')}`)
      }

      const columns = this.model.families.reduce<unknown[]>(
        (_columns, family, familyIndex) => {
          const familyModel = this.families[familyIndex] || {}
          const familyNation = familyModel.nation || {}
          const innerColumns = this.restoreColumns(
            family.columns,
            familyNation.value
          ).map(item =>
            Object.assign({}, item, { familyGroup: `${familyIndex + 1}` })
          )
          return _columns.concat(innerColumns)
        },
        []
      )

      let receiveSms = 0
      columns.forEach(i => {
        if (i.columnCode === 'receiveSms') {
          receiveSms += Number(i.value) || 0
        }
      })

      if (receiveSms < 1) {
        return this.$Message.error(`请选择一个接收短信的家长`)
      }

      //验证证件类型是否重复
      let isRepeat = 0
      this.otherIdTypes.forEach(i => {
        if (i[0] && i[1] && i[0] === i[1]) {
          isRepeat += 1
        }
      })

      if (isRepeat > 0) {
        return this.$Message.error(`证件类型不能重复`)
      }
      this.$emit('on-submit', columns)
    })
  }

  private handleSubmit(event: Event) {
    event.preventDefault()
  }

  private handleFieldInput(prop: string, val: unknown) {
    // console.log(`${this.$options.name}.handleFieldInput()`)
    const path: any = prop.split('.')
    const property = path.pop()
    let model: any = this.model
    path.forEach((key: any) => (model = model[key]))

    if (model !== void 0 && model !== null && property !== void 0) {
      this.$set(model, `${property}`, val)

      //根据证件类型，判断是否显示护照颁发地和有效期
      let idTypeItem: any = this.model.families[path[1]].columns[path[3]]
      if (idTypeItem.columnCode.indexOf('idType') != -1) {
        if ([2, 3, 6].includes(idTypeItem.value)) {
          idTypeItem.children[1].forceShow = 1
          idTypeItem.children[2].forceShow = 1
        } else {
          idTypeItem.children[1].forceShow = 0
          idTypeItem.children[2].forceShow = 0
        }

        //把证件类型记录到证件号码的parentValue中
        if (idTypeItem.children[0].columnCode.indexOf('idNo') != -1) {
          idTypeItem.children[0].parentValue = idTypeItem.value
        }

        //记录当前选择的证件类型
        if (idTypeItem.columnCode.indexOf('idType1') != -1) {
          let groupIdType = this.otherIdTypes[path[1]] || []
          groupIdType[0] = idTypeItem.value
          this.$set(this.otherIdTypes, path[1], groupIdType)

          //判断护照类型改变时，进行校验另一种类型是否重复
          if (path.length == 4) {
            if (
              this.model.families[path[1]].columns[Number(path[3]) + 1] &&
              this.model.families[path[1]].columns[Number(path[3]) + 1].value !=
                val
            ) {
              this.form.validateField(
                `families.${path[1]}.columns.${Number(path[3]) + 1}.value`
              )
            }
          }
        }
        if (model.columnCode.indexOf('idType2') != -1) {
          let groupIdType = this.otherIdTypes[path[1]] || []
          groupIdType[1] = idTypeItem.value
          this.$set(this.otherIdTypes, path[1], groupIdType)

          //判断护照类型改变时，进行校验另一种类型是否重复
          if (path.length == 4) {
            if (
              this.model.families[path[1]].columns[Number(path[3]) - 1].value !=
              val
            ) {
              this.form.validateField(
                `families.${path[1]}.columns.${Number(path[3]) - 1}.value`
              )
            }
          }
        }
      }
      //国籍改变时，修改传递给子组件的nations
      if (model.columnCode === 'nation') {
        this.$set(this.nations, path[1], val)
      }
      if (model.columnCode === 'relation') {
        if (Number(model.value) % 2 === 0) {
          this.model.families[path[1]].columns[Number(path[3]) - 1].value = '1'
        } else {
          this.model.families[path[1]].columns[Number(path[3]) - 1].value = '0'
        }
        this.$set(this.$data, 'model', this.model)
      }
    }
  }

  private handleReceiveSMSChange(index: number) {
    this.model.families.forEach((family, familyIndex) => {
      family.columns.forEach(item => {
        // 接收短信模块
        if (item.columnCode === 'receiveSms') {
          this.$set(item, 'value', index === familyIndex ? '1' : '0')
        }
      })
    })
  }

  private handleAddFamily() {
    if (this.model.families.length > 2) {
      return this.$Message.warning('最多可添加三位家庭成员')
    }
    const template = this.model.families[this.model.families.length - 1]
    const family = {
      columns: template.columns.map(column => {
        let { children, ...item } = column
        let value: string | null | undefined = void 0
        const valueName = ''

        if (item.columnCode === 'nation') {
          value = 'CN'
        }

        if (Array.isArray(column.children)) {
          children = children?.map(child => {
            return Object.assign({}, child, { value, valueName })
          })
        }

        return Object.assign({}, item, { value, valueName, children })
      }),
      receiveSms: 0,
      group: `${this.model.families.length + 1}`,
    }

    /** 添加新的家庭成员 */
    this.model.families.push(family)

    //修改证件信息“添加”按钮位于第几组的位置标记
    this.model.families.forEach((f: Family, index: number) => {
      f.columns.forEach(element => {
        if (
          element.columnCode == 'idType1' ||
          element.columnCode == 'idType2'
        ) {
          element.index = Number(index) + 1
        }

        if (element.columnCode == 'idType1') {
          this.idLengths[index] = 1
        } else if (element.columnCode == 'idType2') {
          this.idLengths[index] = 2
        }
      })
    })

    /** 更新各家长的国籍数据 */
    this.updateFamilies(this.model.families)
  }

  private handleDeleteFamily(familyIndex: number) {
    if (this.model.families.length === 1) {
      return this.$Message.error('不能删除所有家庭成员，至少保留一个。')
    }

    this.$Modal.confirm({
      title: '删除确认',
      content: `确定要删除该家庭成员${this.$t(
        `number.${familyIndex + 1}`
      )}吗？`,
      onOk: () => {
        // 过滤被删除的家庭成员
        const families = this.model.families.filter((family, index) => {
          return index !== familyIndex
        })

        /** 修正 group 属性 */
        families.forEach((family, index) => {
          this.$set(family, 'group', `${index + 1}`)
        })

        //修改证件信息“添加”按钮位于第几组的位置标记
        families.forEach((f: Family, index: number) => {
          let arr: number[] = []

          f.columns.forEach(element => {
            if (
              element.columnCode == 'idType1' ||
              element.columnCode == 'idType2'
            ) {
              element.index = index
              arr.push(element.value)
            }
          })

          this.otherIdTypes[index] = arr
        })

        /** 更新 model 属性 */
        this.$set(this.model, 'families', families)

        /** 更新家长国籍数据 */
        this.updateFamilies(families)
      },
    })
  }

  //添加证件信息
  private handleAdd(name: string) {
    //获取当前家庭信息组索引和当前组信息
    const indexValue: any = name.split('.')[1]
    const currentFamily: any = this.model.families[indexValue]

    //获取主证件信息索引位置
    const typeIndex = currentFamily.columns.findIndex(
      (i: any) => i.columnCode == 'idType1'
    )

    //在主证件信息后面添加辅助证件信息
    currentFamily.columns.splice(
      typeIndex + 1,
      0,
      this.getIdInfo(Number(indexValue) + 1, 2, void 0, '', '', void 0)
    )

    this.$set(this.model.families, indexValue, currentFamily)

    this.idLengths[indexValue] = 2
  }
  //删除证件信息
  private handleDel(name: string) {
    //获取当前家庭组信息
    const value: any = name.split('.')[1]
    const currentFamily = this.model.families[value]

    //获取要删除的证件信息的索引位置（只有辅助证件可以删除）
    const typeIndex = currentFamily.columns.findIndex(
      i => i.columnCode == 'idType2'
    )

    currentFamily.columns.splice(typeIndex, 1)
    this.$set(this.model.families, value, currentFamily)

    //删除辅助证件后，重置相关记录
    this.otherIdTypes[value][1] = void 0
    this.idLengths[value] = 1
  }

  //证件信息组
  private getIdInfo(
    index: number, //第几个家庭信息组
    typeIndex: number, //第几个证件信息组（主：1，辅：2）
    idType: number | undefined,
    idNo: string,
    idAddress: string,
    idDate: number | undefined
  ) {
    const strDate = idDate + ''
    const date = idDate
      ? new Date(
          `${strDate.substr(0, 4)}-${strDate.substr(4, 2)}-${strDate.substr(
            6,
            2
          )}`
        )
      : ''

    return {
      columnType: '6',
      kindCode: 'family',
      parentCode: '',
      kindName: '家庭成员信息',
      columnCode: 'idType' + typeIndex,
      columnName: typeIndex == 1 ? '主要证件' : '辅助证件',
      forceShow: 1,
      value: idType,
      valueName: '',
      required: this.idInfoRequired,
      index: index,
      typeIndex: typeIndex,
      nation: 'CN',
      children: [
        {
          columnType: '0',
          kindCode: 'family',
          parentCode: 'idType' + typeIndex,
          kindName: '家庭成员信息',
          columnCode: 'idNo',
          columnName: '证件号码',
          forceShow: 1,
          value: idNo,
          valueName: '',
          required: this.idInfoRequired,
        },
        {
          columnType: '4',
          kindCode: 'family',
          parentCode: 'idType' + typeIndex,
          kindName: '家庭成员信息',
          columnCode: 'idDate',
          columnName: '有效期',
          forceShow: [2, 3, 6].includes(idType || 2) ? 1 : 0,
          value: date,
          valueName: '',
          required: this.passportDateRequired,
        },
        {
          columnType: '0',
          kindCode: 'family',
          parentCode: 'idType' + typeIndex,
          kindName: '家庭成员信息',
          columnCode: 'idAddress',
          columnName: '颁发地',
          forceShow: [2, 3, 6].includes(idType || 2) ? 1 : 0,
          value: idAddress,
          valueName: '',
          required: this.passportAddressRequired,
        },
      ],
    }
  }
}
