













































































































































































import Vue from 'vue'
import { Component, Model, Watch, Prop, Ref } from 'vue-property-decorator'
import { Grade, Student, Constant } from 'xuexin-vuex'
import StudentTag from '@store/modules/student-tag'
import StudentTagType from 'types/student-tag'
import { Spin, Form, FormItem, Checkbox } from 'view-design'
import AppModal from '@components/app-modal'
import { SchoolSelect } from '@business-components/school'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { GradeSelect } from '@business-components/grade'
import { BusinessTypeSelect } from '@business-components/business-type'
import { SchoolClassTypeSelect } from '@business-components/school-class-type'
import { GlassSelect } from '@business-components/glass'
import DictionarySelect from '@business-components/dictionary-select'
import i18n from './i18n'

interface Data {
  termID: number
  parentUnitID: number
  unitID: number
  schoolDepartID: number
  phaseID: number
  studentID: string
}

interface Model {
  termID: number
  unitID: number
  phaseID: number
  unitGradeID: number
  manageTypeID: number
  unitClassTypeID: number
  classID: number
  freshStudent?: boolean
  academicDirection?: string
  studentCategory?: string
}

type Extra = Record<
  string,
  { schoolDepartID: number; schoolDepartName: string }
>

const components = {
  AppModal,
  Spin,
  Form,
  FormItem,
  SchoolSelect,
  SchoolPhaseSelect,
  GradeSelect,
  BusinessTypeSelect,
  SchoolClassTypeSelect,
  GlassSelect,
  DictionarySelect,
  Checkbox,
}
@Component({ name: 'ChangeDepartModal', components, i18n })
export default class ChangeDepartModal extends Vue {
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Action('fetch')
  private readonly fetchPreDepart!: Student.Action.Fetch
  @Student.Getter('itemStatus')
  private readonly getItemStatus!: Student.Getter.ItemStatus
  @Student.Getter('itemExtra')
  private readonly getitemExtra!: Student.Getter.ItemExtra
  @StudentTag.Action('fetch')
  private readonly fetchTag!: StudentTagType.Action.Fetch

  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Data
  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'change-grade-modal'
  private hidden = false
  private visible = false
  private loading = true
  private verifying = false
  private model: any = {
    freshStudent: true,
  }
  private show: Record<string, boolean> = {}
  private gradeList: any[] = []
  private schoolList: any[] = []
  private currentProjectID: number | undefined = 0
  // prettier-ignore
  private rules: FormRules = {
    unitID: [
      { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
    ],
    phaseID: [
      { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
    ],
    unitGradeID: [
      { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
    ],
    manageTypeID: [
      { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
    ],
    unitClassTypeID: [
      { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
    ],
    classID: [
      { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
    ],
    freshStudent:[
      {  type: 'string', trigger: 'change', validator: this.handleValidator },
    ],
    academicDirection:[
      {  type: 'string', trigger: 'change', validator: this.handleValidator },
    ],
    studentCategory:[
      {  type: 'string', trigger: 'change', validator: this.handleValidator },
    ],
  }
  private tagItem: any = {}

  get parentUnitID() {
    const currentSchool = this.schoolList.find(
      i => i.unitID === this.model.unitID
    )
    return currentSchool?.parentUnitID || this.data.parentUnitID
  }

  get departID() {
    return this.extra[`${this.model.unitID}`]?.schoolDepartID
  }

  get departName() {
    return (
      this.extra[`${this.model.unitID}`]?.schoolDepartName ||
      `${this.$t('tips.no-depart')}`
    )
  }

  get status() {
    return this.getItemStatus(this.data.studentID)
  }

  get updating() {
    return this.status.updating === true
  }

  get fetching() {
    return this.status.fetching === true
  }

  get extra(): Extra {
    return this.getitemExtra(this.data.studentID) || {}
  }

  get disabled() {
    return this.departID === void 0 || this.departID === null
  }

  @Watch('value', { immediate: true })
  watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  /**
   * 保存转至学部
   */
  @Watch('updating')
  watchUpdating(updating: boolean, previous: boolean) {
    if (this.visible === true && updating === false && previous === true) {
      this.stopInteraction()

      if (this.status.updatingError !== null) {
        return this.$Message.error(this.status.updatingError)
      }

      this.$emit('on-finish')
    }
  }

  @Watch('fetching')
  watchFetching(fetching: boolean, previous: boolean) {
    if (this.visible === true && fetching === false && previous === true) {
      if (this.status.fetchingError !== null) {
        return this.$Message.error(this.status.fetchingError)
      }
      this.$set(this.model, 'termID', this.data.termID)
      this.$set(this.model, 'parentUnitID', this.parentUnitID)
      this.$set(this.model, 'studentID', this.data.studentID)
      // this.$set(this.model, 'unitID', this.model.unitID || this.data.unitID)
      this.$set(this.$data, 'hidden', false)
    }
  }
  //
  @Watch('model.unitID')
  private watchunitID(value: number) {
    if (value) {
      this.fetchTagItem(value)

      this.fetchPreDepart({
        actionType: Constant.ActionType.Depart,
        parentUnitID: this.parentUnitID,
        schoolDepartID: this.data.schoolDepartID,
        phaseID: this.data.phaseID,
        studentID: this.data.studentID,
      })
    }
  }

  //学段变化为非高中，重置应届生为true
  @Watch('model.phaseID')
  private watchPhaseID(value: number) {
    this.resetTagValue(this.model.manageTypeID, value)
  }

  // 监听转至方向
  @Watch('model.manageTypeID', { immediate: true })
  private watchDirection(manageTypeID: number, prev: number) {
    this.resetTagValue(manageTypeID, this.model.phaseID)
  }

  @Watch('model.unitGradeID')
  private watchUnitGradeID(value: number) {
    if (value && this.gradeList.length > 0) {
      this.currentProjectID = this.gradeList.find(
        i => i.unitGradeID === value
      ).projectID
    } else {
      this.currentProjectID = void 0
    }
  }

  private fetchTagItem(unitID: number) {
    this.fetchTag({
      xuexinID: this.data.studentID,
      unitID: unitID,
      termID: this.data.termID,
      type: 1,
    }).then(({ result }: any) => {
      this.tagItem = {
        freshStudent: result.freshStudent === 0 ? false : true,
        academicDirection: result.academicDirection
          ? `${result.academicDirection}`
          : void 0,
        studentCategory: result.studentCategory
          ? `${result.studentCategory}`
          : void 0,
        hasTermBill: result.hasTermBill,
      }
      if (this.model.phaseID == 4 || this.model.phaseID == 3) {
        const {
          freshStudent,
          academicDirection,
          studentCategory,
        } = this.tagItem
        this.$set(this.model, 'freshStudent', freshStudent)
        this.$set(this.model, 'academicDirection', academicDirection)
        this.$set(this.model, 'studentCategory', studentCategory)
      }
    })
  }
  private handleValidator(
    rule: FormRule,
    value: any,
    callback: FormRuleCallback
  ) {
    if (
      (this.model.manageTypeID == 3 &&
        ['freshStudent', 'academicDirection', 'studentCategory'].includes(
          rule.field || '-1'
        )) ||
      (rule.field == 'studentCategory' &&
        this.model.academicDirection == '5') ||
      !(rule.field !== 'freshStudent' && Boolean(value) === false)
    ) {
      return callback()
    } else {
      return callback(`${this.$t(`error.${rule.field}`)}`)
    }
  }

  private handleFinish(field: string, list: any[]) {
    // console.log(
    //   `${this.$options.name}.handleFinish(${field} = length:${list.length})`
    // )
    if (field === 'unitGradeID') {
      this.gradeList = list
    }
    switch (field) {
      case 'unitID' /** 校区 */:
        this.schoolList = list
      case 'phaseID' /** 学段 */:
      case 'unitGradeID' /** 学校年级 */:
      case 'manageTypeID': /** 报读类型 */
      case 'unitClassTypeID' /** 校本班型 */:
        {
          if (list.length === 1) {
            /** 设置唯一值 */
            this.$set(this.model, field, list[0][field])
          }
          this.$set(this.show, field, list.length !== 1)
        }
        break
      default:
        break
    }

    if (field === 'unitID') {
      this.$set(this.model, 'unitID', void 0)
      this.$set(this.show, 'unitID', true)
    }
  }

  private stopInteraction() {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)

    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }

  private handleOK() {
    this.$set(this.$data, 'verifying', true)
    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        /** 校验失败 */
        return this.stopInteraction()
      }
      const { academicDirection, studentCategory } = this.model
      /** 学部互转操作 */
      this.update(
        Object.assign({}, this.model, {
          actionType: Constant.ActionType.Depart,
          originalUnitID: this.data.unitID,
          freshStudent: this.model.freshStudent ? 1 : 0,
          schoolDepartID: this.data.schoolDepartID,
          studentCategory:
            academicDirection === '5' ? void 0 : studentCategory,
        })
      )
    })
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleHidden() {
    this.$emit('on-hidden')
    this.stopInteraction()
    this.$set(this.$data, 'model', { freshStudent: true })
    this.form?.resetFields()
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      this.$set(this.$data, 'model', { freshStudent: true })
      this.fetchPreDepart({
        actionType: Constant.ActionType.Depart,
        parentUnitID: this.parentUnitID,
        schoolDepartID: this.data.schoolDepartID,
        phaseID: this.data.phaseID,
        studentID: this.data.studentID,
      })
      // this.fetchTagItem(this.data.unitID)
    } else {
      this.model = { freshStudent: true }
    }

    this.$emit('on-visible-change', visible)
  }
  //重置学生标签默认值
  private resetTagValue(manageTypeID: number, phaseID: number) {
    if (manageTypeID != 3) {
      const {
        freshStudent,
        academicDirection,
        studentCategory,
      } = this.tagItem
      if (phaseID === 4 || phaseID === 3) {
        this.$set(this.model, 'freshStudent', freshStudent)
        this.$set(this.model, 'academicDirection', academicDirection)
        this.$set(this.model, 'studentCategory', studentCategory)
      } else {
        this.$set(this.model, 'freshStudent', true)
        this.$set(this.model, 'academicDirection', '1')
        this.$set(this.model, 'studentCategory', studentCategory || '3')
      }
    } else {
      //
    }
  }
}
