export default {
  messages: {
    'zh-CN': {
      text: {
        title: '密码重置成功',
        'sub-title': '请使用新密码进行登录',
        nextStep: '我要登录',
      },
    },
    'en-US': {},
  },
}
