export const messages: Messages = {
  'zh-CN': {
    title: {
      create: '添加年级',
      view: '查看年级',
      edit: '编辑年级',
    },
    label: {
      unit: '校区',
      depart: '学部',
      phase: '学段',
      aboard: '出国方向',
      startTerm: '起始学期',
      endTerm: '结束学期',
      schoolSystem: '学制',
      gradeName: '年级名称',
      gradeDirector: '年级主任',
      creator: '添加人',
      createDate: '添加时间',
    },
    level: '级',
    text: {
      null: '-',
      undefined: '-',
      loading: '加载中...',
    },
    message: {
      noChoice: '没有选择',
      unit: '请选择校区',
      schoolDepart: '请选择学部',
      phase: '请选择学段',
      abroad: '请选择出国方向',
      startTerm: '请选择起始学期',
      schoolSystem: '请选择学制',
    },
  },
  'en-US': {},
}

export default { messages }
