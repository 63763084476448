import Vue, { CreateElement } from 'vue'
import Component from 'vue-class-component'

@Component({ name: 'RootLayout' })
export default class RootLayout extends Vue {
  render(createElement: CreateElement) {
    return createElement('div', { class: 'page root-layout' }, [
      createElement('router-view'),
    ])
  }
  mount() {}
}
