import Vue, { CreateElement, VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Constant } from 'xuexin-vuex'
import { Input, DatePicker } from 'view-design'
import { SchoolYearSelect } from '@business-components/school-year'
import { SemesterSelect } from '@business-components/semester'
import { SchoolSelect } from '@business-components/school'
import { SchoolDepartSelect } from '@business-components/school-depart'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { GradeSelect } from '@business-components/grade'
import { GlassSelect } from '@business-components/glass'
import { BusinessTypeSelect } from '@business-components/business-type'
import { ClassTypeSelect } from '@business-components/class-type'
import { SchoolClassTypeSelect } from '@business-components/school-class-type'
import { StudentStatusSelect } from '@business-components/student-status'
import { MonthSelect } from '@business-components/month'

import PageTyping from '../../../types/page'

@Component({ name: 'SearchColumn' })
export default class SearchColumn extends Vue {
  @Prop({ type: String, required: true }) private readonly label!: string
  @Prop({ type: Object, required: true })
  private readonly data!: PageTyping.Search
  @Prop({ type: Object, required: true })
  private readonly parameter!: PageTyping.SearchParameter
  @Prop({}) private readonly value!: any
  @Prop({ type: Boolean, default: true }) private readonly multiple!: boolean
  @Prop({ type: Boolean, default: false }) private readonly required!: boolean

  private readonly prefixCls = 'search-item'
  private visible = true

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get style() {
    const visible = this.visible === true && this.data.show === 1
    return {
      display: visible ? 'inline-block' : 'none',
    }
  }

  created() {
    switch (this.data.code) {
      case 'unitID': // 校区
        // 以上条件，默认不显示
        this.$set(this.$data, 'visible', false)
        break
      default:
        this.$set(this.$data, 'visible', true)
        break
    }
  }

  render(createElement: CreateElement) {
    const children: VNodeChildren = []
    const props: Record<string, any> = {}
    const attrs: Record<string, any> = {}
    const style: Record<string, any> = {}
    const on: Record<string, any> = {}
    const { type, code } = this.data
    let tag: VueConstructor | null = null
    switch (`${type}`) {
      case Constant.ColumnType.Text /** 文本: 0 */:
        props.value = this.value
        attrs.placeholder = this.label
        on.input = this.handleInput
        tag = Input
        break

      case Constant.ColumnType.Select /** 下拉框: 1 */:
        attrs.multiple = this.multiple
        attrs.clearable = this.required !== true
        attrs.value = this.value
        on['on-change'] = this.handleChange
        on['on-finish'] = this.handleFinish
        style.width = '200px'
        attrs.placeholder = this.label
        switch (code) {
          case 'schoolYear' /** 学年 */:
            attrs.required = this.required
            attrs.clearable = false
            style.width = '120px'
            tag = SchoolYearSelect
            break
          case 'termID' /** 学期 */:
            attrs.required = this.required
            tag = SemesterSelect
            break
          case 'parentUnitID': /** 学校 */
          case 'inUnitID' /** 转至校区 */:
            props.organID = this.parameter.organID
            props.termID = this.parameter.termID
            attrs.clearable = true
            tag = SchoolSelect
            break
          case 'unitID' /** 校区 */:
            attrs.required = this.required
            props.organID = this.parameter.organID
            props.unitID = this.parameter.parentUnitID
            props.capture = true
            attrs.clearable = true
            tag = SchoolSelect
            break
          case 'schoolDepartID' /** 学部 */:
            props.termID = this.parameter.termID
            props.unitID = this.parameter.unitID
            attrs.clearable = true
            style.width = '120px'
            tag = SchoolDepartSelect
            break
          case 'phaseID' /** 学段 */:
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            style.width = '120px'
            attrs.multiple = true
            tag = SchoolPhaseSelect
            break
          case 'unitGradeID': /** 学校年级 */
          case 'gradeID' /** 年级 */:
            props.termID = this.parameter.termID
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            props.phaseID = this.parameter.phaseID
            props.base = code === 'gradeID' ? true : false
            style.width = code === 'gradeID' ? '100px' : '160px'
            attrs.multiple = this.multiple
            tag = GradeSelect
            break
          case 'classID' /** 班级 */:
            props.termID = this.parameter.termID
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            props.phaseID = this.parameter.phaseID
            props.unitGradeID = this.parameter.unitGradeID
            props.manageTypeID = this.parameter.manageTypeID
            props.unitClassTypeID = this.parameter.unitClassTypeID
            attrs.multiple = this.multiple
            tag = GlassSelect
            break
          case 'manageTypeID' /** 报读类型 */:
            props.unitID = this.parameter.unitID
            props.phaseID = this.parameter.phaseID
            style.width = '120px'
            attrs.multiple = this.multiple
            tag = BusinessTypeSelect
            break
          case 'classTypeID' /** 班级类型 */:
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            props.phaseID = this.parameter.phaseID
            props.manageTypeID = this.parameter.manageTypeID
            props.schoolSystemID = this.parameter.schoolSystemID
            style.width = '120px'
            attrs.multiple = this.multiple
            tag = ClassTypeSelect
            break
          case 'unitClassTypeID' /** 校本班型 */:
            props.unitID = this.parameter.unitID
            props.schoolDepartID = this.parameter.schoolDepartID
            props.phaseID = this.parameter.phaseID
            props.manageTypeID = this.parameter.manageTypeID
            props.schoolSystemID = this.parameter.schoolSystemID
            props.classTypeID = this.parameter.classTypeID
            style.width = '120px'
            attrs.multiple = this.multiple
            tag = SchoolClassTypeSelect
            break
          case 'studentStatus' /** 学生状态 */:
            style.width = '120px'
            tag = StudentStatusSelect
            break
          case 'endMonth' /** 截止月份*/:
            style.width = '120px'
            tag = MonthSelect
          default:
            console.log(
              `未处理下拉框(code: ${code}, type: ${type}), label: ${this.label}`
            )

            break
        }
        break

      case Constant.ColumnType.Date /** 日期: 4 */:
      case Constant.ColumnType.Interval /** 区间日期: 7 */:
        props.value = this.value
        attrs.placeholder = this.label
        props.type =
          `${type}` === Constant.ColumnType.Interval ? 'daterange' : 'date'
        style.width =
          `${type}` === Constant.ColumnType.Interval ? '220px' : void 0
        on.input = this.handleInput
        tag = DatePicker
        break

      default:
        console.log(
          `未处理搜索条件(code: ${code}, type: ${type}, label: ${this.label})`
        )
        break
    }

    if (tag === null) {
      return null
    }

    /** 添加节点组件 */
    children.push(createElement(tag, { style, props, attrs, on }))

    /** label 元素*/
    // const LabelElement = createElement(
    //   'div',
    //   {
    //     class: `${this.prefixCls}_label`,
    //   },
    //   [this.label]
    // )

    /**  content 元素 */
    const ContentElement = createElement(
      'div',
      {
        class: `${this.prefixCls}_content`,
      },
      children
    )

    return createElement(
      'div',
      {
        class: this.classes,
        style: this.style,
      },
      // [LabelElement, ContentElement]
      [ContentElement]
    )
  }

  mounted() {}

  private handleFinish(list: any[]) {
    switch (this.data.code) {
      case 'unitID': // 校区
        /**
         * 以上搜索条件，数据长度不为 1 时，不显示
         * (数据长度为 0 时，需要显示出来，以便告知用户)。
         */
        // console.log(list)
        this.$set(this.$data, 'visible', list.length !== 1)
        break
      default:
        break
    }
  }

  private handleInput(value: any) {
    this.$emit('on-change', this.data.code, value)
  }

  private handleChange(value: any) {
    this.$emit('on-change', this.data.code, value)
  }
}
