// 小记行显示名称
function subTotalName(item: any, type: number, sumKeyArr: any): string {
  let _name = ''
  for (let i = 0; i < type; i++) {
    _name += `${item[sumKeyArr[i]] == null ? '' : item[sumKeyArr[i]]} > `
  }
  return _name.substring(0, _name.length - 3) + '：小计'
}

// 设置小记行，和存储小记行信息
function setSumRow(
  item: any,
  type: number,
  sumKeyArr: any,
  arr: any,
  indexArr: any,
  hide: boolean = false
) {
  let sumRowObj: any = { summary: 'yes', hide }
  sumRowObj.type = type
  sumRowObj[sumKeyArr[type - 1]] = subTotalName(item, type, sumKeyArr)
  arr.push(Object.assign({}, item, sumRowObj))
  indexArr.push({ index: arr.length - 1, type: type })
}

// 根据传入的显示列，合并数据行
function mergeList(
  list: any,
  sumKeyArr: string[],
  countColumn: string[],
  percentage: string[]
) {
  if (sumKeyArr.length === 0) {
    return list
  }
  let resultObj: any = {},
    newArr: any = []

  // 遍历源数组
  list.forEach((n: any) => {
    const colStr = sumKeyArr
      .map(s => {
        return n[s]
      })
      .join('-')
    if (resultObj[colStr] === void 0) {
      resultObj[colStr] = { len: 1, merge: Object.assign({}, n) }
    } else {
      resultObj[colStr].len += 1
      Array.from(new Set(countColumn.concat(percentage))).forEach(i => {
        resultObj[colStr].merge[i] += Number(n[i])
      })
    }
  })

  for (let key in resultObj) {
    let o: any = Object.assign({}, resultObj[key].merge)
    percentage.forEach(i => {
      o[i] = ((resultObj[key].merge[i] * 10000) / resultObj[key].len).toFixed(0)
    })
    newArr.push(o)
  }

  return newArr
}

//处理列表合并数据
export function setReportData({
  list, // 列表数据
  typeVal, //小记项值（0，1，2，3，4）
  sumKeyArr, //小记项的条件列（school,manageType,depart…）
  countColumn, //数量列（可以合计的列）
  percentageColumn, //百分比列和计算百分比需要的列,类型 string[]
}: any): any {
  if (list.length === 0) {
    return {
      dataArr: [],
      indexArr: [],
      rowspans: {},
    }
  }
  sumKeyArr.length < typeVal ? (typeVal = sumKeyArr.length) : ''

  let numberColumn: string[] = [],
    percentage: string[] = percentageColumn || [],
    virtualRowIndexArr: any = [], //虚拟行（小记行）索引和类型
    arr: any = [],
    totalObj: any = {},
    countObj: any = {}

  if (countColumn === void 0) {
    for (let key in list[0]) {
      typeof list[0][key] == 'number' ? numberColumn.push(key) : ''
    }
  } else {
    numberColumn = countColumn.concat()
  }
  let simplifyList = list.map((item: any) => {
    return Object.assign({}, item)
  })

  // 赋值小计行各数量列
  let i = 1
  while (i <= 4) {
    countObj[i] = {}
    numberColumn.concat(percentage).forEach(item => {
      countObj[i][item] = { num: 0, len: 0, rate: 0 }
    })
    i++
  }

  // 赋值总计行各数量列
  numberColumn.concat(percentage).forEach(item => (totalObj[item] = 0))

  let firstKey2 = '',
    firstKey3 = '',
    firstKey4 = ''

  simplifyList.forEach((item: any, index: number) => {
    // 获取合计
    numberColumn
      .concat(percentage)
      .forEach(i => (totalObj[i] += Number(item[i])))
    if (typeVal !== 0) {
      const _next: any = simplifyList[index + 1]
      let _prev: any = null

      if (index === 0) {
        firstKey2 = item[sumKeyArr[1]]
        firstKey3 = item[sumKeyArr[2]]
        firstKey4 = item[sumKeyArr[3]]

        _prev = item
      } else {
        _prev = simplifyList[index - 1]
      }

      if (item[sumKeyArr[0]] !== _prev[sumKeyArr[0]]) {
        firstKey2 = item[sumKeyArr[1]]
        firstKey3 = item[sumKeyArr[2]]
        firstKey4 = item[sumKeyArr[3]]
      } else {
        if (item[sumKeyArr[1]] !== _prev[sumKeyArr[1]]) {
          firstKey3 = item[sumKeyArr[2]]
          firstKey4 = item[sumKeyArr[3]]
        } else {
          if (item[sumKeyArr[2]] !== _prev[sumKeyArr[2]]) {
            firstKey4 = item[sumKeyArr[3]]
          }
        }
      }

      arr.push(item)

      // 给最后一行添加小计行
      if (index + 1 === simplifyList.length) {
        // 当typeVal == 4时，需要添加相应小记行
        if (typeVal > 3) {
          setSumRow(item, 4, sumKeyArr, arr, virtualRowIndexArr)
        }
        if (typeVal > 2) {
          // 添加小记3
          setSumRow(item, 3, sumKeyArr, arr, virtualRowIndexArr)
        }

        if (typeVal > 1) {
          // 添加小记2
          setSumRow(item, 2, sumKeyArr, arr, virtualRowIndexArr)
        }

        //添加小记1
        setSumRow(item, 1, sumKeyArr, arr, virtualRowIndexArr)
        return
      }

      // 小记4项时,在前三个关键列相同且第四列不同行前面，添加小记4
      if (
        typeVal === 4 &&
        item[sumKeyArr[0]] === _next[sumKeyArr[0]] &&
        item[sumKeyArr[1]] === _next[sumKeyArr[1]] &&
        item[sumKeyArr[2]] === _next[sumKeyArr[2]] &&
        item[sumKeyArr[3]] != _next[sumKeyArr[3]]
      ) {
        setSumRow(item, 4, sumKeyArr, arr, virtualRowIndexArr)
      }

      // 小记3，4项时，在前两个关键列相同且第三列不同行前面，添加小记3，4
      if (
        typeVal > 2 &&
        item[sumKeyArr[0]] === _next[sumKeyArr[0]] &&
        item[sumKeyArr[1]] === _next[sumKeyArr[1]] &&
        item[sumKeyArr[2]] != _next[sumKeyArr[2]]
      ) {
        if (typeVal > 3) {
          setSumRow(item, 4, sumKeyArr, arr, virtualRowIndexArr)
        }

        setSumRow(
          item,
          3,
          sumKeyArr,
          arr,
          virtualRowIndexArr,
          item[sumKeyArr[3]] == firstKey4 && typeVal !== 3
        )
      }

      // 小记2，3，4项时，在第一个关键列相同且第二列不同行前面，添加小记2，3，4
      if (
        typeVal > 1 &&
        item[sumKeyArr[0]] === _next[sumKeyArr[0]] &&
        item[sumKeyArr[1]] != _next[sumKeyArr[1]]
      ) {
        if (typeVal > 3) {
          setSumRow(item, 4, sumKeyArr, arr, virtualRowIndexArr)
        }
        if (typeVal > 2) {
          setSumRow(
            item,
            3,
            sumKeyArr,
            arr,
            virtualRowIndexArr,
            item[sumKeyArr[3]] == firstKey4 && typeVal !== 3
          )
        }
        setSumRow(
          item,
          2,
          sumKeyArr,
          arr,
          virtualRowIndexArr,
          item[sumKeyArr[2]] == firstKey3 && typeVal !== 2
        )
      }

      // 给关键列相同的最后一行添加小计行
      if (item[sumKeyArr[0]] != _next[sumKeyArr[0]]) {
        // 当typeVal== 4 时，添加小记4项
        if (typeVal > 3) {
          setSumRow(item, 4, sumKeyArr, arr, virtualRowIndexArr)
        }

        if (typeVal > 2) {
          setSumRow(
            item,
            3,
            sumKeyArr,
            arr,
            virtualRowIndexArr,
            item[sumKeyArr[3]] == firstKey4 && typeVal !== 3
          )
        }

        if (typeVal > 1) {
          setSumRow(
            item,
            2,
            sumKeyArr,
            arr,
            virtualRowIndexArr,
            item[sumKeyArr[2]] == firstKey3 && typeVal !== 2
          )
        }
        setSumRow(
          item,
          1,
          sumKeyArr,
          arr,
          virtualRowIndexArr,
          item[sumKeyArr[1]] == firstKey2 && typeVal !== 1
        )
      }
    }

    //若只有合计， 给百分比列，添加%
    if (typeVal === 0 && percentage.length > 0) {
      percentage.forEach(p => (item[p] = item[p] / 100 + '%'))
    }
  })

  // 如果有百分比列，总计设置百分比列
  if (percentage.length > 0) {
    percentage.forEach(i => {
      //change start
      totalObj[i] = (totalObj[i] / simplifyList.length / 100).toFixed(2) + '%'
      //change end
    })
  }

  // 添加合计行
  if (typeVal === 0) {
    virtualRowIndexArr = []
    let totalRowObj: any = { type: 'total', summary: 'yes' }
    totalRowObj[sumKeyArr[0]] = '合计'

    simplifyList = simplifyList.concat([Object.assign(totalRowObj, totalObj)])
    simplifyList.forEach((item: any, index: number) => {
      item['keyID'] = index
    })

    return {
      dataArr: simplifyList.concat(),
      indexArr: virtualRowIndexArr,
      rowspans: {},
    }
  }

  let rowspans: any = {}
  // 初始化 rowspans
  sumKeyArr.forEach((item: any, index: number) => {
    if (index < typeVal) {
      rowspans[item] = []
    }
  })
  //获取关键列的合并行数
  let _key1 = '',
    _key2 = '',
    _key3 = '',
    _key4 = '',
    curRowIndex1 = 0,
    curRowIndex2 = 0,
    curRowIndex3 = 0,
    curRowIndex4 = 0

  arr.forEach((element: any, index: number) => {
    if (index == 0) {
      _key1 = element[sumKeyArr[0]]
      _key2 = element[sumKeyArr[1]]
      _key3 = element[sumKeyArr[2]]
      _key4 = element[sumKeyArr[3]]
      curRowIndex1 = index
      curRowIndex2 = index
      curRowIndex3 = index
      curRowIndex4 = index

      rowspans[sumKeyArr[0]][0] = 1
      typeVal > 1 ? (rowspans[sumKeyArr[1]][index] = 1) : ''
      typeVal > 2 ? (rowspans[sumKeyArr[2]][index] = 1) : ''
      typeVal > 3 ? (rowspans[sumKeyArr[3]][index] = 1) : ''
    } else {
      if (_key1 == element[sumKeyArr[0]]) {
        rowspans[sumKeyArr[0]][curRowIndex1] += 1
        rowspans[sumKeyArr[0]][index] = 0

        // 小记两项
        if (typeVal > 1) {
          if (_key2 === element[sumKeyArr[1]]) {
            rowspans[sumKeyArr[1]][curRowIndex2] += 1
            rowspans[sumKeyArr[1]][index] = 0

            // 小记三项
            if (typeVal > 2) {
              if (_key3 == element[sumKeyArr[2]]) {
                rowspans[sumKeyArr[2]][curRowIndex3] += 1
                rowspans[sumKeyArr[2]][index] = 0

                // 小记四项
                if (typeVal > 3) {
                  if (_key4 == element[sumKeyArr[3]]) {
                    rowspans[sumKeyArr[3]][curRowIndex4] += 1
                    rowspans[sumKeyArr[3]][index] = 0
                  } else {
                    _key4 = element[sumKeyArr[3]]
                    curRowIndex4 = index

                    rowspans[sumKeyArr[3]][index] = 1
                  }
                }
              } else {
                _key3 = element[sumKeyArr[2]]
                _key4 = element[sumKeyArr[3]]
                curRowIndex3 = index
                curRowIndex4 = index

                rowspans[sumKeyArr[2]][index] = 1
                typeVal > 3 ? (rowspans[sumKeyArr[3]][index] = 1) : ''
              }
            }
          } else {
            _key2 = element[sumKeyArr[1]]
            _key3 = element[sumKeyArr[2]]
            _key4 = element[sumKeyArr[3]]
            curRowIndex2 = index
            curRowIndex3 = index
            curRowIndex4 = index

            rowspans[sumKeyArr[1]][index] = 1
            typeVal > 2 ? (rowspans[sumKeyArr[2]][index] = 1) : ''
            typeVal > 3 ? (rowspans[sumKeyArr[3]][index] = 1) : ''
          }
        }
      } else {
        _key1 = element[sumKeyArr[0]]
        _key2 = element[sumKeyArr[1]]
        _key3 = element[sumKeyArr[2]]
        _key4 = element[sumKeyArr[3]]
        curRowIndex1 = index
        curRowIndex2 = index
        curRowIndex3 = index
        curRowIndex4 = index

        rowspans[sumKeyArr[0]][index] = 1
        typeVal > 1 ? (rowspans[sumKeyArr[1]][index] = 1) : ''
        typeVal > 2 ? (rowspans[sumKeyArr[2]][index] = 1) : ''
        typeVal > 3 ? (rowspans[sumKeyArr[3]][index] = 1) : ''
      }
    }

    //设置小记行的小记列（数量列）
    if (element.type == undefined) {
      for (let key in countObj) {
        numberColumn.forEach(n => {
          countObj[key][n]['num'] += Number(element[n])
        })

        percentage.forEach(p => {
          countObj[key][p]['rate'] += Number(element[p])
          countObj[key][p]['len'] += 1

          // 给百分比列，添加%
          element[p] = element[p] / 100 + '%'
        })
      }
    } else {
      if (element.type <= typeVal) {
        if (percentage.length > 0) {
          //change start
          percentage.forEach(p => {
            element[p] =
              (
                countObj[element.type][p]['rate'] /
                countObj[element.type][p]['len'] /
                100
              ).toFixed(2) + '%'

            countObj[element.type][p]['rate'] = 0
            countObj[element.type][p]['len'] = 0
          })
          //change end
        }

        numberColumn.forEach(n => {
          if (!percentage.includes(n)) {
            element[n] = countObj[element.type][n]['num']
            countObj[element.type][n]['num'] = 0
          }
        })
      }
    }
  })

  // 添加合计行
  let totalRowObj: any = { type: 'total', summary: 'yes' }
  totalRowObj[sumKeyArr[0]] = '合计'
  arr.push(Object.assign(totalRowObj, totalObj))

  arr.forEach((item: any, index: number) => {
    item['keyID'] = index
  })

  return {
    dataArr: arr.concat(),
    indexArr: virtualRowIndexArr,
    rowspans: Object.assign({}, rowspans),
  }
}

// 合并行列方法
export function spanChildMethod(
  { row, column, rowIndex, columnIndex }: any,
  inventedDataIndexArr: any, //小记行索引和项数[{index:小记行在列表中的索引，type:小记几项}]
  sumKey: string[],
  rowspans: any //合并行列标记对象{key1：number[]，key2：number[]} key: 合并项数对应的列头(parentUnitName,manageTypeName……), 'number[]':当前列中每个单元格应合并的行数
) {
  const findItem = inventedDataIndexArr.find((item: any) => {
      return item.index == rowIndex
    }),
    validHeaderLength = sumKey.length

  // 根据选择的合并项，找出对应列并进行行合并
  if (row.type == 'total') {
    if (columnIndex === 0) {
      return {
        rowspan: 1,
        colspan: validHeaderLength,
      }
    } else if (columnIndex < validHeaderLength) {
      return { rowspan: 1, colspan: 0 }
    }
  } else if (findItem != void 0) {
    //合并展示列后面的有效列，设置rowspan:1,colspan:0 (有效列：除数量列后的其它列，也即可被合并的列)
    if (columnIndex < findItem.type - 1) {
      return {
        rowspan: 0,
        colspan: 1,
      }
    } else if (columnIndex === findItem.type - 1) {
      return {
        rowspan: 1,
        colspan: validHeaderLength - (findItem.type - 1),
      }
    } else if (
      columnIndex > findItem.type - 1 &&
      columnIndex < validHeaderLength
    ) {
      return { rowspan: 1, colspan: 0 }
    }
  } else {
    if (sumKey.includes(column.key)) {
      if (rowspans[column.key] == undefined) {
        return { rowspan: 1, colspan: 1 }
      }

      let rowspan = rowspans[column.key][rowIndex]
      let colspan = rowspan > 0 ? 1 : 0

      return {
        rowspan,
        colspan,
      }
    }
  }
}
