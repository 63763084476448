/**
 * 信息设置页面路由
 */
import RouterViewLayout from '@layouts/router-view-layout'
import { RouteConfig } from 'vue-router'
import AbroadPage from '@pages/information-setup/abroad'
import SchoolClassType from '@pages/information-setup/school-class-type'
import TermSettingsPage from '@pages/information-setup/term-setting'
import TrialReadingSettingPage from '@pages/information-setup/trial-reading-setting'
import StudentInfoPage from '@pages/information-setup/student-info'
import ConfigManagementPage from '@pages/information-setup/config-management'
import AddClassSettingsPage from '@pages/information-setup/add-class-setting'

const children: RouteConfig[] = []

// 信息设置列表
children.push(
  {
    path: 'abroad',
    name: 'info-setup-abroad',
    component: AbroadPage,
    meta: { title: '出国方向', authCode: '050901', parentAuthCode: '0509' },
  },
  {
    path: 'school-class-type',
    name: 'info-setup-school-class-type',
    component: SchoolClassType,
    meta: { title: '校本班型', authCode: '050902', parentAuthCode: '0509' },
  },
  {
    path: 'term-post-setting',
    name: 'info-setup-term-post',
    component: TermSettingsPage,
    meta: { title: '学期注册设置', authCode: '050903', parentAuthCode: '0509' },
  },
  {
    path: 'trial-reading-setting',
    name: 'info-setup-trial-reading',
    component: TrialReadingSettingPage,
    meta: { title: '试读期限设置', authCode: '050904', parentAuthCode: '0509' },
  },
  {
    path: 'student-info-setting',
    name: 'info-setup-student-info',
    component: StudentInfoPage,
    meta: { title: '学生信息设置', authCode: '050905', parentAuthCode: '0509' },
  },
  {
    path: 'config-management',
    name: 'info-setup-config-management',
    component: ConfigManagementPage,
    meta: { title: '配置管理', authCode: '050906', parentAuthCode: '0509' },
  },
  {
    path: 'add-class-setting',
    name: 'info-setup-add-class',
    component: AddClassSettingsPage,
    meta: { title: '添加班级设置', authCode: '050907', parentAuthCode: '0509' },
  }
)

export default {
  path: '/settings',
  redirect: { name: 'info-setup-abroad' },
  meta: { title: '信息设置', authCode: '0509' },
  component: RouterViewLayout,
  props: { name: 'info-setup' },
  children,
}
