export const messages: Messages = {
  'zh-CN': {
    loading: '加载中...',
  },
  'en-US': {
    loading: '加载中...',
  },
}

export default { messages }
