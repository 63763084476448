export const messages: Messages = {
  'zh-CN': {
    month: '月',
  },
  'en-US': {
    month: '月',
  },
}

export default { messages }
