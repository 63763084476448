import Vue from 'vue'
import StudentDistribution from '../../../types/student-distribution'
import { namespace as BindingNamespace } from 'vuex-class'
import omit from 'lodash.omit'
import { handlers } from 'xuexin-vuex/src/util'
import { Ajax } from 'xuexin-vuex/src/ajax'

export const namespace = '@xuexin-vuex/studentDistribution'
export const types = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',

  FETCH_COUNTTYPES_REQUEST: 'FETCH_COUNTTYPES_REQUEST',
  FETCH_COUNTTYPES_SUCCESS: 'FETCH_COUNTTYPES_SUCCESS',
  FETCH_COUNTTYPES_FAILURE: 'FETCH_COUNTTYPES_FAILURE',

  EXPORT_REQUEST: 'EXPORT_REQUEST',
  EXPORT_SUCCESS: 'EXPORT_SUCCESS',
  EXPORT_FAILURE: 'EXPORT_FAILURE',
}

const state: StudentDistribution.State = {
  list: { data: [] },
  countTypes: { data: [] },
}

const actions: StudentDistribution.Actions = {
  fetch(context, payload) {
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload: omit(payload, 'endDate'),
      api: `post ${__STUDENT_API__}/report/student/distribution`,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
      parameter: omit(payload, 'endDate'),
    })
  },
  fetchCountTypes(context, payload) {
    let type = types.FETCH_COUNTTYPES_REQUEST
    let api = `get ${__STUDENT_API__}/report/student/distribution/countTypes`
    let parameter = ''
    let success = types.FETCH_COUNTTYPES_SUCCESS
    let failure = types.FETCH_COUNTTYPES_FAILURE
    return Ajax(context, { type, payload, api, parameter, success, failure })
  },
  //导出
  export(context, payload) {
    const { type, ...parameter } =
      payload.type == 'student/distribution'
        ? omit(payload, 'endDate')
        : payload
    return Ajax(context, {
      type: types.EXPORT_REQUEST,
      payload,
      api: `post ${__STUDENT_API__}/report/${type}/export`,
      parameter: parameter,
      success: types.EXPORT_SUCCESS,
      failure: types.EXPORT_FAILURE,
    })
  },
}

const mutations: StudentDistribution.Mutations = {
  [types.FETCH_REQUEST](state) {
    handlers.request(state.list, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { result, total }) {
    const item = state.list
    Vue.set(item, 'data', result)
    Vue.set(item.data, 'total', total)
    // handlers.coverToState(state, 'id', result, total)
    handlers.success(item, 'fetching')
  },
  [types.FETCH_FAILURE](state, { message }) {
    handlers.failure(state.list, 'fetching', message)
  },

  [types.FETCH_COUNTTYPES_REQUEST](state) {
    handlers.request(state.countTypes, 'fetching')
  },
  [types.FETCH_COUNTTYPES_SUCCESS](state, { result }) {
    Vue.set(state.countTypes, 'data', result)
    handlers.success(state.countTypes, 'fetching')
  },
  [types.FETCH_COUNTTYPES_FAILURE](state, { message }) {
    handlers.failure(state.countTypes, 'fetching', message)
  },
  [types.EXPORT_REQUEST](state, { payload }) {
    handlers.request(state.list, 'deleting')
  },
  [types.EXPORT_SUCCESS](state, { payload }) {
    handlers.success(state.list, 'deleting')
  },
  [types.EXPORT_FAILURE](state, { payload, message }) {
    handlers.failure(state.list, 'deleting', message)
  },
}

const getters: StudentDistribution.Getters = {
  list() {
    return () => state.list?.data
  },
  listStatus() {
    return () => omit(state.list, ['data'])
  },
  countTypes() {
    return () => state.countTypes?.data
  },
  countTypesStatus() {
    return () => omit(state.countTypes, ['data'])
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
