export enum Gender {
  Male = '0',
  Female = '1',
  Secret = '-1',
}

export const GenderList = [Gender.Male, Gender.Female]

export const messages: Messages = {
  'zh-CN': {
    [Gender.Male]: '男',
    [Gender.Female]: '女',
    [Gender.Secret]: '保密',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [Gender.Male]: '男',
    [Gender.Female]: '女',
    [Gender.Secret]: '保密',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
