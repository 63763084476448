import { Constant } from 'xuexin-vuex'

export type SubjectType = Constant.SubjectType
export const SubjectType = Constant.SubjectType

export const SubjectTypeList = [
  SubjectType.Ordinary,
  SubjectType.LiberalArts,
  SubjectType.Science,
]

export const messages: Messages = {
  'zh-CN': {
    [`${SubjectType.Ordinary}`]: '普通',
    [`${SubjectType.LiberalArts}`]: '文科',
    [`${SubjectType.Science}`]: '理科',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${SubjectType.Ordinary}`]: '普通',
    [`${SubjectType.LiberalArts}`]: '文科',
    [`${SubjectType.Science}`]: '理科',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
