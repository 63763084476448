import { Constant } from 'xuexin-vuex'

export type OperateClass = Constant.OperateType
export const OperateClass = Constant.OperateType

export const OperateClassList = [
  OperateClass.StatusChange /** 学籍异动类 */,
  OperateClass.StudentOther /** 学籍其他类 */,
  OperateClass.StudentStatus /** 学生状态类 */,
  OperateClass.BasicInformation /** 基础信息类 */,
  OperateClass.ApprovalStatus /** 审批情况类 */,
]

export const messages: Messages = {
  'zh-CN': {
    [`${OperateClass.StatusChange}`]: '学籍异动类',
    [`${OperateClass.StudentOther}`]: '学籍其他类',
    [`${OperateClass.StudentStatus}`]: '学生状态类',
    [`${OperateClass.BasicInformation}`]: '基础信息类',
    [`${OperateClass.ApprovalStatus}`]: '审批情况类',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${OperateClass.StatusChange}`]: '学籍异动类',
    [`${OperateClass.StudentOther}`]: '学籍其他类',
    [`${OperateClass.StudentStatus}`]: '学生状态类',
    [`${OperateClass.BasicInformation}`]: '基础信息类',
    [`${OperateClass.ApprovalStatus}`]: '审批情况类',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
