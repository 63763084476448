export const messages: Messages = {
  'zh-CN': {
    save: '保存',
    loading: '加载中...',
    message: {
      'non-null': '请选择',
      IDNO: '',
      IDType: '',
      activity: '',
      birthday: '',
      businessType: '',
      channel: '',
      channelLevelThree: '',
      channelLevelTwo: '',
      classType: '请选择校本班型',
      direction: '',
      diseaseHistory: '',
      enrollScore: '',
      ethnicity: '',
      gender: '',
      grade: '',
      gradeIntention: '',
      height: '',
      homeAddress: '',
      homeAddressInfo: '',
      householdIncome: '',
      medicalBirthCertificate: '',
      name: '',
      nation: '',
      nativePlace: '',
      nativePlaceInfo: '',
      onlyChild: '',
      ownerChild: '',
      parentUnitID: '',
      passportAddress: '',
      passportDate: '',
      passportNO: '',
      phase: '',
      photo: '',
      previousSchool: '',
      referralCode: '',
      registeredPlace: '',
      registeredPlaceInfo: '',
      remark: '',
      researchField: '',
      residenceType: '',
      school: '',
      schoolDepartID: '',
      shoeSize: '',
      singleParent: '',
      staffChild: '',
      staffChild_mother: '',
      staffChild_parent: '',
      statusID: '',
      statusType: '',
      studentMark: '',
      studentType: '',
      studentscore: '',
      studentscoreArea: '',
      studentscoreRemark: '',
      term: '',
      transientStudent: '',
      undefined: '',
      usedName: '',
      weight: '',
    },
  },
  'en-US': {},
}

export default { messages }
