import { namespace as BindNamespace } from 'vuex-class'
import { handlers, coverToState } from 'xuexin-vuex/src/util'
import { Ajax } from 'xuexin-vuex/src/ajax'
import StudentReport from '../../../types/student-report'
import omit from 'lodash.omit'
export enum ActionType {
  Overview = 1,
  Abnormal = 2,
  Monthly = 3,
  Compare = 4,
}

export const namespace = '@xuexin-vuex/student-report'

export const types = {
  FETCH_LIST_REQUEST: 'FETCH_LIST_REQUEST',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
  FETCH_LIST_FAILURE: 'FETCH_LIST_FAILURE',

  EXPORT_REQUEST: 'EXPORT_REQUEST',
  EXPORT_SUCCESS: 'EXPORT_SUCCESS',
  EXPORT_FAILURE: 'EXPORT_FAILURE',
}

const state: StudentReport.State = {
  studentOverview: {
    list: { data: [] },
    items: {},
  },
  studentAbornalChange: {
    list: { data: [] },
    items: {},
  },
  studentCountMonthly: {
    list: { data: [] },
    items: {},
  },
  studentCompareAnalysis: {
    list: { data: [] },
    items: {},
  },
  titles: {
    list: { data: [] },
    items: {},
  },
}

const actions: StudentReport.Actions = {
  /** 查询列表 */
  fetchList(context, payload) {
    const { actionType, ...parameter } = payload
    let api = ``
    let type = types.FETCH_LIST_REQUEST
    let success = types.FETCH_LIST_SUCCESS
    let failure = types.FETCH_LIST_FAILURE

    switch (actionType) {
      case ActionType.Overview:
        api = `post ${__STUDENT_API__}/report/studentView`
        break
      case ActionType.Abnormal:
        api = `post ${__STUDENT_API__}/report/changes`
        break
      case ActionType.Monthly:
        api = `post ${__STUDENT_API__}/report/month/studentCount`
        break
      case ActionType.Compare:
        api = `post ${__STUDENT_API__}/report/student/enrollment`
        break
      default:
        api = `post ${__STUDENT_API__}/report/studentView`
        break
    }
    return Ajax(context, { type, payload, api, parameter, success, failure })
  },
  /** 导出 */
  export(context, payload) {
    const { actionType, ...parameter } = payload
    let api = ``
    let type = types.EXPORT_REQUEST
    let success = types.EXPORT_SUCCESS
    let failure = types.EXPORT_FAILURE

    switch (actionType) {
      case ActionType.Overview:
        api = `post ${__STUDENT_API__}/report/studentView/export`
        break
      case ActionType.Abnormal:
        api = `post ${__STUDENT_API__}/report/changes/excelExport`
        break
      case ActionType.Monthly:
        api = `post ${__STUDENT_API__}/report/month/studentCount/export`
        break
      case ActionType.Compare:
        api = `post ${__STUDENT_API__}/report/student/enrollment/export`
        break
      default:
        api = `post ${__STUDENT_API__}/report/studentView/export`
        break
    }

    return Ajax(context, { type, payload, api, parameter, success, failure })
  },
}

const mutations: StudentReport.Mutations = {
  [types.FETCH_LIST_REQUEST](state, { payload }) {
    handlers.request(state[payload.type!].list, 'fetching')
  },
  [types.FETCH_LIST_SUCCESS](state, { payload, result, total }) {
    if (payload.type === 'studentCountMonthly') {
      handlers.coverToState(state[payload.type!], 'id', result?.dataList, total)
      handlers.coverToState(
        state.titles,
        'month',
        result?.monthTitleList,
        total
      )
    } else {
      handlers.coverToState(state[payload.type!], 'id', result, total)
    }

    handlers.success(state[payload.type!].list, 'fetching')
  },
  [types.FETCH_LIST_FAILURE](state, { payload, message }) {
    handlers.failure(state[payload.type!].list, 'fetching', message)
  },
  /** 导出 */
  [types.EXPORT_REQUEST](state, { payload }) {
    handlers.request(state[payload.type!].list, 'updating')
  },
  [types.EXPORT_SUCCESS](state, { payload }) {
    handlers.success(state[payload.type!].list, 'updating')
  },
  [types.EXPORT_FAILURE](state, { payload, message }) {
    handlers.failure(state[payload.type!].list, 'updating', message)
  },
}

const getters: StudentReport.Getters = {
  state(state) {
    return name => state[name] || { list: { data: [] }, items: {} }
  },
  item(state, getters) {
    return (name, id) => {
      return getters.state(name).items[`${id}`]?.data
    }
  },
  list(state, getters) {
    return name => {
      return state[name].list.data.map(id => {
        const item = getters.item(name, id)
        return Object.assign({}, item)
      })
    }
  },
  listStatus(state, getters) {
    return name => omit(getters.state(name).list, ['data'])
  },
}

export const { State, Action, Mutation, Getter } = BindNamespace(namespace)
export const Module = { state, actions, mutations, getters, namespaced: true }
export default { State, Action, Mutation, Getter, namespace, types, Module }
