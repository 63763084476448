import { Constant } from 'xuexin-vuex'

export type Education = Constant.Education
export const Education = Constant.Education

export const EducationList = [
  Education.PhD,
  Education.Postgraduate,
  Education.Undergraduate,
  Education.College,
  Education.HighSchool,
  Education.MiddleSchool,
  Education.PrimarySchool,
]

export const messages: Messages = {
  'zh-CN': {
    [Education.PhD]: '博士',
    [Education.Postgraduate]: '研究生',
    [Education.Undergraduate]: '本科',
    [Education.College]: '大专',
    [Education.HighSchool]: '高中',
    [Education.MiddleSchool]: '初中',
    [Education.PrimarySchool]: '小学',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [Education.PhD]: '博士',
    [Education.Postgraduate]: '研究生',
    [Education.Undergraduate]: '本科',
    [Education.College]: '大专',
    [Education.HighSchool]: '高中',
    [Education.MiddleSchool]: '初中',
    [Education.PrimarySchool]: '小学',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
