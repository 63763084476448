export const messages: Messages = {
  'zh-CN': {
    title: '发短信',
    label: {
      name: '学生姓名',
      content: '短信内容',
      sendDate: '定时发送',
      signature: '短信签名',
    },
    placeholder: {
      content: '最多可输入110个字',
      sendDate: '定时发送',
      signature: '短信签名',
      display: '共计',
      'display-name': '位学生',
    },
    message: {
      content: '请输入短信内容',
      success: '发送成功',
      none: '暂无',
      loading: '加载中……',
      warning: '暂未查询到人员信息',
      noStudent: '暂无学生信息，无法发送短信',
    },
  },
  'en-US': {},
}
export default { messages }
