export const messages: Messages = {
  'zh-CN': {
    title: '导入记录',
    column: {
      index: '序号',
      date: '导入时间',
      type: '导入类型',
      successCount: '成功条数',
      failureCount: '失败条数',
      operator: '操作人',
    },
    noDataText: '暂无数据',
  },
  'en-US': {
    title: '导入记录',
    column: {
      index: '序号',
      date: '导入时间',
      type: '导入类型',
      successCount: '成功条数',
      failureCount: '失败条数',
      operator: '操作人',
    },
    noDataText: '暂无数据',
  },
}

export default { messages }
