import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Button, Checkbox, Dropdown, DropdownMenu } from 'view-design'
import CheckboxGroup from '@components/app-checkbox-group'

export type CheckboxProp = {
  label: string
  value: string
  style: Record<string, any>
  disabled: boolean
}

@Component({ name: 'SearchDropdown' })
export default class SearchDropdown extends Vue {
  @Prop({ type: String, required: true }) private readonly label!: string
  @Prop({ type: Array, default: () => [] })
  private readonly data!: CheckboxProp[]
  @Prop({ type: Array, default: () => [] })
  private readonly selection!: string[]

  private readonly prefixCls = 'search-dropdown'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get props() {
    return {
      trigger: 'click',
      // placement: 'bottom-end',
      'transfer-class-name': `${this.prefixCls}_dropdown`,
    }
  }
  get checkAll() {
    return (
      this.selection.length !== 0 &&
      this.selection.length === this.currentData.length
    )
  }

  get indeterminate() {
    if (this.checkAll === true) {
      return false
    } else {
      if (
        this.selection.length ==
        this.currentData.filter(item => item.disabled === true).length
      ) {
        return false
      } else {
        return true
      }
    }
  }

  get currentData() {
    return this.data.filter(i => {
      return i.value !== 'simpleDate'
    })
  }

  render(createElement: CreateElement) {
    const ButtonElement = createElement(
      Button,
      {
        props: { type: 'info' },
      },
      [this.label]
    )

    const currentData = this.data.filter(i => {
      return i.value !== 'simpleDate'
    })

    // this.$set(this.$data, 'currentData', currentData)

    const MenusElement = createElement(DropdownMenu, { slot: 'list' }, [
      createElement('div', { class: 'check-all' }, [
        createElement(
          Checkbox,
          {
            props: {
              indeterminate: this.indeterminate,
            },
            attrs: { value: this.checkAll },
            on: {
              'on-change': this.handleCheckAllChange,
            },
          },
          ['全选']
        ),
      ]),
      createElement(CheckboxGroup, {
        props: { data: currentData },
        attrs: { value: this.selection },
        on: { 'on-change': this.handleChange },
      }),
    ])

    return createElement(
      Dropdown,
      {
        class: this.classes,
        props: this.props,
      },
      [ButtonElement, MenusElement]
    )
  }

  private handleChange(selection: string[]) {
    this.$emit('on-change', selection)
  }
  private handleCheckAllChange(checked: boolean) {
    const filtered = checked
      ? this.currentData
      : this.currentData.filter(item => item.disabled === true)

    const selection = filtered.map(item => item.value)
    this.$emit('on-change', selection)
  }
}
