import Vue, { CreateElement } from 'vue'
import { Component } from 'vue-property-decorator'
import { Constant ,Session} from 'xuexin-vuex'
import { Spin } from 'view-design'
import { Watch } from 'vue-property-decorator'
import { decryptFromBase64 } from '@util'

const i18n = {
  messages: {
    'zh-CN': {
      loading: '正在准备数据，请稍候...',
    },
    'en-US': {},
  },
}
const components = { Spin }


export interface StatusType extends Partial<Session.Getter.Status> {
  loginUrl: string
}


@Component({ name: 'AuthPage', components, i18n })
export default class AuthPage extends Vue {
  @Session.Action('fetch') private readonly fetchUser!: Session.Action.Fetch
  @Session.Getter('status') private readonly status!: StatusType 
  @Session.Getter('user') private readonly user!: Session.Getter.User
  private readonly prefixCls = 'auth-page'

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get isSigned(): boolean {
    return this.user !== void 0 && this.user !== null
  }

  get fetching() {
    return this.status.fetching === true
  }

  @Watch('fetching')
  watchFetching(fetching: boolean, previous: boolean) {


    if (fetching === false && previous === true) {
      if (this.status.fetchingError !== null || this.isSigned === false) {
        /** 获取用户信息失败，跳转到登录页面。 */
        return this.$nextTick(this.forwardToSign)
        // window.location.href = this.loginUrl
      }
      return this.$nextTick(this.forwardToView)
    }
  }

  created() {}

  render(createElement: CreateElement) {
    return createElement(
      'div',
      {
        class: this.classes,
      },
      [
        createElement(
          'Spin',
          {
            props: { fix: true },
          },
          [createElement('span', {}, [`${this.$t('loading')}`])]
        ),
      ]
    )
  }

  mounted() {
    if (this.isSigned === true) {
      return this.forwardToSign()
    }

    this.fetchUser({ platform: Constant.Platform.Education })
  }

  private forwardToSign() {
    var loginUrl = this.status?.loginUrl || ''
    if(loginUrl === ''){
      this.$router.replace({ name: 'login', query: this.$route.query })
    }else{
      window.location.href = loginUrl
    }
  }

  private forwardToView() {
    let url = '/'

    // link 不为空时，解码 url 地址
    if (typeof this.$route.query.link === 'string') {
      url = decryptFromBase64(this.$route.query.link, 'link-url')
    }

    // 解析 url 地址，获取需要跳转的 route
    const { route, location } = this.$router.resolve(url)
    if (route.name === void 0 || route.name === null) {
      this.$router.replace({ path: '/' })
    } else {
      this.$router.replace(location)
    }
  }
}
