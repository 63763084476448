/** 插班生人数统计 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import StudentInsertReport from '@pages/statistic-reports/student-insert'

const children: RouteConfig[] = []
/** 插班生人数统计 */
children.push({
  path: '/reports/student-insert',
  name: 'student-insert-report',
  component: StudentInsertReport,
  meta: { ignore: true, authCode: '050712', parentAuthCode: '0507' },
})
export default {
  path: '/reports',
  name: 'student-insert',
  redirect: { name: 'student-insert-report' },
  meta: { title: '插班生人数统计', authCode: '050712' },
  component: RouterViewLayout,
  props: { name: 'student-insert' },
  children,
}
