import { Constant } from 'xuexin-vuex'

export type GraduateDirection = Constant.GraduateDirection
export const GraduateDirection = Constant.GraduateDirection
export const GraduateDirectionList = [
  GraduateDirection.Qingbei,
  GraduateDirection.KeyUniversity,
  GraduateDirection.Undergraduate,
  GraduateDirection.StudyAbroad,
  GraduateDirection.VocationalCollege,
  GraduateDirection.Other,
]

export const messages: Messages = {
  'zh-CN': {
    [GraduateDirection.Qingbei]: '清北',
    [GraduateDirection.KeyUniversity]: '一本',
    [GraduateDirection.Undergraduate]: '本科',
    [GraduateDirection.StudyAbroad]: '出国',
    [GraduateDirection.VocationalCollege]: '高职高专',
    [GraduateDirection.Other]: '其它',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [GraduateDirection.Qingbei]: '清北',
    [GraduateDirection.KeyUniversity]: '一本',
    [GraduateDirection.Undergraduate]: '本科',
    [GraduateDirection.StudyAbroad]: '出国',
    [GraduateDirection.VocationalCollege]: '高职高专',
    [GraduateDirection.Other]: '其它',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
