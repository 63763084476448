






































































































































































import Vue from 'vue'
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Student, School, Session, Constant } from 'xuexin-vuex'
import { Button, TableColumn } from 'view-design'
import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import GradeModal from '@business-components/grade-modal'
import GlassModal from '@business-components/glass-modal'
import SendMessageModal from '@business-components/send-message-modal'
import { convertSearchParameter } from '@util'

import Export from '@store/modules/export'
import ExportTyping from '../../../types/export'
import PageTyping from '../../../types/page'

const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  AppTable,
  AppPagination,
  GradeModal,
  GlassModal,
  SendMessageModal,
}

const i18n = {
  messages: {
    'zh-CN': {
      text: {
        unregistered: '未注册',
        registered: '已注册',
        null: '-',
        undefined: '-',
        index: '序号',
      },
      button: {
        export: '导出',
        sms: '发短信',
        smsRecord: '短信发送记录',
        registration: '注册',
      },
      message: {
        registrations: '您确定要注册选中的学生吗？',
        registration: '您确定要注册该学生吗？',
        noDataText: '暂无数据',
        noParameter: '请先选择搜索条件',
        noSearch: '请先点击搜索',
        noSchoolDepart: '最少选择到学部',
        success: '注册成功',
      },
    },
    'en-US': {},
  },
}

type Flat = Record<string, Student.Entity>

@Component({ name: 'RegistrationPage', components, i18n })
export default class RegistrationPage extends Mixins(PagePropsMixins) {
  /** 学校模块 */
  @School.Action('fetch')
  private readonly fetchSchoolStatus!: School.Action.Fetch
  @School.Getter('item')
  private readonly getSchool!: School.Getter.Item
  /** session模块 */
  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  @Student.Getter('list') private readonly studentList!: Student.Getter.List
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus

  @Student.Action('fetchList')
  private readonly fetchList!: Student.Action.FetchList
  @Student.Action('update')
  private readonly update!: Student.Action.Update

  @Export.Action('exportFile')
  private readonly fetchExportUrl!: ExportTyping.Action.ExportFile
  @Export.Getter('item')
  private readonly getExportUrl!: ExportTyping.Getter.Item
  @Export.Getter('listStatus')
  private readonly getExportStatus!: ExportTyping.Getter.ListStatus

  private readonly prefixCls = 'registration-page'
  private loading = false
  private page = 1
  private pageSize = 50
  private operable = true /** 是否大于等于当前学期，可否操作 */
  private selection: Student.Entity[] = []
  private flat: Flat = {}
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private searchParameter: Partial<PageTyping.SearchParameter> = {}
  private modal: { name: string | null; data: any } = { name: null, data: {} }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get currentTermID() {
    return this.getUser?.extraMap.currentTermID || 0
  }
  /** 获取机构id */
  get organID() {
    return this.getUser?.organID
  }

  get disabledRegistration(): boolean {
    return this.selection.length === 0
  }

  get disableRegistered() {
    return this.getSchool(this.searchParameter.unitID!)?.allowRegist
  }

  get valid() {
    // const { schoolDepartID } = this.searchParameter
    // return (
    //   Boolean(schoolDepartID) ||
    //   schoolDepartID === 0 ||
    //   Boolean(this.parameter.schoolDepartID) ||
    //   this.parameter.schoolDepartID === 0
    // )
    return true
  }

  get noDataText() {
    // if (
    //   Boolean(this.parameter.schoolDepartID) === false ||
    //   Boolean(this.searchParameter.schoolDepartID) === false
    // ) {
    //   return this.$t('message.noSearch')
    // }
    return this.$t('message.noDataText')
  }

  get tableColumn() {
    if (this.valid !== true) {
      return []
    }
    const columns: TableColumn[] = this.columns.map(item => {
      const column: TableColumn = Object.assign({}, item)
      switch (column.key) {
        case 'num': // 序号
          column.render = (createElement, parameter) => {
            const text =
              (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
            return createElement!('span', `${text}`)
          }
          break
        case 'registStatus':
          column.slot = 'registStatus'
          break
        case 'gradeName':
        case 'className':
          column.width = 240
          break
        case 'operate':
          column.width = 120
          column.slot = 'operate'
          column.fixed = 'right'
          column.ellipsis = false
          break
        case 'registDate':
          column.slot = 'registDate'
          column.width = 200
          break
        default:
          break
      }
      return column
    })
    columns.unshift(
      {
        type: 'selection',
        width: 60,
        fixed: 'left',
        align: 'center',
      },
      {
        title: `${this.$t('text.index')}`,
        render: (createElement, parameter) => {
          const text =
            (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
          return createElement!('span', `${text}`)
        },
        width: 80,
        fixed: 'left',
        align: 'center',
      }
    )
    return columns
  }

  get tableData() {
    if (this.valid !== true) {
      return []
    }
    return this.studentList.map(item => {
      /** 还原当前选中的学生 */
      if (this.flat[item.studentID] !== void 0) {
        return Object.assign({}, item, { _checked: true })
      }
      return item
    })
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get total() {
    if (this.valid !== true) {
      return 0
    }
    return this.listStatus.total || 0
  }
  get disableButton() {
    return this.total === 0
  }

  get authCode() {
    return this.$route.meta.authCode
  }

  get exportUrl() {
    return this.getExportUrl(`${this.searchParameter}`)
  }

  @Watch('parameter.unitID')
  private watchSchoolDepartID(value: number, oldValue: unknown) {
    if (oldValue === null || oldValue === undefined) {
      this.searchParameter = { ...this.parameter }
      this.fetch()
    }
  }
  @Watch('parameter.termID')
  private watchValue(value: number): void {
    this.operable = value >= this.currentTermID
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.loading === true) {
        this.$set(this.$data, 'loading', false)
      }
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }
  /** 监听更新班级状态 */
  @Watch('listStatus.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    /** 更新请求完成 */
    if (updating === false && previous === true) {
      /** 更新失败，提示错误消息。 */
      if (this.listStatus.updatingError !== null) {
        this.$Message.error(this.listStatus.updatingError)
        return this.$Modal.remove()
      }
      this.$Message.success(this.$t('message.success'))
      this.$Modal.remove()
      return this.fetch()
    }
  }

  /** 监听已选学生 */
  @Watch('selection', { immediate: true })
  private watchSelection(selection: Student.Entity[]) {
    this.$set(
      this.$data,
      'flat',
      selection.reduce((flat: Flat, item) => {
        return (flat[item.studentID] = item), flat
      }, {})
    )
  }

  @Watch('getExportStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (!creating && previous) {
      if (this.getExportStatus.creatingError !== null) {
        this.$Message.error(this.getExportStatus.creatingError)
        return
      }
      location.href = this.exportUrl.url
    }
  }

  private selectionList(selection: Student.Entity[]): void {
    this.$set(this.$data, 'selection', selection)
  }

  private handleClick(viewType: string, student: Student.Entity) {
    // if (Boolean(this.parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(`${this.$t('message.noSchoolDepart')}`)
    // }
    switch (viewType) {
      case 'all-registered':
        this.$Modal.confirm({
          title: this.$t('button.registration') as string,
          content: this.$t('message.registrations') as string,
          loading: true,
          onOk: () => {
            return this.update({
              xuexinIDs: this.selection.map(item => item.studentID),
              unitID: this.searchParameter.unitID,
              termID: this.searchParameter.termID,
              actionType: Constant.ActionType.Register,
            })
          },
        })
        break
      case 'registered':
        this.$Modal.confirm({
          title: this.$t('button.registration') as string,
          content: this.$t('message.registration') as string,
          loading: true,
          onOk: () => {
            return this.update({
              xuexinIDs: [student.studentID],
              unitID: this.searchParameter.unitID,
              termID: this.searchParameter.termID,
              actionType: Constant.ActionType.Register,
            })
          },
        })
        break
      case 'grade':
        this.$set(this.$data, 'modal', {
          name: 'grade-modal',
          data: {
            viewType: 'view',
            parameter: this.searchParameter,
            unitGradeID: student.unitGradeID,
          },
        })
        break
      case 'glass':
        this.$set(this.$data, 'modal', {
          name: 'glass-modal',
          data: {
            viewType: 'view',
            parameter: this.searchParameter,
            classID: student.classID,
          },
        })
        break
      case 'sms':
        this.$set(this.$data, 'modal', {
          name: 'sms-modal',
          data: {
            list: (this.selection.length === 0
              ? this.studentList
              : this.selection
            ).map(item => {
              return {
                id: item.studentID,
                name: item.studentName,
                mobile: item.parentMobile,
              }
            }),
            authCode: this.authCode,
            searches: this.searchParameter,
            actionType: Constant.ActionType.Register,
            pageType: Constant.PageType.Register,
          },
        })
        break
      case 'sms-record' /** 短信发送记录 */:
        this.$router.push({
          name: 'semester-registration-sms-record',
          query: this.parameter,
        })
        break
      case 'view-student': // 查看学生信息
        this.$router.push({
          name: 'semester-registration-student-detail',
          params: {
            xuexinID: student.studentID || '',
            unitID: `${this.searchParameter.unitID || ''}`,
            termID: `${this.searchParameter.termID || ''}`,
            parentUnitID: `${student.parentUnitID || ''}`,
          },
        })
        break
      case 'export':
        this.fetchExportUrl({
          ids: this.selection.map(item => item.studentID),
          searches: this.convertParameter(),
          columns: this.columns.map(item => item.key || ''),
          actionType: Constant.ActionType.Register,
        })
        break
    }
  }

  private convertParameter() {
    return convertSearchParameter(this.parameter)
  }

  private fetch() {
    if (
      this.valid !== true /** 参数无效 */ ||
      this.fetching === true /** 正在请求数据 */
    ) {
      /** 以上情况不发起请求 */
      return void 0
    }

    this.fetchList({
      searches: this.convertParameter(),
      columns: this.columns.map(item => item.key || ''),
      page: this.page,
      pageSize: this.pageSize,
      actionType: Constant.ActionType.Register,
    })
    this.fetchSchoolStatus({
      organID: this.organID,
      termID: this.searchParameter.termID,
      unitID: this.searchParameter.unitID,
    })
  }
  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    // if (Boolean(parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(`${this.$t('message.noSchoolDepart')}`)
    // }
    this.$set(this.$data, 'page', 1)
    this.$set(this.$data, 'searchParameter', parameter)
    this.fetch()
  }

  /** 已选学生发生改变 */
  private handleSelectionChange(selection: Student.Entity[]) {
    this.$set(this.$data, 'selection', selection)
  }

  /** 页码发生变化 */
  private handlePageChange(page: number) {
    /**
     * 修改本地 `page` 属性值，清空已选数据，并重新请求数据。
     */
    this.$set(this.$data, 'page', page)
    this.$set(this.$data, 'selection', [])
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number) {
    /**
     * 修改本地 `pageSize` 属性值，若当前 `page` 为 1，则手动请求数据。
     */
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }

  /** 窗口关闭事件处理函数 */
  private handleModalHidden() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }

  /** 窗口功能完成事件处理函数 */
  private handleModalFinish() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
    this.fetch()
    this.$set(this.$data, 'searchParameter', this.parameter)
  }
}
