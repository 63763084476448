export const messages: Messages = {
  'zh-CN': {
    title: '试读',
    loading: '加载中……',
    label: {
      name: '学生姓名',
      date: '试读日期',
    },
    text: {
      date: '选择日期',
      to: '至',
      success: '添加试读成功',
      validDate: '试读开始日期不能为空',
    },
  },
  'en-US': {},
}

export default { messages }
