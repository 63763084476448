/**
 * 校际互转学生页面路由
 */
import RouterViewLayout from '@layouts/router-view-layout'
import { RouteConfig } from 'vue-router'
import InnerTransferPage from '@pages/inner-transfer'
import StudentDetailPage from '@pages/student-detail'
import SmsRecordPage from '@pages/sms-record'
import { Constant } from 'xuexin-vuex'

const children: RouteConfig[] = []

// 校际互转学生列表
children.push({
  path: '/students/inner-transfer',
  name: 'inner-transfer',
  component: InnerTransferPage,
  meta: { ignore: true, authCode: '050606', parentAuthCode: '0506' },
})

/** 学生详情 */
children.push({
  path: '/students/inner-transfer/:xuexinID/:unitID/:termID/:tranferStudentID',
  name: 'inner-transfer-student-detail',
  component: StudentDetailPage,
  meta: { ignore: true, authCode: '050606', parentAuthCode: '0506' },
  props: { actionType: 'detail' },
})

/** 短信发送记录 */
children.push({
  path: 'sms-record',
  name: 'inner-transfer-sms-record',
  component: SmsRecordPage,
  meta: { title: '短信发送记录', authCode: '050606', parentAuthCode: '0506' },
  props: {
    actionType: Constant.ActionType.InnerTransfer,
  },
})

export default {
  path: '/students/inner-transfer',
  redirect: { name: 'inner-transfer' },
  meta: { title: '校际互转学生', authCode: '050606' },
  component: RouterViewLayout,
  props: { name: 'inner-transfer' },
  children,
}
