
































import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import { Button, Icon, Form, FormItem, Input } from 'view-design'
import { EnableStatusRadio } from '@business-components/enable-status'
import { Abroad } from 'xuexin-vuex'
import i18n from '../../i18n'
const components = {
  AppModal,
  Button,
  Icon,
  Form,
  FormItem,
  Input,
  EnableStatusRadio,
}

@Component({ name: 'AddEditModal', components, i18n })
export default class AddEditModal extends Vue {
  @Ref('form') private readonly form!: Form
  @Model('input', { type: Boolean }) private value!: boolean
  @Prop({ type: Number, default: -1 }) private abroadID?: number
  @Prop({ type: Number, default: 1 }) private enable?: number
  @Prop({ type: String }) private name?: string
  @Abroad.Getter('itemStatus')
  private readonly itemStatus!: Abroad.Getter.ItemStatus
  @Abroad.Getter('listStatus')
  private readonly listStatus!: Abroad.Getter.ListStatus
  @Abroad.Action('create') private readonly create!: Abroad.Action.Create
  @Abroad.Action('update') private readonly update!: Abroad.Action.Update

  private readonly prefixCls = 'add-edit-view'
  private title = ''
  private visible = this.value
  private verifying = false
  private loading = true

  private model: any = {
    name: '',
    enable: 1,
  }

  //验证规则
  get rules() {
    return {
      name: {
        required: true,
        validator: this.validName,
        trigger: 'blur',
      },
    }
  }
  //获取当前出国方向的状态
  get getItemStatus() {
    return this.itemStatus(this.abroadID === void 0 ? -1 : this.abroadID)
  }

  @Watch('value', { immediate: true })
  private watchVisible(value: boolean) {
    this.visible = value
    if (value) {
      if (this.abroadID === -1) {
        this.title = this.$t('button-text.create') as 'create'
      } else {
        this.title = this.$t('button-text.edit') as 'edit'
        this.model.name = this.name
        this.model.enable = this.enable
      }
    }
  }
  //根据listStatus的数据状态，判断创建事件是否完成
  @Watch('listStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (this.value && !creating && previous) {
      if (this.listStatus.creatingError !== null) {
        this.$Message.error(this.listStatus.creatingError)
        return this.stopAction()
      }
      this.$emit('input', false)
      this.$emit('handle-close', false)
      this.$Message.success(this.$t('message.create-success'))
      return this.stopAction()
    }
  }
  //根据itemStatus数据状态，判断更新事件是否完成
  @Watch('getItemStatus.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (this.value && !updating && previous) {
      if (this.getItemStatus.updatingError !== null) {
        this.$Message.error(this.getItemStatus.updatingError)
        return this.stopAction()
      }
      this.$emit('input', false)
      this.$emit('handle-close', false)
      this.$Message.success(this.$t('message.edit-success'))
      return this.stopAction()
    }
  }
  //弹窗状态重置事件
  private stopAction(): void {
    this.loading = false
    this.verifying = false
    this.$nextTick(() => (this.loading = true))
  }
  //确定按钮事件
  private handleOK(): void {
    this.form.validate((valid?: boolean) => {
      if (valid) {
        this.verifying = true
        let that = this
        if (that.abroadID === -1) {
          that.create({
            abroadName: that.model.name,
            enable: that.model.enable,
          })
        } else {
          that.update({
            abroadName: that.model.name,
            enable: that.model.enable,
            abroadID: that.abroadID,
          })
        }
      } else {
        return this.stopAction()
      }
    })
  }
  //弹窗状态发生变化事件
  private handleVisibleChange(visible: boolean): void {
    this.$emit('input', visible)
  }
  //关闭按钮事件
  private handleHidden(): void {
    this.form.resetFields()
    this.$set(this.$data, 'model', {})
    this.stopAction()
  }
  //出国方向名称验证方法
  private validName(rule: string, value: string, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('message.abroad-cannot-empty') as string))
    } else {
      callback()
    }
  }
}
