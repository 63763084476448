import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'

type ViewType = 'text'

const IDTypeList = [
  { value: 1, label: '居民身份证' },
  { value: 2, label: '香港特区护照/身份证明' },
  { value: 3, label: '澳门特区护照/身份证明' },
  { value: 4, label: '台湾居民来往大陆通行证' },
  { value: 5, label: '境外永久居住证' },
  { value: 6, label: '护照' },
  { value: 7, label: '港澳居民居住证' },
  { value: 8, label: '台湾居民居住证' },
]

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `IDType${upperFirst(type)}`
  const components = {}
  @Component({ name, components, inheritAttrs: false })
  class IDTypeComponent extends Vue {
    @Prop({ type: String, default: 'CN' }) private readonly nation!: string
    @Prop({ type: Number }) private readonly filter!: number
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType!: ViewType

    get classes() {
      return {
        [`idType-${type}`]: this.viewType === void 0,
        [`idType-${this.viewType}`]: this.viewType !== void 0,
      }
    }

    get props() {
      const props: Record<string, any> = {}
      if (this.viewType === 'text') {
        return props
      }

      switch (type) {
        case ComponentType.Select:
          props.data = this.list
          break
        default:
          break
      }
      return props
    }

    get list() {
      return this.extra.concat(
        IDTypeList.filter(item => {
          switch (this.nation) {
            case 'CN':
              return item.value == 1 || item.value == 6
            case 'HK':
              return item.value == 2 || item.value == 7
            case 'MO':
              return item.value == 3 || item.value == 7
            case 'TW':
              return item.value == 4 || item.value == 8
            case '':
              return true
            default:
              return item.value == 5 || item.value == 6
          }
        }).filter(i => !this.filter || !(this.filter == i.value))
      )
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        tag = 'span'
        const item = this.list.find(
          item => item.value === Number(this.$attrs.value)
        )
        const text = item !== void 0 ? item.label : '-'
        children = [text]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return IDTypeComponent
}

export const IDInfoSelect = createComponent(AppSelect, ComponentType.Select)
