export const EthnicityList = new Array(57)
  .fill(1)
  .map((item, index) => index + 1)

export const messages: Messages = {
  'zh-CN': {
    1: '阿昌族',
    2: '白族',
    3: '保安族',
    4: '布朗族',
    5: '布依族',
    6: '藏族',
    7: '朝鲜族',
    8: '达斡尔族',
    9: '傣族',
    10: '德昂族',
    11: '东乡族',
    12: '侗族',
    13: '独龙族',
    14: '俄罗斯族',
    15: '鄂伦春族',
    16: '鄂温克族',
    17: '高山族',
    18: '哈尼族',
    19: '哈萨克族',
    20: '汉族',
    21: '赫哲族 ',
    22: '回族',
    23: '基诺族 ',
    24: '京族',
    25: '景颇族 ',
    26: '柯尔克孜族',
    27: '拉祜族',
    28: '黎族',
    29: '珞巴族',
    30: '满族',
    31: '毛南族',
    32: '门巴族',
    33: '蒙古族',
    34: '苗族',
    35: '仫佬族 ',
    36: '纳西族 ',
    37: '怒族',
    38: '普米族 ',
    39: '羌族',
    40: '撒拉族 ',
    41: '畲族',
    42: '水族',
    43: '僳僳族 ',
    44: '塔吉克族',
    45: '塔塔尔族',
    46: '土家族',
    47: '土族',
    48: '佤族',
    49: '维吾尔族',
    50: '乌孜别克族',
    51: '锡伯族',
    52: '瑶族',
    53: '彝族',
    54: '仡佬族',
    55: '裕固族',
    56: '壮族',
    57: '其他',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    1: '阿昌族',
    2: '白族',
    3: '保安族',
    4: '布朗族',
    5: '布依族',
    6: '藏族',
    7: '朝鲜族',
    8: '达斡尔族',
    9: '傣族',
    10: '德昂族',
    11: '东乡族',
    12: '侗族',
    13: '独龙族',
    14: '俄罗斯族',
    15: '鄂伦春族',
    16: '鄂温克族',
    17: '高山族',
    18: '哈尼族',
    19: '哈萨克族',
    20: '汉族',
    21: '赫哲族 ',
    22: '回族',
    23: '基诺族 ',
    24: '京族',
    25: '景颇族 ',
    26: '柯尔克孜族',
    27: '拉祜族',
    28: '黎族',
    29: '珞巴族',
    30: '满族',
    31: '毛南族',
    32: '门巴族',
    33: '蒙古族',
    34: '苗族',
    35: '仫佬族 ',
    36: '纳西族 ',
    37: '怒族',
    38: '普米族 ',
    39: '羌族',
    40: '撒拉族 ',
    41: '畲族',
    42: '水族',
    43: '僳僳族 ',
    44: '塔吉克族',
    45: '塔塔尔族',
    46: '土家族',
    47: '土族',
    48: '佤族',
    49: '维吾尔族',
    50: '乌孜别克族',
    51: '锡伯族',
    52: '瑶族',
    53: '彝族',
    54: '仡佬族',
    55: '裕固族',
    56: '壮族',
    57: '其他',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
