import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppRadioGroup from '@components/app-radio-group'
import AppCheckboxGroup from '@components/app-checkbox-group'
import AppSelect from '@components/app-select'
import { Checkbox } from 'view-design'
import i18n from './i18n'
import { isArray } from 'lodash'

type ViewType = 'text'
const prefixCls = `FreshStudent`

function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `FreshStudent${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class FreshStudentComponent extends Vue {
    @Prop({ type: Number, default: null })
    private readonly phaseID!: any
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType!: ViewType

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }

    get locale(): Locale {
      return this.$i18n.locale as Locale
    }

    get list() {
      const arr = [
        { label: '是', value: 1 },
        { label: '否', value: 0 },
      ]
      return this.extra.concat(
        arr.map(value => {
          return { label: value.label, value: value.value }
        })
      )
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
        case ComponentType.RadioGroup:
          props.data = this.list
          break
      }
      return props
    }

    @Watch('phaseID', { immediate: true })
    private watchPhase(phaseID: number, prev: number) {
      if (phaseID && phaseID !== 4 && phaseID !== 3) {
        this.$emit('input', true)
      }
    }

    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (type === ComponentType.Text || this.viewType === 'text') {
        children = [this.$attrs.value ? '是' : '否']

        return createElement(
          'span',
          {
            class: this.classes,
            props: this.props,
            attrs: Object.assign({}, this.$attrs),
            on: this.$listeners,
          },
          children
        )
      }

      /** 以复选框方式显示 */
      if (type === ComponentType.CheckboxGroup) {
        children = ['是']
      }

      return createElement(
        component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return FreshStudentComponent
}

export const FreshStudentRadioGroup = createComponent(
  AppRadioGroup,
  ComponentType.RadioGroup
)
export const FreshStudentCheckbox = createComponent(
  Checkbox,
  ComponentType.CheckboxGroup
)
export const FreshStudentSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
export const FreshStudentText = createComponent('span', ComponentType.Text)
