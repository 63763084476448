import { Constant } from 'xuexin-vuex'

export type StudentStatus = Constant.StudentStatus
export const StudentStatus = Constant.StudentStatus

export const StudentStatusList = [
  StudentStatus.Normal,
  StudentStatus.Suspension,
  StudentStatus.EmptyStatus,
]

export const messages: Messages = {
  'zh-CN': {
    [`${StudentStatus.Normal}`]: '正常',
    [`${StudentStatus.Suspension}`]: '转学',
    [`${StudentStatus.EmptyStatus}`]: '取消录取',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${StudentStatus.Normal}`]: '正常',
    [`${StudentStatus.Suspension}`]: '转学',
    [`${StudentStatus.EmptyStatus}`]: '取消录取',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
