import { SchoolClassType } from 'types'
import { namespace as BindingNamespace } from 'vuex-class'
import Ajax from 'xuexin-vuex/src/ajax'
import Api from 'xuexin-vuex/src/api'
import omit from 'lodash.omit'
import {
  handlers,
  getListState,
  mergeToCompositeState,
} from 'xuexin-vuex/src/util'
import filterData from '../../../filterData.json'
const fileterMap: Record<string | number, boolean> = filterData.unitIDs

function getListKey(parameter: SchoolClassType.Parameter) {
  const {
    parentUnitID = -1,
    unitID = -1,
    schoolDepartID = -1,
    phaseID = -1,
    schoolSystemID = -1,
    manageTypeID = -1,
    classTypeID = -1,
    direction = -1,
  } = parameter

  const pd = Array.isArray(parentUnitID) ? parentUnitID : [parentUnitID]
  const unit = Array.isArray(unitID) ? unitID : [unitID]
  const sd = Array.isArray(schoolDepartID) ? schoolDepartID : [schoolDepartID]
  const sp = Array.isArray(phaseID) ? phaseID : [phaseID]
  const ss = Array.isArray(schoolSystemID) ? schoolSystemID : [schoolSystemID]
  const mt = Array.isArray(manageTypeID) ? manageTypeID : [manageTypeID]
  const ct = Array.isArray(classTypeID) ? classTypeID : [classTypeID]

  return `pd:${pd.join(',')}&unit:${unit.join(',')}&sd:${sd.join(
    ','
  )}&sp:${sp.join(',')}&ss:${ss.join(',')}&mt:${mt.join(',')}&ct:${ct.join(
    ','
  )}&dc:${direction}`
}

export const namespace = '@xuexin-vuex/school-class-type'
export const types = {
  CREATE_REQUEST: 'CREATE_REQUEST',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_FAILURE: 'CREATE_FAILURE',

  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',

  FETCH_LIST_REQUEST: 'FETCH_LIST_REQUEST',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
  FETCH_LIST_FAILURE: 'FETCH_LIST_FAILURE',

  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',
}

const state: SchoolClassType.State = {
  list: {},
  items: {},
}

const actions: SchoolClassType.Actions = {
  // 查询校本班型列表（国内）
  fetchList(context, payload) {
    const { unitID = 0, direction = 0, transfer = false } = payload
    let url = ''
    if (transfer && fileterMap[`${unitID}`] && direction === 2) {
      url = `post ` + __STUDENT_INTL_API__ + `/unitClassTypes/list`
    } else {
      url = `post ` + Api.student + `/unitClassTypes/list`
    }

    return Ajax(context, {
      type: types.FETCH_LIST_REQUEST,
      payload,
      api: url,
      parameter: payload,
      success: types.FETCH_LIST_SUCCESS,
      failure: types.FETCH_LIST_FAILURE,
    })
  },
  // 创建校本班型
  create(context, payload) {
    return Ajax(context, {
      type: types.CREATE_REQUEST,
      payload,
      api: `post ${Api.student}/unitClassTypes/add`,
      parameter: payload,
      success: types.CREATE_SUCCESS,
      failure: types.CREATE_FAILURE,
    })
  },
  // 删除校本班型
  delete(context, payload) {
    return Ajax(context, {
      type: types.DELETE_REQUEST,
      payload,
      api: `delete ${Api.student}/unitClassTypes/${payload.unitClassTypeID}`,
      parameter: payload,
      success: types.DELETE_SUCCESS,
      failure: types.DELETE_FAILURE,
    })
  },
  // 更改校本班型
  update(context, payload) {
    const { unitClassTypeID, actionType } = payload
    let api = ''

    if (actionType !== void 0 && actionType === 1) {
      api = `put ${Api.student}/unitClassTypes/${unitClassTypeID}/${payload.enable}`
    } else {
      api = `post ${Api.student}/unitClassTypes/${unitClassTypeID}`
    }

    return Ajax(context, {
      type: types.UPDATE_REQUEST,
      payload,
      api: api,
      parameter: payload,
      success: types.UPDATE_SUCCESS,
      failure: types.UPDATE_FAILURE,
    })
  },
}

const mutations: SchoolClassType.Mutations = {
  // 查询校本班型列表
  [types.FETCH_LIST_REQUEST](state, { payload }) {
    const listState = getListState(state, getListKey(payload))
    handlers.request(listState, 'fetching')
  },
  [types.FETCH_LIST_SUCCESS](state, { payload, result, total }) {
    const listKey = getListKey(payload)
    mergeToCompositeState(state, listKey, 'unitClassTypeID', result, total)
    const listState = getListState(state, listKey)
    handlers.success(listState, 'fetching')
  },
  [types.FETCH_LIST_FAILURE](state, { payload, message }) {
    const listState = getListState(state, getListKey(payload))
    handlers.failure(listState, 'fetching', message)
  },

  //创建校本班型
  [types.CREATE_REQUEST](state, { payload }) {
    const listState = getListState(state, getListKey(payload))
    handlers.request(listState, 'creating')
  },
  [types.CREATE_SUCCESS](state, { payload, parameter, result }) {
    const listState = getListState(state, getListKey(payload))
    handlers.success(listState, 'creating')
  },
  [types.CREATE_FAILURE](state, { payload, message }) {
    const listState = getListState(state, getListKey(payload))
    handlers.failure(listState, 'creating', message)
  },

  // 删除校本班型
  [types.DELETE_REQUEST](state, { payload }) {
    handlers.itemRequest(state, payload.unitClassTypeID, 'deleting')
  },
  [types.DELETE_SUCCESS](state, { payload }) {
    handlers.itemSuccess(state, payload.unitClassTypeID, 'deleting')
  },
  [types.DELETE_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, payload.unitClassTypeID, 'deleting', message)
  },

  // 更改校本班型
  [types.UPDATE_REQUEST](state, { payload }) {
    handlers.itemRequest(state, payload.unitClassTypeID, 'updating')
  },
  [types.UPDATE_SUCCESS](state, { payload, parameter }) {
    handlers.itemSuccess(state, payload.unitClassTypeID, 'updating', parameter)
  },
  [types.UPDATE_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, payload.unitClassTypeID, 'updating', message)
  },
}

const getters: SchoolClassType.Getters = {
  itemStatus(state) {
    return id => omit(state.items[`${id}`], ['data'])
  },
  item(state) {
    return id => state.items[`${id}`]?.data
  },
  listStatus(state) {
    return parameter => omit(state.list[getListKey(parameter)], ['data'])
  },
  list(state, getters) {
    return parameter => {
      const listState = state.list[getListKey(parameter)] || { data: [] }
      return listState.data.map(id => getters.item(id)!)
    }
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
