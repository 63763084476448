
























































import Vue from 'vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Button, TableColumn } from 'view-design'
import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import AppPagination from '@components/app-pagination'
import ReportSearch from '@components/report-search'
import ClassPayment from '@store/modules/class-payment'
import ClassPaymentType from 'types/class-payment'
import { Session } from 'xuexin-vuex'

import AppTable from '@components/app-table'
import { convertSearchParameter } from '@util'
import PageTyping from '@/../types/page'

const components = {
  ViewLayout,
  ReportSearch,
  Button,
  AppTable,
  AppPagination,
}

const i18n = {
  messages: {
    'zh-CN': {
      button: {
        export: '导出',
      },
      text: {
        null: '-',
        undefined: '-',
        index: '序号',
      },
      message: {
        noUnitID: '请选择校区',
        noDataText: '暂无数据',
        noParameter: '请先选择搜索条件',
        nonSearch: '请先点击搜索',
      },
    },
    'en-US': {},
  },
}

@Component({ name: 'ClassPaymentPage', components, i18n })
export default class ClassPaymentPage extends Mixins(PagePropsMixins) {
  @ClassPayment.Action('fetch')
  private readonly fetchList!: ClassPaymentType.Action.Fetch
  @ClassPayment.Getter('list')
  private readonly getList!: ClassPaymentType.Getter.List
  @ClassPayment.Getter('listStatus')
  private readonly getListStatus!: ClassPaymentType.Getter.ListStatus
  @ClassPayment.Action('export') private readonly export!: any
  @Session.Getter('user') private readonly getUser!: Session.Getter.User

  private readonly prefixCls = 'class-payment-page'
  private loading = false
  private page = 1
  private pageSize = 50
  private parameter: any = {
    endDate: new Date().getTime(),
  }
  private searchParameter: Partial<PageTyping.SearchParameter> = {}
  get listStatus() {
    return this.getListStatus()
  }
  get fetching() {
    return this.listStatus.fetching === true
  }
  get total() {
    return this.listStatus.total || 0
  }
  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get tableColumn() {
    if (Boolean(this.searchParameter.termID) === false) {
      return []
    }
    const columns: TableColumn[] = [
      {
        title: '序号',
        width: 70,
        render: (createElement, parameter) => {
          const text =
            (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
          return createElement!('span', `${text}`)
        },
      },
      { title: '班级', key: 'className', width: 220 },
      { title: '姓名', key: 'studentName', width: 80 },
      { title: '学生ID', key: 'studentID', width: 100 },
      { title: '性别', slot: 'gender', width: 70 },
      { title: '学生状态', slot: 'studentStatus', width: 100 },
      { title: '缴费状态', key: 'payStatusStr', width: 110 },
      { title: '电子账户余额', key: 'balanceAmount', width: 100 },
      { title: '住宿', key: 'stayStatusStr', width: 80 },
      { title: '校车', key: 'busStatusStr', width: 120 },
      { title: '托管', key: 'trusteeshipStr', width: 80 },
      { title: '校服', key: 'uniformStatusStr', width: 120 },
      { title: '床上用品', key: 'bedStatusStr', width: 120 },
      { title: '餐点类型', key: 'mealTypeStr', width: 240 },
      {
        title: '下学期直升',
        width: 120,
        key: 'helicopterStatusStr',
      },
    ]
    if (this.getList()?.headerList) {
      const headerList: any = this.getList()?.headerList!.map(item => {
        return Object.assign({}, item, {
          width: 70,
        })
      })
      columns.splice(-1, 0, ...headerList)
    }
    return columns
  }

  get tableData() {
    if (Boolean(this.searchParameter.termID) === false) {
      return []
    }

    return this.getList()?.dataList
  }
  get tableTotalData() {
    if (Boolean(this.searchParameter.termID) === false) {
      return []
    }
    const totalCount = []
    totalCount.push(this.getList()?.itemAllCount)
    return totalCount
  }
  get valid() {
    const { termID } = this.searchParameter
    return (
      Boolean(termID) ||
      termID === 0 ||
      Boolean(this.parameter.termID) ||
      this.parameter.termID === 0
    )
  }

  get noDataText() {
    if (
      Boolean(this.parameter.termID) === false ||
      Boolean(this.searchParameter.termID) === false
    ) {
      return this.$t('message.nonSearch')
    }
    return this.$t('message.noDataText')
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.loading === true) {
        this.$set(this.$data, 'loading', false)
      }
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }
  private handleSummary({ columns, data }: { columns: any; data: any }) {
    if (this.tableColumn.length === 0 || this.tableData?.length === 0) {
      return []
    }
    const sums: any = {}
    columns.forEach((column: any, index: number) => {
      const key = column.key
      if (index === 1) {
        sums[key] = {
          key,
          value: '总计',
        }
        return
      }
      const values = this.tableTotalData.map((item: any) =>
        typeof item[key] ? item[key] : ''
      )
      const v = values.map((item: any) => {
        sums[key] = {
          key,
          value: typeof item === 'number' ? item : '',
        }
      })
    })

    return sums
  }
  /** 导出 */
  private handleExport(parameter: any) {
    this.export(Object.assign({}, parameter)).then((res: any) => {
      if (res.result) {
        window.location.href = `${res.result}`
      }
    })
  }
  /** 页码发生变化 */
  private handlePageChange(page: number) {
    this.$set(this.$data.searchParameter, 'page', page)
    this.$set(this.$data, 'page', page)
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number) {
    this.$set(this.$data.searchParameter, 'pageSize', pageSize)
    this.$set(this.$data, 'pageSize', pageSize)
    this.searchParameter.page === 1 && this.fetch()
  }
  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    if (Boolean(parameter.termID) === false) {
      return this.$Message.warning(`${this.$t('message.noParameter')}`)
    }
    this.$set(this.$data, 'searchParameter', parameter)
    this.$set(this.$data.searchParameter, 'page', 1)
    this.$set(this.$data.searchParameter, 'pageSize', this.pageSize)
    this.fetch()
  }
  private fetch() {
    if (
      this.valid !== true /** 参数无效 */ ||
      this.fetching === true /** 正在请求数据 */
    ) {
      /** 以上情况不发起请求 */
      return void 0
    }
    this.fetchList(Object.assign({}, this.searchParameter))
  }
  created() {
    const newValue =
      this.getUser?.extraMap?.systemTime || new Date().getTime()
    this.parameter.endDate = new Date(newValue)
  }
}
