
















































import Vue from 'vue'
import { Component, Prop, Watch, Model } from 'vue-property-decorator'
import { Constant } from 'xuexin-vuex'
import i18n from './i18n'
import AppTable from '@components/app-table'
import AppModal from '@components/app-modal'
import AppPagination from '@components/app-pagination'
import Records from '@store/modules/records'
import { Records as RecordsTyping } from 'types'
const components = { AppModal, AppTable, AppPagination }
type DataType = {
  termID: number
  unitID: number
  type: Constant.PageType
}
@Component({ name: 'ImportRecordModal', components, i18n })
export default class ImportRecordModal extends Vue {
  @Records.Action('fetchList')
  private readonly fetchList!: RecordsTyping.Action.FetchList
  @Records.Getter('list') private readonly list!: RecordsTyping.Getter.List
  @Records.Getter('listStatus')
  private readonly listStatus!: RecordsTyping.Getter.ListStatus

  @Model('input') private value!: boolean
  @Prop({ default: () => ({}) }) private readonly data!: DataType

  private readonly prefixCls = 'record-modal'
  private visible = false

  private page = 1
  private pageSize = 10

  get termID() {
    return this.data.termID
  }

  get unitID() {
    return this.data.unitID
  }

  get type() {
    return this.data.type
  }

  get total() {
    return this.listStatus.total || this.list.length
  }

  get tableColumns() {
    return [
      // prettier-ignore
      { title: this.$t('column.index'),key: 'num', align: 'center', width: 80 },
      // prettier-ignore
      { title: this.$t('column.date'), key: 'importDate', slot: 'importDate', width: 180 },
      { title: this.$t('column.type'), key: 'typeName', width: 150 },
      // prettier-ignore
      { title: this.$t('column.successCount'), align: 'center', slot: 'success'  },
      // prettier-ignore
      { title: this.$t('column.failureCount'),  align: 'center', slot: 'failure'  },
      // prettier-ignore
      { title: this.$t('column.operator'), slot: 'realName', ellipsis: true},
    ]
  }

  get tableData() {
    return this.list.map((item: any, index: number) => {
      const report = Object.assign({}, item)
      report.num = (this.page - 1) * this.pageSize + index + 1
      return report
    })
  }

  get noDataText() {
    if (Boolean(this.total) === false) {
      return this.$t('noDataText')
    }
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get fetchingError() {
    return this.listStatus.fetchingError || null
  }

  get parameter() {
    return {
      termID: this.termID,
      unitID: this.unitID,
      type: this.type,
      /** 1:查询导入记录 2:操作记录 3: 短信记录*/
      actionType: 1,
    }
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('fetching', { immediate: true })
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.fetchingError !== null) {
        this.$Message.error(this.fetchingError)
      }
    }
  }

  /** 请求数据 */
  private fetch() {
    this.fetchList(
      Object.assign({}, this.parameter, {
        page: this.page,
        pageSize: this.pageSize,
      })
    )
  }

  private handleHidden() {
    this.$emit('on-hidden')
  }

  private handleVisibleChange(visible: boolean): void {
    if (visible === true) {
      if (Boolean(this.unitID) === false) {
        return
      }
      this.fetch()
    }
    this.$emit('on-visible-change', visible)
  }

  /** 页码发生变化*/
  private handlePageChange(page: number): void {
    this.$set(this.$data, 'page', page)
    this.fetch()
  }

  /** 每页条数发生变化 */
  private handlePageSizeChange(pageSize: number): void {
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }
}
