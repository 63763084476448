/** 班级数量统计 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import ClassesCount from '@pages/statistic-reports/classes-count'

const children: RouteConfig[] = []

children.push({
  path: '/reports/classes-count',
  name: 'classes-count-report',
  component: ClassesCount,
  meta: { ignore: true, authCode: '050706', parentAuthCode: '0507' },
})

export default {
  path: '/reports',
  name: 'classes-count',
  redirect: { name: 'classes-count-report' },
  meta: { title: '班级数量统计', authCode: '050706' },
  component: RouterViewLayout,
  props: { name: 'classes-count' },
  children,
}
