
















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import PageHeader from '@functional-components/page-header'
import PageBody from '@functional-components/page-body'
import PageFooter from '@functional-components/page-footer'
import { VerificationStep, ResetStep, SuccessfulStep } from './views'
import i18n from './i18n'

const components = {
  PageHeader,
  PageBody,
  PageFooter,
  VerificationStep,
  ResetStep,
  SuccessfulStep,
}
@Component({ name: 'PasswordResetPage', components, i18n })
export default class PasswordResetPage extends Vue {
  private readonly prefixCls = 'password-reset-page'
  private step = 1

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
}
