






















































































































import Vue, { CreateElement } from 'vue'
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'
import PageTyping from '../../../../types/page'
import PagePropsMixins from '@mixins/page-props-mixins'
import { Constant, Student } from 'xuexin-vuex'
import { Button, TableColumn } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import { StudentFlowStatusBadge } from '@business-components/student-flow-status'
import {
  StudentStatusTag,
  StudentStatusModal,
} from '@business-components/student-status'
import ExportModal from '@business-components/export-modal'
import SendMessageModal from '@business-components/send-message-modal'
import ClassModal from '@business-components/glass-modal'
import i18n from './i18n'
import { convertSearchParameter } from '@util'
import { getParameter, setParameter } from '@util/parameter'
import NumFormate from '@util/num-format'

type Flat = Record<string, Student.Entity>
const ActionType = Constant.ActionType

const components = {
  Button,
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  AppTable,
  AppPagination,
  StudentFlowStatusBadge,
  /** 学生状态相关 */
  StudentStatusTag,
  StudentStatusModal,
  /** 窗口 */
  ExportModal,
  SendMessageModal,
  ClassModal,
}
@Component({ name: 'TransferStudentListPage', components, i18n })
export default class TransferStudentListPage extends Mixins(PagePropsMixins) {
  @Student.Action('fetchList')
  private readonly fetchStudents!: Student.Action.FetchList
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Student.Getter('list') private readonly students!: Student.Getter.List
  @Student.Mutation('CLEAN_LIST')
  private readonly cleanList!: Student.Mutation.CleanList

  private prefixCls = 'transfer-student-list-page'
  private flat: Flat = {}
  private modal: { name: string | null; data: any } = { name: null, data: {} }
  private selection: Student.Entity[] = []
  private searchParameter: Partial<PageTyping.SearchParameter> = {}
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private page = 1
  private pageSize = 10

  get authCode() {
    return this.$route.meta.authCode
  }

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get valid() {
    return true
  }

  get noDataText() {
    return this.$t('text.noDataText')
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get total() {
    return this.listStatus.total || 0
  }

  get tableColumns(): TableColumn[] {
    if (this.valid !== true || this.tableData.length === 0) {
      return []
    }

    const columns = this.columns.map(item => {
      let column = item

      switch (item.key) {
        case 'studentStatus' /** 学生状态 */:
          column = Object.assign({}, item, { slot: 'studentStatus' })
          break
        case 'parentMobile' /** 家长电话 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.smsPhone ? NumFormate(`${row.smsPhone}`) : '-'
              return createElement!('span', {}, [text])
            },
          })
        case 'studentPhone' /** 学生电话 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.studentPhone
                ? NumFormate(`${row.studentPhone}`)
                : '-'
              return createElement!('span', {}, [text])
            },
          })
        case 'IDNO' /** 证件号码 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.IDNO ? NumFormate(`${row.IDNO}`) : '-'
              return createElement!('span', {}, [text])
            },
          })
        case 'departOperateDate':
        case 'identifyDate':
          item.width = 140
          break
      }
      return column
    })

    columns.unshift({
      type: 'index',
      width: 80,
      fixed: 'left',
      align: 'center',
      title: '序号',
    })

    return columns
  }

  get tableData() {
    if (this.valid !== true) {
      return []
    }

    return this.students.map((item, index) => {
      const student = Object.assign({}, item)

      /** 还原当前选中的学生 */
      if (this.flat[item.shortStudentID] !== void 0) {
        student._checked = true
      }

      student.num = (this.page - 1) * this.pageSize + index + 1

      return student
    })
  }

  get disabled() {
    return this.tableData.length === 0
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 查询学生列表请求完成 */
    if (fetching === false && previous === true) {
      /** 提示错误信息 */
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }
  @Watch('searchParameter.unitID')
  private watchUnitID(value: number, oldValue: unknown) {
    // 有条件缓存时，不走默认查询
    const para = getParameter(`${this.$route.meta.authCode}`)
    if (para) return

    // 无条件缓存时，默认查询一次
    if (oldValue === null || oldValue === undefined) {
      this.parameter = { ...this.searchParameter }
      this.fetch()
    }
  }
  created() {
    /** 清理其它页面的缓存数据 */
    this.cleanList()
    // this.fetch()

    // 若有查询条件缓存，则回显查询条件
    const para = getParameter(`${this.$route.meta.authCode}`)
    if (para) {
      let parameters = {}
      parameters = Object.assign({}, para.searches)
      this.$set(this.$data, 'parameter', parameters)
      this.$set(this.$data, 'searchParameter', parameters)
      this.page = para.page
      this.pageSize = para.pageSize
      this.fetch()
    }
  }

  mounted() {}

  destroyed() {}

  private convertParameter() {
    return convertSearchParameter(this.parameter)
  }

  /** 查询数据 */
  private fetch() {
    if (
      this.valid !== true /** 参数无效 */ ||
      this.fetching === true /** 正在请求数据 */
    ) {
      /** 以上情况不发起请求 */
      return void 0
    }

    this.fetchStudents({
      actionType: ActionType.InsideTransfer,
      authCode: this.authCode,
      searches: this.convertParameter(),
      page: this.page,
      pageSize: this.pageSize,
    })

    // 缓存查询条件
    setParameter(`${this.$route.meta.authCode}`, {
      authCode: this.authCode,
      searches: this.searchParameter,
      page: this.page,
      pageSize: this.pageSize,
    })
  }

  /** 页面按钮单击操作 */
  private handleClick(action: string, student: Student.Entity) {
    switch (action) {
      case 'sms-record' /** 短信发送记录 */:
        {
          this.$router.push({
            name: 'inside-transfer-sms-record',
            query: this.convertParameter(),
          })
        }
        break
      case 'export' /** 导出 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'export-modal',
            data: {
              list: this.selection.map(item => ({ id: item.studentID })),
              authCode: this.authCode,
              actionType: ActionType.InsideTransfer,
              type: Constant.PageType.InsideTransfer,
              searches: this.convertParameter(),
            },
          })
        }
        break
      case 'sms' /** 发短信 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'sms-modal',
            data: {
              list: this.selection.map(item => ({
                id: item.studentID,
                name: item.studentName,
                mobile: item.parentMobile,
              })),
              searches: this.convertParameter(),
              authCode: this.authCode,
              actionType: ActionType.InsideTransfer,
              pageType: Constant.PageType.InsideTransfer,
            },
          })
        }
        break
      case 'view-student' /** 查看学生详情 */:
        {
          this.$router.push({
            name: 'inside-transfer-detail',
            params: {
              termID: `${student.termID}`,
              unitID: `${student.unitID}`,
              xuexinID: student.studentID,
              parentUnitID: `${student.parentUnitID || ''}`,
            },
          })
        }
        break
      case 'view-status' /** 查看学生状态 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'student-status-modal',
            data: {
              termID: student.termID,
              studentID: student.studentID,
              status: student.studentStatus,
            },
          })
        }
        break
      case 'view-grade' /** 查年年级信息 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'grade-modal',
            data: {
              viewType: 'view',
              unitGradeID: student.unitGradeID,
              parameter: { termID: student.termID },
            },
          })
        }
        break
      case 'view-class' /** 查看班级信息 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'class-modal',
            data: {
              viewType: 'view',
              classID: student.classID,
              parameter: { termID: student.termID },
            },
          })
        }
        break
      default:
        console.warn(`${this.$options.name}.handleClick(${action})`)
        break
    }
  }

  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    // if (Boolean(parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(`${this.$t('warning.noSchoolDepartID')}`)
    // }

    this.$set(this.$data, 'parameter', parameter)
    this.$set(this.$data, 'page', 1)
    this.fetch()
  }

  /** 已选学生发生变化时的处理函数 */
  private handleSelectionChange(selection: Student.Entity[]) {
    this.$set(this.$data, 'selection', selection)
  }

  /** 页码发生变化 */
  private handlePageChange(page: number) {
    /**
     * 修改本地 `page` 属性值，清空已选数据，并重新请求数据。
     */
    this.$set(this.$data, 'page', page)
    this.$set(this.$data, 'selection', [])
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number) {
    /**
     * 修改本地 `pageSize` 属性值，若当前 `page` 为 1，则手动请求数据。
     */
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }

  /** 窗口功能完成事件处理函数 */
  private handleModalFinish(modal: string) {
    /** 清空窗口状态 */
    this.$set(this.$data, 'modal', { name: null, data: {} })

    switch (modal) {
      case 'sms-modal' /** 发送短信窗口 */:
        // 以上窗口不提示(窗口内部已处理)
        break
      default:
        this.$Message.success(`${this.$t('success')}`)
        break
    }
  }

  /** 窗口关闭后处理函数 */
  private handleModalHidden(modal: string) {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }
}
