import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import { Input, DatePicker, FormItem, Checkbox } from 'view-design'

import { DirectionText } from '@business-components/direction'
import { SchoolSelect } from '@business-components/school'
import { SemesterSelect } from '@business-components/semester'
import { BusinessTypeSelect } from '@business-components/business-type'
import { SchoolClassTypeSelect } from '@business-components/school-class-type'
import { HouseholdIncomeSelect } from '@business-components/household-income'
import { GenderRadioGroup } from '@business-components/gender'
import { IDInfoSelect } from '@business-components/id-type'
import { ResidenceTypeRadioGroup } from '@business-components/residence-type'
import { EnrollTypeRadioGroup } from '@business-components/enroll-type'
import { StatusTypeRadioGroup } from '@business-components/status-type'
import { ResearchFieldRadioGroup } from '@business-components/research-field'
import { RelationSelect } from '@business-components/relation'
import { WorkplaceTypeSelect } from '@business-components/workplace-type'
import { PoliticalStatusSelect } from '@business-components/political-status'
import { EducationSelect } from '@business-components/education'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { GradeSelect } from '@business-components/grade'
import { EnrollScoreSelect } from '@business-components/enroll-score'
import { PrimarySchoolScoreSelect } from '@business-components/primary-school-score'
import { TransientStatusTypeSelect } from '@business-components/transient-status-type'
import { CollegeDirectionSelect } from '@business-components/college-direction'

import { NationalitySelect } from '@components/nationality'
import { EthnicitySelect } from '@components/ethnicity'
import { AppBooleanRadioGroup } from '@components/app-boolean'
import { BloodTypeSelect } from '@components/blood-type'
import { AcademicDirectionSelect } from '@business-components/academic-direction'
import { StudentCategorySelect } from '@business-components/student-category'
import { FreshStudentCheckbox } from '@business-components/fresh-student'
import RegionSelector from '@components/region-selector'
import ImageView from '../image-view'

import i18n from './i18n'
import { createElementAccess } from 'typescript'
import { isArray } from 'lodash'

@Component({ name: 'StudentField', i18n })
export default class StudentField extends Vue {
  @Prop({ type: Object }) private readonly data!: Student.Column
  @Prop({ type: String }) private readonly prop!: string
  @Prop({ type: String }) private readonly label?: string

  @Prop() private readonly actionType!: string
  @Prop() private readonly direction!: number
  @Prop() private readonly termID!: number
  @Prop() private readonly parentUnitID!: number
  @Prop() private readonly unitID!: number
  @Prop() private readonly schoolDepartID!: number
  @Prop() private readonly manageTypeID!: number
  @Prop() private readonly phaseID!: number
  @Prop() private readonly studentMark!: string
  @Prop() private readonly hiddenSchool!: boolean
  @Prop() private readonly freshStudent!: number
  @Prop() private readonly statusType!: string
  @Prop() private readonly hasTermBill!: number
  @Prop() private readonly idLength!: any
  @Prop() private readonly nation!: string
  @Prop() private readonly nations!: string[]
  @Prop() private readonly basicCode!: string
  @Prop() private readonly otherIdType!: any
  @Prop() private readonly otherIdTypes!: any

  private readonly prefixCls = 'student-field'
  private divideClassStudentEdit = false // 分班学生不能编辑报读学期、校区、学段、年级、应届生、学业方向/入学分类、学生类别
  get style() {
    const { columnCode, columnType, typeIndex } = this.data
    const style: Record<string, any> = {}

    switch (columnType) {
      case Constant.ColumnType.Text /** 文本: 0 */:
        style.width = '220px'
        switch (columnCode) {
          case 'referralCode': /** 推荐码 */
          case 'name': /** 学生姓名 */
          case 'usedName': /** 曾用名 */
          case 'statusID': /** 国家学籍号 */
          case 'height': /** 身高 */
          case 'weight': /** 身重 */
          case 'shoeSize': /** 鞋码 */
          case 'idAddress': /** 证件颁发地 */
          case 'passportNO': /** 护照号 */
          case 'passportAddress': /** 护照颁发地 */
          case 'previousSchool': /** 原就读学校 */
          case 'parentName': /** 家长姓名 */
          case 'phone': /** 联系电话 */
          case 'diseaseHistory': /** 特异病史 */
          case 'remark': /** 备注 */
          case 'workplace': /** 工人单位 */
          case 'addressInfo': /** 联系详细地址 */
          case 'nativePlaceInfo': /** 籍贯详细地址 */
          case 'registeredPlaceInfo': /** 户口详细地址 */
          case 'pRegisteredPlaceInfo': /** 家长户口详细地址 */
          case 'homeAddressInfo' /** 现家庭详细地址 */:
          case 'studentscoreRemark' /** 无成绩说明 */:
            break
          case 'idNo' /** 证件号码 */:
            break
          case 'studentscore' /** 分数 */:
            style.width = '80px'
            break
          case 'studentscoreArea' /** 学生分数说明 */:
            style.width = '230px'
            break
          default:
            console.log(
              `未处理的文本(${this.data.columnName}:${columnCode}) style 属性`
            )
            break
        }
        break
      case Constant.ColumnType.Select /** 下拉框: 1 */:
        style.width = '220px'
        switch (columnCode) {
          case 'education' /** 文化程度 */:
          case 'politicalStatus' /** 政治面貌 */:
          case 'studentPoliticalStatus' /** 学生政治面貌 */:
          case 'transientStatusType' /** 借读生转籍状态 */:
          case 'collegeDirection' /** 高考方向 */:
          case 'workplaceType' /** 工作性质 */:
          case 'relation' /** 关系 */:
          case 'nation' /** 国籍 */:
          case 'ethnicity' /** 民族 */:
          case 'householdIncome' /** 家庭年收入 */:
          case 'activity' /** 招生活动 */:
          case 'school' /** 校区 */:
          case 'term' /** 学期 */:
          case 'businessType' /** 报读类型 */:
          case 'classType' /** 校本班型 */:
          case 'bloodType' /** 血型 */:
            break
          case 'phase' /** 学段 */:
          case 'grade' /** 年级 */:
            style.width = void 0
            break
          case 'academicDirection': /**学业方向 */
          case 'studentCategory' /**学生分类 */:
            style.width = '220px'
            break
          default:
            console.log(
              `未处理的下拉框(${this.data.columnName}:${columnCode}) style 属性`
            )
            break
        }
        break
      case Constant.ColumnType.RadioGroup /** 单选按钮: 2 */:
        switch (columnCode) {
          case 'statusType' /** 学籍 */:
          case 'studentType' /** 入学分类 */:
          case 'transientStudent': /** 借读生 */
          case 'onlyChild': /** 独生女子 */
          case 'singleParent': /** 单亲家庭 */
          case 'ownerChild': /** 业主子女 */
          case 'staffChild': /** 校职工女子 */
          case 'studentMark': /** 集团优生 */
          case 'guardian' /** 监护人 */:
          case 'residenceType' /** 户口类型 */:
          case 'direction' /** 报名方向 */:
          case 'gender' /** 性别 */:
          case 'researchField' /** 学业方向 */:
            break
          default:
            console.log(
              `未处理的单选按钮(${this.data.columnName}:${columnCode}) style 属性`
            )
            break
        }
        break
      case Constant.ColumnType.Checkbox /** 复选框: 3 */:
        break
      case Constant.ColumnType.Date /** 日期: 4 */:
        style.width = '220px'
        break
      case Constant.ColumnType.Pic /** 图片: 5 */:
        break
      case Constant.ColumnType.Object /** 组合类: 6 */:
        style.width = '220px'
        switch (columnCode) {
          case 'address': /** 联系地址 */
          case 'nativePlace': /** 籍贯 */
          case 'registeredPlace': /** 户口所在地 */
          case 'homeAddress' /** 现家庭住址 */:
          case 'pRegisteredPlace' /** 家长户口所在地 */:
            style.marginBottom = '24px'
            break
          case 'enrollScore' /** 中考成绩 */:
          case 'primarySchoolScore' /** 小学毕业成绩 */:
            style.width = '140px'
            break
          case 'birthplace' /** 出生地 */:
            break
          case 'idType' + typeIndex:
            break
          default:
            console.log(
              `未处理组合组件(${this.data.columnName}:${columnCode})的 style 属性`
            )
            break
        }
        break
      default:
        console.log(
          `未处理类型(${this.data.columnName}:${columnCode})的 style 属性`
        )
        break
    }
    return style
  }

  get props() {
    const { columnCode, columnType, typeIndex } = this.data
    const props: Record<string, unknown> = {}

    switch (columnType) {
      case Constant.ColumnType.Text /** 文本: 0 */:
        props.value = this.data.value
        switch (columnCode) {
          case 'highSchoolScore' /** 高考成绩 */:
          case 'referralCode' /** 推荐码 */:
            props.disabled = true
            break
          case 'name': /** 学生姓名 */
          case 'shoeSize': /** 鞋码 */
          case 'passportAddress': /** 护照颁发地 */
          case 'previousSchool' /** 原就读学校 */:
          case 'statusID' /** 国家学籍号 */:
          case 'provincialStatusID': /** 省级学籍号 */
          case 'graduationSchoolName': /** 毕业学校 */
          case 'studentPhone': /** 学生手机号 */
          case 'parentName': /** 家长姓名 */
          case 'phone' /** 联系电话 */:
          case 'studentscore' /** 分数 */:
            break
          case 'diseaseHistory': /** 特异病史 */
          case 'remark': /** 备注 */
          case 'workplace': /** 工人单位 */
          case 'addressInfo': /** 联系详细地址 */
          case 'nativePlaceInfo': /** 籍贯详细地址 */
          case 'registeredPlaceInfo': /** 户口详细地址 */
          case 'pRegisteredPlaceInfo': /** 家长户口详细地址 */
          case 'homeAddressInfo' /** 现家庭详细地址 */:
            props.type = 'textarea'
            break
          case 'studentscoreRemark' /** 无成绩说明 */:
            props.type = 'textarea'
            props.placeholder =
              `${this.studentMark}` === '1'
                ? '请填写集团认定优生的条件'
                : '请填写无中考成绩原因'
            break
          case 'studentscoreArea' /** 学生分数说明 */:
            props.type = 'textarea'
            props.placeholder = '请填写中考所在地及考试满分'
            break
          case 'idNo':
            props.placeholder = '请输入证件号码'
            break
          case 'idAddress':
            props.placeholder = '请输入证件颁发地'
            break
          case 'height' /** 身高 */:
            props.placeholder = '仅支持数字，范围50-300'
            break
          case 'weight' /** 体重 */:
            props.placeholder = '仅支持数字，范围5-300'
            break
          case 'usedName' /** 曾用名 */:
            props.placeholder = '请按户口本上填写，默认为 “无”'
            break
          default:
            console.log(
              `未处理的文本(${this.data.columnName}:${columnCode}) props 属性`
            )
            break
        }
        break
      case Constant.ColumnType.Select /** 下拉框: 1 */:
        switch (columnCode) {
          case 'activity' /** 招生活动 */:
            props.value = this.data.value
            props.disabled = true
            props.readonly = true
            break
          case 'school' /** 校区 */:
            props.capture = true
            props.termID = this.termID
            props.unitID = this.parentUnitID
            props.direction = this.direction
            props.viewType = this.divideClassStudentEdit ? 'text' : void 0
            break
          case 'term' /** 学期 */:
            props.unitID = this.unitID
            break
          case 'businessType' /** 报读类型 */:
            props.unitID = this.unitID
            props.phaseID = this.phaseID
            props.required = this.data.required === 1
            break
          case 'classType' /** 校本班型 */:
            props.parentUnitID = this.parentUnitID
            props.unitID = this.unitID
            props.schoolDepartID = this.schoolDepartID
            props.phaseID = this.phaseID
            props.manageTypeID = this.manageTypeID
            props.direction = this.direction
            props.requestType = 'innerTransfer'
            break
          case 'phase' /** 学段 */:
            props.unitID = this.unitID
            props.schoolDepartID = this.schoolDepartID
            props.direction = this.direction
            props.disabled = this.divideClassStudentEdit
            break
          case 'grade' /** 年级 */:
            props.base = true
            props.unitID = this.unitID
            props.schoolDepartID = this.schoolDepartID
            props.phaseID = this.phaseID
            props.compose = false // 不显示拼接字段
            props.disabled = this.divideClassStudentEdit
            break
          case 'academicDirection' /**学业方向 */:
            props.phaseID = this.phaseID
            props.freshStudent = this.freshStudent
            props.required = this.data.required === 1
            props.viewType =
              this.phaseID != 4 || Boolean(this.hasTermBill) ? 'text' : ''
            break
          case 'studentCategory' /**学生类别 */:
            props.phaseID = this.phaseID
            props.freshStudent = this.freshStudent
            props.required = this.data.required === 1
            props.viewType = Boolean(this.hasTermBill) ? 'text' : ''
            break
          /** 以下字段不需要单独处理 */
          case 'householdIncome' /** 家庭年收入 */:
          case 'relation' /** 关系 */:
          case 'nation' /** 国籍 */:
          case 'ethnicity' /** 民族 */:
          case 'bloodType' /** 血型 */:
          case 'workplaceType': /** 工作性制 */
          case 'education' /** 文化程度 */:
          case 'politicalStatus' /** 政治面貌 */:
          case 'studentPoliticalStatus' /**学生政治面貌 */:
          case 'transientStatusType' /** 借读生转籍状态 */:
          case 'collegeDirection' /** 高考方向 */:
            break
          default:
            console.log(
              `未处理的下拉框(${this.data.columnName}:${columnCode}) props 属性`
            )
            break
        }
        break
      case Constant.ColumnType.RadioGroup /** 单选按钮: 2 */:
        switch (columnCode) {
          case 'direction' /** 报名方向 */:
            props.parentUnitID = this.parentUnitID
            break
          case 'statusType':
            props.freshStudent = Number(this.freshStudent)
            break
          case 'transientStudent':
            props.statusType = this.statusType
          default:
            break
        }
        break
      case Constant.ColumnType.Checkbox /** 复选框: 3 */:
        switch (columnCode) {
          case 'freshStudent' /** 应届生 */:
            props.phaseID = this.phaseID
            props.value = this.data.value
            props.required = this.data.required === 1
            props.viewType =
              (this.phaseID != 4 && this.phaseID != 3) ||
              Boolean(this.hasTermBill)
                ? 'text'
                : ''
            props.disabled = this.divideClassStudentEdit
            break
          default:
            break
        }
        break
      case Constant.ColumnType.Date /** 日期: 4 */:
        props.value = this.data.value
        props.editable = false
        switch (columnCode) {
          case 'idDate' /**护照有效期 */:
            props.placeholder = '请选择有效期'
            break
          case 'birthday':
            props.disabled = this.otherIdType.includes(1)
            break
          default:
            console.log(
              `未处理的日期(${this.data.columnName}:${columnCode}) props 属性`
            )
        }
        break
      case Constant.ColumnType.Pic /** 图片: 5 */:
        switch (columnCode) {
          case 'photo': /** 学生照片 */
          case 'medicalBirthCertificate' /** 医学证明 */:
            props.value = this.data.value
            break
          default:
            console.log(
              `未处理的图片(${this.data.columnName}:${columnCode}) props 属性`
            )
            break
        }
        break
      case Constant.ColumnType.Object /** 组合类: 6 */:
        switch (columnCode) {
          case 'idType' + typeIndex:
            props.nation =
              this.basicCode === 'family'
                ? this.nations[this.data.index || 0]
                : this.nation
            props.value = this.data.value
            break
          case 'address' /** 联系地址 */:
          case 'homeAddress': /** 现家庭住址 */
          case 'nativePlace': /** 籍贯 */
          case 'birthplace': /** 出生地 */
          case 'registeredPlace': /** 户口所在地 */
          case 'pRegisteredPlace' /** 家长户口所在地 */:
            props.value = this.data.value
            break
          /** 自定义的组件，value 在 attrs 里面 */
          case 'enrollScore' /** 中考成绩 */:
            break
          default:
            console.log(
              `未处理组合组件(${this.data.columnName}:${columnCode})的 props 属性`
            )
            break
        }
        break
      default:
        console.log(
          `未处理类型(${this.data.columnName}:${columnCode})的 props 属性`
        )
        break
    }
    return props
  }

  get attrs() {
    const { columnCode, columnType, typeIndex } = this.data
    const attrs: Record<string, unknown> = {}
    switch (columnType) {
      case Constant.ColumnType.Text /** 文本: 0 */:
        break
      case Constant.ColumnType.Select /** 下拉框: 1 */:
        switch (columnCode) {
          case 'activity' /** 招生活动 */:
            break
          case 'education' /** 文化程度 */:
          case 'politicalStatus' /** 政治面貌 */:
          case 'studentPoliticalStatus' /**学生政治面貌 */:
          case 'transientStatusType' /** 借读生转籍状态 */:
          case 'collegeDirection' /** 高考方向 */:
          case 'workplaceType' /** 工作性质 */:
          case 'relation' /** 关系 */:
          case 'nation' /** 国籍 */:
          case 'ethnicity' /** 民族 */:
          case 'householdIncome' /** 家庭年收入 */:
          case 'businessType' /** 报读类型 */:
          case 'classType' /** 校本班型 */:
          case 'idNo':
          case 'school' /** 校区 */:
          case 'phase' /** 学段 */:
          case 'grade' /** 年级 */:
          case 'idAddress':
            attrs.value = this.data.value
            break
          case 'bloodType' /** 血型 */:
            attrs.value = this.data.value || '4'
            attrs.placeholder = '如果为空，默认未知'
            break

          case 'term' /** 学期 */:
          case 'academicDirection' /**学业方向 */:
          case 'studentCategory' /**学生类别 */:
            attrs.value = this.data.value
            attrs.disabled = this.divideClassStudentEdit
            break
          default:
            console.log(
              `未处理的下拉框(${this.data.columnName}:${columnCode}) attrs 属性`
            )
            break
        }
        break
      case Constant.ColumnType.RadioGroup /** 单选按钮: 2 */:
        switch (columnCode) {
          case 'statusType' /** 学籍 */:
          case 'studentType' /** 入学分类 */:
          case 'residenceType' /** 户口类型 */:
          case 'direction' /** 报名方向 */:
          case 'gender' /** 性别 */:
          case 'researchField' /** 学业方向 */:
          case 'guardian' /** 监护人 */:
          case 'onlyChild' /** 独生女子 */:
          case 'singleParent' /** 单亲家庭 */:
          case 'transientStudent' /** 借读生 */:
          case 'ownerChild' /** 业主子女 */:
          case 'staffChild' /** 校职工女子 */:
          case 'studentMark' /** 集团优生 */:
            attrs.value = this.data.value
            break
          default:
            console.log(
              `未处理的单选按钮(${this.data.columnName}:${columnCode}) attrs 属性`
            )
            break
        }
        break
      case Constant.ColumnType.Checkbox /** 复选框: 3 */:
        switch (columnCode) {
          case 'freshStudent':
            attrs.value = Boolean(this.data.value)
            attrs.disabled =
              Boolean(this.hasTermBill) ||
              (this.phaseID !== 4 && this.phaseID !== 3) ||
              this.divideClassStudentEdit
            break
          default:
            console.log(
              `未处理的复选按钮(${this.data.columnName}:${columnCode}) attrs 属性`
            )
            break
        }
      case Constant.ColumnType.Date /** 日期: 4 */:
      case Constant.ColumnType.Pic /** 图片: 5 */:
      case Constant.ColumnType.Object /** 组合类: 6 */:
        switch (columnCode) {
          case 'enrollScore' /** 中考成绩 */:
          case 'idType' + typeIndex /** 证件信息-证件类型 */:
            attrs.value = this.data.value
            break
          default:
            break
        }
        break
      default:
        console.log(
          `未处理的类型(${this.data.columnName}:${columnCode}) attrs 属性`
        )
        break
    }
    return attrs
  }

  get tag() {
    let tag
    const { columnCode, columnType, typeIndex } = this.data
    switch (columnType) {
      case Constant.ColumnType.Text /** 文本: 0 */:
        tag = Input
        break
      case Constant.ColumnType.Select /** 下拉框: 1 */:
        switch (columnCode) {
          case 'education' /** 文化程度 */:
            tag = EducationSelect
            break
          case 'politicalStatus' /** 政治面貌 */:
          case 'studentPoliticalStatus' /**学生政治面貌 */:
            tag = PoliticalStatusSelect
            break
          case 'transientStatusType' /** 借读生转籍状态 */:
            tag = TransientStatusTypeSelect
            break
          case 'collegeDirection' /** 高考方向 */:
            tag = CollegeDirectionSelect
            break
          case 'workplaceType' /** 工作性质 */:
            tag = WorkplaceTypeSelect
            break
          case 'relation' /** 关系 */:
            tag = RelationSelect
            break
          case 'nation' /** 国籍 */:
            tag = NationalitySelect
            break
          case 'ethnicity' /** 民族 */:
            tag = EthnicitySelect
            break
          case 'householdIncome' /** 家庭年收入 */:
            tag = HouseholdIncomeSelect
            break
          case 'activity' /** 招生活动 */:
            tag = Input
            break
          case 'school' /** 校区 */:
            tag = SchoolSelect
            break
          case 'term' /** 学期 */:
            tag = SemesterSelect
            break
          case 'businessType' /** 报读类型 */:
            tag = BusinessTypeSelect
            break
          case 'classType' /** 校本班型 */:
            tag = SchoolClassTypeSelect
            break
          case 'phase' /** 学段 */:
            tag = SchoolPhaseSelect
            break
          case 'grade' /** 年级 */:
            tag = GradeSelect
            break
          case 'bloodType' /** 血型 */:
            tag = BloodTypeSelect
            break
          case 'academicDirection':
            tag = AcademicDirectionSelect
            break
          case 'studentCategory':
            tag = StudentCategorySelect
            break
          default:
            console.log(
              `未处理的下拉框(${this.data.columnName}:${columnCode}) tag 属性`
            )
            break
        }
        break
      case Constant.ColumnType.RadioGroup /** 单选按钮: 2 */:
        switch (columnCode) {
          case 'statusType' /** 学籍 */:
            tag = StatusTypeRadioGroup
            break
          case 'studentType' /** 入学分类 */:
            tag = EnrollTypeRadioGroup
            break
          case 'transientStudent': /** 借读生 */
          case 'onlyChild': /** 独生女子 */
          case 'singleParent': /** 单亲家庭 */
          case 'ownerChild': /** 业主子女 */
          case 'staffChild': /** 校职工女子 */
          case 'studentMark': /** 集团优生 */
          case 'guardian' /** 监护人 */:
            tag = AppBooleanRadioGroup
            break
          case 'residenceType' /** 户口类型 */:
            tag = ResidenceTypeRadioGroup
            break
          case 'direction' /** 报名方向 */:
            tag = DirectionText
            break
          case 'gender' /** 性别 */:
            tag = GenderRadioGroup
            break
          case 'researchField' /** 学业方向 */:
            tag = ResearchFieldRadioGroup
            break
          /** 不处理 */
          case 'receiveSms' /** 是否接收短信 */:
            break
          default:
            console.log(
              `未处理的单选按钮(${this.data.columnName}:${columnCode}) tag 属性`
            )
            break
        }
        break
      case Constant.ColumnType.Checkbox /** 复选框: 3 */:
        switch (columnCode) {
          case 'freshStudent' /** 应届生 */:
            tag = FreshStudentCheckbox
            break
          default:
            console.log(
              `未处理的复选框(${this.data.columnName}:${columnCode}) tag 属性`
            )
            break
        }
        break
      case Constant.ColumnType.Date /** 日期: 4 */:
        tag = DatePicker
        break
      case Constant.ColumnType.Pic /** 图片: 5 */:
        switch (columnCode) {
          case 'photo': /** 学生照片 */
          case 'medicalBirthCertificate' /** 医学证明 */:
            tag = ImageView
            break
          default:
            console.log(
              `未处理的图片(${this.data.columnName}:${columnCode}) tag 属性`
            )
            break
        }
        break
      case Constant.ColumnType.Object /** 组合类: 6 */:
        switch (columnCode) {
          case 'idType' + typeIndex:
            tag = IDInfoSelect
            break
          case 'address': /** 联系地址 */
          case 'nativePlace': /** 籍贯 */
          case 'registeredPlace': /** 户口所在地 */
          case 'homeAddress' /** 现家庭住址 */:
          case 'birthplace' /** 出生地 */:
          case 'pRegisteredPlace' /** 家长户口所在地 */:
            tag = RegionSelector
            break
          case 'enrollScore' /** 中考成绩 */:
            tag = EnrollScoreSelect
            break
          /** 此处组合组件不用处理 */
          case 'gradeIntention' /** 报读年级 */:
          case 'channel' /** 招生渠道 */:
            tag = 'div'
            break
          default:
            tag = 'div'
            console.log(
              `未处理的组合类型(${this.data.columnName}: ${columnCode}) tag 属性`
            )
            break
        }
        break
      default:
        console.log(
          `未处理的类型(${this.data.columnName}:${columnCode}) tag 属性`
        )
        break
    }

    return tag
  }

  get fieldProps() {
    return {
      actionType: this.actionType,
      direction: this.direction,
      termID: this.termID,
      parentUnitID: this.parentUnitID,
      unitID: this.unitID,
      schoolDepartID: this.schoolDepartID,
      manageTypeID: this.manageTypeID,
      phaseID: this.phaseID,
      studentMark: this.studentMark,
      hiddenSchool: this.hiddenSchool,
      freshStudent: this.freshStudent,
    }
  }

  get fieldProp() {
    return this.data.columnType === Constant.ColumnType.Object
      ? void 0
      : `${this.prop}.value`
  }

  get required() {
    return this.data.columnType === Constant.ColumnType.Object
      ? this.data.required === 1
      : void 0
  }

  get rules() {
    const { columnType, columnCode } = this.data
    //非证件号
    if (columnCode !== 'idNo') {
      if (
        columnType === Constant.ColumnType.Object || // 组合组件不直接校验数据
        this.data.required !== 1 || // 非必选项不校验数据
        columnCode === 'activity' // 活动不校验
      ) {
        return void 0
      } else {
        return {
          required: true,
          trigger: columnType === Constant.ColumnType.Text ? 'blur' : 'change',
          validator: this.validator,
        }
      }
    } else {
      //证件号
      if (
        this.data.required ||
        (this.data.value && this.data.value.length > 0)
      ) {
        return {
          required: true,
          trigger: columnType === Constant.ColumnType.Text ? 'blur' : 'change',
          validator: this.validator,
        }
      } else {
        return void 0
      }
    }
  }

  created() {
    this.divideClassStudentEdit = Boolean(
      this.$route.name === 'divide-class-student-edit'
    )
  }

  render(h: CreateElement) {
    // 未生成 tag 的组件不渲染
    if (this.tag === void 0 || this.tag === null) {
      return h(void 0)
    }

    const {
      columnType,
      columnCode,
      typeIndex = 1,
      index: groupIndex = 0,
    } = this.data
    const children: VNodeChildren = []

    /** 组合字段特殊处理 */
    if (
      columnType === Constant.ColumnType.Object &&
      columnCode !== 'birthplace' /** 出生地地区选择器 */
    ) {
      switch (columnCode) {
        /** 地址类组合字段 */
        case 'address': /** 联系地址 */
        case 'nativePlace': /** 籍贯 */
        case 'registeredPlace': /** 户口所在地 */
        case 'pRegisteredPlace': /** 家长户口所在地 */
        case 'homeAddress' /** 现家庭住址 */:
          children.push(
            h(
              FormItem,
              {
                props: {
                  prop: `${this.prop}.value`,
                  rules:
                    this.data.required === 1
                      ? {
                          required: true,
                          trigger: 'change',
                          validator: this.validator,
                        }
                      : void 0,
                },
              },
              [
                h(this.tag, {
                  props: this.props,
                  attrs: this.attrs,
                  style: this.style,
                  on: { input: this.handleInput },
                }),
              ]
            ),
            ...(this.data.children || [])?.map((item, index) => {
              return h(StudentField, {
                props: {
                  data: item,
                  prop: `${this.prop}.children.${index}`,
                  ...this.fieldProps,
                },
                on: this.$listeners,
              })
            })
          )
          break

        /** 报读年级 */
        case 'gradeIntention':
          children.push(
            ...(this.data.children || [])?.map((item, index) => {
              const key = `${this.prop}.children.${index}`
              const style = {
                width: '100px',
                display: 'inline-block',
                marginRight: index === 0 ? '20px' : '0px',
              }

              return h(StudentField, {
                props: {
                  data: item,
                  prop: `${key}`,
                  ...this.fieldProps,
                },
                style,
                on: this.$listeners,
              })
            })
          )
          break

        /** 招生渠道 */
        case 'channel':
          this.data.children?.forEach(item => {
            children.push(
              h('span', { style: { marginRight: '10px' } }, [item.value])
            )
          })
          break

        /** 中考成绩 */
        case 'enrollScore':
          /** 添加成绩下拉框 */
          children.push(
            h(
              FormItem,
              {
                props: {
                  prop: `${this.prop}.value`,
                  rules:
                    this.data.required === 1
                      ? {
                          required: true,
                          trigger: 'change',
                          validator: this.validator,
                        }
                      : void 0,
                },
                style: { display: 'inline-block' },
              },
              [
                h(this.tag, {
                  props: this.props,
                  attrs: this.attrs,
                  style: this.style,
                  on: { input: this.handleInput },
                }),
              ]
            ),
            /** 添加成绩备注类信息 */
            ...(this.data.children || [])?.map((item, index) => {
              switch (this.data.value) {
                /** 学校所在地时，只显示学生分数 */
                case Constant.EnrollScore.Local:
                  if (
                    item.columnCode !== 'studentscore' &&
                    item.columnCode !== 'studentscoreArea'
                  ) {
                    return void 0
                  }
                  break
                /** 非学校所在地，不显示 无成绩说明 */
                case Constant.EnrollScore.NonLocal:
                  if (item.columnCode === 'studentscoreRemark') {
                    return void 0
                  }
                  break
                /** 无成绩时，仅显示无成绩原因 */
                case Constant.EnrollScore.No:
                  if (item.columnCode !== 'studentscoreRemark') {
                    return void 0
                  }
                  break
                default:
                  break
              }
              return h(StudentField, {
                props: {
                  data: item,
                  prop: `${this.prop}.children.${index}`,
                  ...this.fieldProps,
                },
                on: this.$listeners,
              })
            })
          )

          /** 添加成线原因啥的 */
          switch (this.data.value) {
            case Constant.EnrollScore.Local /** 学校所在地 */:
              // children.push('分数')
              break
            case Constant.EnrollScore.NonLocal /** 非学校所在地 */:
              // children.push('分数及学样所在地')
              break
            case Constant.EnrollScore.No /** 无成绩 */:
            // children.push('圾成绩原因')
            default:
              break
          }
          break

        case 'idType' + typeIndex:
          children.push(
            h(
              FormItem,
              {
                props: {
                  prop: `${this.prop}.value`,
                  rules:
                    this.data.required === 1
                      ? {
                          required: true,
                          trigger: 'change',
                          validator: this.validator,
                        }
                      : void 0,
                },
              },
              [
                h(this.tag, {
                  props: this.props,
                  attrs: this.attrs,
                  style: this.style,
                  on: { input: this.handleInput },
                }),
              ]
            ),
            ...(this.data.children || [])?.map((item, index) => {
              if (item.forceShow === 0) {
                return void 0
              }
              return h(StudentField, {
                props: {
                  data: item,
                  prop: `${this.prop}.children.${index}`,
                  ...this.fieldProps,
                },
                on: this.$listeners,
              })
            }),
            h(
              'a',
              {
                style: {
                  marginTop: '10px',
                  display:
                    typeIndex > 1
                      ? 'inline-block'
                      : this.basicCode === 'family'
                      ? this.idLength[groupIndex] > 1
                        ? 'none'
                        : 'inline-block'
                      : this.idLength > 1
                      ? 'none'
                      : 'inline-block',
                },
                attrs: { class: this.basicCode === 'family' ? groupIndex : '' },
                on: { click: typeIndex == 1 ? this.handleAdd : this.handleDel },
              },
              [`${typeIndex == 1 ? '+添加' : '-删除'}`]
            )
          )
          break
        default:
          console.log(
            `其它未处理的组合字段(${this.data.columnName}: ${this.data.columnCode})`
          )
          break
      }
    }
    // 正常字段
    else {
      // 非组合组件时，添加 input 事件
      const on: Record<string, (...args: any[]) => void> = {
        input: this.handleInput,
      }

      switch (columnCode) {
        case 'school' /** 报读校区 */:
          on['on-finish'] = this.handleOnFinish
          break
        default:
          break
      }
      children.push(
        h(this.tag, {
          props: this.props,
          attrs: this.attrs,
          style: this.style,
          on,
        })
      )

      /** 处理额外元素 */
      switch (columnCode) {
        /** 学生分数 */
        case 'studentscore':
          children.push(
            h(
              'span',
              { style: { lineHeight: '32px', marginLeft: '10px' } },
              '分'
            )
          )
          break
        /** 学生身高 */
        case 'height':
          children.push(
            h(
              'span',
              { style: { lineHeight: '32px', marginLeft: '10px' } },
              'CM'
            )
          )
          break
        /** 学生体重 */
        case 'weight':
          children.push(
            h(
              'span',
              { style: { lineHeight: '32px', marginLeft: '10px' } },
              'KG'
            )
          )
          break
        default:
          break
      }
    }

    const style: Record<string, string> = {}

    switch (columnCode) {
      /** 学生分数 */
      case 'studentscore':
        style.display = 'inline-block'
        style.marginLeft = '10px'
        break
      case 'studentscoreArea': /** 学生分数说明 */
      case 'studentscoreRemark' /** 无成绩说明 */:
      case 'idNo':
      case 'idAddress':
      case 'idDate':
        style.marginTop = '24px'
        break
      case 'school':
        style.display = this.hiddenSchool === true ? 'none' : 'inline-block'
        break
      default:
        break
    }

    return h(
      FormItem,
      {
        props: {
          prop: this.fieldProp,
          label: this.label ? this.label : void 0,
          required: this.required,
          rules: this.rules,
        },
        style,
      },
      children
    )
  }

  mounted() {}

  private validator(rule: FormRule, value: any, callback: FormRuleCallback) {
    const fullField: any = rule.fullField?.split('.')

    if (
      value === void 0 ||
      value === null ||
      value === '' ||
      (isArray(value) && value.length < 2)
    ) {
      return callback(`${this.$t('validator.non-null')}`)
    }

    //验证身份证是否合法
    if (
      this.data.columnCode === 'idNo' &&
      this.data.parentValue &&
      this.data.parentValue == 1 &&
      !this.isValidateIdCard(value)
    ) {
      return callback(`请输入正确的身份证号码`)
    }

    if (
      this.basicCode === 'noFamily' &&
      ((this.data.columnCode.indexOf('idType1') != -1 &&
        this.otherIdType[1] == value) ||
        (this.data.columnCode.indexOf('idType2') != -1 &&
          this.otherIdType[0] == value))
    ) {
      return callback(`证件类型不能重复`)
    }

    return callback()
  }

  private handleInput(value: unknown) {
    this.$emit('input', `${this.prop}.value`, value)
  }
  private handleAdd(value: any) {
    this.$emit('addID', `${this.prop}.value`, value.target.classList[0])
  }
  private handleDel(value: any) {
    this.$emit('delID', `${this.prop}.value`, value.target.classList[0])
  }

  private handleOnFinish(list: unknown[]) {
    this.$emit('on-finish', `${this.prop}.value`, list)
  }

  private isValidateIdCard(idCard: string): boolean {
    // 验证身份证号码的长度和格式
    if (!/^\d{17}(\d|x)$/i.test(idCard)) {
      return false
    }
    // 将身份证号码前17位的数字分别乘以不同的系数
    const weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    let sum = 0
    for (let i = 0; i < 17; i++) {
      sum += parseInt(idCard.charAt(i)) * weights[i]
    }
    // 根据加权和计算出校验码
    const mods = ['1', '0', 'x', '9', '8', '7', '6', '5', '4', '3', '2']
    const checkCode = mods[sum % 11]
    // 验证校验码是否正确
    return idCard.charAt(17).toLowerCase() === checkCode
  }
}
