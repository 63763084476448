



































































































































































import Vue from 'vue'
import { Component, Prop, Mixins, Watch, Ref } from 'vue-property-decorator'
import PagePropsMixins from '@mixins/page-props-mixins'
import { TableColumn } from 'view-design'
import { Session, Constant } from 'xuexin-vuex'

import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'

import AppTable from '@components/app-table'
import HomeEcharts from '../components/home-echarts'
import HomeInfo from '../components/home-info'
import { StatisticalFormSelect } from '@business-components/statistical-form'
import i18n from './i18n'

import Export from '@store/modules/export'
import ExportTyping from '../../../../types/export'
import PageTyping from '../../../../types/page'
import SchoolOverview from '@store/modules/school-overview'
import SchoolOverviewType from 'types/school-overview'
import StudentDistribution from '@store/modules/student-distribution'
import { setReportData, spanChildMethod } from '@util/report'
import getPageColumns from '@components/report-toolbar/columns'

const components = {
  ViewLayout,
  ReportSearch,
  ReportToolbar,
  AppTable,
  StatisticalFormSelect,
  HomeEcharts,
  HomeInfo,
}
@Component({ name: 'SchoolOverview', components, i18n })
export default class SchoolOverviewPage extends Mixins(PagePropsMixins) {
  @Session.Getter('user') private readonly getUser!: Session.Entity
  @SchoolOverview.Action('fetch') private readonly fetchTable!: any
  @StudentDistribution.Action('export') private readonly export!: any

  @SchoolOverview.Getter('list')
  private readonly getList!: SchoolOverviewType.Getter.List
  @SchoolOverview.Getter('listStatus')
  private readonly getListStatus!: SchoolOverviewType.Getter.ListStatus

  private readonly prefixCls = 'school-overview-page'
  private parameter: any = {
    endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  }
  private searchParameter: any = {}
  private tParameter: any = {
    countType: 0,
  }
  private defaultData: any = []
  private rowspans: any = {} //合并行列标记对象{key1：number[]，key2：number[]} key: 合并项数对应的列头(parentUnitName,manageTypeName……), 'number[]':当前列中每个单元格应合并的行数
  private sumKey: string[] = [] //选中显示的列头集合
  private inventedDataIndexArr: any = [] //小记行索引和项数{index:小记行在列表中的索引，type:小记几项}
  /** 判断是机构登录还是学校登录（机构默认1，学校默认2） */
  private statisticType: number = 1
  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  get noDataText() {
    if (Boolean(this.parameter.termID) === false) {
      return this.$t('message.noParameter') as string
    }
    return this.$t('message.noDataText') as string
  }
  /** 若school为学校用户   mechanism是集团用户 ordinary普通用户*/
  get identityType() {
    this.getUser.identityType === Constant.IdentityType.School
      ? (this.statisticType = 2)
      : (this.statisticType = 1)
    return this.getUser.identityType === Constant.IdentityType.School
      ? 'school'
      : this.getUser.identityType === Constant.IdentityType.Organization
      ? 'mechanism'
      : 'ordinary'
  }
  /** 学生统计类型 */
  get statisticData() {
    const statisticData = [
      {
        label: this.$t('message.schoolDepart') as string,
        value: 2,
      },
      {
        label: this.$t('message.manageType') as string,
        value: 3,
      },
    ]
    if (this.identityType === 'mechanism') {
      statisticData.unshift({
        label: this.$t('message.school') as string,
        value: 1,
      })
    }
    return statisticData
  }
  // 表头
  get tableColumns(): any {
    return getPageColumns('SchoolOverview')
      .map((item: any) => {
        return {
          title: item.title,
          key: item.code,
          width: 100,
          fixed: 'left',
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat([
        {
          title: '年级数',
          align: 'center',
          key: 'gradeCount',
        },
        {
          title: '班级数',
          align: 'center',
          key: 'classCount',
        },
        {
          title: '在班人数',
          align: 'center',
          key: 'inClassCount',
        },
        {
          title: '班均人数',
          align: 'center',
          key: 'classAvgCount',
        },
        {
          title: '未分班',
          align: 'center',
          key: 'noClassCount',
        },
        {
          title: '在校生人数',
          align: 'center',
          key: 'studentCount',
        },
        {
          title: '优生人数',
          align: 'goodCount',
          key: 'gradeCount',
        },
        {
          title: '优生目标数',
          align: 'center',
          key: 'targetCount',
        },
        {
          title: '优生完成率',
          align: 'center',
          key: 'goodCompleteRate',
        },
      ])
  }

  get dataSources(): any {
    return this.getList()
  }
  get statistic(): number {
    return this.statisticType
  }

  set statistic(value) {
    this.statisticType = value
  }

  // 获取列表请求状态
  get listStatus() {
    return this.getListStatus()
  }
  get fetching() {
    return this.listStatus.fetching
  }
  // 监听加载状态
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.listStatus.fetchingError !== null &&
        this.listStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.listStatus.fetchingError)
        return
      }

      let result = setReportData({
        list: this.dataSources.table,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
        countColumn: [
          'gradeCount',
          'classCount',
          'inClassCount',
          'classAvgCount',
          'noClassCount',
          'studentCount',
          'gradeCount',
          'targetCount',
        ],
        percentageColumn: ['goodCompleteRate'],
      })
      this.defaultData = result.dataArr
      this.inventedDataIndexArr = result.indexArr
      this.rowspans = result.rowspans
    }
  }

  created() {
    this.sumKey = getPageColumns('ClassesCount').map((item: any) => {
      return item.code
    })
  }
  mounted() {}
  private handleType(value: number) {
    this.statistic = value
  }

  private handleStatisticalForm(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
      countColumn: [
        'gradeCount',
        'classCount',
        'inClassCount',
        'classAvgCount',
        'noClassCount',
        'studentCount',
        'gradeCount',
        'targetCount',
      ],
      percentageColumn: ['goodCompleteRate'],
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }
  // 列表列头发生变化
  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.handleSearch(this.searchParameter)
  }
  // 合并行列方法
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }

  // 小计行的样式
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      if (row.hide) {
        return 'ivu-table-summary-row ivu-table-hide-row'
      }
      return 'ivu-table-summary-row'
    }
  }

  private fetch() {
    this.fetchTable(
      Object.assign({}, this.searchParameter, this.tParameter, {
        columns: this.sumKey,
        endDate: this.searchParameter.endDate,
      })
    )
  }
  private handleExport(parameter: any) {
    this.export(
      Object.assign({}, parameter, {
        columns: this.sumKey,
        countType: this.tParameter.countType,
        type: 'studentStatis',
      })
    ).then((res: any) => {
      if (res.result) {
        window.location.href = `${res.result}`
      }
    })
  }
  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    if (Boolean(parameter.termID) === false) {
      return this.$Message.warning(`${this.$t('message.noTermID')}`)
    }

    this.$set(this.$data, 'searchParameter', parameter)
    this.fetch()
  }
}
