import Vue, { CreateElement, VNode } from 'vue'
import { Component, Watch, Prop } from 'vue-property-decorator'
import { StatisticalFormSelect } from '@business-components/statistical-form'
import { ContentTypeSelect } from '@business-components/statistical-content-type'
import PageTyping from '../../../types/page'
import Page from '@store/modules/page'

import { Button, Checkbox, Dropdown, DropdownMenu } from 'view-design'
import CheckboxGroup from '@components/app-checkbox-group'

import i18n from './i18n'
import './report-toolbar.scss'
import getPageColumns from './columns'

type CheckboxProp = {
  label: string
  value: string
  style: Record<string, any>
  disabled: boolean
}

type Parameter = {
  countType: number | string
  countContentType: number
  column: Object
}

const components = {}
@Component({ name: 'DynamicToolbar', components, i18n })
export default class DynamicToolbar extends Vue {
  @Page.Action('update')
  private readonly updateColumns!: PageTyping.Action.Update
  @Page.Getter('columns')
  private readonly getColumns!: PageTyping.Getter.Columns
  @Page.Getter('auths') private readonly getAuths!: PageTyping.Getter.Auths
  @Prop({ type: [String, Number], default: 0 }) private readonly default!:
    | number
    | string
  @Prop({ type: String, default: '' }) private readonly pageName!: string
  @Prop({ type: Boolean, default: false })
  private readonly showClassify!: boolean

  private readonly prefixCls = 'report-toolbar'
  private parameter: Partial<Parameter> = { countType: 0, countContentType: 1 }
  private selection: string[] = []
  private checkboxs: CheckboxProp[] = []

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get authCode() {
    return this.$route.meta.authCode
  }

  get columns(): PageTyping.Column[] {
    return getPageColumns(this.pageName)
  }
  get auths() {
    return this.getAuths(this.authCode).filter(item => item.type === 1)
  }

  @Watch('columns', { immediate: true })
  watchColumns(columns: PageTyping.Column[]) {
    /** 当前选中的列 */
    const selection: string[] = []
    /** 列数 */
    const columnCount = 6
    /** 行数 */
    const rowCount = Math.ceil(columns.length / columnCount) - 1
    /** 满排满列总数量 */
    const threshold = rowCount * columnCount

    /** 遍历列头，拼装数据 */
    const checkboxs = columns.map<CheckboxProp>((item, index) => {
      /** 添加已选列 */
      if (item.show !== 0) {
        selection.push(item.code)
      }
      /** 是否为行末 */
      const isEol = (index + 1) % columnCount === 0
      /** 是否溢出 */
      const isOverflow = index >= threshold

      /** 返回 checkbox-group 数组元素 */
      return {
        label: item.title,
        value: item.code,
        style: {
          width: '130px',
          marginRight: `${isEol ? '0' : '10'}px`,
          marginBottom: `${isOverflow ? '0' : '10'}px`,
        },
        disabled: item.fixed === 1,
      }
    })

    this.$set(this.$data, 'selection', selection)
    this.$set(this.$data, 'checkboxs', checkboxs)
  }

  render(createElement: CreateElement) {
    const children: VNode[] = []
    const TextContainer: VNode[] = []

    /** 小记下拉菜单 */
    children.push(
      createElement(StatisticalFormSelect, {
        attrs: Object.assign({}, this.$attrs, {
          value: this.parameter.countType,
        }),
        on: {
          'on-change': this.handleAmountSelectChange,
        },
      })
    )

    /** 统计内容 */
    if (this.showClassify === true) {
      TextContainer.push(
        createElement(ContentTypeSelect, {
          attrs: Object.assign({}, this.$attrs, {
            value: this.parameter.countContentType,
          }),
          class: `${this.prefixCls}-text`,
          on: {
            'on-contentType': this.handleContentTypeTextChange,
          },
        })
      )
    }

    return createElement('div', { class: this.classes }, [
      /** 权限按钮容器 */
      createElement('div', { class: `${this.prefixCls}-inner` }, children),
      TextContainer,
      /** 显示列按钮 */
      createElement(
        Dropdown,
        {
          class: `${this.prefixCls}_show-columns`,
          props: {
            trigger: 'click',
            placement: 'bottom-end',
            'transfer-class-name': `${this.prefixCls}_dropdown`,
          },
          on: {
            'on-visible-change': this.handleDropdownVisibleChange,
            'on-clickoutside': this.handleDropdownClickOutside,
          },
        },
        [
          /** 激活下拉菜单按钮 */
          createElement(Button, { props: { type: 'info', size: 'small' } }, [
            createElement('span', {}, [`${this.$t('text.columns')}`]),
          ]),
          /** 自定义下拉菜单内容 */
          createElement(DropdownMenu, { slot: 'list' }, [
            createElement(CheckboxGroup, {
              props: { data: this.checkboxs },
              attrs: { value: this.selection },
              on: {
                'on-change': this.handleCheckboxGroupChange,
              },
            }),
          ]),
        ]
      ),
    ])
  }

  mounted() {
    this.$set(
      this.$data.parameter,
      'column',
      this.columns.map(item => {
        return item.code
      })
    )
  }

  private handleDropdownVisibleChange() {}

  private handleDropdownClickOutside() {}

  private handleAmountSelectChange(value: any) {
    this.$set(this.$data.parameter, 'countType', value)
    this.$emit('input', this.parameter)
    this.$emit('on-change-type', value)
  }
  private handleCheckboxGroupChange(selection: string[]) {
    this.$set(this.$data, 'selection', selection)
    if (selection.length === 0) {
      return this.$Message.warning('请至少选择一项统计项')
    }
    this.$set(this.$data.parameter, 'column', selection)
    this.$emit('input', this.parameter)
    this.$emit('on-change-column', selection)
  }

  private handleContentTypeTextChange(value: number) {
    this.$set(this.parameter, 'contentType', value)
    this.$emit('input', this.parameter)
    this.$emit('on-change', this.parameter)
  }
}
