import { Project } from '../../../types/index'
import { namespace as BindingNamespace } from 'vuex-class'
import Ajax from 'xuexin-vuex/src/ajax'
import omit from 'lodash.omit'
import {
  handlers,
  getListState,
  mergeToCompositeState,
} from 'xuexin-vuex/src/util'

export const namespace = '@xuexin-vuex/Project'

export const types = {
  // 查询年级列表
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
}

function getListKey(parameter: Project.Parameter) {
  const { unitID = -1 } = parameter

  return `${unitID}`
}

const state: Project.State = {
  list: {},
  items: {},
}

const actions: Project.Actions = {
  fetchList(context, payload) {
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload,
      api: `get ${__STUDENT_API__}/project/list`,
      parameter: payload,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
}

const mutations: Project.Mutations = {
  [types.FETCH_REQUEST](state, { payload }) {
    const listState = getListState(state, getListKey(payload))
    handlers.request(listState, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { payload, result, total }: any) {
    const listKey = getListKey(payload)
    mergeToCompositeState(state, listKey, 'projectID', result, total)
    const listState = getListState(state, listKey)
    handlers.success(listState, 'fetching')
  },
  [types.FETCH_FAILURE](state, { payload, message }) {
    const listState = getListState(state, getListKey(payload))
    handlers.failure(listState, 'fetching', message)
  },
}

const getters: Project.Getters = {
  itemStatus(state) {
    return id => omit(state.items[`${id}`], ['data'])
  },
  item(state) {
    return id => state.items[`${id}`]?.data
  },
  listStatus(state) {
    return parameter => omit(state.list[getListKey(parameter)], ['data'])
  },
  list(state, getters) {
    return parameter => {
      const listState = state.list[getListKey(parameter)] || { data: [] }
      return listState.data.map(id => getters.item(id)!)
    }
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
