export default {
  messages: {
    'zh-CN': {
      title: '休学',
      label: {
        name: '学生姓名',
        modifyDate: '休学日期',
        remark: '休学原因',
      },
      verification: {
        remark: '请填写休学原因',
      },
    },
    'en-US': {},
  },
}
