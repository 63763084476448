import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { SchoolClassType } from 'xuexin-vuex'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'

const valueField = 'unitClassTypeID'
const labelField = 'unitClassTypeName'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `SchoolClassType${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class SchoolClassTypeComponent extends Vue {
    @Prop({ type: [Number, Array] }) private readonly parentUnitID?: any
    @Prop({ type: [Number, Array] }) private readonly unitID?: any
    @Prop({ type: [Number, Array] }) private readonly schoolDepartID?: any
    @Prop({ type: [Number, Array] }) private readonly phaseID?: any
    @Prop({ type: [Number, Array] }) private readonly schoolSystemID?: any
    @Prop({ type: [Number, Array] }) private readonly manageTypeID?: any
    @Prop({ type: [Number, Array] }) private readonly classTypeID?: any
    @Prop({ type: [Number, Array] }) private readonly projectID?: any
    @Prop({ type: [Number, String] }) private readonly direction?:
      | number
      | string
    @Prop({ type: String, default: null }) private readonly requestType?: string
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: Boolean, default: true }) private readonly cache!: boolean
    @Prop({ type: String }) private readonly viewType?: string
    @Prop({ type: Boolean, default: false }) private readonly transfer!: boolean

    @SchoolClassType.Action('fetchList')
    private readonly fetchList!: SchoolClassType.Action.FetchList
    @SchoolClassType.Getter('listStatus')
    private readonly getStatus!: SchoolClassType.Getter.ListStatus
    @SchoolClassType.Getter('list')
    private readonly getList!: SchoolClassType.Getter.List

    get classes() {
      return {
        [`school-class-type-${type}`]: true,
        [`school-class-type-${type}-${this.viewType}`]:
          this.viewType !== void 0,
      }
    }

    get props() {
      const props: Record<string, any> = {}

      switch (type) {
        case ComponentType.Select:
          props.loading = this.fetching
          props.error = this.status.fetchingError
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    get parameter() {
      return {
        parentUnitID: this.parentUnitID,
        unitID: this.unitID,
        schoolDepartID: this.schoolDepartID,
        phaseID: this.phaseID,
        schoolSystemID: this.schoolSystemID,
        manageTypeID: this.manageTypeID,
        classTypeID: this.classTypeID,
        direction: this.direction,
        requestType: this.requestType,
        transfer: this.transfer,
      }
    }

    get status() {
      return this.getStatus(this.parameter)
    }

    get fetching() {
      return this.status.fetching !== false
    }

    get valid() {
      return Boolean(this.unitID) || this.unitID === 0
    }

    get list() {
      if (this.valid !== true) {
        return this.extra
      }

      const list = this.getList(this.parameter).filter(i =>
        this.projectID ? i.projectID === this.projectID : true
      )
      return this.extra.concat(list.filter(item => item.enable !== 0))
    }

    get value() {
      return Number(this.$attrs.value)
    }

    @Watch('parameter')
    watchParameter() {
      this.fetch()
    }

    created() {
      this.fetch()
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示*/
      if (this.viewType === 'text') {
        tag = 'span'
        const item = this.list.find(item => item[valueField] === this.value)
        const text =
          item !== void 0
            ? item[labelField]
            : this.$i18n.locale === 'en-US'
            ? '-'
            : '无匹配项'
        children = [text]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }

    private fetch() {
      if (
        this.status.fetching === true || // 正在请求中
        // (this.cache === true && this.status.loaded === true) || // 允许缓存且已有缓存
        this.valid !== true // 无效参数
      ) {
        // 以上两种情况，不需要请求数据
        return void 0
      }

      this.fetchList(this.parameter)
    }
  }

  return SchoolClassTypeComponent
}

export const SchoolClassTypeSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
