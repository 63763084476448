/** 学生一览表 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import StudentOverview from '@pages/statistic-reports/student-overview'

const children: RouteConfig[] = []

/** 学生一览表 */
children.push({
  path: '/reports/student-overview',
  name: 'student-overview-report',
  component: StudentOverview,
  meta: { ignore: true, authCode: '050702', parentAuthCode: '0507' },
})

export default {
  path: '/reports/student-overview',
  name: 'student-overview',
  redirect: { name: 'student-overview-report' },
  meta: { title: '学生一览表', authCode: '050702' },
  component: RouterViewLayout,
  props: { name: 'student-overview' },
  children,
}
