

















































import Vue from 'vue'
import i18n from './i18n'
import AppModal from '@components/app-modal'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import Records from '@store/modules/records'
import { Model, Component, Prop, Watch } from 'vue-property-decorator'
import { Input, Button } from 'view-design'
const components = { AppModal, AppTable, AppPagination, Input, Button }

type DataType = {
  recordID: number
}

type searchModel = {
  name: string
  mobile: number
}

@Component({ name: 'SuccessModal', components, i18n })
export default class SuccessModal extends Vue {
  @Model('input') private value!: any
  @Records.Action('fetch') private readonly fetchList!: Records.Action.Fetch
  @Records.Getter('item') private readonly item!: Records.Getter.Item
  @Records.Getter('itemStatus')
  private readonly itemStatus!: Records.Getter.ItemStatus
  @Prop({ default: () => ({}) }) private readonly data!: DataType

  private readonly prefixCls = 'success-modal'
  private search: Partial<searchModel> = {}
  private page = 1
  private pageSize = 10
  private visible = false
  private loading = true

  get tableColumns() {
    // prettier-ignore
    return [
      { title:this.$t('table.index'), key: 'num', width: 80, align: 'center'},
      { title: this.$t('table.name'), key: 'name' },
      { title: this.$t('table.mobile'), key: 'mobile' },
    ]
  }

  get recordID() {
    return this.data?.recordID
  }

  get parameter() {
    return {
      sendStatus: 1 /**成功或者失败状态 */,
      recordID: this.recordID,
    }
  }

  get fetching() {
    return this.itemStatus(this.recordID)?.fetching === true
  }

  get fetchingError() {
    return this.itemStatus(this.recordID).fetchingError || null
  }

  get total() {
    return this.item(this.recordID)?.successCount
  }

  get tableData() {
    const successList: any = this.item(this.recordID)?.child || []
    return successList.map((item: Records.Entity, index: number) => {
      const list = Object.assign({}, item)
      list.num = (this.page - 1) * this.pageSize + index + 1
      return list
    })
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 窗口没有打开不处理状态 */
    if (this.value === false || this.visible === false) {
      return void 0
    }

    if (fetching === false && previous === true) {
      if (this.fetchingError !== null) {
        return this.$Message.error(this.fetchingError)
      }
    }
  }

  /** 条件查询 */
  private handleSearch() {
    this.fetch()
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      this.fetch()
    }

    this.$emit('on-visible-change')
  }

  private handleHidden() {
    this.$emit('on-hidden')
  }

  private stopAction() {
    this.$set(this.$data, 'loading', false)
    this.$nextTick(() => (this.loading = true))
  }

  private fetch() {
    if (this.fetching === true /** 请求中不重复请求 */) {
      return
    }
    this.fetchList(
      Object.assign({}, this.parameter, this.search, {
        page: this.page,
        pageSize: this.pageSize,
      })
    )
  }

  /** 页码发生变化 */
  private handlePageChange(page: number): void {
    /** 修改本地 `page` 属性值，清空已选数据，并重新请求数据。 */
    this.$set(this.$data, 'page', page)
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number): void {
    /**  修改本地 `pageSize` 属性值，若当前 `page` 为 1，则手动请求数据。 */
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }
}
