import { Glass } from 'types'
import { namespace as BindingNamespace } from 'vuex-class'
import Ajax from 'xuexin-vuex/src/ajax'
import Api from 'xuexin-vuex/src/api'
import omit from 'lodash.omit'
import {
  handlers,
  getListState,
  mergeToCompositeState,
  updateState,
} from 'xuexin-vuex/src/util'
import { ActionType } from 'xuexin-vuex/src/constant'
import filterData from '../../../filterData.json'
import transfer from '@routes/students/transfer'
const fileterMap: Record<string | number, boolean> = filterData.unitIDs

export const namespace = '@xuexin-vuex/glass'
export const types = {
  /** 创建班级 */
  CREATE_REQUEST: 'CREATE_REQUEST',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_FAILURE: 'CREATE_FAILURE',

  /** 删除班级 */
  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',

  /** 更新班级信息 */
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',

  /** 查询班级信息 */
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
}

function getListKey(parameter: Glass.Parameter) {
  const {
    unitID = -1, //校区id
    phaseID = -1, //学段id
    schoolSystemID = -1, // 学制id
    manageTypeID = -1, //报读类型id
    unitClassTypeID = -1, //校本班型id
    unitGradeID = -1, //学校年级id
  } = parameter

  return `unit:${unitID}&sp:${phaseID}&ss:${schoolSystemID}&mt:${manageTypeID}&uct:${unitClassTypeID}&ugi:${unitGradeID}`
}

const state: Glass.State = {
  list: {},
  items: {},
}

const actions: Glass.Actions = {
  create(context, payload) {
    return Ajax(context, {
      type: types.CREATE_REQUEST,
      payload,
      api: `post ${Api.student}/classes`,
      parameter: payload.data,
      success: types.CREATE_SUCCESS,
      failure: types.CREATE_FAILURE,
    })
  },

  delete(context, payload) {
    let type = types.DELETE_REQUEST
    let api
    let { actionType, termID, classID } = payload
    let success = types.DELETE_SUCCESS
    let failure = types.DELETE_FAILURE

    switch (actionType) {
      case ActionType.Undo /** 撤班 */:
        api = `delete ${Api.student}/classes/${classID}/1?termID=${termID}`
        break
      default:
        api = `delete ${Api.student}/classes/${classID}/0?termID=${termID}`
        break
    }

    return Ajax(context, { type, payload, api, success, failure })
  },

  update(context, payload) {
    return Ajax(context, {
      type: types.UPDATE_REQUEST,
      payload,
      api: `post ${Api.student}/classes/update`,
      parameter: payload.data,
      success: types.UPDATE_SUCCESS,
      failure: types.UPDATE_FAILURE,
    })
  },
  changeLimcount(context, payload) {
    return Ajax(context, {
      type: types.UPDATE_REQUEST,
      payload,
      api: `post ${Api.student}/classes/change/limitCount`,
      parameter: payload,
      success: types.UPDATE_SUCCESS,
      failure: types.UPDATE_FAILURE,
    })
  },

  fetch(context, payload) {
    let type = types.FETCH_REQUEST
    let api
    const {
      searches = { unitID: 0, schoolDepartID: 0, transfer: false },
    } = payload
    let { actionType, viewType, ...parameter } = payload
    let success = types.FETCH_SUCCESS
    let failure = types.FETCH_FAILURE
    let url = ''
    if (
      searches.transfer &&
      fileterMap[`${searches.unitID}`] &&
      `${searches.schoolDepartID}` === '4'
    ) {
      url = `post ` + __STUDENT_INTL_API__ + `/classes/list`
    } else {
      url = `post ` + Api.student + `/classes/list`
    }

    switch (actionType) {
      case ActionType.Upgrade /** 查询直升信息 */:
        api = `get ${Api.student}/students/graduation/upgrade`
        break
      case ActionType.Glass /** 查询班级列表 */:
        api = url
        break
      case ActionType.ByStudent /** 根据申请就读信息查询班级列表 */:
        api = `post ${Api.student}/divideStudent/info`
        break
      case ActionType.Create /** 生成班级名称 */:
        api = `get ${Api.student}/classes/className`
        if (viewType === 'create' || viewType === 'batch-create') {
          parameter = omit(parameter, ['classID'])
        }
        break
      case ActionType.Workflow /** 提交班级审批 */:
        api = `post ${Api.student}/classes/workflow/${parameter.classID}/${parameter.termID}`
        break
      default:
        /** 默认查询班级信息 */
        api = `get ${Api.student}/classes/${payload.classID}`
        break
    }

    return Ajax(context, { type, payload, api, parameter, success, failure })
  },
}

const mutations: Glass.Mutations = {
  // 创建班级
  [types.CREATE_REQUEST](state, { payload }) {
    handlers.itemRequest(state, payload.classID, 'creating')
  },
  [types.CREATE_SUCCESS](state, { payload }) {
    handlers.itemSuccess(state, payload.classID, 'creating')
  },
  [types.CREATE_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, payload.classID, 'creating', message)
  },

  // 删除班级
  [types.DELETE_REQUEST](state, { payload }) {
    handlers.itemRequest(state, payload.classID, 'deleting')
  },
  [types.DELETE_SUCCESS](state, { payload }) {
    handlers.itemSuccess(state, payload.classID, 'deleting')
  },
  [types.DELETE_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, payload.classID, 'deleting', message)
  },

  // 更新班级
  [types.UPDATE_REQUEST](state, { payload }) {
    handlers.itemRequest(state, payload.classID, 'updating')
  },
  [types.UPDATE_SUCCESS](state, { payload, parameter }) {
    handlers.itemSuccess(state, payload.classID, 'updating', parameter)
  },
  [types.UPDATE_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, payload.classID, 'updating', message)
  },

  // 查询班级相关数据
  [types.FETCH_REQUEST](state, { payload }) {
    switch (payload.actionType) {
      case ActionType.Glass /** 查询班级列表 */:
      case ActionType.ByStudent /** 根据申请就读信息查询班级列表 */:
        {
          const listState = getListState(state, getListKey(payload.searches))
          handlers.request(listState, 'fetching')
        }
        break
      case ActionType.Create /** 生成班级名称 */:
      case ActionType.Upgrade /** 查询直升信息 */:
      default:
        /** 默认查询班级信息/ 提交班级审批 */
        handlers.itemRequest(state, payload.classID, 'fetching')
        break
    }
  },
  [types.FETCH_SUCCESS](state, { payload, result, total }) {
    switch (payload.actionType) {
      case ActionType.Glass /** 查询班级列表 */:
      case ActionType.ByStudent /** 根据申请就读信息查询班级列表 */:
        {
          const listKey = getListKey(payload.searches)
          // prettier-ignore
          mergeToCompositeState(state, listKey, 'classID', result,total, (item: Glass.Entity) => {
              let { phaseID, unitGradeName } = item
              const gradeName = phaseID === 1 ? '' : `(${item.gradeName})`
              const fullGradeName = `${unitGradeName}${gradeName}`
              return (item.fullGradeName = fullGradeName), item
            }
          )
          const listState = getListState(state, listKey)
          handlers.success(listState, 'fetching')
        }
        break
      case ActionType.Create /** 生成班级名称 */:
        {
          let item: Partial<Glass.Entity> = {}
          if (result !== void 0 && result !== null) {
            item.stringBuilderid = result.stringBuilderid
            // prettier-ignore
            item.className = ([] as string[]).concat(result.className).join('、')
          }
          handlers.itemSuccess(state, payload.classID, 'fetching', item)
        }
        break
      case ActionType.Upgrade /** 查询直升信息 */:
        {
          handlers.itemSuccess(state, payload.classID, 'fetching')
          const item = state.items[`${payload.classID}`]
          if (item.extra === void 0) {
            updateState(item, 'extra', {})
          }
          updateState(item.extra, 'upgrade', result)
        }
        break
      case ActionType.Workflow:
        handlers.itemSuccess(state, payload.classID, 'fetching')
        break
      default:
        /** 默认查询班级信息 */
        // prettier-ignore
        if(result){
          result.fullGradeName = result.unitGradeName || null
            if (result.phaseID !== 1) {
              result.fullGradeName = Boolean(result.gradeName)&& Boolean(result.unitGradeName)
                ? `${result.unitGradeName}(${result.gradeName})`
                : result.gradeName
            }
        }
        handlers.itemSuccess(state, payload.classID, 'fetching', result)
        break
    }
  },
  [types.FETCH_FAILURE](state, { payload, message }) {
    switch (payload.actionType) {
      case ActionType.Glass /** 查询班级列表 */:
      case ActionType.ByStudent /** 根据申请就读信息查询班级列表 */:
        {
          const listState = getListState(state, getListKey(payload.searches))
          handlers.failure(listState, 'fetching', message)
        }
        break
      case ActionType.Create /** 生成班级名称 */:
      case ActionType.Upgrade /** 查询直升信息 */:
      default:
        /** 默认查询班级信息 */
        handlers.itemFailure(state, `${payload.classID}`, 'fetching', message)
        break
    }
  },
}

const getters: Glass.Getters = {
  itemStatus(state) {
    return classID => omit(state.items[`${classID}`], ['data'])
  },
  item(state) {
    return classID => state.items[`${classID}`]?.data
  },
  listStatus(state) {
    return parameter => omit(state.list[getListKey(parameter)], ['data'])
  },
  list(state, getters) {
    return parameter => {
      const listState = state.list[getListKey(parameter)] || { data: [] }
      return listState.data.map(id => getters.item(id)!)
    }
  },
  extra(state) {
    return (classID, name) => {
      const item = state.items[`${classID}`] || {}
      const extra = item.extra || {}
      if (name !== void 0 && name !== null) {
        return extra[name] || {}
      }
      return extra
    }
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
