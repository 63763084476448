import { Dictionary } from '../../../types/index'
import { namespace as BindingNamespace } from 'vuex-class'
import Ajax from 'xuexin-vuex/src/ajax'
import Api from 'xuexin-vuex/src/api'
import omit from 'lodash.omit'
import {
  handlers,
  getListState,
  mergeToCompositeState,
} from 'xuexin-vuex/src/util'
import { ActionType } from 'xuexin-vuex/src/constant'

export const namespace = '@xuexin-vuex/Dictionary'

export const types = {
  // 查询年级列表
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
}

function getListKey(parameter: Dictionary.Payload) {
  const {
    catalogID = -1,
    // phaseID = -1, //学段id
    // freshStudent = 0, //学部id
  } = parameter

  return `${catalogID}`
}

const state: Dictionary.State = {
  list: {},
  items: {},
}

const actions: Dictionary.Actions = {
  fetchList(context, payload) {
    const { catalogID, ...parameter } = payload
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload,
      api: `get ${__STUDENT_API__}/base/dics/${catalogID}`,
      parameter: parameter,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
}

const mutations: Dictionary.Mutations = {
  [types.FETCH_REQUEST](state, { payload }) {
    const listState = getListState(state, getListKey(payload))
    handlers.request(listState, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { payload, result, total }: any) {
    const listKey = getListKey(payload)
    // prettier-ignore
    for(let key in result){
      mergeToCompositeState(state, key, 'dataValue', result[key], total, (item: Dictionary.Entity) => {
        return item
      })
    }
    const listState = getListState(state, listKey)
    handlers.success(listState, 'fetching')
  },
  [types.FETCH_FAILURE](state, { payload, message }) {
    const listState = getListState(state, getListKey(payload))
    handlers.failure(listState, 'fetching', message)
  },
}

const getters: Dictionary.Getters = {
  itemStatus(state) {
    return id => omit(state.items[`${id}`], ['data'])
  },
  item(state) {
    return id => state.items[`${id}`]?.data
  },
  listStatus(state) {
    return parameter => omit(state.list[getListKey(parameter)], ['data'])
  },
  list(state, getters) {
    return parameter => {
      const listState = state.list[getListKey(parameter)] || { data: [] }
      return listState.data.map(id => getters.item(id)!)
    }
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
