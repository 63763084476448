import Vue, { CreateElement, VNodeData, VNodeChildren } from 'vue'
import i18n, { StudentStatus } from '../i18n'
import { Prop, Component } from 'vue-property-decorator'

const messages: Messages = {
  'zh-CN': {
    view: '查看',
  },
  'en-US': {
    view: '查看',
  },
}

@Component({ name: 'StudentStatusTag', i18n })
export default class StudentStatusTag extends Vue {
  @Prop({ type: [Number, String], required: true }) private readonly value!:
    | number
    | string

  private readonly prefixCls = 'student-status'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get isCanView() {
    /** 以下状态时，可以查看当前状态信息 */
    return (
      this.value === StudentStatus.Suspension /** 休学状态 */ ||
      this.value === StudentStatus.LeaveSchool /** 离校状态 */
    )
  }

  render(createElement: CreateElement) {
    const data: VNodeData = {
      class: this.classes,
    }

    const children: VNodeChildren = []

    /** 学生状态 */
    children.push(
      createElement('span', { class: `${this.prefixCls}_text` }, [
        `${this.$t(`${this.value}`)}`,
      ])
    )

    /** 是否可以查看状态信息 */
    if (this.isCanView === true) {
      children.push(
        createElement(
          'span',
          {
            class: `text-link ${this.prefixCls}_view-button`,
            style: { marginLeft: '5px' },
            on: {
              click: this.handleClick,
            },
          },
          [messages[this.$i18n.locale]['view']]
        )
      )
    }

    return createElement('span', data, children)
  }

  private handleClick() {
    this.$emit('click')
  }
}
