import Vue from 'vue'
import ClassPayment from '../../../types/class-payment'
import { namespace as BindingNamespace } from 'vuex-class'
import omit from 'lodash.omit'
import { handlers } from 'xuexin-vuex/src/util'
import { Ajax } from 'xuexin-vuex/src/ajax'

export const namespace = '@xuexin-vuex/classPayment'
export const types = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',

  EXPORT_REQUEST: 'EXPORT_REQUEST',
  EXPORT_SUCCESS: 'EXPORT_SUCCESS',
  EXPORT_FAILURE: 'EXPORT_FAILURE',
}

const state: ClassPayment.State = {
  list: { data: [] },
}
const actions: ClassPayment.Actions = {
  fetch(context, payload) {
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload: payload,
      api: `get ${__FINANCE_API__}/report/classcharge`,
      parameter: payload,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
  //导出
  export(context, payload) {
    return Ajax(context, {
      type: types.EXPORT_REQUEST,
      payload,
      api: `get ${__FINANCE_API__}/report/classcharge/export`,
      parameter: payload,
      success: types.EXPORT_SUCCESS,
      failure: types.EXPORT_FAILURE,
    })
  },
}

const mutations: ClassPayment.Mutations = {
  [types.FETCH_REQUEST](state) {
    handlers.request(state.list, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { result, total }) {
    const item = state.list
    Vue.set(item, 'data', result)
    Vue.set(item, 'total', total)
    handlers.success(item, 'fetching')
  },
  [types.FETCH_FAILURE](state, { message }) {
    handlers.failure(state.list, 'fetching', message)
  },
  [types.EXPORT_REQUEST](state, { payload }) {
    handlers.request(state.list, 'deleting')
  },
  [types.EXPORT_SUCCESS](state, { payload }) {
    handlers.success(state.list, 'deleting')
  },
  [types.EXPORT_FAILURE](state, { payload, message }) {
    handlers.failure(state.list, 'deleting', message)
  },
}

const getters: ClassPayment.Getters = {
  list() {
    return () => state.list?.data
  },
  listStatus() {
    return () => omit(state.list, ['data'])
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
