import { namespace as BindingNamespace } from 'vuex-class'
import { Ajax } from 'xuexin-vuex/src/ajax'
import { handlers, coverToState, updateState } from 'xuexin-vuex/src/util'
import { Records } from 'types'
import omit from 'lodash.omit'

export const namespace = '@xuexin-vuex/records'

export enum ActionType {
  /** 导入记录查询 */
  FETCH_IMPORT = 1,
  /** 操作记录查询 */
  FETCH_OPERATE = 2,
  /** 操作记录查询 */
  FETCH_RECORD = 3,
}

export const types = {
  FETCH_LIST_REQUEST: 'FETCH_LIST_REQUEST',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
  FETCH_LIST_FAILURE: 'FETCH_LIST_FAILURE',

  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',

  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',

  CANCEL_REQUEST: 'CANCEL_REQUEST',
  CANCEL_SUCCESS: 'CANCEL_SUCCESS',
  CANCEL_FAILURE: 'CANCEL_FAILURE',

  CLEAN_LIST: 'CLEAN_LIST',
}

const state: Records.State = {
  list: { data: [] },
  items: {},
}

const actions: Records.Actions = {
  fetchList(context, payload) {
    let api = `get ${__STUDENT_API__}/import/record`
    if (payload.actionType === ActionType.FETCH_IMPORT) {
      api = `get ${__STUDENT_API__}/import/record`
    }

    if (payload.actionType === ActionType.FETCH_OPERATE) {
      api = `post ${__STUDENT_API__}/oper/records`
    }

    if (payload.actionType === ActionType.FETCH_RECORD) {
      api = `get ${__STUDENT_API__}/sms/records`
    }

    return Ajax(context, {
      type: types.FETCH_LIST_REQUEST,
      payload: payload,
      api: api,
      parameter: payload,
      success: types.FETCH_LIST_SUCCESS,
      failure: types.FETCH_LIST_FAILURE,
    })
  },
  fetchAll(context, payload) {
    let api = `post ${__STUDENT_API__}/oper/records/all`
    return Ajax(context, {
      type: types.FETCH_LIST_REQUEST,
      payload: payload,
      api: api,
      parameter: payload,
      success: types.FETCH_LIST_SUCCESS,
      failure: types.FETCH_LIST_FAILURE,
    })
  },
  fetch(context, payload) {
    let api = `get ${__STUDENT_API__}/sms/records/${payload.recordID}/${payload.sendStatus}`
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload: payload,
      api: api,
      parameter: payload,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
  // 撤销操作
  updateItem(context, payload) {
    return Ajax(context, {
      type: types.UPDATE_REQUEST,
      payload: payload,
      api: `post ${__STUDENT_API__}/oper/records/revoke/${payload.recordID}`,
      parameter: payload,
      success: types.UPDATE_SUCCESS,
      failure: types.UPDATE_FAILURE,
    })
  },
}

const mutations: Records.Mutations = {
  [types.FETCH_LIST_REQUEST](state) {
    handlers.request(state.list, 'fetching')
  },
  [types.FETCH_LIST_SUCCESS](state, { result, total }) {
    coverToState(state, 'recordID', result, total)
    if (result?.length === 0) {
      handlers.updateState(state.list, 'data', result)
      handlers.success(state.list, 'fetching')
      return
    }
    coverToState(state, 'recordID', result, total)
    handlers.success(state.list, 'fetching')
  },
  [types.FETCH_LIST_FAILURE](state, { message }) {
    handlers.failure(state.list, 'fetching', message)
  },

  [types.FETCH_REQUEST](state, { payload }) {
    handlers.itemRequest(state, `${payload.recordID}`, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { result, payload }) {
    handlers.itemSuccess(state, `${payload.recordID}`, 'fetching', {
      child: result,
    })
  },
  [types.FETCH_FAILURE](state, { message, payload }) {
    handlers.itemFailure(state, `${payload.recordID}`, 'fetching', message)
  },

  [types.UPDATE_REQUEST](state, { payload }) {
    handlers.itemRequest(state, `${payload.recordID}`, 'updating')
  },
  [types.UPDATE_SUCCESS](state, { payload }) {
    handlers.itemSuccess(state, `${payload.recordID}`, 'updating')
  },
  [types.UPDATE_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, `${payload.recordID}`, 'updating', message)
  },

  /** 清空缓存数据 */
  [types.CLEAN_LIST](state) {
    updateState(state, 'list', { data: [] })
    updateState(state, 'items', {})
  },
}

const getters: Records.Getters = {
  list(state, getters) {
    return state.list.data.map(id => getters.item(id)!)
  },
  listStatus(state) {
    return omit(state.list, ['data'])
  },
  item(state) {
    return id => state.items[`${id}`]?.data
  },
  itemStatus(state) {
    return id => omit(state.items[`${id}`], ['data'])
  },
}

export const { State, Action, Mutation, Getter } = BindingNamespace(namespace)
export const Module = { state, actions, mutations, getters, namespaced: true }
export default {
  State,
  Action,
  Mutation,
  Getter,
  namespace,
  types,
  Module,
}
