/**
 * 转学学生路由配置
 */

import { RouteConfig } from 'vue-router'

/** 子页面容器 */
import RouterViewLayout from '@layouts/router-view-layout'

/** 学生列表 */
import StudentListPage from '@pages/transfer/student-list'

/** 学生详情 */
import StudentDetailPage from '@pages/student-detail'

/** 编辑学生信息/重新入校 */
import StudentPage from '@pages/student'

/** 短信发送记录 */
import SmsRecordPage from '@pages/sms-record'
import { Constant } from 'xuexin-vuex'

const children: RouteConfig[] = []

/** 学生列表 */
children.push({
  path: '/students/transfer',
  name: 'transfer-students',
  component: StudentListPage,
  meta: { ignore: true, authCode: '050602', parentAuthCode: '0506' },
})

/** 短信发送记录 */
children.push({
  path: 'sms-record',
  name: 'transfer-sms-record',
  component: SmsRecordPage,
  meta: { title: '短信发送记录', authCode: '050602', parentAuthCode: '0506' },
  props: {
    actionType: Constant.ActionType.Transfer,
  },
})

/** 学生详情 */
children.push({
  path: ':termID/:unitID/:xuexinID',
  name: 'transfer-student-detail',
  component: StudentDetailPage,
  meta: { title: '学生详情', authCode: '050602', parentAuthCode: '0506' },
  props: { actionType: 'detail' },
})

/** 编辑学生 */
children.push({
  path: ':termID/:unitID/:xuexinID/edit',
  name: 'transfer-student-edit',
  component: StudentPage,
  meta: { title: '编辑学生', authCode: '050602', parentAuthCode: '0506' },
  props: { actionType: 'edit' },
})

// 重新入校
children.push({
  path: ':termID/:unitID/:xuexinID/rejoin',
  name: 'transfer-student-rejoin',
  component: StudentPage,
  meta: { title: '重新入校', authCode: '050602', parentAuthCode: '0506' },
  props: { actionType: 'rejoin' },
})

export default {
  path: '/students/transfer',
  name: 'transfer',
  redirect: { name: 'transfer-students' },
  meta: { title: '转学学生', authCode: '050602' },
  component: RouterViewLayout,
  props: { name: 'transfer' },
  children,
}
