import { Constant } from 'xuexin-vuex'

export type Direction = Constant.Direction
export const Direction = Constant.Direction

export const DirectionList = [Direction.Domestic, Direction.International]

export const messages: Messages = {
  'zh-CN': {
    [`${Direction.Domestic}`]: '国内',
    [`${Direction.International}`]: '国际',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${Direction.Domestic}`]: '国内',
    [`${Direction.International}`]: '国际',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
