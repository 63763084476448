import ConfigNode from '../../../types/config-node'
import { namespace as BindingNamespace } from 'vuex-class'
import { Ajax } from 'xuexin-vuex/src/ajax'
import omit from 'lodash.omit'
import { handlers } from 'xuexin-vuex/src/util'

export const namespace = '@xuexin-vuex/config-node'

export const types = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
}

const state: ConfigNode.State = {
  list: { data: [] },
  items: {},
}

const actions: ConfigNode.Actions = {
  //查询审批节点列表
  fetch(context, payload) {
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload,
      api: `get ${__STUDENT_API__}/config/lost/nodes`,
      parameter: payload,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
}

const mutations: ConfigNode.Mutations = {
  //查询审批节点列表
  [types.FETCH_REQUEST](state) {
    handlers.request(state.list, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { result, total }) {
    handlers.coverToState(state, 'id', result, total)
    handlers.success(state.list, 'fetching')
  },
  [types.FETCH_FAILURE](state, { message }) {
    handlers.failure(state.list, 'fetching', message)
  },
}

const getters: ConfigNode.Getters = {
  itemStatus(state) {
    return id => omit(state.items[`${id}`], ['data'])
  },
  item(state) {
    return id => state.items[`${id}`]?.data
  },
  listStatus(state) {
    return omit(state.list, ['data'])
  },
  list(state, getters) {
    return state.list.data.map(id => getters.item(id)!)
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
