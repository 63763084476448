import Vue from 'vue'
import Dashboard from '../../../types/dashboard'
import { namespace as BindingNamespace } from 'vuex-class'
import omit from 'lodash.omit'
import { handlers } from 'xuexin-vuex/src/util'
import { Ajax } from 'xuexin-vuex/src/ajax'
import { GradeSelect } from '@business-components/grade'

export const namespace = '@xuexin-vuex/dashboard'
export const types = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
}

const state: Dashboard.State = {
  studentStatis: { data: {} },
  lostStatis: { data: {} },
  studentView: { data: {} },
  transaction: { data: {} },
  entranceType: { data: {} },
  statusType: { data: {} },
  grade: { data: {} },
}

const actions: Dashboard.Actions = {
  fetch(context, payload) {
    const { actionType, statisticType, ...parameter } = payload
    let api = `get ${__STUDENT_API__}/home/${actionType}/`
    switch (actionType) {
      case 'studentStatis':
        api = `get ${__STUDENT_API__}/home/${actionType}/${statisticType}`
        break
      default:
        break
    }
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload: payload,
      api,
      parameter,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
}

const mutations: Dashboard.Mutations = {
  [types.FETCH_REQUEST](state, { payload }) {
    handlers.request(state[payload.actionType], 'fetching')
  },
  [types.FETCH_SUCCESS](state, { payload, result }) {
    const item = state[payload.actionType]
    const gradeChart: any = {}
    if (payload.actionType === 'studentStatis') {
      gradeChart.goodSeries = result!.grades
        .map(item => {
          if (item.gradeName !== '合计') {
            return {
              name: item.gradeName,
              value: item.goodCount,
            }
          } else {
            gradeChart.studentGoodCount = item.goodCount
            gradeChart.studentTargetCount = item.targetCount
            return {}
          }
        })
        .filter(item => Object.keys(item).length !== 0)
      gradeChart.targetSeries = result!.grades
        .map(item => {
          if (item.gradeName !== '合计') {
            return {
              name: item.gradeName,
              value: item.targetCount,
            }
          }
          return {}
        })
        .filter(item => Object.keys(item).length !== 0)
      gradeChart.gradeName = result!.grades
        .map(item => {
          if (item.gradeName !== '合计') {
            return item.gradeName
          }
          return ''
        })
        .filter(item => item !== '')
      if (result!['unit-schooldepart']?.schooldeparts) {
        result!['unit-schooldepart'].schooldeparts = result![
          'unit-schooldepart'
        ]?.schooldeparts
          .map(item => {
            if (item.schoolDepartName === '合计') {
              result!['unit-schooldepart'].studentCount = item.studentCount!
              return {}
            }
            return {
              value: item.studentCount!,
              name: item.schoolDepartName!,
            }
          })
          .filter(item => Object.keys(item).length !== 0)
        gradeChart['unit-schooldepart-names'] = result![
          'unit-schooldepart'
        ]?.schooldeparts.map(item => {
          return item.name
        })
      } else {
        result!['organ-schooldepart'].schooldeparts = result![
          'organ-schooldepart'
        ]?.schooldeparts
          .map(item => {
            if (item.schoolDepartName === '合计') {
              result!['organ-schooldepart'].studentCount = item.studentCount!
              return {}
            }
            return {
              value: item.studentCount!,
              name: item.schoolDepartName!,
            }
          })
          .filter(item => Object.keys(item).length !== 0)
      }

      if (result!['unit-manageType']?.manageTypes) {
        result!['unit-manageType'].manageTypes = result![
          'unit-manageType'
        ]?.manageTypes
          .map(item => {
            if (item.manageTypeName === '合计') {
              result!['unit-manageType'].studentCount = item.studentCount!
              return {}
            }
            return {
              value: item.studentCount!,
              name: item.manageTypeName!,
            }
          })
          .filter(item => Object.keys(item).length !== 0)
      } else {
        result!['organ-manageType'].manageTypes = result![
          'organ-manageType'
        ]?.manageTypes
          .map(item => {
            if (item.manageTypeName === '合计') {
              result!['organ-manageType'].studentCount = item.studentCount!
              return {}
            }
            return {
              value: item.studentCount!,
              name: item.manageTypeName!,
            }
          })
          .filter(item => Object.keys(item).length !== 0)
      }
      Vue.set(
        item,
        'data',
        Object.assign({}, state.studentStatis.data, gradeChart, result)
      )
    } else if (payload.actionType === 'lostStatis') {
      Vue.set(
        state.studentStatis,
        'data',
        Object.assign({}, state.studentStatis.data, result)
      )
      Vue.set(item, 'data', result)
    } else {
      Vue.set(item, 'data', result)
    }
    handlers.success(item, 'fetching')
  },
  [types.FETCH_FAILURE](state, { payload, message }) {
    handlers.failure(state[payload.actionType], 'fetching', message)
  },
}

const getters: Dashboard.Getters = {
  item(state) {
    return actionType => state[actionType]?.data
  },
  status(state) {
    return actionType => omit(state[actionType], ['data'])
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
