import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'
import i18n, { TrialReadStatusList } from './i18n'

const valueField = 'newOldType'
const labelField = 'newOldTypeName'
const prefixCls = `new-old-type`

function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `NewOldType${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class NewOldTypeComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }
    get locale(): Locale {
      return this.$i18n.locale as Locale
    }

    //1，新生；2，插班生；3,原就读
    get list() {
      return this.extra.concat(
        [{
          "newOldType":"1",
          "newOldTypeName":"新生",
        },
        {
          "newOldType":"2",
          "newOldTypeName":"插班生",
        },
        {
          "newOldType":"3",
          "newOldTypeName":"原就读",
        }]
      )
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (type === ComponentType.Text) {
        children = [`${i18n.messages[this.locale][this.$attrs.value]}`]
      }
      return createElement(
        component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return NewOldTypeComponent
}
export const NewOldTypeSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
export const NewOldTypeText = createComponent('span', ComponentType.Text)
