































import { Component, Prop, Mixins, Watch, Ref } from 'vue-property-decorator'
import PagePropsMixins from '@mixins/page-props-mixins'
import { TableColumn } from 'view-design'
import { Session } from 'xuexin-vuex'

import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'

import AppTable from '@components/app-table'
import { ContentTypeSelect } from '@business-components/statistical-content-type'
import i18n from './i18n'
import Export from '@store/modules/export'
import ExportTyping from '../../../../types/export'
import PageTyping from '../../../../types/page'
import StudentInsert from '@store/modules/student-insert'
import StudentInsertType from 'types/student-insert'
import StudentDistribution from '@store/modules/student-distribution'
import StudentDistributionType from 'types/student-distribution'

import { setReportData, spanChildMethod } from '@util/report'
import getPageColumns from '@components/report-toolbar/columns'

const components = {
  ViewLayout,
  ReportSearch,
  ReportToolbar,
  AppTable,
  ContentTypeSelect,
}
@Component({ name: 'StudentInsertReport', components, i18n })
export default class StudentInsertReport extends Mixins(PagePropsMixins) {
  @StudentInsert.Action('fetch') private readonly fetchTable!: any
  /** session模块 */
  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  @StudentInsert.Getter('list')
  private readonly getList!: StudentInsertType.Getter.List
  @StudentInsert.Getter('listStatus')
  private readonly listStatus!: StudentInsertType.Getter.ListStatus
  @StudentDistribution.Getter('listStatus')
  private readonly exportStatus!: StudentDistributionType.Getter.ListStatus
  @StudentDistribution.Action('export') private readonly export!: any

  private readonly prefixCls = 'student-insert-report-page'
  private parameter: any = {
    endDate: [],
  }
  private searchParameter: any = {}
  private defaultData: any = []
  private tParameter = {
    countType: 0,
    countContentType: 1,
  }
  /**  保留原始数据 */
  private defaultData2: any = this.defaultData.concat([])

  /** 合并行 */
  private rowspans: any = {}
  /** 合并前四项 */
  private sumKey: any = []

  /** 合计行下标 {index:下标，type:1-2-3-4(合计类型)} */
  private inventedDataIndexArr: any = []

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  get authCode() {
    return this.$route.meta.authCode
  }
  get noDataText() {
    if (Boolean(this.parameter.termID) === false) {
      return this.$t('message.noParameter')
    }
    return this.$t('message.noDataText')
  }
  // 表头
  get tableColumns(): any {
    return getPageColumns('StudentInsert')
      .map((item: any) => {
        return {
          title: item.title,
          key: item.code,
          width: 100,
          fixed: 'left',
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat([
        { title: '初始人数', key: 'initNum' },
        { title: '增加人数', key: 'growNum' },
        { title: '减少人数', key: 'decreaseNum' },
        { title: '净增加', key: 'netGrowNum' },
        { title: '截至点人数', key: 'endNum' },
      ])
  }
  get dataSources() {
    return this.getList()
  }
  get status() {
    return this.listStatus()
  }
  get exportStatu() {
    return this.exportStatus()
  }
  get deleting() {
    return this.exportStatu.deleting === true
  }
  get fetching() {
    return this.status.fetching === true
  }
  // 监听加载状态
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.status.fetchingError !== null &&
        this.status.fetchingError !== void 0
      ) {
        this.$Message.error(this.status.fetchingError)
        return
      }

      let result = setReportData({
        list: this.dataSources,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
        countColumn: [
          'initNum',
          'growNum',
          'decreaseNum',
          'netGrowNum',
          'endNum',
        ],
        percentageColumn: [],
      })
      this.defaultData = result.dataArr
      this.inventedDataIndexArr = result.indexArr
      this.rowspans = result.rowspans
    }
  }
  // 监听加载状态
  @Watch('deleting')
  private watchDeleting(deleting: boolean, previous: boolean) {
    if (deleting === false && deleting !== previous) {
      if (
        this.exportStatu.deletingError !== null &&
        this.exportStatu.deletingError !== void 0
      ) {
        return this.$Message.error(this.exportStatu.deletingError)
      }
    }
  }
  mounted() {}
  private handleStatisticalForm(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
      countColumn: [
        'initNum',
        'growNum',
        'decreaseNum',
        'netGrowNum',
        'endNum',
      ],
      percentageColumn: [],
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }
  // 合并行列方法
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }
  // 小计行的样式
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      if (row.hide) {
        return 'ivu-table-summary-row ivu-table-hide-row'
      }
      return 'ivu-table-summary-row'
    }
  }
  private fetch(parameter: any) {
    this.fetchTable(
      Object.assign({}, this.searchParameter, this.tParameter, {
        columns: this.sumKey,
        startDate: parameter.endDate[0],
        endDate: parameter.endDate[1],
      })
    )
  }
  private handleExport(parameter: any) {
    this.export(
      Object.assign({}, parameter, {
        columns: this.sumKey,
        countType: this.tParameter.countType,
        type: 'shift',
        startDate: parameter.endDate[0],
        endDate: parameter.endDate[1],
      })
    ).then((res: any) => {
      if (res.result) {
        window.location.href = `${res.result}`
      }
    })
  }
  // 列表列头发生变化
  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.handleSearch(this.searchParameter)
  }
  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    if (Boolean(parameter.termID) === false) {
      return this.$Message.warning(`${this.$t('message.noTermID')}`)
    }
    this.$set(this.$data, 'searchParameter', parameter)
    this.fetch(parameter)
  }
  created() {
    const newValue = this.getUser?.loginTime || new Date().getTime()
    this.parameter.endDate = [
      new Date(newValue - 24 * 60 * 60 * 1000),
      new Date(newValue),
    ]
    this.sumKey = getPageColumns('StudentInsert').map((item: any) => {
      return item.code
    })
  }
}
