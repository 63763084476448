export default {
  messages: {
    'zh-CN': {
      class: {
        title: '班级管理配置',
      },
      'divide-class': {
        title: '分班配置',
        'head-finance': '对接财务系统',
        'head-pay': '缴费限制',
      },
      approval: {
        title: '审批配置',
        'head-status': '状态',
        'head-node': '计算节点',
      },
      lost: {
        title: '考核流失配置',
        'subtitle-class': '分班管理',
        'subtitle-student': '在班学生',
        'head-trial-reading': '试读生',
        'head-short-term': '短期入学',
        'head-other': '非试读非短期入学',
        'transfer-school': '转学',
        interschool: '校际互转',
      },
      'message-tips': '保存成功！',
      button: '保存',
    },
    'en-US': {
      class: {
        title: '班级管理配置',
      },
      'divide-class': {
        title: '分班配置',
        'head-finance': '对接财务系统',
        'head-pay': '缴费限制',
      },
      approval: {
        title: '审批配置',
        'head-status': '状态',
        'head-node': '计算节点',
      },
      lost: {
        title: '考核流失配置',
        'subtitle-class': '分班管理',
        'subtitle-student': '在班学生',
        'head-trial-reading': '试读生',
        'head-short-term': '短期入学',
        'head-other': '非试读非短期入学',
        'transfer-school': '转学',
        interschool: '校际互转',
      },
      'message-tips': '保存成功！',
      button: '保存',
    },
  },
}
