export const messages: Messages = {
  'zh-CN': {
    title: '批量导入',
    valid: '请选择文件',
    label: {
      term: '导入学期',
      type: '导入类型',
      file: '导入文件',
    },
    button: '导入文件',
    text: {
      success: '导入成功',
      failure: '导入失败',
      unit: '条',
      tip: '请求导入记录里查看',
      noTermID: '请先选择学期数据',
    },
  },
  'en-US': {},
}

export default { messages }
