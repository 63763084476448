/**
 * 分班管理页面路由
 */
import RouterViewLayout from '@layouts/router-view-layout'
import { RouteConfig } from 'vue-router'
import DivideClassPage from '@pages/divide-class'
import StudentPage from '@pages/student'
import StudentDetailPage from '@pages/student-detail'
import SmsRecordPage from '@pages/sms-record'
import { Constant } from 'xuexin-vuex'

const children: RouteConfig[] = []

/** 分班管理列表展示 */
children.push({
  path: '/divide-class',
  name: 'divide-class-management',
  component: DivideClassPage,
  meta: { ignore: true, authCode: '0505', parentAuthCode: '05' },
})

/** 编辑学生 */
children.push({
  path: ':termID/:unitID/:xuexinID/edit',
  name: 'divide-class-student-edit',
  component: StudentPage,
  meta: { title: '编辑学生', authCode: '0505', parentAuthCode: '05' },
  props: { actionType: 'edit' },
})

/** 学生详情 */
children.push({
  path: ':xuexinID/:unitID/:termID/detail',
  name: 'divide-class-student-detail',
  component: StudentDetailPage,
  meta: { ignore: true, authCode: '0505', parentAuthCode: '05' },
  props: { actionType: 'detail' },
})

/** 短信发送记录 */
children.push({
  path: 'sms-record',
  name: 'divide-class-sms-record',
  component: SmsRecordPage,
  meta: { title: '短信发送记录', authCode: '0505', parentAuthCode: '05' },
  props: {
    actionType: Constant.ActionType.Divide,
  },
})

export default {
  path: '/divide-class',
  name: 'divide-class',
  redirect: { name: 'divide-class-management' },
  meta: { title: '分班管理', authCode: '0505' },
  component: RouterViewLayout,
  props: { name: 'divide-class' },
  children,
}
