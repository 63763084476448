/** 流失率统计对比分析 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import LossRateCompareAnalysis from '@pages/statistic-reports/loss-rate-compare-analysis'

const children: RouteConfig[] = []

children.push({
  path: '/reports/loss-rate-compare-analysis',
  name: 'loss-rate-compare-analysis-report',
  component: LossRateCompareAnalysis,
  meta: { ignore: true, authCode: '050711', parentAuthCode: '0507' },
})

export default {
  path: '/reports',
  name: 'loss-rate-compare-analysis',
  redirect: { name: 'loss-rate-compare-analysis-report' },
  meta: { title: '流失率统计对比分析', authCode: '050711' },
  component: RouterViewLayout,
  props: { name: 'loss-rate-compare-analysis' },
  children,
}
