export const messages: Messages = {
  'zh-CN': {
    save: '保存',
    loading: '加载中...',
    validator: {
      'non-null': '请填写必填项',
    },
  },
  'en-US': {},
}

export default { messages }
