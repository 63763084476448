import { Constant } from 'xuexin-vuex'

export type WorkplaceType = Constant.WorkplaceType
export const WorkplaceType = Constant.WorkplaceType

export const WorkplaceTypeList = [
  WorkplaceType.Institution,
  WorkplaceType.StateOwned,
  WorkplaceType.Foreign,
  WorkplaceType.Private,
  WorkplaceType.PrivateBoss,
  WorkplaceType.Farmer,
  WorkplaceType.Freelance,
  WorkplaceType.SelfEmployed,
  WorkplaceType.Professional,
]

export const messages: Messages = {
  'zh-CN': {
    [WorkplaceType.Institution]: '公务员/事业单位',
    [WorkplaceType.StateOwned]: '国有企业员工',
    [WorkplaceType.Foreign]: '外企员工',
    [WorkplaceType.Private]: '民营企业员工',
    [WorkplaceType.PrivateBoss]: '私企老板',
    [WorkplaceType.Farmer]: '农民',
    [WorkplaceType.Freelance]: '自由职业',
    [WorkplaceType.SelfEmployed]: '个体户',
    [WorkplaceType.Professional]: '专业技术人员',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [WorkplaceType.Institution]: '公务员/事业单位',
    [WorkplaceType.StateOwned]: '国有企业员工',
    [WorkplaceType.Foreign]: '外企员工',
    [WorkplaceType.Private]: '民营企业员工',
    [WorkplaceType.PrivateBoss]: '私企老板',
    [WorkplaceType.Farmer]: '农民',
    [WorkplaceType.Freelance]: '自由职业',
    [WorkplaceType.SelfEmployed]: '个体户',
    [WorkplaceType.Professional]: '专业技术人员',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
