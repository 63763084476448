export default {
  messages: {
    'zh-CN': {
      columns: {
        index: '序号',
        'school-name': '学校名称',
        campus: '校区',
        'trial-title-day': '试读期限（天）',
      },
      message: {
        'update-success': '编辑成功！',
        'save-success': '保存成功！',
      },
      save: '保存',
      'change-day': '请选择',
      'trial-period-setting': '试读期限设置（按校历计算）',
    },
    'en-US': {
      columns: {
        index: '序号',
        'school-name': '学校名称',
        campus: '校区',
        'trial-title-day': '试读期限（天）',
      },
      message: {
        'update-success': '编辑成功！',
        'save-success': '保存成功！',
      },
      save: '保存',
      'change-day': '请选择',
      'trial-period-setting': '试读期限设置（按校历计算）',
    },
  },
}
