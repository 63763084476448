// http://localhost:3000/students/in-class/2020202102/468113/5945073/edit

// http://localhost:3000/students/transfer/2020202102/468113/5945073/rejoin

// http://zs.weimingedu.com:81/customer/complete?id=RVxQSxc%3D

/** 测试环境 */
// http://localhost:3000/divide-class/2020202101/100001/1656441/edit

/** 数据比较全的学生 */
// http://localhost:3000/divide-class/2020202101/100001/8095016/edit
// http://localhost:3000/divide-class/2020202101/100003/5554118/edit

export const messages: Messages = {
  'zh-CN': {
    loading: '正在获取学生数据，请稍后...',
    updating: '正在保存学生数据...',
    success: '操作成功',
    back: '返回',
    retry: '重试',
    save: '保存',
  },
  'en-US': {
    loading: '正在获取学生数据，请稍后...',
    updating: '正在保存学生数据...',
    success: '操作成功',
    back: '返回',
    retry: '重试',
    save: '保存',
  },
}

export default { messages }
