import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import { Spin, Button, TableColumn } from 'view-design'
import AppTable from '@components/app-table'
import ViewLayout from '@layouts/view-layout'
import { GraduateDirectionSelect } from '@business-components/graduate-direction'
import i18n from './i18n'
import './batch-edit-direction.scss'

interface SlotScoped {
  column: TableColumn
  index: number
  row: Student.Entity
}

type Selection = Record<string, { value: number; index: number }>

@Component({ name: 'BatchEditDirectionPage', i18n })
export default class BatchEditDirectionPage extends Vue {
  @Student.Action('fetchList')
  private readonly fetchStudents!: Student.Action.FetchList
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Student.Getter('list') private readonly students!: Student.Getter.List
  @Student.Mutation('CLEAN_LIST')
  private readonly cleanList!: Student.Mutation.CleanList

  private readonly prefixCls = 'batch-edit-direction-page'
  private selection: Selection = {}

  get classes() {
    return {
      card: true,
      page: true,
      [this.prefixCls]: true,
    }
  }

  get termID() {
    return Number(this.$route.params.termID)
  }

  get ids() {
    const ids = (this.$route.query.ids || '') as string
    return ids.split(',')
  }

  get fetching() {
    return this.listStatus.fetching !== false
  }

  get updating() {
    return this.listStatus.updating === true
  }

  get hasError() {
    return (
      this.fetching !== true &&
      typeof this.listStatus.fetchingError === 'string'
    )
  }

  get columns(): TableColumn[] {
    return [
      {
        title: `${this.$t('columns.num')}`,
        key: 'num',
        width: 80,
        align: 'center',
      },
      {
        title: `${this.$t('columns.className')}`,
        slot: 'className',
        width: 300,
      },
      {
        title: `${this.$t('columns.studentName')}`,
        slot: 'studentName',
        width: 120,
        align: 'center',
      },
      {
        title: `${this.$t('columns.gender')}`,
        key: 'genderStr',
        width: 100,
        align: 'center',
      },
      {
        title: `${this.$t('columns.direction')}`,
        slot: 'direction',
        width: 220,
      },
      { title: ' ' },
    ]
  }

  get list(): Student.Entity[] {
    return this.students.map((item, index) => {
      const { studentID, graduateDirection } = item
      return Object.assign({}, item, {
        num: index + 1,
        graduateDirection: this.selection[studentID].value,
      })
    })
  }

  @Watch('fetching')
  watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }

      /** 初始化数据 */
      this.$set(
        this.$data,
        'selection',
        this.students.reduce<Selection>((selection, item, index) => {
          selection[item.studentID] = {
            index,
            value: item.graduateDirection || void 0,
          }
          return selection
        }, {})
      )
    }
  }

  @Watch('updating')
  watchUpdating(updating: boolean, prevous: boolean) {
    if (updating === false && prevous === true) {
      if (this.listStatus.updatingError !== null) {
        return this.$Message.error(this.listStatus.updatingError)
      }

      this.$Message.success(`${this.$t('success')}`)
    }
  }

  created() {
    if (isNaN(this.termID) === true) {
      return this.$Message.error('termID is invalid')
    }

    this.handleRetry()
  }

  render(h: CreateElement) {
    const children: VNodeChildren = []
    switch (true) {
      case this.fetching: // 正在加载
        children.push(
          h(
            Spin,
            {
              props: { fix: true },
            },
            [`${this.$t('loading')}`]
          )
        )
        break
      case this.hasError: // 有错误
        children.push(
          h(
            'div',
            {
              class: `${this.prefixCls}_error`,
            },
            [
              h(
                'span',
                {
                  class: `${this.prefixCls}_message`,
                },
                [this.listStatus.fetchingError]
              ),
              h('br'),
              h(
                Button,
                { props: { type: 'primary' }, on: { click: this.handleRetry } },
                [`${this.$t('retry')}`]
              ),
            ]
          )
        )
        break
      default:
        children.push(this.renderHeader(h))
        children.push(this.renderTable(h))
        break
    }

    return h(
      ViewLayout,
      {
        props: { name: this.prefixCls },
      },
      [h('div', { class: this.classes }, children)]
    )
  }

  beforeDestroy() {}

  destroyed() {
    this.cleanList()
  }

  private renderHeader(h: CreateElement) {
    const children: VNodeChildren = []

    children.push(
      h(
        Button,
        {
          props: {
            type: 'text',
            icon: 'ios-arrow-back',
            to: { name: 'graduation-students' },
          },
          style: { float: 'left' },
        },
        [`${this.$t('backtrack')}`]
      )
    )

    children.push(
      h(
        Button,
        {
          props: { type: 'primary' },
          style: { float: 'right' },
          on: { click: this.handleSave },
        },
        [`${this.$t('save')}`]
      )
    )

    return h(
      'div',
      {
        class: `${this.prefixCls}_header`,
      },
      children
    )
  }

  private renderTable(h: CreateElement) {
    return h(AppTable, {
      class: `${this.prefixCls}_table`,
      props: {
        columns: this.columns,
        data: this.list,
      },
      attrs: {
        loading: this.fetching || this.updating,
      },
      scopedSlots: {
        className: ({ row }: SlotScoped) => {
          return h('span', {}, [row.className])
        },
        studentName: ({ row }: SlotScoped) => {
          return h('span', {}, [row.studentName])
        },
        direction: ({ row, index }: SlotScoped) => {
          const attrs = {
            value: row.graduateDirection,
            transfer: true,
            clearable: true,
          }
          const on = {
            input: (value: number) =>
              this.handleDirectionChange(row, index, value),
          }
          return h(GraduateDirectionSelect, { attrs, on })
        },
      },
    })
  }

  private handleDirectionChange(
    student: Student.Entity,
    index: number,
    value: number
  ) {
    const key = student.studentID || student.xuexinID
    this.$set(this.selection, `${key}`, { index, value })
  }

  private handleSave() {
    const list = Object.keys(this.selection).filter(key => {
      const item = this.selection[key]
      return item.value !== this.students[item.index].graduateDirection
    })

    /** 长度为 0 时，表示无改动，则不处理请求。 */
    if (list.length === 0) {
      return void 0
    }

    /** 向后台发送请求 */
    this.update({
      actionType: Constant.ActionType.Graduation,
      termID: this.termID,
      graduations: list.map(xuexinID => {
        const item = this.selection[xuexinID]
        const { phaseID, classID } = this.students[item.index]
        const dataID = item.value
        return { xuexinID, dataID, phaseID, classID }
      }),
    })
  }

  private handleRetry() {
    this.fetchStudents({
      actionType: Constant.ActionType.Batch,
      termID: this.termID,
      xuexinIDs: this.ids,
    })
  }
}
