import { Constant } from 'xuexin-vuex'

export type AcademicDirection = Constant.EnrollType
export const AcademicDirection = Constant.EnrollType

export const AcademicDirectionList = [
  AcademicDirection.Culture,
  AcademicDirection.Art,
  AcademicDirection.IICK,
  AcademicDirection.International,
]

export const messages: Messages = {
  'zh-CN': {
    [`${AcademicDirection.Culture}`]: '文化',
    [`${AcademicDirection.Art}`]: '艺体',
    [`${AcademicDirection.IICK}`]: '港澳台',
    [`${AcademicDirection.International}`]: '外国籍',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${AcademicDirection.Culture}`]: '文化',
    [`${AcademicDirection.Art}`]: '艺体',
    [`${AcademicDirection.IICK}`]: '港澳台',
    [`${AcademicDirection.International}`]: '外国籍',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
