import Vue, { CreateElement, VNode } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { CheckboxGroup, Checkbox, Icon } from 'view-design'

const components = { CheckboxGroup, Checkbox, Icon }
@Component({ name: 'AppCheckboxGroup', components, inheritAttrs: false })
export default class AppCheckboxGroup extends Vue {
  @Prop({ type: Array, default: () => [] }) private readonly data!: any[]
  @Prop({ type: String, default: 'label' }) private readonly labelField!: string
  @Prop({ type: String, default: 'value' }) private readonly valueField!: string
  private readonly prefixCls = 'app-checkbox-group'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get props() {
    return Object.assign({}, this.$attrs)
  }

  render(createElement: CreateElement) {
    const children: VNode[] = this.data.map(item => {
      const label = item[this.labelField]
      const value = item[this.valueField]
      return createElement(
        'Checkbox',
        {
          props: {
            label: value,
            disabled: item.disabled === true,
          },
          style: item.style,
          key: value,
        },
        [
          item.icon !== void 0
            ? createElement('Icon', { props: { type: item.icon } })
            : void 0,
          createElement('span', label),
        ]
      )
    })

    return createElement(
      'CheckboxGroup',
      {
        class: this.classes,
        props: this.props,
        on: this.$listeners,
      },
      children
    )
  }
}
