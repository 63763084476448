/**
 * 分班管理页面路由
 */
import RouterViewLayout from '@layouts/router-view-layout'
import { RouteConfig } from 'vue-router'
import NewStudentPage from '@pages/new-student'
import StudentDetailPage from '@pages/student-detail'
import { Constant } from 'xuexin-vuex'

const children: RouteConfig[] = []

/** 分班管理列表展示 */
children.push({
  path: '/new-student',
  name: 'new-student',
  component: NewStudentPage,
  meta: { ignore: true, authCode: '0510', parentAuthCode: '05' },
})

/** 学生详情 */
children.push({
  path: ':xuexinID/:unitID/:termID/detail',
  name: 'new-student-detail',
  component: StudentDetailPage,
  meta: { ignore: true, authCode: '0510', parentAuthCode: '05' },
  props: { actionType: 'detail' },
})

export default {
  path: '/new-student',
  redirect: { name: 'new-student' },
  meta: { title: '新招学生', authCode: '0510' },
  component: RouterViewLayout,
  props: { name: 'new-student' },
  children,
}
