



































import Vue from 'vue'
import { Component, Ref, Mixins, Watch } from 'vue-property-decorator'

import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'

import getPageColumns from '@components/report-toolbar/columns'
import AppTable from '@components/app-table'
import PageTyping from '../../../../types/page'
import { setReportData, spanChildMethod } from '@util/report'
import i18n from './i18n'

import StudentReport, { ActionType } from '@store/modules/student-report'

const components = {
  ViewLayout,
  ReportSearch,
  AppTable,
  ReportToolbar,
}

@Component({ name: 'StudentOverviewPage', components, i18n })
export default class StudentOverviewPage extends Mixins(PagePropsMixins) {
  @StudentReport.Action('fetchList') private readonly fetchList!: any
  @StudentReport.Action('export') private readonly export!: any
  @StudentReport.Getter('list')
  private readonly getList!: StudentReport.Getter.List
  @StudentReport.Getter('listStatus')
  private readonly listStatus!: StudentReport.Getter.ListStatus

  private readonly prefixCls = 'student-overview'
  private parameter: any = {
    endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  }
  private searchParameter: any = {
    endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  }
  private tParameter: any = { countType: 0 }
  private sumKey: any = []
  private inventedDataIndexArr: any = []
  private defaultData: any = []
  private rowspans: any = {}
  private currentValue = 0

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get noDataText() {
    if (this.dataSources.length === 0) {
      return this.$t('text.noDataText')
    }
    return
  }

  /** 表格列信息 */
  get tableColumns() {
    return getPageColumns('StudentOverview')
      .map((item: any) => {
        if (item.code === 'className') {
          return {
            title: item.title,
            key: item.code,
            width: 200,
          }
        } else {
          return {
            title: item.title,
            key: item.code,
            width: 100,
          }
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat([
        {
          title: '在校生',
          key: 'schoolNum',
          width: 80,
        },
        {
          title: '未分班',
          key: 'noClassNum',
          width: 80,
        },
        {
          title: '性别',
          children: [
            {
              title: '男',
              key: 'maleNum',
              width: 80,
            },
            {
              title: '女',
              key: 'femaleNum',
              width: 80,
            },
          ],
        },
        {
          title: '学生状态',
          children: [
            {
              title: '正常',
              key: 'normalNum',
              width: 80,
            },
            {
              title: '休学',
              key: 'suspendNum',
              width: 80,
            },
            {
              title: '外出艺体',
              key: 'outNum',
              width: 80,
            },
            {
              title: '空挂学籍',
              key: 'blankNum',
              width: 80,
            },
          ],
        },
        {
          title: '住宿状态',
          children: [
            {
              title: '住宿',
              key: 'boardingNum',
              width: 80,
            },
            {
              title: '走读',
              key: 'walkNum',
              width: 80,
            },
          ],
        },
        {
          title: '原就读',
          key: 'oldNum',
          width: 80,
        },
        {
          title: '外招',
          key: 'externalNum',
          width: 80,
        },
        {
          title: '流失',
          key: 'leaveNum',
          width: 80,
        },
        {
          title: '直升',
          key: 'jumpNum',
          width: 80,
        },
        {
          title: '当前学期毕业',
          key: 'graduateNum',
          width: 80,
        },
      ])
  }
  /** 数据源 */
  get dataSources() {
    return this.getList('studentOverview')
  }
  get getListStatus() {
    return this.listStatus('studentOverview')
  }
  /** 请求状态 */
  get fetching() {
    return this.getListStatus.fetching === true
  }
  /** 请求错误信息 */
  get fetchingError() {
    return this.getListStatus.fetchingError
  }
  /** 监听请求状态 */
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous !== fetching) {
      if (Boolean(this.fetchingError)) {
        return this.$Message.error(this.fetchingError as string)
      }

      let result = setReportData({
        list: this.dataSources,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
      })

      this.inventedDataIndexArr = result.indexArr
      this.defaultData = result.dataArr
      this.rowspans = result.rowspans
    }
  }

  /** 搜索 */
  private handleSearch(value: any) {
    if (Boolean(value) === false) {
      return
    }
    this.searchParameter = Object.assign({}, value)

    const { endDate = new Date() } = value

    this.fetchList({
      actionType: ActionType.Overview,
      type: 'studentOverview',
      ...value,
      endDate: new Date(endDate).getTime(),
      countType: this.currentValue,
      columns: this.tableColumns.map((item: any) => item.key || ''),
    })
  }

  /** 导出 */
  private handleExport(value: any) {
    this.export(
      Object.assign({}, value, {
        actionType: ActionType.Overview,
        type: 'studentOverview',
        countType: this.tParameter.countType,
        columns: this.sumKey,
      })
    ).then((res: { result: any }) => {
      window.location.href = `${res.result}`
    })
  }

  /** 切换统计形式 */
  private handleChangeType(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }

  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.handleSearch(this.searchParameter)
  }

  /** 合并行列方法 */
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }
  /** 小计行的样式 */
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      if (row.hide) {
        return 'ivu-table-summary-row ivu-table-hide-row'
      }
      return 'ivu-table-summary-row'
    }
  }

  /** 请求数据 */
  private fetch() {
    if (Boolean(this.parameter) === false) {
      return
    }

    const { endDate = new Date() } = this.parameter

    this.fetchList({
      actionType: ActionType.Overview,
      type: 'studentOverview',
      ...this.parameter,
      endDate: new Date(endDate).getTime(),
      countType: this.currentValue,
      columns: this.tableColumns.map((item: any) => item.key || ''),
    })
  }

  /** 初始化 */
  created() {
    this.sumKey = getPageColumns('StudentOverview').map((item: any) => {
      return item.code
    })
  }
}
