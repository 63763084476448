import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppRadioGroup from '@components/app-radio-group'
import AppSelect from '@components/app-select'
import { Direction } from 'xuexin-vuex'
import i18n, { DirectionList } from './i18n'

type ViewType = 'text'
const prefixCls = `direction`

function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `Direction${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class DirectionComponent extends Vue {
    @Direction.Action('fetch') private readonly fetch!: Direction.Action.Fetch
    @Direction.Getter('status')
    private readonly getStatus!: Direction.Getter.Status
    @Direction.Getter('list') private readonly getList!: Direction.Getter.List

    @Prop({ type: [Number] }) private readonly parentUnitID!: number
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType!: ViewType

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }

    get locale(): Locale {
      return this.$i18n.locale as Locale
    }

    get list() {
      /** 如果有学校id参数，则通过学校id 查询方向数据 */
      if (this.parentUnitID !== void 0) {
        return this.extra.concat(
          this.getList({ parentUnitID: this.parentUnitID }).map(item => {
            return { label: item.name, value: item.id }
          })
        )
      }

      /** 没有学校参数时，返回本地数据 */
      return this.extra.concat(
        DirectionList.map(value => {
          return { label: i18n.messages[this.locale][value], value }
        })
      )
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
        case ComponentType.RadioGroup:
          props.data = this.list
          props.loading = this.status.fetching
          props.error = this.status.fetchingError
          break
      }
      return props
    }

    get status() {
      return this.getStatus({ parentUnitID: this.parentUnitID })
    }

    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (type === ComponentType.Text || this.viewType === 'text') {
        children = [`${i18n.messages[this.locale][this.$attrs.value]}`]
      }

      return createElement(
        component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }

    created() {
      this.fetchList()
    }

    private fetchList() {
      if (
        this.parentUnitID === void 0 ||
        this.parentUnitID === null ||
        (this.status.fetching === true && this.status.loaded === true)
      ) {
        return void 0
      }

      this.fetch({ parentUnitID: this.parentUnitID })
    }
  }

  return DirectionComponent
}

export const DirectionRadioGroup = createComponent(
  AppRadioGroup,
  ComponentType.RadioGroup
)
export const DirectionSelect = createComponent(AppSelect, ComponentType.Select)
export const DirectionText = createComponent('span', ComponentType.Text)
