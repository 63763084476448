export const messages: Messages = {
  'zh-CN': {
    title: {
      modal: '导出',
      column: '列信息',
      student: '学生基本信息',
      parent: '家长基本信息',
      admission: '入学基本信息',
    },
    text: {
      noUnitID: '当前没有学校信息',
      placeholder: '请选择需要导出的列',
      loading: '正在加载...',
      down: '展开',
      up: '收起',
      warning: '暂无导出数据',
    },
  },
  'en-US': {
    title: {
      modal: '导出',
      column: '列信息',
      student: '学生基本信息',
      parent: '家长基本信息',
      admission: '入学基本信息',
    },
    text: {
      placeholder: '请选择需要导出的列',
      loading: '正在加载...',
      down: '展开',
      up: '收起',
    },
  },
}

export default { messages }
