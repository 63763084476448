import { Constant } from 'xuexin-vuex'

export type StatisticalForm = Constant.StatisticalForm
export const StatisticalForm = Constant.StatisticalForm

export const StatisticalFormList = [
  StatisticalForm.TotalOnly,
  StatisticalForm.SubtotalOneItem,
  StatisticalForm.SubtotalTwoItem,
  StatisticalForm.SubtotalThreeItem,
  StatisticalForm.SubtotalFourItem,
]

export const messages: Messages = {
  'zh-CN': {
    [`${StatisticalForm.TotalOnly}`]: '只合计',
    [`${StatisticalForm.SubtotalOneItem}`]: '小计一项',
    [`${StatisticalForm.SubtotalTwoItem}`]: '小计两项',
    [`${StatisticalForm.SubtotalThreeItem}`]: '小计三项',
    [`${StatisticalForm.SubtotalFourItem}`]: '小计四项',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${StatisticalForm.TotalOnly}`]: '只合计',
    [`${StatisticalForm.SubtotalOneItem}`]: '小计一项',
    [`${StatisticalForm.SubtotalTwoItem}`]: '小计两项',
    [`${StatisticalForm.SubtotalThreeItem}`]: '小计三项',
    [`${StatisticalForm.SubtotalFourItem}`]: '小计四项',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
