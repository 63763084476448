import { Constant } from 'xuexin-vuex'

export type ApproveStatus = Constant.ApproveStatus
export const ApproveStatus = Constant.ApproveStatus
export const ApproveStatusList = [
  ApproveStatus.Without,
  ApproveStatus.Waiting,
  ApproveStatus.InApproval,
  ApproveStatus.Adopt,
  ApproveStatus.Failed,
  ApproveStatus.Uncommitted,
]

export const messages: Messages = {
  'zh-CN': {
    group: '集-',
    [ApproveStatus.Without]: '不需要审批',
    [ApproveStatus.Waiting]: '待审批',
    [ApproveStatus.InApproval]: '审批中',
    [ApproveStatus.Adopt]: '审批通过',
    [ApproveStatus.Failed]: '审批未通过',
    [ApproveStatus.Uncommitted]: '未提交审批',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    group: '集-',
    [ApproveStatus.Without]: '不需要审批',
    [ApproveStatus.Waiting]: '待审批',
    [ApproveStatus.InApproval]: '审批中',
    [ApproveStatus.Adopt]: '审批通过',
    [ApproveStatus.Failed]: '审批未通过',
    [ApproveStatus.Uncommitted]: '未提交审批',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
