import { Constant } from 'xuexin-vuex'

export type StudentStatus = Constant.StudentStatus
export const StudentStatus = Constant.StudentStatus

export const StudentStatusList = [
  StudentStatus.Normal,
  StudentStatus.Suspension,
  StudentStatus.EmptyStatus,
  StudentStatus.LeaveSchool,
]

export const messages: Messages = {
  'zh-CN': {
    [`${StudentStatus.Normal}`]: '正常',
    [`${StudentStatus.Suspension}`]: '休学',
    [`${StudentStatus.EmptyStatus}`]: '空挂学籍',
    [`${StudentStatus.LeaveSchool}`]: '外出艺体',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${StudentStatus.Normal}`]: '正常',
    [`${StudentStatus.Suspension}`]: '休学',
    [`${StudentStatus.EmptyStatus}`]: '空挂学籍',
    [`${StudentStatus.LeaveSchool}`]: '外出艺体',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
