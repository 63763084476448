


































import { Component, Mixins, Watch } from 'vue-property-decorator'

import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search-mul'

import AppTable from '@components/app-table'
import i18n from './i18n'
import { Constant, Session } from 'xuexin-vuex'

import EnrollPayData from '@store/modules/enroll-pay-data'
import { Semester } from 'xuexin-vuex'
import { convertToMulti, DateUtil } from '@util'
import { spanChildMethod } from '@util/report'

const components = {
  ViewLayout,
  ReportSearch,
  AppTable,
}

@Component({ name: 'EnrollPayDataPage', components, i18n })
export default class EnrollPayDataPage extends Mixins(PagePropsMixins) {
  @EnrollPayData.Action('fetchList') private readonly fetchList!: any
  @EnrollPayData.Action('export') private readonly export!: any
  @EnrollPayData.Getter('list')
  private readonly getList!: EnrollPayData.Getter.List
  @EnrollPayData.Getter('status')
  private readonly listStatus!: EnrollPayData.Getter.Status

  /** 判断当前是学校还是机构 */
  @Session.Getter('user') private readonly user!: Session.Getter.User

  /** 获取学期 */
  @Semester.Action('fetch')
  private readonly fetchTerm!: Semester.Action.FetchList
  @Semester.Getter('list')
  private readonly getTerm!: Semester.Getter.List

  private readonly prefixCls = 'enroll-pay-data'
  private parameter: any = {
    dateType: 1,
    transType: 1,
    dimension: 'schoolDepart',
    simpleDate: DateUtil.format(new Date(new Date().getTime()), 'yyyy-MM-dd'),
  }
  private defaultData: any = []
  private currnetDateType = Constant.ColumnType.Date
  /** begin */
  private headerName = ' '
  private unitID = -1
  private organID = 0
  private tableColumns: any = []
  private timeString = '截至该天'

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get noDataText() {
    if (this.dataSources.length === 0) {
      return this.$t('text.noDataText')
    }
    return
  }

  /** 表格列信息 */
  get columnsData() {
    const columns = [
      {
        title: `${this.headerName}`,
        align: 'center',
        children: [
          { title: '序号', key: 'num', width: 80, align: 'center' },
          { title: '校区', key: 'shortName', align: 'center', width: 100 },
          {
            title: '学部',
            key: 'schoolDepartName',
            width: 100,
            align: 'center',
          },
          // {
          //   title: '年级',
          //   slot: 'unitGradeName',
          //   width: 100,
          //   align: 'center',
          // },
          // {
          //   title: '班级',
          //   slot: 'className',
          //   width: 100,
          //   align: 'center',
          // },
          // prettier-ignore
          { title: '上学期毕业人数', key: 'lastTermGraducateNum', width: 120, align:'center',},
          // prettier-ignore
          { title: `本学期转学人数(${this.timeString})`, key: 'termTranferNum', width: 122, align:'center',},
          {
            title: '本学期新招学生',
            align: 'center',
            children: [
              {
                title: '外招',
                key: 'termNewNum',
                width: 80,
                align: 'center',
              },
              { title: '直升', key: 'jumpNum', width: 80, align: 'center' },
              {
                title: '毕业留级',
                key: 'stayNum',
                width: 80,
                align: 'center',
              },
              {
                title: '小计',
                key: 'termNewNumCount',
                width: 80,
                align: 'center',
              },
            ],
          },
          {
            title: '本学期校内互转',
            align: 'center',
            children: [
              {
                title: '转入',
                key: 'termUnitTranferIn',
                width: 80,
                align: 'center',
              },
              {
                title: '转出',
                key: 'termUnitTranferOut',
                width: 80,
                align: 'center',
              },
            ],
          },
          // prettier-ignore
          { title: '当前在校人数', key: 'schoolNumNow', width: 120, align:'center' },
          {
            title: '缴清总人数',
            key: 'payedNum',
            width: 120,
            align: 'center',
          },
          {
            title: '欠费人数',
            key: 'unPayedNum',
            width: 120,
            align: 'center',
          },
          {
            title: '缴费完成率',
            key: 'percent',
            width: 120,
            align: 'center',
          },
        ],
      },
    ]

    return columns
  }

  /** 数据源 */
  get dataSources() {
    const list = this.getList(this.parameter) || []
    return list.map((item, index) => {
      item.num = index + 1
      return item
    })
  }
  /** 获取学期---begin */
  /** 当前是机构还是学校 */
  get identityType() {
    return this.user?.identityType
  }

  get termParameter() {
    /** 0: 默认学期, 1: 向后追加两个学期, 2: 截止学期 */
    return {
      type: 1,
      unitID: convertToMulti(this.unitID),
    }
  }
  get termName() {
    const { termID } = this.parameter
    const term = this.getTerm(this.termParameter)
      .filter(item => {
        return item.enable !== 0
      })
      .find(item => {
        return item.termID === termID
      })

    return term?.termName
  }

  get fetching() {
    return this.listStatus(this.parameter).fetching === true
  }
  get fetchingError() {
    return this.listStatus(this.parameter).fetchingError
  }

  get updating() {
    return this.listStatus(this.parameter).updating === true
  }
  get updatingError() {
    return this.listStatus(this.parameter).updatingError
  }

  @Watch('termName')
  watchTermName(value: string) {
    this.$set(this.$data, 'headerName', value)
    this.$set(this.columnsData[0], 'title', `${value}`)
  }

  /** 获取学期---end */
  /** 监听请求状态 */
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.fetchingError !== null) {
        return this.$Message.error(this.fetchingError)
      }
    }
  }

  @Watch('parameter.termID', { immediate: true })
  private watchTermID() {
    this.validTerm()
  }

  @Watch('parameter.dateType', { immediate: true })
  private watchDateType(value: number) {
    switch (value) {
      case 0:
        this.$set(this.$data, 'timeString', '该天')
        break
      case 1:
        this.$set(this.$data, 'timeString', '截至该天')
        break
      case 2:
        this.$set(this.$data, 'timeString', '时间段')
        break
    }
  }

  /** 搜索 */
  private handleSearch() {
    const {
      termDate,
      dateType,
      simpleDate,
      doubleDate,
      dimension,
    } = this.parameter
    const columns: any = this.columnsData[0].children.map((item: any) => {
      return item
    })
    let tempDate_ = ''
    /** 当天，之前处理 */
    if (dateType === 0 || dateType === 1) {
      /** 单日期 */
      tempDate_ = DateUtil.format(simpleDate, 'yyyy-MM-dd')
    }

    if (dateType === 2) {
      /** 时间段 */
      tempDate_ = DateUtil.format(doubleDate[0], 'yyyy-MM-dd')
    }
    const tempSimpleDate_ = tempDate_.split('-')
    columns.splice(
      3,
      0,
      {
        title: `截至${tempSimpleDate_[1]}月${tempSimpleDate_[2]}日本学期转学人数`,
        key: 'termTranferNumDate',
        width: 120,
        align: 'center',
      },
      // {
      //   title: `${tempSimpleDate_[1]}月${tempSimpleDate_[2]}日当天本学期转学人数`,
      //   key: 'termTranferNum',
      //   width: 120,
      //   align: 'center',
      // },
      {
        title: `截至${tempSimpleDate_[1]}月${tempSimpleDate_[2]}日新招学生人数`,
        key: 'termNewNumDate',
        width: 120,
        align: 'center',
      }
    )
    /** 学部维度 */
    switch (dimension) {
      case 'schoolDepart':
        columns.splice(2, 1, {
          title: '学部',
          key: 'schoolDepartName',
          width: 100,
          align: 'center',
        })
        break
      case 'grade':
        columns.splice(2, 1, {
          title: '年级',
          slot: 'unitGradeName',
          width: 100,
          align: 'center',
        })
        break
      case 'class':
        columns.splice(2, 1, {
          title: '班级',
          slot: 'className',
          width: 100,
          align: 'center',
        })
        break
    }
    this.$set(this.$data, 'tableColumns', columns)

    /** 处理本学期起始日期 */
    if (Boolean(termDate)) {
      const tempTerm = termDate.split('-')
      columns.splice(
        3,
        0,
        {
          title: `${tempTerm[1]}月${tempTerm[2]}日${this.$t(
            'table.beforeTerm'
          )}`,
          key: 'lastTermNormalNum',
          width: 120,
          align: 'center',
        },
        {
          title: `截至${tempTerm[1]}月${tempTerm[2]}日上学期转学人数`,
          key: 'lastTermTranferNum',
          width: 120,
          align: 'center',
        }
      )

      this.$set(this.$data, 'tableColumns', columns)
    }

    this.fetch()
  }

  /** 导出 */
  private handleExport() {
    const {
      termID,
      doubleDate,
      simpleDate,
      classID,
      dateType,
      dimension,
      lastTermDate,
      manageTypeID,
      parentUnitID,
      phaseID,
      schoolDepartID,
      termDate,
      unitClassTypeID,
      unitGradeID,
      unitID,
      transType,
    } = this.parameter

    const parameter = Object.assign(
      {},
      {
        termID,
        parentUnitIDs: [parentUnitID],
        unitIDs: [unitID],
        schoolDepartIDs: schoolDepartID,
        unitGradeIDs: unitGradeID,
        unitClassTypeIDs: unitClassTypeID,
        classIDs: classID,
        phaseIDs: phaseID,
        manageTypeIDs: manageTypeID,
        lastTermDate: new Date(lastTermDate).getTime(),
        termDate: new Date(termDate).getTime(),
        dimension,
        dateType,
        transType,
        dates:
          dateType === 0 || dateType === 1
            ? [new Date(simpleDate).getTime()]
            : [
                new Date(doubleDate[0]).getTime(),
                new Date(doubleDate[1]).getTime(),
              ],
      }
    )

    this.export(parameter).then((res: { result: any }) => {
      window.location.href = `${res.result}`
    })
  }

  /** 请求数据 */
  private fetch() {
    const {
      termID,
      doubleDate,
      simpleDate,
      classID,
      dateType,
      dimension,
      lastTermDate,
      manageTypeID,
      parentUnitID,
      phaseID,
      schoolDepartID,
      termDate,
      unitClassTypeID,
      unitGradeID,
      unitID,
      transType,
    } = this.parameter

    const parameter = Object.assign(
      {},
      {
        termID,
        parentUnitIDs: [parentUnitID],
        unitIDs: [unitID],
        schoolDepartIDs: schoolDepartID,
        unitGradeIDs: unitGradeID,
        unitClassTypeIDs: unitClassTypeID,
        classIDs: classID,
        phaseIDs: phaseID,
        manageTypeIDs: manageTypeID,
        lastTermDate: new Date(lastTermDate).getTime(),
        termDate: new Date(termDate).getTime(),
        dimension,
        dateType,
        transType,
        dates:
          dateType === 0 || dateType === 1
            ? [new Date(simpleDate).getTime()]
            : [
                new Date(doubleDate[0]).getTime(),
                new Date(doubleDate[1]).getTime(),
              ],
      }
    )

    if (dateType !== 2) {
      this.fetchList(parameter)
    } else {
      if (
        new Date(doubleDate[0]).getTime() > 0 ||
        new Date(doubleDate[1]).getTime() > 0
      ) {
        this.fetchList(parameter)
      } else {
        this.$Message.warning('时间段不能为空')
      }
    }
  }
  private validTerm() {
    const { termID } = this.parameter
    const termID_ = `${termID}`
    const termFlag: string = termID_.charAt(termID_.length - 1)
    if (termFlag === '1') {
      this.parameter = Object.assign(this.parameter, {
        lastTermDate: `${new Date().getFullYear()}-03-01`,
        termDate: `${new Date().getFullYear()}-09-01`,
      })
    }

    if (termFlag === '2') {
      this.parameter = Object.assign(this.parameter, {
        lastTermDate: `${new Date().getFullYear()}-09-01`,
        termDate: `${new Date().getFullYear()}-03-01`,
      })
    }
  }
  mounted() {
    this.validTerm()
    this.$set(this.$data, 'tableColumns', this.columnsData)
  }

  /** 初始化 */
  created() {
    /** 判断当前身份 */
    const { parentUnitID } = this.parameter
    if (
      this.identityType === Constant.IdentityType.School /** 学校身份 */ &&
      !(Boolean(parentUnitID) || parentUnitID === 0) /** 无学校id */
    ) {
      this.$set(this.$data, 'unitID', this.user?.parentUnitID)
    } else
      this.identityType === Constant.IdentityType.Organization && // 当前为机构身份
        !(Boolean(this.organID) || this.organID === 0)
    {
      const _organID = this.user?.organID
      this.$set(this.parameter, 'organID', _organID)
    }
    if (Boolean(this.unitID)) {
      this.fetchTerm(this.termParameter)
    }
  }
}
