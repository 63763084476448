import { mergeLocaleMessage } from '@util'

export const messages: Messages = {
  'zh-CN': {
    message: {
      noDataText: '暂无数据',
      noParameter: '请先选择搜索条件',
      noTermID: '最少选择到学期',
      studentCount: '学生人数',
      transfersCount: '转出人数',
      turnoverRate: '实际流失率',
      null: '-',
    },
  },
  'en-US': {
    message: {
      noDataText: '暂无数据',
      noParameter: '请先选择搜索条件',
      noTermID: '最少选择到学期',
      studentCount: '学生人数',
      transfersCount: '转出人数',
      turnoverRate: '实际流失率',
      null: '-',
    },
  },
}

export default mergeLocaleMessage(messages)
