/**
 * 毕业学生路由配置
 */

import { RouteConfig } from 'vue-router'

/** 子页面容器 */
import RouterViewLayout from '@layouts/router-view-layout'

/** 学生列表 */
import StudentListPage from '@pages/graduation/student-list'

/** 学生详情页面 */
import StudentDetailPage from '@pages/student-detail'

/** 短信记录页成 */
import SmsRecordPage from '@pages/sms-record'

/** 批量编辑毕业方向 */
import BatchEditDirectionPage from '@pages/batch-edit-direction'

import { Constant } from 'xuexin-vuex'

const children: RouteConfig[] = []

// 毕业学生列表
children.push({
  path: '/students/graduation',
  name: 'graduation-students',
  component: StudentListPage,
  meta: { ignore: true, authCode: '050603', parentAuthCode: '0506' },
})

// 学生详情
children.push({
  path: ':termID/:unitID/:xuexinID',
  name: 'graduation-student-detail',
  component: StudentDetailPage,
  meta: { title: '学生详情', authCode: '050603', parentAuthCode: '0506' },
  props: { actionType: 'detail' },
})

/** 短信发送记录 */
children.push({
  path: 'sms-record',
  name: 'graduation-sms-record',
  component: SmsRecordPage,
  meta: { title: '短信发送记录', authCode: '050603', parentAuthCode: '0506' },
  props: {
    actionType: Constant.ActionType.Graduation,
  },
})

// 批量编辑学生毕业去向
children.push({
  path: ':termID/directions',
  name: 'batch-edit-direction',
  component: BatchEditDirectionPage,
  meta: { title: '编辑毕业去向', authCode: '050603', parentAuthCode: '0506' },
  props: true,
})

export default {
  path: '/students/graduation',
  name: 'graduation',
  redirect: { name: 'graduation-students' },
  meta: { title: '毕业学生', authCode: '050603' },
  component: RouterViewLayout,
  props: { name: 'graduation' },
  children,
}
