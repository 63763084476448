












































































import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import { ActionType } from 'xuexin-vuex/src/constant'
import AppModal from '@components/app-modal'
import AppSelect from '@components/app-select'
import {
  Button,
  Icon,
  Form,
  FormItem,
  RadioGroup,
  Radio,
  Input,
  Checkbox,
} from 'view-design'
import InformationSetup from '@store/modules/information-setup'
import i18n from '../../i18n'
const components = {
  AppModal,
  Button,
  Icon,
  Form,
  FormItem,
  Input,
  AppSelect,
  RadioGroup,
  Radio,
  Checkbox,
}
@Component({ name: 'AddEditModal', components, i18n })
export default class AddEditModal extends Vue {
  @Ref('form') private readonly form!: Form
  @Model('input') private value!: any
  @Prop({ type: String }) private readonly modalType!: string
  @Prop({ type: Number }) private readonly direction!: number
  @Prop({ type: Object }) private readonly data!: InformationSetup.Entity
  @InformationSetup.Action('create')
  private readonly create!: InformationSetup.Action.Create
  @InformationSetup.Action('update')
  private readonly update!: InformationSetup.Action.Update
  @InformationSetup.Getter('listStatus')
  private readonly listStatus!: InformationSetup.Getter.ListStatus
  @InformationSetup.Getter('itemStatus')
  private readonly itemStatus!: InformationSetup.Getter.ItemStatus

  // todo 调用退回接口
  private readonly prefixCls = 'add-edit-view'
  private visible = false
  private loading = true
  private defaultSort = -1
  private list = [
    { value: 0, label: '文本框' },
    { value: 1, label: '下拉列表' },
    { value: 2, label: '单选' },
    { value: 3, label: '复选' },
    { value: 4, label: '时间' },
  ]

  private model: InformationSetup.Entity = {
    columnName: '',
    alias: '',
    columnType: -1,
    cusDatas: [
      {
        cusDataID: -1,
        dataName: '',
        sort: 0,
        selected: 0,
      },
      {
        cusDataID: -1,
        dataName: '',
        sort: 1,
        selected: 0,
      },
    ],
  }

  get rules() {
    return {
      columnName: {
        validator: this.validName,
        type: 'blur',
        required: this.data.dataType != 1,
      },
      columnType: {
        validator: this.validType,
        type: 'change',
        required: this.data.dataType != 1,
      },
      cusDatas: {
        validator: this.validDatas,
        required: this.data.dataType != 1,
      },
    }
  }

  // 监听窗口显示状态
  @Watch('value', { immediate: true })
  private watchVisible(value: boolean) {
    this.visible = value
    if (this.modalType == 'edit') {
      this.$set(this.$data, 'model', Object.assign({}, this.data))
      if (this.data.cusDatas !== void 0) {
        let _cusDatas = this.data.cusDatas.map((item: any) => {
          return {
            cusDataID: item.cusDataID,
            dataName: item.dataName,
            sort: item.sort,
            selected: item.selected,
          }
        })
        this.$set(this.$data.model, 'cusDatas', _cusDatas)
      }
    } else {
      this.model.kindCode = this.data.kindCode
    }
  }

  // 获取列表状态
  get getListStatus() {
    return this.listStatus('student')
  }

  // 获取列表状态
  get getItemStatus() {
    return this.itemStatus('student', this.data.extendID || -1)
  }

  // 监听添加状态
  @Watch('getListStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (!creating && previous) {
      if (
        this.getListStatus.creatingError !== null &&
        this.getListStatus.creatingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.creatingError)
        return this.stopAction()
      }
      this.form.resetFields()
      this.$emit('input', false)
      this.$emit('on-change')
      this.$Message.success(this.$t('message.create-success'))
    }
  }

  // 监听更新状态
  @Watch('getItemStatus.updating')
  private watchItemStatus(updating: boolean, previous: boolean) {
    if (!updating && previous) {
      if (
        this.getItemStatus.updatingError !== null &&
        this.getItemStatus.updatingError !== void 0
      ) {
        this.$Message.error(this.getItemStatus.updatingError)
        return this.stopAction()
      }
      this.form.resetFields()
      this.$emit('input', false)
      this.$emit('on-change')
      this.$Message.success(this.$t('message.edit-success'))
    }
  }

  private handleChange(item: any, state: boolean) {
    this.model.cusDatas?.forEach(item => {
      item.selected = 0
    })
    if (state) {
      item.selected = 1
    }
  }

  private stopAction(): void {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleAdd() {
    this.$set(
      this.$data.model,
      'cusDatas',
      this.model.cusDatas?.concat([
        {
          dataName: '',
          sort: this.model.cusDatas.length,
          selected: 0,
        },
      ])
    )
  }

  private handleDelete(index: number) {
    this.model.cusDatas?.splice(index, 1)
  }
  // 确定事件
  private handleOK(): void {
    this.form.validate((valid?: boolean) => {
      if (!valid) {
        return this.stopAction()
      }
      if (this.modalType == 'add') {
        const { extendID, ...parameter } = Object.assign(this.model, {
          type: 'student',
          kindCode: this.data.kindCode,
          direction: this.direction,
        })
        //新建信息项
        this.create(parameter)
      } else {
        if (this.data.dataType == 1) {
          //只编辑别名
          this.update({
            type: 'student',
            editType: 'alias',
            alias: this.model.alias,
            dataType: this.data.dataType,
            extendID: this.data.extendID,
            direction: this.direction,
          })
        } else {
          //编辑信息项
          this.update(
            Object.assign(this.model, {
              type: 'student',
              editType: 'alias',
              kindCode: this.data.kindCode,
              direction: this.direction,
            })
          )
        }
      }
    })
  }
  // 关闭弹窗
  private handleHidden(): void {
    this.$emit('input', false)
    this.form.resetFields()
    this.stopAction()
  }
  // 验证规则
  private validName(rule: string, value: string, callback: any) {
    if (value === '') {
      callback(new Error(`名称不可为空`))
    } else {
      callback()
    }
  }
  private validType(rule: string, value: number, callback: any) {
    if (value === -1) {
      callback(new Error(`显示类型不可为空`))
    } else {
      callback()
    }
  }
  private validDatas(rule: string, value: any, callback: any) {
    if (
      value.length < 2 &&
      this.model.columnType !== 4 &&
      this.model.columnType !== 5
    ) {
      callback(new Error(`请至少设置两个选项`))
    } else {
      callback()
    }
  }
}
