import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppRadioGroup from '@components/app-radio-group'
import AppSelect from '@components/app-select'
import Dictionary from '@store/modules/dictionary'
import DictionaryType from 'types/dictionary'

type ViewType = 'text'
const prefixCls = `collegeDirection`

function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `CollegeDirection${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class CollegeDirectionComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType!: ViewType

    @Dictionary.Getter('listStatus')
    private readonly getStatus!: DictionaryType.Getter.ListStatus
    @Dictionary.Getter('list')
    private readonly getList!: DictionaryType.Getter.List

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }

    get locale(): Locale {
      return this.$i18n.locale as Locale
    }

    get list() {
      const list = this.getList({ catalogID: 117 }).filter((item: any) => {
        // 过滤被禁用的数据
        return item.enable !== 0
      })

      return this.extra.concat(
        list.map((value: any) => {
          return {
            label: value.dataValue,
            value: value.dataKey,
          }
        })
      )
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
        case ComponentType.RadioGroup:
          props.data = this.list
          break
      }
      return props
    }

    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (type === ComponentType.Text || this.viewType === 'text') {
        children = this.list.find(i => i.value == this.$attrs.value).label
      }

      return createElement(
        component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return CollegeDirectionComponent
}

export const CollegeDirectionRadioGroup = createComponent(
  AppRadioGroup,
  ComponentType.RadioGroup
)
export const CollegeDirectionSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
export const CollegeDirectionText = createComponent('span', ComponentType.Text)
