
















import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import { Select, Option } from 'view-design'
import Dictionary from '@store/modules/dictionary'
import DictionaryType from 'types/dictionary'

type DataType = {
  freshStudent: number | boolean
  phaseID: number
}

const components = {
  Select,
  Option,
}
@Component({ name: '', components })
export default class Dictionaryselect extends Vue {
  @Prop({ default: '' }) private readonly value!: string | number
  @Prop({ type: Number }) private readonly phaseID!: number
  @Prop({ type: [Boolean, Number], default: 1 })
  private readonly freshStudent!: any
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
  @Prop({ type: Boolean, default: false }) private readonly viewText!: boolean
  @Prop({ type: Boolean, default: false }) private readonly transfer!: boolean
  @Prop({ type: String, default: 'academicDirection' })
  private readonly type!: string

  @Dictionary.Action('fetchList')
  private readonly fetchList!: DictionaryType.Action.FetchList
  @Dictionary.Getter('listStatus')
  private readonly getStatus!: DictionaryType.Getter.ListStatus
  @Dictionary.Getter('list')
  private readonly getList!: DictionaryType.Getter.List

  private model: string | number = ''
  private name: string = ''

  get status() {
    return this.getStatus(this.parameter)
  }

  get fetching() {
    return this.status.fetching === true
  }
  get list() {
    const list = this.getList(this.parameter).filter((item: any) => {
      // 过滤被禁用的数据
      return item.enable !== 0
    })

    return list.map((value: any) => {
      return {
        label: value.dataValue,
        value: value.dataKey,
      }
    })
  }

  get parameter() {
    if (this.phaseID == 3 && this.type !== 'academicDirection') {
      return {
        phaseID: this.phaseID,
        catalogID: 115,
      }
    } else {
      return {
        phaseID: this.phaseID,
        freshStudent: this.freshStudent ? 1 : 0,
        catalogID:
          this.type === 'academicDirection'
            ? 114
            : this.freshStudent
            ? 115
            : 107,
      }
    }
  }

  @Watch('parameter')
  watchParameter() {
    this.fetch()
  }
  @Watch('value', { immediate: true })
  watchValue(value: any) {
    this.model = value
    //查看name时，处理name
    const list = this.getList(this.parameter).filter((item: any) => {
      // 过滤被禁用的数据
      return (this.phaseID != 4 || !this.freshStudent) && item.dataKey !== '5'
    })

    if (value && list.length > 0) {
      this.name =
        list.find(item => {
          return `${item.dataKey}` === `${value}`
        })?.dataValue || ''
    }
  }

  private handleChange(val: string | number) {
    this.model = val

    this.$emit('input', val)
  }
  private fetch() {
    // if (this.status.fetching === true || this.status.loaded) {
    //   // 以上情况，不需要请求数据
    //   return void 0
    // }

    if (this.value && this.list.length > 0) {
      this.name =
        this.list.find((item: any) => {
          return `${item.value}` === `${this.value}`
        })?.label || ''
    }
  }

  created() {
    // this.fetch()
  }
}
