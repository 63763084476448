var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.prefixCls},_vm._l((_vm.data),function(item,index){return (
      item.columnCode === 'statusID' ||
        item.columnCode === 'ethnicity' ||
        item.columnCode === 'residenceType' ||
        item.columnCode === 'nativePlace' ||
        item.columnCode === 'birthplace' ||
        item.columnCode === 'registeredPlace' ||
        (item.columnCode !== 'statusID' &&
          item.columnCode !== 'ethnicity' &&
          item.columnCode !== 'passportDate' &&
          item.columnCode !== 'passportAddress' &&
          item.columnCode !== 'residenceType' &&
          item.columnCode !== 'nativePlace' &&
          item.columnCode !== 'birthplace' &&
          item.columnCode !== 'registeredPlace')
    )?_c('div',{key:index,staticClass:"info"},[(!(item.columnCode === 'idInfo' && item.value.length > 0))?_c('span',{staticClass:"key_item"},[_vm._v(_vm._s(item.columnName)+":")]):_vm._e(),_vm._v(" "),(item.columnCode === 'idInfo' && item.value.length > 0)?_c('div',_vm._l((item.value),function(idInfo){return _c('span',{key:idInfo.idNo,style:(("display: inline-block;" + (idInfo.mainFlag === 1 ? '' : 'margin-top:16px')))},[_c('span',{staticClass:"key_item"},[_vm._v(_vm._s(idInfo.mainFlag === 1 ? '主要' : '辅助')+"证件:")]),_vm._v(" "),_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(idInfo.idTypeName)+" - "+_vm._s(idInfo.idNo)+"\n\n          "+_vm._s([2, 3, 6].includes(idInfo.idType)
              ? (" - 护照有效期:" + (idInfo.idDate) + " - 护照颁发地:" + (idInfo.idAddress))
              : '')+"\n        ")])])}),0):(
        (_vm.type === 'detail' &&
          item.columnCode !== 'photo' &&
          item.columnCode !== 'medicalBirthCertificate' &&
          item.columnCode !== 'height' &&
          item.columnCode !== 'weight' &&
          !item.children) ||
          (item.children && item.children.length === 0)
      )?_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(item.valueName || _vm.$t('text.null')))]):(_vm.type === 'detail' && item.columnCode === 'height')?_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(item.valueName || _vm.$t('text.null'))+" CM")]):(_vm.type === 'detail' && item.columnCode === 'weight')?_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(item.valueName || _vm.$t('text.null'))+" KG")]):(
        _vm.type === 'detail' &&
          item.children &&
          _vm.nullValue(item.children) < item.children.length
      )?_vm._l((item.children),function(ite){return _c('span',{key:ite.columnCode,staticClass:"value_item"},[_vm._v(_vm._s(item.valueName)+"\n      "),_vm._l((item.children),function(ite){return (ite.valueName !== null)?_c('span',{key:ite.columnCode},[_c('span',{staticClass:"second"},[_vm._v(_vm._s(ite.valueName || ''))])]):_vm._e()})],2)}):(
        _vm.type === 'detail' &&
          item.children &&
          _vm.nullValue(item.children) === item.children.length
      )?_c('span',{staticClass:"value_item"},[_vm._v("\n      "+_vm._s(item.valueName || _vm.$t('text.null'))+"\n    ")]):(
        (item.columnCode === 'photo' ||
          item.columnCode === 'medicalBirthCertificate') &&
          item.value
      )?_c('Avatar',{staticClass:"value_item",attrs:{"src":item.value,"shape":"square","size":"80"}}):_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(_vm.$t('text.null')))])],2):_vm._e()}),0)}
var staticRenderFns = []

export { render, staticRenderFns }