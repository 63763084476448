var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ViewLayout',{attrs:{"name":_vm.prefixCls,"loading":_vm.loading}},[_c('div',{class:_vm.classes},[_c('div',{class:("card " + _vm.prefixCls + "_head")},[_c('ReportSearch',{attrs:{"type":"class-payment"},on:{"on-search":_vm.handleSearch,"on-export":_vm.handleExport},model:{value:(_vm.parameter),callback:function ($$v) {_vm.parameter=$$v},expression:"parameter"}})],1),_vm._v(" "),_c('div',{class:("card " + _vm.prefixCls + "_body")},[_c('AppTable',{class:(_vm.prefixCls + "_table"),attrs:{"no-data-text":_vm.noDataText,"data":_vm.tableData,"columns":_vm.tableColumn,"loading":_vm.fetching,"show-summary":"","summary-method":_vm.handleSummary},scopedSlots:_vm._u([{key:"gender",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.gender === 0 ? '男' : row.gender === 1 ? '女' : '保密'))])]}},{key:"studentStatus",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.studentStatus === 0
              ? '正常'
              : row.studentStatus === 1
              ? '休学'
              : row.studentStatus === 2
              ? '空挂学籍'
              : '外出艺体'))])]}}])}),_vm._v(" "),(_vm.total !== 0 || _vm.valid !== true)?_c('AppPagination',{class:(_vm.prefixCls + "_pagination"),attrs:{"current":_vm.page,"page-size":_vm.pageSize,"total":_vm.total,"page-size-opts":[50, 100, 150, 200, 250, 300],"disabled":_vm.fetching,"show-sizer":true},on:{"on-change":_vm.handlePageChange,"on-page-size-change":_vm.handlePageSizeChange}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }