









































































import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import { User, Constant, Student } from 'xuexin-vuex'
import { Form, FormItem, Input, DatePicker, Scroll, Spin } from 'view-design'
import Records from '@store/modules/records'
import i18n from './i18n'

type DataType = {
  list: Array<{ id: string; name: string; mobile: number }>
  searches: Record<string, any>
  authCode: string
  actionType: Constant.ActionType
  pageType: Constant.PageType
  isSms: boolean
  unitID: number
  recordID: number
}

type Model = {
  xuexinIDs: string[]
  msg: string
  timingTime?: Date
  autograph?: string
}

const components = {
  AppModal,
  Form,
  FormItem,
  Input,
  DatePicker,
  Scroll,
  Spin,
}
@Component({ name: 'SendMessageModal', components, i18n })
export default class SendMessageModal extends Vue {
  @User.Action('fetchList') private readonly fetchList!: User.Action.FetchList
  @User.Action('sendMsg') private readonly sendMessage!: User.Action.SendMsg
  @User.Getter('list') private readonly getStudents!: User.Getter.List
  @User.Getter('listStatus')
  private readonly listStatus!: User.Getter.ListStatus

  @Records.Action('fetch')
  private readonly fetchFailureUserList!: Records.Action.Fetch
  @Records.Getter('item') private readonly item!: Records.Getter.Item
  @Records.Getter('itemStatus')
  private readonly itemStatus!: Records.Getter.ItemStatus

  @Model('input') private value!: boolean
  @Prop({ default: () => ({}), required: true })
  private readonly data!: DataType
  @Ref('form')
  private readonly form!: Form

  private readonly prefixCls = 'send-message-modal'
  private page = 1
  private pageSize = 10
  private visible = false
  private loading = true

  private model: Partial<Model> = {}
  private names: string = ''
  private flatStudents: string[] = []

  get rules(): FormRules {
    return {
      //prettier-ignore
      msg: [{required: true, trigger: 'change', message: this.$t('message.content')}]
    }
  }

  /** 搜索条件 */
  get searches() {
    return this.data.searches || {}
  }

  /** 学校ID */
  get unitID() {
    return this.searches.unitID
  }

  get termID() {
    return this.searches?.termID
  }

  get isUseful() {
    return Boolean(this.unitID) === false || Boolean(this.termID) === false
  }

  /** 菜单权限 */
  get authCode() {
    return this.data.authCode
  }

  /** 请求类型 */
  get actionType() {
    return this.data.actionType
  }

  /** 页面请求类型 */
  get type() {
    return this.data.pageType
  }

  /** 是否选择了用户 */
  get hasUser() {
    return this.data?.list?.length === 0
  }

  /** 是否重新发送 */
  get isSms() {
    return this.data.isSms || false
  }

  /** 发送记录ID */
  get recordID() {
    return this.data?.recordID
  }

  /** 获取用户列表 */
  get list(): any {
    /** 获取发送失败的用户信息 */
    if (this.isSms === true && this.hasUser === true) {
      return this.item(this.recordID)?.child || []
    }

    /** 获取要发送短信的所有用户信息 */
    return this.data.list || []
  }

  /** 请求参数 */
  get parameter() {
    return {
      searches: this.searches,
      actionType: this.actionType,
    }
  }

  /** 共计当前给多少人发送信息 */
  get total() {
    /** 没有选择用户 */
    if (this.hasUser === true) {
      if (this.isSms === true) {
        return this.item(this.recordID)?.failureCount || 0
      }
      return this.listStatus(this.parameter)?.total
    }
    /** 选择了用户 */
    return this.list.length
  }

  get noStudent() {
    return this.total === 0
  }

  /** 控制定时发送时间 */
  get limitDate() {
    if (this.visible === true || this.value === true) {
      return {
        disabledDate: (date: number) => {
          return date && date.valueOf() < Date.now() - 86400000
        },
      }
    }
  }

  /** 请求用户列表状态 */
  get fetching() {
    return this.listStatus(this.parameter)?.fetching === true
  }
  get fetchingError() {
    return this.listStatus(this.parameter)?.fetchingError || null
  }

  /** 请求重新发送用户状态 */
  get failFetching() {
    return this.itemStatus(this.recordID)?.fetching === true
  }
  get failFetchingError() {
    return this.itemStatus(this.recordID).fetchingError || null
  }

  /** 发送短信状态 */
  get status() {
    return this.listStatus(this.model)
  }
  get sending() {
    return this.status.creating === true
  }
  get sendingError() {
    return this.status.creatingError || null
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 窗口没有打开的时候不处理状态 */
    if (this.visible === false || this.value === false) {
      return void 0
    }

    if (fetching === false && previous === true) {
      if (this.fetchingError !== null) {
        return this.$Message.error(this.fetchingError)
      }
      /** 没有选择用户, 初始化重新获取数据*/
      if (this.page === 1) {
        this.flatStudents = []
      }
      const result = this.getStudents(this.parameter)
      /** 返回数据为空不做处理 */
      if (result.length === 0) {
        return
      }
      /** 返回数据进行拼接 */
      this.flatStudents = this.flatStudents.concat(
        result.map((item: User.Entity) => {
          return `${item.studentName}（${item.smsPhone ||
            item.parentMobile}）`
        })
      )
      this.$set(this.$data, 'names', this.flatStudents.join('、'))
    }
  }

  @Watch('sending')
  private watchSending(sending: boolean, previous: boolean) {
    /** 窗口没有打开的时候不处理状态 */
    if (this.visible === false || this.value === false) {
      return void 0
    }

    if (sending === false && previous === true) {
      if (this.sendingError !== null) {
        this.$Message.error(this.sendingError)
        return this.stopAction()
      }
      this.$Message.success(this.$t('message.success'))
      this.$emit('on-finish')
    }
  }

  private handleReachBottom() {
    const result = this.getStudents(this.parameter)
    if (result.length === 0) {
      return
    }

    return new Promise(resolve => {
      setTimeout(() => {
        this.page++
        this.fetch()
        resolve()
      }, 2000)
    })
  }

  private stopAction() {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleOK() {
    /** 判断传入的条件 */
    if (this.isUseful === true) {
      this.$emit('on-finish')
      return this.stopAction()
    }

    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        return this.stopAction()
      }

      /** 校验通过发送短信 */
      const { timingTime: dateTime } = this.model
      const timingTime = dateTime ? new Date(dateTime).getTime() : null
      let parameter = Object.assign({}, this.model, {
        timingTime,
        unitID: this.unitID,
        searches: this.searches,
        type: this.type,
        recordID: this.recordID,
        smsbos: this.data.list,
      })

      /** 没有选择人员信息的时候，不给后端传xuexinIDs */
      if (this.hasUser === true) {
        parameter = Object.assign({}, parameter, {
          smsbos: [],
        })
      }
      this.sendMessage(parameter)
    })
  }

  private fetch() {
    /** 选择了用户 */
    if (this.hasUser === false) {
      return
    }
    /** 查询发送短信失败的所有人员信息 */
    if (this.isSms === true) {
      this.fetchFailureUserList(
        Object.assign(
          {},
          {
            sendStatus: 0, // 失败状态
            recordID: this.recordID,
            page: this.page,
            pageSize: this.pageSize,
          }
        )
      )
      return
    }
    /** 查询列表中所有要发送短信的人员信息 */
    this.fetchList(
      Object.assign({}, this.parameter, {
        authCode: this.authCode,
        page: this.page,
        pageSize: this.pageSize,
      })
    )
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      /** 判断传入的条件 */
      if (this.isUseful === true) {
        return
      }

      /** 窗口打开page恢复到1 */
      this.$set(this.$data, 'page', 1)
      /** 窗口打开获取用户数据 */
      this.fetch()

      /** 处理需要展示的用户名及电话信息 */
      const xuexinIDs: string[] = []
      // prettier-ignore
      const names = this.list.map((item: any) => {
          return (
            xuexinIDs.push(item.id || item.xuexinID || item.studentID),
            `${item.name ? item.name : `${this.$t('message.none')}`}（${
              item.mobile ? item.mobile : `${this.$t('message.none')}`
            }）`
          )
        })
        .join('、')
      this.$set(this.$data, 'names', names)
      this.$set(this.$data, 'model', { xuexinIDs })
    }
    this.$emit('on-visible-change', visible)
  }

  private handleHidden() {
    this.stopAction()
    this.$emit('on-hidden')
    if (this.isUseful === false) {
      this.form.resetFields()
    }
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }
}
