









































































import Vue from 'vue'
import { Component, Prop, Mixins, Watch, Ref } from 'vue-property-decorator'
import { TableColumn, Checkbox } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'
import AppTable from '@components/app-table'
import AppCheckboxGroup from '@components/app-checkbox-group'
import i18n from './i18n'
import Export from '@store/modules/export'
import ExportTyping from '../../../../types/export'
import PageTyping from '../../../../types/page'
import ActualLossRate from '@store/modules/actual-loss-rate'
import ActualLossRateType from 'types/actual-loss-rate'
import { setReportData, spanChildMethod } from '@util/report'
import HomeEcharts from '../components/home-echarts'
import getPageColumns from '@components/report-toolbar/columns'

const components = {
  ViewLayout,
  ReportSearch,
  ReportToolbar,
  AppTable,
  HomeEcharts,
  Checkbox,
  AppCheckboxGroup,
}
@Component({ name: 'ActualLossRatePage', components, i18n })
export default class ActualLossRatePage extends Vue {
  @ActualLossRate.Action('fetchChart') private readonly fetchChart!: any
  @ActualLossRate.Action('fetch') private readonly fetchTable!: any
  @ActualLossRate.Action('export') private readonly export!: any
  @ActualLossRate.Getter('list')
  private readonly getList!: ActualLossRateType.Getter.List
  @ActualLossRate.Getter('listStatus')
  private readonly listStatus!: ActualLossRateType.Getter.ListStatus
  @ActualLossRate.Getter('chartStatus')
  private readonly chartStatus!: ActualLossRateType.Getter.ChartStatus
  @ActualLossRate.Getter('chartItem')
  private readonly getChartItem!: ActualLossRateType.Getter.ChartItem

  private readonly prefixCls = 'actual-loss-rate-page'
  private loading = false
  /** 上下学期 */
  private termType = [0, 1]
  private termTypeValue = [
    {
      value: 0,
      label: '上学期',
    },
    {
      value: 1,
      label: '下学期',
    },
  ]

  private parameter: Partial<PageTyping.SearchParameter> = {}
  private searchParameter: Partial<PageTyping.SearchParameter> = {}
  private defaultData: any = []
  private tParameter = { countType: 0 }
  /** 合并行 */
  private rowspans: any = {}
  /** 合并前四项 */
  private sumKey: any = []

  /** 合计行下标 {index:下标，type:1-2-3-4(合计类型)} */
  private inventedDataIndexArr: any = []

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  // 列表加载状态
  get chartLoading() {
    return this.getChartStatus.fetching
  }

  get tableColumns(): any {
    return getPageColumns('ActualLossRate')
      .map((item: any) => {
        return {
          title: item.title,
          key: item.code,
          width: 100,
          fixed: 'left',
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat([
        {
          title: '上学期',
          align: 'center',
          children: [
            {
              title: '进班点名人数',
              key: 'lastCallNum',
              width: 130,
            },
            {
              title: '转出人数',
              key: 'lastOutNum',
              width: 110,
            },
            {
              title: '转入人数',
              key: 'lastInNum',
              width: 110,
            },
            {
              title: '流失率',
              key: 'lastLostRate',
              width: 130,
            },
          ],
        },
        {
          title: '下学期学期',
          align: 'center',
          children: [
            {
              title: '进班点名人数',
              key: 'nextCallNum',
              width: 130,
            },
            {
              title: '转出人数',
              key: 'nextOutNum',
              width: 110,
            },
            {
              title: '转入人数',
              key: 'nextInNum',
              width: 110,
            },
            {
              title: '流失率',
              key: 'nextLostRate',
              width: 130,
            },
          ],
        },
        {
          title: '全学年',
          align: 'center',
          children: [
            {
              title: '流失率',
              key: 'yearLostRate',
              width: 130,
            },
          ],
        },
      ])
  }

  get dataSources() {
    return this.getList()
  }

  get chartInfo(): any {
    return this.getChartItem()
  }
  get chartData() {
    if (this.chartInfo['organ-lost'] !== void 0) {
      return {
        series: this.chartInfo['organ-lost'].series,
        category: this.chartInfo['organ-lost'].category,
      }
    } else if (this.chartInfo['unit-lost'] !== void 0) {
      return {
        series: this.chartInfo['unit-lost'].series,
        category: this.chartInfo['unit-lost'].category,
      }
    } else {
      return {
        series: [],
        category: [],
      }
    }
  }

  // 获取列表请求状态
  get getListStatus() {
    return this.listStatus()
  }
  // 获取图表请求状态
  get getChartStatus() {
    return this.chartStatus()
  }

  get lostStatisLegend(): string[] {
    return ['学生人数', '转出人数', '流失率']
  }
  // 监听加载状态
  @Watch('getListStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.getListStatus.fetchingError !== null &&
        this.getListStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.fetchingError)
        return
      }

      let result = setReportData({
        list: this.dataSources,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
        countColumn: [
          'lastCallNum',
          'lastOutNum',
          'lastInNum',
          'nextCallNum',
          'nextOutNum',
          'nextInNum',
        ],
        percentageColumn: ['lastLostRate', 'nextLostRate', 'yearLostRate'],
      })
      this.defaultData = result.dataArr
      this.inventedDataIndexArr = result.indexArr
      this.rowspans = result.rowspans
    }
  }

  // 监听导出状态
  @Watch('getListStatus.deleting')
  private watchDeleting(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.getListStatus.deletingError !== null &&
        this.getListStatus.deletingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.deletingError)
        return
      }
    }
  }

  @Watch('termType', { immediate: true })
  private watchValue(value: number[]) {
    this.fetchChart(
      Object.assign({}, this.searchParameter, {
        termType: this.termType,
        schoolYear: 2020,
      })
    )
  }

  get fetching() {
    return false
  }
  created() {
    this.sumKey = getPageColumns('ActualLossRate').map((item: any) => {
      return item.code
    })
  }

  // 小记项发生变化
  private handleChangeType(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
      countColumn: [
        'lastCallNum',
        'lastOutNum',
        'lastInNum',
        'nextCallNum',
        'nextOutNum',
        'nextInNum',
      ],
      percentageColumn: ['lastLostRate', 'nextLostRate', 'yearLostRate'],
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }
  // 列表列头发生变化
  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.fetchTable(
      Object.assign({}, this.searchParameter, {
        columns: this.sumKey,
        countType: this.tParameter.countType,
      })
    )
  }
  // 合并行列方法
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }

  // 小计行的样式
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      if (row.hide) {
        return 'ivu-table-summary-row ivu-table-hide-row'
      }
      return 'ivu-table-summary-row'
    }
  }

  private fetch(value: any) {
    this.fetchChart(
      Object.assign({}, value, {
        termType: [0, 1],
      })
    )

    this.fetchTable(
      Object.assign({}, value, {
        columns: this.sumKey,
        countType: this.tParameter.countType,
      })
    )
  }
  private handleExport(value: any) {
    this.export(
      Object.assign({}, value, {
        columns: this.sumKey,
        countType: this.tParameter.countType,
      })
    ).then((res: any) => {
      if (res.result) {
        window.location.href = `${res.result}`
      }
    })
  }
  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    if (Boolean(parameter.termID) === false) {
      return this.$Message.warning(`请选择学期`)
    }
    this.searchParameter = Object.assign({}, parameter)
    this.fetch(parameter)
  }
}
