






















































import Vue from 'vue'
import { Component, Watch, Ref } from 'vue-property-decorator'
import { Session } from 'xuexin-vuex'
import { Form, FormItem, Input, Button } from 'view-design'
import { MobilePhoneReg } from '@util'
import i18n from './i18n'

const components = { Form, FormItem, Input, Button }
@Component({ name: 'VerificationStep', components, i18n })
export default class VerificationStep extends Vue {
  @Session.Action('sendSMSCode')
  private readonly sendSMSCode!: Session.Action.SendSMSCode
  @Session.Action('verifySMSCode')
  private readonly verifySMSCode!: Session.Action.VerifySMSCode
  @Session.Getter('status') private readonly status!: Session.Getter.Status
  @Ref('form') private readonly form!: Form
  @Ref('mobile') private readonly mobile!: Input
  @Ref('code') private readonly code!: Input

  private readonly prefixCls: string = 'verification-step'
  private isSent = false
  private countdown = 0
  private timerID = 0

  private model = { mobile: '', code: '', type: '1' }
  private rules: FormRules = {
    mobile: [{ trigger: 'blur', validator: this.verifyMobile }],
    code: [{ trigger: 'blur', validator: this.verifyCode }],
  }

  get classes() {
    return {
      'reset-password-step': true,
      [this.prefixCls]: true,
    }
  }

  get sendButtonText(): string {
    if (this.isSent === false) {
      return `${this.$t('text.sendCode')}`
    }

    const second = `${this.$t('text.second')}`
    const message = `${this.$t('text.resend')}`

    if (this.countdown === 0) {
      return `${message}`
    }

    return `${message}(${this.countdown}${second})`
  }

  get disableSendSMS(): boolean {
    return this.countdown > 0
  }

  get disableNextStep(): boolean {
    return this.isSent === false
  }

  get sending() {
    return this.status.sending === true
  }

  get verifying() {
    return this.status.verifying === true
  }

  get disabled() {
    return this.sending === true || this.verifying === true
  }

  @Watch('sending') // 短信验证码发送状态
  private watchSending(sending: boolean, previous: boolean) {
    // "获取短信验证码"，请求已完成。
    if (sending === false && previous === true) {
      if (this.status.sendingError !== null) {
        return this.$Message.error(this.status.sendingError)
      }

      this.$Message.success(this.$t('text.sendSuccess'))
      /** 主动设置短信验证码焦点 */
      this.$nextTick(() => this.code.focus())
    }
  }

  @Watch('verifying') // 短信验证码验证状态
  private watchVerifying(verifying: boolean, previous: boolean) {
    if (verifying === false && previous === true) {
      if (this.status.verifyingError !== null) {
        return this.$Message.error(this.status.verifyingError)
      }
      return this.$emit('input', 2)
    }
  }

  mounted() {
    this.mobile.focus()
  }

  private verifyMobile(
    rule: FormRule,
    value: string,
    callback: FormRuleCallback
  ) {
    if (value === void 0 || value === null || value.trim() === '') {
      return callback(`${this.$t('verification.mobileIsNull')}`)
    }

    if (MobilePhoneReg.test(value) === false) {
      return callback(`${this.$t('verification.mobileIsInvalid')}`)
    }

    callback()
  }

  private verifyCode(
    rule: FormRule,
    value: string,
    callback: FormRuleCallback
  ) {
    if (this.isSent === false) {
      return callback()
    }
    if (value === void 0 || value === null || value.trim() === '') {
      return callback(`${this.$t('verification.codeIsNull')}`)
    }

    callback()
  }

  private handleSendClick(): void {
    this.form.validateField('mobile', (error?: any) => {
      if (error !== '') {
        return this.$nextTick(this.mobile.focus)
      }

      /** 清除定时器 */
      window.clearInterval(this.timerID)

      /** 更新发送状态 */
      this.isSent = true
      /** FIXME: 测试结束后，应该修改为 60 秒 */
      this.countdown = 3

      /** 设置定时器 */
      this.timerID = window.setInterval(() => {
        if (this.countdown === 0) {
          return window.clearInterval(this.timerID)
        }

        this.countdown--
      }, 1000 * 1)

      this.sendSMSCode({ mobile: this.model.mobile, type: this.model.type })
    })
  }

  private handleNextClick(): void {
    this.form.validate((valid?: boolean) => {
      if (valid === false) {
        return void 0
      }

      /** 校验短信验证码 */
      this.verifySMSCode({
        mobile: this.model.mobile,
        type: '1',
        vererificationCode: this.model.code,
      })
    })
  }

  private handleEnter(name: string) {
    switch (name) {
      case 'mobile':
        this.form.validateField('mobile', valid => {
          if (valid !== void 0 && String(valid).trim() !== '') {
            return this.$nextTick(() => this.mobile.focus())
          }

          this.handleSendClick()
        })
        break
      case 'code':
        this.form.validateField('code', valid => {
          if (valid !== void 0 && String(valid).trim() !== '') {
            return this.$nextTick(() => this.code.focus())
          }

          this.handleNextClick()
        })
        break
      default:
        break
    }
  }
}
