export const setParameter = (
  authCode: string,
  parameter: Record<string, unknown>
): void => {
  const para: Record<string, unknown> = {}
  para[authCode] = parameter
  sessionStorage.setItem('parameter', JSON.stringify(para))
}
export const getParameter = (authCode: string): any => {
  const para: Record<string, Record<string, unknown>> = JSON.parse(
    sessionStorage.getItem('parameter') || '{}'
  )
  return para[authCode] || null
}
