import { namespace as BindingNamespace } from 'vuex-class'
import { Ajax } from 'xuexin-vuex/src/ajax'
import { handlers } from 'xuexin-vuex/src/util'
import { Download } from 'types'
import omit from 'lodash.omit'
import { ActionType } from 'xuexin-vuex/src/constant'

export const namespace = '@xuexin-vuex/download'

function getStateKey(parameter: Download.Payload) {
  return `download_url`
}

export const types = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
}

const state: Download.State = {
  list: { data: [] },
  items: {},
}

const actions: Download.Actions = {
  /** 请求下载地址 */
  fetch(context, payload) {
    let api = `post ${__STUDENT_API__}/export/divideStudent/model`
    if (payload.actionType === ActionType.Divide) {
      api = `post ${__STUDENT_API__}/export/divideStudent/model`
    }
    if (
      payload.actionType === ActionType.InClass &&
      payload.url === 'template'
    ) {
      //在班学生重新分班导入模板下载
      api = `post ${__STUDENT_API__}/download/template/in-class/divide`
    }

    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload: payload,
      api: api,
      parameter: payload,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
}

const mutations: Download.Mutations = {
  [types.FETCH_REQUEST](state, { payload }) {
    handlers.itemRequest(state, getStateKey(payload), 'fetching')
  },
  [types.FETCH_SUCCESS](state, { result, payload }) {
    handlers.itemSuccess(state, getStateKey(payload), 'fetching', {
      url: result,
    })
  },
  [types.FETCH_FAILURE](state, { message, payload }) {
    handlers.itemFailure(state, getStateKey(payload), 'fetching', message)
  },
}

const getters: Download.Getters = {
  list(state, getters) {
    return state.list.data.map(field => getters.item(`${field}`)!)
  },
  listStatus(state) {
    return omit(state.list, ['data'])
  },
  item(state) {
    return field => state.items[`${field}`]?.data
  },
  itemStatus(state) {
    return field => omit(state.items[`${field}`], ['data'])
  },
}

export const { State, Action, Mutation, Getter } = BindingNamespace(namespace)
export const Module = { state, actions, mutations, getters, namespaced: true }
export default {
  State,
  Action,
  Mutation,
  Getter,
  namespace,
  types,
  Module,
}
