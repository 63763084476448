var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.prefixCls},_vm._l((_vm.data),function(item,index){return (
      item.columnCode !== 'enrollScore' ||
        (item.columnCode === 'enrollScore' && _vm.phaseID === 4)
    )?_c('div',{key:index,staticClass:"info"},[_c('span',{staticClass:"key_item",staticStyle:{"width":"140px"}},[_vm._v(_vm._s(item.columnName)+":")]),_vm._v(" "),(item.columnCode !== 'enrollScore')?_c('span',[(
          (_vm.type === 'detail' && !item.children) ||
            (item.children && item.children.length === 0)
        )?_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(item.valueName || _vm.$t('text.null')))]):(
          _vm.type === 'detail' &&
            item.children &&
            _vm.nullValue(item.children) < item.children.length
        )?_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(item.valueName)+"\n        "),_vm._l((item.children),function(ite){return (ite.valueName !== null)?_c('span',{key:ite.columnCode},[_c('span',{staticClass:"second"},[_vm._v(_vm._s(ite.valueName || ''))])]):_vm._e()})],2):(
          _vm.type === 'detail' &&
            item.children &&
            _vm.nullValue(item.children) === item.children.length
        )?_c('span',{staticClass:"value_item"},[_vm._v("\n        "+_vm._s(item.valueName || _vm.$t('text.null'))+"\n      ")]):_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(_vm.$t('text.null')))])]):(item.columnCode === 'enrollScore' && _vm.phaseID === 4)?_c('span',[(
          (_vm.type === 'detail' && !item.children) ||
            (item.children && item.children.length === 0)
        )?_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(item.valueName || _vm.$t('text.null')))]):(
          _vm.type === 'detail' &&
            item.children &&
            _vm.nullValue(item.children) < item.children.length
        )?_c('span',{staticClass:"value_item"},[_vm._v(_vm._s(item.valueName)+"\n        "),_vm._l((item.children),function(ite){return _c('span',{key:ite.columnCode},[(
              item.value === '3' && ite.columnCode === 'studentscoreRemark'
            )?_c('span',{staticClass:"second"},[_vm._v(_vm._s(ite.valueName || ''))]):_vm._e(),_vm._v(" "),(item.value === '1' && ite.columnCode === 'studentscore')?_c('span',{staticClass:"second"},[_vm._v(_vm._s(ite.valueName || ''))]):_vm._e(),_vm._v(" "),(
              item.value === '2' &&
                (ite.columnCode === 'studentscore' ||
                  ite.columnCode === 'studentscoreArea')
            )?_c('span',{staticClass:"second"},[_vm._v(_vm._s(ite.valueName || ''))]):_vm._e()])})],2):(
          _vm.type === 'detail' &&
            item.children &&
            _vm.nullValue(item.children) === item.children.length
        )?_c('span',{staticClass:"value_item"},[_vm._v("\n        "+_vm._s(_vm.$t('text.null'))+"\n      ")]):_vm._e()]):_vm._e()]):_vm._e()}),0)}
var staticRenderFns = []

export { render, staticRenderFns }