export enum DivideStatus {
  True = 1,
  False = 0,
}

export const DivideStatusList = [DivideStatus.True, DivideStatus.False]

export const messages: Messages = {
  'zh-CN': {
    [`${DivideStatus.True}`]: '已分班',
    [`${DivideStatus.False}`]: '未分班',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${DivideStatus.True}`]: '已分班',
    [`${DivideStatus.False}`]: '未分班',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
