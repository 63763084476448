import Vue from 'vue'
import ActualLossRate from '../../../types/actual-loss-rate'
import { namespace as BindingNamespace } from 'vuex-class'
import omit from 'lodash.omit'
import { handlers } from 'xuexin-vuex/src/util'
import { Ajax } from 'xuexin-vuex/src/ajax'

export const namespace = '@xuexin-vuex/ActualLossRate'
export const types = {
  FETCH_CHART_REQUEST: 'FETCH_CHART_REQUEST',
  FETCH_CHART_SUCCESS: 'FETCH_CHART_SUCCESS',
  FETCH_CHART_FAILURE: 'FETCH_CHART_FAILURE',

  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',

  EXPORT_REQUEST: 'EXPORT_REQUEST',
  EXPORT_SUCCESS: 'EXPORT_SUCCESS',
  EXPORT_FAILURE: 'EXPORT_FAILURE',
}

const state: ActualLossRate.State = {
  chart: { data: {} },
  list: { data: {} },
}

const actions: ActualLossRate.Actions = {
  fetch(context, payload) {
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload: payload,
      api: `post ${__STUDENT_API__}/report/realost/table`,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
      parameter: payload,
    })
  },
  fetchChart(context, payload) {
    return Ajax(context, {
      type: types.FETCH_CHART_REQUEST,
      payload: payload,
      api: `post ${__STUDENT_API__}/report/realost/chart`,
      success: types.FETCH_CHART_SUCCESS,
      failure: types.FETCH_CHART_FAILURE,
      parameter: payload,
    })
  },
  export(context, payload) {
    return Ajax(context, {
      type: types.EXPORT_REQUEST,
      payload: payload,
      api: `post ${__STUDENT_API__}/report/excelExport`,
      success: types.EXPORT_SUCCESS,
      failure: types.EXPORT_FAILURE,
      parameter: Object.assign({}, payload, {
        exportType: payload.exportType || 8,
      }),
    })
  },
}

const mutations: ActualLossRate.Mutations = {
  [types.FETCH_REQUEST](state) {
    handlers.request(state.list, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { result }) {
    const item = state.list
    Vue.set(item, 'data', result)
    handlers.success(item, 'fetching')
  },
  [types.FETCH_FAILURE](state, { message }) {
    handlers.failure(state.list, 'fetching', message)
  },
  [types.FETCH_CHART_REQUEST](state) {
    handlers.request(state.chart, 'fetching')
  },
  [types.FETCH_CHART_SUCCESS](state, { result }) {
    const item = state.chart
    Vue.set(item, 'data', result)
    handlers.success(item, 'fetching')
  },
  [types.FETCH_CHART_FAILURE](state, { message }) {
    handlers.failure(state.chart, 'fetching', message)
  },

  [types.EXPORT_REQUEST](state, { payload }) {
    handlers.request(state.list, 'deleting')
  },
  [types.EXPORT_SUCCESS](state, { payload }) {
    handlers.success(state.list, 'deleting')
  },
  [types.EXPORT_FAILURE](state, { payload, message }) {
    handlers.failure(state.list, 'deleting', message)
  },
}

const getters: ActualLossRate.Getters = {
  list(state) {
    return () => state.list?.data
  },
  listStatus(state) {
    return () => omit(state.list, ['data'])
  },
  chartItem(state) {
    return () => state.chart?.data
  },
  chartStatus(state) {
    return () => omit(state.chart, ['data'])
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
