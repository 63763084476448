import Vue, { CreateElement, VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Spin, Icon, Button, Tabs, TabPane } from 'view-design'
import { Student, Constant } from 'xuexin-vuex'
import StudentTag from '@store/modules/student-tag'
import StudentTagType from 'types/student-tag'
import ViewLayout from '@layouts/view-layout'
import StudentOverview from './student-overview'
import ContentPane from './content-pane'
import FamilyPane from './family-pane'
import { toString, toNumber, isString, isNumber, noop, DateUtil } from '@util'
import i18n from './i18n'
import './student.scss'

interface TabPaneItem {
  name: string
  label: string
  columns: Student.Column[]
}

type Parameter = Record<string, unknown> & { columns: Student.Column[] }

type ActionType = 'rejoin' | 'edit'

@Component({ name: 'StudentPage', i18n })
export default class StudentPage extends Vue {
  @Student.Action('fetch') private readonly fetch!: Student.Action.Fetch
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('itemStatus')
  private readonly getStatus!: Student.Getter.ItemStatus
  @Student.Getter('item') private readonly getItem!: Student.Getter.Item
  @Prop({ type: String, default: 'edit' })
  private readonly actionType!: ActionType

  private readonly prefixCls: string = 'student-page'
  private tabModel = ''
  private tabPanes: TabPaneItem[] = []
  private model: Record<string, unknown> = {}
  private basic: Record<string, unknown> = {}
  private parameter: Parameter = { columns: [] }
  private defaultStuTag: any = {}
  private idLength = 1
  private passportAddressRequired = 0
  private passportDateRequired = 0
  private idInfoRequired = 0

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get termID(): number {
    return toNumber(this.$route.params.termID) as number
  }

  get unitID(): number {
    return toNumber(this.$route.params.unitID) as number
  }

  get xuexinID(): string {
    return this.$route.params.xuexinID
  }

  get authCode(): string {
    return this.$route.meta.authCode
  }

  get invalid() {
    return (
      isNaN(this.termID) || isNaN(this.unitID) || isNaN(Number(this.xuexinID))
    )
  }

  get status() {
    return this.getStatus(this.xuexinID)
  }

  get fetching() {
    return this.status.fetching === true
  }

  get updating() {
    return this.status.updating === true
  }

  get hasError() {
    return this.fetching === false && this.status.fetchingError !== null
  }

  get student() {
    return this.getItem(this.xuexinID)
  }

  //在班学生获取当前学段，分班学生获取报读学段
  get watchPhaseID() {
    if (this.$route.name === 'in-class-student-edit') {
      return this.student?.phaseID
    } else {
      return this.model.phase
    }
  }

  //学段不为高中时，应届生重置为true
  @Watch('watchPhaseID', { immediate: true })
  watchPhase(phaseID: number, previous: unknown) {
    let freshStudent_index = 0
    let academicDirection_index = 0
    let studentCategory_index = 0
    if (this.tabPanes[3] && this.tabPanes[3].columns) {
      this.tabPanes[3].columns.forEach((element, index) => {
        if (element.columnCode === 'freshStudent') {
          freshStudent_index = index
        } else if (element.columnCode === 'academicDirection') {
          academicDirection_index = index
        } else if (element.columnCode === 'studentCategory') {
          studentCategory_index = index
        } else {
          //
        }
      })
    } else {
      //
    }

    if (phaseID === 4 || phaseID === 3) {
      const {
        freshStudent,
        academicDirection,
        studentCategory,
      } = this.defaultStuTag

      if (this.tabPanes[3]) {
        this.$set(this.model, 'freshStudent', freshStudent)
        this.$set(
          this.tabPanes[3].columns[freshStudent_index],
          'value',
          freshStudent
        )

        this.$set(this.model, 'academicDirection', academicDirection)
        this.$set(
          this.tabPanes[3].columns[academicDirection_index],
          'value',
          academicDirection
        )

        this.$set(this.model, 'studentCategory', studentCategory)
        this.$set(
          this.tabPanes[3].columns[studentCategory_index],
          'value',
          studentCategory
        )
      } else {
        //
      }
    } else {
      if (this.tabPanes[3]) {
        this.$set(this.model, 'freshStudent', true)
        this.$set(this.tabPanes[3].columns[freshStudent_index], 'value', true)

        this.$set(this.model, 'academicDirection', '1')
        this.$set(
          this.tabPanes[3].columns[academicDirection_index],
          'value',
          '1'
        )

        this.$set(this.model, 'studentCategory', '3')
        this.$set(this.tabPanes[3].columns[studentCategory_index], 'value', '3')
      } else {
        //
      }
    }
  }

  @Watch('fetching')
  watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true && this.hasError === false) {
      this.compileColumns()
    }
  }

  @Watch('updating')
  watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      if (this.status.updatingError !== null) {
        return this.$Message.error(this.status.updatingError)
      }

      const params = this.parameter.columns
        .filter(item => {
          return item.columnCode === 'school' || item.columnCode === 'term'
        })
        .reduce<Record<string, string>>((params, item) => {
          if (
            (isString(item.value) && item.value !== '') ||
            isNumber(item.value)
          ) {
            params[item.columnCode === 'school' ? 'unitID' : 'termID'] = String(
              item.value
            )
          }
          return params
        }, this.$route.params)

      if (this.actionType === 'rejoin') {
        this.$router.replace({ name: 'divide-class-management' })
        return this.$Message.success(
          '重新入校成功，请在分班管理列表选择相应学期操作该学生'
        )
      }
      /** 更新路由信息 */
      this.$router.replace({ name: this.$route.name!, params }).catch(noop)
      return this.$Message.success(this.$t('success'))
    }
  }

  @Watch('model.idType1')
  watchidType1(idType1: string, previous: string) {
    const idType1Arr = idType1.split('-')
    if (idType1Arr[0] == '1' && idType1Arr[1].length > 13) {
      const reg = /^(\d{4})(\d{2})(\d{2})$/
      const date = idType1Arr[1].substring(6, 14).replace(reg, '$1-$2-$3')
      this.$set(this.model, 'birthday', new Date(date))
      const groupIndex = this.tabPanes.findIndex(i => i.name == 'base')
      this.setColumnValue(groupIndex, 'birthday', new Date(date))
    }
  }
  @Watch('model.idType2')
  watchidType2(idType2: string, previous: string) {
    const idType2Arr = idType2.split('-')
    if (idType2Arr[0] == '1' && idType2Arr[1].length > 13) {
      const reg = /^(\d{4})(\d{2})(\d{2})$/
      const date = idType2Arr[1].substring(6, 14).replace(reg, '$1-$2-$3')
      this.$set(this.model, 'birthday', new Date(date))
      const groupIndex = this.tabPanes.findIndex(i => i.name == 'base')
      this.setColumnValue(groupIndex, 'birthday', new Date(date))
    }
  }

  created() {
    this.fetchStudent()
  }

  render(h: CreateElement) {
    const children: VNodeChildren = []
    switch (true) {
      case this.fetching === true /** 显示正在加载 */:
        children.push(
          h(Spin, { props: { fix: true } }, [`${this.$t('loading')}`])
        )
        break
      case this.hasError === true /** 显示后台返回的错误消息 */:
        children.push(
          h('div', { class: `${this.prefixCls}_error` }, [
            h('span', { class: `${this.prefixCls}_error-message` }, [
              this.status.fetchingError,
            ]),
            h('br'),
            h(
              Button,
              {
                props: { disabled: this.fetching, type: 'primary' },
                on: { click: this.fetchStudent },
              },
              [`${this.$t('retry')}`]
            ),
          ])
        )
        break
      default:
        {
          /** 添加返回按钮 */
          children.push(
            h('div', {}, [
              h(
                'span',
                {
                  class: `${this.prefixCls}_back`,
                  on: {
                    click: this.handleOnBack,
                  },
                },
                [
                  h(Icon, { props: { type: 'ios-arrow-back' } }),
                  `${this.$t('back')}`,
                ]
              ),
            ])
          )

          /** 添加学生基础信息 */
          // children.push(h(StudentOverview, { props: { data: this.basic } }))
          /** 添加 Tabs 内容部分 */
          children.push(
            h('div', { class: `card ${this.prefixCls}_body` }, [
              h(
                Tabs,
                {
                  props: { value: this.tabModel, animated: false },
                  on: {
                    input: this.handleTabsInput,
                  },
                },
                this.tabPanes.map(({ label, name, columns }) => {
                  let paneContent: VueConstructor
                  const props: Record<string, any> = {
                    visible: this.tabModel === name,
                    actionType: this.actionType,
                    data: columns,
                  }

                  switch (name) {
                    case Constant.KindCode.Family /** 家庭成员 */:
                      paneContent = FamilyPane
                      break
                    default:
                      paneContent = ContentPane
                      break
                  }

                  /** 添加额外数据 */
                  props.direction = this.model.direction
                  props.termID = this.model.term
                  props.unitID = this.model.school
                  // props.phaseID = this.model.phase
                  props.phaseID = this.watchPhaseID
                  props.manageTypeID = this.model.businessType
                  props.parentUnitID = this.model.parentUnitID
                  // props.schoolDepartID = this.model.schoolDepartID
                  props.pending = this.updating
                  props.nation = this.model.nation
                  props.studentMark = this.model.studentMark
                  props.freshStudent = this.model.freshStudent
                  props.hasTermBill = this.student?.hasTermBill
                  props.academicDirection = this.model.academicDirection
                  props.idLength = this.idLength
                  props.statusType = this.model.statusType
                  props.transientStudent = this.model.transientStudent

                  return h(TabPane, { key: name, props: { label, name } }, [
                    h(paneContent, {
                      props,
                      on: {
                        'on-submit': this.handleSubmit,
                        'on-field-change': this.handleFieldChange,
                        addID: this.handleAdd,
                        delID: this.handleDel,
                      },
                    }),
                  ])
                })
              ),

              this.updating === true
                ? h(Spin, { props: { fix: true } }, [`${this.$t('updating')}`])
                : '',
            ])
          )
        }
        break
    }

    return h(
      ViewLayout,
      {
        class: this.prefixCls,
        props: { name: this.prefixCls },
      },
      children
    )
  }

  mounted() {}

  private fetchStudent() {
    /** 参数无效时，不发送请求 */
    if (this.invalid === true) {
      return void 0
    }
    this.fetch({
      studentID: this.xuexinID,
      termID: this.termID,
      unitID: this.unitID,
      authCode: this.authCode,
      platform: 5,
    })
  }

  private compileColumns() {
    if (this.student === void 0 || this.student === null) {
      return void 0
    }
    const { columns: _columns = [], ...basic } = this.student

    // 编辑学生信息去掉集团优生，不允许编辑集团优生
    const columns =
      _columns.filter(item => {
        //获取护照颁发地,有效期,证件信息是否必填
        if (item.columnCode === 'passportAddress') {
          this.passportAddressRequired = item.required
          return false
        }
        if (item.columnCode === 'passportDate') {
          this.passportDateRequired = item.required
          return false
        }
        if (item.columnCode === 'idInfo') {
          this.idInfoRequired = item.required
        }
        return item.columnCode !== 'studentMark'
      }) || []

    let newColumns: any = []
    // 重新入校学生，清空申请就读信息和入学信息
    if (this.actionType === 'rejoin') {
      newColumns = columns.map(item => {
        if (item.kindCode === 'enroll' || item.kindCode === 'status') {
          return Object.assign({}, item, { value: null })
        }
        return item
      })
    } else {
      newColumns = columns.concat()
    }

    const model: Record<string, unknown> = {}
    const tabMap: Record<string, TabPaneItem> = {}
    const compose: Record<string, Partial<Student.Column>> = {}

    /** 对 column 进行分类 */
    newColumns.forEach((column: Student.Column) => {
      const _column = Object.assign({}, column, {
        /** 后端 column.columnType 的值类型不统一，强制转换为 string 类型 */
        columnType: toString(column.columnType),
      })

      if (column.columnCode === 'freshStudent') {
        this.$set(
          this.defaultStuTag,
          'freshStudent',
          column.value == '0' ? false : true
        )
      }
      if (column.columnCode === 'academicDirection') {
        this.$set(
          this.defaultStuTag,
          'academicDirection',
          column.value || void 0
        )
      }
      if (column.columnCode === 'studentCategory') {
        this.$set(this.defaultStuTag, 'studentCategory', column.value || void 0)
      }
      //设置血型默认值
      if (column.columnCode === 'bloodType') {
        _column.value = column.value || '4'
      }

      const { columnType, columnCode, parentCode = '' } = _column

      /** 转换数据类型 */
      switch (columnCode) {
        case 'term': /** 学期 */
        case 'school': /** 校区 */
        case 'phase': /** 学段 */
        case 'grade': /** 年级 */
        case 'businessType' /** 报读类型 */:
        case 'classType' /** 校本班型 */:
        case 'onlyChild' /** 独生女子 */:
        case 'singleParent' /** 单亲家庭 */:
        case 'transientStudent' /** 借读生 */:
        case 'ownerChild' /** 业主子女 */:
        case 'staffChild' /** 校职工女子 */:
        case 'studentMark' /** 集团优生 */:
        case 'ethnicity' /** 民族 */:
        case 'direction' /** 报名方向 */:
        case 'freshStudent':
          /** 转为 number 类型 */
          _column.value = toNumber(_column.value)
          break
        /** 日期类数据 */
        case 'birthday' /** 出生日期 */:
          _column.value = new Date(_column.value)
          break
        /** 地址类数据转换 */
        case 'address' /** 联系地址 */:
        case 'homeAddress': /** 现家庭住址 */
        case 'nativePlace': /** 籍贯 */
        case 'birthplace': /** 出生地 */
        case 'registeredPlace' /** 户口所在地 */:
          _column.value =
            _column.value !== void 0 &&
            _column.value !== null &&
            `${_column.value}`.trim() !== ''
              ? `${_column.value}`.split('-')
              : []
          break
        default:
          break
      }

      model[columnCode] = _column.value
      const name = _column.kindCode

      /** 初始化 tab 项 */
      if (tabMap[name] === void 0) {
        tabMap[name] = { name, label: _column.kindName, columns: [] }
      }

      /** 组合节点的父节点（血型也是组合组件） */
      if (
        columnType === Constant.ColumnType.Object ||
        columnCode === 'bloodType'
      ) {
        if (compose[columnCode] === void 0) {
          compose[columnCode] = { children: [] }
        }
        _column.children = compose[columnCode].children
        compose[columnCode] = _column
      }
      // 归属于 parentCode 节点
      else if (Boolean(parentCode) === true) {
        if (compose[parentCode] === void 0) {
          compose[parentCode] = { children: [] }
        }
        compose[parentCode].children?.push(_column)
        return void 0
      }

      tabMap[name].columns.push(_column)
    })

    /** 生成 tabPanes */
    const tabPanes = Object.keys(tabMap)
      .map(key => tabMap[key])
      .filter(item => {
        // “申请就读信息” 仅0505 “重新入学” 和 “分管管理”中的编辑学生可查看。
        if (item.name === Constant.KindCode.Enroll) {
          return (
            this.actionType === 'rejoin' || this.$route.meta.authCode === '0505'
          )
        } else {
          if (this.actionType === 'rejoin') {
            return false
          }
          return true
        }
      })

    /** 添加额外数据 */
    const extraProps = ['parentUnitID', 'schoolDepartID']
    extraProps.forEach(key => {
      if (this.student === void 0) {
        return void 0
      }
      const value = this.student[key]
      model[key] = value ? Number(value) : value
    })

    this.$set(this.$data, 'tabPanes', tabPanes)

    //查找学生基本信息，添加证件信息组
    const baseIndex = tabPanes.findIndex(i => i.name === 'base')
    const baseColumns: any = this.tabPanes[baseIndex].columns
    const IDInfoIndex = baseColumns.findIndex(
      (i: any) => i.columnCode === 'idInfo'
    )
    //获取证件回显信息
    const idInfoData = Object.assign({}, baseColumns[IDInfoIndex])
    //获取有几组证件信息
    this.idLength = idInfoData.value.length || 1
    //重新按远数据结构存储需要回显的证件信息
    let setIdInfos = []
    if (idInfoData.value.length > 0) {
      setIdInfos = idInfoData.value.map((item: any, index: number) => {
        return this.getIdInfo(
          index + 1,
          item.idType,
          item.idNo,
          item.idAddress,
          item.idDate
        )
      })
    } else {
      setIdInfos = [this.getIdInfo(1, void 0, '', '', void 0)]
    }

    //把后台返回的证件信息删除，并在其位置上添加重组后的证件信息
    baseColumns.splice(IDInfoIndex, 1, ...setIdInfos)

    this.$set(this.tabPanes[baseIndex], 'columns', baseColumns)

    this.$set(this.$data, 'tabModel', tabPanes[0].name)
    this.$set(this.$data, 'model', model)
    this.$set(this.$data, 'basic', basic)
  }

  private handleTabsInput(name: string) {
    this.$set(this.$data, 'tabModel', name)
  }

  private handleAdd(name: string, value: any) {
    if (this.idLength == 1) {
      this.idLength = 2

      const index = this.tabPanes.findIndex(i => i.name === 'base')
      const col: any = JSON.parse(JSON.stringify(this.tabPanes[index].columns))
      col.push(this.getIdInfo(2, void 0, '', '', void 0))

      this.$set(this.tabPanes[index], 'columns', col)
    } else {
      //
    }
  }
  private handleDel(name: string, value: any) {
    if (this.idLength == 2) {
      this.idLength = 1

      const index = this.tabPanes.findIndex(i => i.name === 'base')
      const col = this.tabPanes[index].columns
      col.splice(Number(name.split('.')[1]))
      this.$set(this.tabPanes[index], 'columns', col)
    } else {
      //
    }
  }

  private handleFieldChange(columnCode: string, value: unknown, columns?: any) {
    this.$set(this.model, columnCode, value)

    //嵌套信息子信息改变时,获取实时显示数据并覆盖到tabPanes中
    if (columns && columns.length > 0) {
      const kindIndex = this.tabPanes.findIndex(
        i => i.name === columns[0].kindCode
      )
      this.$set(this.tabPanes[kindIndex], 'columns', columns)
    }
  }
  //提交
  private handleSubmit(columns: Student.Column[]) {
    const copyColumns: any = [...columns.concat()]
    let idInfos: any = [],
      idInfos2: any = {},
      kindCode = ''

    if (copyColumns.length > 0) {
      copyColumns.forEach((item: any) => {
        if (item.columnCode === 'freshStudent') {
          item.value = item.value ? 1 : 0
          item.valueName = item.value ? '是' : '否'
        }

        if (
          this.model.academicDirection === '5' &&
          item.columnCode === 'studentCategory'
        ) {
          item.value = void 0
          item.valueName = ''
        }

        //基本信息提取证件信息
        if (item.kindCode === 'base') {
          kindCode = 'base'
          //提取主要证件信息
          if (
            item.columnCode === 'idType1' ||
            (item.parentCode && item.parentCode?.indexOf('idType1') != -1)
          ) {
            idInfos.length === 0
              ? idInfos.push({ mainFlag: 1, familyGroup: 1 })
              : ''

            item.columnCode === 'idType1'
              ? (idInfos[0]['idType'] = item.value)
              : (idInfos[0][item.columnCode] = this.ToCDB(item.value))
            if (item.columnCode === 'idDate') {
              idInfos[0]['idDate'] = DateUtil.format(
                item.value,
                'yyyy-MM-dd'
              ).replace(/-/g, '')
            }
          }
          //提取辅助证件信息
          if (
            item.columnCode === 'idType2' ||
            (item.parentCode && item.parentCode?.indexOf('idType2') != -1)
          ) {
            idInfos.length === 1
              ? idInfos.push({ mainFlag: 0, familyGroup: 1 })
              : ''

            item.columnCode === 'idType2'
              ? (idInfos[1]['idType'] = item.value)
              : (idInfos[1][item.columnCode] = this.ToCDB(item.value))

            if (item.columnCode === 'idDate') {
              idInfos[1]['idDate'] = DateUtil.format(
                item.value,
                'yyyy-MM-dd'
              ).replace(/-/g, '')
            }
          }
        }
        //家庭信息提取证件信息
        else if (item.kindCode === 'family') {
          kindCode = 'family'
          const fg = item.familyGroup || 1
          //提取主要证件信息
          if (
            item.columnCode === 'idType1' ||
            (item.parentCode && item.parentCode?.indexOf('idType1') != -1)
          ) {
            idInfos2[fg] ? '' : (idInfos2[fg] = [{ mainFlag: 1 }])

            item.columnCode === 'idType1'
              ? (idInfos2[fg][0]['idType'] = item.value)
              : (idInfos2[fg][0][item.columnCode] = this.ToCDB(item.value))

            if (item.columnCode === 'idDate') {
              idInfos2[fg][0]['idDate'] = DateUtil.format(
                item.value,
                'yyyy-MM-dd'
              ).replace(/-/g, '')
            }
          }
          //提取辅助证件信息
          if (
            item.columnCode === 'idType2' ||
            (item.parentCode && item.parentCode?.indexOf('idType2') != -1)
          ) {
            idInfos2[fg] ? '' : (idInfos2[fg] = [])
            idInfos2[fg][1] ? '' : (idInfos2[fg][1] = {})
            idInfos2[fg][1]['mainFlag'] = 0

            item.columnCode === 'idType2'
              ? (idInfos2[fg][1]['idType'] = item.value)
              : (idInfos2[fg][1][item.columnCode] = this.ToCDB(item.value))

            if (item.columnCode === 'idDate') {
              idInfos2[fg][1]['idDate'] = DateUtil.format(
                item.value,
                'yyyy-MM-dd'
              ).replace(/-/g, '')
            }
          }
        }
        //入学信息，借读生为否时，借读生转籍状态清空；学籍不为‘无本校学籍’时，借读生和借读生转籍状态清空
        else if (item.kindCode === 'status') {
          // item.columnCode === 'statusType' && (this.model.statusType = item.value)
          // item.columnCode === 'transientStudent' &&
          //   (this.model.transientStudent = item.value)
        } else {
          //
        }
      })
    }
    //把家庭信息放在一个数组总并标记familyGroup
    if (JSON.stringify(idInfos2) !== '{}') {
      for (let i in idInfos2) {
        const arr = idInfos2[i]
        arr.forEach((s: any) => {
          if (s.idType && s.idNo != '') {
            idInfos.push(Object.assign({ familyGroup: i }, s))
          }
        })
      }
    }

    //提交时过滤无用的证件信息
    let columns_submit: any = copyColumns.filter(
      (i: any) =>
        (i.parentCode && i.parentCode.indexOf('idType') == -1) ||
        (!i.parentCode && i.columnCode.indexOf('idType') == -1)
    )

    //基本信息和家庭信息拼接证件信息
    if (
      columns_submit[0].kindCode === 'base' ||
      columns_submit[0].kindCode === 'family'
    ) {
      columns_submit = columns_submit.concat([
        {
          kindCode: kindCode,
          kindName: '基本信息',
          columnCode: 'idInfo',
          forceShow: 1,
          value: idInfos,
          columnName: '证件信息',
        },
      ])
    }

    const parameter: Parameter = {
      xuexinID: this.xuexinID,
      termID: this.termID,
      unitID: this.unitID,
      columns: columns_submit.filter(i =>
        Boolean(this.student?.hasTermBill) ||
        Boolean(this.$route.name === 'divide-class-student-edit')
          ? i.columnCode !== 'studentCategory'
          : true
      ),
    }

    if (this.actionType === 'rejoin') {
      parameter.actionType = Constant.ActionType.Rejoin
    }

    this.parameter = parameter
    /** 更新/重新入校 */
    this.update(parameter)
  }

  /**修改学生的属性值 */
  private setColumnValue(groupIndex: number, columnCode: string, value: any) {
    const group = this.tabPanes[groupIndex]

    let current: any = group.columns.find(i => i.columnCode === columnCode)
    current.value = value

    this.$set(this.tabPanes, groupIndex, group)
  }

  private handleOnBack() {
    this.$router.go(-1)
  }

  //证件信息组
  private getIdInfo(
    typeIndex: number,
    idType: number | undefined,
    idNo: string,
    idAddress: string,
    idDate: number | undefined
  ) {
    const strDate = idDate + ''
    const date = idDate
      ? new Date(
          `${strDate.substr(0, 4)}-${strDate.substr(4, 2)}-${strDate.substr(
            6,
            2
          )}`
        )
      : ''

    return {
      columnType: '6',
      kindCode: 'base',
      parentCode: '',
      kindName: '基本信息',
      columnCode: 'idType' + typeIndex,
      columnName: typeIndex == 1 ? '主要证件' : '辅助证件',
      forceShow: 1,
      value: idType,
      valueName: '',
      required: this.idInfoRequired,
      typeIndex: typeIndex,
      nation: 'CN',
      children: [
        {
          columnType: '0',
          kindCode: 'base',
          parentCode: 'idType' + typeIndex,
          kindName: '基本信息',
          columnCode: 'idNo',
          columnName: '证件号码',
          forceShow: 1,
          value: idNo,
          valueName: '',
          required: this.idInfoRequired,
        },
        {
          columnType: '4',
          kindCode: 'base',
          parentCode: 'idType' + typeIndex,
          kindName: '基本信息',
          columnCode: 'idDate',
          columnName: '有效期',
          forceShow: [2, 3, 6].includes(idType || 2) ? 1 : 0,
          value: date,
          valueName: '',
          required: this.passportDateRequired,
        },
        {
          columnType: '0',
          kindCode: 'base',
          parentCode: 'idType' + typeIndex,
          kindName: '基本信息',
          columnCode: 'idAddress',
          columnName: '颁发地',
          forceShow: [2, 3, 6].includes(idType || 2) ? 1 : 0,
          value: idAddress,
          valueName: '',
          required: this.passportAddressRequired,
        },
      ],
    }
  }
  //全角转半角,并去除前后空格
  private ToCDB(str: string) {
    if (str == void 0 || str == null) return str
    var tmp = ''
    for (var i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) > 65248 && str.charCodeAt(i) < 65375) {
        tmp += String.fromCharCode(str.charCodeAt(i) - 65248)
      } else {
        tmp += String.fromCharCode(str.charCodeAt(i))
      }
    }
    return tmp
  }
}
