




















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { TableColumn, Breadcrumb } from 'view-design'
import AppTable from '@components/app-table'
import HomeEcharts from '../home-echarts'
const components = { AppTable }

const i18n = {
  messages: {
    'zh-CN': {
      text: {
        grade: '年级',
        eugenics: '优生/目标数',
        schoolDepart: '学部',
        studentCount: '学生人数',
        manageType: '报读类型',
      },
    },
    'en-US': {
      text: {
        grade: '年级',
        eugenics: '优生/目标数',
        schoolDepart: '学部',
        studentCount: '学生人数',
        manageType: '报读类型',
      },
    },
  },
}

@Component({ name: 'HomeInfo', components, i18n })
export default class HomeInfo extends Vue {
  /** 类型 */
  @Prop(String) private type!:
    | 'grade'
    | 'school'
    | 'schoolDepart'
    | 'manageType'
    | 'lostStatis'

  @Prop({ type: Array }) private readonly tableData!: []

  private readonly prefixCls = 'home-info'

  get gradeColumn(): TableColumn[] {
    return [
      {
        title: this.$t('text.grade') as string,
        key: 'gradeName',
        width: 86,
        className: 'info-table',
      },
      {
        title: this.$t('text.eugenics') as string,
        width: 113,
        key: 'count',
        slot: 'count',
        className: 'info-table',
      },
    ]
  }
  get tableColumn() {
    let columns: TableColumn[] = []
    switch (this.type) {
      case 'schoolDepart':
        columns = [
          {
            title: this.$t('text.schoolDepart') as string,
            key: 'schoolDepartName',
            className: 'info-table',
          },
          {
            title: this.$t('text.studentCount') as string,
            key: 'studentCount',
            className: 'info-table',
          },
        ]
        break
      case 'manageType':
        columns = [
          {
            title: this.$t('text.manageType') as string,
            key: 'manageTypeName',
            className: 'info-table',
          },
          {
            title: this.$t('text.studentCount') as string,
            key: 'studentCount',
            className: 'info-table',
          },
        ]
        break
    }
    return columns
  }
}
