import ClassReport from '../../../types/class-report'
import { namespace as BindingNamespace } from 'vuex-class'
import { Ajax } from 'xuexin-vuex/src/ajax'
import omit from 'lodash.omit'
import { handlers } from 'xuexin-vuex/src/util'

export const namespace = '@xuexin-vuex/class-report'

export const types = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',

  EXPORT_REQUEST: 'EXPORT_REQUEST',
  EXPORT_SUCCESS: 'EXPORT_SUCCESS',
  EXPORT_FAILURE: 'EXPORT_FAILURE',
}

const state: ClassReport.State = {
  classCount: {
    list: { data: [] },
    items: {},
  },
  classCountMonthly: {
    list: { data: [] },
    items: {},
  },
  classCompareAnalysis: {
    list: { data: [] },
    items: {},
  },
  titles: {
    list: { data: [] },
    items: {},
  },
}

const actions: ClassReport.Actions = {
  //查询流失率列表
  fetch(context, payload) {
    const { type, ...parameter } = payload
    let api = ''

    switch (type) {
      case 'classCount':
        api = `${__STUDENT_API__}/report/classNum/1`
        break
      case 'classCountMonthly':
        api = `${__STUDENT_API__}/report/classNum/2`
        break
      default:
        api = `${__STUDENT_API__}/report/classNum/3`
        break
    }

    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload,
      api: `post ${api}`,
      parameter: parameter,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
  //删除学生信息配置
  export(context, payload) {
    const { type, ...parameter } = payload
    let api = ''

    switch (type) {
      case 'classCount':
        api = `${__STUDENT_API__}/report/classNum/export/1`
        break
      case 'classCountMonthly':
        api = `${__STUDENT_API__}/report/classNum/export/2`
        break
      default:
        api = `${__STUDENT_API__}/report/classNum/export/3`
        break
    }
    return Ajax(context, {
      type: types.EXPORT_REQUEST,
      payload,
      api: `post ${api}`,
      parameter: parameter,
      success: types.EXPORT_SUCCESS,
      failure: types.EXPORT_FAILURE,
    })
  },
}

const mutations: ClassReport.Mutations = {
  //查询学生信息配置列表
  [types.FETCH_REQUEST](state, { payload }) {
    handlers.request(state[payload.type!].list, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { payload, result, total }) {
    handlers.coverToState(state[payload.type!], 'id', result?.dataList, total)
    handlers.coverToState(
      state.titles,
      payload.type === 'classCountMonthly' ? 'month' : 'termID',
      result?.titleList,
      total
    )
    handlers.success(state[payload.type!].list, 'fetching')
  },
  [types.FETCH_FAILURE](state, { payload, message }) {
    handlers.failure(state[payload.type!].list, 'fetching', message)
  },

  //导出
  [types.EXPORT_REQUEST](state, { payload }) {
    handlers.request(state[payload.type!].list, 'deleting')
  },
  [types.EXPORT_SUCCESS](state, { payload }) {
    handlers.success(state[payload.type!].list, 'deleting')
  },
  [types.EXPORT_FAILURE](state, { payload, message }) {
    handlers.failure(state[payload.type!].list, 'deleting', message)
  },
}

const getters: ClassReport.Getters = {
  state(state) {
    return name => state[name] || { list: { data: [] }, items: {} }
  },
  item(state, getters) {
    return (name, id) => {
      return getters.state(name).items[`${id}`]?.data
    }
  },
  list(state, getters) {
    return name => {
      return state[name].list.data.map(id => {
        const item = getters.item(name, id)
        return Object.assign({}, item)
      })
    }
  },
  listStatus(state, getters) {
    return name => omit(getters.state(name).list, ['data'])
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
