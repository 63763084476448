



































import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Model, Watch, Ref, Prop } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import { Spin, Form, FormItem } from 'view-design'
import AppModal from '@components/app-modal'
import GraduateDirectionSelect from '../select'
import i18n from './i18n'

interface Model {
  xuexinID: string
  dataID: number
  phaseID: number
  classID: number
}

interface Data extends Model {
  termID: number
}

const components = { AppModal, Spin, Form, FormItem, GraduateDirectionSelect }
@Component({ name: 'GraduateDirectionModal', components, i18n })
export default class GraduateDirectionModal extends Vue {
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Model('input', { type: Boolean }) private value!: boolean
  @Prop({ required: true }) private readonly data!: Data
  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'graduate-direction-modal'
  private hidden = true
  private visible = false
  private loading = true
  private verifying = false
  private model: Partial<Model> = {}

  get rules(): FormRules {
    // prettier-ignore
    return {
      dataID: [{ required: true, trigger: 'change', validator: this.handleValidator }],
    }
  }

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get updating() {
    return this.listStatus.updating === true
  }

  @Watch('value')
  watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('updating')
  watchUpdating(updating: boolean, previous: boolean) {
    if (this.visible === true && updating === false && previous === true) {
      this.stopInteraction()
      if (this.listStatus.updatingError !== null) {
        return this.$Message.error(this.listStatus.updatingError)
      }

      this.$emit('on-finish')
    }
  }

  private stopInteraction() {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)

    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleOk() {
    this.$set(this.$data, 'verifying', true)

    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        /** 校验失败 */
        return this.stopInteraction()
      }

      /** 向后台发送请求 */
      this.update({
        actionType: Constant.ActionType.Graduation,
        termID: this.data.termID,
        graduations: [this.model],
      })
    })
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      /** 初始化 model */
      this.$set(this.$data, 'model', {
        dataID: this.data.dataID,
        xuexinID: this.data.xuexinID,
        phaseID: this.data.phaseID,
        classID: this.data.classID,
      })
      this.$set(this.$data, 'hidden', false)
    }

    this.$emit('on-visible-change', visible)
  }

  private handleHidden() {
    this.$emit('on-hidden')
    this.stopInteraction()
    this.$set(this.$data, 'hidden', true)
  }

  private handleValidator(
    rule: FormRule,
    value: any,
    callback: FormRuleCallback
  ) {
    if (Boolean(value) === false) {
      return callback(`${this.$t('validator')}`)
    }
    return callback()
  }
}
