
















































































































































import Vue from 'vue'
import { Component, Model, Watch, Prop, Ref } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import { Form, FormItem, Spin, Button } from 'view-design'
import i18n from './i18n'
import { SchoolSelect } from '@business-components/school'
import { AbroadSelect } from '@business-components/abroad'
import { SchoolDepartSelect } from '@business-components/school-depart'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { SchoolSystemSelect } from '@business-components/school-system'
import { SemesterSelect } from '@business-components/semester'
import TeacherSelect from '@business-components/teacher'
import { DateUtil } from '@util'

import { Grade, School, Abroad, Session, Constant } from 'xuexin-vuex'
const components = {
  AppModal,
  Form,
  FormItem,
  SchoolDepartSelect,
  SchoolPhaseSelect,
  SchoolSystemSelect,
  SemesterSelect,
  SchoolSelect,
  AbroadSelect,
  TeacherSelect,
  Spin,
  Button,
}
/** 试图类型 */
type ViewType = 'create' | 'batch-create' | 'edit' | 'view'
type dataType = {
  viewType: ViewType
  unitGradeID: number
  parameter: Grade.Entity
  termID: number
}

@Component({ name: 'GradeModal', components, i18n })
export default class GradeModal extends Vue {
  /** 学校模块 */
  @School.Action('fetch')
  private readonly fetchSchoolStatus!: School.Action.Fetch
  @School.Getter('item')
  private readonly getSchool!: School.Getter.Item
  /** session模块 */
  @Session.Getter('user') private readonly getUser!: Session.Getter.User

  @Grade.Action('fetchItem') private readonly fetch!: Grade.Action.FetchItem
  @Grade.Action('update') private readonly update!: Grade.Action.Update
  @Grade.Action('create') private readonly create!: Grade.Action.Create
  @Grade.Getter('itemStatus')
  private readonly getItemStatus!: Grade.Getter.ItemStatus
  @Grade.Getter('listStatus')
  private readonly getListStatus!: Grade.Getter.ListStatus
  @Grade.Getter('item') private readonly getItem!: Grade.Getter.Item

  @Model('input', { type: Boolean }) private value!: boolean
  @Prop({
    default: () => {
      return {
        viewType: 'view',
        unitGradeID: -1,
        parameter: {},
        termID: void 0,
      }
    },
  })
  private readonly data!: dataType

  @Ref('form') private readonly form!: Form
  private prefixCls = 'grade-modal'
  private visible = false
  private showAbroad = true
  private verifying = false
  private loading = true
  private showSchoolSelect = true
  private phaseName = ''
  private abroadName = ''
  private unitGradeName = ''
  private model: Partial<Grade.Parameter> = {}

  get rules(): any {
    return {
      unitID: [
        {
          validator: this.validateNull,
          message: this.$t('message.unit') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      schoolDepartID: [
        {
          validator: this.validateNull,
          message: this.$t('message.schoolDepart') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      phaseID: [
        {
          validator: this.validateNull,
          message: this.$t('message.phase') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      abroadID: [
        {
          validator: this.validateNull,
          message: this.$t('message.abroad') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      startTermID: [
        {
          validator: this.validateNull,
          message: this.$t('message.startTerm') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      schoolSystemID: [
        {
          validator: this.validateNull,
          message: this.$t('message.schoolSystem') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
    }
  }

  get itemStatus() {
    return this.getItemStatus(this.data.unitGradeID)
  }

  get listStatus() {
    return this.getListStatus(this.model)
  }

  get parameter() {
    return (
      Object.assign({}, this.data.parameter, { termID: this.data.termID }) ||
      {}
    )
  }

  get viewType() {
    return this.data.viewType || 'view'
  }
  /** 获取机构id */
  get organID() {
    return this.getUser?.organID
  }
  get abroadStatus() {
    return this.getSchool(this.parentUnitID!)?.abroadStatus
  }
  /** 是否显示出国方向下拉框 */
  get showAbroadSelect() {
    return (
      this.showAbroad &&
      this.abroadStatus === 1 &&
      this.model.schoolDepartID === 4
    )
  }

  get parentUnitID() {
    return this.identityType === 'mechanism'
      ? this.model.parentUnitID
      : this.getUser?.parentUnitID
  }
  /** 若school为学校用户   mechanism是集团用户 ordinary普通用户*/
  get identityType() {
    if (this.getUser) {
      return this.getUser.identityType === Constant.IdentityType.School
        ? 'school'
        : this.getUser.identityType === Constant.IdentityType.Organization
        ? 'mechanism'
        : 'ordinary'
    }
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean): void {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('itemStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 窗口不显示时，不处理数据。 */
    if (this.value === false || this.visible === false) {
      return void 0
    }
    if (fetching === false && previous === true) {
      if (this.itemStatus.fetchingError !== null) {
        return this.$Message.error(this.itemStatus.fetchingError)
      }
      /** 更新年级数据 */
      const item: any = this.getItem(this.data.unitGradeID)
      if (item === void 0) {
        return void 0
      }
      this.$set(
        this.$data,
        'model',
        Object.assign({}, item, {
          /** 添加时间 */
          createDate: item.createDate
            ? DateUtil.format(item.createDate, 'yyyy-MM-dd hh:mm')
            : '-',
          /** 添加人 */
          creatorInfo: item
            ? ['creatorName', 'departmentName', 'creatorMobile']
                .map(key => item[key])
                .filter(item => item !== void 0 && item !== null)
                .join(' ') || '-'
            : '-',
          /** 年级主任 */
          gradeDirectorInfo: item
            ? [
                'gradeDirectorName',
                'gradeDepartmentName',
                'gradeDirectorMobile',
              ]
                .map(key => item[key])
                .filter(item => item !== void 0 && item !== null)
                .join(' ') || '-'
            : '-',
        })
      )
      this.$set(this.$data, 'unitGradeName', item.unitGradeName)
      this.$set(this.$data, 'phaseName', item.phaseName)
    }
  }

  @Watch('listStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    /** 窗口不显示时，不处理数据。 */
    if (this.value === false || this.visible === false) {
      return void 0
    }
    // 创建请求完成
    if (creating === false && previous === true) {
      if (this.listStatus.creatingError !== null) {
        this.$Message.error(this.listStatus.creatingError)
        return this.stopLoading()
      }
      /** 添加成功，关闭窗口，通知父级组件。 */
      this.$set(this.$data, 'visible', false)
      this.$emit('on-finish', 'grade-modal', 'create')
      return this.stopLoading()
    }
  }

  @Watch('itemStatus.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    /** 窗口不显示时，不处理数据。 */
    if (this.value === false || this.visible === false) {
      return void 0
    }

    if (updating === false && previous === true) {
      if (this.itemStatus.updatingError !== null) {
        this.$Message.error(this.itemStatus.updatingError)
        return this.stopLoading()
      }
      /** 更新成功，关闭窗口，通知父级组件。 */
      this.$set(this.$data, 'visible', false)
      this.$emit('on-finish', 'grade-modal', 'edit')
      return this.stopLoading()
    }
  }

  private handleAbroadShow(list: Abroad.Entity[]) {
    this.showAbroad = list.length > 1
  }
  private validateNull(
    rule: object,
    value: number | string,
    callback: any
  ): void {
    if (value === -1 || value === void 0 || value === '') {
      return callback(this.$t('message.noChoice') as string)
    }
    return callback()
  }
  private handleShowSchoolSelect(list: School.Getter.List) {
    this.showSchoolSelect = list.length > 1
  }

  private handleSchoolPhase(info: any) {
    this.phaseName = info?.label || this.model.phaseName
    this.getUnitGradeName()
  }
  private handleSemester(value: number) {
    this.getUnitGradeName()
  }
  private handleAbroadName(info: any) {
    this.abroadName = info?.label || ''
    this.getUnitGradeName()
  }

  private getUnitGradeName() {
    if (this.model.schoolDepartID !== 4) {
      if (this.phaseName && this.model.startTermID) {
        return (this.unitGradeName = (this.phaseName +
          this.model.startTermID.toString().substring(2, 4) +
          this.$t('level')) as string)
      }
      return ''
    }
    if (this.phaseName && this.model.startTermID) {
      return (this.unitGradeName = ('国际' +
        (this.phaseName +
          (this.abroadName ? this.abroadName : '') +
          this.model.startTermID.toString().substring(2, 4) +
          this.$t('level'))) as string)
    }
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private stopLoading(): void {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)
    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }

  private handleOK() {
    if (this.viewType === 'view') {
      this.$set(this.$data, 'visible', false)
      return this.$emit('on-ok')
    }
    this.$set(this.$data, 'visible', true)
    this.form.validate(valid => {
      if (valid === false) {
        return this.stopLoading()
      }
      this.$set(this.$data.model, 'unitGradeName', this.unitGradeName)
      const { creator, createDate, ...needParameter } = this.model
      if (this.viewType === 'edit') {
        const paramter = Object.assign({}, needParameter, {
          unitGradeID: this.data.unitGradeID,
        })
        return this.update(paramter)
      }
      if (this.viewType === 'create') {
        return this.create(needParameter)
      }
    })
  }

  private handleVisibleChange(visible: boolean): void {
    if (visible === true) {
      if (this.viewType !== 'create') {
        this.fetch({
          unitGradeID: this.data.unitGradeID,
          termID: this.data.termID || this.data.parameter.termID,
        })
      }
      this.fetchSchoolStatus({
        organID: this.organID,
        termID: this.data.termID || this.data.parameter.termID,
        unitID: this.parentUnitID,
      })
    }
    this.$emit('on-visible-change', visible)
  }

  private handleHidden(): void {
    this.$set(this.$data, 'model', {})
    this.$set(this.$data, 'phaseName', '')
    this.$set(this.$data, 'unitGradeName', '')
    this.stopLoading()
    this.$emit('on-hidden')
  }
}
