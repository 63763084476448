




























































































import Vue from 'vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Grade, Session, Constant } from 'xuexin-vuex'
import { Button, TableColumn } from 'view-design'
import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import GradeModal from '@business-components/grade-modal'
import { convertSearchParameter } from '@util'

import Export from '@store/modules/export'
import ExportTyping from '../../../types/export'
import PageTyping from '../../../types/page'

const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  AppTable,
  AppPagination,
  GradeModal,
}

const i18n = {
  messages: {
    'zh-CN': {
      button: {
        export: '导出',
        delete: '删除',
        edit: '编辑',
        create: '添加年级',
      },
      text: {
        null: '-',
        undefined: '-',
        index: '序号',
      },
      message: {
        delete: '您确定要删除该年级吗？',
        parameter: '请选择查询条件',
        noSchoolDepart: '请选择学部',
        noDataText: '暂无数据',
        noParameter: '请先选择搜索条件',
        deleteSuccess: '删除成功',
        editSuccess: '编辑成功',
        createSuccess: '添加成功',
        nonSearch: '请先点击搜索',
      },
    },
    'en-US': {},
  },
}

@Component({ name: 'GradePage', components, i18n })
export default class GradePage extends Mixins(PagePropsMixins) {
  @Session.Getter('user') private readonly user!: Session.Getter.User

  @Grade.Action('fetchList')
  private readonly fetchList!: Grade.Action.FetchList
  @Grade.Action('delete') private readonly del!: Grade.Action.Delete
  @Grade.Getter('list') private readonly getList!: Grade.Getter.List
  @Grade.Getter('itemStatus')
  private readonly getItemStatus!: Grade.Getter.ItemStatus
  @Grade.Getter('listStatus')
  private readonly getListStatus!: Grade.Getter.ListStatus

  @Export.Action('exportFile')
  private readonly fetchExportUrl!: ExportTyping.Action.ExportFile
  @Export.Getter('item')
  private readonly getExportUrl!: ExportTyping.Getter.Item
  @Export.Getter('listStatus')
  private readonly getExportStatus!: ExportTyping.Getter.ListStatus

  private readonly prefixCls = 'grade-page'
  private loading = false
  private operable = true /** 是否大于等于当前学前，可否操作 */
  private page = 1
  private pageSize = 10
  private unitGradeID = -1
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private searchParameter: Partial<PageTyping.SearchParameter> = {}
  private modal: { name: string | null; data: any } = { name: null, data: {} }

  get listStatus() {
    return this.getListStatus(this.searchParameter)
  }
  get currentTermID() {
    return this.user?.extraMap.currentTermID || 0
  }

  get total() {
    return this.listStatus.total || 0
  }
  get disableButton() {
    return this.total === 0
  }
  get fetching() {
    return this.listStatus.fetching === true
  }

  get itemStatus() {
    if (this.unitGradeID === -1) {
      return {}
    }
    return this.getItemStatus(this.unitGradeID)
  }

  get exportUrl() {
    return this.getExportUrl(`${this.searchParameter}`)
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get tableColumn() {
    if (this.valid !== true || this.tableData.length === 0) {
      return []
    }
    const columns: TableColumn[] = this.columns.map(item => {
      const column: TableColumn = Object.assign({}, item)
      switch (column.key) {
        case 'num': // 序号
          column.render = (createElement, parameter) => {
            const text =
              (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
            return createElement!('span', `${text}`)
          }
          break
        case 'gradeName':
          column.width = 240
          break
        case 'operate':
          column.width = 120
          column.slot = 'operate'
          column.fixed = 'right'
          column.ellipsis = false
          break
        default:
          break
      }
      return column
    })
    columns.unshift({
      title: `${this.$t('text.index')}`,
      render: (createElement, parameter) => {
        const text =
          (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
        return createElement!('span', `${text}`)
      },
      width: 80,
      fixed: 'left',
      align: 'center',
    })
    return columns
  }

  get tableData() {
    if (this.valid !== true) {
      return []
    }
    return this.getList(this.searchParameter)
  }

  get valid() {
    // const { schoolDepartID } = this.searchParameter
    // return (
    //   Boolean(schoolDepartID) ||
    //   schoolDepartID === 0 ||
    //   Boolean(this.parameter.schoolDepartID) ||
    //   this.parameter.schoolDepartID === 0
    // )
    return true
  }

  get noDataText() {
    // if (
    //   Boolean(this.parameter.schoolDepartID) === false ||
    //   Boolean(this.searchParameter.schoolDepartID) === false
    // ) {
    //   return this.$t('message.nonSearch')
    // }
    return this.$t('message.noDataText')
  }

  @Watch('parameter.termID')
  private watchValue(value: number) {
    this.operable = value >= this.currentTermID
  }
  @Watch('parameter.unitID')
  private watchUnitID(value: number, oldValue: unknown) {
    if (oldValue === null || oldValue === undefined) {
      this.searchParameter = { ...this.parameter }
      this.fetch()
    }
  }
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.loading === true) {
        this.$set(this.$data, 'loading', false)
      }
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }

  @Watch('itemStatus.deleting')
  private watchDeleting(deleting: boolean, previous: boolean) {
    if (deleting === false && previous === true) {
      if (this.itemStatus.deletingError !== null) {
        this.$Modal.remove()
        return this.$Message.error(this.itemStatus.deletingError)
      }
      /** 关闭窗口 */
      this.$Message.success(this.$t('message.deleteSuccess'))
      this.$Modal.remove()
      this.fetch()
    }
  }

  @Watch('getExportStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (!creating && previous) {
      if (this.getExportStatus.creatingError !== null) {
        this.$Message.error(this.getExportStatus.creatingError)
        return
      }
      location.href = this.exportUrl.url
    }
  }

  /** 工具栏按钮事件 */
  private handleClick(viewType: string, grade: Grade.Entity): void {
    // if (Boolean(this.parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(`${this.$t('message.noSchoolDepart')}`)
    // }
    switch (viewType) {
      case 'create':
        this.$set(this.$data, 'modal', {
          name: 'grade-modal',
          data: {
            viewType,
            parameter: this.parameter,
          },
        })
        break
      case 'edit':
      case 'view':
        this.$set(this.$data, 'modal', {
          name: 'grade-modal',
          data: {
            viewType,
            parameter: this.searchParameter,
            unitGradeID: grade.unitGradeID,
          },
        })
        this.$set(this.$data, 'unitGradeID', grade.unitGradeID)
        break
      case 'delete':
        this.$Modal.confirm({
          title: this.$t('button.delete') as string,
          content: this.$t('message.delete') as string,
          loading: true,
          onOk: () => {
            this.del({ unitGradeID: grade.unitGradeID })
          },
        })
        this.$set(this.$data, 'unitGradeID', grade.unitGradeID)
        break
      case 'export':
        this.fetchExportUrl({
          searches: this.convertParameter(),
          actionType: Constant.ActionType.Grade,
        })
        break
    }
  }

  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    // if (Boolean(parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(`${this.$t('message.noParameter')}`)
    // }
    this.$set(this.$data, 'page', 1)
    this.$set(this.$data, 'searchParameter', parameter)
    this.fetch()
  }

  /** 页码发生变化 */
  private handlePageChange(page: number) {
    this.$set(this.$data, 'page', page)
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number) {
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }

  /** 窗口功能完成事件处理函数 */
  private handleModalFinish(modalType: string, type: string) {
    this.$set(this.$data, 'modal', { name: null, data: {} })
    this.$Message.success(this.$t(`message.${type}Success`))
    this.fetch()
    this.$set(this.$data, 'searchParameter', this.parameter)
  }
  /** 窗口关闭事件处理函数 */
  private handleModalHidden() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }

  private handleSearchChange(parameter: any) {
    if (
      parameter.itemStatus === void 0 ||
      parameter.itemStatus === null ||
      parameter.itemStatus === -1
    ) {
      return void 0
    }
  }

  private convertParameter() {
    return convertSearchParameter(this.parameter)
  }

  private fetch() {
    if (
      this.valid !== true /** 参数无效 */ ||
      this.fetching === true /** 正在请求数据 */
    ) {
      /** 以上情况不发起请求 */
      return void 0
    }
    this.fetchList({
      searches: this.convertParameter(),
      columns: this.columns.map(item => item.key),
      page: this.page,
      pageSize: this.pageSize,
    })
  }
  created() {}
}
