export const messages: Messages = {
  'zh-CN': {
    text: {
      export: '导出',
      search: '搜索',
    },
    label: {
      schoolYear: '学年',
      termID: '学期',
      parentUnitID: '学校',
      unitID: '校区',
      schoolDepartID: '学部',
      phaseID: '学段',
      gradeID: '年级',
      enrollInGrade: '报读年级',
      unitGradeID: '学校年级',
      classID: '班级',
      manageTypeID: '报读类型',
      classTypeID: '班级类型',
      unitClassTypeID: '校本班型',
      studentMark: '集团优生',
      researchField: '学业方向',
      studentStatus: '学生状态',
      enrollType: '入学分类',
      enrollScore: '中考成绩',
      graduateDirection: '毕业去向',
      graduateDirectStatus: '是否直升',
      source: '学生来源',
      ownerChild: '业主子女',
      staffChild: '教职工子女',
      statusType: '学籍类型',
      dimension: '统计维度',
      dateType: '日期类型',
      endDate: '截止日期',
      startEndDate: '起止日期',
      endMonth: '截止月份',
      date: '日期区间',
      simpleDate: '日期',
      doubleDate: '时间段',
      termDate: '本学期起始日期',
      lastTermDate: '上学期起始日期',
      transType: '是否包含转学',
    },
  },
  'en-US': {},
}

export default { messages }
