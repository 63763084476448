import Vue, { CreateElement, VNode } from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import PageTyping from '../../../types/page'
import Page from '@store/modules/page'
import { Button, Checkbox, Dropdown, DropdownMenu } from 'view-design'
import CheckboxGroup from '@components/app-checkbox-group'
import i18n from './i18n'
import './dynamic-toolbar.scss'

type CheckboxProp = {
  label: string
  value: string
  style: Record<string, any>
  disabled: boolean
}

const components = {}
@Component({ name: 'DynamicToolbar', components, i18n })
export default class DynamicToolbar extends Vue {
  @Page.Action('update')
  private readonly updateColumns!: PageTyping.Action.Update
  @Page.Getter('columns')
  private readonly getColumns!: PageTyping.Getter.Columns
  @Page.Getter('auths') private readonly getAuths!: PageTyping.Getter.Auths

  private readonly prefixCls = 'dynamic-toolbar'
  private selection: string[] = []
  private checkboxs: CheckboxProp[] = []
  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get authCode() {
    return this.$route.meta.authCode
  }

  get columns(): PageTyping.Column[] {
    return this.getColumns(this.authCode)
  }

  get auths() {
    return this.getAuths(this.authCode)
      .filter(item => item.type === 1)
      .concat({ code: 'batchSubmitApproval', type: 1 })
  }

  get checkAll() {
    return (
      this.selection.length !== 0 &&
      this.selection.length === this.checkboxs.length
    )
  }

  get indeterminate() {
    return this.checkAll === true
      ? false
      : this.selection.length !== 0 &&
          this.selection.length !== this.checkboxs.length
  }

  @Watch('columns', { immediate: true })
  watchColumns(columns: PageTyping.Column[]) {
    /** 当前选中的列 */
    const selection: string[] = []
    /** 列数 */
    const columnCount = 5
    /** 行数 */
    const rowCount = Math.ceil(columns.length / columnCount) - 1
    /** 满排满列总数量 */
    const threshold = rowCount * columnCount

    /** 遍历列头，拼装数据 */
    const checkboxs = columns.map<CheckboxProp>((item, index) => {
      /** 添加已选列 */
      if (item.show !== 0) {
        selection.push(item.code)
      }
      /** 是否为行末 */
      const isEol = (index + 1) % columnCount === 0
      /** 是否溢出 */
      const isOverflow = index >= threshold

      /** 返回 checkbox-group 数组元素 */
      return {
        label: item.title,
        value: item.code,
        style: {
          width: '148px',
          marginRight: `0px`,
          marginBottom: `${isOverflow ? '0' : '10'}px`,
          // marginRight: `${isEol ? '0' : '10'}px`,
          // marginBottom: `${isOverflow ? '0' : '10'}px`,
        },
        disabled: item.fixed === 1,
      }
    })

    this.$set(this.$data, 'selection', selection)
    this.$set(this.$data, 'checkboxs', checkboxs)
  }

  render(createElement: CreateElement) {
    const children: VNode[] = []

    /** 权限按钮 */
    this.auths.forEach(item => {
      const scopedSlot = this.$scopedSlots[item.code]

      /** scopedSlot 不存在时，不处理 */
      if (scopedSlot === void 0) {
        return void 0
      }

      children.push(
        createElement(
          'div',
          { class: `${this.prefixCls}_item` },
          scopedSlot({})
        )
      )
    })

    return createElement('div', { class: this.classes }, [
      /** 权限按钮容器 */
      createElement('div', { class: `${this.prefixCls}-inner` }, children),
      /** 显示列按钮 */
      createElement(
        Dropdown,
        {
          class: `${this.prefixCls}_show-columns`,
          props: {
            trigger: 'click',
            placement: 'bottom-end',
            'transfer-class-name': `${this.prefixCls}_dropdown`,
          },
          on: {
            'on-visible-change': this.handleDropdownVisibleChange,
            'on-clickoutside': this.handleDropdownClickOutside,
          },
        },
        [
          /** 激活下拉菜单按钮 */
          createElement(Button, { props: { type: 'info', size: 'small' } }, [
            createElement('span', {}, [`${this.$t('text.columns')}`]),
          ]),
          /** 自定义下拉菜单内容 */
          createElement(DropdownMenu, { slot: 'list' }, [
            createElement('div', { class: 'check-all' }, [
              createElement(
                Checkbox,
                {
                  props: {
                    indeterminate: this.indeterminate,
                  },
                  attrs: { value: this.checkAll },
                  on: {
                    'on-change': this.handleCheckAllChange,
                  },
                },
                ['全选']
              ),
            ]),
            createElement(CheckboxGroup, {
              props: { data: this.checkboxs },
              attrs: { value: this.selection },
              on: {
                'on-change': this.handleCheckboxGroupChange,
              },
            }),
          ]),
        ]
      ),
    ])
  }
  mounted() {}

  private handleDropdownVisibleChange() {}

  private handleDropdownClickOutside() {}

  private handleCheckboxGroupChange(selection: string[]) {
    this.$set(this.$data, 'selection', selection)
    this.$nextTick(this.updateColumn)
  }

  private handleCheckAllChange(checked: boolean) {
    const filtered = checked
      ? this.checkboxs
      : this.checkboxs.filter(item => item.disabled === true)

    const selection = filtered.map(item => item.value)
    this.$set(this.$data, 'selection', selection)
    this.$nextTick(this.updateColumn)
  }

  private updateColumn() {
    const codeMap = this.selection.reduce<Record<string, boolean>>(
      (map, code) => ((map[code] = true), map),
      {}
    )
    const columns = this.columns.map(item => {
      return Object.assign({}, item, {
        show: codeMap[item.code] === true ? 1 : 0,
      })
    })

    this.updateColumns({ authCode: this.authCode, columns })
  }
}
