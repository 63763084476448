import Page from '../../../types/page'
import { namespace as BindingNamespace } from 'vuex-class'
import { Ajax } from 'xuexin-vuex/src/ajax'
import { handlers } from 'xuexin-vuex/src/util'
import omit from 'lodash.omit'

export enum SearchType {
  /** 输入框 */
  Input = 'input',
  /** 下拉框 */
  Select = 'select',
  /** 多选下拉框 */
  MultiSelect = 'multi-select',
}

export const namespace = '@xuexin-vuex/page'

export const types = {
  /** 更新页面配置 */
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',

  /** 查询页面配置 */
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
}

const state: Page.State = {
  list: { data: [] },
  items: {},
}

const actions: Page.Actions = {
  update(context, payload) {
    const { authCode, ...parameter } = payload
    return Ajax(context, {
      type: types.UPDATE_REQUEST,
      payload,
      api: `put ${__STUDENT_API__}/config/pages/${authCode}/0`,
      parameter,
      success: types.UPDATE_SUCCESS,
      failure: types.UPDATE_FAILURE,
    })
  },
  fetch(context, payload) {
    const { authCode, ...parameter } = payload
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload,
      api: `get ${__STUDENT_API__}/config/pages/${authCode}`,
      parameter,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
}

const mutations: Page.Mutations = {
  /** 更新页面配置 */
  [types.UPDATE_REQUEST](state, { payload }) {
    const { authCode = '', ...parameter } = payload
    handlers.itemRequest(state, authCode, 'updating')
    const _state = state.items[authCode]
    handlers.updateState(
      _state,
      'data',
      Object.assign({}, _state.data, parameter)
    )
  },
  [types.UPDATE_SUCCESS](state, { payload }) {
    handlers.itemSuccess(state, payload.authCode, 'updating')
  },
  [types.UPDATE_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, payload.authCode, 'updating', message)
  },

  /** 查询页面配置 */
  [types.FETCH_REQUEST](state, { payload }) {
    handlers.itemRequest(state, payload.authCode, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { payload, result }) {
    handlers.itemSuccess(state, payload.authCode, 'fetching', result)
  },
  [types.FETCH_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, payload.authCode, 'fetching', message)
  },
}

const getters: Page.Getters = {
  itemStatus() {
    return authCode => omit(state.items[authCode], ['data'])
  },
  item(state) {
    return authCode => state.items[authCode]?.data
  },
  columns(state, getters) {
    return authCode => {
      const item = getters.item(authCode) || ({} as Page.Entity)
      return item.columns || []
    }
  },
  searches(state, getters) {
    return authCode => {
      const item = getters.item(authCode) || ({} as Page.Entity)
      return item.searches || []
    }
  },
  auths(state, getters) {
    return authCode => {
      const item = getters.item(authCode) || ({} as Page.Entity)
      return item.auths || []
    }
  },
  parameter(state, getters) {
    return authCode => {
      const item = getters.item(authCode) || ({} as Page.Entity)
      return item.parameter || {}
    }
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
