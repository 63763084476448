import { Constant } from 'xuexin-vuex'

export type ResearchField = Constant.ResearchField
export const ResearchField = Constant.ResearchField

export const ResearchFieldList = [
  ResearchField.Culture,
  ResearchField.Art,
  ResearchField.International,
  ResearchField.IICK,
  ResearchField.SpecialOffer,
]

export const messages: Messages = {
  'zh-CN': {
    [ResearchField.Culture]: '文化',
    [ResearchField.Art]: '艺体',
    [ResearchField.International]: '出国',
    [ResearchField.IICK]: '港澳台及外国籍',
    [ResearchField.SpecialOffer]: '特招',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [ResearchField.Culture]: '文化',
    [ResearchField.Art]: '艺体',
    [ResearchField.International]: '出国',
    [ResearchField.IICK]: '港澳台及外国籍',
    [ResearchField.SpecialOffer]: '特招',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
