












































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Row, Col as ViewCol, Icon, Avatar, Tabs, TabPane } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import ApplyInfo from './components/apply-info.vue'
import BaseInfo from './components/base-info.vue'
import FamilyInfo from './components/family-info.vue'
import AdmissionInfo from './components/admission-info.vue'
import RecordPage from './components/record.vue'
import omit from 'lodash.omit'

import { Student, Constant } from 'xuexin-vuex'

import i18n from './i18n'

enum KindCode {
  Enroll = 'enroll',
  Base = 'base',
  Family = 'family',
  Status = 'status',
  Record = 'record',
}

const components = {
  ViewLayout,
  Row,
  ViewCol,
  Icon,
  Avatar,
  Tabs,
  TabPane,
  ApplyInfo,
  BaseInfo,
  FamilyInfo,
  AdmissionInfo,
  RecordPage,
}

@Component({ name: 'StudentDetailPage', components, i18n })
export default class StudentDetailPage extends Vue {
  @Student.Action('fetch') private readonly fetch!: Student.Action.Fetch
  @Student.Getter('itemStatus')
  private readonly getStatus!: Student.Getter.ItemStatus
  @Student.Getter('item') private readonly getItem!: Student.Getter.Item

  @Prop({ type: String, default: '' }) private readonly actionType!: string

  private readonly prefixCls = 'student-detail'
  private activeTag: string = 'enroll'

  private KindCode = KindCode

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get xuexinID(): string {
    return this.$route.params.xuexinID
  }

  get termID() {
    return Number(this.$route.params.termID) || undefined
  }
  get parentUnitID() {
    return Number(this.$route.params.parentUnitID) || undefined
  }
  get unitID() {
    return Number(this.$route.params.unitID) || undefined
  }
  get tranferStudentID() {
    return (
      Number(this.$route.params.tranferStudentID) ||
      Number(this.$route.query.tranferStudentID) ||
      undefined
    )
  }
  get item(): Partial<Student.Entity> {
    return this.getItem(this.xuexinID) || { columns: [] }
  }

  get columns(): Student.Column[] {
    return this.item.columns || []
  }

  get kinds() {
    const kinds = [
      KindCode.Enroll,
      KindCode.Base,
      KindCode.Family,
      KindCode.Status,
    ]
    if (this.item.recordShow === 1) {
      kinds.push(KindCode.Record)
    }
    return kinds
  }
  /** 详情数据 */
  get tabs() {
    const tabMap: Record<string, any> = {}
    const compose: Record<string, Partial<Student.Column>> = {}
    let statusTypeVal = '',
      transientStudentVal = '',
      freshStudentVal = ''

    this.columns.forEach(i => {
      i.columnCode === 'statusType' && (statusTypeVal = i.value)
      i.columnCode === 'transientStudent' && (transientStudentVal = i.value)
      i.columnCode === 'freshStudent' && (freshStudentVal = i.value)
    })

    /** 对 column 进行分类 */
    this.columns?.forEach(column => {
      /**无本校学籍时，才显示“借读生”，“借读生”为“是”时，才显示“借读生转籍状态” */
      // if (statusTypeVal != '3') {
      //   if (
      //     column.columnCode === 'transientStudent' ||
      //     column.columnCode === 'transientStatusType'
      //   ) {
      //     return
      //   }
      // } else {
      //   if (transientStudentVal != '1') {
      //     if (column.columnCode === 'transientStatusType') {
      //       return
      //     }
      //   }
      // }

      if (Number(freshStudentVal) === 0 && `${transientStudentVal}` === '4') {
        if (
          column.columnCode === 'transientStudent' ||
          column.columnCode === 'transientStatusType'
        ) {
          return
        }
      } else {
        if (`${transientStudentVal}` !== '1') {
          if (column.columnCode === 'transientStatusType') {
            return
          }
        }
      }

      let value = column.value
      //处理护照有效期
      if (column.columnCode === 'idInfo') {
        value = JSON.parse(JSON.stringify(column.value))
        value.forEach((i: any) => {
          i.idDate = i.idDate
            ? `${(i.idDate + '').substr(0, 4)}-${(i.idDate + '').substr(
                4,
                2
              )}-${(i.idDate + '').substr(6, 2)}`
            : '-'
        })
      }
      const assignColumn = Object.assign({}, column, { value })
      const { columnType, columnCode, parentCode = '' } = assignColumn
      const name = assignColumn.kindCode

      /** 初始化 tab 项 */
      if (tabMap[name] === void 0) {
        tabMap[name] = { name, label: assignColumn.kindName, columns: [] }
      }

      /** 组合节点的父节点 */
      if (Number(columnType) === Number(Constant.ColumnType.Object)) {
        if (compose[columnCode] === void 0) {
          compose[columnCode] = { children: [] }
        }
        assignColumn.children = compose[columnCode].children
        compose[columnCode] = assignColumn
      }
      // 归属于 parentCode 节点
      else if (Boolean(parentCode) === true) {
        if (compose[parentCode] === void 0) {
          compose[parentCode] = { children: [] }
        }
        compose[parentCode].children?.push(assignColumn)
        return void 0
      }

      tabMap[name].columns.push(assignColumn)
    })
    const kind = Object.keys(tabMap).map(key => tabMap[key])
    if (Object.keys(kind).length < 1) {
      return []
    }
    this.activeTag = kind[0].name
    return this.kinds.map(kindCode => {
      if (kindCode === KindCode.Record) {
        return {
          kindCode: KindCode.Record,
          kindName: this.$t('text.operationRecord') as string,
          data: [],
        }
      }
      if (kindCode === KindCode.Family) {
        return {
          kindCode,
          kindName: kind.filter(item => item.name === kindCode)[0]?.label,
          data: kind
            .map(item => {
              if (item.name === kindCode) {
                return [...item.columns[0].families]
              }
            })
            .filter(item => {
              return item !== void 0 && item !== null
            })[0],
        }
      }
      return {
        kindCode,
        kindName: kind.filter(item => item.name === kindCode)[0]?.label,
        data: kind
          .map(item => {
            if (item.name === kindCode) {
              return item.columns
            }
          })
          .filter(item => {
            return item !== void 0 && item !== null
          })[0],
      }
    })
  }

  get gender() {
    return this.$t(`text.gender.${this.item.gender}`)
  }

  get status() {
    return this.getStatus(this.xuexinID)
  }

  get loading() {
    return this.status.fetching !== false
  }
  /** 切换tab */
  private handleTab(name: string): void {
    this.activeTag = name
  }

  private handleBack() {
    this.$router.go(-1)
  }

  private compileColumns() {
    if (this.item === void 0 || this.item === null) {
      return void 0
    }

    const tabMap: Record<string, any> = {}
    const compose: Record<string, Partial<Student.Column>> = {}
    /** 对 column 进行分类 */
    this.columns?.forEach(column => {
      let value = column.value
      const assignColumn = Object.assign({}, column, { value })
      const { columnType, columnCode, parentCode = '' } = assignColumn
      const name = assignColumn.kindCode

      /** 初始化 tab 项 */
      if (tabMap[name] === void 0) {
        tabMap[name] = { name, label: assignColumn.kindName, columns: [] }
      }

      /** 组合节点的父节点 */
      if (columnType === Constant.ColumnType.Object) {
        if (compose[columnCode] === void 0) {
          compose[columnCode] = { children: [] }
        }
        assignColumn.children = compose[columnCode].children
        compose[columnCode] = assignColumn
      }
      // 归属于 parentCode 节点
      else if (Boolean(parentCode) === true) {
        if (compose[parentCode] === void 0) {
          compose[parentCode] = { children: [] }
        }
        compose[parentCode].children?.push(assignColumn)
        return void 0
      }

      tabMap[name].columns.push(assignColumn)
    })

    /** 生成 tabPanes */
    const tabPanes = Object.keys(tabMap).map(key => tabMap[key])
    return tabPanes
  }

  created() {
    this.fetch({
      studentID: this.xuexinID,
      tranferStudentID: this.tranferStudentID,
      termID: this.termID,
      unitID: this.unitID,
      authCode: this.$route.meta.authCode,
      platform: 5,
    })
  }
}
