import {
  Api,
  User,
  Semester,
  School,
  SchoolDepart,
  SchoolPhase,
  SchoolSystem,
  BusinessType,
  ClassType,
  Student,
  TrialRead,
  Abroad,
  Teacher,
  ApprovalNode,
  Workflow,
  Common,
  Region,
  Direction,
} from 'xuexin-vuex'

import Page from './page'
import Dashboard from './dashboard'
import InformationSetup from './information-setup'
import ConfigNode from './config-node'
import Export from './export'
import Records from './records'
import Download from './download'
import ActualLossRate from './actual-loss-rate'
import AssessLossRate from './assess-loss-rate'
import LostReport from './lost-report'
import ClassReport from './class-report'
import StudentInsert from './student-insert'
import StudentDistribution from './student-distribution'
import SchoolOverview from './school-overview'
import StudentReport from './student-report'
import ClassPayment from './class-payment'
import ClassBatchSubmitApproval from './class-batch-submit-approval'
import EnrollPayData from './enroll-pay-data'
import SchoolClassType from './school-class-type'
import Grade from './grade'
import Glass from './glass'
import Session from './session'
import Dictionary from './dictionary'
import StudentTag from './student-tag'
import Project from './project'
import OperateClass from './operate-class'

Api.init({
  library: __LIBRARY_API__,
  student: __STUDENT_API__,
  management: __MANAGEMENT_API__,
  personal: __PERSONAL_API__,
  dataReport: __DATA_REPORT_API__,
})

const modules = [
  User,
  Session,
  Semester,
  School,
  SchoolDepart,
  SchoolPhase,
  SchoolSystem,
  BusinessType,
  ClassType,
  Grade,
  Glass,
  Student,
  TrialRead,
  Abroad,
  Teacher,
  SchoolClassType,
  ApprovalNode,
  Workflow,
  Common,
  Region,
  Direction,
  EnrollPayData,
  Dictionary,
  StudentTag,
  Project,
  OperateClass,
]
export default modules.reduce<any>(
  (modules, item) => {
    return (modules[item.namespace] = item.Module), modules
  },
  {
    [Page.namespace]: Page.Module, // 页面配置
    [Dashboard.namespace]: Dashboard.Module, // 首页
    [InformationSetup.namespace]: InformationSetup.Module, // 信息配置
    [ConfigNode.namespace]: ConfigNode.Module, //流失配置节点
    [Export.namespace]: Export.Module, // 导出模块
    [Records.namespace]: Records.Module, // 操作记录
    [Download.namespace]: Download.Module, // 模版下载
    [ActualLossRate.namespace]: ActualLossRate.Module, // 实际流失率统计
    [AssessLossRate.namespace]: AssessLossRate.Module, // 考核流失率统计
    [LostReport.namespace]: LostReport.Module, // 流失对比分析
    [ClassReport.namespace]: ClassReport.Module, // 班级统计
    [StudentInsert.namespace]: StudentInsert.Module, // 插班生分析
    [StudentDistribution.namespace]: StudentDistribution.Module, // 学生分布分析
    [SchoolOverview.namespace]: SchoolOverview.Module, // 学校总览分析
    [StudentReport.namespace]: StudentReport.Module,
    [ClassPayment.namespace]: ClassPayment.Module,
    [ClassBatchSubmitApproval.namespace]: ClassBatchSubmitApproval.Module,
    [EnrollPayData.namespace]: EnrollPayData.Module,
    [Dictionary.namespace]: Dictionary.Module,
    [StudentTag.namespace]: StudentTag.Module,
    [Project.namespace]: Project.Module,
    [OperateClass.namespace]: OperateClass.Module,
  }
)
