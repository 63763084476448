

























































































































































































































































































































































































import Vue, { CreateElement } from 'vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import PagePropsMixins from '@mixins/page-props-mixins'
import { Session, School, SchoolDepart, Student, Constant } from 'xuexin-vuex'
import { Button, TableColumn } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'

import {
  StudentStatusTag,
  StudentStatusModal,
} from '@business-components/student-status' /** 学生状态组件 */

import { StudentFlowStatusBadge } from '@business-components/student-flow-status'

import ExportModal from '@business-components/export-modal'
import SendMessageModal from '@business-components/send-message-modal'
import ImportRecordModal from '@business-components/record-modal'
import TemplateDownloadModal from '../template-download-modal/template-download-modal.vue'
import BatchImportModal from '../import-modal/index'
//import BatchImportModal from '@business-components/multiple-export-modal'
import AcademicLabelModal from '../academic-label-modal'
import LeaveSchoolModal from '../leave-school-modal'
import SuspensionModal from '../suspension-modal'
import ChangeSchoolModal from '@business-components/transfer-school-modal'
import InnerChangeSchoolModal from '@business-components/inner-transfer-modal'
import ChangeGradeModal from '../change-grade-modal'
import ChangeClassModal from '../change-class-modal'
import ChangeDepartModal from '../change-depart-modal'
import GradeModal from '@business-components/grade-modal'
import ClassModal from '@business-components/glass-modal'
import { convertSearchParameter } from '@util'
import i18n from './i18n'
import PageTyping from '../../../../types/page'
import { getParameter, setParameter } from '@util/parameter'
import NumFormate from '@util/num-format'

const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  AppTable,
  AppPagination,
  /** 学生状态想着 */
  StudentStatusTag,
  StudentStatusModal,

  StudentFlowStatusBadge,
  ExportModal,
  SendMessageModal,
  ImportRecordModal,
  TemplateDownloadModal,
  BatchImportModal,
  AcademicLabelModal,
  LeaveSchoolModal,
  SuspensionModal,
  ChangeSchoolModal,
  InnerChangeSchoolModal,
  ChangeGradeModal,
  ChangeClassModal,
  ChangeDepartModal,
  GradeModal,
  ClassModal,
}

type Flat = Record<string, boolean>
/** 学生状态枚举 */
const StudentStatus = Constant.StudentStatus
/** 学生流程状态 */
const FlowStatus = Constant.FlowStatus
/** 请求类型枚举 */
const ActionType = Constant.ActionType
/** 页面类型 */
const PageType = Constant.PageType

@Component({ name: 'InClassStudentListPage', components, i18n })
export default class InClassStudentListPage extends Mixins(PagePropsMixins) {
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Action('fetchList')
  private readonly fetchStudents!: Student.Action.FetchList
  @Student.Getter('list') private readonly students!: Student.Getter.List
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Student.Getter('itemStatus')
  private readonly getStudentStatus!: Student.Getter.ItemStatus
  @Student.Getter('item') private readonly getStudent!: Student.Getter.Item

  @School.Action('fetch') private readonly fetchSchool!: School.Action.Fetch
  @School.Getter('item') private readonly getSchool!: School.Getter.Item
  @Student.Mutation('CLEAN_LIST')
  private readonly cleanList!: Student.Mutation.CleanList

  @Session.Getter('user') private readonly user!: Session.Getter.User

  @SchoolDepart.Getter('item')
  private readonly getSchoolDepart!: SchoolDepart.Getter.Item

  private readonly prefixCls = 'in-class-student-list-page'
  private flat: Flat = {}
  private modal: { name: string | null; data: any } = { name: null, data: {} }
  private selection: Student.Entity[] = []
  private searchParameter: Partial<PageTyping.SearchParameter> = {}
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private page = 1
  private pageSize = 10

  get authCode() {
    return this.$route.meta.authCode
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get valid() {
    // const { schoolDepartID } = this.parameter
    // return (
    //   Boolean(schoolDepartID) ||
    //   schoolDepartID === 0 ||
    //   Boolean(this.searchParameter.schoolDepartID) ||
    //   this.searchParameter.schoolDepartID === 0
    // )
    return true
  }

  get noDataText() {
    // if (
    //   Boolean(this.parameter.schoolDepartID) === false ||
    //   Boolean(this.searchParameter.schoolDepartID) === false
    // ) {
    //   return this.$t('warning.non-search')
    // }
    return this.$t('text.no-data-text')
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get total() {
    return this.listStatus.total || 0
  }

  get tableColumns(): TableColumn[] {
    if (this.valid !== true || this.tableData.length === 0) {
      return []
    }

    const columns = this.columns.map(item => {
      switch (item.key) {
        case 'studentStatus' /** 学生状态 */:
          return Object.assign({}, item, {
            width: 140,
            slot: 'studentStatus',
            render: void 0,
          })
        case 'aliasClassName' /** 学生状态 */:
          return Object.assign({}, item, {
            width: 200,
            slot: 'aliasClassName',
            render: void 0,
          })

        case 'parentMobile' /** 家长电话 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.smsPhone ? NumFormate(`${row.smsPhone}`) : '-'
              return createElement!('span', {}, [text])
            },
          })
        case 'studentPhone' /** 学生电话 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.studentPhone
                ? NumFormate(`${row.studentPhone}`)
                : '-'
              return createElement!('span', {}, [text])
            },
          })
        case 'IDNO' /** 证件号码 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.IDNO ? NumFormate(`${row.IDNO}`) : '-'
              return createElement!('span', {}, [text])
            },
          })
      }
      return item
    })

    // 添加复选框
    columns.unshift({
      type: 'selection',
      width: 60,
      fixed: 'left',
      align: 'center',
    })
    return columns
  }

  get tableData() {
    if (this.valid !== true) {
      return []
    }

    return this.students.map((item, index) => {
      const student = Object.assign({}, item)

      /** 还原当前选中的学生 */
      if (this.flat[item.studentID] !== void 0) {
        student._checked = true
      }

      student.num = (this.page - 1) * this.pageSize + index + 1
      return student
    })
  }

  get itemStatus() {
    if (this.selection.length !== 1) {
      return {}
    }

    return this.getStudentStatus(this.selection[0].studentID)
  }

  get disabled() {
    return this.tableData.length === 0
  }

  get parentUnitID() {
    return this.parameter.parentUnitID
  }

  get schoolConfig() {
    if (
      this.parentUnitID === void 0 ||
      this.parentUnitID === null ||
      this.parameter.schoolDepartID === void 0 ||
      this.parameter.schoolDepartID === null
    ) {
      return []
    }

    const school = this.getSchool(this.parentUnitID) || ({} as School.Entity)
    const schoolDepart =
      this.getSchoolDepart(this.parameter.schoolDepartID) ||
      ({} as SchoolDepart.Entity)

    return (school.columnConfig || []).filter(item => {
      return item.schoolDepartTypeID === schoolDepart.schoolDepartTypeID
    })
  }

  get disableTags() {
    if (
      this.disabled ||
      this.parentUnitID === void 0 ||
      this.parentUnitID === null
    ) {
      return true
    }

    return (
      this.schoolConfig.filter(item => {
        return (
          (item.columnCode === 'studentMark' ||
            item.columnCode === 'researchField') &&
          item.forceShow !== 0
        )
      }).length === 0
    )
  }

  get organID() {
    return this.user?.organID
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 查询学生列表请求完成 */
    if (fetching === false && previous === true) {
      /** 提示错误信息 */
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }

  @Watch('searchParameter.unitID')
  private watchUnitID(value: number, oldValue: unknown) {
    // 有条件缓存时，不走默认查询
    const para = getParameter(`${this.$route.meta.authCode}`)
    if (para) return

    // 无条件缓存时，默认查询一次
    if (oldValue === null || oldValue === undefined) {
      this.parameter = { ...this.searchParameter }
      this.fetch()
    }
  }

  /** 监听学生列表状态 */
  @Watch('listStatus.updating')
  private watchListUpdating(updating: boolean, previous: boolean) {
    /** 仅处理确认提示框 */
    if (this.modal.name !== null) {
      return void 0
    }

    /** 更新操作完成 */
    if (updating === false && previous === true) {
      /** 更新操作失败，提示错误信息 */
      if (this.listStatus.updatingError !== null) {
        return this.$Message.error(this.listStatus.updatingError)
      }

      /** 操作成功提示 */
      this.$Message.success(`${this.$t('success')}`)

      /** 关闭窗口 */
      this.$Modal.remove()
    }
  }

  /** 监听单个学生状态 */
  @Watch('itemStatus.updating')
  private watchItemUpdating(updating: boolean, previous: boolean) {
    /** 仅处理确认提示框 */
    if (this.modal.name !== null) {
      return void 0
    }

    /** 更新操作完成 */
    if (updating === false && previous === true) {
      /** 更新操作失败，提示错误信息 */
      if (this.itemStatus.updatingError !== null) {
        return this.$Message.error(this.itemStatus.updatingError)
      }

      /** 操作成功提示 */
      this.$Message.success(`${this.$t('success')}`)

      /** 关闭窗口 */
      this.$Modal.remove()
    }
  }

  /** 监听已选学生 */
  @Watch('selection', { immediate: true })
  private watchSelection(selection: Student.Entity[]) {
    this.$set(
      this.$data,
      'flat',
      selection.reduce((flat: Flat, item) => {
        return (flat[item.studentID] = true), flat
      }, {})
    )
  }

  @Watch('parentUnitID', { immediate: true })
  private watchParentUnitID(parentUnitID: number, previous: number) {
    if (
      parentUnitID !== previous &&
      parentUnitID !== void 0 &&
      parentUnitID !== null &&
      parentUnitID !== -1
    ) {
      this.fetchSchool({ unitID: parentUnitID })
    }
  }

  created() {
    /** 清理数据 */
    this.cleanList()

    // 若有查询条件缓存，则回显查询条件
    const para = getParameter(`${this.$route.meta.authCode}`)
    if (para) {
      let parameters = {}
      let birthday: any = para.searches.birthday

      if (birthday[0] === '' || !Boolean(birthday)) {
        birthday = ['', '']
      } else {
        birthday = [new Date(birthday[0]), new Date(birthday[1])]
      }

      parameters = Object.assign({}, para.searches, { birthday: birthday })
      this.$set(this.$data, 'parameter', parameters)
      this.$set(this.$data, 'searchParameter', parameters)
      this.page = para.page
      this.pageSize = para.pageSize
      this.fetch()
    }
  }

  mounted() {}

  destroyed() {}

  private fetch() {
    if (
      this.valid !== true /** 参数无效 */ ||
      this.fetching === true /** 正在请求数据 */
    ) {
      /** 以上情况不发起请求 */
      return void 0
    }
    this.fetchStudents({
      authCode: this.authCode,
      searches: this.convertParameter(),
      page: this.page,
      pageSize: this.pageSize,
    })

    // 缓存查询条件
    setParameter(`${this.$route.meta.authCode}`, {
      authCode: this.authCode,
      searches: this.searchParameter,
      page: this.page,
      pageSize: this.pageSize,
    })
  }

  private convertParameter() {
    return convertSearchParameter(this.parameter)
  }

  /** 工具栏按钮处理函数 */
  private handleClick(action: string, student: Student.Entity) {
    switch (action) {
      case 'sms-record' /** 短信发送记录 */:
        {
          this.$router.push({
            name: 'in-class-sms-record',
            query: this.convertParameter(),
          })
        }
        break
      case 'export' /** 导出 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'export-modal',
            data: {
              list: this.selection.map(item => ({ id: item.studentID })),
              authCode: this.authCode,
              actionType: ActionType.InClass,
              type: Constant.PageType.InClass,
              searches: this.convertParameter(),
            },
          })
        }
        break
      case 'sms' /** 发送短信窗口 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'sms-modal',
            data: {
              list: this.selection.map(item => ({
                id: item.studentID,
                name: item.studentName,
                mobile: item.parentMobile,
              })),
              searches: this.convertParameter(),
              authCode: this.authCode,
              actionType: ActionType.InClass,
              pageType: PageType.InClass,
            },
          })
        }
        break
      case 'import-record' /** 导入记录 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'import-record-modal',
            data: {
              termID: this.parameter.termID,
              unitID: this.parameter.unitID,
              type: Constant.PageType.InClass,
            },
          })
        }
        break
      case 'template-download' /** 模板下载 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'template-download-modal',
            data: {},
          })
        }
        break
      case 'batch-import' /** 批量导入 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'batch-import-modal',
            data: {
              termID: this.parameter.termID,
              unitID: this.parameter.unitID,
              pageType: Constant.PageType.InClass,
              importType: Constant.ImportType.StudyLabel,
              type: 'inClass',
            },
          })
        }
        break

      case 'edit' /** 编辑学生信息 */:
        {
          const xuexinID = this.selection[0].studentID
          const student = this.getStudent(`${xuexinID}`)
          // prettier-ignore
          if (student === void 0) { return void 0 }

          this.$router.push({
            name: 'in-class-student-edit',
            params: {
              termID: `${student.termID}`,
              unitID: `${student.unitID}`,
              xuexinID: `${xuexinID}`,
            },
          })
        }
        break
      case 'edit-tags' /** 编辑学业标签 */: {
        if (student !== void 0 || this.selection.length === 1) {
          /** 区别当前学生 ID */
          const studentID = student?.studentID || this.selection[0].studentID
          /** 通过学生 ID 查找最新数据 */
          const item = this.getStudent(this.selection[0].studentID)

          // prettier-ignore
          if (item === void 0) { return void 0 }

          return this.$set(this.$data, 'modal', {
            name: 'academic-label-modal',
            data: {
              config: this.schoolConfig,
              termID: item.termID,
              unitID: item.unitID,
              schoolDepartID: item.schoolDepartID,
              phaseID: item.phaseID,
              classID: item.classID,
              xuexinID: item.studentID,
              studentName: item.studentName,
              studentMark: item.studentMark,
              researchField: item.researchField,
            },
          })
        }

        /** 批量编辑学业标签 */
        const list =
          this.selection.length === 0 ? this.tableData : this.selection
        const ids = list.map(item => item.studentID).join(',')
        return this.$router.push({
          name: 'batch-edit-marks',
          params: {
            termID: `${this.parameter.termID}`,
            unitID: `${this.parameter.unitID}`,
          },
          query: { ids },
        })
      }
      case 'leave-school' /** 离校(外出艺体) */:
        {
          if (this.checkStudentStatus('leave-school') !== true) {
            return void 0
          }

          /** 更新 modal 数据 */
          this.$set(this.$data, 'modal', {
            name: 'leave-school-modal',
            data: this.selection,
            unitID: `${this.parameter.unitID}`,
          })
        }
        break
      case 'back-school' /** 返校(外出艺体返校) */:
        {
          if (this.checkStudentStatus('back-school') !== true) {
            return void 0
          }

          const xuexinIDs: string[] = []
          const [{ termID }] = this.selection
          const names: string[] = []
          this.selection.forEach(({ studentID, studentName }) => {
            xuexinIDs.push(studentID), names.push(studentName)
          })

          /** 操作确认提示 */
          this.$Modal.confirm({
            title: this.$t('title.back-school') as string,
            // prettier-ignore
            content: this.$t('content.back-school', [names.join('、')]) as string,
            loading: true,
            onOk: () => {
              this.update({
                actionType: ActionType.BackSchool,
                xuexinIDs,
                termID,
                unitID: this.parameter.unitID,
              })
            },
            onCancel: () => {},
          })
        }
        break
      case 'suspension' /** 休学 */:
        {
          if (this.checkStudentStatus('suspension') !== true) {
            return void 0
          }

          /** 更新窗口数据 */
          this.$set(this.$data, 'modal', {
            name: 'suspension-modal',
            data: this.selection[0],
          })
        }
        break
      case 'resumption' /** 复学 */:
        {
          if (this.checkStudentStatus('resumption') !== true) {
            return void 0
          }

          /** 操作确认提示 */
          const { studentID, studentName, termID, unitID } = this.selection[0]
          this.$Modal.confirm({
            title: this.$t('title.resumption') as string,
            content: this.$t('content.resumption', [studentName]) as string,
            loading: true,
            onOk: () => {
              this.update({
                actionType: ActionType.Resumption,
                studentID,
                xuexinID: studentID,
                termID,
                unitID,
              })
            },
            onCancel: () => {},
          })
        }
        break
      case 'change-school' /** 转学 */:
        {
          const student = this.getStudent(this.selection[0].studentID)
          if (student === void 0 || student === null) {
            return void 0
          }
          const { termID, unitID } = student
          const organID = this.parameter.organID
          this.$set(this.$data, 'modal', {
            name: 'change-school-modal',
            data: { termID, unitID, organID, student },
          })
        }
        break
      case 'inter-change-school' /** 校际互转 */:
        {
          const student = this.getStudent(this.selection[0].studentID)
          if (student === void 0 || student === null) {
            return void 0
          }
          const { termID, unitID, studentID, phaseID } = student
          const outUnitID = unitID
          const organID = this.organID
          const isCheck = false
          this.$set(this.$data, 'modal', {
            name: 'inner-change-school-modal',
            data: { organID, termID, outUnitID, studentID, isCheck, phaseID },
          })
        }
        break
      case 'change-grade' /** 跳留级 */:
      case 'change-class': // 转班
        {
          const list = this.selection.map(item =>
            this.getStudent(item.studentID)
          ) as Student.Entity[]

          const [
            /** 从第一个学生元素身上获取属性 */
            {
              termID,
              unitID,
              schoolDepartID,
              phaseID,
              unitGradeID,
              gradeRank,
            },
            ...others
          ] = list
          const other = others.find(item => item.unitGradeID !== unitGradeID)

          if (other !== void 0) {
            return this.$Message.warning(`${this.$t('warning.diff-grade')}`)
          }

          this.$set(this.$data, 'modal', {
            name: `${action}-modal`,
            data: {
              termID,
              unitID,
              schoolDepartID,
              phaseID,
              unitGradeID,
              xuexinIDs: this.selection.map(item => item.studentID),
              gradeRank,
            },
          })
        }
        break
      case 'change-depart': // 学部互转
        {
          const student = this.getStudent(this.selection[0].studentID)
          // prettier-ignore
          if (student === void 0) { return void 0 }

          this.$set(this.$data, 'modal', {
            name: 'change-depart-modal',
            data: {
              termID: student.termID,
              parentUnitID: student.parentUnitID,
              unitID: student.unitID,
              schoolDepartID: student.schoolDepartID,
              phaseID: student.phaseID,
              studentID: student.studentID,
            },
          })
        }
        break
      case 'view-student' /** 查看学生信息 */:
        {
          this.$router.push({
            name: 'in-class-student-detail',
            params: {
              termID: student.termID,
              unitID: `${student.unitID}`,
              xuexinID: `${student.studentID}`,
              parentUnitID: `${student.parentUnitID || ''}`,
            },
          })
        }
        break
      case 'view-status' /** 查看学生状态(休学/外出) */:
        {
          this.$set(this.$data, 'modal', {
            name:
              student.studentStatus === 1
                ? 'suspension-modal'
                : 'student-status-modal',
            data: {
              termID: student.termID,
              studentID: student.studentID,
              status: student.studentStatus,
              studentStatus: student.studentStatus,
              unitID: student.unitID,
            },
          })
        }
        break
      case 'view-grade' /** 查看年级信息 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'grade-modal',
            data: {
              viewType: 'view',
              unitGradeID: student.unitGradeID,
              parameter: { termID: student.termID },
            },
          })
        }
        break
      case 'view-class' /** 查看班级信息 */:
        {
          this.$set(this.$data, 'modal', {
            name: 'class-modal',
            data: {
              viewType: 'view',
              classID: student.classID,
              parameter: { termID: student.termID },
            },
          })
        }
        break
      case 'delete': /** 删除 */
      case 'create' /** 添加学生 */:
        console.warn('未实现')
        break
      default:
        console.warn(`${this.$options.name}.handleClick(${action})`)
        break
    }
  }

  /** 检查学生状态及流程状态 */
  private checkStudentStatus(action: string): boolean | void {
    /** 空挂学籍的学生 */
    const emptys: string[] = []
    /** 离校状态的学生 */
    const leaves: string[] = []
    /** 休学状态的学生 */
    const suspensions: string[] = []
    /** 处于转学中的学生 */
    const transfers: string[] = []
    /** 处于校际互转中的学生 */
    const innerTransfers: string[] = []

    /** 遍历获取每个学生的状态并过滤无效数据 */
    const selection = this.selection.filter(item => {
      const student = this.getStudent(item.studentID)
      if (student === void 0 || student === null) {
        return false
      }
      const { studentStatus, flowStatus, studentName } = student

      /** 收集学生状态 */
      switch (studentStatus) {
        case StudentStatus.EmptyStatus: // 空挂学籍
          emptys.push(studentName)
          break
        case StudentStatus.LeaveSchool: // 离校(外出艺体)
          leaves.push(studentName)
          break
        case StudentStatus.Suspension: // 休学
          suspensions.push(studentName)
          break
        default:
          break
      }

      /** 收集学生流程状态 */
      switch (flowStatus) {
        case FlowStatus.Transfer: // 转学
          transfers.push(studentName)
          break
        case FlowStatus.InterSchoolTransfer: // 校际互转
          innerTransfers.push(studentName)
          break
        default:
          break
      }

      return true
    })

    /** 单条件判断 */
    switch (action) {
      /** 返校(外出艺体返校) */
      case 'back-school': {
        return leaves.length !== selection.length
          ? this.$Message.warning(this.$t('warning.back-school'))
          : true
      }
      /** 复学 */
      case 'resumption': {
        return suspensions.length !== selection.length
          ? this.$Message.warning(this.$t('warning.resumption'))
          : true
      }
    }

    /** 多条件判断 */
    switch (true) {
      /**
       * 空挂学籍的学生，不能进行以下操作。
       * 1. 离校(外出艺体)
       * 2. 休学
       */
      case emptys.length > 0 &&
        (action === 'leave-school' || action === 'suspension'): {
        const message = this.$t(`warning.${action}`, [
          emptys.join('、'),
          this.$t(`student-status.${StudentStatus.EmptyStatus}`),
        ])
        return this.$Message.warning(message)
      }

      /**
       * 休学的学生，不能进行以下操作。
       * 1. 离校(外出艺体)
       * 2. 休学
       */
      case suspensions.length > 0 &&
        (action === 'leave-school' || action === 'suspension'): {
        const message = this.$t(`warning.${action}`, [
          suspensions.join('、'),
          this.$t(`student-status.${StudentStatus.Suspension}`),
        ])
        return this.$Message.warning(message)
      }
      /**
       * 已离校(外出艺体)的学生不能进行以下操作
       * 1. 离校(外出艺体)
       * 2. 休学
       */
      case leaves.length > 0 &&
        (action === 'leave-school' || action === 'suspension'): {
        const message = this.$t(`warning.${action}`, [
          leaves.join('、'),
          this.$t(`student-status.${StudentStatus.LeaveSchool}`),
        ])
        return this.$Message.warning(message)
      }
      /**
       * 转学学生不能进行以下操作
       * 1. 离校(外出艺体)
       * 2. 休学
       */
      case transfers.length > 0 &&
        (action === 'leave-school' || action === 'suspension'): {
        const message = this.$t(`warning.${action}`, [
          transfers.join('、'),
          this.$t(`flow-status.${FlowStatus.Transfer}`),
        ])
        return this.$Message.warning(message)
      }
      /**
       * 校际互转的学生不能进行以下操作
       * 1. 离校(外出艺体)
       * 2. 休学
       */
      case innerTransfers.length > 0 &&
        (action === 'leave-school' || action === 'suspension'): {
        const message = this.$t(`warning.${action}`, [
          innerTransfers.join('、'),
          this.$t(`flow-status.${FlowStatus.InterSchoolTransfer}`),
        ])
        return this.$Message.warning(message)
      }
    }

    return true
  }

  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    // if (Boolean(parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(
    //     `${this.$t('warning.no-school-depart-id')}`
    //   )
    // }
    this.$set(this.$data, 'parameter', parameter)
    this.$set(this.$data, 'page', 1)
    this.$set(this.$data, 'selection', [])
    this.fetch()
  }

  /** 已选学生发生改变 */
  private handleSelectionChange(selection: Student.Entity[]) {
    this.$set(this.$data, 'selection', selection)
  }

  /** 页码发生变化 */
  private handlePageChange(page: number) {
    /**
     * 修改本地 `page` 属性值，清空已选数据，并重新请求数据。
     */
    this.$set(this.$data, 'page', page)
    this.$set(this.$data, 'selection', [])
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number) {
    /**
     * 修改本地 `pageSize` 属性值，若当前 `page` 为 1，则手动请求数据。
     */
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }

  /** 窗口功能完成事件处理函数 */
  private handleModalFinish(modal: string) {
    /** 清理窗口 */
    this.$set(this.$data, 'modal', { name: null, data: {} })

    /** 默认不刷新数据 */
    switch (modal) {
      case 'batch-import-modal': /** 批量导入 */
      case 'change-school-modal': /** 转学 */
      case 'inner-change-school-modal': /** 校际互转 */
      case 'change-depart-modal' /** 学部转换 */:
      case 'change-grade-modal': /** 跳留级 */
      case 'change-class-modal' /** 转班 */:
        this.fetch()
        break
      default:
        break
    }

    switch (modal) {
      case 'sms-modal' /** 发送短信窗口 */:
      case 'batch-import-modal' /** 批量导入 */:
        // 以上窗口不提示(窗口内部已处理)
        break
      default:
        this.$Message.success(`${this.$t('success')}`)
        break
    }
  }

  /** 窗口关闭事件处理函数 */
  private handleModalHidden() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }
}
