





























import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import { GradeSelect } from '@business-components/grade'
import { Student } from 'xuexin-vuex'
import { ActionType } from 'xuexin-vuex/src/constant'
import { Form, FormItem, Modal } from 'view-design'
import { Getter, Action } from 'vuex-class'
const components = { AppModal, Form, FormItem, GradeSelect }

type DataType = {
  list: string[]
  searches: Record<string, any>
}

type ModelData = {
  unitGradeID: number
}

@Component({ name: 'EditGradeModal', components })
export default class EditGradeModal extends Vue {
  @Model('input') private value!: boolean
  @Prop({ default: () => ({}) }) private readonly data!: DataType

  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly updateStatus!: Student.Getter.ListStatus
  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'edit-grade-modal'
  private model: ModelData = {
    unitGradeID: -1,
  }

  private visible = false
  private loading = true

  get rules() {
    return {
      unitGradeID: [
        {
          required: true,
          type: 'number',
          trigger: 'blur',
          message: '请选择就读年级',
        },
      ],
    }
  }
  get list() {
    return this.data.list || []
  }

  get searches() {
    return this.data.searches || {}
  }

  get unitID() {
    return this.searches.unitID
  }

  get termID() {
    return this.searches.termID
  }

  get updateParam() {
    const { unitGradeID } = this.model
    return {
      actionType: ActionType.UnitGrade,
      xuexinIDs: this.list,
      unitGradeID: Number(unitGradeID),
      unitID: this.unitID,
      termID: this.termID,
    }
  }

  get updating() {
    return this.updateStatus.updating === true
  }

  get updatingError() {
    return this.updateStatus.updatingError || null
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      /** 窗口没有打开不处理状态 */
      if (this.value === false || this.visible === false) {
        return void 0
      }

      if (this.updatingError !== null) {
        this.$Message.error(this.updatingError)
        return this.stopAction()
      }
      this.$Message.success('编辑成功')
      this.$emit('on-finish')
    }
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      return
    }
    this.$emit('on-visible-change', visible)
  }

  private handleOK() {
    this.form.validate(valid => {
      if (valid !== true) {
        return this.stopAction()
      }
      this.update(this.updateParam)
    })
  }

  private handleHidden() {
    this.form.resetFields()
    this.stopAction()
    this.$emit('on-hidden')
  }

  private stopAction() {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleInput() {
    this.$emit('on-input')
  }
}
