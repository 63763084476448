import Vue, { CreateElement } from 'vue'
import { Component, Model, Watch, Prop } from 'vue-property-decorator'
import { Session, Constant } from 'xuexin-vuex'
import Page from '@store/modules/page'
import { Button } from 'view-design'
import SearchItem from './search-item'
import SearchDropdown from './search-dropdown'
import i18n from './i18n'
import PageTyping from '../../../types/page'
import './dynamic-search.scss'

type CheckboxProp = {
  label: string
  value: string
  style: Record<string, any>
  disabled: boolean
}

type Flat = Record<string, boolean>

const components = {}
@Component({ name: 'DynamicSearch', components, i18n })
export default class DynamicSearch extends Vue {
  @Session.Getter('user') private readonly user!: Session.Getter.User
  @Page.Getter('searches')
  private readonly getSearches!: PageTyping.Getter.Searches
  @Page.Action('update')
  private readonly updateSearches!: PageTyping.Action.Update
  @Model('input', { default: () => ({}) })
  private readonly value!: PageTyping.SearchParameter
  @Prop({ type: Boolean, default: true }) private readonly isTermID?: boolean
  @Prop({
    type: Array,
    default: () => ['termID', 'parentUnitID', 'unitID'],
  })
  private readonly required!: string[]

  @Prop({ type: Array, default: () => [''] })
  private readonly multiple!: string[]

  private readonly prefixCls = 'dynamic-search'
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private selection: string[] = []
  private checkboxs: CheckboxProp[] = []
  private requiredSet: Record<string, boolean> = {}
  private multipleSet: Record<string, boolean> = {}

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get identityType() {
    return this.user?.identityType
  }

  get authCode() {
    return this.$route.meta.authCode
  }

  get searches(): PageTyping.Search[] {
    return this.getSearches(this.authCode)
  }

  @Watch('value', { immediate: true })
  watchValue(value: PageTyping.Parameter) {
    this.$set(this.$data, 'parameter', value || {})

    /** 当前用户不存在，啥也干不了。 */
    if (this.user === void 0 || this.user === null) {
      return void 0
    }

    /** 参数中不包含学期时，设置默认学期 */
    const { organID, termID, parentUnitID } = this.parameter
    if (
      !(Boolean(termID) || termID === 0) && // 未设置学期
      Boolean(this.user.extraMap?.currentTermID) // 且用户包含当前学期
    ) {
      const _termID = this.user.extraMap.currentTermID
      this.$set(this.parameter, 'termID', _termID)
      this.handleSearchItemChange('termID', _termID)
    }

    /** 当前用户身份为学校时，设置学校默认参数 */
    if (
      this.identityType === Constant.IdentityType.School && // 学校身份
      !(Boolean(parentUnitID) || parentUnitID === 0) // 无学校id
    ) {
      const _parentUnitID = this.user.parentUnitID
      this.$set(this.parameter, 'parentUnitID', _parentUnitID)
      this.handleSearchItemChange('parentUnitID', _parentUnitID)
    }
    // 当前用户身份为机构时，设置机构参数
    else if (
      this.identityType === Constant.IdentityType.Organization && // 当前为机构身份
      !(Boolean(organID) || organID === 0)
    ) {
      const _organID = this.user.organID
      this.$set(this.parameter, 'organID', _organID)
      this.handleSearchItemChange('organID', _organID)
    }
  }

  @Watch('searches', { immediate: true })
  watchSearches(searches: PageTyping.Search[]) {
    /** 当前已勾选的条件 */
    const selection: string[] = []
    /** 列数 */
    const columnCount = 5
    /** 行数 */
    const rowCount = Math.ceil(searches.length / columnCount) - 1
    /** 满排满列总数量 */
    const threshold = rowCount * columnCount
    /** 显示更多的复选框组数据 */
    const checkboxs = searches.map((item, index) => {
      /** 添加已勾选的列 */
      if (item.show !== 0) {
        selection.push(`${item.code}`)
      }

      /** 是否为行末 */
      const isEol = (index + 1) % columnCount === 0
      /** 是否溢出 */
      const isOverflow = index >= threshold

      /**
       * 标签特殊处理
       */
      let labelKey = item.code
      switch (this.authCode) {
        case '0502': // 年级管理
          switch (item.code) {
            case 'startTerm':
              labelKey = 'startTerm2'
              break
          }
          break
        case '0503': // 班级管理
          switch (item.code) {
            case 'unitGradeID':
              labelKey = 'gradeID'
              break
          }
        case '0505': // 分班管理
          switch (item.code) {
            case 'gradeID':
              labelKey = 'enrollInGrade'
              break
          }
          break
      }

      return {
        label: this.$t(`label.${labelKey}`),
        value: item.code,
        style: {
          width: '150px',
          marginRight: `0px`,
          // marginRight: `${isEol ? '0' : '10'}px`,
          marginBottom: `${isOverflow ? '0' : '10'}px`,
        },
        disabled: item.fixed === 1,
      }
    })
    /** 更新本地变量 */
    this.$set(this.$data, 'selection', selection)
    this.$set(this.$data, 'checkboxs', checkboxs)
  }

  @Watch('required', { immediate: true })
  watchRequired(required: string[], previous: string[]) {
    const requiredSet = required.reduce((requiredSet: any, item) => {
      if (!this.isTermID && item === 'termID') {
        return (requiredSet[item] = false), requiredSet
      }
      return (requiredSet[item] = true), requiredSet
    }, {})
    this.$set(this.$data, 'requiredSet', requiredSet)
  }

  @Watch('multiple', { immediate: true })
  watchMultiple(multiple: string[], previous: string[]) {
    const multipleSet = multiple.reduce((multipleSet: any, item) => {
      return (multipleSet[item] = true), multipleSet
    }, {})
    this.$set(this.$data, 'multipleSet', multipleSet)
  }

  created() {}

  render(createElement: CreateElement) {
    /** 创建搜索项 */
    const ItemList = this.searches.map(item => {
      /** 学校身份时，且当前条件为学校，强制不显示。 */
      if (this.identityType === 3 && item.code === 'parentUnitID') {
        return null
      }

      /**
       * 标签特殊处理
       */
      let labelKey = item.code
      switch (this.authCode) {
        case '0502': // 年级管理
          switch (item.code) {
            case 'startTerm':
              labelKey = 'startTerm2'
              break
          }
          break
        case '0503': // 班级管理
          switch (item.code) {
            case 'unitGradeID':
              labelKey = 'gradeID'
              break
          }
        case '0505': // 分班管理
          switch (item.code) {
            case 'gradeID':
              labelKey = 'enrollInGrade'
              break
          }
          break
      }
      return createElement(SearchItem, {
        props: {
          value: this.value[item.code],
          data: item,
          label: this.$t(`label.${labelKey}`),
          parameter: this.parameter,
          multiple: this.multipleSet[item.code] === true,
          required: this.requiredSet[item.code] === true,
          authCode: this.authCode,
        },
        on: { 'on-change': this.handleSearchItemChange },
      })
    })

    /** 下拉框 */
    const Dropdown = createElement(
      SearchDropdown,
      {
        props: {
          data: this.checkboxs,
          selection: this.selection,
          label: this.$t('text.settings'),
        },
        on: {
          'on-change': this.handleSearchDropdownChange,
        },
      },
      []
    )

    /** 搜索按钮 */
    const SearchButton = createElement(
      Button,
      {
        props: { type: 'primary' },
        on: { click: this.handleSearch },
      },
      [`${this.$t('text.search')}`]
    )

    const ActionContainer = createElement(
      'div',
      {
        class: `search-item ${this.prefixCls}_actions`,
      },
      [Dropdown, SearchButton]
    )

    return createElement(
      'div',
      {
        class: this.classes,
      },
      [ItemList, ActionContainer]
    )
  }

  mounted() {
    if (this.authCode === '0510') {
      this.$set(this.parameter, 'dateType', 1)
      this.$set(this.parameter, 'simpleDate', new Date())
      this.$set(this.parameter, 'doubleDate', [new Date(), new Date()])
    }
  }

  private handleSearchItemChange(
    code: keyof PageTyping.SearchParameter,
    value: unknown
  ) {
    // console.log(
    //   `${this.$options.name}.handleSearchItemChange(code: ${code}, value: ${value})`
    // )

    this.$set(this.$data.parameter, code, value)
    this.$emit('input', this.parameter)
    this.$emit('on-change', this.parameter)
  }

  private handleSearchDropdownChange(selection: string[]) {
    // console.log(`${this.$options.name}.handleSearchDropdownChange`)
    /** 取消了某个搜索条件 */
    if (selection.length < this.selection.length) {
      /** 查找到被取消的搜索条件 */
      // const length = selection.length
      // const item = this.selection.find((code, index) => {
      //   return index >= length || code !== selection[index]
      // })

      const items = this.selection.filter(code => {
        return !selection.includes(code)
      })

      /** 查找到被取消的条件且非必选时，清空条件值 */
      // if (item !== void 0 && this.requiredSet[item] !== true) {
      //   this.$set(this.$data.parameter, item, void 0)
      // }

      if (items !== void 0 && items.length > 0) {
        items.forEach(item => {
          this.$set(this.$data.parameter, item, void 0)
        })
      }
    }

    const flat = selection.reduce<Flat>((flat, item) => {
      return (flat[item] = true), flat
    }, {})

    const searches = this.searches.map(item => {
      return Object.assign({}, item, {
        show: flat[item.code] === true ? 1 : 0,
      })
    })

    this.updateSearches({ authCode: this.authCode, searches })
  }

  private handleSearch() {
    this.$emit('on-search', Object.assign({}, this.parameter))
  }
}
