






































import Vue from 'vue'
import { Component, Prop, Watch, Model } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import AppTable from '@components/app-table'
import { Tooltip } from 'view-design'
import { Workflow } from 'xuexin-vuex'
const components = { AppTable, AppModal, Tooltip }
const i18n = {
  messages: {
    'zh-CN': {
      title: '查看审批流',
      columns: {
        index: '序号',
        'approval-node': '审批节点',
        'operator-name': '操作人',
        'operator-status': '操作状态',
        'operate-date': '操作时间',
        opinion: '审批意见',
        remark: '备注',
      },
    },
    'en-US': {},
  },
}

@Component({ name: 'WorkflowModal', components, i18n })
export default class WorkflowModal extends Vue {
  @Model('input') private readonly value!: boolean
  // 工作流id
  @Prop({ type: Number }) private readonly workflowID!: number
  @Workflow.Getter('list')
  private readonly list!: Workflow.Getter.List
  @Workflow.Getter('listStatus')
  private readonly listStatus!: Workflow.Getter.ListStatus
  @Workflow.Action('fetchList')
  private readonly fetchList!: Workflow.Action.FetchList
  private readonly prefixCls = 'workflow-modal'
  private visible = false

  get getListStatus() {
    return this.listStatus
  }

  //表头配置
  get columns() {
    return [
      {
        type: 'index',
        title: this.$t('columns.index'),
        align: 'center',
        width: 80,
      },
      { title: this.$t('columns.approval-node'), key: 'nodeName' },
      { title: this.$t('columns.operator-name'), key: 'operatorName' },
      { title: this.$t('columns.operator-status'), key: 'operatorStatus' },
      {
        title: this.$t('columns.operate-date'),
        width: 170,
        slot: 'operateDate',
      },
      { title: this.$t('columns.opinion'), key: 'statusName' },
      {
        title: this.$t('columns.remark'),
        key: 'reMark',
        tooltip: true,
        slot: 'remarkAction',
      },
    ]
  }
  // 监听弹窗显示状态
  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.visible = value
  }
  // 监听查询状态
  @Watch('getListStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.getListStatus.fetchingError !== null) {
        this.$Message.error(this.getListStatus.fetchingError)
      }
    }
  }
  // 弹窗展示状态改变事件
  private handleVisibleChange(visible: boolean): void {
    if (visible) {
      this.fetchList({ workflowID: this.workflowID })
    }
    this.$emit('on-visible-change', visible)
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleHidden() {
    this.$emit('on-hidden')
  }
}
