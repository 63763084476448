

















































































import Vue from 'vue'
import { Component, Model, Prop, Ref, Watch } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import AppModal from '@components/app-modal'
import { Form, FormItem, Input, Tabs, TabPane, Button } from 'view-design'
import { SemesterSelect } from '@business-components/semester'
import { SchoolSelect } from '@business-components/school'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { GradeSelect } from '@business-components/grade'
import Download from '@store/modules/download'
import PageTyping from '../../../../types/page'
import { messages } from '@layouts/view-layout/i18n'
import { Action } from 'vuex-class'

/** 请求类型枚举 */
const ActionType = Constant.ActionType
const components = {
  AppModal,
  Form,
  FormItem,
  Input,
  Tabs,
  TabPane,
  SemesterSelect,
  SchoolSelect,
  SchoolPhaseSelect,
  GradeSelect,
  Button,
}
@Component({ name: 'DownloadModal', components })
export default class DownloadModal extends Vue {
  @Ref('form') private readonly form!: any
  @Download.Action('fetch') private readonly fetchUrl!: Download.Action.Fetch
  @Download.Getter('itemStatus')
  private readonly getItemStatus!: Download.Getter.ItemStatus
  @Download.Getter('item') private readonly getUrlItem!: Download.Getter.Item
  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Partial<
    PageTyping.SearchParameter
  >
  private visible = false
  private loading = false

  public model: any = {}

  get rules() {
    return {
      termID: [
        {
          required: true,
          message: '请选择学期',
        },
      ],
      parentUnitID: [
        {
          required: true,
          message: '请选择学校',
        },
      ],
      unitID: [
        {
          required: true,
          message: '请选择校区',
        },
      ],
      phaseID: [
        {
          required: true,
          message: '请选择学段',
        },
      ],
      unitGradeID: [
        {
          required: true,
          message: '请选择学校年级',
        },
      ],
    }
  }

  @Watch('value')
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
    if (value) {
      this.$set(this.model, 'termID', this.data.termID)
      this.$set(this.model, 'parentUnitID', this.data.parentUnitID)
      this.$set(this.model, 'unitID', this.data.unitID)
    }
  }

  private handleDownload() {
    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        return
      }
      this.loading = true
      /** 向后台发送请求 */
      this.fetchUrl({
        actionType: ActionType.InClass,
        url: 'template',
        searches: {
          ...this.model,
          unitGradeID: this.model.unitGradeID,
        },
      })
        .then(res => {
          if (res.status && res?.result) {
            location.href = `${res?.result}`
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.$Message.error(err.message || err)
        })
    })
  }

  private handleHidden() {
    this.$emit('on-hidden')
    this.$set(this.model, 'phaseID', void 0)
    this.$set(this.model, 'unitGradeID', void 0)
    this.form.resetFields()
  }
}
