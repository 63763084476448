import Vue, { VNodeData } from 'vue'
export default Vue.extend({
  name: 'PageBody',
  functional: true,
  render(createElement, context) {
    const data: VNodeData = {
      class: ['page-body'].concat(context.data.class),
      style: Object.assign({}, context.data.style),
    }

    return createElement('div', data, context.children)
  },
})
