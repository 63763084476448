import Vue, { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Page } from 'view-design'
import './pagination.scss'

const components = { Page }
@Component({ name: 'AppPagination', components, inheritAttrs: false })
export default class AppPagination extends Vue {
  @Prop({ type: Boolean, default: true }) private readonly showSizer!: boolean
  private readonly prefixCls = 'app-pagination'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get props() {
    return Object.assign({ 'show-total': true }, this.$props, this.$attrs)
  }

  render(createElement: CreateElement) {
    return createElement('div', { class: this.classes }, [
      createElement('Page', {
        props: this.props,
        on: this.$listeners,
        scopedSlots: this.$scopedSlots,
      }),
    ])
  }

  getHeight(): number {
    const element = this.$el as HTMLElement
    return element.offsetHeight
  }
}
