import { Grade } from 'types'
import { namespace as BindingNamespace } from 'vuex-class'
import Ajax from 'xuexin-vuex/src/ajax'
import Api from 'xuexin-vuex/src/api'
import omit from 'lodash.omit'
import {
  handlers,
  getListState,
  mergeToCompositeState,
} from 'xuexin-vuex/src/util'
import { ActionType } from 'xuexin-vuex/src/constant'
import filterData from '../../../filterData.json'
const fileterMap: Record<string | number, boolean> = filterData.unitIDs

export const namespace = '@xuexin-vuex/grade'

export const types = {
  CREATE_REQUEST: 'CREATE_REQUEST',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_FAILURE: 'CREATE_FAILURE',

  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',

  // 年级详情
  FETCH_ITEM_REQUEST: 'FETCH_ITEM_REQUEST',
  FETCH_ITEM_SUCCESS: 'FETCH_ITEM_SUCCESS',
  FETCH_ITEM_FAILURE: 'FETCH_ITEM_FAILURE',

  // 查询年级列表
  FETCH_LIST_REQUEST: 'FETCH_LIST_REQUEST',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
  FETCH_LIST_FAILURE: 'FETCH_LIST_FAILURE',

  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',
}

function getListKey(parameter: Grade.Parameter) {
  const {
    termID = -1,
    unitID = -1, //校区id
    phaseID = -1, //学段id
    schoolDepartID = -1, //学部id
    actionType = -1, // 区分基础还是学校年级
  } = parameter

  return `act:${actionType}&te:${termID}&u:${unitID}&sp:${phaseID}&sd:${schoolDepartID}`
}

const state: Grade.State = {
  list: {},
  items: {},
}

const actions: Grade.Actions = {
  create(context, payload) {
    return Ajax(context, {
      type: types.CREATE_REQUEST,
      payload,
      api: `post ${Api.student}/unitGrades`,
      parameter: payload,
      success: types.CREATE_SUCCESS,
      failure: types.CREATE_FAILURE,
    })
  },
  delete(context, payload) {
    return Ajax(context, {
      type: types.DELETE_REQUEST,
      payload,
      api: `delete ${Api.student}/unitGrades/${payload.unitGradeID}`,
      success: types.DELETE_SUCCESS,
      failure: types.DELETE_FAILURE,
    })
  },
  update(context, payload) {
    const { gradeID, ...params } = payload
    return Ajax(context, {
      type: types.UPDATE_REQUEST,
      payload,
      api: `put ${Api.student}/unitGrades`,
      parameter: params,
      success: types.UPDATE_SUCCESS,
      failure: types.UPDATE_FAILURE,
    })
  },
  fetchItem(context, payload) {
    return Ajax(context, {
      type: types.FETCH_ITEM_REQUEST,
      payload,
      api: `get ${Api.student}/unitGrades/${payload.unitGradeID}`,
      parameter: payload,
      success: types.FETCH_ITEM_SUCCESS,
      failure: types.FETCH_ITEM_FAILURE,
    })
  },
  fetchList(context, payload) {
    const {
      searches = {
        unitID: 0,
        direction: 0,
        schoolDepartID: 0,
        transfer: false,
      },
    } = payload
    let url = ''
    if (
      searches.transfer &&
      fileterMap[`${searches.unitID}`] &&
      (searches.direction === 2 || `${searches.schoolDepartID}` === '4')
    ) {
      url = `post ` + __STUDENT_INTL_API__ + `/unitGrades/list`
    } else {
      url = `post ` + Api.student + `/unitGrades/list`
    }

    let api = ''
    switch (payload.actionType) {
      case ActionType.BaseGrade:
        api = `get ${Api.library}/base/grades`
        break
      default:
        api = url
        break
    }
    return Ajax(context, {
      type: types.FETCH_LIST_REQUEST,
      payload,
      api: api,
      parameter: payload,
      success: types.FETCH_LIST_SUCCESS,
      failure: types.FETCH_LIST_FAILURE,
    })
  },
}

const mutations: Grade.Mutations = {
  [types.CREATE_REQUEST](state, { payload }) {
    const listState = getListState(state, getListKey(payload))
    handlers.request(listState, 'creating')
  },
  [types.CREATE_SUCCESS](state, { payload, parameter, result }) {
    const listState = getListState(state, getListKey(payload))
    handlers.itemSuccess(state, result, 'creating', parameter)
    handlers.success(listState, 'creating')
  },
  [types.CREATE_FAILURE](state, { payload, message }) {
    const listState = getListState(state, getListKey(payload))
    handlers.failure(listState, 'creating', message)
  },

  [types.DELETE_REQUEST](state, { payload }) {
    handlers.itemRequest(state, payload.unitGradeID, 'deleting')
  },
  [types.DELETE_SUCCESS](state, { payload }) {
    handlers.itemSuccess(state, payload.unitGradeID, 'deleting')
  },
  [types.DELETE_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, payload.unitGradeID, 'deleting', message)
  },

  [types.UPDATE_REQUEST](state, { payload }) {
    handlers.itemRequest(state, payload.unitGradeID, 'updating')
  },
  [types.UPDATE_SUCCESS](state, { payload, parameter }) {
    handlers.itemSuccess(state, payload.unitGradeID, 'updating', parameter)
  },
  [types.UPDATE_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, payload.unitGradeID, 'updating', message)
  },

  [types.FETCH_ITEM_REQUEST](state, { payload }) {
    handlers.itemRequest(state, payload.unitGradeID, 'fetching')
  },
  [types.FETCH_ITEM_SUCCESS](state, { payload, result }) {
    handlers.itemSuccess(state, payload.unitGradeID, 'fetching', result)
  },
  [types.FETCH_ITEM_FAILURE](state, { payload, message }) {
    handlers.itemFailure(state, payload.unitGradeID, 'fetching', message)
  },

  [types.FETCH_LIST_REQUEST](state, { payload }) {
    const listState = getListState(
      state,
      getListKey(
        payload.actionType === ActionType.BaseGrade ? payload : payload.searches
      )
    )
    handlers.request(listState, 'fetching')
  },
  [types.FETCH_LIST_SUCCESS](state, { payload, result, total }) {
    const listKey = getListKey(
      payload.actionType === ActionType.BaseGrade ? payload : payload.searches
    )
    // prettier-ignore
    mergeToCompositeState(state, listKey, payload.actionType ===ActionType.BaseGrade ?'gradeID':'unitGradeID', result, total, (item: Grade.Entity) => {
      item.fullGradeName = item.unitGradeName || null
      if (item.phaseID !== 1) {
        item.fullGradeName = Boolean(item.gradeName)&& Boolean(item.unitGradeName)
          ? `${item.unitGradeName}(${item.gradeName})`
          : item.gradeName
      }
      return item
    })
    const listState = getListState(state, listKey)
    handlers.success(listState, 'fetching')
  },
  [types.FETCH_LIST_FAILURE](state, { payload, message }) {
    const listState = getListState(
      state,
      getListKey(
        payload.actionType === ActionType.BaseGrade ? payload : payload.searches
      )
    )
    handlers.failure(listState, 'fetching', message)
  },
}

const getters: Grade.Getters = {
  itemStatus(state) {
    return id => omit(state.items[`${id}`], ['data'])
  },
  item(state) {
    return id => state.items[`${id}`]?.data
  },
  listStatus(state) {
    return parameter => omit(state.list[getListKey(parameter)], ['data'])
  },
  list(state, getters) {
    return parameter => {
      const listState = state.list[getListKey(parameter)] || { data: [] }
      return listState.data.map(id => getters.item(id)!)
    }
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
