import { Constant } from 'xuexin-vuex'

export type PoliticalStatus = Constant.PoliticalStatus
export const PoliticalStatus = Constant.PoliticalStatus

export const PoliticalStatusList = [
  PoliticalStatus.Person,
  PoliticalStatus.PartyMember,
  PoliticalStatus.Party,
]
//调整后[{name:“群众”，id:1},{name:“党员”，id:3},{name:“其他”，id:2}]
export const messages: Messages = {
  'zh-CN': {
    [PoliticalStatus.Person]: '群众',
    [PoliticalStatus.Party]: '其他',
    [PoliticalStatus.PartyMember]: '中共党员',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [PoliticalStatus.Person]: '群众',
    [PoliticalStatus.Party]: '其他',
    [PoliticalStatus.PartyMember]: '中共党员',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
