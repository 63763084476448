





















































































































































































































import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import i18n from './i18n'
import { Student, Common, Constant } from 'xuexin-vuex'
import AppModal from '@components/app-modal'
import AppSelect from '@components/app-select'
import AppRadioGroup from '@components/app-radio-group'
import {
  Button,
  Form,
  FormItem,
  Input,
  Upload,
  Modal,
  Icon,
  DatePicker,
  Row,
  Col,
} from 'view-design'
import { SemesterSelect } from '@business-components/semester'
import { ActionType, CommonType } from 'xuexin-vuex/src/constant'
const components = {
  AppModal,
  Button,
  Form,
  FormItem,
  Input,
  AppSelect,
  AppRadioGroup,
  Upload,
  Icon,
  DatePicker,
  Row,
  Col,
  SemesterSelect,
}

type DataType = {
  student: Student.Entity
  unitID: number
  organID: number
  termID: number
}

type Model = {
  name: string
  operateDate: number
  transfeReason: number
  /** 流失  1: 计入 0： 不计入*/
  checkLost: number
  /** 转至地区 1: 本地 2: 外地 */
  transfeArea: number
  area: number
  transfeSchool: string
  school: string
  note: string
  files: any
  transferDate: any
  mealEndDate: any
  stayEndDate: any
  parentBankCard: string
  isMeal: number
  isStay: number
  isRefund: number
  bankOfDeposit: string
  payee: string
  transferReasonRemark: string
}

const commonType = Constant.CommonType.TransferReason

@Component({ name: 'TransferSchoolModal', components, i18n })
export default class TransferSchoolModal extends Vue {
  @Model('input') private value!: any
  @Prop({ default: () => {} }) private readonly data!: DataType
  @Prop({ type: Boolean, default: false }) private readonly file!: boolean

  @Common.Action('fetch') private readonly fetchReason!: Common.Action.Fetch
  @Common.Getter('status')
  private readonly reasonStatus!: Common.Getter.Status
  @Common.Getter('list') private readonly reasonList!: Common.Getter.List
  @Student.Action('update')
  private readonly update!: Student.Action.Update
  @Student.Getter('item') private readonly getItem!: Student.Getter.Item
  @Student.Getter('itemStatus')
  private readonly getItemStatus!: Student.Getter.ItemStatus

  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'change-school-modal'

  private model: Partial<Model> = {
    operateDate: new Date().getTime(),
    checkLost: void 0,
    files: [],
    isMeal: 1,
    isStay: 1,
    isRefund: 1,
    transferReasonRemark: '',
    mealEndDate: [],
    stayEndDate: [],
  }
  private filesBackups: any = []
  private isShowSchool = true
  private visible = false
  private loading = true

  get isNoList() {
    return [
      { label: this.$t('text.yes'), value: 1 },
      { label: this.$t('text.no'), value: 0 },
    ]
  }

  get rules() {
    return {
      //prettier-ignore
      transferDate: [
        {
          required: true,
          type: 'date',
          trigger: 'change',
          message: this.$t('valid.date'),
        },
      ],
      //prettier-ignore
      mealEndDate: [
        {
          required: true,
          type: 'array',
          trigger: 'change',
          validator: this.validDate,
        },
      ],
      stayEndDate: [
        {
          required: true,
          type: 'array',
          trigger: 'change',
          validator: this.validDate,
        },
      ],
      payee: [
        {
          required: false,
          type: 'string',
        },
      ],
      parentBankCard: [
        {
          required: false,
          type: 'string',
        },
      ],
      bankOfDeposit: [
        {
          required: false,
          type: 'string',
        },
      ],
      // prettier-ignore
      transfeReason: [{required: true, type: 'number', trigger: 'change',message: this.$t('valid.reason')}],
      //prettier-ignore
      // checkLost: [{ required: true, type: 'number', trigger: 'change', message: this.$t('valid.flow')}],
      //prettier-ignore
      transfeArea: [{ required: true, type: 'number', trigger: 'change', message: this.$t('valid.area')}],
      //prettier-ignore
      transfeSchool: [{required: true, type: 'string', trigger: 'blur', message: this.$t('valid.school') }],
      checkLost: [
        {
          required: true,
          type: 'number',
          trigger: 'blur',
          message: '请选择是否计入流失率考核',
        },
      ],
      transferReasonRemark: [
        {
          required: true,
          type: 'string',
          trigger: 'blur',
          message: '请填写转学原因',
        },
        {
          min: 5,
          trigger: 'blur',
          message: '转学原因不少于5个字',
        },
      ],
      files: [
        {
          type: 'array',
          trigger: 'blur',
          validator: this.validFile,
        },
      ],
    }
  }

  get outflows() {
    return [
      { label: this.$t('button.do-flow'), id: 1 },
      { label: this.$t('button.un-flow'), id: 0 },
    ]
  }

  get areas() {
    return [
      { label: this.$t('button.uncle'), id: 1 },
      { label: this.$t('button.out-uncle'), id: 2 },
    ]
  }

  get studentID() {
    return this.data?.student?.studentID
  }

  get configLostShow() {
    return this.data?.student?.configLostShow
  }

  get termID() {
    return this.data.termID
  }

  get rStatus() {
    return this.reasonStatus(commonType)
  }

  get fetching() {
    return this.rStatus.fetching === true
  }

  get fetchingError() {
    return this.rStatus.fetchingError || null
  }

  get list() {
    return this.reasonList(commonType)
  }

  get status() {
    return this.getItemStatus(this.studentID)
  }

  get updating() {
    return this.status.updating === true
  }

  get updatingError() {
    return this.status.updatingError || null
  }
  @Watch('model.checkLost')
  private watchcheckLost(value: number) {
    this.model.files = this.filesBackups
  }
  @Watch('value', { immediate: true })
  private watchVisible(value: boolean) {
    this.$set(this.$data, 'visible', value)
    if (value) {
      this.form.resetFields()
      this.model = {
        operateDate: new Date().getTime(),
        files: [],
        isMeal: 1,
        isStay: 1,
        isRefund: 1,
      }
    }
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === true && previous === false) {
      if (this.fetchingError !== null) {
        this.$Message.error(this.fetchingError)
        return
      }
    }
  }

  @Watch('updating', { immediate: true })
  private watchUpdating(updating: boolean, previous: boolean) {
    /** 窗口未处理打开状态时，不处理状态 */
    if (this.value === false || this.visible === false) {
      return void 0
    }
    if (updating === false && previous === true) {
      /** 更新失败 */
      if (this.updatingError !== null) {
        this.$Message.error(this.updatingError)
        return this.stopAction()
      }
      this.form.resetFields()

      this.$emit('on-finish')
    }
  }

  // @Watch('model.transfeReason')
  // private watchReason() {
  //   // 验证有问题，故修改为true qiaoyayun20210422
  //   // if (this.model.transfeReason === 1) {
  //   //   this.isShowSchool = true
  //   // } else {
  //   //   this.isShowSchool = false
  //   // }
  // }

  private stopAction(): void {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleOK(): void {
    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        return this.stopAction()
      }

      let tempLost = 1
      /** 处理文件 */
      const {
        files,
        operateDate,
        transfeReason,
        checkLost,
        transfeArea,
        area,
        transfeSchool,
        school,
        note,
        transferDate,
        mealEndDate,
        stayEndDate,
        parentBankCard,
        isMeal,
        isStay,
        isRefund,
        bankOfDeposit,
        payee,
        transferReasonRemark = '',
      } = this.model

      const formData = new FormData()
      if (this.filesBackups.length > 0) {
        for (let i = 0; i < this.filesBackups.length; i++) {
          formData.append('files[]', this.filesBackups[i])
        }
      }

      if (checkLost !== 0 && checkLost !== 1) {
        formData.append('checkLost', `${tempLost}`)
      } else {
        formData.append('checkLost', `${checkLost}`)
      }

      if (this.isShowSchool === true) {
        formData.append('transfeSchool', `${transfeSchool}`)
        formData.append('transfeArea', `${transfeArea}`)
      } else {
        if (Boolean(school) === true) {
          formData.append('transfeSchool', `${school}`)
        }
        if (Boolean(area) === true) {
          formData.append('transfeArea', `${area}`)
        }
      }

      if (Boolean(note) === true) {
        formData.append('note', `${note}`)
      }

      formData.append('transferReasonRemark', transferReasonRemark)
      formData.append('xuexinID', this.studentID)
      formData.append('termID', `${this.termID}`)
      formData.append('operateDate', `${operateDate}`)
      formData.append('transfeReason', `${transfeReason}`)
      formData.append('transferDate', `${new Date(transferDate).getTime()}`)
      formData.append('isRefund', `${isRefund}`)
      formData.append('unitID', `${this.data.unitID}`)
      if (isRefund) {
        if (isMeal) {
          formData.append(
            'mealEndDate',
            `${new Date(mealEndDate[0]).getTime()},${new Date(
              mealEndDate[1]
            ).getTime()}`
          )
        }
        if (isStay) {
          formData.append(
            'stayEndDate',
            `${new Date(stayEndDate[0]).getTime()},${new Date(
              stayEndDate[1]
            ).getTime()}`
          )
        }

        formData.append('parentBankCard', `${parentBankCard}`)
        formData.append('bankOfDeposit', `${bankOfDeposit}`)
        formData.append('payee', `${payee}`)
      }

      this.update({
        files: formData,
        studentID: this.studentID,
        actionType: ActionType.Transfer,
      })
    })
  }

  private handleVisibleChange(visible: boolean): void {
    if (visible === true) {
      /** 获取学生姓名 */ this.studentID
      this.$set(this.$data.model, 'name', this.data.student.studentName)
      /** 获取转学原因列表 */
      this.fetchReason({
        commonType: commonType,
      })
    }
  }

  private handleHidden(): void {
    this.form.resetFields()
    this.stopAction()
    this.$emit('on-hidden')
  }

  /** 文件上传 */
  private handleUpload(file: any) {
    if (file.size > 10 * 1024 * 1024) {
      this.$Message.warning(`${this.$t('text.desc')}`)
      return false
    }
    /** 展示文件列表 */
    this.model.files = [file]
    this.filesBackups = [file]
    return false
  }

  /** 删除文件 */
  private handleRemove(index: number) {
    this.model.files.splice(index, 1)
    this.filesBackups = []
  }

  private validDate(rule: FormRule, value: any, callback: FormRuleCallback) {
    if (value === void 0 || value === null || value[0] === '') {
      return callback(`${this.$t('valid.date')}`)
    }

    callback()
  }

  private validCard(
    rule: FormRule,
    value: string,
    callback: FormRuleCallback
  ) {
    /**判断不为空 */
    if (value === void 0 || value === null || value === '') {
      return callback(`${this.$t('valid.card')}`)
    }

    /**判断是否为纯数字 */
    const reg = /^[0-9]*$/
    let noReg = 0

    value.split('').forEach(i => {
      if (!reg.test(i)) noReg = 1
      return
    })

    if (noReg === 1) return callback(`${this.$t('valid.card2')}`)

    callback()
  }

  private validFile(rule: any, value: any, callback: any) {
    if (
      (value === void 0 ||
        value === '' ||
        value === null ||
        value.length === 0) &&
      this.model.checkLost !== 1
    ) {
      return callback('请选择附件')
    }

    return callback()
  }
}
