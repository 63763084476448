










































































import Vue from 'vue'
import { Component, Prop, Mixins, Watch, Ref } from 'vue-property-decorator'
import PagePropsMixins from '@mixins/page-props-mixins'
import { TableColumn } from 'view-design'

import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'
import HomeEcharts from '../components/home-echarts'
import AppTable from '@components/app-table'
import AppCheckboxGroup from '@components/app-checkbox-group'

import i18n from './i18n'
import Export from '@store/modules/export'
import ExportTyping from '../../../../types/export'
import PageTyping from '../../../../types/page'
import AssessLossRate from '@store/modules/assess-loss-rate'
import ActualLossRate from '@store/modules/actual-loss-rate'
import AssessLossRateType from 'types/assess-loss-rate'
import { setReportData, spanChildMethod } from '@util/report'
import getPageColumns from '@components/report-toolbar/columns'

const components = {
  ViewLayout,
  ReportSearch,
  ReportToolbar,
  AppTable,
  HomeEcharts,
  AppCheckboxGroup,
}
@Component({ name: 'AssessLossRatePage', components, i18n })
export default class AssessLossRatePage extends Mixins(PagePropsMixins) {
  @AssessLossRate.Action('fetchChart') private readonly fetchChart!: any
  @AssessLossRate.Action('fetch') private readonly fetchTable!: any
  @ActualLossRate.Action('export') private readonly export!: any
  @AssessLossRate.Getter('list')
  private readonly getList!: AssessLossRateType.Getter.List
  @AssessLossRate.Getter('listStatus')
  private readonly listStatus!: AssessLossRateType.Getter.ListStatus
  @AssessLossRate.Getter('chartStatus')
  private readonly chartStatus!: AssessLossRateType.Getter.ChartStatus
  @AssessLossRate.Getter('chartItem')
  private readonly getChartItem!: AssessLossRateType.Getter.ChartItem

  private readonly prefixCls = 'assess-loss-rate-page'
  /** 上下学期 */
  private termType = [0, 1]
  private termTypeValue = [
    {
      value: 0,
      label: '上学期',
    },
    {
      value: 1,
      label: '下学期',
    },
  ]
  private parameter: any = {}
  private searchParameter: Partial<PageTyping.SearchParameter> = {}

  private tParameter: any = { countType: 0 }
  private defaultData: any = []
  private rowspans: any = {} //合并行列标记对象{key1：number[]，key2：number[]} key: 合并项数对应的列头(parentUnitName,manageTypeName……), 'number[]':当前列中每个单元格应合并的行数
  private sumKey: string[] = [] //选中显示的列头集合
  private inventedDataIndexArr: any = [] //小记行索引和项数{index:小记行在列表中的索引，type:小记几项}

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  // 列表加载状态
  get chartLoading() {
    return this.getChartStatus.fetching
  }
  get noDataText() {
    if (Boolean(this.parameter.termID) === false) {
      return this.$t('message.noParameter') as string
    }
    return this.$t('message.noDataText') as string
  }

  // 表头
  get tableColumns(): any {
    return getPageColumns('AssessLossRate')
      .map((item: any) => {
        return {
          title: item.title,
          key: item.code,
          width: 100,
          fixed: 'left',
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat([
        {
          title: '上学期',
          align: 'center',
          children: [
            {
              title: '进班点名人数',
              key: 'lastCallNum',
              className: 'text-align-left',
            },
            {
              title: '转出人数',
              key: 'lastOutNum',
              className: 'text-align-left',
            },
            {
              title: '转入人数',
              key: 'lastInNum',
              className: 'text-align-left',
            },
            {
              title: '流失率',
              key: 'lastLostRate',
              className: 'text-align-left',
            },
          ],
        },
        {
          title: '下学期',
          align: 'center',
          children: [
            {
              title: '进班点名人数',
              key: 'nextCallNum',
              className: 'text-align-left',
            },
            {
              title: '转出人数',
              key: 'nextOutNum',
              className: 'text-align-left',
            },
            {
              title: '转入人数',
              key: 'nextInNum',
              className: 'text-align-left',
            },
            {
              title: '流失率',
              key: 'nextLostRate',
              className: 'text-align-left',
            },
          ],
        },
        {
          title: '全学年',
          align: 'center',
          children: [
            {
              title: '流失率',
              key: 'yearLostRate',
              className: 'text-align-left',
            },
          ],
        },
      ])
  }

  get dataSources() {
    return this.getList()
  }
  get chartInfo(): any {
    return this.getChartItem()
  }
  get chartData() {
    if (this.chartInfo['organ-lost'] !== void 0) {
      return {
        series: this.chartInfo['organ-lost'].series,
        category: this.chartInfo['organ-lost'].category,
      }
    } else if (this.chartInfo['unit-lost'] !== void 0) {
      return {
        series: this.chartInfo['unit-lost'].series,
        category: this.chartInfo['unit-lost'].category,
      }
    } else {
      return {
        series: [],
        category: [],
      }
    }
  }

  // 获取列表请求状态
  get getListStatus() {
    return this.listStatus()
  }
  // 获取图表请求状态
  get getChartStatus() {
    return this.chartStatus()
  }

  get lostStatisLegend(): string[] {
    return ['学生人数', '转出人数', '流失率']
  }
  // 监听加载状态
  @Watch('getListStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.getListStatus.fetchingError !== null &&
        this.getListStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.fetchingError)
        return
      }

      let result = setReportData({
        list: this.dataSources,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
        countColumn: [
          'lastCallNum',
          'lastOutNum',
          'lastInNum',
          'nextCallNum',
          'nextOutNum',
          'nextInNum',
        ],
        percentageColumn: ['lastLostRate', 'nextLostRate', 'yearLostRate'],
      })
      this.defaultData = result.dataArr
      this.inventedDataIndexArr = result.indexArr
      this.rowspans = result.rowspans
    }
  }

  @Watch('termType', { immediate: true })
  private watchValue(value: number[]) {
    this.fetchChart(
      Object.assign({}, this.searchParameter, {
        termType: this.termType,
        schoolYear: 2020,
      })
    )
  }
  get fetching() {
    return this.getListStatus.fetching
  }
  created() {
    this.sumKey = getPageColumns('AssessLossRate').map((item: any) => {
      return item.code
    })
  }
  mounted() {}

  private handleStatisticalForm(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }

  // 合并行列方法
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }
  // 列表列头发生变化
  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.fetchTable(
      Object.assign({}, this.searchParameter, {
        columns: this.sumKey,
        countType: this.tParameter.countType,
      })
    )
  }
  // 小计行的样式
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      return 'ivu-table-summary-row'
    }
  }

  private fetch() {
    this.fetchChart(
      Object.assign({}, this.searchParameter, {
        termType: this.termType,
      })
    )

    this.fetchTable(
      Object.assign({}, this.searchParameter, this.tParameter, {
        columns: this.sumKey,
      })
    )
  }
  private handleExport(parameter: any) {
    this.export(
      Object.assign({}, parameter, {
        columns: this.sumKey,
        countType: this.tParameter.countType,
        exportType: 9,
      })
    ).then((res: any) => {
      if (res.result) {
        window.location.href = `${res.result}`
      }
    })
  }
  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    if (Boolean(parameter.termID) === false) {
      return this.$Message.warning(`${this.$t('message.noTermID')}`)
    }
    this.$set(this.$data, 'searchParameter', parameter)
    this.fetch()
  }
}
