const NumFormate = (str: string): string => {
  if (str && str.length > 4) {
    // 保留字符串的前部分，并将后四位替换为*
    const maskedStr = str.slice(0, -4) + '****';
    return maskedStr;
  }
  return str;
};

export default NumFormate;
