export default {
  messages: {
    'zh-CN': {
      label: {
        termID: '学期',
        unitName: '学校',
        unitID: '校区',
        schoolDepartID: '学部',
        phaseID: '学段',
        unitGradeID: '学校年级',
        classID: '班级',
        manageTypeID: '报读类型',
        classTypeID: '班级类型',
        unitClassTypeID: '校本班型',
        studentMark: '学生标记',
        researchField: '学业方向',
        studentStatus: '学生状态',
      },
      text: {
        columns: '显示列',
      },
    },
    'en-US': {},
  },
}
