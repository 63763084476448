// http://localhost:3000/students/graduation/2019202002/directions?ids=7855650

export const messages: Messages = {
  'zh-CN': {
    retry: '重试',
    loading: '加载中...',
    backtrack: '返回',
    save: '保存',
    success: '操作成功',
    columns: {
      num: '序号',
      className: '毕业班级',
      studentName: '学生姓名',
      gender: '性别',
      direction: '毕业去向',
    },
  },
  'en-US': {
    loading: '加载中...',
    backtrack: '返回',
    save: '保存',
    success: '操作成功',
    columns: {
      num: '序号',
      className: '毕业班级',
      studentName: '学生姓名',
      gender: '性别',
      direction: '毕业去向',
    },
  },
}

export default { messages }
