
































import Vue from 'vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import PagePropsMixins from '@mixins/page-props-mixins'

import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'
import AppTable from '@components/app-table'
import AppSelect from '@components/app-select'
import i18n from './i18n'
import { setReportData, spanChildMethod } from '@util/report'
import LostReport from '@store/modules/lost-report'
import getPageColumns from '@components/report-toolbar/columns'

const components = {
  ViewLayout,
  ReportSearch,
  ReportToolbar,
  AppTable,
  AppSelect,
}
@Component({ name: 'LossCompareAnalysis', components, i18n })
export default class LossCompareAnalysis extends Vue {
  private readonly prefixCls = 'loss-compare-analysis'
  @LostReport.Action('fetch')
  private readonly fetch!: LostReport.Action.Fetch
  @LostReport.Action('export')
  private readonly export!: LostReport.Action.Fetch
  @LostReport.Getter('list') private readonly getList!: LostReport.Getter.List
  @LostReport.Getter('listStatus')
  private readonly listStatus!: LostReport.Getter.ListStatus
  private parameter: any = { endMonth: 1 }
  private searchParameter: any = { endMonth: 1 }
  private tParameter = { countType: 0 }
  private defaultData: any = []
  private rowspans: any = {}
  private sumKey: any = []
  private inventedDataIndexArr: any = []

  // 列表加载状态
  get loading() {
    return this.getListStatus.fetching
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  // 表头
  get columns(): any {
    const schoolYear =
      (this.parameter?.termID + '').substr(0, 4) +
      '-' +
      (this.parameter?.termID + '').substr(4, 4)
    return getPageColumns('LossCompareAnalysis')
      .map((item: any) => {
        return {
          title: item.title,
          key: item.code,
          width: 100,
          fixed: 'left',
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat([
        {
          title: `${schoolYear}学年`,
          align: 'center',
          children: [
            {
              title: '实际流失',
              key: 'realLostNum',
              width: 95,
            },
            {
              title: '考核流失',
              key: 'checkLostNum',
              width: 95,
            },
            {
              title: '实际VS考核',
              key: 'realVSlost',
              width: 120,
            },
          ],
        },

        {
          title: '上学年同期',
          align: 'center',
          children: [
            {
              title: '实际流失',
              key: 'lastRealLostNum',
              width: 95,
            },
            {
              title: '考核流失',
              key: 'lastCheckLostNum',
              width: 95,
            },
            {
              title: '实际VS考核',
              key: 'lastRealVSlost',
              width: 120,
            },
          ],
        },
        {
          title: '变动情况',
          align: 'center',
          children: [
            {
              title: '实际流失',
              key: 'realChangeNum',
              width: 95,
            },
            {
              title: '考核流失',
              key: 'checkChangeNum',
              width: 95,
            },
          ],
        },
      ])
  }

  get dataSources() {
    return this.getList('lostNumber')
  }

  // 获取列表状态
  get getListStatus() {
    return this.listStatus('lostNumber')
  }
  // 监听加载状态
  @Watch('getListStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.getListStatus.fetchingError !== null &&
        this.getListStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.fetchingError)
        return
      }

      let result = setReportData({
        list: this.dataSources,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
        countColumn: [
          'realLostNum',
          'checkLostNum',
          'realVSlost',
          'lastRealLostNum',
          'lastCheckLostNum',
          'lastRealVSlost',
          'realChangeNum',
          'checkChangeNum',
        ],
      })
      this.defaultData = result.dataArr
      this.inventedDataIndexArr = result.indexArr
      this.rowspans = result.rowspans
    }
  }
  // 监听导出状态
  @Watch('getListStatus.deleting')
  private watchDeleting(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.getListStatus.deletingError !== null &&
        this.getListStatus.deletingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.deletingError)
        return
      }
    }
  }

  private handleSearch(value: any) {
    this.searchParameter = Object.assign({}, value)
    this.fetch(
      Object.assign({}, value, {
        type: 'lostNumber',
        countType: this.tParameter.countType,
        columns: this.sumKey,
      })
    )
  }
  private handleExport(value: any) {
    this.export(
      Object.assign({}, value, {
        type: 'lostNumber',
        countType: this.tParameter.countType,
        columns: this.sumKey,
      })
    ).then(res => {
      window.location.href = `${res.result}`
    })
  }

  // 切换统计形式
  private handleChangeType(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
      countColumn: [
        'realLostNum',
        'checkLostNum',
        'realVSlost',
        'lastRealLostNum',
        'lastCheckLostNum',
        'lastRealVSlost',
        'realChangeNum',
        'checkChangeNum',
      ],
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }

  // 切换列表列头
  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.handleSearch(this.searchParameter)
  }

  created() {
    this.sumKey = getPageColumns('LossCompareAnalysis').map((item: any) => {
      return item.code
    })
  }

  // 合并行列方法
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }

  // 小计行的样式
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      if (row.hide) {
        return 'ivu-table-summary-row ivu-table-hide-row'
      }
      return 'ivu-table-summary-row'
    }
  }
}
