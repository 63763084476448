export default {
  messages: {
    'zh-CN': {
      title: '外出艺体',
      label: {
        names: '学生姓名',
        leaveDate: '外出日期',
        remark: '外出说明',
      },
      verification: {
        remark: '请填写外出说明',
      },
    },
    'en-US': {},
  },
}
