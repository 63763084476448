import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import AppSelect from '@components/app-select'
import { Tooltip } from 'view-design'
import i18n, { ApproveStatusList } from '../i18n'

type ViewType = 'text'
@Component({ name: 'ApprovalStatusSelect', i18n, inheritAttrs: false })
export default class ApprovalStatusSelect extends Vue {
  @Prop({ type: String }) private readonly viewType?: ViewType
  @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
  private readonly prefixCls = 'approval-status'

  get classes() {
    const component = this.viewType !== void 0 ? this.viewType : 'select'
    return {
      [`${this.prefixCls}-${component}`]: true,
    }
  }

  get list() {
    return this.extra.concat(
      ApproveStatusList.map(value => {
        return {
          label:
            this.viewType === 'text' && value === -1
              ? '-'
              : this.$t(`${value}`),
          value,
        }
      })
    )
  }

  get props() {
    const props: Record<string, any> = {}
    if (this.viewType === 'text') {
      props.content = this.$t(`-1`)
      props.transfer = true
      props.placement = 'top'
      props.always = false
      return props
    }
    props.data = this.list
    return props
  }

  render(createElement: CreateElement) {
    let tag: any = AppSelect
    let children: VNodeChildren = []

    /** 以文本方式显示 */
    if (this.viewType === 'text') {
      tag = 'span'
      const item = this.list.find(
        item => item.value === Number(this.$attrs.value)
      )
      if (item.value == -1) {
        tag = Tooltip

        return createElement(
          tag,
          {
            class: this.classes,
            style: { cursor: 'pointer' },
            props: this.props,
            on: this.$listeners,
          },
          ['-']
        )
      }

      children = [item?.label || this.$t('null') || this.$attrs.value]
    }

    return createElement(
      tag,
      {
        class: this.classes,
        props: this.props,
        attrs: Object.assign({}, this.$attrs),
        on: this.$listeners,
      },
      children
    )
  }
}
