var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ViewLayout',{ref:"layout",attrs:{"name":_vm.prefixCls,"loading":_vm.loading}},[_c('span',{class:(_vm.prefixCls + "_back"),on:{"click":_vm.handleBack}},[_c('Icon',{attrs:{"type":"ios-arrow-back"}}),_vm._v(_vm._s(_vm.$t('text.back')))],1),_vm._v(" "),_c('div',{class:(_vm.prefixCls + "_header")},[_c('Row',{attrs:{"type":"flex","align":"middle"}},[_c('ViewCol',{attrs:{"span":"6","class-name":"layout-view_side"}},[_c('Avatar',{attrs:{"src":_vm.item.avatar,"icon":"ios-contact","size":"110"}})],1),_vm._v(" "),_c('ViewCol',{attrs:{"span":"18","class-name":"layout-view_main"}},[_c('p',[_vm._v(_vm._s(_vm.item.studentName || _vm.$t('text.null')))]),_vm._v(" "),_c('Row',{attrs:{"type":"flex","justify":"start","align":"middle"}},[_c('ViewCol',{attrs:{"span":"12","class-name":"item"}},[_vm._v(_vm._s(_vm.$t('text.genderName'))+"："),_c('span',[_vm._v(_vm._s(_vm.gender))])]),_vm._v(" "),_c('ViewCol',{attrs:{"span":"12","class-name":"item"}},[_vm._v(_vm._s(_vm.$t('text.birthday'))+"："),_c('span',[_vm._v(_vm._s(_vm.item.birthday || _vm.$t('text.null')))])]),_vm._v(" "),_c('ViewCol',{attrs:{"span":"12","class-name":"item"}},[_vm._v(_vm._s(_vm.$t('text.xuexinID'))+"："),_c('span',[_vm._v(_vm._s(_vm.item.xuexinID))])]),_vm._v(" "),_c('ViewCol',{attrs:{"span":"12","class-name":"item"}},[_vm._v(_vm._s(_vm.$t('text.parentPhone'))+"："),_c('span',[_vm._v(_vm._s(_vm.item.parentMobile || _vm.$t('text.null')))])]),_vm._v(" "),_c('ViewCol',{attrs:{"span":"12","class-name":"item"}},[_vm._v(_vm._s(_vm.$t('text.examCode'))+"："),_c('span',[_vm._v(_vm._s(_vm.item.examCode || _vm.$t('text.null')))])]),_vm._v(" "),_c('ViewCol',{attrs:{"span":"12","class-name":"item"}},[_vm._v(_vm._s(_vm.$t('text.sourceChannel'))+"："),_c('span',[_vm._v(_vm._s(_vm.item.sourceChannel == 1
                ? '直升'
                : _vm.item.sourceChannel == 2
                ? '外招'
                : '-'))])]),_vm._v(" "),_c('ViewCol',{attrs:{"span":"12","class-name":"item"}},[_vm._v(_vm._s(_vm.$t('text.direction'))+"："),_c('span',[_vm._v(_vm._s(_vm.item.direction || _vm.$t('text.null')))])])],1)],1)],1)],1),_vm._v(" "),_c('div',{class:(_vm.prefixCls + "_body")},[(_vm.tabs.length > 0)?_c('Tabs',{attrs:{"value":_vm.activeTag},on:{"on-click":_vm.handleTab}},_vm._l((_vm.tabs),function(ite,index){return (ite.kindName !== undefined)?_c('TabPane',{key:ite.kindCode,attrs:{"label":ite.kindName,"name":ite.kindCode}},[(
            _vm.activeTag === ite.kindCode && ite.kindCode === _vm.KindCode.Enroll
          )?_c('ApplyInfo',{attrs:{"data":ite.data,"type":_vm.actionType}}):_vm._e(),_vm._v(" "),(_vm.activeTag === ite.kindCode && ite.kindCode === _vm.KindCode.Base)?_c('BaseInfo',{attrs:{"data":ite.data,"type":_vm.actionType}}):_vm._e(),_vm._v(" "),(
            _vm.activeTag === ite.kindCode && ite.kindCode === _vm.KindCode.Family
          )?_c('FamilyInfo',{attrs:{"data":ite.data,"type":_vm.actionType}}):_vm._e(),_vm._v(" "),(
            _vm.activeTag === ite.kindCode && ite.kindCode === _vm.KindCode.Status
          )?_c('AdmissionInfo',{attrs:{"data":ite.data,"phaseID":_vm.item.phaseID,"type":_vm.actionType}}):_vm._e(),_vm._v(" "),(
            _vm.activeTag === ite.kindCode && ite.kindCode === _vm.KindCode.Record
          )?_c('RecordPage',{attrs:{"paramater":{ termID: _vm.termID, parentUnitID: _vm.parentUnitID, xuexinID: _vm.xuexinID }}}):_vm._e()],1):_vm._e()}),1):_c('div',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.$t('text.noData')))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }