







































import Vue from 'vue'
import { Component, Model, Prop, Ref, Watch } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import AppModal from '@components/app-modal'
import { Form, FormItem, Input } from 'view-design'
import i18n from './i18n'
import { debug } from 'webpack'

const ActionType = Constant.ActionType

const components = { AppModal, Form, FormItem, Input }
@Component({ name: 'LeaveSchoolModal', components, i18n })
export default class LeaveSchoolModal extends Vue {
  @Student.Getter('listStatus')
  private readonly status!: Student.Getter.ListStatus
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Student.Entity[]
  @Prop({ required: true }) private readonly unitID!: number
  @Ref('form') private readonly form!: Form
  @Ref('textarea') private readonly textarea!: Input

  private readonly prefixCls = 'leave-school-modal'
  private visible = false
  private loading = true
  private verifying = false
  private model: Partial<Student.Parameter> = {}

  get rules(): FormRules {
    return {
      remark: [
        {
          required: true,
          type: 'string',
          message: this.$t('verification.remark'),
          trigger: 'change',
        },
      ],
    }
  }

  get updating() {
    return this.status.updating === true
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (this.visible === true && updating === false && previous === true) {
      this.stopInteraction()

      if (this.status.updatingError !== null) {
        return this.$Message.error(this.status.updatingError)
      }

      /** 通知父级元素，操作完成。 */
      this.$emit('on-finish')
    }
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleOk() {
    const unitID = this.unitID;

    this.$set(this.$data, 'verifying', true)

    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        this.$nextTick(() => this.textarea.focus())
        /** 校验失败 */
        return this.stopInteraction()
      }
      /** 向后台发送请求 */
      const { xuexinIDs, remark, termID } = this.model
      this.update({
        actionType: ActionType.LeaveSchool,
        xuexinIDs,
        remark,
        termID,
        unitID,
      })
    })
  }

  private handleVisibleChange(visible: boolean) {
    /** 窗口打开时，更新 model 数据 */
    if (visible === true) {
      const xuexinIDs: string[] = []
      const [{ termID }] = this.data
      const unitID = this.unitID;
      const names = this.data
        .map(item => (xuexinIDs.push(`${item.studentID}`), item.studentName))
        .join('、')
      this.$set(this.$data, 'model', { xuexinIDs, remark: '', names, termID,unitID })
    }

    this.$emit('on-visible-change', visible)
  }

  private handleHidden() {
    this.stopInteraction()
    this.$emit('on-hidden')
    this.form.resetFields()
  }

  private stopInteraction() {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)

    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }
}
