var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModal',{class:("" + _vm.prefixCls),attrs:{"title":"重新入校","value":_vm.visible,"loading":_vm.loading,"width":600,"mask-closable":false},on:{"input":_vm.handleInput,"on-ok":_vm.handleOK,"on-hidden":_vm.handleHidden,"on-visible-change":_vm.handleVisibleChange}},[_c('div',{class:(_vm.prefixCls + "_content")},[(_vm.hidden)?_c('Spin',{attrs:{"fix":""}},[_vm._v(_vm._s(_vm.$t('loading')))]):_c('Form',{ref:"form",class:(_vm.prefixCls + "_form"),attrs:{"disabled":_vm.verifying || _vm.updating,"model":_vm.model,"label-width":140},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_vm._l((_vm.columns),function(item){return _c('FormItem',{directives:[{name:"show",rawName:"v-show",value:(
          (item.columnCode !== 'school' && item.columnCode !== 'direction') ||
            item.columnCode === 'school' ||
            item.columnCode === 'direction'
        ),expression:"\n          (item.columnCode !== 'school' && item.columnCode !== 'direction') ||\n            item.columnCode === 'school' ||\n            item.columnCode === 'direction'\n        "}],key:item.columnCode,attrs:{"label":item.columnName,"prop":("" + (item.columnCode)),"rules":[
          {
            required:
              item.columnCode !== 'channel' &&
              item.columnCode !== 'activity' &&
              item.columnCode !== 'referralCode',
            message: ("请选择" + (item.columnName)),
          } ]}},[(item.columnCode == 'direction')?_c('AppRadioGroup',{attrs:{"data":_vm.direction,"required":"","labelField":"schoolDepartTypeName","valueField":"schoolDepartTypeID","disabled":""},model:{value:(_vm.model.direction),callback:function ($$v) {_vm.$set(_vm.model, "direction", $$v)},expression:"model.direction"}}):_vm._e(),_vm._v(" "),(item.columnCode == 'term')?_c('SemesterSelect',{model:{value:(_vm.model.term),callback:function ($$v) {_vm.$set(_vm.model, "term", $$v)},expression:"model.term"}}):_vm._e(),_vm._v(" "),(item.columnCode == 'school')?_c('SchoolSelect',{attrs:{"term-i-d":_vm.model.term,"unit-i-d":_vm.data.parentUnitID,"capture":"","disabled":""},on:{"on-finish":_vm.getLength},model:{value:(_vm.model.school),callback:function ($$v) {_vm.$set(_vm.model, "school", $$v)},expression:"model.school"}}):_vm._e(),_vm._v(" "),(item.columnCode == 'phase')?_c('SchoolPhaseSelect',{attrs:{"unitID":_vm.model.school,"direction":_vm.model.direction},model:{value:(_vm.model.phase),callback:function ($$v) {_vm.$set(_vm.model, "phase", $$v)},expression:"model.phase"}}):_vm._e(),_vm._v(" "),(item.columnCode == 'grade')?_c('GradeSelect',{attrs:{"base":"","unitID":_vm.model.school,"phaseID":_vm.model.phase},model:{value:(_vm.model.grade),callback:function ($$v) {_vm.$set(_vm.model, "grade", $$v)},expression:"model.grade"}}):_vm._e(),_vm._v(" "),(item.columnCode == 'businessType')?_c('BusinessTypeSelect',{attrs:{"unitID":_vm.model.school,"phaseID":_vm.model.phase},model:{value:(_vm.model.businessType),callback:function ($$v) {_vm.$set(_vm.model, "businessType", $$v)},expression:"model.businessType"}}):_vm._e(),_vm._v(" "),(item.columnCode == 'classType')?_c('SchoolClassTypeSelect',{attrs:{"unitID":_vm.model.school,"phaseID":_vm.model.phase,"manageTypeID":_vm.model.businessType,"direction":_vm.model.direction},model:{value:(_vm.model.classType),callback:function ($$v) {_vm.$set(_vm.model, "classType", $$v)},expression:"model.classType"}}):_vm._e(),_vm._v(" "),(
            item.columnCode == 'channel' ||
              item.columnCode === 'activity' ||
              item.columnCode === 'referralCode'
          )?_c('span'):_vm._e()],1)}),_vm._v(" "),_c('FormItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.direction == '1'),expression:"model.direction == '1'"}],attrs:{"label":"应届生","prop":"freshStudent","rules":[
          {
            required: _vm.model.direction == '1',
            message: "请选择应届生",
          } ]}},[(
            (_vm.model.phase !== 4 && _vm.model.phase !== 3) || _vm.tagItem.hasTermBill
          )?_c('span',[_vm._v("\n          "+_vm._s(_vm.model.freshStudent ? '是' : '否')+"\n        ")]):_c('Checkbox',{model:{value:(_vm.model.freshStudent),callback:function ($$v) {_vm.$set(_vm.model, "freshStudent", $$v)},expression:"model.freshStudent"}},[_vm._v("是")])],1),_vm._v(" "),_c('FormItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.direction == '1'),expression:"model.direction == '1'"}],attrs:{"label":"学业方向/入学分类","prop":"academicDirection","rules":[
          {
            required: _vm.model.direction == '1',
            message: "请选择学业方向/入学分类",
          } ]}},[_c('DictionarySelect',{attrs:{"phaseID":_vm.model.phase,"freshStudent":_vm.model.freshStudent ? 1 : 0,"type":"academicDirection","viewText":Boolean(_vm.tagItem.hasTermBill) || _vm.model.phase !== 4,"transfer":""},model:{value:(_vm.model.academicDirection),callback:function ($$v) {_vm.$set(_vm.model, "academicDirection", $$v)},expression:"model.academicDirection"}})],1),_vm._v(" "),_c('FormItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.direction == '1' && _vm.model.academicDirection !== '5'),expression:"model.direction == '1' && model.academicDirection !== '5'"}],attrs:{"label":"学生类别","prop":"studentCategory","rules":[
          {
            required: _vm.model.direction == '1',
            message: "请选择学生类别",
          } ]}},[_c('DictionarySelect',{attrs:{"phaseID":_vm.model.phase,"freshStudent":_vm.model.freshStudent ? 1 : 0,"type":"studentCategory","viewText":Boolean(_vm.tagItem.hasTermBill),"transfer":""},model:{value:(_vm.model.studentCategory),callback:function ($$v) {_vm.$set(_vm.model, "studentCategory", $$v)},expression:"model.studentCategory"}})],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }