import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'
import StudentDistribution from '@store/modules/student-distribution'
import StudentDistributionType from 'types/student-distribution'

const valueField = 'id'
const labelField = 'name'
const prefixCls = `content-type`

function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `ContentType${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class ContentTypeComponent extends Vue {
    @StudentDistribution.Action('fetchCountTypes') private readonly fetch!: any
    @StudentDistribution.Getter('countTypes')
    private readonly getCountTypes!: StudentDistributionType.Getter.CountTypes
    @StudentDistribution.Getter('countTypesStatus')
    private readonly getStatus!: StudentDistributionType.Getter.CountTypesStatus

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }
    get list() {
      return this.getCountTypes()
    }
    get status() {
      return this.getStatus()
    }

    created() {
      this.fetch()
    }

    get fetching() {
      return this.status.fetching === true
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.loading = this.fetching
          props.error = this.status.fetchingError
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    @Watch('fetching')
    watchFetching(fetching: boolean, previous: boolean) {
      if (fetching === false && previous === true) {
        this.$set(this.$attrs, 'value', this.$attrs.value || 1)
      }
    }
    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []

      return createElement(
        component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return ContentTypeComponent
}

export const ContentTypeSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
export const ContentTypeText = createComponent('span', ComponentType.Text)
