import Vue from 'vue'
import { LoadingBar, Message, Modal } from 'view-design'

/** 原型链添加 iView 全局方法 */
Vue.prototype.$Loading = LoadingBar
Vue.prototype.$Message = Message
Vue.prototype.$Modal = Modal

/** 修改 Message 默认配置 */
Vue.prototype.$Message.config({
  top: 50,
  duration: 3,
})
