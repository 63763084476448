/** 班级数量月统计 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import ClassesCountMonthly from '@pages/statistic-reports/classes-count-monthly'

const children: RouteConfig[] = []

children.push({
  path: '/reports/classes-count-monthly',
  name: 'classes-count-monthly-report',
  component: ClassesCountMonthly,
  meta: { ignore: true, authCode: '050714', parentAuthCode: '0507' },
})

export default {
  path: '/reports',
  name: 'classes-count-monthly',
  redirect: { name: 'classes-count-monthly-report' },
  meta: { title: '班级数量月统计', authCode: '050714' },
  component: RouterViewLayout,
  props: { name: 'classes-count-monthly' },
  children,
}
