import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'
import AppRadioGroup from '@components/app-radio-group'
import i18n, { ResearchFieldList } from './i18n'

type ViewType = 'text'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `ResearchField${upperFirst(type)}`
  const components = {}
  @Component({ name, components, i18n, inheritAttrs: false })
  class ResearchFieldComponent extends Vue {
    @Prop({ type: String }) private readonly viewType?: ViewType

    get classes() {
      return {
        [`research-field-${type}`]: this.viewType === void 0,
        [`research-field-${this.viewType}`]: this.viewType !== void 0,
      }
    }

    get list() {
      return ResearchFieldList.map(value => {
        return { label: this.$t(`${value}`), value }
      })
    }

    get props() {
      const props: Record<string, any> = {}

      switch (type) {
        case ComponentType.Select:
        case ComponentType.RadioGroup:
          props.data = this.list
          break
        default:
          break
      }
      return props
    }

    created() {}

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        tag = 'span'
        children = [`${this.$t(`${this.$attrs.value}`)}`]
      }

      return createElement(
        tag,
        {
          class: `research-field-${type}`,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return ResearchFieldComponent
}

export const ResearchFieldSelect = createComponent(
  AppSelect,
  ComponentType.Select
)

export const ResearchFieldRadioGroup = createComponent(
  AppRadioGroup,
  ComponentType.RadioGroup
)
