













































import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import { ActionType } from 'xuexin-vuex/src/constant'
import i18n from './i18n'
import { DateUtil } from '@util'
import AppModal from '@components/app-modal'
import { Student, School, TrialRead, Constant } from 'xuexin-vuex'
import { Form, FormItem, DatePicker, Spin } from 'view-design'
const components = { AppModal, Form, FormItem, DatePicker, Spin }

type DataType = {
  student: Student.Entity
  unitID: number
  termID: number
  organID: number
}

type DateType = {
  startDate: Date
  endDate: Date
  systemDate: Date
}

@Component({ name: 'TrialReadingModal', components, i18n })
export default class TrialReadingModal extends Vue {
  @Model('input') private value!: any
  @Prop({ default: () => ({}) }) private readonly data!: DataType

  @Student.Action('update') private readonly update!: Student.Action.Update
  @School.Action('fetch')
  private readonly fetchSchool!: School.Action.Fetch
  @School.Action('update')
  private readonly fetchEndDate!: School.Action.Update
  @School.Getter('item') private readonly getSchoolItem!: School.Getter.Item
  @School.Getter('itemStatus')
  private readonly getSchoolItemStatus!: School.Getter.ItemStatus
  @Student.Getter('itemStatus')
  private readonly getStudentStatus!: Student.Getter.ItemStatus

  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'trial-reading-view'
  private model: Partial<{ startDate: Date }> = {}
  private dateGroup: Partial<DateType> = {}
  private deadlineDate = '' /** 获取截止日期 */

  private visible = false
  private loading = true
  private hidden = true

  get rules(): FormRules {
    return {
      // prettier-ignore
      startDate: [{required: true, type: 'date', trigger: 'change', message: this.$t('text.validDate') }]
    }
  }

  get unitID() {
    return this.data.unitID
  }

  get termID() {
    return this.data.termID
  }

  get organID() {
    return this.data.organID
  }

  /** 获取学生ID */
  get studentID() {
    return this.data.student?.studentID
  }

  /** 获取学生姓名 */
  get studentName() {
    return this.data.student?.studentName
  }

  /** 获取系统时间参数 */
  get getDateParam() {
    return {
      actionType: ActionType.Trial,
      unitID: this.unitID,
      termID: this.termID,
      organID: this.organID,
      xuexinID: this.studentID,
    }
  }

  /** 设置时间区间限制 */
  get limitDate() {
    const { startDate, endDate, systemDate } = this.dateGroup
    return {
      disabledDate: (systemDate: number) => {
        let disabled = true
        if (
          systemDate >= Number(startDate) - 86400000 &&
          systemDate <= Number(endDate) + 5 * 24 * 3600 * 1000
        ) {
          disabled = false
        }
        return disabled
      },
    }
  }

  /** 试读参数 */
  get updateParam() {
    return {
      actionType: ActionType.Trial,
      xuexinID: this.studentID,
      studentID: this.studentID,
      termID: this.termID,
      unitID: this.unitID,
      startDate: this.model.startDate?.getTime(),
    }
  }

  /** 获取时间状态 */
  get fetching() {
    return this.getSchoolItemStatus(this.unitID).fetching === true
  }

  get fetchingError() {
    return this.getSchoolItemStatus(this.unitID).fetchingError || null
  }

  get hasFetchingError() {
    return this.fetching === false && typeof this.fetchingError === 'string'
  }

  /** 截止日期状态 */
  get dFetching() {
    return this.getSchoolItemStatus(this.unitID).updating === true
  }

  get dFetchingError() {
    return this.getSchoolItemStatus(this.unitID).updatingError || null
  }

  /** 获取更新状态 */
  get updating() {
    return this.getStudentStatus(`${this.studentID}`).updating === true
  }

  get updatingError() {
    return this.getStudentStatus(`${this.studentID}`).updatingError || null
  }

  @Watch('value', { immediate: true })
  private watchVisible(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('fetching', { immediate: true })
  private watchFetching(fetching: boolean, previous: boolean) {
    if (this.visible === true && fetching === false && previous === true) {
      this.$set(this.$data, 'hidden', false)
      if (this.fetchingError !== null) {
        this.$Message.error(this.fetchingError)
        return this.stopAction()
      }

      const times = this.getSchoolItem(this.unitID)
      this.$set(this.$data, 'dateGroup', {
        ...times,
      })
    }
  }

  @Watch('dateGroup.startDate')
  private watchDateGroup() {
    const startDate = `${this.dateGroup?.startDate}`
    if (Boolean(startDate) === true) {
      this.$set(
        this.model,
        'startDate',
        `${DateUtil.format(startDate, 'yyyy-MM-dd')}`
      )
      this.fetchEndDate({
        startDate: Number(startDate),
        termID: this.termID,
        unitID: this.unitID,
        xuexinID: this.studentID,
        actionType: Constant.ActionType.Time,
      })
    }
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      /** 窗口没有打开不处理状态 */
      if (this.value === false || this.visible === false) {
        return void 0
      }

      if (this.updatingError !== null) {
        this.$Message.error(this.updatingError)
        return this.stopAction()
      }
      this.$set(this.$data, 'deadlineDate', '')
      this.$Message.success(this.$t('text.success'))
      this.$emit('on-finish')
    }
  }

  /** 获取截止时间 */
  @Watch('dFetching')
  private watchDateFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.dFetchingError !== null) {
        return this.$Message.error(this.dFetchingError)
      }

      const date = this.getSchoolItem(this.unitID)?.trialDeadline
      const deadline = date !== void 0 && date !== null ? new Date(date) : ''
      this.$set(this.$data, 'deadlineDate', deadline)
    }
  }

  /** 根据试读开始时间确定试读结束时间 */
  private handleDateChange(date: any) {
    /** 开始时间变化后重新查询截止时间 */
    const currentDate = new Date(date)
    this.fetchEndDate({
      startDate: currentDate.getTime(),
      termID: this.termID,
      unitID: this.unitID,
      xuexinID: this.studentID,
      actionType: Constant.ActionType.Time,
    })
  }

  private handleOK() {
    if (this.hasFetchingError === true) {
      return this.$set(this.$data, 'visible', false)
    }

    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        this.stopAction()
        return
      }
      /** 试读更新 */
      this.update(this.updateParam)
    })
  }

  private handleVisibleChange(visible: boolean): void {
    if (visible === true) {
      // 1.获取开学和结束日期限制期限
      if (
        this.unitID === void 0 ||
        this.unitID === null ||
        this.unitID === -1
      ) {
        return
      }
      this.fetchSchool(this.getDateParam)
    }
    this.$set(this.$data, 'deadlineDate', '')
    this.$emit('on-visible-change', visible)
  }

  private handleHidden(): void {
    // this.form.resetFields()
    this.stopAction()
    this.$emit('on-hidden')
    this.$set(this.$data, 'deadlineDate', '')
    this.$set(this.model, 'startDate', '')
  }

  private handleInput() {
    this.$emit('on-input')
  }

  private stopAction(): void {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }
}
