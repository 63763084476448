import Vue, { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Modal } from 'view-design'
import './app-modal.scss'

const components = { Modal }
@Component({ name: 'AppModal', components, inheritAttrs: false })
export default class AppModal extends Vue {
  @Prop({ type: Boolean, default: false }) private maskClosable!: boolean
  @Prop({ type: Number, default: 550 }) private width!: number

  private readonly prefixCls = 'app-modal'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get props() {
    return Object.assign({}, this.$props, this.$attrs)
  }

  get listeners() {
    return Object.keys(this.$listeners).reduce<any>((listeners, key) => {
      listeners[key] = (...args: any[]) => {
        this.$nextTick(() => this.$emit.apply(this, [key, ...args]))
      }
      return listeners
    }, {})
  }

  render(createElement: CreateElement) {
    return createElement('Modal', {
      class: this.classes,
      props: this.props,
      on: this.$listeners,
      scopedSlots: this.$scopedSlots,
    })
  }
}
