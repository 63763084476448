



































import Vue from 'vue'
import { Component, Ref, Mixins, Watch } from 'vue-property-decorator'

import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'

import getPageColumns from '@components/report-toolbar/columns'
import AppTable from '@components/app-table'
import PageTyping from '../../../../types/page'
import { setReportData, spanChildMethod } from '@util/report'
import i18n from './i18n'

import StudentReport, { ActionType } from '@store/modules/student-report'

const components = {
  ViewLayout,
  ReportSearch,
  AppTable,
  ReportToolbar,
}

type Flat = Record<string, boolean>

@Component({ name: 'StudentOverviewPage', components, i18n })
export default class StudentOverviewPage extends Mixins(PagePropsMixins) {
  @StudentReport.Action('fetchList') private readonly fetchList!: any
  @StudentReport.Action('export') private readonly export!: any
  @StudentReport.Getter('list')
  private readonly getList!: StudentReport.Getter.List
  @StudentReport.Getter('listStatus')
  private readonly listStatus!: StudentReport.Getter.ListStatus

  private readonly prefixCls = 'student-abnormal-change'
  private parameter: any = {}
  private searchParameter: any = {}
  private tParameter: any = { countType: 0 }
  private sumKey: any = []
  private inventedDataIndexArr: any = []
  private defaultData: any = []
  private rowspans: any = {}
  private currentValue = 0

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  /** 无数据提示 */
  get noDataText() {
    if (this.dataSources.length === 0) {
      return this.$t('text.noDataText')
    }
    return
  }
  /** 表格列信息 */
  get tableColumns() {
    return getPageColumns('StudentAbnormalChange')
      .map((item: any) => {
        return {
          title: item.title,
          key: item.code,
          fixed: 'left',
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat(this.countColumns)
  }
  // 数量列头设置
  get countColumns() {
    return [
      {
        title: '原就读',
        align: 'center',
        key: 'oldStudentNum',
      },
      {
        title: '转入',
        align: 'center',
        children: [
          {
            title: '插班',
            key: 'inClassInsertNum',
          },
          {
            title: '转班',
            key: 'inClassTransferNum',
          },
          {
            title: '跳级',
            key: 'inJumpNum',
          },
          {
            title: '留级',
            key: 'inStayNum',
          },
          {
            title: '降级',
            key: 'inDegradeNum',
          },
          {
            title: '学部互转',
            key: 'inDepartTransferNum',
          },
          {
            title: '校际互转',
            key: 'inSchoolTransferNum',
          },
          {
            title: '小计',
            key: 'inNum',
          },
        ],
      },
      {
        title: '转出',
        align: 'center',
        children: [
          {
            title: '转学',
            key: 'outOutSchoolNum',
          },
          {
            title: '转班',
            key: 'outTransferNum',
          },
          {
            title: '跳级',
            key: 'outJumpNum',
          },
          {
            title: '留级',
            key: 'outStayNum',
          },
          {
            title: '降级',
            key: 'outDegradeNum',
          },
          {
            title: '学部互转',
            key: 'outDepartTransferNum',
          },
          {
            title: '校际互转',
            key: 'outSchoolTransferNum',
          },
          {
            title: '小计',
            key: 'outNum',
          },
        ],
      },
      {
        title: '直升',
        align: 'center',
        children: [
          {
            title: '国内',
            key: 'straightCivilNum',
          },
          {
            title: '国际',
            key: 'straightInternationalNum',
          },
          {
            title: '小计',
            key: 'straightNum',
          },
          {
            title: '直升率',
            key: 'straightRate',
          },
        ],
      },
      {
        title: '毕业',
        key: 'graduateNum',
        align: 'center',
      },
      {
        title: '在班生人数',
        key: 'classStudentNum',
        align: 'center',
      },
    ]
  }
  // 获取数量列头key值
  get countKeys() {
    let arr: string[] = []
    this.countColumns.forEach((item: any) => {
      if (item.children === void 0) {
        arr.push(item.key)
      } else {
        arr.concat(
          item.children.forEach((i: any) => {
            arr.push(i.key)
          })
        )
      }
    })
    return arr
  }
  /** 数据源 */
  get dataSources() {
    return this.getList('studentAbornalChange')
  }
  get getListStatus() {
    return this.listStatus('studentAbornalChange')
  }
  /** 请求状态 */
  get fetching() {
    return this.getListStatus.fetching === true
  }
  /** 请求错误信息 */
  get fetchingError() {
    return this.getListStatus.fetchingError
  }
  /** 监听请求状态 */
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous !== fetching) {
      if (Boolean(this.fetchingError)) {
        return this.$Message.error(this.fetchingError as string)
      }

      let result = setReportData({
        list: this.dataSources,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
        countColumn: this.countKeys,
      })

      this.inventedDataIndexArr = result.indexArr
      this.defaultData = result.dataArr
      this.rowspans = result.rowspans
    }
  }
  /** 搜索 */
  private handleSearch(value: any) {
    this.searchParameter = Object.assign({}, value)
    const { endDate = new Date() } = value

    this.fetchList({
      ...value,
      actionType: ActionType.Abnormal,
      type: 'studentAbornalChange',
      endDate: new Date(endDate).getTime(),
      countType: this.currentValue,
      columns: this.tableColumns.map((item: any) => item.key || ''),
    })
  }
  /** 导出 */
  private handleExport(value: any) {
    this.export(
      Object.assign({}, value, {
        type: 'studentAbornalChange',
        actionType: ActionType.Abnormal,
        countType: this.tParameter.countType,
        columns: this.sumKey,
      })
    ).then((res: { result: any }) => {
      window.location.href = `${res.result}`
    })
  }
  /** 切换统计形式 */
  private handleChangeType(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
      countColumn: this.countKeys,
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }
  /** 表格处理 */
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }
  /**列表列头发生变化 */
  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.handleSearch(this.searchParameter)
  }
  /** 小计行的样式 */
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      if (row.hide) {
        return 'ivu-table-summary-row ivu-table-hide-row'
      }
      return 'ivu-table-summary-row'
    }
  }
  /** 请求数据 */
  private fetch() {
    if (Boolean(this.parameter) === false) {
      return
    }

    const { endDate = new Date() } = this.parameter

    this.fetchList({
      ...this.parameter,
      actionType: ActionType.Abnormal,
      type: 'studentAbornalChange',
      endDate: new Date(endDate).getTime(),
      countType: this.currentValue,
      columns: this.tableColumns.map((item: any) => item.key || ''),
    })
  }
  /** 初始化 */
  created() {
    this.sumKey = getPageColumns('StudentAbnormalChange').map((item: any) => {
      return item.code
    })
  }
}
