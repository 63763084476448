




























import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import { GlassSelect } from '@business-components/glass'
import { Glass, Student } from 'xuexin-vuex'
import { ActionType } from 'xuexin-vuex/src/constant'
import { Form, FormItem, Modal } from 'view-design'
import { Getter, Action } from 'vuex-class'
import i18n from './i18n'
const components = { AppModal, Form, FormItem, GlassSelect }

type DataType = {
  list: Array<{ id: string }>
  searches: Record<string, any>
}

type Model = {
  glass: string
}

type ClassData = Partial<Glass.Entity>

@Component({ name: 'DivideClassModal', components, i18n })
export default class DivideClassModal extends Vue {
  @Model('input') private value!: boolean
  @Prop({ default: () => ({}) }) private readonly data!: DataType

  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly updateStatus!: Student.Getter.ListStatus
  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'divide-class-modal'
  private model: Model = {
    glass: '',
  }

  private classesList: ClassData = []

  private visible = false
  private loading = true

  get rules() {
    return {
      // prettier-ignore
      glass: [{required: true, type:'number', trigger: 'blur',message: this.$t('text.valid')}]
    }
  }
  get list() {
    return this.data.list || []
  }

  get xuexinIDs() {
    return this.list.map(item => {
      return item.id
    })
  }

  get searches() {
    return this.data.searches || {}
  }

  get unitID() {
    return this.searches.unitID
  }

  get termID() {
    return this.searches.termID
  }

  get fetchParam() {
    return {
      actionType: ActionType.ByStudent,
      xuexinIDs: this.xuexinIDs,
      unitID: this.unitID,
      termID: this.termID,
      searches: this.searches,
    }
  }

  get updateParam() {
    const { glass } = this.model
    return {
      actionType: ActionType.Divide,
      classID: Number(glass),
      xuexinIDs: this.xuexinIDs,
      unitID: this.unitID,
      termID: this.termID,
    }
  }

  get updating() {
    return this.updateStatus.updating === true
  }

  get updatingError() {
    return this.updateStatus.updatingError || null
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      /** 窗口没有打开不处理状态 */
      if (this.value === false || this.visible === false) {
        return void 0
      }

      if (this.updatingError !== null) {
        this.$Message.error(this.updatingError)
        return this.stopAction()
      }
      this.$Message.success(this.$t('text.success'))
      this.$emit('on-finish')
    }
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      return
    }
    this.$emit('on-visible-change', visible)
  }

  private handleOK() {
    this.form.validate(valid => {
      if (valid !== true) {
        return this.stopAction()
      }
      this.update(this.updateParam)
    })
  }

  private handleHidden() {
    this.form.resetFields()
    this.stopAction()
    this.$emit('on-hidden')
  }

  private stopAction() {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleInput() {
    this.$emit('on-input')
  }
}
