













































































































































































































import Vue from 'vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { TrialRead, Session, Constant } from 'xuexin-vuex'
import { Button, TableColumn } from 'view-design'
import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import GlassModal from '@business-components/glass-modal'
import GradeModal from '@business-components/grade-modal'
import ViewFeedbackModal from './componetns/viewFeedback-modal.vue'
import EditFeedbackModal from './componetns/editFeedback-modal.vue'
import SendMessageModal from '@business-components/send-message-modal'
import { convertSearchParameter } from '@util'

import Export from '@store/modules/export'
import ExportTyping from '../../../types/export'
import PageTyping from '../../../types/page'

const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  AppTable,
  AppPagination,
  ViewFeedbackModal,
  EditFeedbackModal,
  GlassModal,
  GradeModal,
  SendMessageModal,
}
const i18n = {
  messages: {
    'zh-CN': {
      button: {
        edit: '编辑',
        view: '查看',
        export: '导出',
        sms: '发短信',
        smsRecord: '短信发送记录',
      },
      text: {
        notDone: '未进行',
        processing: '进行中',
        over: '已结束',
        continueStudy: '继续就读',
        goveUp: '放弃',
        interChangeSchool: '校际互转',
        to: '至',
        null: '-',
        undefined: '-',
        index: '序号',
      },
      message: {
        noDataText: '暂无数据',
        noParameter: '请先选择搜索条件',
        success: '编辑成功',
        nonSearch: '请先点击搜索',
        noSchoolDepart: '请选择学部',
      },
    },
    'en-US': {},
  },
}

type Flat = Record<string, TrialRead.Entity>
@Component({ name: 'TrialReadPage', components, i18n })
export default class TrialReadPage extends Mixins(PagePropsMixins) {
  @TrialRead.Getter('list')
  private readonly trialReadList!: TrialRead.Getter.List
  @TrialRead.Getter('listStatus')
  private readonly listStatus!: TrialRead.Getter.ListStatus
  @TrialRead.Action('fetch')
  private readonly fetchList!: TrialRead.Action.Fetch
  /** session模块 */
  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  @Export.Getter('listStatus')
  private readonly getExportStatus!: ExportTyping.Getter.ListStatus
  @Export.Action('exportFile')
  private readonly fetchExportUrl!: ExportTyping.Action.ExportFile
  @Export.Getter('item')
  private readonly getExportUrl!: ExportTyping.Getter.Item

  private readonly prefixCls = 'trial-reading-page'
  private loading = false
  private page = 1
  private pageSize = 10
  private operable = true /** 是否大于等于当前学前，可否操作 */
  private selection: TrialRead.Entity[] = []
  private flat: Flat = {}
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private searchParameter: Partial<PageTyping.SearchParameter> = {}
  private modal: { name: string | null; data: any } = { name: null, data: {} }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  get currentTermID() {
    return this.getUser?.extraMap.currentTermID || 0
  }

  get valid() {
    // const { schoolDepartID } = this.searchParameter
    // return (
    //   Boolean(schoolDepartID) ||
    //   schoolDepartID === 0 ||
    //   Boolean(this.parameter.schoolDepartID) ||
    //   this.parameter.schoolDepartID === 0
    // )
    return true
  }
  get noDataText() {
    // if (
    //   Boolean(this.parameter.schoolDepartID) === false ||
    //   Boolean(this.searchParameter.schoolDepartID) === false
    // ) {
    //   return this.$t('message.nonSearch')
    // }
    return this.$t('message.noDataText')
  }

  get tableColumn() {
    if (this.valid !== true || this.tableData.length === 0) {
      return []
    }
    const columns: TableColumn[] = this.columns.map(item => {
      const column: TableColumn = Object.assign({}, item)
      switch (column.key) {
        case 'feedbackNote':
          column.slot = 'feedbackNote'
          column.fixed = 'right'
          break
        case 'feedbackResult':
        case 'applyDate':
        case 'feedbackStatus':
        case 'feedbackDate':
          column.slot = column.key
          break
        case 'num': // 序号
          column.render = (createElement, parameter) => {
            const text =
              (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
            return createElement!('span', `${text}`)
          }
          break
        case 'gradeName':
        case 'className':
          column.width = 240
          break
        case 'aliasClassName':
          column.width = 200
          break
      }
      return column
    })
    columns.unshift(
      {
        type: 'selection',
        width: 60,
        fixed: 'left',
        align: 'center',
      },
      {
        title: `${this.$t('text.index')}`,
        render: (createElement, parameter) => {
          const text =
            (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
          return createElement!('span', `${text}`)
        },
        width: 80,
        fixed: 'left',
        align: 'center',
      }
    )
    return columns
  }

  get fetching() {
    return this.listStatus.fetching === true
  }
  get authCode() {
    return this.$route.meta.authCode
  }
  get total() {
    if (this.valid !== true) {
      return 0
    }
    return this.listStatus.total || 0
  }

  get disableButton() {
    return this.total === 0
  }

  get exportUrl() {
    return this.getExportUrl(`${this.searchParameter}`)
  }

  get tableData() {
    if (this.valid !== true) {
      return []
    }
    return this.trialReadList.map(item => {
      /** 还原当前选中的学生 */
      if (this.flat[item.shortStudentID] !== void 0) {
        return Object.assign({}, item, { _checked: true })
      }
      return item
    })
  }
  /** 监听搜索条件 */
  @Watch('parameter.unitID')
  private watchSchoolDepartID(value: number, oldValue: unknown) {
    if (oldValue === null || oldValue === undefined) {
      this.searchParameter = { ...this.parameter }
      this.fetch()
    }
  }

  @Watch('parameter.termID')
  private watchValue(value: number): void {
    this.operable = value >= this.currentTermID
  }
  @Watch('$route', { immediate: true })
  routechange(to: any, from: any) {
    if (to.name == 'trial-reading-student-detail') {
      /* 设置为true说明你是返回到这个页面，而不是通过跳转从其他页面进入到这个页面 */
      from.meta.isBack = true
    }
  }
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.loading === true) {
        this.$set(this.$data, 'loading', false)
      }
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }

  /** 监听已选学生 */
  @Watch('selection', { immediate: true })
  private watchSelection(selection: TrialRead.Entity[]) {
    this.$set(
      this.$data,
      'flat',
      selection.reduce((flat: Flat, item) => {
        return (flat[item.shortStudentID] = item), flat
      }, {})
    )
  }

  @Watch('getExportStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (!creating && previous) {
      if (this.getExportStatus.creatingError !== null) {
        this.$Message.error(this.getExportStatus.creatingError)
        return
      }
      location.href = this.exportUrl.url
    }
  }

  private selectionList(selection: TrialRead.Entity[]): void {
    this.$set(this.$data, 'selection', selection)
  }

  private handleClick(viewType: string, trialRead: TrialRead.Entity) {
    if (Boolean(this.parameter.unitID) === false) {
      return this.$Message.warning(`${this.$t('message.noSchoolDepart')}`)
    }
    switch (viewType) {
      case 'view':
        this.$set(this.$data, 'modal', {
          name: 'view-modal',
          data: {
            shortStudentID: trialRead.shortStudentID,
            termID: this.searchParameter.termID,
            unitID: this.searchParameter.unitID,
            isEachTranfer: trialRead.feedbackResult,
          },
        })
        break
      case 'edit':
        this.$set(this.$data, 'modal', {
          name: 'edit-modal',
          data: {
            shortStudentID: trialRead.shortStudentID,
            studentID: trialRead.studentID,
            parameter: this.searchParameter,
            phaseID: trialRead.phaseID,
          },
        })
        break
      case 'grade':
        this.$set(this.$data, 'modal', {
          name: 'grade-modal',
          data: {
            viewType: 'view',
            parameter: this.searchParameter,
            unitGradeID: trialRead.unitGradeID,
          },
        })
        break
      case 'glass':
        this.$set(this.$data, 'modal', {
          name: 'glass-modal',
          data: {
            viewType: 'view',
            parameter: this.searchParameter,
            classID: trialRead.classID,
          },
        })
        break
      case 'sms':
        this.$set(this.$data, 'modal', {
          name: 'sms-modal',
          data: {
            list: (this.selection.length === 0
              ? this.trialReadList
              : this.selection
            ).map(item => {
              return {
                id: item.studentID,
                name: item.studentName,
                mobile: item.parentMobile,
              }
            }),
            authCode: this.authCode,
            searches: this.searchParameter,
            actionType: Constant.ActionType.Trial,
            pageType: Constant.PageType.Trial,
          },
        })
        break
      case 'sms-record':
        this.$router.push({
          name: 'trial-reading-sms-record',
          query: this.searchParameter,
        })
        break
      case 'view-student': // 查看学生信息
        this.$router.push({
          name: 'trial-reading-student-detail',
          params: {
            xuexinID: trialRead.studentID || '',
            unitID: `${this.searchParameter.unitID || ''}`,
            termID: `${this.searchParameter.termID || ''}`,
            parentUnitID: `${trialRead.parentUnitID || ''}`,
          },
        })

        break
      case 'export':
        this.fetchExportUrl({
          ids: this.selection.map(item => item.shortStudentID),
          searches: this.convertParameter(),
          columns: this.columns.map(item => item.key || ''),
          actionType: Constant.ActionType.Trial,
        })
        break
    }
  }
  private convertParameter() {
    return convertSearchParameter(this.parameter)
  }
  private fetch() {
    if (
      this.valid !== true /** 参数无效 */ ||
      this.fetching === true /** 正在请求数据 */
    ) {
      /** 以上情况不发起请求 */
      return void 0
    }

    this.fetchList({
      searches: this.convertParameter(),
      columns: this.columns.map(item => item.key || ''),
      page: this.page,
      pageSize: this.pageSize,
    })
  }

  private handlePageChange(page: number): void {
    this.$set(this.$data, 'page', page)
    this.$set(this.$data, 'selection', [])
    this.fetch()
  }

  private handlePageSizeChange(pageSize: number): void {
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }

  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    // if (Boolean(parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(`${this.$t('message.noParameter')}`)
    // }
    this.$set(this.$data, 'page', 1)
    this.$set(this.$data, 'searchParameter', parameter)
    this.fetch()
  }

  /** 窗口关闭后处理函数 */
  private handleModalHidden() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }
  /** 窗口功能完成事件处理函数 */
  private handleModalFinish() {
    this.$Message.success(this.$t('message.success'))
    this.$set(this.$data, 'modal', { name: null, data: {} })
    this.$set(this.$data, 'searchParameter', this.parameter)
    this.fetch()
  }
  created() {
    // this.fetch()
  }
}
