/** 班级数量对比分析 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import ClassesCompareAnalysis from '@pages/statistic-reports/classes-compare-analysis'

const children: RouteConfig[] = []

children.push({
  path: '/reports/classes-compare-analysis',
  name: 'classes-compare-analysis-report',
  component: ClassesCompareAnalysis,
  meta: { ignore: true, authCode: '050707', parentAuthCode: '0507' },
})

export default {
  path: '/reports',
  name: 'classes-compare-analysis',
  redirect: { name: 'classes-compare-analysis-report' },
  meta: { title: '班级数量对比分析', authCode: '050707' },
  component: RouterViewLayout,
  props: { name: 'classes-compare-analysis' },
  children,
}
