import Vue, { CreateElement, VNode } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { RadioGroup, Radio, Icon } from 'view-design'

const components = { RadioGroup, Radio, Icon }
@Component({ name: 'AppRadioGroup', components, inheritAttrs: false })
export default class AppRadioGroup extends Vue {
  @Prop({ type: Array, default: () => [] }) private readonly data!: any[]
  @Prop({ type: String, default: 'label' }) private readonly labelField!: string
  @Prop({ type: String, default: 'value' }) private readonly valueField!: string
  private readonly prefixCls = 'app-radio-group'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get props() {
    return Object.assign({}, this.$attrs)
  }

  render(createElement: CreateElement) {
    const children: VNode[] = this.data.map(item => {
      const label = item[this.labelField]
      const value = item[this.valueField]
      const disabled = item.disabled === true
      return createElement(
        'Radio',
        {
          props: {
            label: value,
            disabled,
          },
          key: value,
        },
        [
          item.icon !== void 0
            ? createElement('Icon', { props: { type: item.icon } })
            : void 0,
          createElement('span', label),
        ]
      )
    })

    return createElement(
      'RadioGroup',
      {
        class: this.classes,
        props: this.props,
        on: this.$listeners,
      },
      children
    )
  }
}
