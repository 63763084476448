import { Constant } from 'xuexin-vuex'

export type ResidenceType = Constant.ResidenceType
export const ResidenceType = Constant.ResidenceType

export const ResidenceTypeList = [ResidenceType.City, ResidenceType.Village]

export const messages: Messages = {
  'zh-CN': {
    [ResidenceType.City]: '城镇',
    [ResidenceType.Village]: '农村',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [ResidenceType.City]: '城镇',
    [ResidenceType.Village]: '农村',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
