import { Constant } from 'xuexin-vuex'

export type ConfirmStatus = Constant.ConfirmStatus
export const ConfirmStatus = Constant.ConfirmStatus
export const ConfirmStatusList = [
  ConfirmStatus.Waiting,
  ConfirmStatus.Received,
  ConfirmStatus.Returned,
  ConfirmStatus.Revoked,
]

export const messages: Messages = {
  'zh-CN': {
    [ConfirmStatus.Waiting]: '待确认',
    [ConfirmStatus.Received]: '已接收',
    [ConfirmStatus.Returned]: '已退回',
    [ConfirmStatus.Revoked]: '已撤销',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [ConfirmStatus.Waiting]: '待确认',
    [ConfirmStatus.Received]: '已接收',
    [ConfirmStatus.Returned]: '已退回',
    [ConfirmStatus.Revoked]: '已撤销',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
