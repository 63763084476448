import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Grade, Constant, Student } from 'xuexin-vuex'
import { ComponentType, upperFirst } from '@util'
import { ActionType } from 'xuexin-vuex/src/constant'

import AppSelect from '@components/app-select'

type ViewType = 'text'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `Grade${upperFirst(type)}`
  const components = {}
  @Component({ name, components, inheritAttrs: false })
  class GradeComponent extends Vue {
    @Grade.Action('fetchList')
    private readonly fetchList!: Grade.Action.FetchList
    @Grade.Getter('listStatus')
    private readonly getStatus!: Grade.Getter.ListStatus
    @Grade.Getter('list')
    private readonly getList!: Grade.Getter.List

    @Student.Action('fetch')
    private readonly fetchStudentGrade!: Student.Action.Fetch
    @Student.Getter('itemStatus')
    private readonly studentStatus!: Student.Getter.ItemStatus
    @Student.Getter('itemExtra')
    private readonly studentItemExtra!: Student.Getter.ItemExtra

    @Prop({ type: Number }) private readonly termID!: number
    @Prop({ type: Number }) private readonly unitID?: number
    @Prop({ type: [Number, Array] }) private readonly schoolDepartID?:
      | number
      | number[]
    @Prop({ type: Array, default: () => [] })
    private readonly xuexinIDs?: string[]

    @Prop({ type: [Number, Array] }) private readonly phaseID?: any
    @Prop({ type: Number }) private readonly direction?: number
    @Prop({ type: String }) private readonly dataRangeType?: string

    @Prop({ type: Boolean, default: true }) private readonly cache!: boolean
    @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
    /** 是否显示拼接字段 默认 true 显示 false 只显示年级 */
    @Prop({ type: Boolean, default: true }) private readonly compose!: boolean
    /** 是否为基础平台年级 */
    @Prop({ type: Boolean, default: false }) private readonly base!: boolean
    @Prop({ type: Boolean, default: false }) private readonly level!: boolean
    @Prop({ type: String }) private readonly viewType?: ViewType
    @Prop({ type: String }) private readonly type?: string
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: Boolean, default: false }) private readonly transfer!: boolean

    get classes() {
      return {
        [`grade-${type}`]: true,
        [`grade-${type}-${this.viewType}`]: this.viewType !== void 0,
      }
    }
    get labelField() {
      return this.level
        ? 'composeGradeName'
        : this.base
        ? 'gradeName'
        : this.compose
        ? 'composeGradeName'
        : 'unitGradeName'
    }

    get valueField() {
      return this.base ? 'gradeID' : 'unitGradeID'
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.loading = this.fetching
          props.error = this.status.fetchingError
          props.data = this.list
          props.valueField = this.valueField
          props.labelField = this.labelField
          props.transfer = true
          props.disabled = this.disabled
          break
        default:
          break
      }
      return props
    }

    get listeners() {
      return this.$listeners
    }

    get parameter() {
      return {
        termID: this.termID,
        unitID: this.unitID,
        schoolDepartID: this.schoolDepartID,
        direction: this.direction,
        phaseID: this.phaseID,
        actionType: this.base ? Constant.ActionType.BaseGrade : undefined,
        dataRangeType: this.dataRangeType,
        transfer: this.transfer,
      }
    }

    get status() {
      return this.type === 'student-unitGrade'
        ? this.studentStatus(this.xuexinIDs ? this.xuexinIDs[0] : '')
        : this.getStatus(
            this.base
              ? {
                  unitID: this.unitID,
                  schoolDepartID: this.schoolDepartID,
                  phaseID: this.phaseID,
                  actionType: Constant.ActionType.BaseGrade,
                }
              : this.parameter
          )
    }

    get fetching() {
      return this.status.fetching !== false
    }

    get list() {
      if (this.valid !== true && this.fetching) {
        return this.extra
      }
      const list =
        this.type === 'student-unitGrade'
          ? this.studentItemExtra(this.xuexinIDs ? this.xuexinIDs[0] : '')
          : this.getList(
              this.base
                ? {
                    unitID: this.unitID,
                    schoolDepartID: this.schoolDepartID,
                    phaseID: this.phaseID,
                    actionType: Constant.ActionType.BaseGrade,
                  }
                : this.parameter
            ).filter(item => {
              /** 过滤被禁用的数据 */
              return item.enable !== 0
            })
      return list ? this.extra.concat(list) : this.extra
    }

    get value() {
      return Number(this.$attrs.value)
    }

    /** 是否为有效参数 */
    get valid() {
      return Boolean(this.unitID) || this.unitID === 0
    }

    created() {
      this.fetch()
    }

    /** 参数发生变化时，重新请求数据 */
    @Watch('parameter')
    watchParameter() {
      this.fetch()
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        tag = 'span'
        const item = this.list.find(
          item => item[this.valueField] === this.value
        )
        const text = item !== void 0 ? item[this.labelField] : '-'
        children = [text]
      }
      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs, { transfer: true }),
          on: this.listeners,
        },
        children
      )
    }

    private fetch() {
      if (
        this.status.fetching === true /** 正在请求中 */ ||
        // (this.cache === true &&
        //   this.status.loaded === true) /** 允许缓存且已有缓存 */ ||
        this.valid === false /** 无效参数 */
      ) {
        // 以上情况，不请求数据
        return void 0
      }

      /** 查询基础年级数据 */
      if (this.base) {
        return this.fetchList({
          unitID: this.unitID,
          schoolDepartID: this.schoolDepartID,
          phaseID: this.phaseID,
          actionType: Constant.ActionType.BaseGrade,
        })
      }
      if (
        this.type === 'student-unitGrade' &&
        (Boolean(this.termID) || this.termID === 0)
      ) {
        return this.fetchStudentGrade({
          actionType: ActionType.UnitGrade,
          xuexinIDs: this.xuexinIDs,
          unitID: this.unitID,
          termID: this.termID,
        })
      }

      this.fetchList({
        searches: this.parameter,
        sortField: 'unitGradeName',
        sortOrder: 1,
      })
    }
  }

  return GradeComponent
}

export const GradeSelect = createComponent(AppSelect, ComponentType.Select)
