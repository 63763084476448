import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { SchoolPhase } from 'xuexin-vuex'
import { ComponentType, upperFirst, convertToMulti } from '@util'
import AppSelect from '@components/app-select'

type ViewType = 'text'

const valueField = 'phaseID'
const labelField = 'phaseName'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `SchoolPhase${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class SchoolPhaseComponent extends Vue {
    @SchoolPhase.Action('fetchList')
    private readonly fetchList!: SchoolPhase.Action.FetchList
    @SchoolPhase.Getter('listStatus')
    private readonly getStatus!: SchoolPhase.Getter.ListStatus
    @SchoolPhase.Getter('list')
    private readonly getList!: SchoolPhase.Getter.List

    @Prop({ type: [Number, Array] }) private readonly unitID?: number | number[]
    @Prop({ type: [Number, Array] }) private readonly schoolDepartID?:
      | number
      | number[]
    @Prop({}) private readonly direction!: number
    @Prop({}) private readonly filter!: number

    @Prop({ type: Boolean, default: true }) private readonly cache!: boolean
    @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
    @Prop({ type: Boolean, default: false }) private readonly transfer!: boolean
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType?: any

    get classes() {
      return {
        [`school-phase-${type}`]: true,
        [`school-phase-${type}-${this.viewType}`]: this.viewType !== void 0,
      }
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.loading = this.fetching
          props.error = this.status.fetchingError
          props.data = this.list
          props.disabled = this.disabled
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    get parameter() {
      return {
        unitID: convertToMulti(this.unitID),
        schoolDepartID: convertToMulti(this.schoolDepartID),
        direction: this.direction || void 0,
        filter: this.filter,
      }
    }

    get status() {
      return this.getStatus(this.parameter)
    }

    get fetching() {
      return this.status.fetching !== false
    }

    /** 参数是否有效 */
    get valid() {
      return Boolean(this.unitID) || this.unitID === 0
    }

    get list() {
      if (this.valid !== true) {
        return this.extra
      }

      const list = this.getList(this.parameter).filter(item => {
        // 过滤被禁用的数据
        return item.enable !== 0
      })

      this.$emit('on-finish', this.extra.concat(list))

      return this.extra.concat(list)
    }

    get value() {
      return Number(this.$attrs.value)
    }

    /** 参数发生变化时，重置当前 value，并重新请求数据 */
    @Watch('parameter')
    watchParameter() {
      this.fetch()
    }

    created() {
      this.fetch()
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text' || this.viewType) {
        tag = 'span'
        const item = this.list.find(item => item[valueField] === this.value)
        const text =
          item !== void 0
            ? item[labelField]
            : this.$i18n.locale === 'en-US'
            ? '-'
            : '无匹配项'
        children = [text]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }

    private fetch() {
      if (this.transfer) {
        if (
          this.status.fetching === true /** 正在请求中 */ ||
          (this.cache === true &&
            this.status.loaded === true) /** 允许缓存且已有缓存 */ ||
          this.valid === false /** 参数无效 */
        ) {
          // 以上两种情况，不需要请求数据
          return void 0
        }
      } else {
        if (
          this.status.fetching === true /** 正在请求中 */ ||
          // (this.cache === true &&
          //   this.status.loaded === true) /** 允许缓存且已有缓存 */ ||
          this.valid === false /** 参数无效 */
        ) {
          // 以上两种情况，不需要请求数据
          return void 0
        }
      }

      this.fetchList(this.parameter)
    }
  }

  return SchoolPhaseComponent
}

export const SchoolPhaseSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
