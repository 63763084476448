import { mergeLocaleMessage } from '@util'

export default mergeLocaleMessage({
  'zh-CN': {
    success: '操作成功',
    button: {
      export: '导出',
      delete: '删除',
      sms: '发送短信',
      'sms-record': '短信发送记录',
      'template-download': '模板下载',
      'batch-import': '批量导入',
      'import-record': '导入记录',
      edit: '编辑',
      'edit-tags': '编辑学业标签',
      'leave-school': '外出艺体',
      'back-school': '外出艺体返校',
      suspension: '休学',
      resumption: '复学',
      'change-school': '转学',
      'inter-change-school': '校际互转',
      'change-depart': '内转',
      'change-grade': '跳留级',
      'change-class': '转班',
      create: '添加学生',
    },
    text: {
      view: '查看',
      'no-data-text': '暂无学生数据',
    },
    title: {
      'back-school': '外出艺体返校',
      resumption: '复学',
    },
    warning: {
      /** 返校(外出艺体返校) */
      'back-school': '您勾选的学生中存在非"外出艺体"的学生，请重新选择！',
      /** 离校(外出艺体) */
      'leave-school': '“{0}”为“{1}”状态，不能进行外出艺体操作！',
      /** 休学 */
      suspension: '“{0}”为“{1}”状态，不能进行休学操作！',
      /** 复学 */
      resumption: '您勾选的学生不是“休学”状态，请重新选择！',
      'diff-grade': '您选择学生的年级不一致，请您核对一致后，再进行转班！',
      'no-school-depart-id': '请至少选择到学部！',
      'non-search': '请先点击搜索！',
    },
    content: {
      'back-school': '您确定要将“{0}”外出艺体返校吗？', // 外出艺体返校
      resumption: '您确定要将“{0}”复学吗？', // 复学
    },
  },
  'en-US': {},
})
