import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import AppSelect from '@components/app-select'
import i18n, { OperateClassList } from './i18n'
import { ComponentType } from '@util'
import OperateClass from '@store/modules/operate-class'

type ViewType = 'text'
function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  @Component({ name: 'OperateClassSelect', i18n, inheritAttrs: false })
  class OperateClassComponent extends Vue {
    @OperateClass.Action('fetch')
    private readonly fetchList!: OperateClass.Action.Fetch
    @OperateClass.Getter('listStatus')
    private readonly getStatus!: OperateClass.Getter.ListStatus
    @OperateClass.Getter('list')
    private readonly getList!: OperateClass.Getter.List
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType?: ViewType

    get classes() {
      return {
        [`operate-class-select`]: this.viewType === void 0,
        [`operate-class-text`]: this.viewType !== void 0,
      }
    }

    get props() {
      if (this.viewType === 'text') {
        return {}
      }

      return { data: this.list }
    }

    get list() {
      return this.getList()?.map(m => {
        return { label: m?.operateGroupName, value: m?.operateGroup }
      })
    }
    get status() {
      return this.getStatus()
    }

    get fetching() {
      return this.status.fetching !== false
    }

    @Watch('fetching')
    watchFetching(fetching: boolean, previous: boolean) {
      if (fetching === false && previous === true) {
        if (typeof this.status.fetchingError === 'string') {
          return this.$Message.error('查询操作类型数据失败')
        }
      }
    }

    created() {
      this.fetch()
    }
    private fetch() {
      const status = this.getStatus()
      if (status.fetching === true || status.loaded === true) {
        return void 0
      }

      this.fetchList()
    }
    render(createElement: Vue.CreateElement) {
      let tag: any = AppSelect
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        tag = 'span'
        children = [`${this.$t(this.$attrs.value || 'null')}`]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }
  return OperateClassComponent
}

export const OperateClassSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
export const OperateClassText = createComponent('span', ComponentType.Text)
