export default {
  messages: {
    'zh-CN': {
      title: '内转（国际直升）',
      loading: '加载中...',
      label: {
        direction: '直升方向',
        unitID: '直升校区',
        termID: '报读学期',
        manageTypeID: '报读类型',
        gradeID: '直升年级',
        unitClassTypeID: '校本班型',
        freshStudent: '应届生',
        studentCategory: '学生类别',
        academicDirection: '学业方向/入学分类',
      },
      validator: {
        unitID: '请选择要直升的校区！',
        manageTypeID: '请选择报读类型！',
        unitClassTypeID: '请选择校本班型！',
        freshStudent: '请选择应届生',
        studentCategory: '请选择学生类别',
        academicDirection: '请选择学业方向/入学分类',
      },
    },
    'en-US': {},
  },
}
