

































import Vue, { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Records from '@store/modules/records'
import RecordsTyping from 'types/records'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import i18n from '../i18n'
import { TableColumn, TableColumnRenderParams } from 'view-design'

type Paramater = {
  termID: number
  unitID: number
  parentUnitID: number
  xuexinID: string
}

const components = {
  AppTable,
  AppPagination,
}

@Component({ name: 'Record', components, i18n })
export default class Record extends Vue {
  @Records.Action('fetchAll')
  private readonly recordFetchList!: RecordsTyping.Action.FetchAll
  @Records.Getter('list')
  private readonly recodeList!: RecordsTyping.Getter.List
  @Records.Getter('listStatus')
  private readonly recordListStatus!: RecordsTyping.Getter.ListStatus

  @Prop({ type: Object }) private readonly paramater!: Paramater
  private readonly prefixCls = 'record'
  private page = 1
  private pageSize = 10

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get recordFetching() {
    return this.recordListStatus.fetching !== false
  }

  get total() {
    return this.recordListStatus.total || this.recodeList.length
  }
  /** 学籍操作记录展示列 */
  get tableCoulmns() {
    return [
      {
        key: 'num',
        title: this.$t('text.num') as string,
        width: 80,
        render: (
          createElement: CreateElement,
          parameter: TableColumnRenderParams
        ) => {
          const text =
            (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
          return createElement!('span', `${text}`)
        },
      },
      {
        key: 'toTermName',
        title: this.$t('text.termName') as string,
        width: 240,
      },
      {
        key: 'operateType',
        title: this.$t('text.operateType') as string,
        width: 120,
        slot: 'operateType',
      },
      {
        key: 'operateContent',
        width: 300,
        title: this.$t('text.operateContent') as string,
      },
      {
        key: 'operator',
        width: 120,
        title: this.$t('text.operator') as string,
      },
      {
        key: 'operatorDate',
        title: this.$t('text.operatorDate') as string,
        slot: 'operatorDate',
      },
    ]
  }

  /** 学籍操作记录数据 */
  get dataSource() {
    return this.recodeList
  }
  /** 学籍操作记录列表 */
  private recordFetch() {
    this.recordFetchList({
      searches: {
        studentID: this.paramater.xuexinID,
        parentUnitID: this.paramater.parentUnitID,
      },
      actionType: 2,
      page: this.page,
      pageSize: this.pageSize,
    })
  }

  /** 页码发生变化 */
  private handlePageChange(page: number) {
    this.$set(this.$data, 'page', page)
    this.recordFetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number) {
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.recordFetch()
  }
  created() {
    this.recordFetch()
  }
}
