














































import Vue from 'vue'
import i18n from './i18n'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import { Student, School } from 'xuexin-vuex'
import { ActionType } from 'xuexin-vuex/src/constant'
import AppModal from '@components/app-modal'
import { Form, FormItem, DatePicker, Spin } from 'view-design'
import { DateUtil } from '@util'
const components = { AppModal, Form, FormItem, DatePicker, Spin }

type DataType = {
  student: Record<string, Student.Entity>
  termID: number
  unitID: number
  organID: number
}

type Model = {
  startDate: Date
  leaveDate: Date
}

type DateType = {
  startDate: Date
  endDate: Date
  systemDate: Date
}

@Component({ name: 'ShortTermModal', components, i18n })
export default class ShortTermModal extends Vue {
  @Model('input') private value!: boolean
  @Prop({ default: () => ({}) }) private readonly data!: DataType
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('itemStatus')
  private readonly getItemStatus!: Student.Getter.ItemStatus
  @School.Action('fetch') private readonly fetchSchool!: School.Action.Fetch
  @School.Getter('item') private readonly getSchoolItem!: School.Getter.Item
  @School.Getter('itemStatus')
  private readonly schoolItemStatus!: School.Getter.ItemStatus
  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'short-term-modal'
  private dateGroup: Partial<DateType> = {}
  private model: Partial<Model> = {}

  private visible = false
  private loading = true
  private hidden = false

  get rules(): FormRules {
    return {
      // prettier-ignore
      startDate: [{ required: true, type: 'date', trigger: 'change', message: this.$t('text.startDate') }],
      // prettier-ignore
      leaveDate: [{ required: true, type: 'date', trigger: 'change', message: this.$t('text.leaveDate') }]
    }
  }

  get organID() {
    return this.data.organID
  }

  get unitID() {
    return this.data.unitID
  }

  get termID() {
    return this.data.termID
  }

  get studentID() {
    return this.data.student?.studentID
  }

  get studentName() {
    return this.data.student?.studentName
  }

  get updateParam() {
    return Object.assign(
      {},
      {
        actionType: ActionType.ShortTerm,
        startDate: this.model.startDate?.getTime(),
        leaveDate: this.model.leaveDate?.getTime(),
        xuexinID: `${this.studentID}`,
        studentID: `${this.studentID}`,
        termID: this.termID,
        unitID: this.unitID,
      }
    )
  }

  /** 设置开学时间 */
  get startLimitDate() {
    if (this.visible === true || this.value === true) {
      const { startDate, endDate, systemDate } = this.dateGroup
      return {
        disabledDate: (systemDate: number) => {
          let disabled = true
          if (
            systemDate >= Number(startDate) - 86400000 &&
            systemDate <= Number(endDate)
          ) {
            disabled = false
          }
          return disabled
        },
      }
    }
  }

  /** 设置结束时间 */
  get endLimitDate() {
    if (this.visible === true || this.value === true) {
      const { startDate, endDate, systemDate } = this.dateGroup
      /** 控制结束日期的时间范围 */
      let tempStartDate: any
      if (Boolean(this.model.startDate) === true) {
        /** 如果选择了开始日期，截止日历的开始日期需要重新设置 */
        tempStartDate = this.model.startDate
      } else {
        tempStartDate = Number(startDate) - 86400000
      }

      return {
        disabledDate: (systemDate: number) => {
          let disabled = true
          if (
            systemDate >= Number(tempStartDate) &&
            systemDate <= Number(endDate)
          ) {
            disabled = false
          }
          return disabled
        },
      }
    }
  }

  /** 获取系统时间传入的参数 */
  get getDateParam() {
    return {
      actionType: ActionType.Trial,
      xuexinID: this.studentID,
      organID: this.organID,
      termID: this.termID,
      unitID: this.unitID,
    }
  }

  /** 获取系统时间后的状态 */
  get fetching() {
    return this.schoolItemStatus(this.unitID).fetching === true
  }

  get fetchingError() {
    return this.schoolItemStatus(this.unitID).fetchingError || null
  }

  get hasFetchingError() {
    return this.fetching === false && typeof this.fetchingError === 'string'
  }

  /** 获取更新后的状态 */
  get updating() {
    return this.getItemStatus(`${this.studentID}`).updating === true
  }

  get updatingError() {
    return this.getItemStatus(`${this.studentID}`).updatingError
  }

  @Watch('value', { immediate: true })
  private watchVisible(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  private handleChange(value: any) {
    this.$set(this.$data, 'tempStartDate', `${value}`)
    this.$set(this.model, 'leaveDate', '')
  }

  @Watch('fetching')
  private watchItemFetching(fetching: boolean, previous: boolean) {
    if (this.visible === true && fetching === false && previous === true) {
      this.$set(this.$data, 'hidden', false)
      if (this.fetchingError !== null) {
        this.$Message.error(this.fetchingError)
        return this.stopAction()
      }
      /** 获取当前学校开学结束及系统时间 */
      const times = this.getSchoolItem(this.unitID)
      this.$set(this.$data, 'dateGroup', {
        ...times,
      })
      const startDate = `${this.dateGroup?.startDate}`
      this.$set(
        this.model,
        'startDate',
        `${DateUtil.format(startDate, 'yyyy-MM-dd')}`
      )
    }
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      /** 窗口没有打开不处理状态 */
      if (this.value === false || this.visible === false) {
        return void 0
      }

      if (this.updatingError !== null) {
        this.$Message.error(this.updatingError)
        return this.stopAction()
      }

      this.$Message.success(`${this.$t('text.success')}`)
      this.$emit('on-finish')
    }
  }

  private stopAction() {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleOK() {
    if (this.hasFetchingError === true) {
      return this.$set(this.$data, 'visible', false)
    }

    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        return this.stopAction()
      }
      this.update(this.updateParam)
    })
  }

  private handleVisibleChange(visible: boolean): void {
    if (visible === true) {
      // 1.获取开学和结束日期限制期限
      if (
        this.unitID === void 0 ||
        this.unitID === null ||
        this.unitID === -1
      ) {
        return
      }
      this.fetchSchool(this.getDateParam)
    }
    this.$emit('on-visible-change')
  }

  private handleHidden(): void {
    if (Boolean(this.hasFetchingError) === false) {
      this.form.resetFields()
    }
    this.stopAction()
    this.$emit('on-hidden')
  }

  private handleInput() {
    this.$emit('on-input')
  }
}
