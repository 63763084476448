export const messages: Messages = {
  'zh-CN': {
    button: {
      export: '导出',
      undo: '撤销',
      undoed: '已撤销',
      ok: '确定',
      cancel: '取消',
    },
    modal: {
      title: '撤销',
      content: '<p>确定要撤销吗？</p>',
      success: '撤销成功',
    },
    text: {
      noParameter: '请选择查询条件',
      noDataText: '暂无学生数据',
      noSchoolDepartID: '请至少选择到学部！',
    },
    index: '序号',
  },
  'en-US': {},
}

export default { messages }
