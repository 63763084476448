


















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Icon, Button } from 'view-design'
import i18n from './i18n'

const components = { Icon, Button }
@Component({ name: 'SuccessfulStep', components, i18n })
export default class SuccessfulStep extends Vue {
  private readonly prefixCls = 'successful-step'

  get classes() {
    return {
      'reset-password-step': true,
      [this.prefixCls]: true,
    }
  }
}
