export default {
  messages: {
    'zh-CN': {
      unauthorized: '登录过期，请重新登录。',
      forbidden: '资源禁止访问！',
      loading: '加载中...',
      retry: '重试',
    },
    'en-US': {},
  },
}
