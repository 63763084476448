export default {
  messages: {
    'zh-CN': {
      columns: {
        index: '序号',
        abroad: '出国方向',
        state: '状态',
        action: '操作',
      },
      'button-text': {
        create: '添加',
        edit: '编辑',
        delete: '删除',
      },
      message: {
        'confirm-delete': '确定要删除吗？',
        'delete-success': '删除成功！',
        'create-success': '添加成功！',
        'edit-success': '编辑成功！',
        'abroad-cannot-empty': '出国方向不能为空',
      },
    },
    'en-US': {
      columns: {
        index: '序号',
        abroad: '出国方向',
        state: '状态',
        action: '操作',
      },
      'button-text': {
        create: '添加',
        edit: '编辑',
        delete: '删除',
      },
      message: {
        'confirm-delete': '确定要删除吗？',
        'delete-success': '删除成功！',
        'create-success': '添加成功！',
        'edit-success': '编辑成功！',
        'abroad-cannot-empty': '出国方向不能为空',
      },
    },
  },
}
