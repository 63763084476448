/** 学生分布统计 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import StudentDistributionReport from '@pages/statistic-reports/student-distribution'

const children: RouteConfig[] = []
/** 学生分布统计 */
children.push({
  path: '/reports/student-distribution',
  name: 'student-distribution-report',
  component: StudentDistributionReport,
  meta: { ignore: true, authCode: '050713', parentAuthCode: '0507' },
})

export default {
  path: '/reports',
  name: 'student-distribution',
  redirect: { name: 'student-distribution-report' },
  meta: { title: '学生分布统计', authCode: '050713' },
  component: RouterViewLayout,
  props: { name: 'student-distribution' },
  children,
}
