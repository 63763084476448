export default {
  messages: {
    'zh-CN': {
      loading: '加载中...',
      title: '编辑学业标签',
      label: {
        studentName: '学生姓名',
        studentMark: '集团优生',
        researchField: '学业方向',
      },
      placeholder: {
        studentMark: '请选择',
        researchField: '请选择',
      },
      messages: {
        studentMark: '请选择是否优生',
        researchField: '请选择学业方向',
      },
    },
    'en-US': {
      loading: '加载中...',
      title: '编辑学业标签',
      label: {
        studentName: '学生姓名',
        studentMark: '集团优生',
        researchField: '学业方向',
      },
      placeholder: {
        studentMark: '请选择',
        researchField: '请选择',
      },
    },
  },
}
