
















































































































































































































































































































































































import Vue from 'vue'
import { Component, Model, Watch, Prop, Ref } from 'vue-property-decorator'
import omit from 'lodash.omit'
import {
  Form,
  FormItem,
  Input,
  DatePicker,
  InputNumber,
  Upload,
  Button,
  Icon,
  Spin,
} from 'view-design'
import AppModal from '@components/app-modal'
import AppRadioGroup from '@components/app-radio-group'
import { SemesterSelect } from '@business-components/semester'
import { SchoolSelect } from '@business-components/school'
import { SchoolDepartSelect } from '@business-components/school-depart'
import { SchoolClassTypeSelect } from '@business-components/school-class-type'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { SchoolSystemSelect } from '@business-components/school-system'
import { BusinessTypeSelect } from '@business-components/business-type'
import TeacherSelect from '@business-components/teacher'
import { GradeSelect } from '@business-components/grade'
import { SubjectTypeRadio } from '@business-components/subject-type'
import { DateUtil } from '@util'
import { Glass, School, Session, Constant, Grade } from 'xuexin-vuex'
import i18n from './i18n'

const components = {
  AppModal,
  Form,
  FormItem,
  Spin,
  AppRadioGroup,
  Input,
  InputNumber,
  DatePicker,
  SemesterSelect,
  SchoolSelect,
  SchoolSystemSelect,
  SchoolDepartSelect,
  SchoolPhaseSelect,
  BusinessTypeSelect,
  GradeSelect,
  SchoolClassTypeSelect,
  TeacherSelect,
  SubjectTypeRadio,
  Upload,
  Icon,
  Button,
}

/** 试图类型 */
type ViewType = 'create' | 'batch-create' | 'edit' | 'view'
type dataType = {
  viewType: ViewType
  classID: number
  parameter: Glass.Entity
  editPart: Boolean
  termID: number
}
@Component({ name: 'GlassModal', components, i18n })
export default class GlassModal extends Vue {
  /** 学校模块 */
  @School.Action('fetch')
  private readonly fetchSchoolStatus!: School.Action.Fetch
  @School.Getter('item')
  private readonly getSchool!: School.Getter.Item
  /** session模块 */
  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  /** 年级模块 */
  @Grade.Getter('list')
  private readonly getGradeList!: Grade.Getter.List
  /** 班级模块 */
  @Glass.Action('fetch') private readonly fetch!: Glass.Action.Fetch
  @Glass.Action('update') private readonly update!: Glass.Action.Update
  @Glass.Action('create') private readonly create!: Glass.Action.Create
  @Glass.Getter('itemStatus')
  private readonly getItemStatus!: Glass.Getter.ItemStatus
  @Glass.Getter('listStatus')
  private readonly getListStatus!: Glass.Getter.ListStatus
  @Glass.Getter('item') private readonly getItem!: Glass.Getter.Item

  /** 组件属性 */
  @Model('input', { type: Boolean }) private value!: boolean

  @Prop({
    required: true,
    default: () => {
      return { viewType: 'view' ,termID: void 0}
    },
  })
  private readonly data!: dataType
  @Ref('form') private readonly form!: Form

  private prefixCls = 'glass-modal '
  private visible = this.value
  private newClassID: number = 0
  private showSchoolSelect = true
  private loading = true
  private verifying = false
  private actionType = ''
  private model: Partial<Glass.Parameter> = {
    classFile: [],
  }
  get reReadData() {
    return [
      {
        label: this.$t(`boolean[${1}]`) as string,
        value: 1,
        disabled: this.data.editPart ? true : false,
      },
      {
        label: this.$t(`boolean[${0}]`) as string,
        value: 0,
        disabled: this.data.editPart ? true : false,
      },
    ]
  }

  get rules(): FormRules {
    return {
      unitID: [
        {
          validator: this.validateNull,
          message: this.$t('message.unit') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      schoolDepartID: [
        {
          validator: this.validateNull,
          message: this.$t('message.schoolDepart') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      phaseID: [
        {
          validator: this.validateNull,
          message: this.$t('message.phase') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      unitGradeID: [
        {
          validator: this.validateNull,
          message: this.$t('message.unitGrade') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      abroadID: [
        {
          validator: this.validateNull,
          message: this.$t('message.abroad') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      startTermID: [
        {
          validator: this.validateNull,
          message: this.$t('message.startTerm') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      schoolSystemID: [
        {
          validator: this.validateNull,
          message: this.$t('message.schoolSystem') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      manageTypeID: [
        {
          validator: this.validateNull,
          message: this.$t('message.manageType') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      unitClassTypeID: [
        {
          validator: this.validateNull,
          message: this.$t('message.unitClassType') as string,
          trigger: 'change',
          type: 'number',
        },
      ],
      startDate: [
        {
          validator: this.validateNull,
          message: this.$t('message.startDate') as string,
          trigger: 'blur',
          type: 'string',
        },
      ],
      limitCount: [
        {
          validator: this.validateNull,
          message: this.$t('message.limitCount') as string,
          trigger: 'blur',
          type: 'number',
        },
      ],
      maxClassNum: [
        {
          validator: this.validateNull,
          message: this.$t('message.maxClassNum') as string,
          trigger: 'blur',
          type: 'number',
        },
      ],
      subjectType: [
        {
          validator: this.validateSubjectType,
          message: this.$t('message.subjectType') as string,
          type: 'number',
        },
      ],
      repeatType: [
        {
          validator: this.validateRepeatType,
          message: this.$t('message.repeatType') as string,
          type: 'number',
        },
      ],
      realityClassNum: [
        {
          validator: this.validateRealityClassNum,
          type: 'number',
          trigger: 'blur',
        },
      ],
      classNum: [
        {
          validator: this.validateNull,
          message: this.$t('message.classNum') as string,
          type: 'number',
          trigger: 'blur',
        },
      ],
    }
  }

  get parentUnitID() {
    if (this.getUser) {
      return this.getUser.parentUnitID || this.parameter.parentUnitID
    }
  }

  /** 获取机构id */
  get organID() {
    return this.getUser?.organID
  }

  get itemStatus() {
    return this.getItemStatus(
      this.viewType === 'edit' || this.viewType === 'view'
        ? this.data.classID
        : this.newClassID
    )
  }

  get listStatus() {
    return this.getListStatus(this.model)
  }

  get school(): Partial<School.Entity> {
    return this.getSchool(this.parentUnitID!) || {}
  }

  /** 是否显示复读班 */
  get showRepeatType() {
    return (
      this.school.repeatTypeStatus === 1 && (this.model.schoolDepartID === 3||this.model.schoolDepartID===2)
    )
  }

  /** 是否显示文理类型 */
  get showSubjectType() {
    return (
      this.school.subjectTypeStatus === 1 && this.model.schoolDepartID === 3
    )
  }

  /** 班主任信息是否存在 */
  get headTeacher() {
    return (
      Object.keys(this.model.headTeacher ? this.model.headTeacher : {})
        .length !== 0
    )
  }

  /** 班主任名称 */
  get headTeacherName() {
    if (this.headTeacher) {
      return `${this.model.headTeachers?.headTeacherName || '-'}(
          ${this.model.headTeachers?.departmentName || '-'}  ${this.model
        .headTeachers?.mobile || '-'} )`
    }
    return '-'
  }

  get parameter() {
    return this.data.parameter || {}
  }

  get className() {
    return (
      this.getItem(
        this.viewType === 'edit' ? this.data.classID : this.newClassID
      )?.className || ''
    )
  }

  get stringBuilderid() {
    return this.getItem(this.newClassID)?.stringBuilderid || ''
  }

  get viewType() {
    return this.data.viewType || 'view'
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean): void {
    this.visible = value
  }
  @Watch('actionType', { immediate: true })
  private watchActionType(value: string): void {
    this.actionType = value
  }

  /** 监听班级请求状态 */
  @Watch('itemStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 窗口不显示时，不处理数据。 */
    if (this.value === false || this.visible === false) {
      return void 0
    }

    /** 请求完成 */
    if (fetching === false && previous === true) {
      /** 查询失败，提示错误消息。 */
      if (this.itemStatus.fetchingError !== null) {
        return this.$Message.error(this.itemStatus.fetchingError)
      }

      /** 更新班级数据 */
      const item: any = this.getItem(
        this.viewType === 'edit' || this.viewType === 'view'
          ? this.data.classID
          : this.newClassID
      )
       
      if (item === void 0) {
        return void 0
      }
        this.model.classFile = []
      if(this.viewType==="edit"&&item.classFileName!==''&&item.classFileName!==null){
        const classFileJson = {
          name:item.classFileName,
          url:item.classFileUrl
        }
        this.model.classFile.push(classFileJson)
      }
      if (this.actionType === 'fetchGlassName') {
        return this.$set(
          this.$data,
          'model',
          Object.assign({}, this.model, {
            className: this.className,
          })
        )
      }
      this.$set(
        this.$data,
        'model',
        Object.assign({}, item, {
          classFile:this.model.classFile,
          /** 添加时间 */
          createDate: item.createDate
            ? DateUtil.format(item.createDate, 'yyyy-MM-dd hh:mm')
            : '-',
          /** 添加人 */
          creatorInfo: item.creatorInfo
            ? ['creatorName', 'departmentName', 'mobile']
                .map(key => item.creatorInfo[key])
                .filter(item => item !== void 0)
                .join(' ') || '-'
            : '-',
          startDate: DateUtil.format(
            item.startDate ? item.startDate : new Date().getTime(),
            'yyyy-MM-dd'
          ),
          headTeacherID: item.headTeachers?.headTeacherID,
        })
      )
    }
  }

  /** 监听添加班级状态 */
  @Watch('itemStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    /** 窗口不显示时，不处理数据。 */
    if (this.value === false || this.visible === false) {
      return void 0
    }

    /** 创建请求完成 */
    if (creating === false && previous === true) {
      /** 添加失败，提示错误消息。 */
      if (this.itemStatus.creatingError !== null) {
        this.$Message.error(this.itemStatus.creatingError)
        return this.stopLoading()
      }

      /** 添加成功，关闭窗口，通知父级组件。 */
      this.$set(this.$data, 'visible', false)
      this.$emit('on-finish', 'glass-modal', 'create')
      return this.stopLoading()
    }
  }

  /** 监听更新班级状态 */
  @Watch('itemStatus.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    /** 窗口不显示时，不处理数据。 */
    if (this.value === false || this.visible === false) {
      return void 0
    }

    /** 更新请求完成 */
    if (updating === false && previous === true) {
      /** 更新失败，提示错误消息。 */
      if (this.itemStatus.updatingError !== null) {
        this.$Message.error(this.itemStatus.updatingError)
        return this.stopLoading()
      }

      /** 更新成功，关闭窗口，通知父级组件。 */
      this.$set(this.$data, 'visible', false)
      this.$emit('on-finish', 'glass-modal', 'edit')
      return this.stopLoading()
    }
  }
  private handleUnitID(value: number) {
    if (
      value &&
      this.model.phaseID &&
      this.model.unitGradeID &&
      this.model.schoolSystemID &&
      this.model.manageTypeID &&
      this.model.unitClassTypeID &&
      this.model.schoolDepartID
    ) {
      this.fetchGlassName()
    }
  }

  private handleSchoolDepartID(value: number) {
    if (
      value &&
      this.model.phaseID &&
      this.model.unitGradeID &&
      this.model.schoolSystemID &&
      this.model.manageTypeID &&
      this.model.unitClassTypeID &&
      this.model.unitID
    ) {
      this.fetchGlassName()
    }
  }
  private handlePhaseID(value: number) {
    if (
      value &&
      this.model.unitID &&
      this.model.unitGradeID &&
      this.model.schoolSystemID &&
      this.model.manageTypeID &&
      this.model.unitClassTypeID &&
      this.model.schoolDepartID
    ) {
      this.fetchGlassName()
    }
  }
  private handleUnitGradeID(value: number) {
    const schoolSystemID = this.getGradeList({
      termID: this.data.termID || this.parameter.termID,
      unitID: this.model.unitID,
      phaseID: this.model.phaseID,
      schoolDepartID: this.model.schoolDepartID,
    }).filter(item => item.unitGradeID === value)[0].schoolSystemID
    if (this.viewType !== 'view') {
      this.$set(this.$data.model, 'schoolSystemID', schoolSystemID)
    }
    if (
      value &&
      this.model.unitID &&
      this.model.phaseID &&
      this.model.schoolSystemID &&
      this.model.manageTypeID &&
      this.model.unitClassTypeID &&
      this.model.schoolDepartID
    ) {
      this.fetchGlassName()
    }
  }

  private handleManageTypeID(value: number) {
    if (
      value &&
      this.model.unitID &&
      this.model.phaseID &&
      this.model.schoolSystemID &&
      this.model.unitGradeID &&
      this.model.unitClassTypeID &&
      this.model.schoolDepartID
    ) {
      this.fetchGlassName()
    }
  }
  private handleUnitClassTypeID(value: number) {
    if (
      value &&
      this.model.schoolDepartID &&
      this.model.phaseID &&
      this.model.unitGradeID &&
      this.model.schoolSystemID &&
      this.model.manageTypeID &&
      this.model.unitID &&
      (this.viewType === 'batch-create' ? this.model.classNum : true)
    ) {
      this.fetchGlassName()
    }
  }
  private handleClassNum() {
    if (
      this.model.classNum &&
      this.model.schoolDepartID &&
      this.model.phaseID &&
      this.model.unitGradeID &&
      this.model.schoolSystemID &&
      this.model.manageTypeID &&
      this.model.unitClassTypeID &&
      this.model.unitID
    ) {
      this.fetchGlassName()
    }
  }

  private validateNull(rule: object, value: number | string, callback: any) {
    if (value === -1 || value === void 0 || value === '' || value === null) {
      return callback(this.$t('message.noChoice') as string)
    }
    return callback()
  }
  private validateRepeatType(rule: object, value: number | string, callback: any) {
   if(this.showRepeatType===false){
     return callback()
   }
    if (value === -1 || value === void 0 || value === '' || value === null) {
      return callback(this.$t('message.repeatType') as string)
    }
    return callback()
  }
   private validateSubjectType(rule: object, value: number | string, callback: any) {
   if(this.showSubjectType===false){
     return callback()
   }
    if (value === -1 || value === void 0 || value === '' || value === null) {
      return callback(this.$t('message.subjectType') as string)
    }
    return callback()
  }
  private validateRealityClassNum(
    rule: object,
    value: number | string,
    callback: any
  ) {
    if (value === -1 || value === void 0 || value === '' || value === null) {
      return callback(this.$t('message.realityClassNum') as string)
    } 
    if(this.model.limitCount){
      if (!(Number(value)>0&&Number(value)<=Number(this.model.limitCount))) {
      return callback(this.$t('message.realityClassNumSuccess') as string)
    }
    }
    return callback()
  }

  private handleShowSchoolSelect(list: School.Getter.List) {
    this.showSchoolSelect = list.length > 1
  }

  private stopLoading() {
    this.loading = false
    this.verifying = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }
  /** 文件上传 */
  private handleUpload(file: any) {
    /** 展示文件列表 */
    this.$set(this.$data,'model',Object.assign({},this.model,{classFile :[]}))
    this.model.classFile.push(file)
    return false
  }
    /** 删除文件 */
  private handleRemove(index: number) {
    this.model.classFile.splice(index, 1)
       if(this.viewType==="edit"&&this.model.classFileName!==''){
          this.model.classFileName = ''
          this.model.classFileUrl = ''
       }
  }
  private handleOK() {
    if (this.viewType === 'view') {
      this.visible = false
      return this.$emit('on-ok')
    }

    this.verifying = true
    this.form.validate(valid => {
      if (valid === false) {
        return this.stopLoading()
      }
      const parameter = Object.assign({}, this.model, {
        termID: this.data.termID || this.parameter.termID,
        startDate: new Date(this.model.startDate!).getTime(),
        limitCount: Number(this.model.limitCount),
        classNum: this.model.classNum ? Number(this.model.classNum) : 1,
        stringBuilderid: this.stringBuilderid,
        className: this.model.className,
      })

      if (!Boolean(parameter.headTeacherID)) {
        parameter.headTeacher = ''
        parameter.headTeacherMobile = ''
        parameter.headTeacherName = ''
        parameter.headTeachers = void 0
      }
      const formData = new FormData()
      formData.append('classFile', parameter.classFile[0])
    
      if (this.viewType === 'edit') {
        formData.append('data', JSON.stringify(omit(parameter,'classFile')))
        this.update({
          data:formData,
          classID:parameter.classID
        })
      } else {
        parameter.classID = this.newClassID
        parameter.className = `${this.className}`.split('、')
        formData.append('data', JSON.stringify(omit(parameter,'classFile')))
        this.create({
          data:formData,
          classID:parameter.classID
        })
      }
    })
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === false) {
      return void 0
    }
    if (this.viewType !== 'view' && this.viewType !== 'edit') {
      this.newClassID = new Date().getTime()
    }
    if (this.viewType !== 'view') {
      /** 查询学校配置项 */
      this.fetchSchoolStatus({
        unitID: this.parentUnitID,
        organID: this.organID,
      })
    }

    switch (this.viewType) {
      case 'create': // 添加班级
        break
      case 'batch-create': // 批量添加班级
        this.$set(this.$data.model, 'classNum', 1)
        break
      case 'edit':
      case 'view':
        /** 获取班级详情 */
        this.fetch({
          classID: this.data.classID,
          termID: this.data.termID ||this.parameter.termID,
        })
        break
      default:
        break
    }

    this.$emit('on-visible-change')
  }

  private fetchGlassName(): void {
    this.actionType = 'fetchGlassName'
    this.fetch({
      termID: this.data.termID ||this.parameter.termID,
      schoolDepartID: this.model.schoolDepartID,
      phaseID: this.model.phaseID,
      unitGradeID: this.model.unitGradeID,
      schoolSystemID: this.model.schoolSystemID,
      manageTypeID: this.model.manageTypeID,
      unitClassTypeID: this.model.unitClassTypeID,
      unitID: this.model.unitID,
      classNum: this.model.classNum
        ? this.viewType === 'edit'
          ? 1
          : Number(this.model.classNum)
        : undefined,
      classID: this.viewType === 'edit' ? this.data.classID : this.newClassID,
      viewType: this.viewType,
      actionType: Constant.ActionType.Create,
    })
  }

  private handleHidden(): void {
    this.$set(this.$data, 'model', {})
    this.$set(this.$data, 'actionType', '')
    this.stopLoading()
    this.$emit('on-hidden')
  }
}
