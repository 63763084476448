
















































































































































import Vue from 'vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import { Button, TableColumn } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import PagePropsMixins from '@mixins/page-props-mixins'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import GradeModal from '@business-components/grade-modal'
import GlassModal from '@business-components/glass-modal'
import { ApprovalStatusSelect } from '@business-components/approval-status'
import WorkflowModal from '@business-components/workflow-modal'
import SendMessageModal from '@business-components/send-message-modal'
import { convertSearchParameter } from '@util'

import Export from '@store/modules/export'
import ExportTyping from '../../../types/export'
import PageTyping from '../../../types/page'

const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  AppTable,
  AppPagination,
  GradeModal,
  GlassModal,
  ApprovalStatusSelect,
  WorkflowModal,
  SendMessageModal,
}

const i18n = {
  messages: {
    'zh-CN': {
      button: {
        export: '导出',
        sms: '发短信',
        smsRecord: '短信发送记录',
      },
      text: {
        null: '-',
        undefined: '-',
        index: '序号',
      },
      message: {
        noDataText: '暂无数据',
        noParameter: '请先选择搜索条件',
        nonSearch: '请先点击搜索',
        noSchoolDepart: '请选择学部',
      },
    },
    'en-US': {},
  },
}
type Flat = Record<string, Student.Entity>

@Component({ name: 'ShortTermPage', components, i18n })
export default class ShortTermPage extends Mixins(PagePropsMixins) {
  @Student.Getter('list') private readonly studentList!: Student.Getter.List
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Student.Action('fetchList')
  private readonly fetchList!: Student.Action.Fetch

  @Export.Action('exportFile')
  private readonly fetchExportUrl!: ExportTyping.Action.ExportFile
  @Export.Getter('item')
  private readonly getExportUrl!: ExportTyping.Getter.Item
  @Export.Getter('listStatus')
  private readonly getExportStatus!: ExportTyping.Getter.ListStatus

  private readonly prefixCls = 'short-term-page'
  private loading = false
  private page = 1
  private pageSize = 10
  private selection: Student.Entity[] = []
  private flat: Flat = {}
  private parameter: Partial<PageTyping.SearchParameter> = {
    // parentUnitID: 468124,
    // termID: 2019202002,
    // unitID: 100003,
  }
  private searchParameter: Partial<PageTyping.SearchParameter> = {}
  private modal: { name: string | null; data: any } = { name: null, data: {} }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get tableData() {
    if (this.valid !== true) {
      return []
    }
    return this.studentList.map(item => {
      /** 还原当前选中的学生 */
      if (this.flat[item.studentID] !== void 0) {
        return Object.assign({}, item, { _checked: true })
      }
      return item
    })
  }

  get valid() {
    // const { schoolDepartID } = this.searchParameter
    // return (
    //   Boolean(schoolDepartID) ||
    //   schoolDepartID === 0 ||
    //   Boolean(this.parameter.schoolDepartID) ||
    //   this.parameter.schoolDepartID === 0
    // )
    return true
  }

  get noDataText() {
    // if (
    //   Boolean(this.parameter.schoolDepartID) === false ||
    //   Boolean(this.searchParameter.schoolDepartID) === false
    // ) {
    //   return this.$t('message.nonSearch')
    // }
    return this.$t('message.noDataText')
  }

  get tableColumn() {
    if (this.valid !== true || this.tableData.length === 0) {
      return []
    }
    const columns: TableColumn[] = this.columns.map(item => {
      const column: TableColumn = Object.assign({}, item)
      switch (column.key) {
        case 'num': // 序号
          column.render = (createElement, parameter) => {
            const text =
              (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
            return createElement!('span', `${text}`)
          }
          break
        case 'gradeName':
        case 'className':
          column.width = 240
          break
        case 'aliasClassName':
          column.width = 200
          break
        case 'approveStatus':
          column.fixed = 'right'
          break
        case 'applyDate':
          column.slot = 'applyDate'
          break
        default:
          break
      }
      return column
    })
    columns.unshift(
      {
        type: 'selection',
        width: 60,
        fixed: 'left',
        align: 'center',
      },
      {
        title: `${this.$t('text.index')}`,
        render: (createElement, parameter) => {
          const text =
            (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
          return createElement!('span', `${text}`)
        },
        width: 80,
        fixed: 'left',
        align: 'center',
      }
    )
    return columns
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get total() {
    if (this.valid !== true) {
      return 0
    }
    return this.listStatus.total || 0
  }

  get disableButton() {
    return this.total === 0
  }

  get authCode() {
    return this.$route.meta.authCode
  }

  get exportUrl() {
    return this.getExportUrl(`${this.searchParameter}`)
  }

  /** 监听搜索条件 */
  @Watch('parameter.unitID')
  private watchUnitID(value: number, oldValue: unknown) {
    if (this.$route.name !== 'short-term-list') {
      return void 0
    }
    if (oldValue === null || oldValue === undefined) {
      this.searchParameter = { ...this.parameter }
      this.fetch()
    }
  }

  // @Watch('$route', { immediate: true })
  // routechange(to: any, from: any) {
  //   if (to.name == 'short-term-student-detail') {
  //     from.meta.isBack = true
  //   }
  // }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.loading === true) {
        this.$set(this.$data, 'loading', false)
      }
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }

  /** 监听已选学生 */
  @Watch('selection', { immediate: true })
  private watchSelection(selection: Student.Entity[]) {
    // prettier-ignore
    this.$set(this.$data, 'flat', selection.reduce((flat: Flat, item) => {
        return flat[item.studentID] = item, flat
      }, {}))
  }

  @Watch('getExportStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (!creating && previous) {
      if (this.getExportStatus.creatingError !== null) {
        this.$Message.error(this.getExportStatus.creatingError)
        return
      }
      location.href = this.exportUrl.url
    }
  }

  private selectionList(selection: Student.Entity[]): void {
    this.$set(this.$data, 'selection', selection)
  }

  private convertParameter() {
    return convertSearchParameter(this.parameter)
  }

  private fetch() {
    if (
      this.valid !== true /** 参数无效 */ ||
      this.fetching === true /** 正在请求数据 */
    ) {
      /** 以上情况不发起请求 */
      return void 0
    }

    this.fetchList({
      searches: this.convertParameter(),
      columns: this.columns.map(item => item.key || ''),
      page: this.page,
      pageSize: this.pageSize,
      actionType: Constant.ActionType.ShortTerm,
    })
  }

  private handleClick(viewType: string, student: Student.Entity) {
    // if (Boolean(this.parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(`${this.$t('message.noSchoolDepart')}`)
    // }
    switch (viewType) {
      case 'grade':
        this.$set(this.$data, 'modal', {
          name: 'grade-modal',
          data: {
            viewType: 'view',
            parameter: this.searchParameter,
            unitGradeID: student.unitGradeID,
          },
        })
        break
      case 'glass':
        this.$set(this.$data, 'modal', {
          name: 'glass-modal',
          data: {
            viewType: 'view',
            parameter: this.searchParameter,
            classID: student.classID,
          },
        })
        break
      case 'approveStatus':
        if (student.approveStatus === -1) return
        this.$set(this.$data, 'modal', {
          name: 'workflow-modal',
          data: {
            workflowID: student.workflowID,
          },
        })
        break
      case 'sms':
        this.$set(this.$data, 'modal', {
          name: 'sms-modal',
          data: {
            list: (this.selection.length === 0
              ? this.studentList
              : this.selection
            ).map(item => {
              return {
                id: item.studentID,
                name: item.studentName,
                mobile: item.parentMobile,
              }
            }),
            authCode: this.authCode,
            searches: this.searchParameter,
            actionType: Constant.ActionType.ShortTerm,
            pageType: Constant.PageType.Short,
          },
        })
        break
      case 'sms-record':
        this.$router.push({
          name: 'short-term-sms-record',
          query: this.searchParameter,
        })
        break
      case 'view-student': // 查看学生信息
        this.$router.push({
          name: 'short-term-student-detail',
          params: {
            xuexinID: student.studentID,
            unitID: `${this.searchParameter.unitID || ''}`,
            termID: `${this.searchParameter.termID || ''}`,
            parentUnitID: `${student.parentUnitID || ''}`,
          },
        })
        break
      case 'export':
        this.fetchExportUrl({
          ids: this.selection.map(item => item.shortStudentID),
          searches: this.convertParameter(),
          columns: this.columns.map(item => item.key || ''),
          actionType: Constant.ActionType.ShortTerm,
        })
        break
    }
  }

  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    if (Boolean(parameter.unitID) === false) {
      return this.$Message.warning(`${this.$t('message.noParameter')}`)
    }
    this.$set(this.$data, 'page', 1)
    this.$set(this.$data, 'searchParameter', parameter)
    this.fetch()
  }

  /** 已选学生发生改变 */
  private handleSelectionChange(selection: Student.Entity[]) {
    this.$set(this.$data, 'selection', selection)
  }

  /** 页码发生变化 */
  private handlePageChange(page: number) {
    /**
     * 修改本地 `page` 属性值，清空已选数据，并重新请求数据。
     */
    this.$set(this.$data, 'page', page)
    this.$set(this.$data, 'selection', [])
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number) {
    /**
     * 修改本地 `pageSize` 属性值，若当前 `page` 为 1，则手动请求数据。
     */
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }

  /** 窗口功能完成事件处理函数 */
  private handleModalHidden(modalType: string) {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }

  created() {
    // this.fetch()
  }
}
