import { namespace as BindNamespace } from 'vuex-class'
import { handlers } from 'xuexin-vuex/src/util'
import { Ajax } from 'xuexin-vuex/src/ajax'
import EnrollPayData from '../../../types/enroll-pay-data'
import Vue from 'vue'
import omit from 'lodash.omit'

export const namespace = '@xuexin-vuex/enroll-pay-data'

export const types = {
  FETCH_LIST_REQUEST: 'FETCH_LIST_REQUEST',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
  FETCH_LIST_FAILURE: 'FETCH_LIST_FAILURE',

  EXPORT_REQUEST: 'EXPORT_REQUEST',
  EXPORT_SUCCESS: 'EXPORT_SUCCESS',
  EXPORT_FAILURE: 'EXPORT_FAILURE',
}

const state: EnrollPayData.State = {
  headerList: [],
  dataList: [],
}

const actions: EnrollPayData.Actions = {
  /** 查询列表 */
  fetchList(context, payload) {
    const { ...parameter } = payload
    let type = types.FETCH_LIST_REQUEST
    let success = types.FETCH_LIST_SUCCESS
    let failure = types.FETCH_LIST_FAILURE
    let api = `post ${__DATA_REPORT_API__}/studentNum/student`
    return Ajax(context, { type, payload, api, parameter, success, failure })
  },
  /** 导出 */
  export(context, payload) {
    const { ...parameter } = payload
    let type = types.EXPORT_REQUEST
    let success = types.EXPORT_SUCCESS
    let failure = types.EXPORT_FAILURE
    let api = `post ${__DATA_REPORT_API__}/studentNum/student/export`
    return Ajax(context, { type, payload, api, parameter, success, failure })
  },
}

const mutations: EnrollPayData.Mutations = {
  [types.FETCH_LIST_REQUEST](state, { payload }) {
    handlers.request(state, 'fetching')
  },
  [types.FETCH_LIST_SUCCESS](state, { payload, result, total }) {
    Vue.set(state, 'headerList', result?.headerList)
    Vue.set(state, 'dataList', result?.dataList)
    handlers.success(state, 'fetching')
  },
  [types.FETCH_LIST_FAILURE](state, { payload, message }) {
    handlers.failure(state, 'fetching', message)
  },
  /** 导出 */
  [types.EXPORT_REQUEST](state, { payload }) {
    handlers.request(state, 'updating')
  },
  [types.EXPORT_SUCCESS](state, { payload }) {
    handlers.success(state, 'updating')
  },
  [types.EXPORT_FAILURE](state, { payload, message }) {
    handlers.failure(state, 'updating', message)
  },
}

const getters: EnrollPayData.Getters = {
  list() {
    return () => state.dataList
  },
  header() {
    return () => state.headerList
  },
  status() {
    return () => omit(state, ['dataList', 'headerList'])
  },
}

export const { State, Action, Mutation, Getter } = BindNamespace(namespace)
export const Module = { state, actions, mutations, getters, namespaced: true }
export default { State, Action, Mutation, Getter, namespace, types, Module }
