import Vue, { CreateElement } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Region } from 'xuexin-vuex'
import { Cascader } from 'view-design'

@Component({ name: 'RegionSelector', inheritAttrs: false })
export default class RegionSelector extends Vue {
  @Region.Action('fetchList')
  private readonly fetchList!: Region.Action.FetchList
  @Region.Getter('status') private readonly getStatus!: Region.Getter.Status
  @Region.Getter('list') private readonly getList!: Region.Getter.List

  @Prop() private readonly value!: unknown
  @Prop({ default: 'CN' }) private readonly countryCode!: string

  private readonly prefixCls = 'region-selector'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get props() {
    return Object.assign(
      {
        data: this.countries,
        'load-data': this.fetchMore,
      },
      this.$props,
      this.$attrs
    )
  }

  get parameter() {
    return { countryCode: this.countryCode, regionCode: '0' }
  }

  get countries() {
    const list = this.getList({
      countryCode: this.countryCode,
      regionCode: '0',
    })

    return list
  }

  get status() {
    return this.getStatus(this.parameter)
  }

  get fetching() {
    return this.status.fetching !== false
  }

  @Watch('fetching')
  watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (typeof this.status.fetchingError === 'string') {
        return this.$Message.error('查询地区数据失败')
      }
    }
  }

  created() {
    this.fetch()
  }

  render(h: CreateElement) {
    return h(Cascader, {
      class: this.classes,
      props: this.props,
      on: this.$listeners,
    })
  }

  private fetch() {
    const paramter = { countryCode: this.countryCode, regionCode: '0' }
    const status = this.getStatus(paramter)
    if (status.fetching === true || status.loaded === true) {
      return void 0
    }

    this.fetchList(paramter)
  }

  private fetchMore(item: Region.Entity, callback: () => void) {
    if (item.loading === true) {
      return this.$nextTick(() => callback())
    }

    this.fetchList({
      countryCode: this.countryCode,
      regionCode: item.__value,
    })
      .then(callback)
      .catch(callback)
  }
}
