/**
 * 短期入学学生页面路由
 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import ShortTermPage from '@pages/short-term'
import StudentDetailPage from '@pages/student-detail'
import SmsRecordPage from '@pages/sms-record'
import { Constant } from 'xuexin-vuex'

const children: RouteConfig[] = []
// 列表展示
children.push({
  path: '/students/short-term',
  name: 'short-term-list',
  component: ShortTermPage,
  meta: {
    ignore: true,
    authCode: '050605',
    parentAuthCode: '0506',
    keepAlive: false,
    isBack: false,
  },
})
// 学生详情
children.push({
  path: '/students/short-term/:xuexinID/:unitID/:termID',
  name: 'short-term-student-detail',
  component: StudentDetailPage,
  meta: { title: '学生详情', authCode: '050605', parentAuthCode: '0506' },
  props: { actionType: 'detail' },
})
/** 短信发送记录 */
children.push({
  path: 'sms-record',
  name: 'short-term-sms-record',
  component: SmsRecordPage,
  meta: { title: '短信发送记录', authCode: '050605', parentAuthCode: '0506' },
  props: {
    actionType: Constant.ActionType.ShortTerm,
  },
})
export default {
  path: '/students/short-term',
  name: 'short-term',
  meta: { title: '短期入学学生', authCode: '050605' },
  component: RouterViewLayout,
  redirect: { name: 'short-term-list' },
  props: { name: 'short-term' },
  children,
}
