import Vue, { CreateElement, VNode } from 'vue'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { Select, Option } from 'view-design'
import './app-select.scss'

const realValue = (val: any) => {
  if (typeof val === 'string' && `${val}`.trim() !== '') {
    return val
  }

  if (typeof val === 'number') {
    return val
  }

  if (Boolean(val)) {
    return val
  }

  return null
}

const components = { Option }
@Component({ name: 'AppSelect', components, inheritAttrs: false })
export default class AppSelect extends Vue {
  @Prop({ type: Array, default: () => [] }) private readonly data!: any[]
  @Prop({ type: String, default: 'label' }) private readonly labelField!: string
  @Prop({ type: String, default: 'value' }) private readonly valueField!: string
  @Prop({ type: Boolean, required: false }) private readonly loading!: boolean
  @Prop({ type: String, required: false }) private readonly error!: string
  @Prop({ type: Boolean, default: false }) private readonly multiple!: boolean
  @Prop({ type: Boolean, default: false }) private readonly required!: boolean
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
  @Ref('iSelect') private readonly iSelect!: Select & Vue & any

  private readonly prefixCls = 'app-select'
  private list: any[] = this.data || []
  private model: any = realValue(this.$attrs.value)

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get props() {
    return Object.assign({}, this.$attrs, {
      multiple: this.multiple,
      value: Boolean(this.model) || this.model === 0 ? this.model : null,
      disabled: this.loading || this.disabled,
      maxTagCount: this.multiple ? 0 : void 0,
      maxTagPlaceholder: this.maxTagPlaceholder,
    })
  }

  get listeners() {
    return this.$listeners
  }

  /** 会导致数据默认值冲突，同时发现逻辑多余。 */
  // @Watch('loading')
  // watchLoading(loading: boolean, previous: boolean) {
  //   /** 若是远程数据，在请求完成之后，重新检查 model 的状态 */
  //   if (loading === false && previous === true) {
  //     this.checkValueStatus()
  //   }
  // }

  @Watch('data', { immediate: true })
  watchData(data: any[], previous: any[]) {
    /** 若正在处理请求状态时，不处理本地数据 */
    if (
      this.loading === true || //正在请求数据中
      JSON.stringify(data) === JSON.stringify(this.list) // 两次数据相等
    ) {
      return void 0
    }
    /** 同步本地 list 值 */
    this.$set(this.$data, 'list', data)
  }

  @Watch('$attrs.value', { immediate: true })
  watchAttrsValue(value: any, previous: any) {
    /** 若正在处理请求状态时，不处理本地数据 */
    if (
      this.loading === true || // 正在请求数据中
      JSON.stringify(value) === JSON.stringify(this.model) // 两次数据相等
    ) {
      return void 0
    }
    /** 同步本地 model 值 */
    this.$set(this.$data, 'model', realValue(this.$attrs.value))
  }

  @Watch('model')
  watchModel(model: any, previous: any) {
    this.checkValueStatus()
  }

  @Watch('list')
  watchList(list: any[], previous: any[]) {
    if (this.multiple) {
      let arr: number[] = []
      list.forEach(item => {
        if (this.$attrs.value?.includes(item[this.valueField])) {
          arr.push(item[this.valueField])
        }
      })
      this.$set(this.$data, 'model', realValue(arr))
    }

    this.$emit('on-finish', this.list)
    this.checkValueStatus()
  }

  created() {
    /** 组件创建完成时，若处于请求状态，则不做任何操作。 */
    if (this.loading === true) {
      return void 0
    }

    /** 触发 on-finish 事件 */
    this.$emit('on-finish', this.list)

    /** 检查当前值 */
    this.checkValueStatus()
  }

  render(createElement: CreateElement) {
    const options = this.list.map(item => {
      const label = item[this.labelField]
      const value = item[this.valueField]
      const disabled = item.disabled === true
      const tag = item.tag
      return createElement(
        'Option',
        {
          props: { label, value, disabled, tag },
          key: value,
        },
        [label]
      )
    })

    return createElement(
      Select,
      {
        class: this.classes,
        props: this.props,
        on: this.listeners,
        ref: 'iSelect',
      },
      options
    )
  }

  mounted() {}

  private maxTagPlaceholder(num: number) {
    if (num === 1) {
      return (this.iSelect as any).values[0].label
    }

    if (this.$i18n.locale === 'en-US') {
      return `Select ${num} out of ${this.list.length}`
    }

    return `选中${this.list.length}中的${num}`
  }

  private checkValueStatus() {
    /** 本地 list 长度为 0 时，直接置空 model 值 */
    if (this.list.length === 0) {
      return this.$set(this.$data, 'model', null)
    }

    /** 必选，且单选时 */
    if (this.required === true && this.multiple !== true) {
      const item = this.list.find(item => item[this.valueField] === this.model)
      if (item === void 0) {
        return this.setDefaultValue()
      }
      return void 0
    }
  }

  private setDefaultValue() {
    const [{ [this.valueField]: value }] = this.list

    this.$set(this.$data, 'model', value)
    /**
     * 由于采用直接设置 list 中的项为 value 值时，
     * 不会触发 i-select 中的 input, on-change 事件
     * 所以这里需要手动触发一次
     * */
    return this.$nextTick(() => {
      // console.log('nextTick => on-form-change')
      this.iSelect.$emit('input', value)
      this.iSelect.$emit('on-change', value)
      this.iSelect.dispatch('FormItem', 'on-form-change', value)
    })
  }
}
