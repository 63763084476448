import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ClassType } from 'xuexin-vuex'
import { ComponentType, upperFirst, convertToMulti } from '@util'
import AppSelect from '@components/app-select'

type ViewType = 'text'
const valueField = 'classTypeID'
const labelField = 'classTypeName'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `ClassType${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class ClassTypeComponent extends Vue {
    @ClassType.Action('fetchList')
    private readonly fetchList!: ClassType.Action.FetchList
    @ClassType.Getter('listStatus')
    private readonly getStatus!: ClassType.Getter.ListStatus
    @ClassType.Getter('list')
    private readonly getList!: ClassType.Getter.List

    @Prop({ type: [Number, Array] }) private readonly unitID?: number | number[]
    @Prop({ type: [Number, Array] }) private readonly schoolDepartID?:
      | number
      | number[]
    @Prop({ type: [Number, Array] }) private readonly phaseID?:
      | number
      | number[]
    @Prop({ type: [Number, Array] }) private readonly manageTypeID?:
      | number
      | number[]
    @Prop({ type: [Number, Array] }) private readonly schoolSystemID?:
      | number
      | number[]

    @Prop({ type: Boolean, default: true }) private readonly cache!: boolean
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType?: ViewType

    get classes() {
      return {
        [`class-type-${type}`]: true,
        [`class-type-${type}-${this.viewType}`]: this.viewType !== void 0,
      }
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.loading = this.fetching
          props.error = this.status.fetchingError
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    get parameter() {
      return {
        unitID: convertToMulti(this.unitID),
        schoolDepartID: convertToMulti(this.schoolDepartID),
        phaseID: convertToMulti(this.phaseID),
        manageTypeID: convertToMulti(this.manageTypeID),
        schoolSystemID: convertToMulti(this.schoolSystemID),
      }
    }

    get valid() {
      return Boolean(this.schoolDepartID) || this.schoolDepartID === 0
    }

    get status() {
      return this.getStatus(this.parameter)
    }

    get fetching() {
      return this.status.fetching === true
    }

    get list() {
      if (this.valid !== true) {
        return this.extra
      }
      const list = this.getList(this.parameter).filter(item => {
        // 过滤被禁用的数据
        return item.enable !== 0
      })

      return this.extra.concat(list)
    }

    get value() {
      return Number(this.$attrs.value)
    }

    @Watch('parameter')
    watchParameter() {
      this.fetch()
    }

    created() {
      this.fetch()
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        tag = 'span'
        const item = this.list.find(item => item[valueField] === this.value)
        const text =
          item !== void 0
            ? item[labelField]
            : this.$i18n.locale === 'en-US'
            ? '-'
            : '无匹配项'
        children = [text]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }

    private fetch() {
      if (
        this.status.fetching === true || // 正在请求中
        (this.cache === true && this.status.loaded === true) || // 允许缓存且已有缓存
        this.valid !== true // 参数无效
      ) {
        // 以上情况，不需要请求数据
        return void 0
      }

      this.fetchList(this.parameter)
    }
  }

  return ClassTypeComponent
}

export const ClassTypeSelect = createComponent(AppSelect, ComponentType.Select)
