




























































































































import Vue from 'vue'
import { Component, Model, Prop, Ref, Watch } from 'vue-property-decorator'
import { Glass, Student, Constant, School } from 'xuexin-vuex'
import { Spin, Form, FormItem, Checkbox, Radio } from 'view-design'
import AppModal from '@components/app-modal'
import { DirectionRadioGroup } from '@business-components/direction'
import { SchoolSelect } from '@business-components/school'
import { BusinessTypeSelect } from '@business-components/business-type'
import { ProjectSelect } from '@business-components/project'
import DictionarySelect from '@business-components/dictionary-select'
import i18n from './i18n'

interface Data {
  unitID: number
  termID: number
  unitGradeID: number
  classID: number
  direction: string
  xuexinIDs: string[]
}

interface Model {
  direction: number
  termID: number
  unitID: number
  phaseID: number
  gradeID: number
  manageTypeID: number
  projectID: number
  xuexinIDs: string[]
}

const components = {
  AppModal,
  Spin,
  Form,
  FormItem,
  DirectionRadioGroup,
  SchoolSelect,
  BusinessTypeSelect,
  ProjectSelect,
  DictionarySelect,
  Checkbox,
  Radio,
}
@Component({ name: 'UpgradeModal', components, i18n })
export default class UpgradeModal extends Vue {
  @Glass.Action('fetch')
  private readonly fetchUpgradeClass!: Glass.Action.Fetch
  @Glass.Getter('itemStatus')
  private readonly getClassStatus!: Glass.Getter.ItemStatus
  @Glass.Getter('extra') private readonly getClassExtra!: Glass.Getter.Extra
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus

  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Data
  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'upgrade-modal'
  private hidden = true
  private visible = false
  private loading = true
  private verifying = false
  private model: any = {
    freshStudent: true,
    direction: '2',
  }

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }

  get rules(): FormRules {
    // prettier-ignore
    return {
      direction: [{ required: true, trigger: 'change', validator: this.handleValidator }],
      unitID: [{ required: true, trigger: 'change', validator: this.handleValidator }],
      manageTypeID: [{ required: true, trigger: 'change', validator: this.handleValidator }],
      projectID: [{ required: true, trigger: 'change', validator: this.handleValidator }],
      freshStudent: [{ required: true, trigger: 'change', validator: this.handleValidator }],
      academicDirection: [{ required: true, trigger: 'change', validator: this.handleValidator }],
      studentCategory: [{ required: true, trigger: 'change', validator: this.handleValidator }],
    }
  }

  get updating() {
    return this.listStatus.updating
  }

  get classStatus() {
    return this.getClassStatus(this.data.classID || -1)
  }

  get fetching() {
    return this.classStatus.fetching === true
  }

  get upgradeFetchingError() {
    return this.classStatus.fetchingError
  }

  get hasUpgradeError() {
    return (
      this.fetching === false && typeof this.upgradeFetchingError === 'string'
    )
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (this.visible === true && updating === false && previous === true) {
      this.stopInteraction()

      /** 更新失败 */
      if (this.listStatus.updatingError !== null) {
        return this.$Message.error(this.listStatus.updatingError)
      }

      /** 通知父级元素，操作完成。 */
      return this.$emit('on-finish')
    }
  }
  // @Watch('model.direction')
  // watchDirection(value: number, prev: number) {
  //   if (value == 1 && this.model.phaseID == 4) {
  //     this.$set(this.model, 'freshStudent', true)
  //     this.$set(this.model, 'academicDirection', void 0)
  //     this.$set(this.model, 'studentCategory', void 0)
  //   }
  // }

  @Watch('fetching')
  watchFetching(fetching: boolean, previous: boolean) {
    if (this.visible === true && fetching === false && previous === true) {
      this.$set(this.$data, 'hidden', false)
      if (this.upgradeFetchingError !== null) {
        return void 0
      }
      const upgrade = this.getClassExtra(this.data.classID || -1, 'upgrade')
      this.$set(
        this.$data,
        'model',
        Object.assign(
          {},
          {
            ...this.model,
            ...upgrade,
            unitID: void 0,
            direction: this.model.direction,
          }
        )
      )
      if (upgrade.phaseID !== 4) {
        this.$set(this.model, 'freshStudent', true)
        this.$set(this.model, 'academicDirection', '1')
        this.$set(this.model, 'studentCategory', '3')
      }
    }
  }

  private getLength(list: any) {
    if (list && list.length > 0) {
      this.$set(this.model, 'unitID', list[0].unitID)
    }
  }

  private stopInteraction() {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)

    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleOk() {
    if (this.hasUpgradeError === true) {
      return this.$set(this.$data, 'visible', false)
    }

    this.$set(this.$data, 'verifying', true)
    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        /** 校验失败 */
        return this.stopInteraction()
      }
      const { academicDirection, studentCategory } = this.model
      /** 向后台发送请求 */
      this.update(
        Object.assign({}, this.model, {
          prevTermID: this.data.termID,
          actionType: Constant.ActionType.Upgrade,
          freshStudent: this.model.freshStudent ? 1 : 0,
          studentCategory:
            academicDirection === '5' ? void 0 : studentCategory,
          originalUnitID: this.data.originalUnitID,
        })
      )
    })
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      /** 设定 xuexinIDs，并查询直升信息 */
      this.$set(this.model, 'xuexinIDs', this.data.xuexinIDs)
      this.fetchUpgradeClass({
        termID: this.data.termID,
        unitGradeID: this.data.unitGradeID,
        classID: this.data.classID || -1,
        actionType: Constant.ActionType.Upgrade,
      })
    }

    this.$emit('on-visible-change', visible)
  }

  private handleHidden() {
    this.$emit('on-hidden')
    this.stopInteraction()
    this.$set(this.$data, 'hidden', true)
    this.form.resetFields()
  }

  private handleValidator(
    rule: FormRule,
    value: any,
    callback: FormRuleCallback
  ) {
    if (Boolean(value) === false) {
      return callback(`${this.$t(`validator.${rule.field}`)}`)
    }
    return callback()
  }
}
