export const messages: Messages = {
  'zh-CN': {
    message: {
      noDataText: '暂无数据',
      noParameter: '请先选择搜索条件',
      noTermID: '最少选择到学期',
    },
  },
  'en-US': {},
}

export default messages
