import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { School, Session } from 'xuexin-vuex'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'

type ViewType = 'text'
const valueField = 'id'
const labelField = 'name'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `TrialReadResult${upperFirst(type)}`
  const components = {}
  @Component({ name, components, inheritAttrs: false })
  class TrialReadResultComponent extends Vue {
    /** 学校模块 */
    @School.Action('fetch')
    private readonly fetchSchoolStatus!: School.Action.Fetch
    @School.Getter('item')
    private readonly getSchool!: School.Getter.Item
    @School.Getter('itemStatus')
    private readonly getItemStatus!: School.Getter.ItemStatus
    /** session模块 */
    @Session.Getter('user') private readonly getUser!: Session.Getter.User
    @Prop({ type: Number }) private readonly unitID?: number
    @Prop({ type: Number }) private readonly termID?: number
    @Prop({ type: String }) private readonly viewType?: ViewType
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]

    get classes() {
      return {
        [`result-${type}`]: this.viewType === void 0,
        [`result-${this.viewType}`]: this.viewType !== void 0,
      }
    }
    get parentUnitID() {
      return this.unitID || this.getUser?.parentUnitID
    }
    get organID() {
      return this.getUser?.organID
    }

    get list() {
      const list = this.getSchool(this.parentUnitID!)?.probations
      return list ? this.extra.concat(list) : this.extra
    }

    get paramter() {
      return {
        unitID: this.parentUnitID,
        organID: this.organID,
        termID: this.termID,
      }
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.loading = this.fetching
          props.error = this.status.fetchingError
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    get listeners() {
      return this.$listeners
    }

    get status() {
      return this.getItemStatus(this.parentUnitID!)
    }

    get fetching() {
      return this.status.fetching !== false
    }
    /** 参数发生变化时，重新请求数据 */
    @Watch('paramter')
    watchParameter() {
      this.fetchSchoolStatus(this.paramter)
    }

    created() {
      if (this.status.loaded === true) {
        return void 0
      }

      /** 查询学校配置项 */
      this.fetchSchoolStatus(this.paramter)
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        tag = 'span'
        const item = this.list.find(
          item => item[valueField] === Number(this.$attrs.value)
        )
        const text = item !== void 0 ? item[labelField] : '-'
        children = [text]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.listeners,
        },
        children
      )
    }
  }

  return TrialReadResultComponent
}

export const TrialReadResultSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
