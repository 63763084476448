export const messages: Messages = {
  'zh-CN': {
    table: {
      num: '序号',
      unitName: '学校名称',
      manageType: '报读类型',
      depart: '学部',
      schoolDepart: '学部',
      phase: '学段',
      grade: '年级',
      class: '班级',
      classType: '班级类型',
      unitType: '校本班型',
      inClass: '在校生',
      noDivide: '未分班',
      sex: '性别',
      female: '女',
      male: '男',
      studentStatus: '学生状态',
      normal: '正常',
      rest: '休学',
      out: '外出艺体',
      empty: '空挂学籍',
      liveStatus: '住宿状态',
      live: '住宿',
      walk: '走读',
      before: '原就读',
      outPut: '外招',
      flow: '流失',
      straight: '直升',
      graduate: '当前学期毕业',
    },
    text: {
      noDataText: '暂无数据',
    },
  },
  'en-US': {},
}

export default { messages }
