// http://localhost:3000/students/in-class/2019202002/100003/batch-marks?ids=1057865,1412558,1439953,1559152,1791067,1998297,2023122,2048474,2446478,2705547

export const messages: Messages = {
  'zh-CN': {
    retry: '重试',
    loading: '加载中...',
    backtrack: '返回',
    save: '保存',
    success: '操作成功',
    columns: {
      num: '序号',
      className: '毕业班级',
      studentName: '学生姓名',
      gender: '性别',
      studentMark: '集团优生',
      researchField: '学业方向',
    },
  },
  'en-US': {
    loading: '加载中...',
    backtrack: '返回',
    save: '保存',
    success: '操作成功',
    columns: {
      num: '序号',
      className: '毕业班级',
      studentName: '学生姓名',
      gender: '性别',
      studentMark: '集团优生',
      researchField: '学业方向',
    },
  },
}

export default { messages }
