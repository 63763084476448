export enum TransferStatus {
  In = 'in',
  Out = 'out',
}

export const TransferStatusList = [TransferStatus.In, TransferStatus.Out]

export const messages: Messages = {
  'zh-CN': {
    in: '转入',
    out: '转出',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    in: '转入',
    out: '转出',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
