





























































import Vue from 'vue'
import { Component, Ref, Watch } from 'vue-property-decorator'
import { Button, DatePicker } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import AppTable from '@components/app-table'
import { School, Semester, Session } from 'xuexin-vuex'
import { SemesterSelect } from '@business-components/semester'
import { ActionType } from 'xuexin-vuex/src/constant'
import AppPagination from '@components/app-pagination'
import { DateUtil } from '@util'

import i18n from './i18n'

const components = {
  ViewLayout,
  Button,
  AppTable,
  DatePicker,
  SemesterSelect,
  AppPagination,
}

@Component({ name: 'TermSettingPage', components, i18n })
export default class TermSettingPage extends Vue {
  @Ref('pagination') private readonly pagination!: AppPagination
  @School.Action('fetchList')
  private readonly fetchList!: School.Action.FetchList
  @School.Action('update') private readonly update!: School.Action.Update
  @School.Getter('list') private readonly list!: School.Getter.List
  @School.Getter('listStatus')
  private readonly listStatus!: School.Getter.ListStatus
  @School.Getter('itemStatus')
  private readonly itemStatus!: School.Getter.ItemStatus

  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  private readonly prefixCls = 'term-setting'
  private tableHeight: number | null = null
  private dataList: any = []
  private currentTermID: number = -1
  private page = 1
  private pageSize = 10
  private options: any = {}

  // 查询条件
  get parameter() {
    return {
      xjType: 3,
      termID: this.currentTermID,
      organID: this.getUser ? this.getUser.organID : -1,
      page: this.page,
      pageSize: this.pageSize,
    }
  }
  // 加载状态
  get loading() {
    return this.listStatus(this.parameter).fetching
  }
  // 获取更新状态
  get getListStatus() {
    return this.listStatus(this.parameter)
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  // 表头
  get columns() {
    return [
      {
        title: this.$t('columns.index'),
        width: 80,
        align: 'center',
        slot: 'indexAction',
      },
      { title: this.$t('columns.schoolName'), key: 'parentUnitName' },
      { title: this.$t('columns.campus'), key: 'unitName' },
      { title: this.$t('columns.schoolDepartName'), key: 'schoolDepartName' },
      { title: this.$t('columns.gradeName'), key: 'gradeName' },
      { title: this.$t('columns.startDate'), slot: 'startAction' },
      { title: this.$t('columns.endDate'), slot: 'endAction' },
    ]
  }
  // 获取列表总条数
  get total() {
    return this.listStatus(this.parameter).total === null ||
      this.listStatus(this.parameter).total === void 0
      ? 0
      : this.listStatus(this.parameter).total
  }
  // 判断是否显示分页
  get showPagination(): boolean {
    if (this.total === void 0) {
      return false
    }
    return this.total > 10
  }

  @Watch('getUser', { immediate: true })
  private watchUser(value: any) {
    this.currentTermID = this.getUser?.extraMap.currentTermID || -1
  }
  @Watch('getList')
  private watchList(list: any) {
    if (list.length > 0) {
      this.dataList = list.concat([])
    }
  }
  // 监听更新状态
  @Watch('getListStatus.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (!updating && previous) {
      if (this.getListStatus.updatingError !== null) {
        this.$Message.error(this.getListStatus.updatingError)
        return
      }
      this.fetch()
      this.$Message.success(this.$t('message.update-success'))
    }
  }

  get getList() {
    return this.list(this.parameter)
  }
  private mounted() {
    this.fetch()
  }
  private disabledDate(startdate: any) {
    return {
      disabledDate: (date: any) => {
        return date <= startdate
      },
    }
  }
  // 学期发生变化
  private changeTerm(id: number) {
    this.fetch()
  }
  //保存事件
  private handleSave() {
    //unitID,id互相转换（unitID = id, id = unitID）因为需求变更，为了不改变原来数据的存储，后台返回数据把unitID和id对调了
    this.dataList.forEach((item: any) => {
      const { unitID, id } = item
      item.unitID = id
      item.id = unitID
    })
    this.update({
      actionType: ActionType.Register,
      termID: this.currentTermID,
      termEnrolls: this.dataList,
    }).then(() => {
      this.$Message.success(this.$t('message.save-success'))
      this.fetch()
    })
  }
  //时间发生变化
  private changeDate(date: string, row: School.Entity) {
    // 调用更新事件，更新当前行
    let curTermEnroll = [],
      { id, unitID } = row
    curTermEnroll.push(
      Object.assign({}, row, {
        id: unitID,
        unitID: id,
        endDate: new Date(date).getTime(),
      })
    )
    this.update({
      actionType: ActionType.Register,
      termID: this.currentTermID,
      termEnrolls: curTermEnroll,
    }).then(() => {
      this.$Message.success(this.$t('message.save-success'))
      this.$set(
        this.dataList,
        row._index,
        Object.assign({}, row, {
          endDate: new Date(date).getTime(),
        })
      )
    })
  }
  //点击分页
  private handlePageChange(page: number): void {
    this.page = page
    this.$nextTick(this.fetch)
  }
  //切换分页条数
  private handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize
    if (this.page === 1) {
      this.$nextTick(this.fetch)
    } else {
      this.page = 1
    }
  }
  // 列表调用
  private fetch() {
    this.fetchList(this.parameter)
  }
  // 页面布局设置
  private handleResize({ height }: ResizeParameter) {
    let _height = 64 + 12 * 4
    if (this.pagination !== void 0) {
      _height += this.pagination.getHeight()
    }
    this.$set(this.$data, 'tableHeight', height - _height)
  }
}
