

































import Vue from 'vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import PagePropsMixins from '@mixins/page-props-mixins'

import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'
import AppTable from '@components/app-table'
import AppSelect from '@components/app-select'
import i18n from './i18n'
import { setReportData, spanChildMethod } from '@util/report'
import ClassReport from '@store/modules/class-report'
import getPageColumns from '@components/report-toolbar/columns'

const components = {
  ViewLayout,
  ReportSearch,
  ReportToolbar,
  AppTable,
  AppSelect,
}
@Component({ name: 'ClassesCompareAnalysis', components, i18n })
export default class ClassesCompareAnalysis extends Vue {
  private readonly prefixCls = 'classes-compare-analysis'
  @ClassReport.Action('fetch')
  private readonly fetch!: ClassReport.Action.Fetch
  @ClassReport.Action('export')
  private readonly export!: ClassReport.Action.Fetch
  @ClassReport.Getter('list')
  private readonly getList!: ClassReport.Getter.List
  @ClassReport.Getter('listStatus')
  private readonly listStatus!: ClassReport.Getter.ListStatus
  private parameter: any = {}
  private searchParameter: any = {}
  private tParameter: any = { countType: 0 }
  private defaultData: any = []
  private rowspans: any = {} //合并行列标记对象{key1：number[]，key2：number[]} key: 合并项数对应的列头(parentUnitName,manageTypeName……), 'number[]':当前列中每个单元格应合并的行数
  private sumKey: string[] = [] //选中显示的列头集合
  private inventedDataIndexArr: any = [] //小记行索引和项数{index:小记行在列表中的索引，type:小记几项}

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  // 列表加载状态
  get loading() {
    return this.getListStatus.fetching
  }
  // 表头
  get columns(): any {
    return getPageColumns('ClassesCompareAnalysis')
      .map((item: any) => {
        return {
          title: item.title,
          key: item.code,
          width: 100,
          fixed: 'left',
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat(this.extraCol)
  }
  // 获取列表状态
  get getListStatus() {
    return this.listStatus('classCompareAnalysis')
  }
  get extraCol() {
    let months: any = [],
      hasYears: string[] = []

    this.titles.forEach((item: any) => {
      if (!hasYears.includes(item.yearName)) {
        hasYears.push(item.yearName)
        months.push({
          title: item.yearName,
          align: 'center',
          children: [
            {
              title: item.termName,
              key: item.termID,
            },
          ],
        })
      } else {
        months[hasYears.indexOf(item.yearName)].children.push({
          title: item.termName,
          key: item.termID,
        })
      }
    })

    return months
  }
  get countColumns() {
    return this.titles.map((item: any) => {
      return item.year + '-' + item.month
    })
  }

  get titles() {
    return this.getList('titles')
  }
  get dataSources() {
    return this.getList('classCompareAnalysis').concat()
  }

  // 监听加载状态
  @Watch('getListStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.getListStatus.fetchingError !== null &&
        this.getListStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.fetchingError)
        return
      }

      let result = setReportData({
        list: this.dataSources,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
        countColumns: this.countColumns,
      })

      this.defaultData = result.dataArr
      this.inventedDataIndexArr = result.indexArr
      this.rowspans = result.rowspans
    }
  }
  // 监听导出状态
  @Watch('getListStatus.deleting')
  private watchDeleting(fetching: boolean, previous: boolean) {
    if (fetching === false && fetching !== previous) {
      if (
        this.getListStatus.deletingError !== null &&
        this.getListStatus.deletingError !== void 0
      ) {
        this.$Message.error(this.getListStatus.deletingError)
        return
      }
    }
  }

  private handleSearch(value: any) {
    this.searchParameter = Object.assign({}, value)
    this.fetch(
      Object.assign({}, value, {
        type: 'classCompareAnalysis',
        countType: this.tParameter.countType,
        columns: this.sumKey,
      })
    )
  }
  private handleExport(value: any) {
    this.export(
      Object.assign({}, value, {
        type: 'classCompareAnalysis',
        countType: this.tParameter.countType,
        columns: this.sumKey,
      })
    ).then(res => {
      window.location.href = `${res.result}`
    })
  }
  // 小记项发生变化
  private handleChangeType(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
      countColumns: this.countColumns,
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }
  // 列表列头发生变化
  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.handleSearch(this.searchParameter)
  }

  created() {
    this.sumKey = getPageColumns('ClassesCompareAnalysis').map(
      (item: any) => {
        return item.code
      }
    )
  }

  // 合并行列方法
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }
  // 小计行的样式
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      if (row.hide) {
        return 'ivu-table-summary-row ivu-table-hide-row'
      }
      return 'ivu-table-summary-row'
    }
  }
}
