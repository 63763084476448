export default {
  messages: {
    'zh-CN': {
      label: {},
      placeholder: {
        mobile: '手机号',
        code: '短信验证码',
      },
      verification: {
        mobileIsNull: '请输入手机号',
        mobileIsInvalid: '手机号非法',
        codeIsNull: '请输入短信验证码',
      },
      text: {
        title: '找回密码',
        'sub-title': '验证码将会发送至你绑定的手机号',
        sendCode: '发送验证码',
        sendSuccess: '验证码已发送',
        resend: '重新发送',
        second: '秒',
        nextStep: '下一步',
      },
    },
    'en-US': {},
  },
}
