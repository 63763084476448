import { Constant } from 'xuexin-vuex'

export type EnrollType = Constant.EnrollType
export const EnrollType = Constant.EnrollType

export const EnrollTypeList = [
  EnrollType.Culture,
  EnrollType.Art,
  EnrollType.IICK,
  EnrollType.International,
]

export const messages: Messages = {
  'zh-CN': {
    [`${EnrollType.Culture}`]: '文化',
    [`${EnrollType.Art}`]: '艺体',
    [`${EnrollType.IICK}`]: '港澳台',
    [`${EnrollType.International}`]: '外国籍',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${EnrollType.Culture}`]: '文化',
    [`${EnrollType.Art}`]: '艺体',
    [`${EnrollType.IICK}`]: '港澳台',
    [`${EnrollType.International}`]: '外国籍',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
