













































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import i18n from '../i18n'

const components = {}
@Component({ name: 'AdmissionInfo', components, i18n })
export default class AdmissionInfo extends Vue {
  @Prop({ type: Array }) private readonly data!: any
  @Prop({ type: Number }) private readonly phaseID!: number
  @Prop({ type: String }) private readonly type!: string

  private readonly prefixCls = 'admission-info'

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }
  private nullValue(arr: any[]) {
    let count = 0
    arr.forEach(item => {
      item.valueName ? count : count++
    })
    return count
  }
}
