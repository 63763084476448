export default {
  messages: {
    'zh-CN': {
      success: '操作成功',
      button: {
        export: '导出',
        sms: '发送短信',
        'sms-record': '短信发送记录',
      },
      text: {
        view: '查看',
        rejoin: '重新入校',
        noDataText: '暂无学生数据',
      },
      title: {},
      warning: {
        noSchoolDepartID: '请至少选择到学部！',
        noUnitID: '请至少选择到校区！',
        'non-search': '请先点击搜索！',
      },
      content: {},
    },
    'en-US': {},
  },
}
