import Vue, { CreateElement } from 'vue'
import { Component } from 'vue-property-decorator'
import { Dropdown, DropdownMenu, DropdownItem, Icon } from 'view-design'
import { Session, Constant } from 'xuexin-vuex'

type Identity = {
  type: number
  name: string
  id: number
}

type CookieConfig = {
  xuexinID: string
  identity: Identity
}

const IdentityType = Constant.IdentityType
const Platform = Constant.Platform
const components = { Dropdown, DropdownMenu, DropdownItem, Icon }
@Component({ name: 'IdentitySelector', components })
export default class IdentitySelector extends Vue {
  @Session.Action('updateUser')
  private readonly updateUser!: Session.Action.UpdateUser
  @Session.Getter('user') private readonly user!: Session.Entity
  @Session.Getter('units') private readonly units!: Session.Getter.Units
  @Session.Getter('status') private readonly status!: Session.Getter.Status
  private readonly prefixCls = 'identity-selector'
  private readonly cookieKey = `user:${Platform.Education}`
  private identity: Partial<Identity> = {}
  private initClick = true

  get xuexinID() {
    return this.user?.xuexinID
  }

  get list() {
    const { id, type } = this.identity
    return this.units.map(item => {
      return {
        text: item.name,
        value: item.id,
        type: item.type,
        selected: id === item.id && type === item.type,
      }
    })
  }

  /** 认证状态 */
  get unauthorized() {
    return this.status.unauthorized === true
  }

  created() {
    this.initClick = true
    const config: CookieConfig = this.$cookies.get(this.cookieKey)
    if (config === null) {
      this.handleClick(0)
    } else {
      const index = this.list.findIndex(
        item => item.value === config.identity.id
      )
      this.handleClick(index === -1 ? 0 : index)
    }
  }

  render(createElement: CreateElement) {
    if (
      this.identity.name === void 0 ||
      this.identity.name === null ||
      this.list.length < 1
    ) {
      return void 0
    }

    return createElement(
      'Dropdown',
      {
        class: `${this.prefixCls}`,
        props: {
          placement: 'bottom-start',
        },
        on: {
          'on-click': this.handleClick,
        },
      },
      [
        createElement('span', { style: { marginRight: '8px' } }, [
          this.identity.name,
        ]),
        createElement('Icon', { props: { type: 'ios-arrow-down' } }),
        createElement(
          'DropdownMenu',
          { slot: 'list' },
          this.list.map((item, index) => {
            return createElement(
              'DropdownItem',
              { props: { name: index, selected: item.selected } },
              [item.text]
            )
          })
        ),
      ]
    )
  }

  private handleClick(index: number) {
    if (this.xuexinID === void 0 || this.xuexinID === null) {
      return void 0
    }

    const item = this.list[index]
    const identity = { id: item.value, name: item.text, type: item.type }
    this.$set(this.$data, 'identity', identity)

    /** 获取 cookies 中存储的 配置对象 */
    let config = this.$cookies.get(`user:${Platform.Education}`) || {}
    if (config.xuexinID !== this.xuexinID) {
      config = { xuexinID: this.xuexinID }
    }

    /** 当前用户身份类型与ID */
    config.identity = identity
    /** 保存更新后的配置对象到 cookies 中 */
    this.$cookies.set(this.cookieKey, config)

    /** 更新当前配置对象到 vuex 中 */
    this.updateUser({
      identityType: identity.type,
      [item.type === IdentityType.Organization
        ? 'organID'
        : 'parentUnitID']: item.value,
    }).then(() => {
      if (this.initClick === true) {
        return (this.initClick = false)
      }

      // 若登录已失效，则不做处理。
      if (this.unauthorized === true) {
        return void 0
      }
      if (this.$route.name !== 'dashboard') {
        this.$router.replace('/')
      }
    })
  }
}
