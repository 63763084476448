import { Plugin } from 'vuex'
import { Session } from 'xuexin-vuex'
import { createMenus } from '@routes'

// import Auths from '@layouts/app-layout/auths'

const plugins: Array<Plugin<Record<string, any>>> = []

plugins.push(store => {
  store.subscribe(mutation => {
    switch (mutation.type) {
      /** 获取用户信息成功 */
      case `${Session.namespace}/${Session.types.FETCH_SUCCESS}`:
        store.commit(
          `${Session.namespace}/${Session.types.LOGIN_SUCCESS}`,
          mutation.payload
        )
        break
      /** 用户登录成功 */
      case `${Session.namespace}/${Session.types.LOGIN_SUCCESS}`:
        store.commit(`${Session.namespace}/${Session.types.UPDATE_AUTHS}`, {
          payload: { auths: createMenus(mutation.payload.result.auths) },
        })
        break
      default:
        break
    }
  })
})

export default plugins
