




























































































































import Vue from 'vue'
import { Avatar } from 'view-design'
import { Component, Prop } from 'vue-property-decorator'
import i18n from '../i18n'

const components = { Avatar }
@Component({ name: 'BaseInfo', components, i18n })
export default class BaseInfo extends Vue {
  @Prop({ type: Array }) private readonly data!: any
  @Prop({ type: String }) private readonly type!: string

  private readonly prefixCls = 'base-info'
  private isShow = true

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }
  private nullValue(arr: any[]) {
    let count = 0
    arr.forEach(item => {
      item.valueName ? count : count++
    })
    return count
  }
  created() {}
}
