




























































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Row, Col as ViewCol, Spin } from 'view-design'
import { SchoolSelect } from '@business-components/school'
import { SubjectTypeSelect } from '@business-components/subject-type'
import { SchoolDepartSelect } from '@business-components/school-depart'
import Dashboard from '@store/modules/dashboard'
import DashboardType from 'types/dashboard'
import { School, SchoolDepart, Constant, Session } from 'xuexin-vuex'

type ActionType =
  | 'studentStatis'
  | 'lostStatis'
  | 'studentView'
  | 'transaction'
  | 'entranceType'
  | 'statusType'
  | 'grade'
  | 'list-card'

const components = {
  Row,
  ViewCol,
  Spin,
  SchoolSelect,
  SchoolDepartSelect,
}

const i18n = {
  messages: {
    'zh-CN': {
      loading: '加载中',
      all: '全部',
      text: {
        school: '学校',
        schoolDepart: '学部',
        manageType: '报读类型',
      },
    },
    'en-US': {
      loading: '加载中',
      text: {
        school: '学校',
        schoolDepart: '学部',
        manageType: '报读类型',
      },
    },
  },
}

@Component({ name: 'HomeCard', components, i18n })
export default class HomeCard extends Vue {
  @Prop({ type: String }) actionType!: ActionType
  @Prop({ type: Array }) actionTypeArr?: string[]
  /** 若school为学校用户   mechanism是集团用户 ordinary普通用户*/
  @Prop({ type: String }) identityType!: string
  // @Prop({ type: Boolean, default: false }) chartCard?: boolean
  @Prop({ type: Number }) organID!: number
  @Prop({ type: Number }) termID!: number
  @Prop({ type: Number }) parentUnitID!: number
  @Prop({ type: String }) readonly name!: string
  @Prop({ type: String }) readonly className?: string
  @Prop({ type: String }) readonly title?: string
  @Session.Getter('user') readonly getUser!: Session.Entity

  @Dashboard.Action('fetch') readonly fetch!: any
  @Dashboard.Getter('status')
  readonly getStatus!: DashboardType.Getter.Status
  @Dashboard.Getter('item')
  readonly getItem!: DashboardType.Getter.Item
  @School.Getter('listStatus')
  readonly getSchoolStatus!: School.Getter.ListStatus
  readonly prefixCls = 'home-card'
  showSchoolSelect = false
  showSchoolDepartSelect = false
  /** 判断是机构登录还是学校登录（机构默认1，学校默认2） */
  statisticType: number = 1
  model: {
    parentUnitID: number
    unitID: number
    schoolDepartID: number
  } = {
    parentUnitID:
      this.identityType === 'school'
        ? this.parentUnitID
        : this.identityType === 'mechanism'
        ? -1
        : this.parentUnitID,
    unitID: -1,
    schoolDepartID: -1,
  }

  get classes() {
    return {
      page: true,
      card: true,
      [this.prefixCls]: true,
      [this.actionType]: true,
      [this.className || '']: true,
    }
  }

  get parameter() {
    const fetchParameter: {
      parentUnitID?: number
      unitID?: number
      schoolDepartID?: number
      actionType: string
      organID?: number
      statisticType?: number
    } = {
      actionType: this.actionType,
      organID: this.organID,
      parentUnitID: this.model.parentUnitID,
    }

    switch (this.actionType) {
      case 'studentStatis':
        fetchParameter.unitID = -1
        fetchParameter.statisticType = 0
        break
      default:
        fetchParameter.schoolDepartID = this.model.schoolDepartID || -1
        fetchParameter.unitID = this.model.unitID || -1
        break
    }

    return fetchParameter
  }

  get status() {
    return this.getStatus(
      this.actionType === 'grade'
        ? 'studentStatis'
        : this.actionType !== 'list-card'
        ? this.actionType
        : 'studentStatis'
    )
  }

  get loading() {
    return this.status.fetching !== false
  }

  get fetching() {
    return (
      this.getSchoolStatus({
        parentUnitID: this.parameter.parentUnitID,
        termID: this.termID,
      }).fetching !== false
    )
  }

  get loadingError() {
    return this.status.fetchingError
  }

  get item() {
    const args =
      this.actionType === 'grade'
        ? 'studentStatis'
        : this.actionType !== 'list-card'
        ? this.actionType
        : 'studentStatis'
    const result = this.getItem(args)
    return result
  }

  @Watch('identityType')
  watchIdentityType(value: string) {
    this.actionType !== 'list-card' ? this.fetch(this.parameter) : void 0
  }

  @Watch('parentUnitID')
  watchParentUnitID(value: number) {
    this.$set(this.$data.model, 'parentUnitID', value)
  }

  handleSelect(value: number, type: string) {
    if (type === 'parentUnitID') {
      this.$set(this.$data.model, 'unitID', -1)
      this.$set(this.$data.model, 'schoolDepartID', -1)
    } else if (type === 'unitID') {
      this.$set(this.$data.model, 'schoolDepartID', -1)
    }
    this.fetchArr()
  }

  handleClearSchool() {
    this.$set(this.$data.model, 'parentUnitID', -1)
    this.$set(this.$data.model, 'unitID', -1)
    this.$set(this.$data.model, 'schoolDepartID', -1)
    this.fetchArr()
  }

  handleClearUnitID() {
    this.$set(this.$data.model, 'unitID', -1)
    this.$set(this.$data.model, 'schoolDepartID', -1)
    this.fetchArr()
  }

  handleClearSchoolDepar() {
    this.$set(this.$data.model, 'schoolDepartID', -1)
    this.fetchArr()
  }

  handleShowSchoolSelect(list: School.Getter.List) {
    this.$set(this.$data, 'showSchoolSelect', list.length > 1)
    // this.$forceUpdate()
  }

  handleShowSchoolDepartSelect(list: SchoolDepart.Getter.List) {
    this.$set(this.$data, 'showSchoolDepartSelect', list.length > 1)
  }

  fetchArr() {
    if (this.actionTypeArr && this.actionTypeArr.length > 0) {
      this.actionTypeArr.map(item => {
        this.fetch({ ...this.parameter, actionType: item })
      })
    }
  }

  created() {
    if (this.actionType === 'grade' || this.actionType === 'list-card') {
      return void 0
    }
    this.fetch(this.parameter)

    if (this.actionType === 'studentStatis') {
      this.fetch(
        Object.assign({}, this.parameter, { actionType: 'lostStatis' })
      )
    }
  }
}
