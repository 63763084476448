/** 学生人数统计 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import EnrollPayData from '@pages/statistic-reports/enroll-pay-data'

const children: RouteConfig[] = []

children.push({
  path: '/reports/enroll-pay-data',
  name: 'enroll-pay-data-report',
  component: EnrollPayData,
  meta: { ignore: true, authCode: '050718', parentAuthCode: '0507' },
})

export default {
  path: '/reports/enroll-pay-data',
  name: 'enroll-pay-data',
  redirect: { name: 'enroll-pay-data-report' },
  meta: { title: '学生人数统计', authCode: '050718' },
  component: RouterViewLayout,
  props: { name: 'enroll-pay-data' },
  children,
}
