import Vue, { VNodeChildren } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType } from '@util'
import AppSelect from '@components/app-select'

const valueField = 'value'
const labelField = 'label'
const i18n = {
  messages: {
    'zh-CN': {
      1: '无需缴费',
      2: '部分缴费',
      3: '全额缴费',
    },
    'en-US': {
      1: '无需缴费',
      2: '部分缴费',
      3: '全额缴费',
    },
  },
}

function createComponent(
  component: Vue.Component,
  type: ComponentType
): Vue.Component {
  const name = `PaymentSetting${type.charAt(0).toUpperCase() + type.slice(1)}`
  const components = {}
  @Component({ name, components, inheritAttrs: false, i18n })
  class PaymentSettingComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType?: string
    @Prop({ type: Number }) private readonly primaryKey?: number

    get classes() {
      return {
        [`payment-setting-${type}`]: this.viewType === void 0,
        [`payment-setting-${this.viewType}`]: this.viewType !== void 0,
      }
    }
    get list() {
      return this.extra.concat([
        {
          label: this.$t('1') as string,
          value: 1,
        },
        {
          label: this.$t('2') as string,
          value: 2,
        },
        {
          label: this.$t('3') as string,
          value: 3,
        },
      ])
    }

    get props() {
      const props: Record<string, any> = {
        data: this.list,
      }
      switch (type) {
        case ComponentType.Select:
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    render(createElement: Vue.CreateElement) {
      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        if (this.primaryKey === void 0) {
          return createElement('span', { class: this.classes }, [
            this.$attrs.value,
          ])
        } else {
          const activited = this.list.find(item => {
            return item.value === this.primaryKey
          })

          return createElement('span', { class: this.classes }, [
            activited === void 0 ? '' : activited.label,
          ])
        }
      }
      // 以组件方式显示
      let children: VNodeChildren = []
      switch (type) {
        default:
          break
      }
      return createElement(
        component,
        {
          class: `payment-setting-${type}`,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return PaymentSettingComponent
}

export const PaymentSettingSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
