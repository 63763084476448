




































import Vue from 'vue'
import { Component, Ref, Mixins, Watch } from 'vue-property-decorator'

import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import ReportSearch from '@components/report-search'
import ReportToolbar from '@components/report-toolbar'

import getPageColumns from '@components/report-toolbar/columns'
import AppTable from '@components/app-table'
import PageTyping from '../../../../types/page'
import { setReportData, spanChildMethod } from '@util/report'
import i18n from './i18n'

import StudentReport, { ActionType } from '@store/modules/student-report'

const components = {
  ViewLayout,
  ReportSearch,
  AppTable,
  ReportToolbar,
}

type Flat = Record<string, boolean>

@Component({ name: 'StudentCountMonthlyPage', components, i18n })
export default class StudentOverviewPage extends Mixins(PagePropsMixins) {
  @StudentReport.Action('fetchList') private readonly fetchList!: any
  @StudentReport.Action('export') private readonly export!: any
  @StudentReport.Getter('list')
  private readonly getList!: StudentReport.Getter.List
  @StudentReport.Getter('listStatus')
  private readonly listStatus!: StudentReport.Getter.ListStatus

  private readonly prefixCls = 'student-count-monthly'
  private tParameter: any = { countType: 0 }
  private inventedDataIndexArr: any = []
  private searchParameter: any = {}
  private defaultData: any = []
  private parameter: any = {}
  private sumKey: any = []
  private rowspans: any = {}

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  /** 无数据提示 */
  get noDataText() {
    if (this.dataSources.length === 0) {
      return this.$t('text.noDataText')
    }
    return
  }

  get titles() {
    return this.getList('titles') || []
  }

  /** 表格列信息 */
  get tableColumns() {
    return getPageColumns('StudentCountMonthly')
      .map((item: any) => {
        return {
          title: item.title,
          key: item.code,
          fixed: 'left',
        }
      })
      .filter((item: any) => {
        return this.sumKey.includes(item.key)
      })
      .concat(this.extraCol)
  }
  /** 动态月数列表头 */
  get extraCol() {
    let months: any = [],
      hasYears: string[] = []

    if (Boolean(this.titles) === false) {
      return []
    }

    this.titles.forEach((item: any) => {
      if (!hasYears.includes(item.year)) {
        hasYears.push(item.year)
        months.push({
          title: item.year + '年',
          align: 'center',
          children: [
            {
              title: item.month + '月',
              key: item.year + '-' + item.month,
              width: 80,
              algin: 'center',
            },
          ],
        })
      } else {
        months[hasYears.indexOf(item.year)].children.push({
          title: item.month + '月',
          key: item.year + '-' + item.month,
          width: 80,
          algin: 'center',
        })
      }
    })
    return months
  }

  /** 数据源 */
  get dataSources() {
    return this.getList('studentCountMonthly') || []
  }

  get countColumns() {
    return this.titles.map((item: any) => {
      return item.year + '-' + item.month
    })
  }
  get getListStatus() {
    return this.listStatus('studentCountMonthly')
  }
  /** 请求状态 */
  get fetching() {
    return this.getListStatus.fetching === true
  }
  /** 请求错误信息 */
  get fetchingError() {
    return this.getListStatus.fetchingError
  }
  /** 监听请求状态 */
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous !== fetching) {
      if (Boolean(this.fetchingError)) {
        return this.$Message.error(this.fetchingError as string)
      }

      let result = setReportData({
        list: this.dataSources,
        typeVal: this.tParameter.countType,
        sumKeyArr: this.sumKey,
        countColumn: this.countColumns,
      })

      this.inventedDataIndexArr = result.indexArr
      this.defaultData = result.dataArr
      this.rowspans = result.rowspans
    }
  }

  private handleChangeType(value: any) {
    let result = setReportData({
      list: this.dataSources,
      typeVal: value,
      sumKeyArr: this.sumKey,
      countColumns: this.countColumns,
    })

    this.defaultData = result.dataArr
    this.inventedDataIndexArr = result.indexArr
    this.rowspans = result.rowspans
  }

  /** 搜索 */
  private handleSearch(value: any) {
    if (Boolean(value) === false) {
      return
    }
    this.searchParameter = Object.assign({}, value)

    const { endDate = new Date() } = value

    this.fetchList({
      actionType: ActionType.Monthly,
      type: 'studentCountMonthly',
      ...value,
      endDate: new Date(endDate).getTime(),
      countType: this.tParameter.countType,
      columns: this.tableColumns.map((item: any) => item.key || ''),
    })
  }

  /** 导出 */
  private handleExport(value: any) {
    this.export(
      Object.assign({}, value, {
        actionType: ActionType.Monthly,
        type: 'studentCountMonthly',
        countType: this.tParameter.countType,
        columns: this.sumKey,
      })
    ).then((res: any) => {
      if (res.result) {
        window.location.href = `${res.result}`
      }
    })
  }

  /** 列表列头发生变化 */
  private handleChangeColumn(value: any) {
    this.sumKey = value.concat()
    this.handleSearch(this.searchParameter)
  }

  /** 合并行列方法 */
  private objectSpanMethod({ row, column, rowIndex, columnIndex }: any) {
    return spanChildMethod(
      { row, column, rowIndex, columnIndex },
      this.inventedDataIndexArr,
      this.sumKey,
      this.rowspans
    )
  }

  /** 请求数据 */
  private fetch() {
    if (Boolean(this.parameter) === false) {
      return
    }

    const { endDate = new Date() } = this.parameter

    this.fetchList({
      actionType: ActionType.Monthly,
      type: 'studentConuntMonthly',
      ...this.parameter,
      endDate: new Date(endDate).getTime(),
      countType: this.tParameter.countType,
      columns: this.tableColumns.map((item: any) => item.key || ''),
    })
  }

  /** 初始化 */
  created() {
    this.sumKey = getPageColumns('StudentCountMonthly').map((item: any) => {
      return item.code
    })
  }

  /** 小计行的样式 */
  private rowClassName(row: any, index: number) {
    if (row.summary === 'yes') {
      if (row.hide) {
        return 'ivu-table-summary-row ivu-table-hide-row'
      }
      return 'ivu-table-summary-row'
    }
  }
}
