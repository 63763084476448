

























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
@Component({ name: 'ErrorPage' })
export default class ErrorPage extends Vue {
  private errorMsg:any=''
  private redirect_uri:any = ''
  private time:number = 3
  mounted(){
      this.errorMsg=this.$route.query?.errorMsg||'' 

      this.errorMsg=decodeURI((this.$route.query?.errorMsg || '').toString()) || '未知错误' 
      this.redirect_uri=decodeURI((this.$route.query?.redirect_uri||'').toString()) 

      let timerI = setInterval(() =>{
        this.time -= 1
         if(this.time < 1){
           window.location.href = this.redirect_uri
        }
      },1000)
  }
  private toLink(){
      window.location.href = this.redirect_uri
    }
  }
