


















































import Vue from 'vue'
import { Component, Model, Prop, Ref, Watch } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import AppModal from '@components/app-modal'
import { Form, FormItem, Spin, Input } from 'view-design'
import i18n from './i18n'

type DataType = {
  status: Constant.StudentStatus
  termID: number
  studentID: string
}

type ModelType = {
  operatDate: number
}

const ActionType = Constant.ActionType
const components = { AppModal, Form, FormItem, Spin, Input }
@Component({ name: 'StudentStatusModal', components, i18n })
export default class StudentStatusModal extends Vue {
  @Student.Getter('itemStatus')
  private readonly getItemStatus!: Student.Getter.ItemStatus
  @Student.Getter('itemExtra')
  private readonly getItemExtra!: Student.Getter.ItemExtra
  @Student.Action('fetch') private readonly fetchExtra!: Student.Action.Fetch

  @Model('input') private readonly value!: boolean
  @Prop({ required: true, default: () => ({}) })
  private readonly data!: DataType

  private readonly prefixCls = 'student-status-modal'
  private visible = false
  private model: ModelType = { operatDate: new Date().getTime() }

  get termID() {
    return this.data.termID
  }

  get studentID() {
    return this.data.studentID
  }

  get status() {
    return this.data.status === Constant.StudentStatus.LeaveSchool
      ? 'leave-school'
      : 'suspension'
  }

  get itemStatus() {
    return this.getItemStatus(this.studentID)
  }

  get fetching() {
    return this.itemStatus.fetching !== false
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 窗口未处理打开状态时，不处理状态 */
    if (this.value === false || this.visible === false) {
      return void 0
    }

    if (fetching === false && previous === true) {
      /** 查询学生状态失败 */
      if (this.itemStatus.fetchingError !== null) {
        return this.$Message.error(this.itemStatus.fetchingError)
      }

      /** 获取学生额外属性 */
      let extra = this.getItemExtra(this.studentID) || {}
      /** 重写 operator 值 */
      extra = Object.assign({}, extra, {
        operator: ['operatorName', 'departmentName', 'mobile']
          .map(key => extra[key])
          .filter(item => item !== void 0)
          .join(' '),
      })
      const model = this.$set(this.$data, 'model', extra)
    }
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleVisibleChange(visible: boolean) {
    /** 窗口打开时，更新 model 数据 */
    if (visible === true) {
      this.fetchExtra({
        actionType: ActionType.Status,
        studentID: this.studentID,
        termID: this.termID,
      })
    }

    this.$emit('on-visible-change')
  }

  private handleHidden() {
    this.$emit('on-hidden')
  }
}
