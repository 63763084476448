import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import { Spin, Button, TableColumn } from 'view-design'
import AppTable from '@components/app-table'
import ViewLayout from '@layouts/view-layout'
import { StudentMarkSelect } from '@business-components/student-mark'
import { ResearchFieldSelect } from '@business-components/research-field'
import ClassModal from '@business-components/glass-modal'

import i18n from './i18n'
import './batch-edit-academic-label.scss'

// <!-- 班级信息窗口 -->
// <ClassModal
//   :value="modal.name === 'class-modal'"
//   :data="modal.data"
//   @on-hidden="handleModalHidden('class-modal')"
// />

interface SlotScoped {
  column: TableColumn
  index: number
  row: Student.Entity
}

type Selection = Record<
  string,
  { index: number; studentMark?: string; researchField?: string }
>

@Component({ name: 'BatchEditDirectionPage', i18n })
export default class BatchEditDirectionPage extends Vue {
  @Student.Action('fetchList')
  private readonly fetchStudents!: Student.Action.FetchList
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Student.Getter('list') private readonly students!: Student.Getter.List
  @Student.Mutation('CLEAN_LIST')
  private readonly cleanList!: Student.Mutation.CleanList

  private readonly prefixCls = 'batch-edit-academic-label-page'
  private selection: Selection = {}
  private modal: { name: string | null; data: any } = { name: null, data: {} }

  get classes() {
    return {
      card: true,
      page: true,
      [this.prefixCls]: true,
    }
  }

  get termID() {
    return Number(this.$route.params.termID)
  }

  get ids() {
    const ids = (this.$route.query.ids || '') as string
    return ids.split(',')
  }

  get fetching() {
    return this.listStatus.fetching !== false
  }

  get updating() {
    return this.listStatus.updating === true
  }

  get hasError() {
    return (
      this.fetching !== true &&
      typeof this.listStatus.fetchingError === 'string'
    )
  }

  get columns(): TableColumn[] {
    return [
      {
        title: `${this.$t('columns.num')}`,
        key: 'num',
        width: 80,
        align: 'center',
      },
      {
        title: `${this.$t('columns.className')}`,
        slot: 'className',
        width: 220,
      },
      {
        title: `${this.$t('columns.studentName')}`,
        slot: 'studentName',
        width: 120,
        align: 'center',
      },
      {
        title: `${this.$t('columns.gender')}`,
        key: 'genderStr',
        width: 80,
        align: 'center',
      },
      // {
      //   title: `${this.$t('columns.studentMark')}`,
      //   // align: 'center',
      //   slot: 'mark',
      //   width: 120,
      // },
      {
        title: `${this.$t('columns.researchField')}`,
        // align: 'center',
        slot: 'research',
        width: 220,
      },
      { title: ' ' },
    ]
  }

  get list(): Student.Entity[] {
    return this.students.map((item, index) => {
      const { studentID, studentMark, researchField } = item
      return Object.assign({}, item, {
        num: index + 1,
        studentMark: this.selection[studentID].studentMark,
        researchField: this.selection[studentID]?.researchField,
      })
    })
  }

  @Watch('fetching')
  watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }

      /** 初始化数据 */
      this.$set(
        this.$data,
        'selection',
        this.students.reduce<Selection>((selection, item, index) => {
          selection[item.studentID] = {
            index,
            studentMark: `${item.studentMark || ''}`,
            researchField: `${item.researchField || ''}`,
          }
          return selection
        }, {})
      )
    }
  }

  @Watch('updating')
  watchUpdating(updating: boolean, prevous: boolean) {
    if (updating === false && prevous === true) {
      if (this.listStatus.updatingError !== null) {
        return this.$Message.error(this.listStatus.updatingError)
      }

      this.$Message.success(`${this.$t('success')}`)
    }
  }

  created() {
    if (isNaN(this.termID) === true) {
      return this.$Message.error('termID is invalid')
    }

    this.handleRetry()
  }

  render(h: CreateElement) {
    const children: VNodeChildren = []
    switch (true) {
      case this.fetching: // 正在加载
        children.push(
          h(
            Spin,
            {
              props: { fix: true },
            },
            [`${this.$t('loading')}`]
          )
        )
        break
      case this.hasError: // 有错误
        children.push(
          h(
            'div',
            {
              class: `${this.prefixCls}_error`,
            },
            [
              h(
                'span',
                {
                  class: `${this.prefixCls}_message`,
                },
                [this.listStatus.fetchingError]
              ),
              h('br'),
              h(
                Button,
                { props: { type: 'primary' }, on: { click: this.handleRetry } },
                [`${this.$t('retry')}`]
              ),
            ]
          )
        )
        break
      default:
        children.push(this.renderHeader(h))
        children.push(this.renderTable(h))
        break
    }

    children.push(
      h(ClassModal, {
        props: {
          value: this.modal.name === 'class-modal',
          data: this.modal.data,
        },
        on: {
          'on-hidden': () => {
            this.$set(this.$data, 'modal', { name: null, data: {} })
          },
        },
      })
    )

    return h(
      ViewLayout,
      {
        props: { name: this.prefixCls },
      },
      [h('div', { class: this.classes }, children)]
    )
  }

  beforeDestroy() {}

  destroyed() {
    this.cleanList()
  }

  private renderHeader(h: CreateElement) {
    const children: VNodeChildren = []

    children.push(
      h(
        Button,
        {
          props: {
            type: 'text',
            icon: 'ios-arrow-back',
            to: { name: 'in-class-students' },
          },
          style: { float: 'left' },
        },
        [`${this.$t('backtrack')}`]
      )
    )

    children.push(
      h(
        Button,
        {
          props: { type: 'primary' },
          style: { float: 'right' },
          on: { click: this.handleSave },
        },
        [`${this.$t('save')}`]
      )
    )

    return h(
      'div',
      {
        class: `${this.prefixCls}_header`,
      },
      children
    )
  }

  private renderTable(h: CreateElement) {
    return h(AppTable, {
      class: `${this.prefixCls}_table`,
      props: {
        columns: this.columns,
        data: this.list,
      },
      attrs: {
        loading: this.fetching || this.updating,
      },
      scopedSlots: {
        className: ({ row }: SlotScoped) => {
          return h(
            'span',
            {
              class: 'text-link',
              on: {
                click: () => this.handleClick('view-class', row),
              },
            },
            [row.className]
          )
        },
        studentName: ({ row }: SlotScoped) => {
          return h(
            'span',
            {
              class: 'text-link',
              on: {
                click: () => this.handleClick('view-student', row),
              },
            },
            [row.studentName]
          )
        },
        mark: ({ row, index }) => {
          const attrs = {
            value: row.studentMark,
            transfer: true,
            clearable: true,
          }
          const on = {
            input: (value: string) =>
              this.handleStudentMarkChange(row, index, value),
          }
          return h(StudentMarkSelect, { attrs, on })
        },
        research: ({ row, index }) => {
          if (row.phaseID !== 4) {
            return h('span', {}, ['-'])
          }
          const attrs = {
            value: row.researchField,
            transfer: true,
            clearable: true,
          }
          const on = {
            input: (value: string) =>
              this.handleResearchFieldChange(row, index, value),
          }
          return h(ResearchFieldSelect, { attrs, on })
        },
      },
    })
  }

  private handleStudentMarkChange(
    student: Student.Entity,
    index: number,
    value: string
  ) {
    const key = student.studentID || (student.xuexinID as string)
    let item = this.selection[key]
    if (item === void 0) {
      item = { index, studentMark: value }
    } else {
      item = Object.assign({}, item, { studentMark: value })
    }

    this.$set(this.selection, `${key}`, item)
  }

  private handleResearchFieldChange(
    student: Student.Entity,
    index: number,
    value: string
  ) {
    const key = student.studentID || (student.xuexinID as string)
    let item = this.selection[key]
    if (item === void 0) {
      item = { index, researchField: value }
    } else {
      item = Object.assign({}, item, { researchField: value })
    }

    this.$set(this.selection, `${key}`, item)
  }

  private handleSave() {
    const list = Object.keys(this.selection).filter(key => {
      const item = this.selection[key]
      return (
        item.studentMark !== this.students[item.index].studentMark ||
        item.researchField !== this.students[item.index].researchField
      )
    })

    /** 长度为 0 时，表示无改动，则不处理请求。 */
    if (list.length === 0) {
      return void 0
    }

    /** 向后台发送请求 */
    this.update({
      actionType: Constant.ActionType.Labels,
      labels: list.map(xuexinID => {
        const item = this.selection[xuexinID]
        const student = this.students[item.index]
        return {
          xuexinID,
          studentMark: item.studentMark,
          researchField: item.researchField,
          phaseID: student.phaseID,
          classID: student.classID,
          unitID: student.unitID,
          schoolDepartID: student.schoolDepartID,
          termID: student.termID,
        }
      }),
    })
  }

  private handleRetry() {
    this.fetchStudents({
      actionType: Constant.ActionType.Batch,
      termID: this.termID,
      xuexinIDs: this.ids,
    })
  }

  private handleClick(action: string, student: Student.Entity) {
    switch (action) {
      case 'view-class':
        this.$set(this.$data, 'modal', {
          name: 'class-modal',
          data: {
            viewType: 'view',
            classID: student.classID,
            parameter: { termID: student.termID },
          },
        })
        break
      case 'view-student':
        {
          this.$router.push({
            name: 'in-class-student-detail',
            params: {
              termID: student.termID,
              unitID: `${student.unitID}`,
              xuexinID: `${student.studentID}`,
              parentUnitID: `${student.parentUnitID || ''}`,
            },
          })
        }
        break
      default:
        break
    }
  }
}
