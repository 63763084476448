import Vue from 'vue'
import AssessLossRate from '../../../types/assess-loss-rate'
import { namespace as BindingNamespace } from 'vuex-class'
import omit from 'lodash.omit'
import { handlers } from 'xuexin-vuex/src/util'
import { Ajax } from 'xuexin-vuex/src/ajax'

export const namespace = '@xuexin-vuex/AssessLossRate'
export const types = {
  FETCH_CHART_REQUEST: 'FETCH_CHART_REQUEST',
  FETCH_CHART_SUCCESS: 'FETCH_CHART_SUCCESS',
  FETCH_CHART_FAILURE: 'FETCH_CHART_FAILURE',

  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
}

const state: AssessLossRate.State = {
  chart: { data: {} },
  list: { data: {} },
}

const actions: AssessLossRate.Actions = {
  fetch(context, payload) {
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload: payload,
      api: `post ${__STUDENT_API__}/report/checklost/table`,
      parameter: payload,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
    })
  },
  fetchChart(context, payload) {
    return Ajax(context, {
      type: types.FETCH_CHART_REQUEST,
      payload: payload,
      api: `post ${__STUDENT_API__}/report/checklost/chart`,
      parameter: payload,
      success: types.FETCH_CHART_SUCCESS,
      failure: types.FETCH_CHART_FAILURE,
    })
  },
}

const mutations: AssessLossRate.Mutations = {
  [types.FETCH_REQUEST](state) {
    handlers.request(state.list, 'fetching')
  },
  [types.FETCH_SUCCESS](state, { result }) {
    const item = state.list
    Vue.set(item, 'data', result)
    handlers.success(item, 'fetching')
  },
  [types.FETCH_FAILURE](state, { message }) {
    handlers.failure(state.list, 'fetching', message)
  },
  [types.FETCH_CHART_REQUEST](state) {
    handlers.request(state.chart, 'fetching')
  },
  [types.FETCH_CHART_SUCCESS](state, { result }) {
    const item = state.chart
    Vue.set(item, 'data', result)
    handlers.success(item, 'fetching')
  },
  [types.FETCH_CHART_FAILURE](state, { message }) {
    handlers.failure(state.chart, 'fetching', message)
  },
}

const getters: AssessLossRate.Getters = {
  list() {
    return () => state.list?.data
  },
  listStatus() {
    return () => omit(state.list, ['data'])
  },
  chartItem(state) {
    return () => state.chart?.data
  },
  chartStatus(state) {
    return () => omit(state.chart, ['data'])
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
