/**
 * 学期注册页面路由
 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import RegistrationPage from '@pages/semester-registration'
import StudentDetailPage from '@pages/student-detail'
import SmsRecordPage from '@pages/sms-record'
import { Constant } from 'xuexin-vuex'

const children: RouteConfig[] = []
/** 学生列表 */
children.push({
  path: '/unregistered',
  name: 'semester-registration-list',
  component: RegistrationPage,
  meta: { ignore: true, authCode: '0504', parentAuthCode: '05' },
})

/** 学生详情 */
children.push({
  path: '/unregistered/:xuexinID/:unitID/:termID',
  name: 'semester-registration-student-detail',
  component: StudentDetailPage,
  meta: { title: '学生详情', authCode: '0504', parentAuthCode: '05' },
  props: { actionType: 'detail' },
})
/** 短信发送记录 */
children.push({
  path: 'sms-record',
  name: 'semester-registration-sms-record',
  component: SmsRecordPage,
  meta: { title: '短信发送记录', authCode: '0504', parentAuthCode: '05' },
  props: {
    actionType: Constant.ActionType.Register,
  },
})

export default {
  path: '/unregistered',
  name: 'semester-registration',
  meta: { title: '学期注册', authCode: '0504' },
  redirect: { name: 'semester-registration-list' },
  component: RouterViewLayout,
  props: { name: 'semester-registration' },
  children,
}
