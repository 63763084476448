import Vue from 'vue'
import VueCookies from 'vue-cookies'
import RootLayout from '@layouts/root-layout'
import { Constant } from 'xuexin-vuex'
import i18n from './i18n'
import { createStore } from '@store'
import { createRoute } from '@routes'
import axios from 'axios'
import './view-design'
import './directive'
import '@styles/view-design.less'
import '@styles/index.scss'

Vue.use(VueCookies)
/**
 * default:
 * expireTimes = 1d,
 * path = '/',
 * domain = '',
 * secure = '',
 * sameSite = ''
 */
Vue.$cookies.config('1d', '/', '', false, '')
Vue.config.productionTip = false
/** 添加请求系统类别 */
axios.defaults.headers.common['Accept-Platform'] = Constant.Platform.Education

const initState = {}
const store = createStore(initState)

const AppRoot = new Vue({
  components: { RootLayout },
  i18n,
  store,
  router: createRoute(store),
  render(createElement) {
    return createElement('RootLayout')
  },
})

AppRoot.$mount('#vue-root')
