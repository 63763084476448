import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import modules from './modules'
import plugins from './plugins'

Vue.use(Vuex)

type XueXinVuexRootState = {}

export function createStore(state: any = {}): Store<XueXinVuexRootState> {
  const store = new Vuex.Store<XueXinVuexRootState>({
    strict: true,
    state,
    actions: {},
    mutations: {},
    modules,
    plugins,
  })
  return store
}

export default createStore
