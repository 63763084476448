













































































import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Watch, Prop, Model, Ref } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import { Spin, Form, FormItem, Modal } from 'view-design'
import AppModal from '@components/app-modal'
import { BusinessTypeSelect } from '@business-components/business-type'
import { SchoolClassTypeSelect } from '@business-components/school-class-type'
import { GlassSelect } from '@business-components/glass'
import i18n from './i18n'

type Field = 'manageTypeID' | 'unitClassTypeID'

interface Data {
  termID: number
  unitID: number
  schoolDepartID: number
  phaseID: number
  unitGradeID: number
  xuexinIDs: string[]
}

interface Model {
  termID: number
  unitID: number
  manageTypeID: number
  unitClassTypeID: number
  classID: number
  xuexinIDs: string[]
}

const components = {
  AppModal,
  Spin,
  Form,
  FormItem,
  BusinessTypeSelect,
  SchoolClassTypeSelect,
  GlassSelect,
}

@Component({ name: 'ChangeClassModal', components, i18n })
export default class ChangeClassModal extends Vue {
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Data
  @Ref('form') private readonly form!: Form

  private readonly prefixCls = 'change-class-modal'
  private hidden = true
  private visible = false
  private loading = true
  private verifying = false
  private model: Partial<Model> = {}
  private show: Record<Field, boolean> = {
    manageTypeID: true,
    unitClassTypeID: true,
  }
  // prettier-ignore
  private rules: FormRules = {
    manageTypeID: [
      { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
    ],
    unitClassTypeID: [
      { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
    ],
    classID: [
      { required: true, type: 'number', trigger: 'change', validator: this.handleValidator },
    ],
  }

  get updating() {
    return this.listStatus.updating === true
  }

  @Watch('value', { immediate: true })
  watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('updating')
  watchUpdating(updating: boolean, previous: boolean) {
    if (this.visible === true && updating === false && previous === true) {
      this.stopInteraction()

      if (this.listStatus.updatingError !== null) {
        return this.$Message.error(this.listStatus.updatingError)
      }

      this.$emit('on-finish')
    }
  }

  private handleValidator(
    rule: FormRule,
    value: any,
    callback: FormRuleCallback
  ) {
    if (Boolean(value) === false) {
      return callback(`${this.$t(`error.${rule.field}`)}`)
    }
    return callback()
  }

  private handleFinish(field: string, list: any[]) {
    switch (field) {
      default:
        break
    }
  }

  private stopInteraction() {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)

    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }

  private handleOK() {
    this.$set(this.$data, 'verifying', true)
    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        /** 校验失败 */
        return this.stopInteraction()
      }

      /** 转班操作 */
      this.update(
        Object.assign({}, this.model, {
          actionType: Constant.ActionType.Glass,
        })
      )
    })
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleHidden() {
    this.$emit('on-hidden')
    this.stopInteraction()
    this.$set(this.$data, 'hidden', true)
  }

  private handleVisibleChange(visible: boolean) {
    if (visible === true) {
      /** 初始化 model */
      this.$set(
        this.$data,
        'model',
        Object.assign({}, this.model, {
          termID: this.data.termID,
          unitID: this.data.unitID,
          xuexinIDs: this.data.xuexinIDs,
        })
      )

      this.$set(this.$data, 'hidden', false)
    }

    this.$emit('on-visible-change', visible)
  }
}
