



















































import Vue from 'vue'
import { Component, Prop, Watch, Model } from 'vue-property-decorator'
import { Ajax, Constant } from 'xuexin-vuex'
import i18n from './i18n'

import AppCheckboxGroup from '@components/app-checkbox-group'
import AppModal from '@components/app-modal'
import Export from '@store/modules/export'
import { Button, Spin, Checkbox } from 'view-design'
import { Records } from '../../../types'

const ActionType = Constant.ActionType
const components = { AppModal, Spin, Button, AppCheckboxGroup, Checkbox }

type DataType = {
  list: Array<{ id: string }>
  type: Constant.PageType
  authCode: string
  actionType: Constant.ActionType
  searches: Record<string, any>
}

@Component({ name: 'ExportModal', components, i18n })
export default class ExportModal extends Vue {
  @Model('input') private readonly value!: boolean
  @Prop({ default: () => ({}), required: true })
  private readonly data!: DataType

  @Export.Action('fetchList')
  private readonly fetchList!: Export.Action.FetchList
  @Export.Action('updateItem')
  private readonly updateItem!: Export.Action.UpdateItem
  @Export.Action('exportFile')
  private readonly exportFile!: Export.Action.ExportFile
  @Export.Getter('list') private readonly list!: Export.Getter.List
  @Export.Getter('listStatus')
  private readonly listStatus!: Export.Getter.ListStatus
  @Export.Getter('item') private readonly getItem!: Export.Getter.Item

  private readonly prefixCls = 'export-modal'
  private checked: string[] = []
  private checkboxes: any[] = []
  private checkedCode: any[] = []

  private checkAll = false

  private visible = false
  private loading = true
  private _loading = true
  private isNull = false

  get columnsData() {
    const _columns = this.checkboxes.reduce((result, item) => {
      if (result[item.type] === void 0) {
        let title = this.$t('title.column')
        let show = false
        switch (item.type) {
          case 0:
            title = this.$t('title.student')
            break
          case 1:
            title = this.$t('title.parent')
            break
          case 2:
            title = this.$t('title.admission')
            break
          default:
            break
        }
        result[item.type] = { title, show, data: [item] }
      } else {
        result[item.type].data.push(item)
      }
      return result
    }, {})

    return Object.keys(_columns)
      .sort((key, nextKey) => {
        return Number(key) - Number(nextKey)
      })
      .map(key => {
        return _columns[key]
      })
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get fetchingError() {
    return this.listStatus.fetchingError
  }

  get updating() {
    return this.listStatus.updating === true
  }

  get updatingError() {
    return this.listStatus.updatingError
  }

  get downloading() {
    return this.listStatus.creating === true
  }

  get downloadingError() {
    return this.listStatus.creatingError || null
  }

  get exportUrl() {
    return this.getItem(`${this.searches}`)?.url
  }

  get unitID() {
    return this.searches.unitID
  }

  get type() {
    return this.data?.type
  }

  get authCode() {
    return this.data?.authCode
  }

  get searches() {
    return this.data?.searches
  }

  get dataList() {
    return this.data?.list
  }

  get studentIDs() {
    return this.dataList.map(item => {
      return item.id
    })
    return []
  }

  get actionType() {
    return this.data?.actionType || ActionType.Divide
  }
  get indeterminate() {
    if (this.checkAll === true) {
      return false
    } else {
      if (
        this.checked.length ==
        this.checkboxes.filter(item => item.disabled === true).length
      ) {
        return false
      } else {
        return true
      }
    }
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('fetching', { immediate: true })
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === true && previous === false) {
      return this.$set(this.$data, '_loading', true)
    }

    if (fetching === false && previous === true) {
      if (this.fetchingError !== null) {
        return this.$Message.error(this.fetchingError)
      }

      const _checked: string[] = []
      const _checkboxes: any[] = []

      this.list.forEach(item => {
        if (
          [
            'IDType',
            'IDNO',
            'passportNO',
            'passportAddress',
            'passportDate',
            'parentIDType',
            'parentIDNO',
            'parentPassportNO',
          ].includes(item.columnCode)
        ) {
          let _checkboxItem: any = {}

          if (item.columnCode === 'IDType') {
            _checkboxItem = {
              label: '证件信息',
              value: item.columnCode,
              type: item.dataType,
              disabled: false,
            }
            if (item.show === 1) {
              _checked.push(item.columnCode)
            }

            if (item.fixed === 1) {
              _checkboxItem.disabled = true
            }

            _checkboxes.push(_checkboxItem)
          }
          if (item.columnCode === 'parentIDType') {
            _checkboxItem = {
              label: '证件信息',
              value: item.columnCode,
              type: item.dataType,
              disabled: false,
            }

            if (item.show === 1) {
              _checked.push(item.columnCode)
            }

            if (item.fixed === 1) {
              _checkboxItem.disabled = true
            }

            _checkboxes.push(_checkboxItem)
          }
        } else {
          const _checkboxItem = {
            label: item.columnName,
            value: item.columnCode,
            type: item.dataType,
            disabled: false,
          }

          if (item.show === 1) {
            _checked.push(item.columnCode)
          }

          if (item.fixed === 1) {
            _checkboxItem.disabled = true
          }

          _checkboxes.push(_checkboxItem)
        }
      })

      this.$set(this.$data, 'checked', _checked)
      this.$set(this.$data, 'checkboxes', _checkboxes)

      this.checkAll = _checked.length === _checkboxes.length
      return this.$set(this.$data, '_loading', false)
    }
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      if (this.updatingError !== null) {
        this.$Message.error(this.updatingError)
        return this.stopAction()
      }
    }
  }

  @Watch('downloading')
  private watchDownloading(downloading: boolean, previous: boolean) {
    if (downloading === false && previous === true) {
      if (this.downloadingError !== null) {
        this.$Message.error(this.downloadingError)
        return this.stopAction()
      }

      if (Boolean(this.exportUrl) === true) {
        location.href = this.exportUrl
      } else {
        this.$Message.warning(`${this.$t('text.warning')}`)
        return this.stopAction()
      }

      this.$emit('on-finish')
    }
  }

  /** 展开收起操作 */
  private handleClick(index: number) {
    this.columnsData[index].show = !this.columnsData[index].show
    this.$forceUpdate()
  }

  private stopAction(): void {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleChange(selection: string[], isCheckAll?: boolean) {
    const codeMap = selection.reduce<Record<string, boolean>>(
      (map, code) => ((map[code] = true), map),
      {}
    )
    const columns = this.list.map(item => {
      return Object.assign({}, item, {
        show: codeMap[item.columnCode] === true ? 1 : 0,
        code: item.columnCode,
      })
    })

    this.$set(this.$data, 'checkedCode', columns)

    //更新全选按钮状态
    if (!isCheckAll) {
      if (selection.length != this.checkboxes.length) {
        this.checkAll = false
      } else {
        this.checkAll = true
      }
    }
  }

  private handleOk(): void {
    /**
     * @description 更新选中列数据
     * @param columns 选择的列code码
     * @param authCode 左侧菜单code码
     * @param type 导出列数据保存/其他保存类型
     */

    if (Boolean(this.unitID) === false) {
      return this.stopAction()
    }

    const checked = this.list.reduce((result: any, item) => {
      result.push({
        code: item.columnCode,
        fixed: item.fixed,
        show: item.show,
      })
      return result
    }, [])

    const list = this.list.map(item => {
      return Object.assign({}, item, {
        code: item.columnCode,
      })
    })

    this.updateItem({
      columns: this.checkedCode.length === 0 ? list : this.checkedCode,
      authCode: this.authCode,
    })

    /**
     * @description 更新完成后下载文件
     * @param  columns 选择的列的code码
     * @param ids 选择的学生
     * @param searches 列表页面筛选条件
     * @param unitID 学校ID
     * @returns 返回下载路径
     */

    //按照展示顺序排序
    let sortChecked: any = []
    this.checkboxes.forEach(i => {
      if (this.checked.includes(i.value)) {
        let val = i.value
        if (val === 'IDType') {
          val = 'idInfo'
        }

        if (val === 'parentIDType') {
          val = 'parentIdInfo'
        }
        sortChecked.push(val)
      }
    })

    this.exportFile({
      ids: this.studentIDs,
      unitID: this.unitID,
      searches: this.searches,
      columns: sortChecked,
      actionType: this.actionType,
    })
  }

  private handleVisibleChange(visible: boolean): void {
    /** 获取列名信息 */
    if (visible === true) {
      if (Boolean(this.unitID) === false) {
        this.$set(this.$data, 'isNull', true)
      } else {
        this.$set(this.$data, 'isNull', false)
        this.fetchList(
          Object.assign(
            {},
            {
              type: this.type,
              unitID: this.unitID,
            }
          )
        )
      }
    }
    this.$emit('on-visible-change', visible)
  }

  private handleHidden(): void {
    this.stopAction()
    this.$emit('on-hidden')
    this.checkAll = this.checked.length === this.checkboxes.length
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  //全选事件
  private handleCheckAll(checked: boolean) {
    this.checkAll = checked

    let filterAbled: any = []
    if (this.checkAll) {
      filterAbled = this.checkboxes.map(i => i.value)
    } else {
      filterAbled = this.checkboxes.filter(i => i.disabled).map(i => i.value)
    }
    this.$set(this.$data, 'checked', filterAbled)

    //更新所选项的code,用以记录当前选择状态
    this.handleChange(this.checked, true)
  }
}
