















































































































































































































































































import Vue from 'vue'
import { Component, Watch, Mixins } from 'vue-property-decorator'
import { Student, Session } from 'xuexin-vuex'
import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import { TransferStatusText } from '@business-components/transfer-status'
import { StudentFlowStatusBadge } from '@business-components/student-flow-status'
import { ConfirmSelect } from '@business-components/confirm-status'
import { GroupApprovalSelect } from '@business-components/group-approval-status'
import { ApprovalStatusSelect } from '@business-components/approval-status'
import ExportModal from '@business-components/export-modal'
import SendMessageModal from '@business-components/send-message-modal'
import WorkflowModal from '@business-components/workflow-modal'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import AppModal from '@components/app-modal'
import GlassModal from '@business-components/glass-modal'
import GradeModal from '@business-components/grade-modal'
import ReturnConfirmModal from './components/return-confirm-modal'
import InnerTransferModal from '@business-components/inner-transfer-modal'
import { ActionType, PageType } from 'xuexin-vuex/src/constant'
import { DateUtil } from '@util'
import i18n from './i18n'
import Export from '@store/modules/export'
import ExportTyping from '../../../types/export'
import PageTyping from '../../../types/page'

import { Button, Tooltip, TableColumn } from 'view-design'
const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  Tooltip,
  AppTable,
  AppPagination,
  AppModal,
  TransferStatusText,
  StudentFlowStatusBadge,
  ConfirmSelect,
  GroupApprovalSelect,
  ApprovalStatusSelect,
  GlassModal,
  GradeModal,
  ReturnConfirmModal,
  WorkflowModal,
  ExportModal,
  SendMessageModal,
  InnerTransferModal,
}

@Component({ name: 'InnerTransferPage', components, i18n })
export default class InnerTransferPage extends Mixins(PagePropsMixins) {
  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  @Export.Action('exportFile')
  private readonly exportFile!: ExportTyping.Action.ExportFile
  @Export.Getter('item') private readonly getItem!: ExportTyping.Getter.Item
  @Export.Getter('listStatus')
  private readonly getListStatus!: ExportTyping.Getter.ListStatus

  @Student.Action('fetchList')
  private readonly fetchList!: Student.Action.FetchList
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Student.Getter('list')
  private readonly getStudents!: Student.Getter.List
  @Student.State('list')
  private readonly stateList!: any
  @Student.Getter('itemStatus')
  private readonly itemStatus!: Student.Getter.ItemStatus

  private readonly prefixCls = 'inner-transfer-page'
  private actionType = ActionType
  private backReason = ''
  private curTranferStudentID = -1 //当前行主键
  private SelectTranferStudentIDs = [] // 学生IDs
  private selection = [] // 已选学生列表
  private workflowID = -1
  private showReasonModal = false //查看原因显示状态
  private showReturnModal = false //退回弹窗显示状态
  private showWorkflowModal = false //工作流弹窗显示状态
  private showInnerTransferModal = false //互转状态查看弹窗显示状态
  private curTranferStatus = ''
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private modalData: { name: string | null; data: any } = {
    name: null,
    data: {},
  }
  private parameterLog: Partial<PageTyping.SearchParameter> = {}
  private total = 0
  // 查询参数
  private page = 1
  private pageSize = 10
  private tableData: any = []
  private smsRecordDisabled: boolean = true
  @Watch('parameter.unitID')
  private watchUnitID(value: number, oldValue: unknown) {
    if (oldValue === null || oldValue === undefined) {
      this.parameterLog = { ...this.parameter }
      this.fetch()
    }
  }
  @Watch('getUser', { immediate: true })
  private watchUser(value: any) {
    this.$set(
      this.$data.parameter,
      'termID',
      this.getUser?.extraMap.currentTermID
    )
  }

  // 监听查询学生列表事件状态
  @Watch('listStatus.fetching', { immediate: true })
  private watchFetching(fetching: boolean, previous: boolean) {
    if (!fetching && previous) {
      if (this.listStatus.fetchingError !== null) {
        this.$Message.error(this.listStatus.fetchingError)
        return
      }
      this.$set(this.$data, 'tableData', this.getStudents)
      this.total = this.stateList.total
    }
  }

  // 监听导出状态
  @Watch('getListStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (!creating && previous) {
      if (this.getListStatus.creatingError !== null) {
        this.$Message.error(this.getListStatus.creatingError)
        return
      }
      window.open(this.exportUrl, '_blank')
    }
  }

  private getDate(date: number, format: string) {
    return DateUtil.format(date, format)
  }

  // 页面加载状态
  get loading() {
    return this.listStatus.fetching !== false
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  //获取搜索条件中的unitID
  get unitID() {
    return 1
  }
  get exportUrl() {
    return this.getItem(`${this.parameter}`)?.url
  }
  // 获取当前互转学生状态
  get getItemStatus() {
    return this.itemStatus(this.curTranferStudentID.toString())
  }
  // 获取校际互转学生列表
  get students() {
    return this.getStudents
  }

  get invalidParameter() {
    // const { schoolDepartID } = this.parameterLog
    // return (
    //   Boolean(schoolDepartID) ||
    //   schoolDepartID === 0 ||
    //   Boolean(this.parameter.schoolDepartID) ||
    //   this.parameter.schoolDepartID === 0
    // )
    return true
  }

  get noDataText() {
    // if (
    //   Boolean(this.parameter.schoolDepartID) === false ||
    //   Boolean(this.parameterLog.schoolDepartID) === false
    // ) {
    //   return this.$t('content.noParameter') as string
    // }
    return this.$t('content.noDataText') as string
  }

  // 获取列名称信息
  get _columns() {
    if (this.invalidParameter !== true) {
      return []
    }
    const columns: TableColumn[] = this.columns.map(item => {
      const column: TableColumn = Object.assign({}, item)
      switch (column.key) {
        case 'aliasClassName':
          column.width = 200
          break
        default:
          break
      }
      return column
    })

    if (columns.length < 11) {
      columns.forEach((item: TableColumn) => {
        if (item.key === 'tranferConfirmDate') {
          item.width = 250
        } else if (item.key === 'tranferConfirmStatus') {
          item.width = 180
        } else {
          item.width = undefined
        }
        item.minWidth = 140
      })
    }

    columns.unshift(
      {
        type: 'selection',
        width: 60,
        fixed: 'left',
        align: 'center',
      },
      {
        title: '序号',
        width: 80,
        fixed: 'left',
        slot: 'indexAction',
      }
    )

    return columns.filter(
      item =>
        !(
          item.key === 'studentStatus' ||
          item.key === 'studyDate' ||
          item.key === 'operate'
        )
    )
  }

  // 判断是否显示分页
  get showPagination(): boolean {
    if (this.total === void 0) {
      return false
    }
    return this.total > 10
  }

  private handleClick(type: string, row: Student.Entity) {
    switch (type) {
      case 'export':
        this.exportFile({
          actionType: ActionType.InnerTransfer,
          searches: this.parameterLog,
          columns: [],
          ids: this.selection.map((item: Student.Entity) => {
            return item.tranferStudentID
          }),
        })

        break
      case 'sms':
        let arr1: any = []
        let arr2: string[] = []
        this.selection.forEach((item: Student.Entity) => {
          if (!arr2.includes(item.studentID)) {
            arr1.push({
              id: item.studentID,
              name: item.studentName,
              mobile: item.parentMobile,
            })
            arr2.push(item.studentID)
          }
        })
        this.$set(this.$data, 'modalData', {
          name: 'sms',
          data: {
            list: arr1,
            searches: this.parameterLog,
            columns: this._columns,
            actionType: ActionType.InnerTransfer,
            pageType: PageType.InnerTransfer,
          },
        })
        break
      case 'sms-record' /** 短信发送记录 */:
        const _tranferUnitDate =
          typeof this.parameterLog.tranferUnitDate == 'number'
            ? new Date(this.parameterLog.tranferUnitDate).getTime()
            : null
        this.$router.push({
          name: 'inner-transfer-sms-record',
          query: Object.assign({}, this.parameter, {
            tranferUnitDate: _tranferUnitDate,
          }),
        })
        break
      case 'view-student': // 查看学生信息
        const curUnitID =
          row.tranferStatus === 'in' ? row.inUnitID : row.transferUnitID

        this.$router.push({
          name: 'inner-transfer-student-detail',
          params: {
            xuexinID: row.studentID,
            unitID: `${curUnitID}`,
            termID: `${this.parameter.termID}`,
            tranferStudentID: `${row.tranferStudentID}`,
            parentUnitID: `${row.parentUnitID || ''}`,
          },
        })
        break
      case 'grade':
        this.$set(this.$data, 'modalData', {
          name: 'grade-modal',
          data: {
            viewType: 'view',
            parameter: this.parameter,
            unitGradeID: row.unitGradeID,
          },
        })
        break
      case 'glass':
        this.$set(this.$data, 'modalData', {
          name: 'glass-modal',
          data: {
            viewType: 'view',
            parameter: this.parameter,
            classID: row.classID,
          },
        })
        break
      default:
        break
    }
  }

  // 查看工作流
  private handleCheckWorkflow(id: number) {
    this.workflowID = id
    this.showWorkflowModal = true
  }

  //查看退回原因
  private handleReason(val: string) {
    this.backReason = val
    this.showReasonModal = true
  }

  // 退回按钮事件
  private handleReturn(id: number) {
    this.curTranferStudentID = id
    this.showReturnModal = true
  }

  //确认事件
  private handleConfirm(type: string, id: number) {
    let _title = ''
    let _content = ''
    let _type = -1
    this.curTranferStudentID = id

    switch (type) {
      case 'receive':
        _title = '接收'
        _content = '您确定要接收该学生吗？'
        _type = ActionType.Receive
        break
      case 'revoke':
        _title = '撤销'
        _content = '您确定要撤销该学生的校际互转吗？'
        _type = ActionType.Undo
        break
      case 'resubmit':
        _title = '重新提交'
        _content = '您确定要重新提交审批流吗？'
        _type = ActionType.Resubmit
        break
      default:
        break
    }
    this.$Modal.confirm({
      title: _title,
      content: `<p>${_content}</p>`,
      onOk: () => {
        this.update({
          actionType: _type,
          pageType: PageType.InnerTransfer,
          tranferStudentID: id,
        }).then(res => {
          if (res.status) {
            this.$Message.success(this.$t('content.ctrlSuccess'))
            this.fetch()
            return
          }
          if (res.message && res.message !== void 0) {
            this.$Message.error(res.message)
          }
        })
      },
    })
  }

  /**
   * 查看
   */
  private handleChecked(id: number, type: string, unitID: number) {
    this.$set(this.$data, 'modalData', {
      name: 'innerTransfer',
      data: {
        // outUnitID: 1,
        // studentID: '11',
        tranferStudentID: id,
        tranferStatus: type,
        isCheck: true,
      },
    })
  }

  /**
   * 初始化
   */
  created() {
    // this.fetch()
  }
  private fetch() {
    if (
      this.invalidParameter !== true /** 参数无效 */ ||
      this.listStatus.fetching === true /** 正在请求数据 */
    ) {
      /** 以上情况不发起请求 */
      return void 0
    }
    const _tranferUnitDate =
      typeof this.parameter.tranferUnitDate == 'string'
        ? null
        : new Date(this.parameter.tranferUnitDate!).getTime()
    this.fetchList({
      actionType: ActionType.InnerTransfer,
      pageType: PageType.InnerTransfer,
      page: this.page,
      pageSize: this.pageSize,
      searches: Object.assign({}, this.parameterLog, {
        tranferUnitDate: _tranferUnitDate,
      }),
      columns: this.columns.map(item => item.key || ''),
    })
  }

  /** 窗口功能完成事件处理函数 */
  private handleModalFinish(modal: string, target: string) {
    switch (modal) {
      case 'export':
        window.open(target, '_blank')
        break
      default:
        break
    }
    /** 清空当前窗口 */
    this.$set(this.$data, 'modalData', { name: null, data: {} })
  }

  /** 窗口关闭后处理函数 */
  private handleModalHidden() {
    this.$set(this.$data, 'modalData', { name: null, data: {} })
  }

  //点击分页
  private handlePageChange(page: number): void {
    this.page = page
    this.$nextTick(this.fetch)
  }
  //切换分页条数
  private handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize
    if (this.page === 1) {
      this.$nextTick(this.fetch)
    } else {
      this.page = 1
    }
  }

  // 列表中数据被选择/取消
  private handleSelectionChange(rows: Student.Entity[]) {
    this.$set(this.$data, 'selection', rows)
  }

  private handleSearch(parameter: any) {
    // if (Boolean(parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(`${this.$t('content.noParameter')}`)
    // }
    this.page = 1
    this.$set(this.$data, 'parameter', parameter)
    this.$set(this.$data, 'parameterLog', Object.assign({}, parameter))
    this.fetch()

    this.parameterLog.termID === void 0
      ? (this.smsRecordDisabled = true)
      : (this.smsRecordDisabled = false)
  }
}
