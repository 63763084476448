import { Constant } from 'xuexin-vuex'

export type EnrollScore = Constant.EnrollScore
export const EnrollScore = Constant.EnrollScore

export const EnrollScoreList = [
  EnrollScore.Local,
  EnrollScore.NonLocal,
  EnrollScore.No,
]

export const messages: Messages = {
  'zh-CN': {
    [EnrollScore.Local]: '学校所在地',
    [EnrollScore.NonLocal]: '非学校所在地',
    [EnrollScore.No]: '无成绩',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [EnrollScore.Local]: '学校所在地',
    [EnrollScore.NonLocal]: '非学校所在地',
    [EnrollScore.No]: '无成绩',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
