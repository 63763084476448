export default {
  messages: {
    'zh-CN': {
      label: {},
      placeholder: {
        password: '新密码',
        confirmPassword: '再次输入新密码',
      },
      verification: {
        passwordIsNull: '请输入密码',
        tips: '设置6至20位登录密码。',
        confirmPasswordIsNull: '请输入确认密码',
        differentPasswords: '两次输入的密码不一致',
      },
      text: {
        title: '设置新密码',
        'sub-title': '设置6至20位登录密码。',
        nextStep: '下一步',
      },
    },
    'en-US': {},
  },
}
