
















































































































import Vue, { CreateElement } from 'vue'
import { Component, Watch, Mixins } from 'vue-property-decorator'
import { Button, TableColumn } from 'view-design'
import { Constant, Session } from 'xuexin-vuex'
import { DateUtil, convertSearchParameter } from '@util'
import i18n from './i18n'

import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import PageTyping from 'types/page'

import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import AppPagination from '@components/app-pagination'
import AppTable from '@components/app-table'

import GlassModal from '@business-components/glass-modal'
import GradeModal from '@business-components/grade-modal'

import Records from '@store/modules/records'
import Export from '@store/modules/export'

const components = {
  DynamicSearch,
  DynamicToolbar,
  ViewLayout,
  AppPagination,
  AppTable,
  GlassModal,
  GradeModal,
  Button,
}

/** 记录类型 */
const Record = Constant.ActionType.Record

@Component({ name: 'RecordPage', components, i18n })
export default class RecordPage extends Mixins(PagePropsMixins) {
  @Session.Getter('user') private readonly user!: Session.Getter.User

  @Records.Action('fetchList')
  private readonly fetchList!: Records.Action.FetchList
  @Records.Action('updateItem')
  private readonly updateItem!: Records.Action.UpdateItem

  @Export.Action('exportFile')
  private readonly exportFile!: Export.Action.ExportFile
  @Export.Getter('listStatus')
  private readonly exportStatus!: Export.Getter.ListStatus
  @Export.Getter('item') private readonly getItem!: Export.Getter.Item

  @Records.Getter('list') private readonly list!: Records.Getter.List
  @Records.Getter('listStatus')
  private readonly listStatus!: Records.Getter.ListStatus
  @Records.Getter('itemStatus')
  private readonly itemStatus!: Records.Getter.ItemStatus

  @Records.Mutation('CLEAN_LIST')
  private readonly cleanList!: Records.Mutation.CleanList

  private readonly prefixCls = 'record-view'
  private localParameter: Partial<PageTyping.SearchParameter> = {}
  private modal: { name: string | null; data: any } = { name: null, data: {} }
  private parameter: Partial<PageTyping.SearchParameter> = { termID: void 0 }

  private selection: Records.Entity[] = []
  private undoID = -1 /** 撤销ID */
  private pageSize = 10
  private page = 1

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get status() {
    return this.itemStatus(this.undoID)
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get fetchingError() {
    return this.listStatus.fetchingError || null
  }

  get updating() {
    return this.status.updating === true
  }

  get updatingError() {
    return this.status.updatingError || null
  }

  get downloading() {
    return this.exportStatus.creating === true
  }

  get downloadingError() {
    return this.exportStatus.creatingError || null
  }

  get total() {
    return this.listStatus.total || 0
  }

  get tableColumns(): TableColumn[] {
    const columns: TableColumn[] = this.columns.map(item => {
      switch (item.key) {
        case 'operateContent' /** 操作内容 */:
          return Object.assign({}, item, {
            slot: 'operateContent',
            width: 250,
            ellipsis: true,
          })
        case 'operateType' /** 操作类型 */:
        case 'revoke' /** 撤销 */:
        case 'gradeName' /** 年级名称 */:
        case 'className' /** 班级名称 */:
        case 'studentName' /** 学生姓名 */:
          return Object.assign({}, item, {
            slot: item.key,
            render: void 0,
          })
        case 'createDate' /** 创建日期 */:
        case 'operatorDate' /** 操作日期 */:
          return Object.assign({}, item, {
            width: 150,
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const date = row[`${item.key}`]
              const text =
                typeof date !== 'number' || date === 0
                  ? '-'
                  : DateUtil.format(date, 'yyyy/MM/dd hh:mm')
              return createElement!('span', {}, [text])
            },
          })
        case 'aliasClassName' /** 教学班名 */:
          return Object.assign({}, item, {
            width: 200,
            slot: 'aliasClassName',
            render: void 0,
          })
        default:
          break
      }
      return item
    })

    /** 添加复选框 */
    columns.unshift(
      {
        type: 'selection',
        width: 60,
        fixed: 'left',
        align: 'center',
      },
      {
        title: `${this.$t('index')}`,
        key: 'num',
        width: 80,
        fixed: 'left',
        align: 'center',
      }
    )

    return columns.filter(
      item =>
        !(
          item.key === 'studentStatus' ||
          item.key === 'studyDate' ||
          item.key === 'approveStatus' ||
          item.key === 'tranferStatus' ||
          item.key === 'divideType' ||
          item.key === 'parentMobile' ||
          item.key === 'tranferConfirmStatus' ||
          item.key === 'tranferConfirmDate' ||
          item.key === 'studentType' ||
          item.key === 'payType' ||
          item.key === 'operate'
        )
    )
  }

  get tableData() {
    if (this.valid !== true) {
      return []
    }

    return this.list.map((item, index) => {
      const operateRecord = Object.assign({}, item)
      operateRecord.num = (this.page - 1) * this.pageSize + index + 1
      return operateRecord
    })
  }

  get valid() {
    return true
  }

  get noDataText() {
    return this.$t('text.noDataText')
  }
  @Watch('parameter.unitID')
  private watchSchoolDepartID(value: number, oldValue: unknown) {
    if (oldValue === null || oldValue === undefined) {
      this.localParameter = { ...this.parameter }
      this.fetch()
    }
  }
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.fetchingError !== null) {
        return this.$Message.error(this.fetchingError)
      }
    }
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      if (this.updatingError !== null) {
        return this.$Message.error(this.updatingError)
      }
      this.$Message.success(this.$t('modal.success'))
      this.fetch()
    }
  }

  @Watch('downloading')
  private watchDownloading(creating: boolean, previous: boolean) {
    if (creating === false && previous === true) {
      if (this.downloadingError !== null) {
        return this.$Message.error(this.downloadingError)
      }
      location.href = this.getItem(`${this.parameter}`)?.url
    }
  }

  private convertParameter() {
    return convertSearchParameter(this.localParameter)
  }

  private handleClick(action: string, row: any) {
    switch (action) {
      case 'export' /** 导出 */:
        const ids = this.selection.map(item => {
          return item.recordID
        })
        this.exportFile({
          actionType: Record,
          searches: this.convertParameter(),
          ids,
        })
        break
      case 'view-student' /** 查看学生 */:
        this.$router.push({
          name: 'operate-record-student-detail',
          params: {
            xuexinID: row.studentID,
            unitID: `${row.unitID || ''}`,
            parentUnitID: `${row.parentUnitID || ''}`,
            termID: `${row.termID || ''}`,
          } as any,
          query: {
            tranferStudentID: `${row.tranferStudentID || ''}`,
          },
        })
        break
      case 'view-glass' /** 查看班级 */:
        this.$set(this.$data, 'modal', {
          name: 'glass-modal',
          data: {
            termID: row.termID,
            viewType: 'view',
            classID: row?.classID,
            parameter: this.convertParameter(),
          },
        })
        this.$set(this.$data, 'classID', row.classID)
        break
      case 'view-grade' /** 查看年级 */:
        this.$set(this.$data, 'modal', {
          name: 'grade-modal',
          data: {
            termID: row.termID,
            viewType: 'view',
            unitGradeID: row.unitGradeID,
            parameter: this.convertParameter(),
          },
        })
        break
      case 'revoke' /** 撤销 */:
        // if (row.dataSource === 2) {
        //   return this.$Message.error('请到国际学籍进行撤销操作！')
        // }
        this.$Modal.confirm({
          title: `${this.$t('modal.title')}`,
          content: `${this.$t('modal.content')}`,
          onOk: () => {
            this.$set(this.$data, 'undoID', row.recordID)
            this.updateItem({
              recordID: row.recordID,
              termID: row.termID,
            })
          },
        })
        break
    }
  }

  created() {
    this.cleanList()
    // this.fetch()
  }

  private fetch() {
    if (this.valid !== true) {
      return void 0
    }

    this.fetchList(
      Object.assign(
        {},
        {
          actionType: 2,
          searches: this.convertParameter(),
          page: this.page,
          pageSize: this.pageSize,
        }
      )
    )
  }

  private validDate(parameter: number) {
    return !parameter
  }

  /** 搜索条件发生变化 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    // if (Boolean(parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(`${this.$t('text.noSchoolDepartID')}`)
    // }

    const { operatorDate = [] } = parameter
    if (
      operatorDate.length === 0 ||
      this.validDate(operatorDate[0]) ||
      this.validDate(operatorDate[1])
    ) {
      delete parameter.operatorDate
    }

    if (operatorDate.length > 0) {
      operatorDate[0] = new Date(operatorDate[0]).getTime()
      operatorDate[1] = new Date(operatorDate[1]).getTime()
    }

    this.$set(this.$data, 'localParameter', parameter)
    if (
      !this.parameter.operateType ||
      (this.parameter.operateType && this.parameter.operateType.length < 1)
    ) {
      this.localParameter.operateType = this.parameter.operateTypeList.map(
        item => item.value
      )
    }
    this.$set(this.$data, 'page', 1)
    this.fetch()
  }

  /** 窗口关闭事件处理函数 */
  private handleModalHidden() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
    this.$set(this.$data, 'localParameter', this.parameter)
  }

  /** 窗口功能完成事件处理函数 */
  private handleModalFinish() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
    this.$set(this.$data, 'localParameter', this.parameter)
    this.fetch()
  }

  /** 勾选数据 */
  private handleSelectionChange(rows: Records.Entity[]) {
    this.$set(this.$data, 'selection', rows)
  }

  /** 页码发生变化 */
  private handlePageChange(page: number): void {
    this.$set(this.$data, 'page', page)
    this.$set(this.$data, 'selection', [])
    this.fetch()
  }

  /** 每页数据条数发生变化 */
  private handlePageSizeChange(pageSize: number): void {
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }
}
