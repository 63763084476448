export default {
  messages: {
    'zh-CN': {
      label: {
        userName: '用户名',
        password: '密  码'
      },
      placeholder: {
        userName: '请输入用户名',
        password: '请输入密码'
      },
      verification: {
        userName: '请输入用户名!',
        password: '请输入密码!'
      },
      text: {
        remember: '自动登录',
        forgot: '忘记密码',
        submit: '登录',
        success: '登录成功',
        company: '为明教育集团'
      }
    },
    'en-US': {},
  }
}
