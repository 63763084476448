/**
 * 试读学生页面路由
 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import TrialReadPage from '@pages/trial-reading'
import StudentDetailPage from '@pages/student-detail'
import SmsRecordPage from '@pages/sms-record'
import { Constant } from 'xuexin-vuex'

const children: RouteConfig[] = []
// 列表展示
children.push({
  path: '/students/trial',
  name: 'trial-reading-list',
  component: TrialReadPage,
  meta: {
    ignore: true,
    authCode: '050604',
    parentAuthCode: '0506',
    keepAlive: false,
    isBack: false,
  },
})
// 学生详情
children.push({
  path: '/students/trial/:xuexinID/:unitID/:termID',
  name: 'trial-reading-student-detail',
  component: StudentDetailPage,
  meta: { title: '学生详情', authCode: '050604', parentAuthCode: '0506' },
  props: { actionType: 'detail' },
})
/** 短信发送记录 */
children.push({
  path: 'sms-record',
  name: 'trial-reading-sms-record',
  component: SmsRecordPage,
  meta: { title: '短信发送记录', authCode: '050604', parentAuthCode: '0506' },
  props: {
    actionType: Constant.ActionType.Trial,
  },
})
export default {
  path: '/students/trial',
  name: 'trial-reading',
  meta: { title: '试读学生', authCode: '050604' },
  component: RouterViewLayout,
  props: { name: 'trial-reading' },
  redirect: { name: 'trial-reading-list' },
  children,
}
