const i18n = {
  messages: {
    'zh-CN': {
      title: '编辑反馈',
      studentName: '学生姓名',
      feedbackResult: '试读结果',
      applyDate: '转学时间',
      checkLost: '考核流失',
      inParentUnit: '转至学校',
      inUnit: '转至校区',
      direction: '转至方向',
      targetTerm: '转至学期',
      manageType: '报读类型',
      targetGrade: '转至年级',
      unitClassType: '校本班型',
      enrollDate: '到校日期',
      desc: '备注',
      count: '计入',
      noCount: '不计入',
      domestic: '国内',
      international: '国际',
      null: '-',
      undefined: '-',
      loading: '加载中...',
      placeholder: {
        enrollDate: '请选择到校日期',
      },
      freshStudent: '应届生',
      studentCategory: '学生类别',
      academicDirection: '学业方向/入学分类',
      message: {
        resultType: '请选择试读结果',
        assessment: '请选择考核流失是否计入',
        inParentUnitID: '请选择转至学校',
        inUnitID: '请选择转至校区',
        direction: '请选择转至方向',
        manageTypeID: '请选择报读类型',
        phaseID: '请选择学段',
        targetGradeID: '请选择转至年级',
        unitClassTypeID: '请选择校本班型',
        feedbackResult: '请选择试读结果',
        checkLost: '请选择考核流失',
        enrollDate: '请选择到校日期',
        noChoice: '请选择',
        freshStudent: '请选择应届生',
        studentCategory: '请选择学生类别',
        academicDirection: '请选择学业方向/入学分类',
        transferDate: '请选择实际转学日期',
      },
      error: {
        freshStudent: '请选择应届生',
        studentCategory: '请选择学生类别',
        academicDirection: '请选择学业方向/入学分类',
      },
      label: {
        termName: '转学学期',
        date: '操作日期',
        name: '学生姓名',
        area: '转至地区',
        reason: '转学原因',
        flow: '考核流失',
        school: '转至学校',
        file: '附件',
        mark: '备注',
        tip: '选择文件',
        transferDate: '实际转学日期',
        mealEndDate: '是否就餐',
        stayEndDate: '是否住宿',
        parentBankCard: '家长收款账号',
        isRefund: '是否退费',
        payee: '收款人姓名',
        bankOfDeposit: '开户行',
      },
      text: {
        placeholder: '请输入备注信息',
        reason: '请选择转学原因',
        success: '转学成功',
        size: '文件总大小',
        desc: '上传的文件总大小不能超过10M',
        yes: '是',
        no: '否',
      },

      valid: {
        name: '学生姓名不能为空',
        date: '请选择日期',
        flow: '请选择考核流失信息',
        area: '请选择转入地区',
        reason: '请选择转学原因',
        school: '请填写转至学校',
        card: '请填写收款账号',
        card2: '请输入正确的银行卡号',
        payee: '请输入收款人姓名',
        bankOfDeposit: '请输入开户行',
      },
      button: {
        'do-flow': '计入',
        'un-flow': '不计入',
        uncle: '本地',
        'out-uncle': '外地',
      },
    },
    'en-US': {},
  },
}
export default i18n
