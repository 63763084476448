export default {
  messages: {
    'zh-CN': {
      success: '操作成功',
      button: {
        export: '导出',
        sms: '发送短信',
        'sms-record': '短信发送记录',
        'import-record': '导入记录',
        'template-download': '模板下载',
        'batch-import': '批量导入',
        upgrade: '内转(国际直升)',
      },
      text: {
        view: '查看',
        'edit-graduation': '编辑毕业去向',
        'no-data-text': '暂无学生数据',
      },
      title: {},
      warning: {
        'non-high-school': '请选择相同学段的同学进行操作！',
        'non-classmates': '请选择相同班级的同学进行操作！',
        'no-school-depart-id': '请至少选择到学部！',
        'no-student': '请先搜索学生！',
        'non-search': '请先点击搜索！',
        'repeat-upgrade': '您选择学生中存在已经直升的，请重新选择！',
        'class-inconsistent':
          '您选择学生的年级不一致，请您核对一致后，再进行直升！',
      },
      content: {},
      column: {
        operate: '操作列',
      },
    },
    'en-US': {},
  },
}
