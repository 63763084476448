import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Prop, Ref } from 'vue-property-decorator'
import { Icon } from 'view-design'

@Component({ name: 'ImageView' })
export default class ImageView extends Vue {
  @Prop({ type: String }) private readonly value!: string
  @Ref('file') private readonly file!: HTMLInputElement
  @Ref('img') private readonly image!: HTMLImageElement
  @Prop({ type: String, default: '上传图片' }) private readonly content!: string

  private readonly prefixCls = 'image-view'
  private uuid = ''
  private model = this.value

  render(h: CreateElement) {
    const children: VNodeChildren = []

    if (
      this.model === void 0 ||
      this.model === null ||
      String(this.model).trim() === ''
    ) {
      children.push(
        h(
          'label',
          {
            class: `${this.prefixCls}_upload`,
            attrs: {
              for: this.uuid,
            },
          },
          [
            h(Icon, { props: { type: 'md-add' } }),
            h('br'),
            h('span', {}, [this.content]),
          ]
        )
      )
    } else {
      // 添加图片
      children.push(
        h('img', {
          class: `${this.prefixCls}_image`,
          attrs: { src: this.model },
          ref: 'img',
        })
      )

      /** 添加操作按钮 */
      children.push(
        h('div', { class: `${this.prefixCls}_actions` }, [
          h(Icon, {
            props: { type: 'md-eye' },
            on: { click: this.handleViewClick },
          }),
          h(Icon, {
            props: { type: 'md-trash' },
            on: { click: this.handleDelete },
          }),
        ])
      )
    }

    // 添加上传文件控件
    children.push(
      h('input', {
        ref: 'file',
        attrs: {
          id: this.uuid,
          type: 'file',
          accept: 'image/png, image/jpeg, image/jpg',
          hidden: true,
        },
        on: {
          change: this.handleChange,
        },
      })
    )

    return h('div', { class: `${this.prefixCls}` }, children)
  }

  created() {
    this.$set(this.$data, 'uuid', `${new Date().getTime()}`)
  }

  private handleChange() {
    if (this.file.files === null) {
      return void 0
    }
    const file = this.file.files[0]
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        const base64 = reader.result
        this.$set(this.$data, 'model', base64)
        this.$emit('input', base64)
      },
      { capture: false, once: true }
    )
    reader.readAsDataURL(file)
  }

  private handleViewClick() {
    this.$Modal.info({
      width: 580,
      render: () => {
        return this.$createElement('img', {
          attrs: { src: this.model },
          style: { maxWidth: '500px' },
        })
      },
    })
  }

  private handleDelete() {
    this.$set(this.$data, 'model', '')
    this.file.value = ''
    this.$emit('input', '')
  }
}
