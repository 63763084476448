


















































































































































































































































































































































































































































































































































import Vue from 'vue'
import ViewLayout from '../../layouts/view-layout'
import AppRadioGroup from '@components/app-radio-group'
import { Component } from 'vue-property-decorator'
import HomeCard from './components/home-card'
import HomeInfo from './components/home-info'
import HomeEcharts from './components/home-echarts'
import { Row, Col as ViewCol, Icon } from 'view-design'
import { Session, Constant } from 'xuexin-vuex'
import Dashboard from '@store/modules/dashboard'
import DashboardType from 'types/dashboard'

import i18n from './i18n'
const components = {
  ViewLayout,
  HomeCard,
  HomeInfo,
  Row,
  ViewCol,
  Icon,
  AppRadioGroup,
  HomeEcharts,
}

@Component({ name: 'DashboardPage', components, i18n })
export default class DashboardPage extends Vue {
  @Session.Getter('user') private readonly getUser!: Session.Entity
  @Dashboard.Getter('item')
  readonly getItem!: DashboardType.Getter.Item
  @Dashboard.Getter('status')
  readonly getStatus!: DashboardType.Getter.Status
  readonly prefixCls = 'dashboard-page'
  statistic = 1
  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }
  get studentStatisData(): any {
    return this.getItem('studentStatis')
  }
  get status() {
    return this.getStatus('studentStatis')
  }
  get schoolDepartNames(): string[] {
    return [
      this.$t('text.primarySchool') as string,
      this.$t('text.juniorHighSchool') as string,
      this.$t('text.highSchool') as string,
      this.$t('text.internationalDepartment') as string,
    ]
  }

  get manageTypeNames(): string[] {
    const names = [
      this.$t('text.domestic') as string,
      this.$t('text.quasiInternational') as string,
      this.$t('text.pureInternational') as string,
      this.$t('text.weiming') as string,
    ]
    // console.log(names)
    return names
  }

  get lostStatisLegend(): string[] {
    return [
      this.$t('text.studentCount') as string,
      this.$t('text.transfersCount') as string,
      // this.$t('text.turnoverRate') as string,
    ]
  }
  /** 获取机构id */
  get organID() {
    return this.getUser.organID
  }

  /** 获取学期id */
  get termID() {
    return this.getUser.extraMap.currentTermID
  }

  /** 获取学校id */
  get parentUnitID() {
    return this.identityType === 'school' ? this.getUser.parentUnitID : -1
  }

  /** 若school为学校用户   mechanism是集团用户 ordinary普通用户*/
  get identityType() {
    this.getUser.identityType === Constant.IdentityType.School
      ? (this.statistic = 2)
      : (this.statistic = 1)

    return this.getUser.identityType === Constant.IdentityType.School
      ? 'school'
      : this.getUser.identityType === Constant.IdentityType.Organization
      ? 'mechanism'
      : 'ordinary'
  }
  /** 获取班主任，若是班主任则显示为普通页面 */
  get headTeacher() {
    return !(
      this.getUser.roles.length === 1 &&
      this.getUser.roles.filter(item => item.roleCode === 'unit-bzr')
        .length === 1
    )
  }
  absentee(value: number, sum: number) {
    return Number((value / sum).toFixed(2)) * 100
  }
}
