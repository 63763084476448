const columns = [
  {
    code: 'parentUnitName',
    fixed: 0,
    show: 1,
    title: '学校名称',
  },
  {
    code: 'manageTypeName',
    fixed: 0,
    show: 1,
    title: '报读类型',
  },
  {
    code: 'schoolDepartName',
    fixed: 0,
    show: 1,
    title: '学部',
  },
  {
    code: 'phaseName',
    fixed: 0,
    show: 1,
    title: '学段',
  },
  {
    code: 'unitGradeName',
    fixed: 0,
    show: 1,
    title: '年级',
  },
  {
    code: 'className',
    fixed: 0,
    show: 1,
    title: '班级',
  },
  {
    code: 'headTeacherName',
    fixed: 0,
    show: 1,
    title: '班主任',
  },

  {
    code: 'classTypeName',
    fixed: 0,
    show: 1,
    title: '班级类型',
  },
  {
    code: 'unitClassTypeName',
    fixed: 0,
    show: 1,
    title: '校本班型',
  },
]

export default function getPageColumns(page: string) {
  let cols: any = []
  switch (page) {
    case 'SchoolOverview': //学校一览表
    case 'StudentCompareAnalysis': // 在校人数对比分析
    case 'ClassesCount': //班级数量统计
    case 'ClassesCountMonthly': //班级数量月统计
    case 'ClassesCompareAnalysis': //班级数量对比分析
    case 'StudentInsert': //插班生人数统计
    case 'StudentDistribution': //学生分布统计
      cols = columns.filter(item => {
        return item.code !== 'className' && item.code !== 'headTeacherName'
      })
      break
    case 'StudentOverview' /** 学生一览表统计 */:
      cols = columns.filter(item => {
        return item
      })
    case 'StudentCountMonthly': //学生人数月统计
      cols = columns.filter(item => {
        return item.code !== 'headTeacherName'
      })
      break
    case 'StudentAbnormalChange' /**学生异动统计 */:
      cols = columns
      break
    case 'ActualLossRate': //实际流失率统计
    case 'AssessLossRate': //考核流失率统计
      cols = columns.filter(item => {
        return (
          item.code !== 'phaseName' &&
          item.code !== 'className' &&
          item.code !== 'headTeacherName'
        )
      })
      break
    case 'LossCompareAnalysis': //流失人数统计对比分析
    case 'LossRateCompareAnalysis': //流失率统计对比分析
      cols = columns.filter(item => {
        return (
          item.code !== 'unitGradeName' &&
          item.code !== 'className' &&
          item.code !== 'headTeacherName'
        )
      })
      break
    default:
      cols = columns
      break
  }
  return cols
}
