import Vue, { CreateElement, VNodeChildren } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import AppSelect from '@components/app-select'
import i18n, { GraduateDirectionList } from '../i18n'

type ViewType = 'text'
@Component({ name: 'GraduateDirectionSelect', inheritAttrs: false })
export default class GraduateDirectionSelect extends Vue {
  @Prop({ type: String }) private readonly viewType?: ViewType
  @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
  private readonly prefixCls = 'graduate-direction'

  get locale(): Locale {
    return this.$i18n.locale as Locale
  }

  get classes() {
    const component = this.viewType !== void 0 ? this.viewType : 'select'
    return {
      [`${this.prefixCls}-${component}`]: true,
    }
  }

  get list() {
    if (this.viewType === 'text') {
      return []
    }

    return this.extra.concat(
      GraduateDirectionList.map(value => {
        const label = i18n.messages[this.locale][value]
        return { label, value }
      })
    )
  }

  get props() {
    const props: Record<string, any> = {}

    if (this.viewType === 'text') {
      return props
    }

    props.data = this.list

    return props
  }

  render(createElement: CreateElement) {
    let tag: any = AppSelect
    let children: VNodeChildren = []

    /** 以文本方式显示 */
    if (this.viewType === 'text') {
      tag = 'span'
      const item = this.list.find(
        item => item.value === Number(this.$attrs.value)
      )
      children = [
        item?.label || i18n.messages[this.locale].null || this.$attrs.value,
      ]
    }

    return createElement(
      tag,
      {
        class: this.classes,
        props: this.props,
        attrs: Object.assign({}, this.$attrs),
        on: this.$listeners,
      },
      children
    )
  }
}
