/**
 * 年级管理页面路由
 */
import GradePage from '@pages/grade-management'

export default {
  path: '/grades',
  name: 'grade-management',
  meta: { title: '年级管理', authCode: '0502', parentAuthCode: '05' },
  component: GradePage,
  props: { name: 'grade-management' },
}
