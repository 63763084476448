/**
 * 在校学生页面路由
 */

import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import StudentListPage from '@pages/in-class/student-list'
import StudentPage from '@pages/student'
import StudentDetailPage from '@pages/student-detail'
import SmsRecordPage from '@pages/sms-record'
import BatchEditAcademicLabel from '@pages/batch-edit-academic-label'
import { Constant } from 'xuexin-vuex'

const children: RouteConfig[] = []

/** 在校学生列表 */
children.push({
  path: '/students/in-class',
  name: 'in-class-students',
  component: StudentListPage,
  meta: { ignore: true, authCode: '050601', parentAuthCode: '0506' },
})

/** 短信发送记录 */
children.push({
  path: 'sms-record',
  name: 'in-class-sms-record',
  component: SmsRecordPage,
  meta: { title: '短信发送记录', authCode: '050601', parentAuthCode: '0506' },
  props: {
    actionType: Constant.ActionType.InClass,
  },
})

/** 批量编辑学业标签 */
children.push({
  path: ':termID/:unitID/batch-marks',
  name: 'batch-edit-marks',
  component: BatchEditAcademicLabel,
  meta: { title: '编辑学业标签', authCode: '050601', parentAuthCode: '0506' },
})

/** 学生详情 */
children.push({
  path: ':termID/:unitID/:xuexinID',
  name: 'in-class-student-detail',
  component: StudentDetailPage,
  meta: { title: '学生详情', authCode: '050601', parentAuthCode: '0506' },
  props: { actionType: 'detail' },
})

/** 编辑学生 */
children.push({
  path: ':termID/:unitID/:xuexinID/edit',
  name: 'in-class-student-edit',
  component: StudentPage,
  meta: { title: '编辑学生', authCode: '050601', parentAuthCode: '0506' },
  props: { actionType: 'edit' },
})

export default {
  path: '/students/in-class',
  name: 'in-class',
  redirect: { name: 'in-class-students' },
  meta: { title: '在班学生', authCode: '050601' },
  component: RouterViewLayout,
  props: { name: 'in-class' },
  children,
}
