export enum StudentMark {
  True = '1',
  False = '0',
}

export const StudentMarkList = [StudentMark.True, StudentMark.False]

export const messages: Messages = {
  'zh-CN': {
    [`${StudentMark.True}`]: '是',
    [`${StudentMark.False}`]: '否',
    null: '否',
    undefined: '否',
    '': '否',
  },
  'en-US': {
    [`${StudentMark.True}`]: '是',
    [`${StudentMark.False}`]: '否',
    null: '否',
    undefined: '否',
    '': '否',
  },
}

export default { messages }
