import { Constant } from 'xuexin-vuex'

export type HouseholdIncome = Constant.HouseholdIncome
export const HouseholdIncome = Constant.HouseholdIncome

export const HouseholdIncomeList = [
  HouseholdIncome.Level1,
  HouseholdIncome.Level2,
  HouseholdIncome.Level3,
]

export const messages: Messages = {
  'zh-CN': {
    [HouseholdIncome.Level1]: '10万以下',
    [HouseholdIncome.Level2]: '10万-50万',
    [HouseholdIncome.Level3]: '50万以上',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [HouseholdIncome.Level1]: '10万以下',
    [HouseholdIncome.Level2]: '10万-50万',
    [HouseholdIncome.Level3]: '50万以上',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
