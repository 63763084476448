import { Constant } from 'xuexin-vuex'

export type StatusChangeType = Constant.StatusChangeType
export const StatusChangeType = Constant.StatusChangeType

export type StudentOther = Constant.StudentOther
export const StudentOther = Constant.StudentOther

export type OperateStudentStatus = Constant.OperateStudentStatus
export const OperateStudentStatus = Constant.OperateStudentStatus

export type BasicInformation = Constant.BasicInformation
export const BasicInformation = Constant.BasicInformation

export type ApprovalStatus = Constant.ApprovalStatus
export const ApprovalStatus = Constant.ApprovalStatus

export const StatusChangeTypeList = [
  // StatusChangeType.Graduate /** 毕业 */,
  StatusChangeType.UndoUpgrade /** 撤销直升 */,
  StatusChangeType.Upgrade /** 直升 */,
  StatusChangeType.Reentry /** 重新入校 */,
  StatusChangeType.Downgrade /** 降级 */,
  StatusChangeType.Repeat /** 留级 */,
  StatusChangeType.Skip /** 跳级 */,
  StatusChangeType.DepartChange /** 学部互转 */,
  StatusChangeType.ClassChange /** 转班 */,
  StatusChangeType.DivideClass /** 分班 */,
  StatusChangeType.InnerTransfer /** 校际互转 */,
  StatusChangeType.Transfer /** 转学 */,
  StatusChangeType.Admission /** 录取 */,
  StatusChangeType.CancelAdmission /** 取消录取 */,
  StatusChangeType.ChangeSemester /** 变更报读学期 */,
]
export const StudentOtherList = [
  StudentOther.GraduateDirection /** 毕业去向 */,
  StudentOther.Register /** 学期注册 */,
  StudentOther.Short /** 申请短期入学 */,
  StudentOther.TrialResult /** 试读反馈 */,
  StudentOther.Trial /** 试读 */,
  // StudentOther.DeleteStudent /** 删除学生 */,
  // StudentOther.AddStudent /** 添加学生 */,
]
export const OperateStudentStatusList = [
  OperateStudentStatus.LeaveSchoolBack /** 外出艺体返校 */,
  OperateStudentStatus.LeaveSchool /** 外出艺体 */,
  OperateStudentStatus.Resumption /** 复学 */,
  OperateStudentStatus.Suspension /** 休学*/,
]
export const ApprovalStatusList = [
  ApprovalStatus.NoApproved /** 审批未通过 */,
  ApprovalStatus.Approved /** 审批通过 */,
]

export const BasicInformationList = [
  BasicInformation.ExamNum /** 考号 */,
  // BasicInformation.EnrollType /** 入学分类 */,
  BasicInformation.Status /** 学籍 */,
  BasicInformation.LoanStudent /** 借读生 */,
  // BasicInformation.StudentMark /** 学生标记 */,
  BasicInformation.ResearchField /** 学业方向 */,
  BasicInformation.BillingStudent /** 计费学生类别 */,
  BasicInformation.QualityStudent /** 质量学生类别 */,
  // BasicInformation.AdmitStudent /** 录取学生类别 */,
]

export const messages: Messages = {
  'zh-CN': {
    [`${StudentOther.GraduateDirection}`]: '毕业去向',
    [`${StatusChangeType.Graduate}`]: '毕业',
    [`${StatusChangeType.UndoUpgrade}`]: '撤销直升',
    [`${StatusChangeType.Upgrade}`]: '直升',
    [`${BasicInformation.ExamNum}`]: '考号',
    [`${StatusChangeType.Reentry}`]: '重新入校',
    [`${OperateStudentStatus.LeaveSchoolBack}`]: '外出艺体返校',
    [`${OperateStudentStatus.LeaveSchool}`]: '外出艺体',
    [`${OperateStudentStatus.Resumption}`]: '复学',
    [`${OperateStudentStatus.Suspension}`]: '休学',
    [`${StatusChangeType.Downgrade}`]: '降级',
    [`${StatusChangeType.Repeat}`]: '留级',
    [`${StatusChangeType.Skip}`]: '跳级',
    [`${StatusChangeType.DepartChange}`]: '学部互转',
    [`${StatusChangeType.ClassChange}`]: '转班',
    [`${ApprovalStatus.NoApproved}`]: '审批未通过',
    [`${ApprovalStatus.Approved}`]: '审批通过',
    [`${BasicInformation.EnrollType}`]: '入学分类',
    [`${BasicInformation.Status}`]: '学籍',
    [`${BasicInformation.LoanStudent}`]: '借读生',
    [`${BasicInformation.StudentMark}`]: '学生标记',
    [`${BasicInformation.ResearchField}`]: '学业方向',
    [`${BasicInformation.BillingStudent}`]: '计费学生类别',
    [`${BasicInformation.QualityStudent}`]: '质量学生类别',
    [`${BasicInformation.AdmitStudent}`]: '录取学生类别',
    [`${StudentOther.Register}`]: '学期注册',
    [`${StatusChangeType.DivideClass}`]: '分班',
    [`${StatusChangeType.InnerTransfer}`]: '校际互转',
    [`${StatusChangeType.Transfer}`]: '转学',
    [`${StudentOther.Short}`]: '申请短期入校',
    [`${StudentOther.TrialResult}`]: '试读反馈',
    [`${StudentOther.Trial}`]: '试读',
    [`${StatusChangeType.Admission}`]: '录取',
    [`${StatusChangeType.CancelAdmission}`]: '取消录取',
    [`${StatusChangeType.ChangeSemester}`]: '变更报读学期',
    [`${StudentOther.DeleteStudent}`]: '删除学生',
    [`${StudentOther.AddStudent}`]: '添加学生',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${StudentOther.GraduateDirection}`]: '毕业去向',
    [`${StatusChangeType.Graduate}`]: '毕业',
    [`${StatusChangeType.UndoUpgrade}`]: '撤销直升',
    [`${StatusChangeType.Upgrade}`]: '直升',
    [`${BasicInformation.ExamNum}`]: '考号',
    [`${StatusChangeType.Reentry}`]: '重新入校',
    [`${OperateStudentStatus.LeaveSchoolBack}`]: '外出艺体返校',
    [`${OperateStudentStatus.LeaveSchool}`]: '外出艺体',
    [`${OperateStudentStatus.Resumption}`]: '复学',
    [`${OperateStudentStatus.Suspension}`]: '休学',
    [`${StatusChangeType.Downgrade}`]: '降级',
    [`${StatusChangeType.Repeat}`]: '留级',
    [`${StatusChangeType.Skip}`]: '跳级',
    [`${StatusChangeType.DepartChange}`]: '学部互转',
    [`${StatusChangeType.ClassChange}`]: '转班',
    [`${ApprovalStatus.NoApproved}`]: '审批未通过',
    [`${ApprovalStatus.Approved}`]: '审批通过',
    [`${BasicInformation.EnrollType}`]: '入学分类',
    [`${BasicInformation.Status}`]: '学籍',
    [`${BasicInformation.LoanStudent}`]: '借读生',
    [`${BasicInformation.StudentMark}`]: '学生标记',
    [`${BasicInformation.ResearchField}`]: '学业方向',
    [`${BasicInformation.BillingStudent}`]: '计费学生类别',
    [`${BasicInformation.QualityStudent}`]: '质量学生类别',
    [`${BasicInformation.AdmitStudent}`]: '录取学生类别',
    [`${StudentOther.Register}`]: '学期注册',
    [`${StatusChangeType.DivideClass}`]: '分班',
    [`${StatusChangeType.InnerTransfer}`]: '校际互转',
    [`${StatusChangeType.Transfer}`]: '转学',
    [`${StudentOther.Short}`]: '申请短期入校',
    [`${StudentOther.TrialResult}`]: '试读反馈',
    [`${StudentOther.Trial}`]: '试读',
    [`${StatusChangeType.Admission}`]: '录取',
    [`${StatusChangeType.CancelAdmission}`]: '取消录取',
    [`${StatusChangeType.ChangeSemester}`]: '变更报读学期',
    [`${StudentOther.DeleteStudent}`]: '删除学生',
    [`${StudentOther.AddStudent}`]: '添加学生',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
