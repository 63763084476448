













































































import Vue from 'vue'
import { Component, Model, Prop, Ref, Watch } from 'vue-property-decorator'
import { Student, Constant } from 'xuexin-vuex'
import AppModal from '@components/app-modal'
import {
  Form,
  FormItem,
  Input,
  Upload,
  Button,
  DatePicker,
} from 'view-design'
import i18n from './i18n'

type File = {
  fileName: string
  fileUrl: string
}

/** 请求类型枚举 */
const ActionType = Constant.ActionType
const components = {
  AppModal,
  Form,
  FormItem,
  Input,
  Upload,
  Button,
  DatePicker,
}
@Component({ name: 'SuspensionModal', components, i18n })
export default class SuspensionModal extends Vue {
  @Student.Getter('itemStatus')
  private readonly getItemStatus!: Student.Getter.ItemStatus
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('itemExtra')
  private readonly getItemExtra!: Student.Getter.ItemExtra
  @Student.Action('fetch') private readonly fetchExtra!: Student.Action.Fetch
  @Model('input') private readonly value!: boolean
  @Prop({ required: true }) private readonly data!: Student.Entity
  @Prop({ required: false, default: 0 })
  private readonly type!: number //0:休学操作，1:休学原因查看编辑
  @Ref('form') private readonly form!: Form
  @Ref('textarea') private readonly textarea!: Input

  private readonly prefixCls = 'suspension-modal'
  private name = ''
  private visible = false
  private loading = true
  private verifying = false
  private model: Partial<Student.Parameter> = {
    file: [],
    remarkUrl: '',
    remarkDate: new Date(),
  }
  private defaultFileList = []
  private uploadLoading = false
  private STUDENT_API = __STUDENT_API__

  get rules(): FormRules {
    return {
      remark: [
        {
          type: 'string',
          required: true,
          message: this.$t('verification.remark') as string,
          trigger: 'change',
        },
      ],
    }
  }

  get status() {
    return this.getItemStatus(this.data.studentID)
  }
  get fetching() {
    return this.status.fetching !== false
  }
  get updating() {
    return this.status.updating === true
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 窗口未处理打开状态时，不处理状态 */
    if (this.value === false || this.visible === false) {
      return void 0
    }

    if (fetching === false && previous === true) {
      /** 查询学生状态失败 */
      if (this.status.fetchingError !== null) {
        return this.$Message.error(this.status.fetchingError)
      }

      /** 获取学生额外属性 */
      let extra = this.getItemExtra(this.data.studentID) || {}

      const {
        operateDate,
        remarkUrl,
        remark,
        operatorName,
        studentName,
        remarkDate,
      } = extra

      this.name = studentName

      if (remarkUrl) {
        let lastSlashIndex = remarkUrl.lastIndexOf('/')
        let fileName = remarkUrl.substring(lastSlashIndex + 1)
        this.model.file = [{ fileName, fileUrl: remarkUrl }]
        this.model.remarkUrl = remarkUrl
      }
      if (remark) this.model.remark = remark

      this.model.operateDate = new Date(operateDate)
      this.model.remarkDate = remarkDate ? new Date(remarkDate) : new Date()
      this.model.operatorName = operatorName
    }
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (this.visible === true && updating === false && previous === true) {
      this.stopInteraction()

      if (this.status.updatingError !== null) {
        return this.$Message.error(this.status.updatingError)
      }

      this.$emit('on-finish')
    }
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private handleOk() {
    this.$set(this.$data, 'verifying', true)

    this.form.validate((valid?: boolean) => {
      if (valid !== true) {
        this.$nextTick(() => this.textarea.focus())
        /** 校验失败 */
        return this.stopInteraction()
      }
      const { studentID, remark, termID, remarkUrl, remarkDate } = this.model
      /** 向后台发送请求 */
      this.update({
        actionType: ActionType.Suspension,
        studentID: studentID,
        xuexinID: studentID,
        remark: remark,
        termID: termID,
        unitID: this.data.unitID,
        remarkUrl: remarkUrl,
        isUpdate: this.type === 1 ? 1 : void 0,
        remarkDate: new Date(remarkDate).getTime(),
      })
    })
  }

  private handleBefore(file: File) {
    this.uploadLoading = true
  }
  private handleSuccess(response: {
    result: File[]
    status: boolean
    errmsg: string
  }) {
    this.uploadLoading = false

    if (!response?.status) {
      this.$Message.error(response.errmsg)
      return
    }
    this.model.file = response?.result
    this.model.remarkUrl = response?.result[0].fileUrl
  }
  private handleError(error: string | { message: string }) {
    console.log(error)
    this.$Message.error(error?.message || error)
    this.uploadLoading = false
  }
  private handleExceededSize() {
    this.$Message.error('上传文件最大不得超过20M')
    this.uploadLoading = false
  }

  private handleVisibleChange(visible: boolean) {
    /** 窗口打开时，更新 model 数据 */
    if (visible === true) {
      const { studentID, studentName, termID } = this.data || {}
      this.$set(this.$data, 'model', {
        ...this.model,
        studentID,
        remark: '',
        termID,
      })
      this.$set(this.$data, 'name', studentName)

      this.fetchExtra({
        actionType: ActionType.Status,
        studentID: studentID,
        termID: termID,
      })
    }

    this.$emit('on-visible-change', visible)
  }

  private handleHidden() {
    this.stopInteraction()
    this.$emit('on-hidden')
    this.form.resetFields()
    this.model = {}
  }

  private stopInteraction() {
    this.$set(this.$data, 'loading', false)
    this.$set(this.$data, 'verifying', false)

    this.$nextTick(() => {
      this.$set(this.$data, 'loading', true)
    })
  }
}
