/** 在校生人数对比分析 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import StudentCompareAnalysis from '@pages/statistic-reports/student-compare-analysis'

const children: RouteConfig[] = []
/** 在校生人数对比分析 */
children.push({
  path: '/reports/student-compare-analysis',
  name: 'student-compare-analysis-report',
  component: StudentCompareAnalysis,
  meta: { ignore: true, authCode: '050705', parentAuthCode: '0507' },
})

export default {
  path: '/reports/student-compare-analysis',
  name: 'student-compare-analysis',
  redirect: { name: 'student-compare-analysis-report' },
  meta: { title: '在校生人数对比分析', authCode: '050705' },
  component: RouterViewLayout,
  props: { name: 'student-compare-analysis' },
  children,
}
