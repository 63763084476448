




























































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { convertNumToCH } from '@util'
import i18n from '../i18n'
import { Student, Constant } from 'xuexin-vuex'

const components = {}
@Component({ name: 'FamilyInfo', components, i18n })
export default class FamilyInfo extends Vue {
  @Prop({ type: Array }) private readonly data!: any
  @Prop({ type: String }) private readonly type!: string

  private readonly prefixCls = 'family-info'
  private tabMap: Record<string, any>[] = []
  private isShow = true

  get classes() {
    return {
      [this.prefixCls]: true,
    }
  }
  /** 阿拉伯转汉字 */
  private numToCH(num: number): string {
    return convertNumToCH(num)
  }
  private nullValue(arr: any[]) {
    let count = 0
    arr.forEach(item => {
      item.valueName ? count : count++
    })
    return count
  }
  created() {
    this.data.forEach((item: any, index: number) => {
      const composes: Record<string, Partial<Student.Column>> = {}
      item.columns.forEach((column: any) => {
        let value = column.value

        //处理护照有效期
        if (column.columnCode === 'idInfo') {
          value = JSON.parse(JSON.stringify(column.value))
          value.forEach((i: any) => {
            i.idDate = i.idDate
              ? `${(i.idDate + '').substr(0, 4)}-${(i.idDate + '').substr(
                  4,
                  2
                )}-${(i.idDate + '').substr(6, 2)}`
              : '-'
          })
        }

        const assignColumn = Object.assign({}, column, { value })
        const { columnType, columnCode, parentCode = '' } = assignColumn
        const name = assignColumn.kindCode
        /** 初始化 tab 项 */
        if (this.tabMap[index] === void 0) {
          this.tabMap[index] = {
            group: item.group,
            receiveSms: item.receiveSms,
            columns: [],
          }
        }
        /** 组合节点的父节点 */
        if (Number(columnType) === Number(Constant.ColumnType.Object)) {
          if (composes[columnCode] === void 0) {
            composes[columnCode] = { children: [] }
          }
          assignColumn.children = composes[columnCode].children
          composes[columnCode] = assignColumn
        }
        // 归属于 parentCode 节点
        else if (Boolean(parentCode) === true) {
          if (composes[parentCode] === void 0) {
            composes[parentCode] = { children: [] }
          }
          composes[parentCode].children?.push(assignColumn)
          return void 0
        }
        this.tabMap[index].columns.push(assignColumn)
      })
    })

    this.tabMap.forEach((item: any) => {
      item.columns.forEach((ite: any) => {
        if (ite.columnCode === 'nation' && ite.value !== 'CN') {
          this.$set(this.$data, 'isShow', false)
        }
      })
    })
  }
}
