export enum Stay {
  Male = 0,
  Female = 1,
}

export const StayList = [Stay.Male, Stay.Female]

export const messages: Messages = {
  'zh-CN': {
    [Stay.Male]: '走读',
    [Stay.Female]: '住宿',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [Stay.Male]: '走读',
    [Stay.Female]: '住宿',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
