import { Constant } from 'xuexin-vuex'

export type IDType = Constant.IDType
export const IDType = Constant.IDType

export const IDTypeList = [IDType.IDCard, IDType.IICK]

export const messages: Messages = {
  'zh-CN': {
    [1]: '居民身份证',
    [2]: '香港特区护照/身份证明',
    [3]: '澳门特区护照/身份证明',
    [4]: '境外永久居住证',
    [5]: '台湾居民来往大陆通行证',
    [6]: '护照',
    [7]: '港澳居民居住证',
    [8]: '台湾居民居住证',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [1]: '居民身份证',
    [2]: '香港特区护照/身份证明',
    [3]: '澳门特区护照/身份证明',
    [4]: '境外永久居住证',
    [5]: '台湾居民来往大陆通行证',
    [6]: '护照',
    [7]: '港澳居民居住证',
    [8]: '台湾居民居住证',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
