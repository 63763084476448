import Vue, { VNodeData, VNodeChildren, VNode } from 'vue'
import AppLogo from '@functional-components/app-logo'
import IdentitySelector from '@components/identity-selector'
import LanguageSelector from '@components/language-selector'
import UserAvatar from '@components/user-avatar'

export default Vue.extend({
  name: 'PageHeader',
  functional: true,
  props: {
    user: { type: Object },
  },
  render(createElement, context) {
    const prefixCls = 'page-header'
    const data: VNodeData = {
      class: [prefixCls].concat(context.data.class),
      style: Object.assign({}, context.data.style),
    }

    const children: VNodeChildren = []
    let hrefUrl = ''
    switch (__ENV__) {
      case 'dev':
        hrefUrl = 'http://education.international.xuexindev.com/'
        break
      case 'test':
        hrefUrl = 'http://education.international.xuexintest.com/'
        break
      case 'pre':
        hrefUrl = 'http://education.intl.test.xuexin.org.cn/'
        break
      case 'prod':
        hrefUrl = 'http://education.intl.imxuexin.cn'
        break
      default:
        hrefUrl = 'http://education.international.xuexintest.com/'
        break
    }
    /** 添加 Logo */
    children.push(
      createElement(
        'div',
        {
          class: `${prefixCls}_item ${prefixCls}_item-logo`,
          key: 'logo',
        },
        [createElement(AppLogo)]
      )
    )

    /** 添加用户头像 */
    if (context.props.user !== void 0) {
      /** 添加系统标题 */
      children.push(
        createElement(
          'div',
          {
            class: `${prefixCls}_item ${prefixCls}_item-title`,
            key: 'title',
          },
          '明心•学籍管理平台(国内)'
        )
      )
      children.push(
        createElement(
          'a',
          {
            class: `${prefixCls}_item ${prefixCls}_item-title`,
            key: 'title-link',
            style: {
              fontSize: '14px',
              color: '#cfcccc',
              marginLeft: '30px',
            },
            attrs: {
              href: hrefUrl,
              target: '_blank',
            },
          },
          '转至国际学籍'
        )
      )

      children.push(
        createElement(
          'div',
          {
            class: `${prefixCls}_item ${prefixCls}_item-user`,
            key: 'user',
          },
          [
            createElement(UserAvatar, {
              props: { user: context.props.user },
            }),
          ]
        )
      )
    }

    /** 添加身份选择菜单 */
    children.push(
      createElement(
        'div',
        {
          class: `${prefixCls}_item ${prefixCls}_item-identity`,
          key: 'identity',
        },
        [createElement(IdentitySelector)]
      )
    )
    // /** 添加语言选择器 */
    // children.push(
    //   createElement(
    //     'div',
    //     {
    //       class: `${prefixCls}_item ${prefixCls}_item-language`,
    //       key: 'language-selector',
    //     },
    //     [createElement(LanguageSelector)]
    //   )
    // )

    return createElement('header', data, children)
  },
})
