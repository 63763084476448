


















































































































































































































































































































































import Vue from 'vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Glass, School, Session, Constant } from 'xuexin-vuex'
import { Button, TableColumn } from 'view-design'
import PagePropsMixins from '@mixins/page-props-mixins'
import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'
import GlassModal from '@business-components/glass-modal'
import GradeModal from '@business-components/grade-modal'
import WorkflowModal from '@business-components/workflow-modal'
import ChageClassesModal from './change-classes-modal.vue'
import { ApprovalStatusSelect } from '@business-components/approval-status'
import { SubjectTypeText } from '@business-components/subject-type'
import { convertSearchParameter } from '@util'

import { FeedbackStatusSelect } from '@business-components/trial-read-status'
import { TrialReadResultSelect } from '@business-components/trial-read-result'

import Export from '@store/modules/export'
import ClassBatchSubmitApproval from '@store/modules/class-batch-submit-approval'
import ExportTyping from '../../../types/export'
import PageTyping from '../../../types/page'
import BatchSubmitApproval from '../../../types/class-batch-submit-approval'
import { Parameter } from 'xuexin-vuex/types/student'

const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  AppTable,
  AppPagination,
  GlassModal,
  GradeModal,
  WorkflowModal,
  ApprovalStatusSelect,
  SubjectTypeText,
  ChageClassesModal,
  FeedbackStatusSelect,
  TrialReadResultSelect,
}

const i18n = {
  messages: {
    'zh-CN': {
      button: {
        export: '导出',
        delete: '删除',
        templateDownload: '模板下载',
        batchImport: '批量导入',
        importRecord: '导入记录',
        edit: '编辑',
        submit: '提交',
        create: '添加班级',
        batchCreate: '批量添加班级',
        revoke: '撤班',
        batchSubmitApproval: '批量提交审批',
      },
      text: {
        null: '-',
        undefined: '-',
        ordinary: '普通',
        liberalArts: '文科',
        science: '理科',
        index: '序号',
        1: '是',
        0: '否',
      },
      message: {
        delete: '您确定要删除该班级吗？',
        revoke: '您确定要撤销该班级吗？',
        workflow: '您确定要提交吗？',
        noDataText: '暂无数据',
        noSchoolDepart: '请选择学部',
        noParameter: '请先选择搜索条件',
        nonSearch: '请先点击搜索',
        deleteSuccess: '删除成功',
        revokeSuccess: '撤销成功',
        workflowSuccess: '提交成功',
        editSuccess: '编辑成功',
        createSuccess: '添加成功',
        submitSuccess: '提交审批成功',
        batchSubmitApproval: '您确定提交本页未提交审批班级？',
      },
    },
    'en-US': {},
  },
}

@Component({ name: 'GlassPage', components, i18n })
export default class GlassPage extends Mixins(PagePropsMixins) {
  /** 学校模块 */
  @School.Action('fetch')
  private readonly fetchSchoolStatus!: School.Action.Fetch
  @School.Getter('item')
  private readonly getSchool!: School.Getter.Item
  /** session模块 */
  @Session.Getter('user') private readonly getUser!: Session.Getter.User

  @Glass.Getter('list') private readonly getList!: Glass.Getter.List
  @Glass.Getter('listStatus')
  private readonly getListStatus!: Glass.Getter.ListStatus
  @Glass.Getter('itemStatus')
  private readonly getStatus!: Glass.Getter.ItemStatus
  @Glass.Getter('item')
  private readonly getItem!: Glass.Getter.Item
  @Glass.Action('fetch') private readonly fetchList!: Glass.Action.Fetch
  @Glass.Action('delete') private readonly del!: Glass.Action.Delete

  @Export.Action('exportFile')
  private readonly fetchExportUrl!: ExportTyping.Action.ExportFile
  @Export.Getter('item')
  private readonly getExportUrl!: ExportTyping.Getter.Item
  @Export.Getter('listStatus')
  private readonly getExportStatus!: ExportTyping.Getter.ListStatus
  @ClassBatchSubmitApproval.Action('submit')
  private readonly batchSubmitApproval!: BatchSubmitApproval.Action.Submit
  @ClassBatchSubmitApproval.Getter('listStatus')
  private readonly getSubmitStatus!: BatchSubmitApproval.Getter.ListStatus

  private readonly prefixCls = 'glass-page'
  private classID = -1
  private loading = false
  private deleteType = 'delete'
  private workflow = false
  private page = 1
  private pageSize = 10
  private operable = true /** 是否大于等于当前学前，可否操作 */
  private parameter: Partial<PageTyping.SearchParameter> = {}
  private searchParameter: any = {}
  private modal: { name: string | null; data: any } = { name: null, data: {} }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  get currentTermID() {
    return this.getUser?.extraMap.currentTermID || 0
  }
  get listStatus() {
    return this.getListStatus(this.searchParameter)
  }

  get itemStatus() {
    if (this.classID === -1) {
      return {}
    }
    return this.getStatus(this.classID)
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get total() {
    return this.listStatus.total || 0
  }
  get disableButton() {
    return this.total === 0
  }
  get parentUnitID() {
    if (this.getUser) {
      return this.getUser.parentUnitID
    }
  }
  /** 获取机构id */
  get organID() {
    return this.getUser?.organID
  }

  get tableData() {
    if (this.valid !== true) {
      return []
    }
    return this.getList(this.searchParameter)
  }

  get school(): Partial<School.Entity> {
    return this.getSchool(this.parentUnitID!) || {}
  }

  /** 是否禁启用添加按钮 */
  get disableCreate() {
    const newTime = new Date().getTime()
    return (
      this.school.addTimeLimit
        ?.map((item: Glass.Entity) => {
          if (
            (newTime >= item.startDate && newTime <= item.endDate) ||
            item.startDate === null ||
            item.endDate === null
          ) {
            return false
          }
          return true
        })
        .filter((item: Boolean) => item === false).length < 1
    )
  }

  get exportUrl() {
    return this.getExportUrl(`${this.searchParameter}`)
  }

  get submitStatus() {
    return this.getSubmitStatus()
  }

  get valid() {
    // const { schoolDepartID } = this.searchParameter
    // return (
    //   Boolean(schoolDepartID) ||
    //   schoolDepartID === 0 ||
    //   Boolean(this.parameter.schoolDepartID) ||
    //   this.parameter.schoolDepartID === 0
    // )
    return true
  }

  get noDataText() {
    // if (
    //   Boolean(this.parameter.schoolDepartID) === false ||
    //   Boolean(this.searchParameter.schoolDepartID) === false
    // ) {
    //   return this.$t('message.nonSearch')
    // }
    return this.$t('message.noDataText')
  }

  get tableColumn() {
    if (this.valid !== true || this.tableData.length === 0) {
      return []
    }
    const columns: TableColumn[] = this.columns.map(item => {
      const column: TableColumn = Object.assign({}, item)
      switch (column.key) {
        case 'gradeName':
        case 'className':
          column.width = 240
          break
        case 'aliasClassName':
          column.width = 200
          break
        case 'operate':
          column.width = 180
          column.slot = 'operate'
          column.fixed = 'right'
          column.ellipsis = false
          break
        case 'subjectType':
        case 'repeatType':
          column.slot = column.key
          break
        default:
          break
      }
      return column
    })
    columns.unshift({
      title: `${this.$t('text.index')}`,
      render: (createElement, parameter) => {
        const text =
          (this.page - 1) * this.pageSize + (parameter?.index || 0) + 1
        return createElement!('span', `${text}`)
      },
      width: 80,
      fixed: 'left',
      align: 'center',
    })
    return columns
  }

  get authCode() {
    return this.$route.meta.authCode
  }

  @Watch('parameter.termID')
  private watchValue(value: number): void {
    this.operable = value >= this.currentTermID
  }
  @Watch('parameter.unitID')
  private watchSchoolDepartID(value: number, oldValue: unknown) {
    if (oldValue === null || oldValue === undefined) {
      this.searchParameter = { ...this.parameter }
      this.fetch()
    }
  }
  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.loading === true) {
        this.$set(this.$data, 'loading', false)
      }
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }

  @Watch('itemStatus.deleting')
  private watchDeleting(deleting: boolean, previous: boolean) {
    if (deleting === false && previous === true) {
      if (this.itemStatus.deletingError !== null) {
        this.$Modal.remove()
        return this.$Message.error(this.itemStatus.deletingError)
      }
      this.$Message.success(this.$t(`message.${this.deleteType}Success`))
      this.$Modal.remove()
      this.fetch()
    }
  }
  @Watch('itemStatus.fetching')
  private watchItemFetching(fetching: boolean, previous: boolean) {
    if (this.workflow === false) {
      return void 0
    }
    if (fetching === false && previous === true) {
      if (this.itemStatus.fetchingError !== null) {
        this.$Modal.remove()
        this.$set(this.$data, 'workflow', false)
        return this.$Message.error(this.itemStatus.fetchingError)
      }
      this.$Message.success(this.$t('message.workflowSuccess'))
      this.$Modal.remove()
      this.$set(this.$data, 'workflow', false)
      this.fetch()
    }
  }

  @Watch('getExportStatus.creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (!creating && previous) {
      if (this.getExportStatus.creatingError !== null) {
        this.$Message.error(this.getExportStatus.creatingError)
        return
      }
      location.href = this.exportUrl.url
    }
  }

  @Watch('submitStatus.fetching')
  private watchSubmiting(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.submitStatus.fetchingError !== null) {
        this.$Modal.remove()
        return this.$Message.error(this.submitStatus.fetchingError)
      }
      this.$Message.success(this.$t('message.submitSuccess'))
      this.$Modal.remove()
      this.fetch()
    }
  }

  /** 工具栏按钮事件 */
  private handleClick(viewType: string, glass: Glass.Entity) {
    if (Boolean(this.parameter.unitID) === false) {
      return this.$Message.warning(`${this.$t('message.noSchoolDepart')}`)
    }
    switch (viewType) {
      case 'create':
      case 'batch-create':
        this.$set(this.$data, 'modal', {
          name: 'glass-modal',
          data: {
            viewType,
            parameter: this.parameter,
          },
        })
        break
      case 'edit':
        let editPart = false,
          editTeacher = false
        if (
          glass.approach === -1 ||
          glass.studentCount > 0 ||
          (glass.studentCount === 0 &&
            glass.workflowCode === 'changeClassLimit')
        ) {
          editPart = true
        }
        if (
          glass.startDate < new Date().getTime() &&
          glass.studentCount > 0 &&
          glass.approach === -1
        ) {
          editPart = true
          editTeacher = true
        }
        this.$set(this.$data, 'modal', {
          name: 'glass-modal',
          data: {
            viewType: 'edit',
            parameter: this.searchParameter,
            classID: glass?.classID,
            termID: glass?.termID,
            editPart,
            editTeacher,
          },
        })
        this.$set(this.$data, 'classID', glass.classID)
        break
      case 'view-glass':
        this.$set(this.$data, 'modal', {
          name: 'glass-modal',
          data: {
            viewType: 'view',
            parameter: this.searchParameter,
            classID: glass?.classID,
          },
        })
        this.$set(this.$data, 'classID', glass.classID)
        break
      case 'view-grade':
        this.$set(this.$data, 'modal', {
          name: 'grade-modal',
          data: {
            viewType: 'view',
            parameter: this.searchParameter,
            unitGradeID: glass.unitGradeID,
          },
        })
        break
      case 'change-classes':
        this.$set(this.$data, 'modal', {
          name: 'change-classes-modal',
          data: {
            termID: glass.termID,
            classID: glass.classID,
            className: glass.className,
            limitCount: glass.limitCount,
          },
        })
        break
      case 'delete':
        this.$Modal.confirm({
          title: this.$t('button.delete') as string,
          content: this.$t('message.delete') as string,
          loading: true,
          onOk: () => {
            this.del({
              classID: glass.classID as number,
              termID: this.searchParameter.termID!,
              actionType: Constant.ActionType.Delete,
            })
          },
        })
        this.$set(this.$data, 'deleteType', 'delete')
        this.$set(this.$data, 'classID', glass.classID)
        break
      case 'revoke':
        this.$Modal.confirm({
          title: this.$t('button.revoke') as string,
          content: this.$t('message.revoke') as string,
          loading: true,
          onOk: () => {
            this.del({
              classID: glass.classID as number,
              termID: this.searchParameter.termID!,
              actionType: Constant.ActionType.Undo,
            })
          },
        })
        this.$set(this.$data, 'deleteType', 'revoke')
        this.$set(this.$data, 'classID', glass.classID)
        break
      case 'workflow':
        this.$Modal.confirm({
          title: this.$t('button.submit') as string,
          content: this.$t('message.workflow') as string,
          loading: true,
          onOk: () => {
            this.fetchList({
              classID: glass.classID as number,
              termID: this.searchParameter.termID!,
              actionType: Constant.ActionType.Workflow,
            })
          },
          onCancel: () => {
            this.$set(this.$data, 'workflow', false)
          },
        })
        this.$set(this.$data, 'workflow', true)
        this.$set(this.$data, 'classID', glass.classID)
        break
      case 'export':
        this.fetchExportUrl({
          searches: this.convertParameter(),
          columns: this.columns.map(item => item.key || ''),
          actionType: Constant.ActionType.Glass,
        })
        break
      case 'approveStatus':
        if (glass.approveStatus === -1) return

        this.$set(this.$data, 'modal', {
          name: 'workflow-modal',
          data: {
            workflowID: glass.workflowID,
          },
        })
        break
      case 'batchSubmitApproval':
        this.$Modal.confirm({
          title: this.$t('button.batchSubmitApproval') as string,
          content: this.$t('message.batchSubmitApproval') as string,
          loading: true,
          onOk: () => {
            this.batchSubmitApproval({
              searches: this.convertParameter(),
              authCode: this.authCode,
              page: this.page,
              pageSize: this.pageSize,
            })
          },
        })
    }
  }

  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    // if (Boolean(parameter.schoolDepartID) === false) {
    //   return this.$Message.warning(`${this.$t('message.noParameter')}`)
    // }
    this.$set(this.$data, 'page', 1)
    this.$set(this.$data, 'searchParameter', parameter)
    this.fetch()
  }

  /** 页码发生变化 */
  private handlePageChange(page: number) {
    this.$set(this.$data, 'page', page)
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number) {
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }

  /** 窗口关闭事件处理函数 */
  private handleModalHidden() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
  }

  /** 窗口功能完成事件处理函数 */
  private handleModalFinish(modalType: string, type: string) {
    if (type && modalType) {
      this.$Message.success(this.$t(`message.${type}Success`))
    }
    this.$set(this.$data, 'modal', { name: null, data: {} })
    this.fetch()
    this.$set(this.$data, 'searchParameter', this.parameter)
  }
  private convertParameter() {
    return convertSearchParameter(this.parameter)
  }

  private fetch() {
    if (
      this.valid !== true /** 参数无效 */ ||
      this.fetching === true /** 正在请求数据 */
    ) {
      /** 以上情况不发起请求 */
      return void 0
    }

    this.fetchList({
      searches: this.convertParameter(),
      authCode: this.authCode,
      page: this.page,
      pageSize: this.pageSize,
      actionType: Constant.ActionType.Glass,
    })
  }

  created() {
    /** 查询学校配置项 */
    this.fetchSchoolStatus({
      unitID: this.parentUnitID,
      organID: this.organID,
    })
    // this.fetch()
  }
}
