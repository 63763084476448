export const messages: Messages = {
  'zh-CN': {
    text: {
      settings: '条件设置',
      search: '搜索',
    },
    label: {
      termID: '学期',
      parentUnitID: '学校',
      unitID: '校区',
      schoolDepartID: '学部',
      phaseID: '学段',
      gradeID: '年级',
      enrollInGrade: '报读年级',
      unitGradeID: '学校年级',
      classID: '班级',
      manageTypeID: '报读类型',
      classTypeID: '班级类型',
      unitClassTypeID: '校本班型',
      studentMark: '集团优生',
      researchField: '学业方向',
      studentStatus: '学生状态',
      enrollType: '入学分类',
      enrollScore: '中考成绩',
      graduateDirection: '毕业去向',
      graduateDirectStatus: '是否直升',
      sourceChannel: '学生来源',
      divideStatus: '分班状态',
      reSchoolStatus: '重新入校状态',
      ownerChild: '业主子女',
      staffChild: '教职工子女',
      statusType: '学籍类型',
      transientStudent: '借读生',
      householdIncome: '家庭年收入',
      singleParent: '单亲家庭',
      onlyChild: '独生子女',
      residenceType: '户口类别',
      ethnicity: '民族',
      nation: '国籍',
      birthday: '出生日期',
      parentName: '家长姓名',
      parentMobile: '家长电话',
      examCode: '考号',
      gender: '性别',
      studentID: '学生ID',
      studentName: '学生姓名',
      tranferDate: '转学日期',
      approveDate: '审批日期',
      tranferReason: '转学原因',
      approveStatus: '审批状态',
      feedbackStatus: '试读状态',
      feedbackResult: '试读结果',
      feedbackDate: '试读日期',
      applyName: '申请人',
      applyDate: '申请时间',
      subjectType: '文理类型',
      repeatType: '复读班',
      startDate: '开始日期',
      endDate: '结束日期',
      limitCount: '班额',
      creatorName: '添加人',
      createDate: '添加时间',
      departOperateDate: '内转操作时间',
      identifyDate: '内转确认时间',
      startCreateDate: '创建开始时间',
      endCreateDate: '创建结束时间',
      headTeacherName: '班主任',
      className: '班级名称',
      registStatus: '注册状态',
      registPerson: '注册人',
      registDate: '注册日期',
      direction: '报名方向',
      divideType: '分班类型',
      enterDate: '录取日期',
      payType: '缴费状态',
      studyDate: '就读日期',
      leaveDate: '离校日期',
      inUnitID: '转至校区',
      tranferUnitDate: '互转日期',
      tranferStatus: '互转状态',
      tranferConfirmStatus: '确认状态',
      operateGroup: '操作类别',
      operateType: '操作类型',
      operatorDate: '操作时间',
      gradeName: '年级名称',
      schoolSystemID: '学制',
      abroadID: '出国方向',
      startTerm: '开始学期',
      startTerm2: '起始学期',
      endTerm: '结束学期',
      unitGradeName: '年级名称',
      gradeDirectorName: '年级主任',
      dateType: '时间类型',
      studentTypeSelect: '学生状态',
      simpleDate: '入学时间',
      doubleDate: '入学时间',
      freshStudent: '应届生',
      academicDirection: '学业方向/入学分类',
      studentCategory: '学生类别',
      stayStatus: '住宿状态',
      collegeDirection: '高考方向',
      transientStatusType: '借读生转籍状态',
      newOldType: '新老生类型',
      schoolDepartStatus: '内转状态',
    },
    placeholder: {
      commom: '请选择',
      termID: '请选择学期',
      parentUnitID: '请选择学校',
      unitID: '请选择校区',
      schoolDepartID: '请选择学部',
      phaseID: '请选择学段',
      unitGradeID: '请选择年级',
      classID: '请选择班级',
      manageTypeID: '请选择报读类型',
      classTypeID: '请选择班型',
      unitClassTypeID: '请选择校本班型',
      studentMark: '请选择是否优生',
      researchField: '请选择学业方向',
      studentStatus: '请选择学生状态',
      academicDirection: '请选择学业方向/入学分类',
      studentCategory: '请选择学生类别',
      stayStatus: '请选择住宿状态',
      collegeDirection: '请选择高考方向',
      transientStatusType: '请选择借读生转籍状态',
      newOldType: '请选择新老生类型',
    },
  },
  'en-US': {},
}

export default { messages }
