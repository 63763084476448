/** 学生人数月统计表 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import StudentCountMonthly from '@pages/statistic-reports/student-count-monthly'

const children: RouteConfig[] = []
/** 学生人数月统计表 */
children.push({
  path: '/reports/student-count-monthly',
  name: 'student-count-monthly-report',
  component: StudentCountMonthly,
  meta: { ignore: true, authCode: '050704', parentAuthCode: '0507' },
})

export default {
  path: '/reports/student-count-monthly',
  name: 'student-count-monthly',
  redirect: { name: 'student-count-monthly-report' },
  meta: { title: '学生人数月统计表', authCode: '050704' },
  component: RouterViewLayout,
  props: { name: 'student-count-monthly' },
  children,
}
