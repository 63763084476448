import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppRadioGroup from '@components/app-radio-group'
import AppSelect from '@components/app-select'
import i18n, { BooleanList } from './i18n'

type ViewType = 'text'
const prefixCls = `app-boolean`

function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `AppBoolean${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class AppBooleanComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType!: ViewType
    @Prop({ type: String }) private readonly statusType!: string

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }

    get locale(): Locale {
      return this.$i18n.locale as Locale
    }

    get list() {
      return this.extra.concat(
        BooleanList.map(value => {
          return { label: i18n.messages[this.locale][value], value }
        }).filter(i => {
          return ['1', '2'].includes(this.statusType)
            ? i.value === 0
            : ['3', '4'].includes(this.statusType)
            ? i.value === 1
            : true
        })
      )
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
        case ComponentType.RadioGroup:
          props.data = this.list
          break
      }
      return props
    }

    @Watch('statusType', { immediate: true })
    private watchstatusType(current: string, prev: string) {
      if (current === '1' || current === '2') {
        this.$emit('input', 0)
      } else if (current === '3' || current === '4') {
        this.$emit('input', 1)
      }
    }

    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (type === ComponentType.Text || this.viewType === 'text') {
        children = [`${i18n.messages[this.locale][this.$attrs.value]}`]
      }

      return createElement(
        component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return AppBooleanComponent
}

export const AppBooleanRadioGroup = createComponent(
  AppRadioGroup,
  ComponentType.RadioGroup
)
export const AppBooleanSelect = createComponent(AppSelect, ComponentType.Select)
export const AppBooleanText = createComponent('span', ComponentType.Text)
