export default {
  messages: {
    'zh-CN': {
      title: {
        suspension: '休学',
        'leave-school': '外出艺体',
      },
      label: {
        'student-name': '学生姓名',
        date: {
          suspension: '休学日期',
          'leave-school': '外出日期',
        },
        remark: {
          suspension: '休学原因',
          'leave-school': '外出说明',
        },
        operator: '操作人',
        'operat-date': '操作时间',
      },
      text: {
        loading: '加载中...',
      },
    },
    'en-US': {},
  },
}
