export default {
  messages: {
    'zh-CN': {
      loading: '正在加载数据',
      title: '转班',
      label: {
        manageTypeID: '报读类型',
        unitClassTypeID: '校本班型',
        classID: '转至班级',
      },
      error: {
        manageTypeID: '请选择报读类型',
        unitClassTypeID: '请选择校本班型',
        classID: '请选择转至班级',
        freshStudent: '请选择应届生',
        studentCategory: '请选择学生类别',
        academicDirection: '请选择学业方向/入学分类',
      },
    },
    'en-US': {},
  },
}
