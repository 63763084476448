import Vue, { CreateElement } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Badge,
  Avatar,
} from 'view-design'
import i18n from './i18n'
import { Session } from 'xuexin-vuex'

@Component({ name: 'UserAvatar', i18n })
export default class UserAvatar extends Vue {
  @Session.Action('logout') private readonly logout!: Session.Action.Logout
  @Prop({ type: Object }) private readonly user!: Session.Entity
  private readonly prefixCls = 'user-avatar'

  get avatar() {
    return this.user.avatar || void 0
  }

  get name() {
    return `${this.user.realName}`.charAt(0).toUpperCase()
  }

  render(createElement: CreateElement) {
    return createElement(
      Dropdown,
      {
        props: {
          trigger: 'hover',
          placement: 'bottom-end',
        },
        on: {
          'on-click': this.handleClick,
        },
      },
      [
        createElement(
          Badge,
          {
            props: {
              dot: false,
            },
            style: {
              lineHeight: '24px',
            },
          },
          [
            createElement(
              Avatar,
              {
                props: {
                  src: this.avatar,
                  icon: 'md-person',
                  size: 24,
                },
              },
              [this.name]
            ),
          ]
        ),
        createElement(
          'span',
          { style: { lineHeight: '24px', marginLeft: '12px' } },
          [this.user.realName]
        ),
        createElement(
          DropdownMenu,
          {
            slot: 'list',
          },
          [
            createElement(
              DropdownItem,
              {
                props: {
                  name: 'exit',
                },
              },
              [`${this.$t('exit')}`]
            ),
          ]
        ),
      ]
    )
  }

  private handleClick(name: string) {
    switch (name) {
      case 'exit' /** 退出登录 */:
        this.$Modal.confirm({
          title: `${this.$t('confirm.title')}`,
          content: `${this.$t('confirm.content')}`,
          onOk: () => this.logout().then((res) => {
            // console.log(res.result?.loginUrl,'ggggggggggggg')
            window.location.href = res.result?.loginUrl
          }),
        })
        break
      default:
        break
    }
  }
}
