








































































































































































































import Vue from 'vue'
import { Component, Ref, Watch } from 'vue-property-decorator'
import { Button } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import AppSelect from '@components/app-select'
import InformationSetup from '@store/modules/information-setup'
import ConfigNode from '@store/modules/config-node'
import {
  EnableStatusSelect,
  EnableStatusRadio,
} from '@business-components/enable-status'
import { PaymentSettingSelect } from '@business-components/payment-setting'
import TeacherSelect from '@business-components/teacher'
import { ApprovalNode } from 'xuexin-vuex'
import i18n from './i18n'

const components = {
  ViewLayout,
  Button,
  EnableStatusSelect,
  EnableStatusRadio,
  PaymentSettingSelect,
  AppSelect,
  TeacherSelect,
}

@Component({ name: 'ConfigManagementPage', components, i18n })
export default class ConfigManagementPage extends Vue {
  @InformationSetup.Getter('list')
  private readonly list!: InformationSetup.Getter.List
  @InformationSetup.Getter('listStatus')
  private readonly listStatus!: InformationSetup.Getter.ListStatus
  @InformationSetup.Action('fetch')
  private readonly fetch!: InformationSetup.Action.Fetch
  @InformationSetup.Action('update')
  private readonly update!: InformationSetup.Action.Update

  @ConfigNode.Getter('list')
  private readonly lostNodeList!: ConfigNode.Getter.List
  @ConfigNode.Action('fetch')
  private readonly fetchLostNode!: ConfigNode.Action.Fetch

  @ApprovalNode.Getter('list')
  private readonly approvalNodeList!: ApprovalNode.Getter.List
  @ApprovalNode.Action('fetchList')
  private readonly fetchApprovalNode!: ApprovalNode.Action.FetchList

  private readonly prefixCls = 'config-page'

  private classDataManage: InformationSetup.Entity[] = []
  private classDataDivide: InformationSetup.Entity[] = []
  private approvalData: InformationSetup.Entity[] = []
  private lostsDataClass1: InformationSetup.Entity[] = []
  private lostsDataClass2: InformationSetup.Entity[] = []
  private lostsDataStudent1: InformationSetup.Entity[] = []
  private lostsDataStudent2: InformationSetup.Entity[] = []

  // 页面加载状态
  get loading() {
    return (
      this.getClassListStatus.fetching &&
      this.getApprovalListStatus.fetching &&
      this.getLostsListStatus.fetching
    )
  }

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }
  //校际互转选择节点列表
  get xjhzlostNodeList() {
    return this.lostNodeList.filter(item => {
      return (
        item.dataValue == '计入' ||
        item.dataValue == '不计入' ||
        item.dataValue == '集团审批通过后不计入'
      )
    })
  }
  // 班级管理配置列表状态
  get getClassListStatus() {
    return this.listStatus('class')
  }
  // 审批配置列表状态
  get getApprovalListStatus() {
    return this.listStatus('approval')
  }
  // 考核流失配置列表状态
  get getLostsListStatus() {
    return this.listStatus('losts')
  }
  // 班级管理配置列表查询状态
  @Watch('getClassListStatus.fetching')
  private watchClassFetching(fetching: boolean, previous: boolean) {
    if (!fetching && previous) {
      if (
        this.getClassListStatus.fetchingError !== null &&
        this.getClassListStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.getClassListStatus.fetchingError)
        return
      }
      //获取班级配置信息
      if (this.list('class').length < 1) {
        this.classDataManage = []
        this.classDataDivide = []
      } else {
        this.classDataManage = this.list('class').filter((item: any) => {
          return item.moduleType === 1
        })
        this.classDataDivide = this.list('class').filter((item: any) => {
          return item.moduleType === 2
        })
      }
    }
  }
  // 审批配置列表查询状态
  @Watch('getApprovalListStatus.fetching')
  private watchApprovalFetching(fetching: boolean, previous: boolean) {
    if (!fetching && previous) {
      if (
        this.getApprovalListStatus.fetchingError !== null &&
        this.getApprovalListStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.getApprovalListStatus.fetchingError)
        return
      }
      // 审批配置列表数据
      this.approvalData = this.list('approval')
    }
  }
  // 考核流失配置列表查询状态
  @Watch('getLostsListStatus.fetching')
  private watchLostsFetching(fetching: boolean, previous: boolean) {
    if (!fetching && previous) {
      if (
        this.getLostsListStatus.fetchingError !== null &&
        this.getLostsListStatus.fetchingError !== void 0
      ) {
        this.$Message.error(this.getLostsListStatus.fetchingError)
        return
      }

      if (this.list('losts').length < 1) {
        this.lostsDataClass1 = []
        this.lostsDataClass2 = []
        this.lostsDataStudent1 = []
        this.lostsDataStudent2 = []
      } else {
        // 考核流失-分班管理-转学
        this.lostsDataClass1 = this.list('losts')
          .filter((item: InformationSetup.Entity) => {
            return item.moduleType === 1 && item.lostTypeCode === 1
          })
          .sort(this.sortBy('userType'))

        // 考核流失-分班管理-校际互转
        this.lostsDataClass2 = this.list('losts')
          .filter((item: InformationSetup.Entity) => {
            return item.moduleType === 1 && item.lostTypeCode === 2
          })
          .sort(this.sortBy('userType'))

        // 考核流失-在班学生-转学
        this.lostsDataStudent1 = this.list('losts')
          .filter((item: InformationSetup.Entity) => {
            return item.moduleType === 2 && item.lostTypeCode === 1
          })
          .sort(this.sortBy('userType'))

        // 考核流失-在班学生-校际互转
        this.lostsDataStudent2 = this.list('losts')
          .filter((item: InformationSetup.Entity) => {
            return item.moduleType === 2 && item.lostTypeCode === 2
          })
          .sort(this.sortBy('userType'))
      }
    }
  }

  // 班级管理配置列表更新状态
  @Watch('getClassListStatus.updating')
  private watchClassUpdating(updating: boolean, previous: boolean) {
    if (!updating && previous) {
      if (
        this.getClassListStatus.updatingError !== null &&
        this.getClassListStatus.updatingError !== void 0
      ) {
        this.$Message.error(this.getClassListStatus.updatingError)
        return
      }
      this.$Message.success(this.$t('message-tips'))
    }
  }
  // 审批配置列表更新状态
  @Watch('getApprovalListStatus.updating')
  private watchApprovalUpdating(updating: boolean, previous: boolean) {
    if (!updating && previous) {
      if (
        this.getApprovalListStatus.updatingError !== null &&
        this.getApprovalListStatus.updatingError !== void 0
      ) {
        this.$Message.error(this.getApprovalListStatus.updatingError)
        return
      }
      this.$Message.success(this.$t('message-tips'))
    }
  }
  // 考核流失配置列表更新状态
  @Watch('getLostsListStatus.updating')
  private watchLostsUpdating(updating: boolean, previous: boolean) {
    if (!updating && previous) {
      if (
        this.getLostsListStatus.updatingError !== null &&
        this.getLostsListStatus.updatingError !== void 0
      ) {
        this.$Message.error(this.getLostsListStatus.updatingError)
        return
      }
      this.$Message.success(this.$t('message-tips'))
    }
  }

  // 初始化查询
  mounted() {
    this.fetch({ type: 'class' })
    this.fetchApprovalNode({}).then(() => {
      this.fetch({ type: 'approval' })
    })
    this.fetchLostNode({}).then(() => {
      this.fetch({ type: 'losts' })
    })
  }
  // 保存班级管理
  private handleSaveManage() {
    this.update({
      type: 'class',
      editType: 'save',
      configClasses: this.classDataManage,
    })
  }
  // 保存分班配置
  private handleSaveDivide() {
    this.update({
      type: 'class',
      editType: 'save',
      configClasses: this.classDataDivide,
    })
  }
  // 保存审批配置
  private handleSaveApproval() {
    this.update({
      type: 'approval',
      editType: 'save',
      approvals: this.approvalData,
    })
  }
  // 保存考核流失配置
  private handleSaveLosts() {
    const arr = this.lostsDataClass1.concat(
      this.lostsDataClass2,
      this.lostsDataStudent1,
      this.lostsDataStudent2
    )
    this.update({ type: 'losts', editType: 'save', losts: arr })
  }
  //排序
  private sortBy(props: any): any {
    return function(a: any, b: any) {
      return a[props] - b[props]
    }
  }
}
