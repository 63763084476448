

































































import Vue from 'vue'
import { Ref, Component, Watch } from 'vue-property-decorator'
import { Session, Constant } from 'xuexin-vuex'
import { Form, FormItem, Input, Checkbox, Button } from 'view-design'
import PageHeader from '@functional-components/page-header'
import PageBody from '@functional-components/page-body'
import PageFooter from '@functional-components/page-footer'
import { decryptFromBase64, encryptToBase64 } from '@util'
import i18n from './i18n'

const components = {
  PageHeader,
  PageBody,
  PageFooter,
  Form,
  FormItem,
  Input,
  Checkbox,
  Button,
}
@Component({ name: 'LoginPage', components, i18n })
export default class LoginPage extends Vue {
  @Session.Action('login') private readonly login!: Session.Action.Login
  @Session.Getter('status') private readonly status!: Session.Getter.Status

  @Ref('form') private readonly form!: Form
  @Ref('userName') private readonly userName!: Input
  @Ref('password') private readonly password!: Input

  private readonly prefixCls = 'login-page'
  private verifying = false
  private autoLogin = true
  private model: Session.Parameter = {
    userName: '',
    password: '',
  } as Session.Parameter

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get rules(): FormRules {
    return {
      userName: [
        {
          required: true,
          trigger: 'change',
          validator: this.validator,
        },
      ],
      password: [
        {
          required: true,
          trigger: 'change',
          validator: this.validator,
        },
      ],
    }
  }

  get logining() {
    return this.status.logining === true
  }

  @Watch('logining')
  watchLogining(logining: boolean, previous: boolean) {
    /** 登录请求完成 */
    if (logining === false && previous === true) {
      this.$set(this.$data, 'verifying', false)
      if (this.status.loginingError !== null) {
        return this.$Message.error(this.status.loginingError)
      }

      let url = '/'

      /** link 不为空时，解码 url 地址 */
      if (typeof this.$route.query.link === 'string') {
        url = decryptFromBase64(this.$route.query.link, 'link-url')
      }

      /**
       * 解析 url 地址，获取需要跳转的 route
       * 若路由未匹配，则直接跳转到首页
       * */
      const { route, location } = this.$router.resolve(url)
      if (route.name === null || route.name === void 0) {
        this.$router.replace({ path: '/' })
      } else {
        this.$router.replace(location)
      }

      this.$Message.success(`${this.$t('text.success')}`)
    }
  }

  mounted() {
    this.userName.focus()
  }

  private validator(
    rule: FormRule,
    value: any,
    callback: (error?: string) => void
  ) {
    if (value === void 0 || value === null || `${value}`.trim() === '') {
      return callback(`${this.$t(`verification.${rule.field}`)}`)
    }
    callback()
  }

  private handleEnter(name: string) {
    switch (name) {
      case 'userName':
        this.form.validateField('userName', valid => {
          if (valid !== void 0 && String(valid).trim() !== '') {
            return this.$nextTick(() => this.userName.focus())
          }

          this.$nextTick(() => this.password.focus())
        })
        break
      case 'password':
        this.form.validateField('password', valid => {
          if (valid !== void 0 && String(valid).trim() !== '') {
            return this.$nextTick(() => this.password.focus())
          }
          this.handleSubmit()
        })
        break
      default:
        break
    }
  }

  private handleSubmit() {
    this.$set(this.$data, 'verifying', true)
    this.form.validate((valid?: boolean) => {
      if (valid === false) {
        return this.$set(this.$data, 'verifying', false)
      }

      this.login(
        Object.assign({}, this.model, {
          password: encryptToBase64(this.model.password, this.model.userName),
          platform: Constant.Platform.Education,
          os: 0,
          deviceModel: 'pc',
        })
      )
    })
  }
}
