



































































































































































































































































































































































import Vue, { CreateElement } from 'vue'
import { Component, Watch, Prop, Ref, Mixins } from 'vue-property-decorator'
import PagePropsMixins from '@mixins/page-props-mixins'
import { Session, Student, Constant, School } from 'xuexin-vuex'
import { Button, TableColumn, Tooltip } from 'view-design'
import Download from '@store/modules/download'

import ViewLayout from '@layouts/view-layout'
import DynamicSearch from '@components/dynamic-search'
import DynamicToolbar from '@components/dynamic-toolbar'
import AppTable from '@components/app-table'
import AppPagination from '@components/app-pagination'

import ExportModal from '@business-components/export-modal'
import SendMessageModal from '@business-components/send-message-modal'
import ImportRecordModal from '@business-components/record-modal'
import MultipleExportModal from '@business-components/multiple-export-modal'
import TransferSchoolModal from '@business-components/transfer-school-modal'
import InterTransferModal from '@business-components/inner-transfer-modal'
import { StudentFlowStatusBadge } from '@business-components/student-flow-status'
import { DivideClassTypeTag } from '@business-components/divide-class-type'
import { StudentStatusTag } from '@business-components/student-status' /** 学生状态组件 */

import DivideClassModal from './divide-class-modal'
import ShortTermModal from './short-term-modal'
import TrialReadingModal from './trial-reading-modal'
import EditGradeModal from './edit-grade-modal'
import { DateUtil, convertSearchParameter } from '@util'
import PageTyping from 'types/page'
import i18n from './i18n'
import { PayType } from 'xuexin-vuex/src/constant'
import { getParameter, setParameter } from '@util/parameter'
import NumFormate from '@util/num-format'

type Flat = Record<string, boolean>
/** 分班类型 */
const DivideClassType = Constant.DivideClassType
/** 请求类型 */
const ActionType = Constant.ActionType
/** 页面类型 */
const PageType = Constant.PageType
/** 导入类型 */
const ImportType = Constant.ImportType

const components = {
  ViewLayout,
  DynamicSearch,
  DynamicToolbar,
  Button,
  AppTable,
  AppPagination,
  StudentFlowStatusBadge,
  ExportModal,
  SendMessageModal,
  ShortTermModal,
  TrialReadingModal,
  ImportRecordModal,
  MultipleExportModal,
  DivideClassTypeTag,
  DivideClassModal,
  InterTransferModal,
  TransferSchoolModal,
  EditGradeModal,
  Tooltip,
  StudentStatusTag,
}

@Component({ name: 'DivideClassStudnetListPage', components, i18n })
export default class DivideClassStudnetListPage extends Mixins(
  PagePropsMixins
) {
  @Session.Getter('user') private readonly user!: Session.Getter.User

  @Student.Action('fetchList')
  private readonly fetchStudents!: Student.Action.FetchList
  @Student.Action('update') private readonly update!: Student.Action.Update
  @Student.Getter('listStatus')
  private readonly listStatus!: Student.Getter.ListStatus
  @Student.Getter('list')
  private readonly students!: Student.Getter.List
  @Student.Getter('itemStatus')
  private readonly itemStatus!: Student.Getter.ItemStatus

  @Download.Action('fetch') private readonly fetchUrl!: Download.Action.Fetch
  @Download.Getter('itemStatus')
  private readonly getItemStatus!: Download.Getter.ItemStatus
  @Download.Getter('item') private readonly getUrlItem!: Download.Getter.Item

  @School.Getter('item') private readonly getSchool!: School.Getter.Item

  private readonly prefixCls = 'divide-class-student-list-page'
  private modal: { name: string | null; data: any } = { name: null, data: {} }
  private selection: Student.Entity[] = []
  private flat: Flat = {}
  private operateID = '' /** 撤销ID，获取撤销请求状态 */

  private page = 1
  private pageSize = 10
  private isSearched = false
  private localParameter: Partial<PageTyping.SearchParameter> = {}
  private parameter: Partial<PageTyping.SearchParameter> = {}

  private isTurnSchool = false /** 是否进行校际互转 */
  private isTrial = false /** 是否是试读生 */
  private isChange = false /** 是否转学 */
  private isShort = false /** 是否是短期入学 */

  private operable = true /** 是否大于等于当前学期，可否操作 */

  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get organID() {
    return this.user?.organID
  }

  get termID() {
    return this.parameter.termID
  }

  get xuexinID() {
    return this.user?.xuexinID
  }

  get authCode() {
    return this.$route.meta.authCode
  }

  get valid() {
    /** 至少选择到校区 */
    const { unitID } = this.parameter
    return Boolean(unitID) || unitID === 0
  }

  get noDataText() {
    if (
      Boolean(this.parameter.unitID) === false ||
      Boolean(this.localParameter.unitID) === false
    ) {
      return this.$t('content.noUnitID')
    }
  }

  get total() {
    return this.listStatus.total || 0
  }

  get tableColumns(): TableColumn[] {
    const columns: TableColumn[] = this.columns.map(item => {
      switch (item.key) {
        case 'gradeName':
          item.width = 240
          break
        case 'divideType' /** 分班类型 */:
          return Object.assign({}, item, {
            width: 200,
            slot: 'divideType',
            align: 'center',
            render: void 0,
          })
        case 'parentMobile' /** 家长电话 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.smsPhone ? NumFormate(`${row.smsPhone}`) : '-'
              return createElement!('span', {}, [text])
            },
          })
        case 'studentPhone' /** 学生电话 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.studentPhone
                ? NumFormate(`${row.studentPhone}`)
                : '-'
              return createElement!('span', {}, [text])
            },
          })
        case 'IDNO' /** 证件号码 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const text = row.IDNO ? NumFormate(`${row.IDNO}`) : '-'
              return createElement!('span', {}, [text])
            },
          })
        case 'enterDate' /** 报读日期 */:
          return Object.assign({}, item, {
            width: 150,
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const date = row[`${item.key}`]
              const text =
                typeof date !== 'number' || date === 0
                  ? '-'
                  : DateUtil.format(date, 'yyyy/MM/dd hh:mm')
              return createElement!('span', {}, [text])
            },
          })
        case 'birthday' /** 出生日期 */:
          return Object.assign({}, item, { render: void 0 })
        case 'nation' /** 国籍 */:
        case 'ethnicity' /** 民族 */:
          return Object.assign({}, item, {
            width: 150,
            render: void 0,
          })
        case 'studentStatus' /** 学生状态 */:
        case 'nativePlace' /** 籍贯 */:
        case 'workplace' /**工作地 */:
        case 'registeredPlace' /** 户口所在地 */:
        case 'remark' /** 备注 */:
        case 'homeAddressInfo' /** 现家庭住址 */:
        case 'passportAddress' /**  护照颁发地	*/:
        case 'address' /**联系地址 */:
        case 'parentNation' /**家长国籍 */:
          return Object.assign({}, item, {
            render: void 0,
            slot: item.key,
          })
        case 'workplaceType' /** 工作性质 */:
        case 'politicalStatus' /** 政治面貌 */:
          return Object.assign({}, item, {
            width: 180,
          })
        case 'school' /**报读校区 */:
          return Object.assign({}, item, {
            width: 200,
            slot: 'school',
          })
        case 'enrollScore' /** 入学成绩 */:
          return Object.assign({}, item, {
            render(createElement: CreateElement, parameter: any = {}) {
              const row = (parameter.row || {}) as any
              const score = Number(row[`${item.key}`])
              const score1 = row['studentscore']
              const area = row['studentscoreArea']
              const remark = row['studentscoreRemark']
              let text =
                typeof score !== 'number'
                  ? '-'
                  : `${
                      score === 1
                        ? '学校所在地'
                        : score === 2
                        ? '非学校所在地'
                        : score === 3
                        ? '无成绩'
                        : '-'
                    }${score1 === '' ? '' : '/' + score1}${
                      area === '' ? '' : '/' + area
                    }${remark === '' ? '' : '/' + remark}`

              return createElement!('span', {}, [text])
            },
          })

        case 'addressInfo' /** 地址信息 */:
          return Object.assign({}, item, {
            ellipis: false,
          })
        default:
          break
      }
      return item
    })

    columns.unshift(
      {
        type: 'selection',
        width: 60,
        fixed: 'left',
        align: 'center',
      },
      {
        title: `${this.$t('index')}`,
        key: 'num',
        width: 80,
        fixed: 'left',
        align: 'center',
      }
    )

    return columns
  }

  get tableData() {
    if (this.valid !== true) {
      return []
    }

    return this.students.map((item, index) => {
      const student = Object.assign({}, item)
      if (this.flat[item.studentID] !== void 0) {
        student._checked = true
      }
      student.num = (this.page - 1) * this.pageSize + index + 1
      return student
    })
  }

  get disButton() {
    return this.tableData.length > 0 ? false : true
  }

  get fetching() {
    return this.listStatus.fetching === true
  }

  get updating() {
    return this.itemStatus(`${this.operateID}`).updating === true
  }

  get updatingError() {
    return this.itemStatus(`${this.operateID}`).updatingError || null
  }

  get urlStatus() {
    return this.getItemStatus('download_url')
  }

  get urlFetching() {
    return this.urlStatus.fetching === true
  }

  get urlFetchingError() {
    return this.urlStatus.fetchingError || null
  }

  get currentTermID() {
    return this.user?.extraMap.currentTermID || 0
  }

  @Watch('termID')
  private watchValue(value: number): void {
    this.operable = value >= this.currentTermID
  }

  @Watch('fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    /** 查询学生列表请求完成 */
    if (fetching === false && previous === true) {
      if (this.listStatus.fetchingError !== null) {
        return this.$Message.error(this.listStatus.fetchingError)
      }
    }
  }

  @Watch('updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (updating === false && previous === true) {
      if (this.updatingError !== null) {
        return this.$Message.error(this.updatingError)
      }

      this.$Message.success(this.$t('placeholder.success'))
      this.fetch()
    }
  }

  @Watch('urlFetching')
  private watchUrlFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.urlFetchingError !== null) {
        return this.$Message.error(this.urlFetchingError)
      }
      /** 获取下载链接 */
      location.href = this.getUrlItem('download_url')?.url || ''
    }
  }

  /** 监听已选学生 */
  @Watch('selection', { immediate: true })
  private watchSelection(selection: Student.Entity[]) {
    if (selection.length === 1) {
      const { flowStatus, payType }: any = selection[0]
      switch (flowStatus) {
        case 1 /** 试读 */:
          this.$set(this.$data, 'isTrial', true)
          break
        case 3 /** 转学 */:
        case 5 /** 转试 */:
        case 6 /** 转短 */:
          this.$set(this.$data, 'isChange', true)
          break
        /** 校际互转学生不能进行校际互转、转学、申请短期入学、试读 */
        case 2 /** 校际互转 */:
        case 7 /** 校短 */:
        case 8 /** 校试 */:
          this.$set(this.$data, 'isTurnSchool', true)
          break
        case 4 /** 短期 */:
          this.$set(this.$data, 'isShort', true)
          break
        case 0 /** 正常 */:
          break
      }
    } else {
      this.$set(this.$data, 'isTurnSchool', false)
      this.$set(this.$data, 'isTrial', false)
      this.$set(this.$data, 'isChange', false)
      this.$set(this.$data, 'isShort', false)
    }

    // prettier-ignore
    this.$set(this.$data, 'flat', selection.reduce((flat: Flat, item) => {
          return flat[item.studentID] = true, flat
        }, {}))
  }

  /** 监听搜索条件 */
  @Watch('parameter', { immediate: true })
  private watchParameter(parameter: PageTyping.SearchParameter) {
    if (Object.keys(parameter).length > 0) {
      // this.handleSearch(parameter)
    }
  }

  // 初始化查询
  @Watch('parameter.unitID')
  private watchUnitID(value: number, oldValue: unknown) {
    // 有条件缓存时，不走默认查询
    const para = getParameter(`${this.$route.meta.authCode}`)
    if (para) return

    // 无条件缓存时，默认查询一次
    if (oldValue === null || oldValue === undefined) {
      this.localParameter = { ...this.parameter }
      this.fetch()
    }
  }

  created() {
    // 若有查询条件缓存，则回显查询条件
    const para = getParameter(`${this.$route.meta.authCode}`)
    if (para) {
      let parameters = {}
      let enterDate: any = para.searches.enterDate

      if (enterDate === '' || !Boolean(enterDate)) {
        enterDate = void 0
      } else {
        enterDate = new Date(enterDate)
      }

      parameters = Object.assign({}, para.searches, { enterDate: enterDate })

      this.$set(this.$data, 'localParameter', parameters)
      this.$set(this.$data, 'parameter', parameters)
      this.page = para.page
      this.pageSize = para.pageSize
      this.fetch()
    }
  }

  /** 查询数据 */
  private fetch() {
    if (
      this.fetching === true /** 正在请求数据 */ ||
      this.valid !== true /** 参数无效 */
    ) {
      return void 0
    }

    this.fetchStudents(
      Object.assign(
        {},
        {
          page: this.page,
          pageSize: this.pageSize,
          authCode: this.authCode,
          searches: this.convertParameter(),
          actionType: ActionType.Divide,
        }
      )
    )

    // 缓存查询条件
    setParameter(
      `${this.$route.meta.authCode}`,
      Object.assign(
        {},
        {
          page: this.page,
          pageSize: this.pageSize,
          authCode: this.authCode,
          searches: Object.assign({}, this.localParameter),
          actionType: ActionType.Divide,
        }
      )
    )
  }

  private convertParameter() {
    return convertSearchParameter(this.localParameter)
  }

  /** 页面按钮单击操作 */
  private handleClick(action: string, student: Student.Entity) {
    if (Boolean(this.parameter.unitID) === false) {
      return this.$Message.warning(`${this.$t('content.noUnitID')}`)
    }

    const { unitID = '', termID = '' } = this.localParameter
    switch (action) {
      case 'export' /** 导出 */:
        this.$set(this.$data, 'modal', {
          name: 'export',
          data: {
            list: this.selection.map(item => ({ id: item.studentID })),
            searches: this.convertParameter(),
            authCode: this.authCode,
            actionType: ActionType.Divide,
            type: PageType.DivideClass,
          },
        })
        break
      case 'sms' /** 发短信 */:
        this.$set(this.$data, 'modal', {
          name: 'sms',
          data: {
            list: this.selection.map(item => {
              return {
                id: item.studentID,
                name: item.studentName,
                mobile: item.smsPhone,
              }
            }),
            authCode: this.authCode,
            searches: this.convertParameter(),
            actionType: ActionType.Divide,
            pageType: PageType.DivideClass,
          },
        })
        break
      case 'sms-record' /** 短信发送记录 */:
        this.$router.push({
          name: 'divide-class-sms-record',
          query: this.convertParameter(),
        })
        break
      case 'view-student' /** 学生查看 */:
        this.$router.push({
          name: 'divide-class-student-detail',
          params: {
            xuexinID: student.studentID,
            unitID: `${unitID}`,
            termID: `${termID}`,
            parentUnitID: `${student.parentUnitID || ''}`,
          },
        })
        break
      case 'edit' /** 学生编辑 */:
        {
          const {
            termID: sTermID,
            unitID: sUnitID,
            studentID,
            xuexinID,
          } = this.selection[0]
          this.$router.push({
            name: 'divide-class-student-edit',
            params: {
              termID: sTermID,
              unitID: `${sUnitID}`,
              xuexinID: `${studentID || xuexinID}`,
            },
          })
        }

        break
      case 'inter-change-school' /** 添加校际互转 */:
        const studentID = this.selection[0].studentID
        const phaseID = this.selection[0].phaseID
        this.$set(this.$data, 'modal', {
          name: 'inter-change-school',
          data: {
            studentID,
            outUnitID: unitID,
            termID: termID,
            organID: this.organID,
            phaseID,
          },
        })
        break
      case 'change-school' /** 转学 */:
        this.$set(this.$data, 'modal', {
          name: 'change-school',
          data: {
            student: this.selection[0],
            organID: this.organID,
            unitID: unitID,
            termID: termID,
          },
        })
        break
      case 'apply-admission' /** 申请短期入学 */:
        this.$set(this.$data, 'modal', {
          name: 'apply-admission',
          data: {
            student: this.selection[0],
            organID: this.organID,
            unitID: unitID,
            termID: termID,
          },
        })
        break
      case 'trial-reading' /** 试读 */:
        this.$set(this.$data, 'modal', {
          name: 'trial-reading',
          data: {
            student: this.selection[0],
            organID: this.organID,
            unitID: unitID,
            termID: termID,
          },
        })
        break
      case 'import-record' /** 导入记录 */:
        if (Boolean(unitID) === false) {
          return this.$Message.warning(this.$t('content.noText'))
        }
        this.$set(this.$data, 'modal', {
          name: 'import-record',
          data: {
            termID: termID,
            unitID: unitID,
            type: Constant.PageType.DivideClass,
          },
        })
        break
      case 'template-download' /** 模板下载 */:
        const ids = this.selection.map(item => {
          return Number(item.studentID)
        })

        if (Object.keys(this.localParameter).length === 0) {
          return this.$Message.warning(this.$t('content.noText'))
        }

        this.fetchUrl({
          ids: ids,
          searches: this.convertParameter(),
        })
        break
      case 'batch-import' /** 批量导入 */:
        if (Boolean(termID) === false) {
          return this.$Message.warning(this.$t('content.noText'))
        }
        this.$set(this.$data, 'modal', {
          name: 'batch-import',
          data: {
            unitID: unitID,
            termID: termID,
            pageType: PageType.DivideClass,
            importType: ImportType.Divide,
          },
        })
        break
      case 'divide-class' /** 分班 */:
        this.$set(this.$data, 'modal', {
          name: 'divide-class',
          data: {
            list: this.selection.map(item => ({ id: item.studentID })),
            searches: this.convertParameter(),
          },
        })
        break
      case 'divide-type' /** 分类类型 */:
        {
          this.operateID = `${student.studentID}`
          switch (student.divideType) {
            case DivideClassType.Enrollment /** 新生状态时，可以取消录取 */:
            case DivideClassType.Reentry /** 重新入校，可以取消录取 */:
            case DivideClassType.GradReentry /**毕业重新入校，可以取消录取 */:
            case DivideClassType.Reread /** 复读，可以取消录取 */:
              this.$Modal.confirm({
                title: `${this.$t('content.title')}`,
                content: `${this.$t('content.msg')}`,
                loading: true,
                onOk: () => {
                  // matriculate 0: 未录取， 1: 已录取
                  // load =true
                  this.update({
                    termID: Number(termID),
                    unitID: Number(unitID),
                    studentID: student.studentID,
                    actionType: ActionType.CancelAdmission,
                  }).then(() => {
                    this.$Modal.remove()
                  })
                },
                onCancel: () => {},
              })
              break
            case DivideClassType.Upgrade /** 直升状态时，可以撤销 */:
              this.$Modal.confirm({
                title: `${this.$t('text.undo')}`,
                content: `${this.$t('content.undo')}`,
                onOk: () => {
                  this.update({
                    termID: Number(termID),
                    unitID: Number(unitID),
                    studentID: student.studentID,
                    actionType: ActionType.Undo,
                    pageType: PageType.DivideClass,
                  })
                },
                onCancel: () => {},
              })
              break
            case DivideClassType.InnerTransfer /** 校际互转状态时，可以查看 */:
              this.$set(this.$data, 'modal', {
                name: 'inter-change-school',
                data: {
                  tranferStudentID: student.tranferStudentID,
                  tranferStatus: 'in',
                  isCheck: true,
                  termID: termID,
                  organID: this.organID,
                },
              })
              break
            default:
              break
          }
        }
        break
      case 'edit-grade' /** 编辑就读年级 */:
        this.$set(this.$data, 'modal', {
          name: 'edit-grade',
          data: {
            list: this.selection.map(item => item.studentID),
            searches: this.convertParameter(),
          },
        })
      case 'create' /** 添加学生(一期不做) */:
      case 'delete' /** 删除(一期不做) */:
        break
      default:
        console.log(`${this.$options.name}.handleClick(${action})`)
        break
    }
  }

  /** 已选学生发生变化时的处理函数 */
  private handleSelectionChange(selection: Student.Entity[]) {
    this.$set(this.$data, 'selection', selection)
  }

  /** 搜索条件触发查询事件 */
  private handleSearch(parameter: PageTyping.SearchParameter) {
    if (parameter.enterDate === '' || void 0 || null) {
      delete parameter.enterDate
    }

    this.$set(this.$data, 'localParameter', parameter)
    this.$set(this.$data, 'isSearched', true)
    this.$set(this.$data, 'page', 1)
    this.fetch()
  }

  /** 窗口功能完成事件处理函数 */
  private handleModalFinish(modal: string, target: string) {
    /** 清空当前窗口 */
    this.$set(this.$data, 'modal', { name: null, data: {} })
    /** 默认不刷新数据 */
    switch (modal) {
      case 'change-school' /** 转学 */:
        this.$Message.success(this.$t('placeholder.success'))
      case 'inter-change-school' /** 校际互转 */:
      case 'apply-admission' /** 短期入学 */:
      case 'trial-reading' /** 试读 */:
      case 'divide-class' /** 分班 */:
      case 'batch-import' /** 批量导入 */:
      case 'edit-grade' /** 编辑就读年级 */:
        this.$set(this.$data, 'localParameter', this.parameter)
        this.fetch()
        break
      default:
        break
    }
  }

  /** 窗口关闭后处理函数 */
  private handleModalHidden() {
    this.$set(this.$data, 'modal', { name: null, data: {} })
    this.$set(this.$data, 'localParameter', this.parameter)
  }

  /** 页码发生变化 */
  private handlePageChange(page: number): void {
    /** 修改本地 `page` 属性值，清空已选数据，并重新请求数据 */
    this.$set(this.$data, 'page', page)
    this.$set(this.$data, 'selection', [])
    this.fetch()
  }

  /** 每页数据发生变化 */
  private handlePageSizeChange(pageSize: number): void {
    /** 修改本地 `pageSize` 属性值，若当前 `page` 为 1，则手动请求数据 */
    this.$set(this.$data, 'pageSize', pageSize)
    this.page === 1 && this.fetch()
  }
}
