export default {
  messages: {
    'zh-CN': {
      columns: {
        school: '学校',
        campus: '校区',
        schoolDepart: '学部',
        phase: '学段',
        manageType: '报读类型',
        classType: '班级类型',
        state: '状态',
        operate: '操作',
      },
      'button-text': {
        query: '搜索',
        create: '添加',
        edit: '编辑',
        delete: '删除',
      },
      message: {
        'confirm-delete': '确定要删除吗？',
        'delete-success': '删除成功！',
        'create-success': '添加成功！',
        'edit-success': '编辑成功！',
        'name-cannot-empty': '校本班型不能为空',
        'select-school': '请选择学校',
        'select-campus': '请选择校区',
        'select-school-depart': '请选择学部',
        'select-phase': '请选择学段',
        'select-manage-type': '请选择报读类型',
        'select-class-type': '请选择班级类型',
      },
      schoolClassType: '校本班型',
      all: '全部',
    },
    'en-US': {
      columns: {
        school: '学校',
        campus: '校区',
        schoolDepart: '学部',
        phase: '学段',
        manageType: '报读类型',
        classType: '班级类型',
        state: '状态',
        operate: '操作',
      },
      'button-text': {
        query: '搜索',
        create: '添加',
        edit: '编辑',
        delete: '删除',
      },
      message: {
        'confirm-delete': '确定要删除吗？',
        'delete-success': '删除成功！',
        'create-success': '添加成功！',
        'edit-success': '编辑成功！',
        'name-cannot-empty': '校本班型不能为空',
        'select-school': '请选择学校',
        'select-campus': '请选择校区',
        'select-school-depart': '请选择学部',
        'select-phase': '请选择学段',
        'select-manage-type': '请选择报读类型',
        'select-class-type': '请选择班级类型',
      },
      schoolClassType: '校本班型',
      all: '全部',
    },
  },
}
