import Vue, { VNodeChildren, VNodeData } from 'vue'
export default Vue.extend({
  name: 'PageFooter',
  functional: true,
  render(createElement, context) {
    const data: VNodeData = {
      class: ['page-footer'].concat(context.data.class),
      style: Object.assign({}, context.data.style),
    }
    const children: VNodeChildren = []

    children.push(
      createElement('span', {
        style: {},
        domProps: {
          innerHTML:
            'Copyright&nbsp;&copy;&nbsp;www.weimingedu.com,&nbsp;All&nbsp;Rights&nbsp;Reserved',
        },
      })
    )

    children.push(createElement('br'))

    children.push(
      createElement('span', {
        style: {},
        domProps: {
          innerHTML:
            context.parent.$i18n?.locale === 'en-US'
              ? 'Weiming Education'
              : '为明教育集团',
        },
      })
    )

    return createElement('footer', data, children)
  },
})
