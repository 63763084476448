























































































































































































































































































import Vue from 'vue'
import { Component, Model, Watch, Prop, Ref } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import { Spin } from 'view-design'
import { TrialRead } from 'xuexin-vuex'
const components = { AppModal, Spin }

const i18n = {
  messages: {
    'zh-CN': {
      title: '查看反馈',
      studentName: '学生姓名',
      feedbackResult: '试读结果',
      tranferDate: '转学日期',
      continueStudy: '继续就读',
      goveUp: '放弃',
      interChangeSchool: '校际互转',
      assessmentLoss: '考核流失',
      notCounted: '不计入',
      count: '计入',
      eachTranferDate: '互转日期',
      inParentUnitName: '转至学校',
      inUnitName: '转至校区',
      direction: '转至方向',
      domestic: '国内',
      international: '国际',
      targetTermName: '转至学期',
      targetGradeName: '转至年级',
      manageTypeName: '报读类型',
      unitClassTypeName: '校本班型',
      enrollDate: '到校日期',
      feedbackNote: '备注',
      operator: '操作人',
      operatorDate: '操作日期',
      null: '-',
      undefined: '-',
      loading: '加载中...',
      noData: '暂无数据',
    },
    'en-US': {},
  },
}

type dataType = {
  shortStudentID: number
  termID: number
  unitID: number
  isEachTranfer: boolean
}
@Component({ name: 'ViewFeedbackModal', components, i18n })
export default class ViewFeedbackModal extends Vue {
  @Model('input', { type: Boolean }) private value!: boolean
  @Prop({ required: true }) private readonly data!: dataType

  @TrialRead.Getter('itemStatus')
  private readonly getStatus!: TrialRead.Getter.ItemStatus
  @TrialRead.Getter('item') private readonly getItem!: TrialRead.Getter.Item
  @TrialRead.Action('fetchItem')
  private readonly fetch!: TrialRead.Action.FetchItem

  private visible = this.value
  private loading = true
  private model: any = {}

  get itemStatus() {
    return this.getStatus(this.shortStudentID)
  }

  get shortStudentID() {
    return this.data.shortStudentID
  }
  get termID() {
    return this.data.termID
  }
  get unitID() {
    return this.data.unitID
  }

  get isEachTranfer() {
    return this.noData ? this.data.isEachTranfer : 'no-data'
  }

  get noData() {
    return Object.keys(this.model).length > 0
  }
  @Watch('value', { immediate: true })
  private watchValue(value: boolean): void {
    this.visible = value
  }

  @Watch('itemStatus.fetching')
  private watchFetching(fetching: boolean, previous: boolean) {
    if (fetching === false && previous === true) {
      if (this.itemStatus.fetchingError !== null) {
        return this.$Message.error(this.itemStatus.fetchingError)
      }
      this.$set(
        this.$data,
        'model',
        Object.assign({}, this.getItem(this.shortStudentID))
      )
    }
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }

  private stopLoading(): void {
    this.loading = false
    this.$nextTick(() => (this.loading = true))
  }

  private handleVisibleChange(visible: boolean): void {
    if (visible) {
      this.fetch({
        shortStudentID: this.shortStudentID,
        termID: this.termID,
        unitID: this.unitID,
      })
    }
    this.$emit('on-visible-change')
  }
  private handleHidden(): void {
    this.$set(this.$data, 'model', {})
    this.stopLoading()
    this.$emit('on-hidden')
  }
}
