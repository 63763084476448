import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ComponentType, upperFirst, convertToMulti } from '@util'
import AppSelect from '@components/app-select'
import { School } from 'xuexin-vuex'

// 学校关键字
type ViewType = 'text'
let labelField = 'unitName'
let valueField = 'unitID'

function createComponent(
  wrappedComponent: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `School${upperFirst(type)}`
  const components = {}
  @Component({ name, components, inheritAttrs: false })
  class HigherOrderComponent extends Vue {
    @School.Action('fetchList')
    private readonly fetchList!: School.Action.FetchList
    @School.Getter('list')
    private readonly getList!: School.Getter.List
    @School.Getter('listStatus')
    private readonly getStatus!: School.Getter.ListStatus

    /** 机构ID */
    @Prop({ type: Number }) private readonly organID!: number
    /** 学期ID */
    @Prop({ type: Number }) private readonly termID!: number
    /** 学校ID */
    // @Prop({ type: Number }) private readonly unitID!: number
    @Prop({ type: [Number, Array] }) private readonly unitID?:
      | number
      | Array<number>
    /** 学期注册、试读需传入type：1: 学籍系统 2：试读期限 3：学期注册 */
    @Prop({ type: Number, default: 1 }) private readonly type!: number
    /** text显示 */
    @Prop({ type: String }) private readonly viewType!: ViewType
    /** 区分学校校区 true: 校区，false: 学校 */
    @Prop({ type: Boolean, default: false }) private readonly capture!: boolean
    /** 是否允许缓存 */
    @Prop({ type: Boolean, default: false }) private readonly cache!: boolean
    /** 额外数据 */
    @Prop({ default: () => [] }) private readonly extra!: any[]
    /** 过滤相关权限下的学校 */
    @Prop({ type: [Number, String] }) private readonly filter!: number | string
    /** 报名方向 1:国内 ， 2: 国外, 3: 既有国际又有国内*/
    @Prop({ type: [Number, String] }) private readonly direction!:
      | number
      | string
    /** 是否内转弹窗 */
    @Prop({ type: Boolean, default: false })
    private readonly innerTransUnit!: boolean

    get classes() {
      return {
        [`school-${type}`]: true,
        [`school-${type}-${this.viewType}`]: this.viewType !== void 0,
      }
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.loading = this.fetching
          props.error = this.status.fetchingError
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    get parameter() {
      const parameter: any = {
        organID: this.organID,
        termID: this.termID,
        xjType: this.type,
        filter: this.filter,
        innerTransUnit: this.innerTransUnit,
      }

      if (this.capture === true) {
        parameter.parentUnitID = convertToMulti(this.unitID)
      }

      return parameter
    }

    get valid() {
      /** capture 为 true 时，表示校区组件  */
      if (this.capture === true) {
        return Boolean(this.unitID)
      }
      return Boolean(this.termID) || this.termID === 0
    }

    get status() {
      return this.getStatus(this.parameter)
    }

    get fetching() {
      return this.status.fetching === true
    }

    get list() {
      /** 参数不合法时，直接返回 */
      if (this.valid !== true) {
        return this.extra
      }

      //当内转时宝安学校要能转到深圳学校
      let params = { ...this.parameter }
      if (this.innerTransUnit) {
        params.parentUnitID =
          this.parameter.parentUnitID === 468112
            ? 468106
            : this.parameter.parentUnitID
      }
      const schools = this.getList(params)
      let filterSchools
      if (Boolean(this.direction) === true) {
        filterSchools = schools.filter(
          item =>
            item.enable !== 0 &&
            (item.direction === Number(this.direction) ||
              item.direction === 3) /** 3:既是国内又是国外 */
        )
      } else {
        filterSchools = schools.filter(item => item.enable !== 0)
      }
      return this.extra.concat(filterSchools)
    }

    get value() {
      return Number(this.$attrs.value)
    }

    @Watch('parameter')
    watchParameter(parameter: any, previous: any) {
      /** 当前组件为校区时，监听学校ID的变化 */
      if (
        this.capture === true &&
        parameter?.parentUnitID !== previous.parentUnitID
      ) {
        return this.fetch()
      }

      /** 当前组件为学校时，监听学期或者机构ID的变化 */
      if (
        parameter.termID !== previous.termID ||
        parameter.organID !== previous.organID
      ) {
        return this.fetch()
      }
    }

    // @Watch('fetching', { immediate: true, deep: true })
    // watchFetching(fetching: boolean, previous: boolean) {
    //   if (fetching === true && previous === false) {
    //     this.$emit('on-finish', this.list)
    //   }
    // }

    @Watch('list', { immediate: true, deep: true })
    watchList(curr: any, prev: any) {
      if (curr !== prev) {
        this.$emit('on-finish', this.list)
      }
    }

    created() {}

    render(createElement: Vue.CreateElement) {
      let tag: any = wrappedComponent
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        tag = 'span'
        const item = this.list.find(item => item[valueField] === this.value)
        const text =
          item !== void 0
            ? item[labelField]
            : this.$i18n.locale === 'en-US'
            ? '-'
            : '无匹配项'
        children = [text]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs, {}),
          on: this.$listeners,
        },
        children
      )
    }

    mounted() {
      // this.fetch()
      const _this = this
      setTimeout(function() {
        _this.fetch()
      }, 50)
    }

    private fetch() {
      //  (this.cache === true && this.status.loaded === true) || // 允许缓存且已有缓存,当前请求的不是校区数据
      if (
        this.status.fetching === true || // 正在请求中
        this.valid !== true // 参数不合法
      ) {
        /** 以上条件成立时，不发送请求 */
        return void 0
      }

      this.fetchList(this.parameter)
    }
  }

  return HigherOrderComponent
}

export const SchoolSelect = createComponent(AppSelect, ComponentType.Select)
