import { Constant } from 'xuexin-vuex'

export type DateType = Constant.DateType
export const DateType = Constant.DateType

export const DateTypeList = [DateType.Today, DateType.Before, DateType.Duration]

export const messages: Messages = {
  'zh-CN': {
    [`${DateType.Today}`]: '当天',
    [`${DateType.Before}`]: '之前',
    [`${DateType.Duration}`]: '时间段',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [`${DateType.Today}`]: '当天',
    [`${DateType.Before}`]: '之前',
    [`${DateType.Duration}`]: '时间段',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
