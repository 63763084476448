import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'
import CountryList from './data.json'

type ViewType = 'text'
const valueField = 'regionCode'
const labelField = 'regionName'

const prefixCls = `nationality`
function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `Nationality${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class NationalityComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType!: ViewType

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }

    get locale(): Locale {
      return this.$i18n.locale as Locale
    }

    get list() {
      return this.extra.concat(CountryList)
    }

    get props() {
      const props: Record<string, any> = {}
      switch (type) {
        case ComponentType.Select:
          props.valueField = valueField
          props.labelField = labelField
          props.data = this.list
          break
      }
      return props
    }

    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (type === ComponentType.Text || this.viewType === 'text') {
        children = [`中国`]
      }

      return createElement(
        component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, { filterable: true }, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return NationalityComponent
}

export const NationalitySelect = createComponent(
  AppSelect,
  ComponentType.Select
)
export const NationalityText = createComponent('span', ComponentType.Text)
