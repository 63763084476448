import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Abroad } from 'xuexin-vuex'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'

const valueField = 'abroadID'
const labelField = 'abroadName'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `Abroad${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class AbroadComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: Boolean, default: false }) private readonly cache!: boolean
    @Prop({ type: String }) private readonly viewType?: string

    @Abroad.Action('fetchList')
    private readonly fetchList!: Abroad.Action.FetchList
    @Abroad.Getter('listStatus')
    private readonly status!: Abroad.Getter.ListStatus
    @Abroad.Getter('list')
    private readonly abroads!: Abroad.Getter.List

    get classes() {
      return {
        [`abroad-${type}`]: true,
        [`abroad-${type}-${this.viewType}`]: this.viewType !== void 0,
      }
    }
    get props() {
      const props: Record<string, any> = {}

      switch (type) {
        case ComponentType.Select:
          props.loading = this.fetching
          props.error = this.status.fetchingError
          props.data = this.list
          props.valueField = valueField
          props.labelField = labelField
          break
        default:
          break
      }
      return props
    }

    get fetching() {
      return this.status.fetching !== false
    }

    get list() {
      return this.extra.concat(
        this.abroads.filter(item => {
          // 过滤被禁用的数据
          return item.enable !== 0
        })
      )
    }

    get value() {
      return Number(this.$attrs.value)
    }

    created() {
      if (
        this.status.fetching === true || // 正在请求中
        (this.cache === true && this.status.loaded === true) // 允许缓存且已有缓存
      ) {
        // 以上两种情况，不需要请求数据
        return void 0
      }

      this.fetchList({})
    }

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      /** 以文本方式显示 */
      if (this.viewType === 'text') {
        tag = 'span'
        const item = this.list.find(item => item[valueField] === this.value)
        const text = item !== void 0 ? item[labelField] : '-'
        children = [text]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return AbroadComponent
}

export const AbroadSelect = createComponent(AppSelect, ComponentType.Select)
