import Vue, { CreateElement, VNodeData, VNodeChildren } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Tooltip } from 'view-design'
import i18n, { FlowStatus } from './i18n'
import { DateUtil } from '@util'

const colors = {
  [`${FlowStatus.TrialReading}`]: '#9bb702',
  [`${FlowStatus.InterSchoolTransfer}`]: '#2ed8f7',
  [`${FlowStatus.Transfer}`]: '#ff9955',
  [`${FlowStatus.ShortTerm}`]: '#39BA60',
  [`${FlowStatus.TransferTrial}`]: '#ff7171',
  [`${FlowStatus.TransferShort}`]: '#ff85de',
  [`${FlowStatus.SchoolShort}`]: '#2e9bf7',
  [`${FlowStatus.SchoolTrial}`]: '#2b57d4',
}

const messages: Messages = {
  'zh-CN': {
    [`${FlowStatus.Normal}`]: '',
    [`${FlowStatus.TrialReading}`]: '试',
    [`${FlowStatus.InterSchoolTransfer}`]: '校',
    [`${FlowStatus.Transfer}`]: '转',
    [`${FlowStatus.ShortTerm}`]: '短',
    [`${FlowStatus.TransferTrial}`]: '转试',
    [`${FlowStatus.TransferShort}`]: '转短',
    [`${FlowStatus.SchoolShort}`]: '校短',
    [`${FlowStatus.SchoolTrial}`]: '校试',
    to: '至',
  },
  'en-US': {
    [`${FlowStatus.Normal}`]: '',
    [`${FlowStatus.TrialReading}`]: '试',
    [`${FlowStatus.InterSchoolTransfer}`]: '校',
    [`${FlowStatus.Transfer}`]: '转',
    [`${FlowStatus.ShortTerm}`]: '短',
    [`${FlowStatus.TransferTrial}`]: '转试',
    [`${FlowStatus.TransferShort}`]: '转短',
    [`${FlowStatus.SchoolShort}`]: '校短',
    [`${FlowStatus.SchoolTrial}`]: '校试',
    to: '至',
  },
}

@Component({ name: 'StudentFlowStatusBadge', i18n })
export default class StudentFlowStatusBadge extends Vue {
  @Prop({ type: Number, required: true }) private readonly value!: FlowStatus
  @Prop({ type: Number }) private readonly startDate!: number
  @Prop({ type: Number }) private readonly endDate!: number

  private readonly prefixCls = 'student-flow-status'

  get tooltip() {
    if (
      typeof this.startDate !== 'number' ||
      typeof this.endDate !== 'number'
    ) {
      return false
    }

    return (
      this.value === FlowStatus.TrialReading /** 试读生 */ ||
      this.value === FlowStatus.ShortTerm /** 短期生 */ ||
      this.value === FlowStatus.TransferTrial /** 转试 */ ||
      this.value === FlowStatus.TransferShort /** 转短 */ ||
      this.value === FlowStatus.SchoolShort /** 校短 */ ||
      this.value === FlowStatus.SchoolTrial /** 校试 */
    )
  }

  get content() {
    return (
      `${DateUtil.format(this.startDate, 'yyyy/MM/dd')}` +
      ` ${this.$t('to')} ` +
      `${DateUtil.format(this.endDate, 'yyyy/MM/dd')}`
    )
  }

  get props() {
    const props: Record<string, any> = {}
    if (this.tooltip) {
      props.placement = 'right-start'
      props.content = this.content
    }
    return props
  }

  render(createElement: CreateElement) {
    if (this.value === FlowStatus.Normal) {
      return null
    }

    const data: VNodeData = {
      class: `${this.prefixCls}_badge`,
      style: {
        position: 'absolute',
        right: '2px',
        top: '2px',
        color: '#fff',
        fontSize: '12px',
        padding: '0px 4px',
        'border-radius': '4px',
        background: colors[this.value],
      },
      props: this.props,
    }

    const children: VNodeChildren = [messages[this.$i18n.locale][this.value]]

    return createElement(this.tooltip ? Tooltip : 'span', data, children)
  }
}
