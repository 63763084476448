import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import AppSelect from '@components/app-select'
import i18n, { StudentMarkList } from './i18n'

type ViewType = 'text'

function createComponent(
  component: Vue.Component,
  type: ComponentType
): VueConstructor {
  const name = `StudentMark${upperFirst(type)}`
  const components = {}
  @Component({ name, components, i18n, inheritAttrs: false })
  class StudentMarkComponent extends Vue {
    @Prop({ type: String }) private readonly viewType?: ViewType
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]

    get classes() {
      return {
        [`student-mark-${type}`]: this.viewType === void 0,
        [`student-mark-${type}`]: this.viewType !== void 0,
      }
    }

    get props() {
      const props: Record<string, any> = {}
      if (this.viewType === 'text') {
        return props
      }

      switch (type) {
        case ComponentType.Select:
          props.data = this.list
          break
        default:
          break
      }
      return props
    }

    get list() {
      if (this.viewType === 'text') {
        return []
      }
      const list = StudentMarkList.map(item => {
        return { label: this.$t(`${item}`), value: item }
      })
      return this.extra.concat(list)
    }

    created() {}

    render(createElement: Vue.CreateElement) {
      let tag: any = component
      let children: VNodeChildren = []

      if (this.viewType === 'text') {
        tag = 'span'
        children = [`${this.$t(this.$attrs.value)}`]
      }

      return createElement(
        tag,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }
  }

  return StudentMarkComponent
}

export const StudentMarkSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
