var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ViewLayout',{attrs:{"name":_vm.prefixCls,"loading":_vm.fetching}},[_c('div',{class:_vm.classes},[_c('div',{ref:"head",class:("card " + _vm.prefixCls + "_head")},[_c('ReportSearch',{on:{"on-search":_vm.handleSearch,"on-export":_vm.handleExport},model:{value:(_vm.parameter),callback:function ($$v) {_vm.parameter=$$v},expression:"parameter"}})],1),_vm._v(" "),_c('div',{class:("card " + _vm.prefixCls + "_chart")},[(
          (_vm.dataSources['organ-school'] &&
            _vm.statistic === 1 &&
            (_vm.dataSources['organ-school'].studentCount === null ||
              _vm.dataSources['organ-school'].studentCount === undefined ||
              _vm.dataSources['organ-school'].studentCount === 0)) ||
            (((_vm.statistic !== 1 &&
              _vm.dataSources['unit-schooldepart'] &&
              _vm.dataSources['unit-schooldepart'].schooldeparts.length < 1) ||
              (_vm.dataSources['organ-schooldepart'] &&
                _vm.dataSources['organ-schooldepart'].schooldeparts.length < 1) ||
              (_vm.dataSources['unit-manageType'] &&
                _vm.dataSources['unit-manageType'].manageTypes.length < 1) ||
              (_vm.dataSources['organ-manageType'] &&
                _vm.dataSources['organ-manageType'].manageTypes.length < 1)) &&
              _vm.dataSources.grades.length < 1 &&
              ((_vm.dataSources['organ-school'] &&
                _vm.dataSources['organ-school'].series.lenth < 1 &&
                _vm.dataSources['organ-school'].category.lenth < 1 &&
                _vm.dataSources['organ-schooldepart'] &&
                _vm.dataSources['organ-schooldepart'].series.length < 1 &&
                _vm.dataSources['organ-schooldepart'].category.length < 1 &&
                _vm.dataSources['organ-manageType'] &&
                _vm.dataSources['organ-manageType'].series.length < 1 &&
                _vm.dataSources['organ-manageType'].category.length < 1) ||
                (_vm.dataSources['unit-schooldepart'] &&
                  _vm.dataSources['unit-schooldepart'].series.length < 1 &&
                  _vm.dataSources['unit-schooldepart'].category.length < 1 &&
                  _vm.dataSources['unit-manageType'] &&
                  _vm.dataSources['unit-manageType'].series.length < 1 &&
                  _vm.dataSources['unit-manageType'].category.length < 1)))
        )?_c('div',{staticClass:"no-data"},[_c('img',{attrs:{"src":require("../../dashboard/assets/no-data.png"),"alt":""}}),_vm._v("\n        "+_vm._s(_vm.$t('message.noDataText'))+"\n      ")]):_c('div',{staticClass:"detail"},[_c('div',{staticClass:"tab"},[_c('ul',_vm._l((_vm.statisticData),function(item){return _c('li',{key:item.value,class:_vm.statistic === item.value ? 'active' : ''},[_c('span',{on:{"click":function($event){return _vm.handleType(item.value)}}},[_vm._v(_vm._s(item.label))])])}),0)]),_vm._v(" "),_c('div',{staticClass:"left"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.statistic === 1),expression:"statistic === 1"}],staticClass:"only-column"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('message.studentCount')))]),_vm._v(" "),(_vm.dataSources['organ-school'])?_c('div',{staticClass:"value"},[_vm._v("\n              "+_vm._s(_vm.dataSources['organ-school'].studentCount)+"\n            ")]):_vm._e()]),_vm._v(" "),(
              _vm.statistic !== 1 &&
                (_vm.dataSources['unit-schooldepart'] ||
                  _vm.dataSources['organ-schooldepart'] ||
                  _vm.dataSources['unit-manageType'] ||
                  _vm.dataSources['organ-manageType'])
            )?_c('HomeInfo',{attrs:{"type":_vm.statistic === 2 ? 'schoolDepart' : 'manageType',"table-data":_vm.statistic === 2
                ? _vm.identityType === 'school'
                  ? _vm.dataSources['unit-schooldepart'].schooldeparts
                  : _vm.dataSources['organ-schooldepart'].schooldeparts
                : _vm.identityType === 'school'
                ? _vm.dataSources['unit-manageType'].manageTypes
                : _vm.dataSources['organ-manageType'].manageTypes}}):_vm._e(),_vm._v(" "),_c('HomeInfo',{attrs:{"type":"grade","table-data":_vm.dataSources.grades}})],1),_vm._v(" "),(
            (_vm.dataSources['organ-school'] &&
              _vm.dataSources['organ-schooldepart'] &&
              _vm.dataSources['organ-manageType']) ||
              (_vm.dataSources['unit-schooldepart'] &&
                _vm.dataSources['unit-manageType'])
          )?_c('div',{staticClass:"right"},[_c('HomeEcharts',{attrs:{"type":_vm.statistic === 1
                ? 'school'
                : _vm.statistic === 2
                ? _vm.identityType === 'school'
                  ? 'unitSchoolDepart'
                  : 'schoolDepart'
                : 'manageType',"series":_vm.statistic === 1
                ? _vm.dataSources['organ-school'].series
                : _vm.statistic === 2
                ? _vm.identityType === 'school'
                  ? _vm.dataSources['unit-schooldepart'].series
                  : _vm.dataSources['organ-schooldepart'].series
                : _vm.identityType === 'school'
                ? _vm.dataSources['unit-manageType'].series
                : _vm.dataSources['organ-manageType'].series,"xAxisData":_vm.statistic === 1
                ? _vm.dataSources['organ-school'].category
                : _vm.statistic === 2
                ? _vm.identityType === 'school'
                  ? _vm.dataSources['unit-schooldepart'].category
                  : _vm.dataSources['organ-schooldepart'].category
                : _vm.identityType === 'school'
                ? _vm.dataSources['unit-manageType'].category
                : _vm.dataSources['organ-manageType'].category,"legend":_vm.statistic === 2 && _vm.identityType === 'mechanism'
                ? _vm.schoolDepartNames
                : _vm.statistic === 3
                ? _vm.manageTypeNames
                : undefined}})],1):_vm._e()])]),_vm._v(" "),_c('div',{class:("card " + _vm.prefixCls + "_body")},[_c('ReportToolbar',{class:(_vm.prefixCls + "_toolbar"),attrs:{"pageName":"SchoolOverview"},on:{"on-change-type":_vm.handleStatisticalForm,"on-change-column":_vm.handleChangeColumn},model:{value:(_vm.tParameter),callback:function ($$v) {_vm.tParameter=$$v},expression:"tParameter"}}),_vm._v(" "),_c('AppTable',{class:(_vm.prefixCls + "_table"),attrs:{"no-data-text":_vm.noDataText,"data":_vm.defaultData,"loading":_vm.fetching,"columns":_vm.tableColumns,"span-method":_vm.objectSpanMethod,"row-class-name":_vm.rowClassName}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }