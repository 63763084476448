import {Session} from 'types'
import {Constant}  from 'xuexin-vuex'
import Vue from 'vue'
import { namespace as BindingNamespace } from 'vuex-class'
import Ajax from 'xuexin-vuex/src/ajax'
import Api from 'xuexin-vuex/src/api'
import omit from 'lodash.omit'


export const namespace = '@xuexin-vuex/session'
export const types = {
  /** Http 状态码 */
  HTTP_CODE_401: 'HTTP_CODE_401',
  HTTP_CODE_403: 'HTTP_CODE_403',

  /** 更新权限 */
  UPDATE_AUTHS: 'UPDATE_AUTHS',

  /** 登入 */
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_COMPLETE: 'LOGIN_COMPLETE',

  /** 登出 */
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  LOGOUT_COMPLETE: 'LOGOUT_COMPLETE',

  /** 发送短信验证码 */
  SEND_SMS_CODE_REQUEST: 'SEND_SMS_CODE_REQUEST',
  SEND_SMS_CODE_SUCCESS: 'SEND_SMS_CODE_SUCCESS',
  SEND_SMS_CODE_FAILURE: 'SEND_SMS_CODE_FAILURE',
  SEND_SMS_CODE_COMPLETE: 'SEND_SMS_CODE_COMPLETE',

  /** 校验短信验证码 */
  VERIFY_SMS_CODE_REQUEST: 'VERIFY_SMS_CODE_REQUEST',
  VERIFY_SMS_CODE_SUCCESS: 'VERIFY_SMS_CODE_SUCCESS',
  VERIFY_SMS_CODE_FAILURE: 'VERIFY_SMS_CODE_FAILURE',
  VERIFY_SMS_CODE_COMPLETE: 'VERIFY_SMS_CODE_COMPLETE',

  /** 修改密码 */
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_COMPLETE: 'RESET_PASSWORD_COMPLETE',

  /** 获取用户信息 */
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
  FETCH_COMPLETE: 'FETCH_COMPLETE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  UPDATE_USER_COMPLETE: 'UPDATE_USER_COMPLETE',
}

const state: Session.State = {
  data: null,
  unauthorized: false,
  forbidden: false,
  isInitiative: false,
  loginUrl:''
}

const actions: Session.Actions = {
  login(context, payload) {
    return Ajax(context, {
      type: types.LOGIN_REQUEST,
      payload,
      api: `post ${Api.personal}/user/login`,
      parameter: payload,
      success: types.LOGIN_SUCCESS,
      failure: types.LOGIN_FAILURE,
      complete: types.LOGIN_COMPLETE,
    })
  },
  logout(context, payload) {
    return Ajax(context, {
      type: types.LOGOUT_REQUEST,
      payload,
      api: `get ${Api.student}/oauth/user/logout`,
      success: types.LOGOUT_SUCCESS,
      failure: types.LOGOUT_FAILURE,
      complete: types.LOGOUT_COMPLETE,
    })
  },
  sendSMSCode(context, payload) {
    return Ajax(context, {
      type: types.SEND_SMS_CODE_REQUEST,
      payload,
      api: `post ${Api.personal}/user/sms`,
      parameter: payload,
      success: types.SEND_SMS_CODE_SUCCESS,
      failure: types.SEND_SMS_CODE_FAILURE,
      complete: types.SEND_SMS_CODE_COMPLETE,
    })
  },
  verifySMSCode(context, payload) {
    return Ajax(context, {
      type: types.VERIFY_SMS_CODE_REQUEST,
      payload,
      api: `post ${Api.personal}/user/sms/verification`,
      parameter: payload,
      success: types.VERIFY_SMS_CODE_SUCCESS,
      failure: types.VERIFY_SMS_CODE_FAILURE,
      complete: types.VERIFY_SMS_CODE_COMPLETE,
    })
  },
  resetPassword(context, payload) {
    return Ajax(context, {
      type: types.RESET_PASSWORD_REQUEST,
      payload,
      api: `put ${Api.personal}/user/pwd/reset`,
      parameter: payload,
      success: types.RESET_PASSWORD_SUCCESS,
      failure: types.RESET_PASSWORD_FAILURE,
      complete: types.RESET_PASSWORD_COMPLETE,
    })
  },
  fetch(context, payload) {
    return Ajax(context, {
      type: types.FETCH_REQUEST,
      payload,
      api: `get ${Api.student}/oauth/user/info`,
      parameter: payload,
      success: types.FETCH_SUCCESS,
      failure: types.FETCH_FAILURE,
      complete: types.FETCH_COMPLETE,
    })
  },
  updateUser(context, payload) {
    const { identityType, ...parameter } = payload
    return Ajax(context, {
      type: types.UPDATE_USER_REQUEST,
      payload,
      api: `put ${Api.personal}/user/userLoginType/${identityType}`,
      parameter,
      success: types.UPDATE_USER_SUCCESS,
      failure: types.UPDATE_USER_FAILURE,
      complete: types.UPDATE_USER_COMPLETE,
    })
  },
}

const mutations: Session.Mutations = {
  /** 登入 */
  [types.LOGIN_REQUEST](state) {
    Vue.set(state, 'isInitiative', false)
    Vue.set(state, 'logining', true)
    Vue.set(state, 'loginingError', null)
    Vue.set(state, 'fetchingError', null)
  },
  [types.LOGIN_SUCCESS](state, { result }) {
    Vue.set(state, 'data', Object.assign({}, state.data, result))
    Vue.set(state, 'unauthorized', false)
    Vue.set(state, 'forbidden', false)
  },
  [types.LOGIN_FAILURE](state, { message }) {
    Vue.set(state, 'loginingError', message)
  },
  [types.LOGIN_COMPLETE](state) {
    Vue.set(state, 'logining', false)
  },

  /** 登出 */
  [types.LOGOUT_REQUEST](state) {
    Vue.set(state, 'logouting', true)
    Vue.set(state, 'logoutingError', null)
  },
  [types.LOGOUT_SUCCESS](state) {
    Vue.set(state, 'unauthorized', true)
    Vue.set(state, 'isInitiative', true)
  },
  [types.LOGOUT_FAILURE](state, { message }) {
    Vue.set(state, 'logoutingError', message)
  },
  [types.LOGOUT_COMPLETE](state) {
    Vue.set(state, 'logouting', false)
  },

  /** 发送短信验证码 */
  [types.SEND_SMS_CODE_REQUEST](state) {
    Vue.set(state, 'sending', true)
    Vue.set(state, 'sendingError', null)
  },
  [types.SEND_SMS_CODE_SUCCESS]() {},
  [types.SEND_SMS_CODE_FAILURE](state, { message }) {
    Vue.set(state, 'sendingError', message)
  },
  [types.SEND_SMS_CODE_COMPLETE](state) {
    Vue.set(state, 'sending', false)
  },

  /** 校验短信验证码 */
  [types.VERIFY_SMS_CODE_REQUEST](state) {
    Vue.set(state, 'verifying', true)
    Vue.set(state, 'verifyingError', null)
  },
  [types.VERIFY_SMS_CODE_SUCCESS](state, { result }) {
    Vue.set(state, 'uid', result?.uid || null)
    Vue.set(state, 'xuexinID', result?.xuexinID || null)
  },
  [types.VERIFY_SMS_CODE_FAILURE](state, { message }) {
    Vue.set(state, 'verifyingError', message)
  },
  [types.VERIFY_SMS_CODE_COMPLETE](state) {
    Vue.set(state, 'verifying', false)
  },

  /** 修改密码 */
  [types.RESET_PASSWORD_REQUEST](state) {
    Vue.set(state, 'resetting', true)
    Vue.set(state, 'resettingError', null)
  },
  [types.RESET_PASSWORD_SUCCESS]() {},
  [types.RESET_PASSWORD_FAILURE](state, { message }) {
    Vue.set(state, 'resettingError', message)
  },
  [types.RESET_PASSWORD_COMPLETE](state) {
    Vue.set(state, 'resetting', false)
  },

  /** 获取用户信息 */
  [types.FETCH_REQUEST](state) {
    Vue.set(state, 'fetching', true)
    Vue.set(state, 'fetchingError', null)
  },
  [types.FETCH_SUCCESS](state, { result }) {
  },
  [types.FETCH_FAILURE](state, { message,result }) {
    Vue.set(state, 'loginUrl', result?.loginUrl)
    Vue.set(state, 'fetchingError', message)
  },
  [types.FETCH_COMPLETE](state, { message,result }) {
    Vue.set(state, 'loginUrl', result?.loginUrl)
    Vue.set(state, 'fetching', false)
  },

  /** 更新当前用户的配置信息 */
  [types.UPDATE_USER_REQUEST](state, { payload }) {
    switch (payload.identityType) {
      case Constant.IdentityType.Organization:
        Vue.set(state.data!, 'identityType', payload.identityType)
        Vue.set(state.data!, 'organID', payload.organID)
        break
      case Constant.IdentityType.School:
        Vue.set(state.data!, 'identityType', payload.identityType)
        Vue.set(state.data!, 'parentUnitID', payload.parentUnitID)
        break
      default:
        break
    }
    Vue.set(state, 'updating', true)
    Vue.set(state, 'updatingError', null)
  },
  [types.UPDATE_USER_SUCCESS]() {},
  [types.UPDATE_USER_FAILURE](state, { message }) {
    Vue.set(state, 'updatingError', message)
  },
  [types.UPDATE_USER_COMPLETE](state) {
    Vue.set(state, 'updating', false)
  },

  /** 更新用户权限 */
  [types.UPDATE_AUTHS](state, { payload }) {
    if (state.data === void 0 || state.data === null) {
      return void 0
    }
    Object.keys(payload).forEach(key => {
      Vue.set(state.data!, key, payload[key])
    })
  },

  /** HTTP 状态码错误 */
  [types.HTTP_CODE_401](state) {
    Vue.set(state, 'unauthorized', true)
  },

  [types.HTTP_CODE_403](state) {
    Vue.set(state, 'forbidden', true)
  },
}

const getters: Session.Getters = {
  user(state) {
    return state.data
  },
  status(state) {
    return omit(state, ['data'])
  },
  auths(state) {
    return state.data?.auths || []
  },
  units(state) {
    const { organList = [], unitList = [] } = state.data || {}
    const list: Session.Unit[] = organList.map(item => {
      return {
        name: item.organName,
        id: item.organID,
        type: Constant.IdentityType.Organization,
      }
    })

    const flat: Record<string, boolean> = {}
    unitList.forEach(({ parentUnitID, parentUnitName }) => {
      if (flat[parentUnitID] === true || Boolean(parentUnitID) === false) {
        return void 0
      }
      flat[parentUnitID] = true

      list.push({
        name: parentUnitName,
        id: parentUnitID,
        type: Constant.IdentityType.School,
      })
    })

    return list
  },
}

const bindingHelpers = BindingNamespace(namespace)
export const Module = { state, actions, getters, mutations, namespaced: true }
export const { State, Action, Mutation, Getter } = bindingHelpers
export default { State, Action, Mutation, Getter, Module, namespace, types }
