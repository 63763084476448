export const messages: Messages = {
  'zh-CN': {
    label: {
      status: '发送状态',
      date: '发送时间',
      operator: '操作人',
      to: '至',
      search: '查询',
      back: '返回上一层',
    },
    status: {
      all: '全部',
      send: '已发送',
      unsend: '未发送',
      cancel: '已取消',
    },
    text: {
      date: '请选择日期',
      noDataText: '暂无数据',
      cancel: '取消发送',
      success: '取消成功',
      title: '确认取消',
      content: '确定取消发送吗？',
      copy: '复制成功',
      none: '无',
    },
    table: {
      index: '序号',
      sendDate: '发送时间',
      msg: '短信内容',
      autograph: '短信签名',
      successCount: '成功数',
      failureCount: '失败数',
      createDate: '创建时间',
      status: '发送状态',
      operatorInfo: '操作人',
      operate: '操作',
    },
  },
  'en-US': {},
}
export default { messages }
