







































































import Vue from 'vue'
import { Component, Prop, Watch, Model, Ref } from 'vue-property-decorator'
import AppModal from '@components/app-modal'
import {
  Button,
  Upload,
  Form,
  FormItem,
  Icon,
  Modal,
  Radio,
  RadioGroup,
} from 'view-design'
import { Constant, Semester } from 'xuexin-vuex'
import { SemesterSelect } from '@business-components/semester'
import { SchoolSelect } from '@business-components/school'
import { SchoolPhaseSelect } from '@business-components/school-phase'
import { GradeSelect } from '@business-components/grade'
import Export from '@store/modules/export'
import i18n from './i18n'

const components = {
  AppModal,
  Button,
  Form,
  FormItem,
  Upload,
  Icon,
  Radio,
  RadioGroup,
  SemesterSelect,
  SchoolSelect,
  SchoolPhaseSelect,
  GradeSelect,
}

type ImportType = Constant.ImportType

type DataType = {
  termID: number
  parentUnitID: number
  unitID: number
  importType: ImportType
}

type Model = {
  termID: number
  parentUnitID: number
  unitID: number
  phaseID: number
  unitGradeID: number
  files: any
}

@Component({ name: 'ImportModal', components, i18n })
export default class ImportModal extends Vue {
  @Model('input') private value!: boolean
  @Prop({ default: () => {} }) private readonly data!: DataType
  @Export.Action('create')
  private readonly create!: Export.Action.Create
  @Export.Getter('itemStatus')
  private readonly itemStatus!: Export.Getter.ItemStatus
  @Export.Getter('item') private readonly item!: Export.Getter.Item
  @Ref('form') private readonly form!: Form
  @Ref('upload') private readonly upload!: Upload

  private readonly prefixCls = 'import-modal'
  private model: Partial<Model> = { files: '' }
  private visible = false
  private loading = true

  get rules() {
    return {
      termID: [
        {
          required: true,
          message: '请选择学期',
        },
      ],
      parentUnitID: [
        {
          required: true,
          message: '请选择学校',
        },
      ],
      unitID: [
        {
          required: true,
          message: '请选择校区',
        },
      ],
      phaseID: [
        {
          required: true,
          message: '请选择学段',
        },
      ],
      unitGradeID: [
        {
          required: true,
          message: '请选择学校年级',
        },
      ],
      files: [{ message: '请选择文件', type: 'object' }],
    }
  }

  get creating() {
    return this.itemStatus('key_&')?.creating === true
  }

  get creatingError() {
    return this.itemStatus('key_&')?.creatingError || null
  }

  @Watch('value', { immediate: true })
  private watchValue(value: boolean) {
    this.$set(this.$data, 'visible', value)
    if (value) {
      this.$set(this.model, 'termID', this.data.termID)
      this.$set(this.model, 'parentUnitID', this.data.parentUnitID)
      this.$set(this.model, 'unitID', this.data.unitID)
    } else {
      this.$set(this.model, 'phaseID', void 0)
      this.$set(this.model, 'unitGradeID', void 0)
    }
  }

  @Watch('creating')
  private watchCreating(creating: boolean, previous: boolean) {
    if (creating === false && previous === true) {
      if (this.creatingError !== null) {
        this.$Message.error(this.creatingError)
        return this.stopAction()
      }

      const count = this.item('key_&')
      this.$Message.success(
        `${this.$t('text.success')} ${count?.successCount}${this.$t(
          'text.unit'
        )},${this.$t('text.failure')} ${count?.failureCount}${this.$t(
          'text.unit'
        )},${this.$t('text.tip')}`
      )
      this.$set(this.$data, 'model', { files: '' })
      this.$emit('on-finish')
    }
  }

  /** 文件上传 */
  private handleUpload(file: any) {
    /** 展示文件列表 */
    this.model.files = file
    return false
  }

  private handleOK() {
    this.form.validate(valid => {
      const { files } = this.model
      if (valid !== true || files === void 0) {
        return this.stopAction()
      }

      /** 文件上传 */
      const formData = new FormData()

      formData.append('termID', `${this.model.termID}`)
      formData.append('unitID', `${this.model.unitID}`)
      formData.append('unitGradeID ', `${this.model.unitGradeID}`)
      formData.append('originalFile', files) // 文件对象

      this.create({
        file: formData,
        operationType: 'reDivide',
      })
    })
  }

  private handleVisibleChange(visible: boolean) {
    this.$emit('on-visible-change', visible)
  }

  private handleHidden() {
    // this.$set(this.$data, 'model', { files: '' })
    this.$emit('on-hidden')
    this.$set(this.model, 'files', '')
    this.$set(this.model, 'phaseID', void 0)
    this.$set(this.model, 'unitGradeID', void 0)
    this.form.resetFields()
  }

  private handleInput(value: boolean) {
    this.$emit('input', value)
  }
}
