export enum RegistStatus {
  Unregistered = 0,
  Registered = 1,
}

export const RegistStatusList = [
  RegistStatus.Unregistered,
  RegistStatus.Registered,
]

export const messages: Messages = {
  'zh-CN': {
    [RegistStatus.Unregistered]: '未注册',
    [RegistStatus.Registered]: '已注册',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    [RegistStatus.Unregistered]: '未注册',
    [RegistStatus.Registered]: '已注册',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
