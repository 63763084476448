/** 考核流失率统计 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import AssessLossRate from '@pages/statistic-reports/assess-loss-rate'

const children: RouteConfig[] = []
/** 考核流失率统计 */
children.push({
  path: '/reports/assess-loss-rate',
  name: 'assess-loss-rate-report',
  component: AssessLossRate,
  meta: { ignore: true, authCode: '050709', parentAuthCode: '0507' },
})
export default {
  path: '/reports',
  name: 'assess-loss-rate',
  redirect: { name: 'assess-loss-rate-report' },
  meta: { title: '考核流失率统计', authCode: '050709' },
  component: RouterViewLayout,
  props: { name: 'assess-loss-rate' },
  children,
}
