











import Vue from 'vue'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
import ECharts from '@libs/echarts'

const i18n = {
  messages: {
    'zh-CN': {
      text: {
        studentNum: '学生人数',
        turnoverRate: '流失率',
        noData: '暂无数据',
      },
    },
    'en-US': {},
  },
}

@Component({ name: 'HomeECharts', i18n })
export default class HomeECharts extends Vue {
  /** 图表类型 */
  @Prop({ type: String }) type!:
    | 'school'
    | 'schoolDepart'
    | 'unitSchoolDepart'
    | 'manageType'
    | 'lostStatis'
    | 'pie'
  /**  纵坐标数据 */
  @Prop({ type: Array }) readonly series!: any[]
  /**  横坐标数据 */
  @Prop({ type: Array }) readonly xAxisData!: string[]
  /** 颜色 */
  @Prop({
    type: Array,
    default: () => [
      '#3fa1ff',
      '#fbd337',
      '#f2637b',
      '#435188',
      '#975fe4',
      '#3aa0ff',
      '#4ecb73',
    ],
  })
  readonly color!: string[]
  /** 展示类型 */
  @Prop({ type: Array }) readonly legend?: string[]
  @Prop({ type: Boolean, default: true })
  readonly legendShow?: boolean
  @Prop({ type: Boolean, default: false })
  readonly rotate?: boolean
  @Prop({ type: Number })
  readonly lineLength?: number

  @Prop({ type: Object }) title?: any
  @Ref('echart-dom') readonly dom!: HTMLDivElement

  echart: echarts.ECharts | null = null

  mounted() {
    if (
      (!this.series && !this.xAxisData) ||
      this.series?.length < 1 ||
      this.xAxisData?.length < 1
    ) {
      return void 0
    }
    this.echart = ECharts.init(this.dom)
    this.refresh()
  }

  refresh() {
    if (this.echart === null) {
      return void 0
    }

    /** 显示类型 */
    const legend: ECharts.EChartOption.Legend = this.legend
      ? {
          right: this.lineLength
            ? undefined
            : this.type === 'pie'
            ? undefined
            : 20,
          left: this.lineLength ? 0 : this.type === 'pie' ? '20%' : undefined,
          itemGap: 10,
          itemWidth: 10,
          itemHeight: 10,
          data: this.legend,
          top: 20,
          borderRadius: 2,
          selectedMode: false,
        }
      : {}
    const title: any = this.title
      ? {
          text: `${this.title.name}\n\n${this.title.count}`,
          x: 'center',
          y: this.lineLength ? '40%' : '43%',
          itemGap: 50,
          textStyle: {
            color: '#323e59',
            fontSize: 14,
          },
        }
      : {}
    /** 显示介绍 */
    const tooltip: ECharts.EChartOption.Tooltip = {
      trigger: this.type === 'pie' ? 'item' : 'axis',
      padding: 10,
      backgroundColor: 'rgba(255,255,255,.8)',
      borderColor: '#eee',
      borderWidth: 1,
      position: this.type === 'pie' ? ['45%', '50%'] : undefined,
      textStyle: {
        color: '#323e59',
        fontSize: 12,
      },
      extraCssText: 'text-align:left;',
    }

    /** 纵坐标显示项 */
    const yAxis: any[] = []
    /** 显示数据定义 */
    let series: any[] = []
    switch (this.type) {
      case 'school':
        yAxis.push({
          type: 'value',
          name: this.$t('text.studentNum') as string,
          nameTextStyle: {
            fontSize: 14,
            color: '#8A8E96',
            padding: [0, 0, 0, -40],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
          axisLabel: {
            color: '#8A8E96',
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
        })
        series.push({ type: 'bar', data: this.series, barMaxWidth: 20 })
        tooltip.formatter = (params: any): any => {
          return `${params[0].name}<br/>${params[0].marker}${this.$t(
            'text.studentNum'
          ) as string}：${params[0].value}人`
        }
        break
      case 'unitSchoolDepart':
        yAxis.push({
          type: 'value',
          name: this.$t('text.studentNum') as string,
          nameTextStyle: {
            fontSize: 14,
            color: '#8A8E96',
            padding: [0, 0, 0, -40],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
          axisLabel: {
            color: '#8A8E96',
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
        })
        series = [
          {
            type: 'bar',
            barMaxWidth: 20,
            data: this.series,
          },
        ]
        tooltip.formatter = (params: any): any => {
          return `${params[0].name}<br/>${params[0].marker}${this.$t(
            'text.studentNum'
          ) as string}：${params[0].value}人`
        }
        break
      case 'schoolDepart':
      case 'manageType':
        yAxis.push({
          type: 'value',
          name: this.$t('text.studentNum') as string,
          nameTextStyle: {
            fontSize: 14,
            color: '#8A8E96',
            padding: [0, 0, 0, -40],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
          axisLabel: {
            color: '#8A8E96',
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
        })
        series = this.series.map(item => {
          return {
            type: 'bar',
            barMaxWidth: 20,
            name: `${item[`${this.type}Name`]}`,
            data: item.data,
          }
        })
        tooltip.formatter = (params: any): any => {
          let res = `${params[0].name}<br/>`
          params.forEach((item: any, index: number) => {
            res += `${params[index].marker}${params[index].seriesName}：${params[index].value}人<br/>`
          })
          return res
        }
        break
      case 'lostStatis':
        yAxis.push({
          type: 'value',
          name: this.$t('text.studentNum') as string,
          nameTextStyle: {
            fontSize: 14,
            color: '#8A8E96',
            padding: [0, 0, 0, -40],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
          axisLabel: {
            color: '#8A8E96',
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#D9DCE1',
            },
          },
        })
        // series = this.series?.map((item, index) => {
        //   return {
        //     type: index === 2 ? 'line' : 'bar',
        //     name: item.name,
        //     barMaxWidth: 20,
        //     lineStyle: {
        //       width: index === 2 ? 3 : undefined,
        //     },
        //     stack: index === 2 ? undefined : this.series[0].name,
        //     yAxisIndex: index === 2 ? 1 : undefined,
        //     data: item.data,
        //   }
        // })
        series = this.series.map(item => {
          return {
            type: 'bar',
            barMaxWidth: 20,
            name: item.name,
            data: item.data,
          }
        })
        tooltip.formatter = (params: any): any => {
          let res = `${params[0].name}<br/>`
          params.forEach((item: any, index: number) => {
            if (index === params.length - 1) {
              return void 0
            }
            res += `${params[index].marker}${params[index].seriesName}：${params[index].value}人<br/>`
          })
          return res
        }
        break
      case 'pie':
        series.push({
          type: 'pie',
          radius: this.rotate ? ['31%', '40%'] : ['51%', '60%'],
          data: this.series,
          labelLine: {
            show: true,
            length: this.rotate ? 6 : 10,
          },
          bottom: this.rotate ? 20 : undefined,
          label: {
            normal: {
              // rotate: this.rotate ? 45 : 0,
              // padding: this.rotate ? [0, -40] : 0,
              alignTo: this.rotate ? 'edge' : undefined,
              margin: this.rotate ? 5 : undefined,
            },
          },
        })
        tooltip.formatter = (params: any): any => {
          return `${params.marker}${params.name}：${params.value}${
            params.name === '实际流失率' ? '%' : '人'
          }`
        }
        legend.icon = 'circle'
        legend.orient = this.lineLength ? 'vertical' : 'horizontal'
        break
    }
    this.echart.setOption(
      {
        color: this.color,
        grid: {
          containLabel: true,
          left: 20,
          right: 20,
          bottom: 20,
        },
        legend: this.legendShow === false ? { show: false } : legend,
        title,
        tooltip,
        xAxis:
          this.type === 'pie'
            ? undefined
            : [
                {
                  type: 'category',
                  data: this.xAxisData || [],
                  axisTick: {
                    alignWithLabel: true,
                  },
                  axisLabel: {
                    color: '#8A8E96',
                  },
                  axisLine: {
                    show: true,
                    lineStyle: {
                      color: '#D9DCE1',
                    },
                  },
                },
              ],
        yAxis: this.type === 'pie' ? undefined : yAxis,
        series,
      },
      true
    )
  }

  @Watch('series')
  watchSeriesChange() {
    setTimeout(() => {
      this.echart = ECharts.init(this.dom)
      this.refresh()
    }, 200)
    // this.refresh()
  }
}
