















































import Vue from 'vue'
import { Component, Ref, Watch } from 'vue-property-decorator'
import { Button, DatePicker } from 'view-design'
import ViewLayout from '@layouts/view-layout'
import AppTable from '@components/app-table'
import AppSelect from '@components/app-select'
import { School, Session } from 'xuexin-vuex'
import { ActionType } from 'xuexin-vuex/src/constant'
import AppPagination from '@components/app-pagination'
import i18n from './i18n'

const components = {
  ViewLayout,
  Button,
  AppTable,
  AppSelect,
  DatePicker,
  AppPagination,
}

@Component({ name: 'TrialPage', components, i18n })
export default class TrialPage extends Vue {
  @Ref('pagination') private readonly pagination!: AppPagination
  @School.Action('fetchList')
  private readonly fetchList!: School.Action.FetchList
  @School.Action('update') private readonly update!: School.Action.Update
  @School.Getter('list') private readonly list!: School.Getter.List
  @School.Getter('listStatus')
  private readonly listStatus!: School.Getter.ListStatus
  @Session.Getter('user') private readonly getUser!: Session.Getter.User
  private readonly prefixCls = 'trial-page'
  private dataList: any = []
  private curUnitID: number = -1
  private page = 1
  private pageSize = 10

  // 查询条件
  get parameter() {
    return {
      xjType: 2,
      organID: this.getUser ? this.getUser.organID : -1,
      termID: this.termID,
    }
  }
  // 加载状态
  get loading() {
    return this.listStatus(this.parameter).fetching
  }
  /** 获取学期id */
  get termID() {
    return this.getUser?.extraMap.currentTermID
  }

  // 获取更新状态
  get getListStatus() {
    return this.listStatus(this.parameter)
  }
  get classes() {
    return {
      page: true,
      [this.prefixCls]: true,
    }
  }

  get dayList() {
    let arr: any = []
    for (let i = 1; i < 21; i++) {
      arr.push({ value: i, label: i })
    }
    return arr
  }

  get columns() {
    return [
      {
        title: this.$t('columns.index'),
        width: 80,
        align: 'center',
        slot: 'indexAction',
      },
      { title: this.$t('columns.school-name'), key: 'parentUnitName' },
      { title: this.$t('columns.campus'), key: 'unitName' },
      { title: this.$t('columns.trial-title-day'), slot: 'handleAction' },
    ]
  }
  // 获取列表总条数
  get total() {
    return this.listStatus(this.parameter).total === null ||
      this.listStatus(this.parameter).total === void 0
      ? 0
      : this.listStatus(this.parameter).total
  }
  // 判断是否显示分页
  get showPagination(): boolean {
    if (this.total === void 0) {
      return false
    }
    return this.total > 10
  }

  // 监听查询状态
  @Watch('getListStatus.fetching')
  private watchfetching(fetching: boolean, previous: boolean) {
    if (!fetching && previous) {
      if (this.getListStatus.fetchingError !== null) {
        this.$Message.error(this.getListStatus.fetchingError)
      }
      this.dataList = this.list(this.parameter)
    }
  }
  // 监听更新状态
  @Watch('getListStatus.updating')
  private watchUpdating(updating: boolean, previous: boolean) {
    if (!updating && previous) {
      if (this.getListStatus.updatingError !== null) {
        this.$Message.error(this.getListStatus.updatingError)
        return
      }
      this.$Message.success(this.$t('message.update-success'))
    }
  }

  //初始化页面
  private created() {
    this.fetch()
  }
  //保存事件
  private handleSave() {
    this.update(this.parameter)
  }
  //点击某一行事件
  private handleRowclick(row: School.Entity) {
    this.curUnitID = row.unitID
  }
  //天数发生变化
  private changeDay(day: number) {
    let _index = 0
    this.dataList.forEach((item: any, index: number) => {
      if (item.unitID === this.curUnitID) {
        _index = index
      }
    })
    // this.$set(
    //   this.dataList,
    //   _index,
    //   Object.assign({}, this.dataList[_index], { limitDay: day })
    // )
    // // 调用更新事件，更新当前行
    let cuRprobationLimits: any = []
    cuRprobationLimits.push(
      Object.assign({}, this.dataList[_index], { limitDay: day })
    )
    this.update({
      actionType: ActionType.Trial,
      probationLimits: cuRprobationLimits,
    })
  }
  //点击分页
  private handlePageChange(page: number): void {
    this.page = page
    this.$nextTick(this.fetch)
  }
  //切换分页条数
  private handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize
    if (this.page === 1) {
      this.$nextTick(this.fetch)
    } else {
      this.page = 1
    }
  }
  // 列表调用
  private fetch() {
    this.fetchList(
      Object.assign(this.parameter, {
        page: this.page,
        pageSize: this.pageSize,
      })
    )
  }
  // 页面布局设置
  private handleResize({ height }: ResizeParameter) {
    let _height = 64 + 12 * 4
    if (this.pagination !== void 0) {
      _height += this.pagination.getHeight()
    }
    this.$set(this.$data, 'tableHeight', height - _height)
  }
}
