import Vue, { VNodeChildren, VueConstructor } from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ComponentType, upperFirst } from '@util'
import Dictionary from '@store/modules/dictionary'
import DictionaryType from 'types/dictionary'
import AppRadioGroup from '@components/app-radio-group'
import AppSelect from '@components/app-select'
import i18n, { AcademicDirectionList } from './i18n'

type ViewType = 'text'
const prefixCls = `academic-direction`

function createComponent(
  component: Vue.Component | string,
  type: ComponentType
): VueConstructor {
  const name = `AcademicDirection${upperFirst(type)}`
  @Component({ name, inheritAttrs: false })
  class AcademicDirectionComponent extends Vue {
    @Dictionary.Action('fetchList')
    private readonly fetchList!: DictionaryType.Action.FetchList
    @Dictionary.Getter('listStatus')
    private readonly getStatus!: DictionaryType.Getter.ListStatus
    @Dictionary.Getter('list')
    private readonly getList!: DictionaryType.Getter.List

    @Prop({ type: Number }) private readonly phaseID!: number
    @Prop({ type: [Boolean, Number], default: 1 })
    private readonly freshStudent!: any
    @Prop({ type: Array, default: () => [] }) private readonly extra!: any[]
    @Prop({ type: String }) private readonly viewType!: ViewType

    get classes() {
      return {
        [`${prefixCls}-${type}`]: true,
      }
    }

    get locale(): Locale {
      return this.$i18n.locale as Locale
    }
    get status() {
      return this.getStatus(this.parameter)
    }

    get fetching() {
      return this.status.fetching === true
    }

    get list() {
      const list = this.getList(this.parameter).filter((item: any) => {
        // 过滤被禁用的数据
        return (this.phaseID == 4 && this.freshStudent) || item.dataKey !== '5'
      })
      return this.extra.concat(
        list.map((value: any) => {
          return {
            label: value.dataValue,
            value: value.dataKey,
          }
        })
      )
    }

    get props() {
      const props: Record<string, any> = {
        loading: this.fetching,
      }
      switch (type) {
        case ComponentType.Select:
        case ComponentType.RadioGroup:
          props.data = this.list
          break
      }
      return props
    }

    get parameter() {
      return {
        phaseID: this.phaseID,
        freshStudent: this.freshStudent ? 1 : 0,
        catalogID: 114,
      }
    }

    @Watch('parameter')
    watchParameter() {
      this.fetch()
    }

    render(createElement: Vue.CreateElement) {
      let children: VNodeChildren = []
      /** 以文本方式显示 */
      if (type === ComponentType.Text || this.viewType === 'text') {
        const curItem = this.list.find(item => {
          return `${item.value}` == `${this.$attrs.value}`
        })
        if (curItem) {
          children = [`${curItem.label}`]
        }
      }

      return createElement(
        this.viewType === 'text' ? 'span' : component,
        {
          class: this.classes,
          props: this.props,
          attrs: Object.assign({}, this.$attrs),
          on: this.$listeners,
        },
        children
      )
    }

    private fetch() {
      if (this.status.fetching === true || this.status.loaded) {
        // 以上情况，不需要请求数据
        return void 0
      }

      // this.fetchList({
      //   catalogID: '114,115,107',
      //   phaseID: 4,
      //   freshStudent: 1,
      // })
    }

    created() {
      // this.fetch()
    }
  }

  return AcademicDirectionComponent
}

export const AcademicDirectionSelect = createComponent(
  AppSelect,
  ComponentType.Select
)
export const AcademicDirectionText = createComponent('span', ComponentType.Text)
