/** 实际流失率统计 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import ActualLossRate from '@pages/statistic-reports/actual-loss-rate'

const children: RouteConfig[] = []
/** 实际流失率统计 */
children.push({
  path: '/reports/actual-loss-rate',
  name: 'actual-loss-rate-report',
  component: ActualLossRate,
  meta: { ignore: true, authCode: '050708', parentAuthCode: '0507' },
})

export default {
  path: '/reports',
  name: 'actual-loss-rate',
  redirect: { name: 'actual-loss-rate-report' },
  meta: { title: '实际流失率统计', authCode: '050708' },
  component: RouterViewLayout,
  props: { name: 'actual-loss-rate' },
  children,
}
