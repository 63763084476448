/** 班级缴费统计 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import ClassPayment from '@pages/statistic-reports/class-payment'

const children: RouteConfig[] = []

/** 班级缴费统计 */
children.push({
  path: '/reports/class-payment',
  name: 'class-payment-report',
  component: ClassPayment,
  meta: { ignore: true, authCode: '050715', parentAuthCode: '0507' },
})

export default {
  path: '/reports/class-payment',
  name: 'class-payment',
  redirect: { name: 'class-payment-report' },
  meta: { title: '班级缴费统计', authCode: '050715' },
  component: RouterViewLayout,
  props: { name: 'class-payment' },
  children,
}
