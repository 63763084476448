/** 学生异动统计表 */
import { RouteConfig } from 'vue-router'
import RouterViewLayout from '@layouts/router-view-layout'
import StudentAbnormalChange from '@pages/statistic-reports/student-abnormal-change'

const children: RouteConfig[] = []

/** 学生异动统计表 */
children.push({
  path: '/reports/student-abnormal-change',
  name: 'student-abnormal-change-report',
  component: StudentAbnormalChange,
  meta: { ignore: true, authCode: '050703', parentAuthCode: '0507' },
})

export default {
  path: '/reports/student-abnormal-change',
  name: 'student-abnormal-change',
  redirect: { name: 'student-abnormal-change-report' },
  meta: { title: '学生异动统计表', authCode: '050703' },
  component: RouterViewLayout,
  props: { name: 'student-abnormal-change' },
  children,
}
