export const messages: Messages = {
  'zh-CN': {
    title: '转学',
    label: {
      termName: '转学学期',
      date: '操作日期',
      name: '学生姓名',
      area: '转至地区',
      reason: '转学原因',
      flow: '考核流失',
      school: '转至学校',
      file: '附件',
      mark: '备注',
      tip: '选择文件',
      transferDate: '实际转学日期',
      mealEndDate: '是否就餐',
      stayEndDate: '是否住宿',
      parentBankCard: '家长收款账号',
      isRefund: '是否退费',
      payee: '收款人姓名',
      bankOfDeposit: '开户行',
    },
    text: {
      placeholder: '请输入备注信息',
      reason: '请选择转学原因',
      success: '转学成功',
      size: '文件总大小',
      desc: '上传的文件总大小不能超过10M',
      yes: '是',
      no: '否',
    },

    valid: {
      name: '学生姓名不能为空',
      date: '请选择日期',
      flow: '请选择考核流失信息',
      area: '请选择转入地区',
      reason: '请选择转学原因',
      school: '请填写转至学校',
      card: '请填写收款账号',
      card2: '请输入正确的银行卡号',
      payee: '请输入收款人姓名',
      bankOfDeposit: '请输入开户行',
    },
    button: {
      'do-flow': '计入',
      'un-flow': '不计入',
      uncle: '本地',
      'out-uncle': '外地',
    },
    placeholder: {
      date: '请选择日期',
    },
  },
  'en-US': {},
}

export default { messages }
