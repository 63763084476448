export const BloodTypeList = ['0', '1', '2', '3', '4', '5']

export const messages: Messages = {
  'zh-CN': {
    '0': 'A型血',
    '1': 'B型血',
    '2': 'AB型血',
    '3': 'O型血',
    '4': '未知',
    '5': '其他',
    null: '-',
    undefined: '-',
    '': '-',
  },
  'en-US': {
    '0': 'A型血',
    '1': 'B型血',
    '2': 'AB型血',
    '3': 'O型血',
    '4': '未知',
    '5': '其他',
    null: '-',
    undefined: '-',
    '': '-',
  },
}

export default { messages }
